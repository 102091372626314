import React, { Fragment, useEffect, useState } from 'react';

import { useDispatch, useSelector, useNotifications } from 'hooks';
import modalTypes from 'constants/modalTypes';
import { getNotificationList } from 'store/actions/notification';
import { openModal } from 'store/actions/modal';

import { isDevEnvironment } from 'store/reducers/app';
import {
  selectNotificationPersistentList,
  selectNotificationPersistentListLoading,
  selectPersistentListTotalCount
} from 'store/reducers/notification';
import { selectHasNotificationsPopup } from 'store/reducers/navigation';

import { Notification, LoadingSpinner, LoadingDots, Icon, IconButton } from 'components';
import {
  NotificationPopupShadow,
  NotificationPopupWrapper,
  NotificationListWrapper,
  NotificationsListHeading,
  NotificationsListHeader,
  NotificationsDivider,
  LoadingWrapper,
  LoadMoreButton,
  EmptyPlaceholder
} from './styled';

const NotificationsPopup = () => {
  const dispatch = useDispatch();
  const { handleCloseNotificationsPopup, handleNotificationNavigate } = useNotifications();

  const hasDevEnvironment = useSelector(isDevEnvironment);
  const hasNotificationsPopup = useSelector(selectHasNotificationsPopup);
  const notifications = useSelector(selectNotificationPersistentList);
  const notificationsLoading = useSelector(selectNotificationPersistentListLoading);
  const notificationsTotalCount = useSelector(selectPersistentListTotalCount);

  const [page, setPage] = useState(1);

  useEffect(() => {
    if (page > 1) {
      dispatch(getNotificationList({ page }));
    }
  }, [dispatch, page]);

  useEffect(() => {
    setPage(1);
  }, [hasDevEnvironment]);

  const handlePageChange = () => setPage((page) => page + 1);

  const handleNotificationItemClick = (notification) => {
    handleCloseNotificationsPopup();
    handleNotificationNavigate(notification);
  };

  const handleOpenClearAllConfirmation = () => {
    dispatch(openModal(modalTypes.CLEAR_ALL_PERSISTENT_NOTIFICATIONS));
  };

  if (notificationsLoading && hasNotificationsPopup && page === 1) {
    return (
      <LoadingWrapper>
        <LoadingSpinner text="Please wait" type="logo" />
      </LoadingWrapper>
    );
  }

  if (!hasNotificationsPopup) {
    return null;
  }

  return (
    <NotificationPopupShadow visible={hasNotificationsPopup} onClick={handleCloseNotificationsPopup}>
      <NotificationPopupWrapper onClick={(e) => e.stopPropagation()}>
        <NotificationsListHeading isEmptyList={!Boolean(notifications.length)}>
          <NotificationsListHeader>Notifications</NotificationsListHeader>
          {Boolean(notifications.length) && (
            <IconButton width={76} height={24} onClick={handleOpenClearAllConfirmation} square text="Clear All" />
          )}
        </NotificationsListHeading>
        <NotificationListWrapper>
          {Boolean(notifications.length) ? (
            notifications.map((notification, id) => (
              <Fragment key={id}>
                <Notification
                  transparent
                  padding="20px 24px"
                  width="100%"
                  notification={notification}
                  onClick={handleNotificationItemClick}
                />
                {id !== notifications.length - 1 && <NotificationsDivider />}
              </Fragment>
            ))
          ) : (
            <EmptyPlaceholder>There are no notifications for you</EmptyPlaceholder>
          )}
          {notificationsTotalCount > notifications.length && (
            <LoadMoreButton disabled={notificationsLoading && page > 1} onClick={handlePageChange}>
              {notificationsLoading && page > 1 ? (
                <LoadingDots />
              ) : (
                <>
                  <Icon name="loadMore" />
                  Load More
                </>
              )}
            </LoadMoreButton>
          )}
        </NotificationListWrapper>
      </NotificationPopupWrapper>
    </NotificationPopupShadow>
  );
};

export default NotificationsPopup;
