import { formatters, normalizers, validators } from 'utils';

export const inputConfigTypes = {
  AMOUNT: {
    placeholder: '1,234',
    type: 'text',
    format: formatters.amount
  },
  DATE: {
    type: 'text',
    placeholder: 'MM/DD/YYYY',
    format: formatters.date,
    validate: [validators.date],
    normalize: normalizers.date
  },
  CURRENCY: {
    placeholder: '1,234.56',
    type: 'text',
    styletype: 'currency',
    format: formatters.currency
  },
  NUMBER: {
    placeholder: '1234',
    type: 'text',
    validate: [validators.number]
  }
};
