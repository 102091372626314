import actionTypes from 'constants/actionTypes';

export const getConversationList = (payload) => ({
  type: actionTypes.GET_CONVERSATION_LIST_FETCH,
  payload
});

export const getCommunicationSmsList = (payload) => ({
  type: actionTypes.GET_COMMUNICATION_SMS_LIST_FETCH,
  payload
});

export const createCommunicationSms = (payload) => ({
  type: actionTypes.CREATE_COMMUNICATION_SMS_FETCH,
  payload
});

export const updateConversation = (payload) => ({
  type: actionTypes.UPDATE_CONVERSATION_FETCH,
  payload
});
