import { call, takeEvery, put } from 'redux-saga/effects';
import actionTypes from 'constants/actionTypes';
import { getImageFromS3 } from 'utils';

function* getAvatarOriginal(action) {
  const { payload } = action;
  const { url, id } = payload;

  try {
    const response = yield call(getImageFromS3, url);

    if (response.blob === undefined) {
      yield put({
        type: actionTypes.GET_AVATAR_ORIGINAL_SUCCESS,
        payload: {
          id,
          url,
          blobUrl: ''
        }
      });
      // Skip empty avatar
      return;
    }

    const blob = yield response.blob();
    const blobUrl = URL.createObjectURL(blob);

    if (blobUrl) {
      yield put({
        type: actionTypes.GET_AVATAR_ORIGINAL_SUCCESS,
        payload: {
          id,
          url,
          blobUrl
        }
      });
    } else {
      yield put({
        type: actionTypes.GET_AVATAR_ORIGINAL_ERROR
      });
    }
  } catch (err) {
    console.log(err);
  }
}

export default function* watchGetAvatarOriginal() {
  yield takeEvery(actionTypes.GET_AVATAR_ORIGINAL_FETCH, getAvatarOriginal);
}
