import { call, takeLatest, put } from 'redux-saga/effects';

import api_constants from 'constants/api';
import errors from 'constants/errors';
import actionTypes from 'constants/actionTypes';
import modalTypes from 'constants/modalTypes';
import messages from 'constants/messages';
import { openModal } from 'store/actions';

import Api from 'services/ApiService';

function* updatePromoCode({ payload }) {
  const { referral_code, is_active = true } = payload;
  try {
    const response = yield call(Api.amplifyPut, `${api_constants.PROMO_CODE}/${referral_code}`, payload);

    if (!response || response.error_message || response.err) {
      throw new Error(response?.err);
    }

    yield put({
      type: actionTypes.UPDATE_PROMO_CODE_SUCCESS,
      payload: response
    });

    yield put(
      openModal(modalTypes.RESULT, {
        title: `Reward "${referral_code}" has been updated`,
        text: is_active ? messages.requstSucceed.PROMO_CODE_TEXT : messages.requstSucceed.PROMO_CODE_DISABLED_TEXT
      })
    );
  } catch (err) {
    yield put({
      type: actionTypes.UPDATE_PROMO_CODE_ERROR
    });
    yield put(
      openModal(modalTypes.ERROR, {
        errorMessage: err?.message || err?.name || errors.DEFAULT
      })
    );
  }
}

export default function* watchUpdatePromoCode() {
  yield takeLatest(actionTypes.UPDATE_PROMO_CODE_FETCH, updatePromoCode);
}
