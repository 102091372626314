import { columnNames } from 'common/enums/sortings';

export const taskTable = {
  TAG_LIST: {
    TITLE: 'Tag',
    COLUMN_NAME: columnNames.TAG_LIST
  },
  STAGE: {
    TITLE: 'Stage',
    COLUMN_NAME: columnNames.STAGE
  },
  ID: {
    TITLE: 'ID',
    COLUMN_NAME: columnNames.ID
  },
  HO_GIVEN_NAME: {
    TITLE: 'HO Name',
    COLUMN_NAME: columnNames.HO_GIVEN_NAME
  },
  PRO_GIVEN_NAME: {
    TITLE: 'PRO Name',
    COLUMN_NAME: columnNames.PRO_GIVEN_NAME
  },
  DATETIME_COMPLETED: {
    TITLE: 'Completed Date',
    COLUMN_NAME: columnNames.DATETIME_COMPLETED
  },
  DATETIME_CREATED: {
    TITLE: 'Request Date',
    COLUMN_NAME: columnNames.DATETIME_CREATED
  },
  IS_QUOTED: {
    TITLE: 'Quoted',
    COLUMN_NAME: columnNames.IS_QUOTED
  },
  AMOUNT_ESTIMATED: {
    TITLE: 'Est. Amount',
    COLUMN_NAME: columnNames.AMOUNT_ESTIMATED
  },
  WARNING: {
    TITLE: 'Early Warning',
    COLUMN_NAME: columnNames.WARNING
  },
  LAST_NOTE: {
    TITLE: 'Last Note',
    COLUMN_NAME: columnNames.LAST_NOTE
  },
  DAYS_DELAY: {
    TITLE: 'Days Delayed',
    COLUMN_NAME: columnNames.DAYS_DELAY
  },
  AMOUNT_INVOICED: {
    TITLE: 'Invoiced',
    COLUMN_NAME: columnNames.AMOUNT_INVOICED
  },
  LAST_MESSAGE: {
    TITLE: 'Last Message',
    COLUMN_NAME: columnNames.LAST_MESSAGE
  },
  TOTAL_PAID: {
    TITLE: 'Total Amount',
    COLUMN_NAME: columnNames.TOTAL_PAID
  }
};
