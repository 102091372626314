export const planStages = {
  NOT_CONFIRMED: 0,
  CONFIRMED: 1,
  QUOTE_SENT: 2,
  QUOTE_APPROVED: 3,
  SCHEDULED: 4,
  COMPLETED: 5,
  FINAL_SENT: 6,
  FINAL_PAID: 7
};

export const PLAN_LIST = [
  {
    title: 'Confirmed by Pro',
    description: ''
  },
  {
    title: 'Quote Sent',
    description: ''
  },
  {
    title: 'Quote Approved',
    description: ''
  },
  {
    title: 'Visit Scheduled',
    description: ''
  },
  {
    title: 'Pro Completed the Job',
    description: ''
  },
  {
    title: 'Final Payment Sent',
    description: ''
  },
  {
    title: 'Paid Final Payment',
    description: ''
  }
];
