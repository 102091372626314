import React from 'react';

import { useDispatch, useEffect, useForm, useSelector } from 'hooks';
import content from 'constants/content';
import { updateJobField, openModal, closeModal } from 'store/actions';
import { selectModalData } from 'store/reducers/modal';
import { selectUpdateJobFieldLoading } from 'store/reducers/service';
import { selectJobPayments } from 'store/reducers/invoice';

import modalTypes from 'constants/modalTypes';
import { Modal, HookForm, Button } from 'components';

import { CancelationReasonWrapper, FlexRow, dropdownStyles } from './styled';

const CancelTask = ({ modalType = modalTypes.CANCEL_TASK_REASON }) => {
  const dispatch = useDispatch();
  const updateJobFieldLoading = useSelector(selectUpdateJobFieldLoading);
  const modalData = useSelector(selectModalData);
  const payments = useSelector(selectJobPayments);
  const { task_id } = modalData || {};

  const {
    control,
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors }
  } = useForm();
  const watchUnfulfilledGroup = watch('unfulfilled_group');
  const unfulfilledReasons = content.unfulfilledReasonList.filter((item) => item.groupId === watchUnfulfilledGroup);
  const isTextAreaDescription = watchUnfulfilledGroup === content.unfulfilledGroup.OTHER;

  useEffect(() => {
    setValue('unfulfilled_reason', null);
    const unfulfilledReasons = content.unfulfilledReasonList.filter((item) => item.groupId === watchUnfulfilledGroup);
    if (unfulfilledReasons.length === 1) {
      setValue('unfulfilled_reason', unfulfilledReasons[0].id);
      setValue('additional_info', '');
    }
  }, [watchUnfulfilledGroup]);

  const handleUpdateJob = ({ additional_info, unfulfilled_reason, job_id }) => {
    dispatch(
      updateJobField({
        job_id,
        body: {
          additional_info,
          status: 3,
          unfulfilled_reason: Number(unfulfilled_reason),
          resultModal: {
            modalType: modalTypes.RESULT,
            title: `Job ID ${job_id} is canceled`,
            text: 'All parties will be notified.'
          }
        }
      })
    );
  };

  const handleConfirmCanceling =
    ({ unfulfilled_reason, additional_info, job_id }) =>
    () => {
      handleUpdateJob({ unfulfilled_reason, additional_info, job_id });
    };

  const handleCloseCanceling = () => {
    dispatch(closeModal(modalTypes.CONFIRMATION));
  };

  const handleCancelTask = ({ unfulfilled_reason, additional_info = '' }) => {
    if (!task_id) {
      return;
    }

    const isPendingPayment = payments.some(({ is_paid }) => !is_paid);
    if (isPendingPayment) {
      dispatch(
        openModal(modalTypes.CONFIRMATION, {
          title: 'Are you sure?',
          text: 'This job has pending payment(s). Would you still like cancel this job?',
          hasPrimaryButton: true,
          primaryButtonTitle: 'Yes',
          primaryButtonLoadingSelector: selectUpdateJobFieldLoading,
          onPrimaryButtonPress: handleConfirmCanceling({ unfulfilled_reason, additional_info, job_id: task_id }),
          hasSecondaryButton: true,
          secondaryButtonTitle: 'No'
        })
      );
      return;
    }

    handleUpdateJob({
      additional_info,
      unfulfilled_reason,
      job_id: task_id
    });
  };

  const onModalClose = () => {
    dispatch(closeModal(modalType));
  };

  return (
    <Modal
      modalType={modalType}
      heading={`Cancel the Job`}
      text={`The HO will be notified about the cancelation.\nCurrent SP will be unassigned from this job.`}
      hideModal={onModalClose}
      closeButton={{
        onClick: onModalClose,
        disabled: updateJobFieldLoading
      }}
      padding="0"
      overflowMode="none">
      <CancelationReasonWrapper>
        <FlexRow>
          <HookForm.Select
            label=""
            name="unfulfilled_group"
            control={control}
            rules={{ required: true }}
            error={errors.unfulfilled_group}
            placeholder="Select the Reason"
            width={430}
            style={dropdownStyles}
            options={content.unfulfilledGroupList}
          />
        </FlexRow>
        {watchUnfulfilledGroup ? (
          <FlexRow>
            {isTextAreaDescription ? (
              <HookForm.Textarea
                label="Description"
                rows={6}
                width={360}
                inputConfig={{ defaultValue: '' }}
                name="additional_info"
                register={register}
                placeholder="Describe a reason"
                error={errors.additional_info}
              />
            ) : (
              <HookForm.Radio
                label={'Description'}
                name="unfulfilled_reason"
                control={control}
                rules={{ required: true }}
                error={errors.unfulfilled_reason}
                placeholder="Description"
                options={unfulfilledReasons}
              />
            )}
          </FlexRow>
        ) : null}
        <FlexRow>
          <Button
            title={'Cancel Job'}
            onClick={handleSubmit(handleCancelTask)}
            loading={updateJobFieldLoading}
            disabled={updateJobFieldLoading}
            margin="0"
          />
        </FlexRow>
      </CancelationReasonWrapper>
    </Modal>
  );
};

export default CancelTask;
