import { call, takeLatest, put } from 'redux-saga/effects';
import api_constants from 'constants/api';
import errors from 'constants/errors';
import actionTypes from 'constants/actionTypes';
import Api from 'services/ApiService';

function* getCampaignById({ payload }) {
  const { campaignId } = payload;
  try {
    const response = yield call(Api.amplifyGet, `${api_constants.CAMPAIGN}/${campaignId}`);

    if (!(response || response.id)) {
      throw new Error(errors.DEFAULT);
    }

    yield put({
      type: actionTypes.GET_CAMPAIGN_BY_ID_SUCCESS,
      payload: response
    });
  } catch (err) {
    yield put({
      type: actionTypes.GET_CAMPAIGN_BY_ID_ERROR
    });
  }
}

export default function* watchGetCampaignById() {
  yield takeLatest(actionTypes.GET_CAMPAIGN_BY_ID_FETCH, getCampaignById);
}
