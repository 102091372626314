import styled from 'styled-components';
import styles from 'constants/styles';

export const ContentFormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  padding: 32px 24px;
`;

export const FlexRow = styled.div`
  display: flex;
  flex: 1;
  padding: 13px 0 5px;

  &:first-child {
    padding: 0 0 5px;
  }
  &:last-child {
    justify-content: space-between;
  }
`;

export const Label = styled.label`
  font-weight: 600;
  font-size: 12px;
  line-height: 1.7;
  color: ${styles.colors.GRAY};
`;

export const Heading = styled.span`
  flex: 1;
  font-weight: bold;
  font-size: 24px;
  line-height: 1.2;
`;

export const dropdownStyles = {
  background: styles.colors.DARK_GREY_3
};
