import { call, takeLatest, put } from 'redux-saga/effects';
import api_constants from 'constants/api';
import actionTypes from 'constants/actionTypes';
import Api from 'services/ApiService';
import { getFormattedReview } from 'utils';

function* getCustomerReviews({ payload: { page, reset, customerId } }) {
  try {
    const response = yield call(Api.amplifyGet, `${api_constants.REVIEW}?page=${page}&user_id=${customerId}`);

    const formattedReviews = response.results
      .map((review) => ({ ...review, isCustomers: true }))
      .map(getFormattedReview);
    if (!response || !response.results) {
      throw new Error();
    }
    yield put({
      type: actionTypes.GET_CUSTOMER_REVIEWS_SUCCESS,
      payload: {
        reviews: formattedReviews,
        count: response.count,
        page,
        reset
      }
    });
  } catch (err) {
    console.log(err);
    yield put({
      type: actionTypes.GET_CUSTOMER_REVIEWS_ERROR
    });
  }
}

export default function* watchGetCustomerReviews() {
  yield takeLatest(actionTypes.GET_CUSTOMER_REVIEWS_FETCH, getCustomerReviews);
}
