import React from 'react';

import { getFullName } from 'common/helpers/user';
import { useDispatch, useSelector } from 'hooks';
import { closeModal } from 'store/actions';
import { updateJobField } from 'store/actions/service';
import { selectUpdateJobFieldLoading } from 'store/reducers/service';
import { selectModalData } from 'store/reducers/modal';
import modalTypes from 'constants/modalTypes';
import { ModalConfirmation } from 'components';
import { ModalText } from './styled';

const UnassignProviderConfirmation = () => {
  const dispatch = useDispatch();
  const modalData = useSelector(selectModalData);
  const updateJobFieldLoading = useSelector(selectUpdateJobFieldLoading);
  const name = getFullName(modalData?.user || {});

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const handleUnassignServiceProvider = () => {
    dispatch(
      updateJobField({
        job_id: modalData.job_id,
        body: {
          service_provider_id: null,
          resultModal: {
            modalType: modalTypes.UNASSIGN_PROVIDER_RESULT,
            job_id: modalData.job_id,
            name
          }
        }
      })
    );
  };

  return (
    <ModalConfirmation
      modalType={modalTypes.UNASSIGN_PROVIDER}
      smallPaddings={false}
      title="Unassign Pro?"
      text={
        <ModalText>
          <strong>{name} </strong>will receive notification about job unassignment{' '}
          <strong>(ID {modalData.job_id}).</strong>
        </ModalText>
      }
      hideModal={handleCloseModal}
      primaryButton={{
        onClick: handleCloseModal,
        disabled: updateJobFieldLoading
      }}
      secondaryButton={{
        title: 'Confirm',
        onClick: handleUnassignServiceProvider,
        disabled: updateJobFieldLoading,
        loading: updateJobFieldLoading
      }}
    />
  );
};

export default UnassignProviderConfirmation;
