import React from 'react';
import { useWatch } from 'hooks';

import { HookForm } from 'components';
import { jobCategories, jobTypes } from 'common/enums/job';

export const CreateJobNavigation = ({ register, control, setValue, name_prefix = 'campaign_push.path_type' }) => {
  const [selectedJobType, selectedJobCategory] = useWatch({
    control,
    name: [`${name_prefix}.job_type`, `${name_prefix}.job_category_id`]
  });

  const jobTypeOptions = [
    { name: jobTypes.NORMAL.name, id: jobTypes.NORMAL.id },
    { name: jobTypes.PROJECT.name, id: jobTypes.PROJECT.id },
    { name: jobTypes.SEASON_CLEANING.name, id: jobTypes.SEASON_CLEANING.id },
    { name: 'Empty', id: null }
  ];

  const jobCategoryOptions = [
    ...jobCategories.filter(({ job_types }) => job_types?.includes(selectedJobType)),
    { name: 'Empty', id: null }
  ];

  const handleSetJobType = (value) => {
    if (value == null) {
      setValue(`${name_prefix}.job_description`, '', { shouldValidate: true });
    }
    const hasCategoryMatchType = jobCategoryOptions?.some(({ id }) => id === selectedJobType);
    if (!hasCategoryMatchType || value == null) {
      setValue(`${name_prefix}.job_category_id`, null, { shouldValidate: true });
    }
  };

  const handleSetJobCategory = (value) => {
    if (value == null) {
      setValue(`${name_prefix}.job_description`, '', { shouldValidate: true });
    }
  };

  return (
    <>
      <div>
        <HookForm.Select
          name={`${name_prefix}.job_type`}
          label="Type*"
          placeholder="Select Type"
          control={control}
          fill
          width={300}
          onCustomChange={handleSetJobType}
          options={jobTypeOptions}
          scrollToItemBlock="center"
        />
      </div>
      <div style={{ margin: '21px 0' }}>
        <HookForm.Select
          name={`${name_prefix}.job_category_id`}
          label="Category*"
          placeholder="Select Category"
          control={control}
          fill
          width={300}
          onCustomChange={handleSetJobCategory}
          hasDisabledOpacity={selectedJobType === null}
          options={jobCategoryOptions}
          scrollToItemBlock="center"
        />
      </div>
      <div style={{ margin: '0 0 5px' }}>
        <HookForm.ControlledTextarea
          name={`${name_prefix}.job_description`}
          register={register}
          control={control}
          disabled={selectedJobType === null || selectedJobCategory === null}
          label="Description (optional)"
          placeholder="Enter description"
          rules={{
            maxLength: 1000
          }}
          hasSmallLegthTitle
          lengthInfoPrefix="Restricted length"
          style={{
            height: '80px',
            minHeight: '80px'
          }}
        />
      </div>
      <HookForm.ControlledTextarea
        name={`${name_prefix}.request_source_name`}
        control={control}
        lengthInfoPrefix="Restricted length"
        rules={{
          maxLength: 30
        }}
        label="Request source*"
        placeholder="Enter request source"
        hasSmallLegthTitle
        style={{
          height: '35px',
          minHeight: '35px'
        }}
      />
    </>
  );
};
