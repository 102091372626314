import { columnNames } from 'common/enums/sortings';

export const quoteTable = {
  DOWNLOAD_CSV_BUTTON: {
    TITLE: '',
    FILTER_NAME: columnNames.DOWNLOAD_CSV_BUTTON
  },
  JOB_ID: {
    TITLE: 'ID',
    COLUMN_NAME: columnNames.JOB_ID
  },
  HO_GIVEN_NAME: {
    TITLE: 'HO Name',
    COLUMN_NAME: columnNames.HO_GIVEN_NAME
  },
  HO_ID: {
    TITLE: 'HO ID',
    COLUMN_NAME: columnNames.HO_ID
  },
  PRO_GIVEN_NAME: {
    TITLE: 'PRO Name',
    COLUMN_NAME: columnNames.PRO_GIVEN_NAME
  },
  PRO_ID: {
    TITLE: 'PRO ID',
    COLUMN_NAME: columnNames.PRO_ID
  },
  JOB_CATEGORY_NAME: {
    TITLE: 'Request Cat',
    COLUMN_NAME: columnNames.JOB_CATEGORY_NAME
  },
  DATETIME_CREATED: {
    TITLE: 'Invoice Date',
    COLUMN_NAME: columnNames.DATETIME_CREATED
  },
  AMOUNT_TOTAL_CHARGE: {
    TITLE: 'Invoice Amount',
    COLUMN_NAME: columnNames.AMOUNT_TOTAL_CHARGE
  },
  LAST_MESSAGE: {
    TITLE: 'Last Message',
    COLUMN_NAME: columnNames.LAST_MESSAGE
  },
  LAST_NOTE: {
    TITLE: 'Last Note',
    COLUMN_NAME: columnNames.LAST_NOTE
  }
};
