import React, { useEffect } from 'react';
import { AmplifyProvider } from '@aws-amplify/ui-react';
import { Route, Routes } from 'react-router-dom';
import moment from 'moment-timezone';

import { useSelector } from 'hooks';
import { environments } from 'common/enums/app';
import { environment } from 'common/constants/app';
import { selectActiveTimezone, environment as selectEnvironment } from 'store/reducers/app';

import { amplifyTheme } from 'constants/styles';
import { ErrorBoundary } from 'components';
import { ProtectedNavigation, MainNavigation, StorybookNavigation, Switches } from 'navigation';
import { FlexContainer, GlobalStyle } from './styled';

const RootNavigation = () => {
  const isLocal = environment === environments.LOCAL.TYPE;
  const appEnv = useSelector(selectEnvironment);
  const activeTimezone = useSelector(selectActiveTimezone);

  useEffect(() => {
    moment.tz.setDefault(activeTimezone);
  }, [activeTimezone]);

  if (isLocal) {
    return (
      <ErrorBoundary>
        <GlobalStyle />
        <FlexContainer>
          <Routes>
            <Route path="/storybook/*" element={<StorybookNavigation />} />
            <Route path="*" element={<MainNavigation />} />
          </Routes>
          <Switches isLocal />
        </FlexContainer>
      </ErrorBoundary>
    );
  }

  return (
    <ErrorBoundary>
      <GlobalStyle />
      <FlexContainer>
        <Routes>
          {appEnv !== environments.PRODUCT.TYPE && <Route path="/storybook/*" element={<StorybookNavigation />} />}
          <Route
            path="*"
            element={
              <AmplifyProvider theme={amplifyTheme}>
                <ProtectedNavigation />
              </AmplifyProvider>
            }
          />
        </Routes>
        <Switches />
      </FlexContainer>
    </ErrorBoundary>
  );
};

export default RootNavigation;
