import React, { memo } from 'react';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';

import { useState, useCallback, useEffect, useRef, useMemo } from 'hooks';
import { getCenteredBoundsLiteral } from './helpers/map';
import { prepareVisitMarkers } from './helpers/visit';
import { MAP_PADDING } from './constants';
import { defaultCoordinates } from './enums';
import { CustomerMarker, VisitMarker } from './components';
import { MapContainer, mapContainerStyle } from './styles';

const Map = ({
  providers,
  providersLoading,
  job,
  selectedProvider,
  hoveredProId,
  onChangeHoveredVisit,
  onCloseAssignModal,
  onProviderSelect,
  setSelectedVisitId,
  selectedVisitId
}) => {
  // a customer marker should be the center of the map only on first load of provider list
  const hasInitialFitting = useRef(false);
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    mapIds: ['f4407895ddc41428'],
    googleMapsApiKey: 'AIzaSyBybWSAiu-1qkGwA6YQvgw44Iq9_HgSOz0'
  });
  const [googleMap, setGoogleMap] = useState(null);

  const upcomingVisits = useMemo(() => prepareVisitMarkers(providers), [providers]);

  const customerCoordinates = job?.id
    ? {
        lat: job.user?.latitude,
        lng: job.user?.longitude
      }
    : null;

  const handleExtendBounds = () => {
    if (!window.google?.maps) {
      return null;
    }
    const bounds = new window.google.maps.LatLngBounds(customerCoordinates);

    let markers = selectedProvider.id ? selectedProvider.upcoming_events : upcomingVisits;

    if (selectedVisitId) {
      markers = markers.filter(({ visit_id }) => visit_id === selectedVisitId);
    }

    markers.forEach(({ latitude, longitude }) => bounds.extend({ lat: latitude, lng: longitude }));
    return bounds;
  };

  const onMarkersRefit = () => {
    const bounds = handleExtendBounds();

    if (!hasInitialFitting.current && googleMap && upcomingVisits.length && job?.id) {
      // fit map center to customer marker
      const latLngBoundsLiteral = getCenteredBoundsLiteral(bounds, customerCoordinates);
      googleMap.fitBounds(latLngBoundsLiteral, MAP_PADDING);
      hasInitialFitting.current = true;
      return;
    }

    if (bounds && googleMap) {
      googleMap.fitBounds(bounds, MAP_PADDING);
    }
  };

  useEffect(() => {
    if (upcomingVisits.length) {
      onMarkersRefit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProvider.id, selectedVisitId, upcomingVisits.length]);

  useEffect(() => {
    if (!googleMap) {
      return;
    }

    if (!upcomingVisits.length) {
      googleMap.setCenter(customerCoordinates || defaultCoordinates);
    }

    onMarkersRefit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [job?.id]);

  const handleLoad = useCallback(
    (map) => {
      map.setCenter(job?.id ? customerCoordinates : defaultCoordinates);
      setGoogleMap(map);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [job?.id, customerCoordinates]
  );

  const handleUnmount = useCallback(() => {
    setGoogleMap(null);
  }, []);

  const handleMarkerClick = ({ visit_id, service_provider_id }) => {
    if (selectedVisitId === visit_id) {
      return;
    }

    onProviderSelect(service_provider_id);
    setSelectedVisitId(visit_id);
  };

  const handleCloseVisitPopup = () => {
    setSelectedVisitId(null);
  };

  if (!isLoaded) {
    return null;
  }

  return (
    <MapContainer>
      <GoogleMap
        zoom={!providersLoading && providers ? 11 : 14.5}
        options={{
          clickableIcons: false,
          controlSize: 20,
          disableDefaultUI: true,
          minZoom: 8,
          mapId: 'f4407895ddc41428',
          zoomControl: true
        }}
        mapContainerStyle={mapContainerStyle}
        onLoad={handleLoad}
        onUnmount={handleUnmount}>
        {!providersLoading &&
          upcomingVisits.map((visit) => (
            <VisitMarker
              key={`${visit.visit_id}_pro_marker`}
              selectedProId={selectedProvider?.id}
              selectedVisitId={selectedVisitId}
              onMarkerClick={handleMarkerClick}
              onClosePopup={handleCloseVisitPopup}
              onChangeHoveredVisit={onChangeHoveredVisit}
              isProHovered={visit.service_provider_id === hoveredProId}
              visit={visit}
            />
          ))}
        {Boolean(job?.id) && (
          <CustomerMarker
            job={job}
            position={customerCoordinates}
            closeModal={onCloseAssignModal}
            selectedProId={selectedProvider?.id}
          />
        )}
      </GoogleMap>
    </MapContainer>
  );
};

export default memo(Map);
