import React from 'react';
import { useDispatch, useNavigate, useSelector } from 'hooks';
import { closeModal } from 'store/actions';
import { selectModalData } from 'store/reducers/modal';
import modalTypes from 'constants/modalTypes';
import { ModalConfirmation } from 'components';

const DiscardChanges = () => {
  const dispatch = useDispatch();
  const modalData = useSelector(selectModalData);

  const handleCloseModal = () => {
    dispatch(closeModal(modalTypes.DISCARD_CHANGES));
  };
  const navigate = useNavigate();

  const handleConfirm = () => {
    navigate(-1);
    handleCloseModal();
  };

  return (
    <ModalConfirmation
      modalType={modalTypes.DISCARD_CHANGES}
      hideModal={handleCloseModal}
      title={`Do you want to continue campaign ${modalData?.hasEditing ? 'editing' : 'creation'}?`}
      primaryButton={{
        title: 'Discard Changes',
        onClick: handleConfirm
      }}
      secondaryButton={{
        title: 'Keep',
        onClick: handleCloseModal
      }}
    />
  );
};

export default DiscardChanges;
