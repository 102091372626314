import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { FieldValue } from '../styled';

export const BundledTaskDescription = styled.span`
  color: ${({ theme }) => theme.colors.INVOICE_TEXT};
  font-weight: 500;
  font-size: 13px;
  line-height: 1.31;
  margin: 0 0 16px 0;
  display: block;
  &:last-child {
    margin: 0;
  }
`;

export const FieldValueWithMargin = styled(FieldValue)`
  margin-right: 118px;
  white-space: pre-line;
`;

export const GrayBoldTextStyle = css`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.GRAY};
  font-size: 13px;
  line-height: 1.31;
`;

export const GrayBoldLink = styled(Link)`
  ${GrayBoldTextStyle}
  text-decoration: none;
  &:hover {
    color: ${({ theme }) => theme.colors.DARK_GREEN};
  }
`;

export const GrayBoldSpan = styled.span`
  ${GrayBoldTextStyle}
`;
