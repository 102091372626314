import { call, takeLatest, put } from 'redux-saga/effects';
import api_constants from 'constants/api';
import actionTypes from 'constants/actionTypes';
import { openModal } from 'store/actions/modal';
import modalTypes from 'constants/modalTypes';
import Api from 'services/ApiService';

function* deleteReward(action) {
  const { id } = action.payload;
  try {
    const response = yield call(Api.amplifyDel, `${api_constants.REWARD}/${id}`);
    if (response.error_message || response.err) {
      throw new Error(response?.err);
    }
    yield put({
      type: actionTypes.DELETE_REWARD_SUCCESS,
      payload: {
        id
      }
    });
    yield put(
      openModal(modalTypes.RESULT, {
        title: `Reward has been removed`
      })
    );
  } catch (err) {
    console.log(err);
  }
}

export default function* watchDeleteReward() {
  yield takeLatest(actionTypes.DELETE_REWARD_FETCH, deleteReward);
}
