import { call, takeEvery, put } from 'redux-saga/effects';

import { analyticPeriods } from 'common/enums/analytics';
import api_constants from 'constants/api';
import actionTypes from 'constants/actionTypes';
import errors from 'constants/errors';
import Api from 'services/ApiService';

function* getAnalyticMetric({ payload: { metricType, periodType = analyticPeriods.MONTH.TYPE, startDate, endDate } }) {
  let dateFilters = '';
  if (startDate) {
    dateFilters += `&period_start_gte=${startDate ?? ''}`;
  }
  if (endDate) {
    dateFilters += `&period_start_lte=${endDate ?? ''}`;
  }

  if (endDate || startDate) {
    dateFilters += '&is_custom=true';
  }

  try {
    const response = yield call(
      Api.amplifyGet,
      `${api_constants.ANALYTIC_METRICS}?data_type=${metricType}&period=${periodType}${dateFilters}`
    );

    if (!(response || response.results)) {
      throw new Error(errors.DEFAULT);
    }

    yield put({
      type: actionTypes.GET_ANALYTIC_METRIC_SUCCESS,
      payload: {
        metricType,
        data: response.results
      }
    });
  } catch (err) {
    yield put({
      type: actionTypes.GET_ANALYTIC_METRIC_ERROR,
      payload: { metricType }
    });
    console.log(err);
  }
}

export default function* watchGetAnalyticMetric() {
  yield takeEvery(actionTypes.GET_ANALYTIC_METRIC_FETCH, getAnalyticMetric);
}
