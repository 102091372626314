import { call, takeLatest, put } from 'redux-saga/effects';
import api_constants from 'constants/api';
import actionTypes from 'constants/actionTypes';
import modalTypes from 'constants/modalTypes';
import errors from 'constants/errors';

import Api from 'services/ApiService';
import { getBadges, openModal } from 'store/actions';
import { refreshTaskInfo } from 'store/actions/service';
import { getUpdatedMediaIds } from 'common/helpers/media';

function* updateQuotation({ payload: { data, files, hasSent } }) {
  const { id, service_request_id: task_id, is_accepted } = data;
  const { hide_modal, ...requestData } = data;
  try {
    const mediaIds = files?.length ? yield call(getUpdatedMediaIds, { files, hasMediaType: true }) : [];
    const response = yield call(Api.amplifyPut, `${api_constants.QUOTATION}/${id}`, {
      ...requestData,
      ...(!files ? {} : { media: mediaIds })
    });

    if (!response?.id) {
      throw new Error(response?.error_message || response?.err?.message || response?.err?.name || errors.DEFAULT);
    }

    yield put({
      type: actionTypes.UPDATE_QUOTATION_SUCCESS,
      payload: {
        ...response,
        is_quotation: true
      }
    });

    if (is_accepted && !hide_modal) {
      yield put(
        openModal(modalTypes.RESULT, {
          title: `The quote is approved`,
          text: 'The homeowner will be notified.'
        })
      );

      return;
    }

    // DEV-492. TODO: (unasigned) write without using response?.hide_modal
    if (!hide_modal) {
      yield put(
        openModal(modalTypes.RESULT, {
          title: `Quotation ${hasSent ? 'Sent' : 'Saved'}`,
          text: hasSent ? `The homeowner & pro will be notified.` : 'Changes are saved'
        })
      );
    }

    if (data?.is_moderated) {
      yield put(getBadges());
    }
  } catch (err) {
    yield put({
      type: actionTypes.UPDATE_QUOTATION_ERROR
    });
    yield put(
      openModal(modalTypes.ERROR, {
        errorMessage: err?.message || err?.name || errors.DEFAULT
      })
    );
    console.log(err);
  } finally {
    yield put(refreshTaskInfo({ task_id, silent: true, isPaymentInfo: true }));
  }
}

export default function* watchUpdateQuotation() {
  yield takeLatest(actionTypes.UPDATE_QUOTATION_FETCH, updateQuotation);
}
