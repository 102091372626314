import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { IconButton, LoadingDots } from 'components';
import { FieldValue, EditableLabelPrefix, StyledInput, IconMargin, SpinnerContainer, EditableValue } from './styled';
import { selectUpdateJobFieldLoading } from 'store/reducers/service';
import { inputConfigTypes } from './enums';

const EditableLabel = ({
  autocomplete = false,
  value = '',
  initialValues,
  type = 'TEXT',
  handleSubmit,
  onBlur,
  serviceInstanceLoading = false,
  sourceFieldLoading = false,
  selectedEditingField,
  name,
  invalid,
  disabled = false
}) => {
  const [isInputShowing, setIsInputShowing] = useState(false);
  const isValueEmpty = value === null || value === '';
  const fieldValueConfigs = {
    disabled,
    isValueEmpty,
    isInputShowing
  };

  useEffect(() => {
    if (isInputShowing && !serviceInstanceLoading && !sourceFieldLoading) {
      // hide input on finish loading
      hideInput();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceInstanceLoading, sourceFieldLoading]);

  const submitValue = (value) => {
    onBlur(value);
    if (value.inputField !== initialValues.inputField) {
      return;
    }
    // hide input if nothing changed
    hideInput();
  };

  const showInput = () => {
    setIsInputShowing(true);
  };

  const hideInput = () => {
    setIsInputShowing(false);
  };

  // default disabled empty state
  if (!isInputShowing & isValueEmpty && disabled) {
    return (
      <FieldValue {...fieldValueConfigs}>
        <>&mdash;</>
      </FieldValue>
    );
  }

  // default empty state
  if (!isInputShowing & isValueEmpty) {
    return (
      <FieldValue {...fieldValueConfigs} onClick={showInput}>
        Set up
      </FieldValue>
    );
  }

  // default state with edit button
  if (!isInputShowing) {
    return (
      <FieldValue {...fieldValueConfigs}>
        <div>
          <EditableValue disabled={disabled}>
            {Boolean(type === 'CURRENCY') ? '$' : ''}
            {value}
          </EditableValue>
          <IconMargin>{!disabled && <IconButton iconSize={12} iconName="editFill" onClick={showInput} />}</IconMargin>
        </div>
      </FieldValue>
    );
  }

  // loading
  if ((serviceInstanceLoading || sourceFieldLoading) && selectedEditingField === name) {
    return (
      <FieldValue {...fieldValueConfigs}>
        <SpinnerContainer name={name}>
          <LoadingDots
            left={['datetime_expected_completed', 'expected_value'].includes(name) ? -3 : 0}
            top={name === 'subcategory' ? 1 : 0}
          />
        </SpinnerContainer>
      </FieldValue>
    );
  }

  // editing state
  return (
    <FieldValue {...fieldValueConfigs}>
      {Boolean(type === 'CURRENCY') && <EditableLabelPrefix>$</EditableLabelPrefix>}
      <StyledInput
        {...(inputConfigTypes[type] || {})}
        props={{
          styletype: type,
          ...(invalid ? { invalid } : {})
        }}
        value={value}
        component="input"
        name="inputField"
        onBlur={handleSubmit(submitValue)}
        autoComplete={autocomplete ? 'on' : 'off'}
        autoFocus={true}
      />
    </FieldValue>
  );
};

EditableLabel.propTypes = {
  type: PropTypes.string
};

EditableLabel.defaultProps = {
  type: 'TEXT'
};

const mapStateToProps = (state, props) => ({
  form: props.name,
  initialValues: { inputField: props.value },
  // TODO: (unassigned) suggestion to cut this from redux, drop loading prop
  serviceInstanceLoading: selectUpdateJobFieldLoading(state)
});

export default connect(mapStateToProps)(reduxForm()(EditableLabel));
