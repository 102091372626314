import { call, takeLatest, put, select } from 'redux-saga/effects';
import api_constants from 'constants/api';
import actionTypes from 'constants/actionTypes';
import { saveLocationSearch } from 'store/actions/navigation';
import { isTestTasksVisible, selectFilteredGeoLocationList } from 'store/reducers/app';
import Api from 'services/ApiService';
import { formatSearchTerm } from 'common/helpers/queryParams';

function* getCustomerList(action) {
  const {
    payload: {
      page,
      order_by,
      ho_stage,
      search = '',
      operational_area_id,
      promo_code_id = null,
      date_created_gte = null,
      date_created_lte = null,
      is_full_active = null,
      is_cognito_active = null,
      is_pre_filled = null,
      is_dormant,
      is_early_promise,
      hasSavingQueryParams,
      menuItemType,
      locationSearch,
      cohort_value
    }
  } = action;
  const testTasksVisible = yield select(isTestTasksVisible);
  const filteredGeoLocationList = yield select(selectFilteredGeoLocationList);
  const opAreasIDString = operational_area_id
    ? operational_area_id
    : filteredGeoLocationList.length
    ? filteredGeoLocationList.join(',')
    : '';

  try {
    const response = yield call(
      Api.amplifyGet,
      `${api_constants.CUSTOMER}?page=${page}${order_by ? `&order_by=${order_by}` : ''}${
        search ? `&search=${formatSearchTerm(search)}` : ''
      }${opAreasIDString ? `&operational_area_id=${opAreasIDString}` : ''}${
        is_full_active !== null ? `&is_full_active=${Number(is_full_active)}` : ''
      }${is_cognito_active !== null ? `&is_cognito_active=${Number(is_cognito_active)}` : ''}${
        promo_code_id ? `&promo_code_id=${promo_code_id}` : ''
      }${date_created_gte ? `&date_created_gte=${date_created_gte}` : ''}${
        date_created_lte ? `&date_created_lte=${date_created_lte}` : ''
      }${is_pre_filled !== null ? `&is_pre_filled=${Number(is_pre_filled)}` : ''}${
        testTasksVisible ? '' : '&is_test=0'
      }${ho_stage === undefined ? '' : `&ho_stage=${ho_stage}`}${
        cohort_value === undefined ? '' : `&cohort_value=${cohort_value}`
      }${is_dormant === undefined ? '' : `&is_dormant=${is_dormant}`}${
        is_early_promise === undefined ? '' : `&is_early_promise=${is_early_promise}`
      }`
    );
    if (!response?.results) {
      throw new Error();
    }

    yield put({
      type: actionTypes.GET_CUSTOMER_LIST_SUCCESS,
      payload: {
        page,
        totalCount: response.count,
        users: response.results
      }
    });

    if (hasSavingQueryParams) {
      yield put(saveLocationSearch({ menuItemType, locationSearch }));
    }
  } catch (err) {
    yield put({
      type: actionTypes.GET_CUSTOMER_LIST_ERROR
    });
    console.log(err);
  }
}

export default function* watchGetCustomerList() {
  yield takeLatest(actionTypes.GET_CUSTOMER_LIST_FETCH, getCustomerList);
}
