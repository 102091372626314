import React from 'react';
import { useTheme } from 'hooks';
import { LoadingDots } from 'components';

import { NavLink } from './styled';

const Breadcrumb = ({ breadcrumbName, children, isActive, pathname, onClick, hasLoading, search }) => {
  const theme = useTheme();
  const active = isActive ? 'true' : undefined;

  const handlePreventDefault = (e) => {
    if (active) {
      e.preventDefault();
    }
    if (onClick) {
      onClick();
    }
  };

  return (
    <NavLink active={active} onClick={handlePreventDefault} to={`${pathname}${search ? `?${search}` : ''}`}>
      {hasLoading && <LoadingDots color={theme.colors.GRAY} />} {children || breadcrumbName}
    </NavLink>
  );
};

export default Breadcrumb;
