import React from 'react';

import { useSelector } from 'hooks';

import { selectServiceInstance } from 'store/reducers/service';
import { getAdminStatusNameByCode } from 'common/helpers/job';

import { DataRow, DataRecord, FieldTitle, TagsList, Tag } from '../styled';

const JobCoreInfo = () => {
  const job = useSelector(selectServiceInstance);

  return (
    <DataRow>
      <DataRecord>
        <FieldTitle>Stage</FieldTitle>
        <Tag capitalize>{getAdminStatusNameByCode(job.admin_status) || '-'}</Tag>
      </DataRecord>
      <DataRecord>
        <FieldTitle>Job Type</FieldTitle>
        <Tag>{job.job_type_name || '-'}</Tag>
      </DataRecord>
      <DataRecord>
        <FieldTitle>Request Category</FieldTitle>
        <Tag>{job.job_category_name || '-'}</Tag>
      </DataRecord>
      <DataRecord>
        {Boolean(job?.tag_list.length) && (
          <>
            <FieldTitle>Priority Listing</FieldTitle>
            <TagsList>
              {job.tag_list.map(({ id, name }) => (
                <Tag key={id}>{name}</Tag>
              ))}
            </TagsList>
          </>
        )}
      </DataRecord>
    </DataRow>
  );
};

export default JobCoreInfo;
