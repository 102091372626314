import React from 'react';
// import { userGroups } from 'common/enums/user';
// import { HookForm } from 'components';
import { Event, PushNotification } from './components';

export const CampaignSetUp = ({
  control,
  values,
  onSetExecutionDate,
  onSetIDs,
  onSetUploadedCSVFile,
  onScrollToEnd,
  onPushTitleChange
}) => {
  return (
    <>
      {/* <div style={{fontSize: '20px', marginTop: '28px'}}>
            Template settings
        </div>
        <div style={{ margin: '16px 0 24px 0' }}>
            <HookForm.Radio
                label="App*"
                name="group_id"
                disabled
                row
                gap="42px"
                control={control}
                rules={{ required: true }}
                options={[
                { name: userGroups.CUSTOMER.APP_NAME, value: userGroups.CUSTOMER.TYPE },
                { name: userGroups.PROVIDER.APP_NAME, value: userGroups.PROVIDER.TYPE, disabled: true }
                ]}
            />
        </div>
        <HookForm.Radio
            label="Campaign type*"
            name="campaignType"
            control={control}
            gap="60px"
            rules={{ required: true }}
            row
            options={[
                { name: 'Post', value: 1, disabled: true },
                { name: 'Chatty chat/push', value: 2 }
            ]}
        /> */}
      <PushNotification control={control} onPushTitleChange={onPushTitleChange} />
      <Event
        control={control}
        values={values}
        onSetExecutionDate={onSetExecutionDate}
        onSetIDs={onSetIDs}
        onSetUploadedCSVFile={onSetUploadedCSVFile}
        onScrollToEnd={onScrollToEnd}
      />
    </>
  );
};
