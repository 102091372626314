import { call, takeLatest, put } from 'redux-saga/effects';

import api_constants from 'constants/api';
import errors from 'constants/errors';
import actionTypes from 'constants/actionTypes';
import modalTypes from 'constants/modalTypes';
import { openModal } from 'store/actions';
import Api from 'services/ApiService';

function* deleteCampaign({ payload }) {
  const { campaignId, templateTitle, onCustomClose } = payload;
  try {
    // TODO: add deletion of media from s3
    const response = yield call(Api.amplifyDel, `${api_constants.CAMPAIGN}/${campaignId}`);

    if (response?.err) {
      throw new Error(response?.err.response?.data.error_message);
    }

    yield put({
      type: actionTypes.DELETE_CAMPAIGN_SUCCESS
    });

    yield put(
      openModal(modalTypes.RESULT, {
        onCustomClose: () => onCustomClose && onCustomClose(),
        title: `The "${templateTitle}"\ncampaign successfully deleted`
      })
    );
  } catch (err) {
    yield put({
      type: actionTypes.DELETE_CAMPAIGN_ERROR
    });

    yield put(
      openModal(modalTypes.ERROR, {
        errorMessage: err?.message || errors.DEFAULT
      })
    );
  }
}

export default function* watchDeleteCampaign() {
  yield takeLatest(actionTypes.DELETE_CAMPAIGN_FETCH, deleteCampaign);
}
