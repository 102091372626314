import React from 'react';
import { useTheme } from 'hooks';
import { Icon, StyledTooltip } from 'components';
import { AbsoluteWrapper, Info, Bullet, SubBullet } from './styled';

export const CustomFieldsTooltip = () => {
  const theme = useTheme();
  return (
    <AbsoluteWrapper>
      <StyledTooltip
        arrow
        placement="top-start"
        title={
          <div>
            Tips:
            <Info>
              <Bullet />
              {'insert {given_name} to specify the user’s first name'}
            </Info>
            <Info>
              <SubBullet />
              {'every empty {given_name} will be replaced with “there”'}
            </Info>
            <Info>
              <Bullet />
              {'insert {city} to specify the user’s city'}
            </Info>
          </div>
        }>
        <div>
          <Icon name="infoOutlined" color={theme.colors.DARK_GREY_2} />
        </div>
      </StyledTooltip>
    </AbsoluteWrapper>
  );
};
