import React from 'react';

import { useState } from 'hooks';
import { Button } from 'components';
import { Notes } from 'containers';

import { DataRow, DataRecord } from '../styled';

export const JobNotes = ({ jobId }) => {
  const [showAddNoteForm, setShowAddNoteForm] = useState(false);

  const toggleNoteForm = () => setShowAddNoteForm((value) => !value);

  return (
    <>
      <Notes
        type="service"
        showForm={showAddNoteForm}
        serviceId={jobId}
        openEditNoteForm={toggleNoteForm}
        closeEditNoteForm={toggleNoteForm}
        displayNotesLoading
      />
      <DataRow>
        <DataRecord margin="24px 0 24px 0">
          {!showAddNoteForm && (
            <Button variant="primary" onClick={toggleNoteForm}>
              Add Notes
            </Button>
          )}
        </DataRecord>
      </DataRow>
    </>
  );
};

export default JobNotes;
