import React from 'react';
import { Storage } from 'aws-amplify';

import { useEffect } from 'hooks';
import { Icon, LoadingSpinner } from 'components';

import { selectUserNoteListLoading, selectUserNoteList } from 'store/reducers/user';

import { formatDateShortTimeStamp } from 'utils';
import {
  NoteContainer,
  NoteData,
  NoteTitleRow,
  NoteTitle,
  NoteCreated,
  FilesFlexRow,
  NoteFile,
  NoteFileName,
  FieldTitle,
  LoadingContainerMedium,
  NoteListWrapper,
  ReminderWrapper
} from './styled';
import { getUserNoteList } from 'store/actions';
import { videoExtensions } from 'common/enums/media';
import { getUrlFileExtension } from 'common/helpers/media';
import styles from 'constants/styles';
import { useDispatch, useSelector } from 'hooks';

export const Notes = ({ userId, setIsPDFViewerShown, setIsLightBoxShown, setIsVideoPlayerShown, setMediaUrl }) => {
  const dispatch = useDispatch();
  const openAttachedFile = async (innerPath) => {
    const fileExt = getUrlFileExtension(innerPath || '');
    const signedUrl = await Storage.get(innerPath);

    setMediaUrl(signedUrl);
    const hasVideoPlayer = Object.values(videoExtensions).includes(fileExt);
    const hasPDFViewer = fileExt === '.pdf';
    const hasImageViewer = !hasVideoPlayer && !hasPDFViewer;

    setIsVideoPlayerShown(hasVideoPlayer);
    setIsPDFViewerShown(hasPDFViewer);
    setIsLightBoxShown(hasImageViewer);
  };

  useEffect(() => {
    if (userId === null) {
      return;
    }

    dispatch(getUserNoteList({ userId, noCallLogs: true }));
  }, [userId]);

  const noteList = useSelector(selectUserNoteList);
  const noteListLoading = useSelector(selectUserNoteListLoading);

  return (
    <div>
      {!!noteListLoading ? (
        <LoadingContainerMedium>
          <LoadingSpinner text="Please wait" type="logo" />
        </LoadingContainerMedium>
      ) : (
        <NoteListWrapper>
          <FieldTitle>Pro notes</FieldTitle>
          {noteList.map((note) => (
            <NoteContainer key={note.id}>
              <Icon name="comment" size={17} backgroundSize={17} color={styles.colors.DARK_4} />
              <div>
                <NoteData>
                  <NoteTitleRow>
                    <NoteTitle>{note.author?.given_name || `(${note.author?.id || 'Uploading'})`}</NoteTitle>
                    <NoteCreated>{formatDateShortTimeStamp(note.date_created)}</NoteCreated>
                  </NoteTitleRow>

                  {Boolean(note.description) && <NoteTitle>{note.description.trim()}</NoteTitle>}

                  {!!note.media?.length && (
                    <FilesFlexRow>
                      {note.media?.map((file) => (
                        <NoteFile key={file.id} clickable onClick={(e) => openAttachedFile(file.media.inner_path)}>
                          <Icon name="file" size={17} backgroundSize={17} />
                          <NoteFileName>{file.media.original_name || file.media.file_name}</NoteFileName>
                        </NoteFile>
                      ))}
                    </FilesFlexRow>
                  )}
                </NoteData>
                {!!note.reminder_counter && (
                  <ReminderWrapper margin="9px 0 0 20px">
                    <Icon name="time" size={18} />
                    {`Remaind in ${note.reminder_counter}h`}
                  </ReminderWrapper>
                )}
              </div>
            </NoteContainer>
          ))}
        </NoteListWrapper>
      )}
    </div>
  );
};

export default Notes;
