export const reportColumns = [
  {
    Header: 'Owner Name',
    accessor: 'Owner Name',
    width: 80
  },
  {
    Header: 'Owner Last Name',
    accessor: 'Owner Last Name',
    width: 80
  },
  {
    Header: 'Operational area',
    accessor: 'Operational area',
    width: 80
  },
  {
    Header: '# requested jobs',
    accessor: '# requested jobs',
    width: 80
  },
  {
    Header: '# ongoing jobs',
    accessor: '# ongoing jobs',
    width: 80
  },
  {
    Header: '# scheduled jobs',
    accessor: '# scheduled jobs',
    width: 80
  },
  {
    Header: '# completed jobs',
    accessor: '# completed jobs',
    width: 80
  },
  {
    Header: '# unfulfilled jobs',
    accessor: '# unfulfilled jobs',
    width: 80
  },
  {
    Header: '# duplicated jobs',
    accessor: '# duplicated jobs',
    width: 80
  },
  {
    Header: '# ongoing duplicated jobs',
    accessor: '# ongoing duplicated jobs',
    width: 80
  },
  {
    Header: '# scheduled duplicated jobs',
    accessor: '# scheduled duplicated jobs',
    width: 80
  },
  {
    Header: '# completed duplicated jobs',
    accessor: '# completed duplicated jobs',
    width: 80
  },
  {
    Header: '# unfulfilled duplicated jobs',
    accessor: '# unfulfilled duplicated jobs',
    width: 80
  },
  {
    Header: '# total jobs',
    accessor: '# total jobs',
    width: 80
  },
  {
    Header: '# ongoing total jobs',
    accessor: '# ongoing total jobs',
    width: 80
  },
  {
    Header: '# scheduled total jobs',
    accessor: '# scheduled total jobs',
    width: 80
  },
  {
    Header: '# completed total jobs',
    accessor: '# completed total jobs',
    width: 80
  },
  {
    Header: '# unfulfilled total jobs',
    accessor: '# unfulfilled total jobs',
    width: 80
  },
  {
    Header: 'Scheduled rate',
    accessor: 'scheduled rate',
    width: 80
  },
  {
    Header: 'Completion rate',
    accessor: 'completion rate',
    width: 80
  },
  {
    Header: '$ gross revenue',
    accessor: '$ gross revenue',
    width: 80,
    formatterName: 'dollarCurrencyInput'
  },
  {
    Header: '$ net revenue',
    accessor: '$ net revenue',
    width: 80,
    formatterName: 'dollarCurrencyInput'
  },
  {
    Header: '% net margin',
    accessor: '% net margin',
    width: 80,
    formatterName: 'percent'
  }
];
