import actionTypes from 'constants/actionTypes';

const defaultState = {
  list: [],
  listLoading: false,
  creationLoading: false,
  sendTestLoading: false,
  page: 1,
  totalCount: 0,
  instance: {},
  instanceLoading: false,
  deleteLoading: false,
  editLoading: false
};

function reducer(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.GET_CAMPAIGNS_LIST_FETCH: {
      return {
        ...state,
        listLoading: true
      };
    }

    case actionTypes.GET_CAMPAIGNS_LIST_SUCCESS: {
      const { page, totalCount, list = [] } = action.payload || {};
      return {
        ...state,
        list,
        listLoading: false,
        page,
        totalCount
      };
    }

    case actionTypes.GET_CAMPAIGNS_LIST_ERROR: {
      return {
        ...state,
        listLoading: false
      };
    }

    case actionTypes.GET_CAMPAIGN_BY_ID_FETCH: {
      return {
        ...state,
        instanceLoading: true
      };
    }

    case actionTypes.GET_CAMPAIGN_BY_ID_SUCCESS: {
      return {
        ...state,
        instance: action.payload || {},
        instanceLoading: false
      };
    }

    case actionTypes.GET_CAMPAIGN_BY_ID_ERROR: {
      return {
        ...state,
        instanceLoading: false
      };
    }

    case actionTypes.CREATE_CAMPAIGN_FETCH: {
      return {
        ...state,
        creationLoading: true
      };
    }
    case actionTypes.CREATE_CAMPAIGN_ERROR:
    case actionTypes.CREATE_CAMPAIGN_SUCCESS: {
      return {
        ...state,
        creationLoading: false
      };
    }

    case actionTypes.SEND_TEST_CAMPAIGN_EVENT_FETCH: {
      return {
        ...state,
        sendTestLoading: true
      };
    }

    case actionTypes.SEND_TEST_CAMPAIGN_EVENT_ERROR:
    case actionTypes.SEND_TEST_CAMPAIGN_EVENT_SUCCESS: {
      return {
        ...state,
        sendTestLoading: false
      };
    }

    case actionTypes.EDIT_CAMPAIGN_FETCH: {
      return {
        ...state,
        editLoading: true
      };
    }

    case actionTypes.EDIT_CAMPAIGN_SUCCESS: {
      return {
        ...state,
        editLoading: false
      };
    }

    case actionTypes.EDIT_CAMPAIGN_ERROR: {
      return {
        ...state,
        editLoading: false
      };
    }

    case actionTypes.DELETE_CAMPAIGN_FETCH: {
      return {
        ...state,
        deleteLoading: true
      };
    }

    case actionTypes.DELETE_CAMPAIGN_ERROR:
    case actionTypes.DELETE_CAMPAIGN_SUCCESS: {
      return {
        ...state,
        deleteLoading: false
      };
    }

    default:
      return state;
  }
}

export const selectSendTestLoading = (state) => state.campaigns.sendTestLoading;
export const selectCampaignsInstanceId = (state) => state.campaigns.instance?.id;
export const selectCampaignById = (state, id) => (state.campaigns.instance?.id === id ? state.campaigns.instance : {});
export const selectHasCampaignLoading = (state) => state.campaigns.instanceLoading;
export const selectCampaignsList = (state) => state.campaigns.list;
export const selectCampaignsListLoading = (state) => state.campaigns.listLoading;
export const selectCampaignsListTotalCount = (state) => state.campaigns.totalCount;
export const selectCampaignsListPage = (state) => state.campaigns.page;
export const selectCampaignCreationLoading = (state) => state.campaigns.creationLoading;
export const selectDeleteCampaignLoading = (state) => state.campaigns.deleteLoading;
export const selectEditCampaignLoading = (state) => state.campaigns.editLoading;

export default reducer;
