import React from 'react';
import { useCallback, useDispatch, useFilters, useMemo, useSelector } from 'hooks';

import { defaultAnalyticsParams } from 'common/enums/analytics';
import { siblingPaginationTypes } from 'common/enums/navigation';
import { csvFileTypes } from 'common/enums/app';
import routes from 'constants/routes';

import { getServiceList } from 'store/actions';
import { getColumns, getRows } from './helpers';
import { PaginationFullScreen, Table } from 'components';
import { selectServiceList, selectServiceListLoading, selectServiceTotalCount } from 'store/reducers/service';

const AnalyticsTask = () => {
  const dispatch = useDispatch();
  const list = useSelector(selectServiceList);
  const totalCount = useSelector(selectServiceTotalCount);
  const listLoading = useSelector(selectServiceListLoading);

  const handleFetchServiceList = useCallback(
    (updatedFilters = {}) => {
      dispatch(
        getServiceList({
          ...defaultAnalyticsParams,
          ...updatedFilters
        })
      );
    },
    [dispatch]
  );

  const { filters, handleChangePage, handleChangeSorting, handleRowClick, handleRowContextClick, handleDownloadCSV } =
    useFilters({
      csvFileType: csvFileTypes.TASK,
      onFetchList: handleFetchServiceList,
      listRoute: routes.ANALYTICS_JOBS,
      instanceRoute: routes.SERVICE,
      siblingPaginationType: siblingPaginationTypes.JOB
    });

  const columns = useMemo(() => getColumns({ onDownloadCSV: handleDownloadCSV }), [handleDownloadCSV]);
  const data = useMemo(() => getRows({ list }), [list]);

  return (
    <PaginationFullScreen
      count={totalCount}
      currentPage={Number(filters.page)}
      onPageChange={handleChangePage}
      length={list?.length}
      loading={listLoading}
      searchByParam={filters.search}>
      <Table
        onSort={handleChangeSorting}
        onRowClick={handleRowClick}
        onRowContextClick={handleRowContextClick}
        columns={columns}
        data={data}
        sortingDirection={filters.sorting_direction}
        sortingColumnName={filters.sorting_column}
      />
    </PaginationFullScreen>
  );
};

export default AnalyticsTask;
