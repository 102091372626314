import { apiPageSizes } from 'common/enums/queryParams';
import { unreadMessageSeparator } from 'common/enums/chat';
import actionTypes from 'constants/actionTypes';
import { renderSystemMessage, removeDuplicated } from 'utils';

const defaultState = {
  messageList: [],
  messageCount: 0,
  firstMessagesLoaded: false,
  activeTabIndex: 0,
  hasUnreadMessages: false,
  unreadSystemMessageIndex: 0,
  historyMessageLeftCount: 0,
  deleteTaskMessageLoading: false,
  updateTaskMessageLoading: false
};

function reducer(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.GET_TASK_MESSAGE_LIST_FETCH: {
      const { idGt, idLt, firstMessagesLoaded } = action.payload;
      return {
        ...state,
        ...(idGt || idLt
          ? {}
          : {
              messageList: [],
              messageCount: 0,
              historyMessageLeftCount: 0
            }),
        firstMessagesLoaded: firstMessagesLoaded
      };
    }

    case actionTypes.GET_TASK_MESSAGE_LIST_SUCCESS: {
      const { idGt, idLt, count, setIsOpen, messageList } = action.payload;
      const updatedState = {
        ...state,
        firstMessagesLoaded: true
      };

      // adding 1 page messages
      if (!idGt && !idLt) {
        return {
          ...updatedState,
          messageList: [...state.messageList.filter((message) => message._id.includes('-')), ...messageList],
          messageCount: count,
          historyMessageLeftCount: count - apiPageSizes.DEFAULT
        };
      }

      if (idLt) {
        return {
          ...updatedState,
          messageList: removeDuplicated([...state.messageList, ...messageList], '_id'),
          historyMessageLeftCount: count - apiPageSizes.DEFAULT
        };
      }

      // because of long pulling specific
      // backend sometimes gives us a corrupted message without media link and text when we're sending an image (it happens during executing post request)
      // that's why we need to remove it from the list
      const upToDateMessageState = messageList.filter((item) => item.image || item.text);
      const hasUnreadMessages =
        state.hasUnreadMessages ||
        (!setIsOpen && upToDateMessageState.some(({ is_system, admin_author_id }) => !admin_author_id && !is_system));

      const unreadSystemMessageIndex = (state.unreadSystemMessageIndex || 0) + upToDateMessageState.length;

      const updatedMessageList = [
        ...upToDateMessageState.map((newMessage) => {
          const localMessage = state.messageList.find(({ _id }) => newMessage._id === _id);
          return {
            ...newMessage,
            ...(localMessage?.media ? { media: localMessage?.media } : {})
          };
        }),
        ...state.messageList.filter((message) => message._id.includes('-') || Number(message._id) <= Number(idGt))
      ];

      return {
        ...updatedState,
        messageList: removeDuplicated(
          [
            ...updatedMessageList.slice(0, unreadSystemMessageIndex),
            ...(unreadSystemMessageIndex > 0 && (setIsOpen || state.activeTabIndex === 0)
              ? [
                  renderSystemMessage({
                    ...unreadMessageSeparator,
                    date_created: updatedMessageList[unreadSystemMessageIndex].createdAt
                  })
                ]
              : []),
            ...updatedMessageList.slice(unreadSystemMessageIndex)
          ],
          '_id'
        ),
        hasUnreadMessages,
        unreadSystemMessageIndex
      };
    }

    case actionTypes.GET_TASK_MESSAGE_LIST_ERROR: {
      return {
        ...state,
        messageListLoading: false
      };
    }

    case actionTypes.GET_TASK_LINK_PREVIEWS_SUCCESS: {
      const { message_id } = action.payload;
      const messageList = state.messageList.map((message) => {
        if (message._id !== message_id) {
          return message;
        }

        const updatedLinkList = message.link_list.map((link) => ({
          ...action.payload,
          ...link
        }));

        return {
          ...message,
          link_list: updatedLinkList,
          link_list_loaded: message.link_list_loaded + 1
        };
      });

      return {
        ...state,
        messageList
      };
    }

    case actionTypes.CREATE_TASK_MESSAGE_FETCH: {
      const { messageTransformed } = action.payload;
      const unreadSystemMessageIndex = state.unreadSystemMessageIndex;
      return {
        ...state,
        messageList: [messageTransformed, ...state.messageList],
        messageCount: state.messageCount + 1,
        ...(unreadSystemMessageIndex ? { unreadSystemMessageIndex: unreadSystemMessageIndex + 1 } : {})
      };
    }

    case actionTypes.GET_TASK_MESSAGE_BY_ID_SUCCESS:
    case actionTypes.CREATE_TASK_MESSAGE_SUCCESS: {
      const { transformedId, message } = action.payload;
      const isOutgoingMessageInList = state.messageList.some((item) => item._id === transformedId);
      if (!isOutgoingMessageInList) {
        return state;
      }

      // sometimes backend gives us in long pulling response a duplicated message
      // with media link, that's why we need to remove it from the list;
      // (it happens before post success)
      const upToDateMessageState = state.messageList.filter((item) => item._id !== message._id);
      const messageList = upToDateMessageState.map((item) =>
        item._id === transformedId ? { ...message, media: item?.media } : item
      );
      return {
        ...state,
        messageList,
        hasUnreadMessages: false
      };
    }

    case actionTypes.CREATE_TASK_MESSAGE_ERROR: {
      const { transformedId, message } = action.payload;
      const isMessageInList = state.messageList.some((item) => item._id === transformedId);

      if (!isMessageInList) {
        return state;
      }

      return {
        ...state,
        messageList: state.messageList.map((item) =>
          item._id === transformedId
            ? {
                ...message,
                deliveryError: true
              }
            : item
        )
      };
    }

    case actionTypes.RESET_TASK_UNREAD_MESSAGES_MARK: {
      return {
        ...state,
        hasUnreadMessages: false
      };
    }

    case actionTypes.RESET_TASK_UNREAD_MESSAGES_DIVIDER: {
      return {
        ...state,
        messageList: state.messageList.filter((message) => !message.system),
        unreadSystemMessageIndex: 0
      };
    }

    case actionTypes.CHANGE_ACTIVE_TAB: {
      return {
        ...state,
        activeTabIndex: action.payload
      };
    }

    case actionTypes.DELETE_TASK_MESSAGE_FETCH: {
      const { message } = action.payload;
      return {
        ...state,
        deleteTaskMessageLoading: true
      };
    }
    case actionTypes.DELETE_TASK_MESSAGE_SUCCESS: {
      const { message } = action.payload;
      return {
        ...state,
        deleteTaskMessageLoading: false,
        messageList: state.messageList.filter((item) => item._id !== message._id)
      };
    }
    case actionTypes.DELETE_TASK_MESSAGE_ERROR: {
      return {
        ...state,
        deleteTaskMessageLoading: false
      };
    }

    case actionTypes.UPDATE_TASK_MESSAGE_FETCH: {
      const { message } = action.payload;
      return {
        ...state,
        updateTaskMessageLoading: true,
        messageList: state.messageList.map((item) => (item._id === message._id ? message : item))
      };
    }
    case actionTypes.UPDATE_TASK_MESSAGE_SUCCESS: {
      const { message } = action.payload;
      return {
        ...state,
        updateTaskMessageLoading: false,
        messageList: state.messageList.map((item) => (item._id === message._id ? message : item))
      };
    }
    case actionTypes.UPDATE_TASK_MESSAGE_ERROR: {
      return {
        ...state,
        updateTaskMessageLoading: false
      };
    }

    default: {
      return state;
    }
  }
}

export const selectTaskMessageList = (state) => state.taskChat.messageList;
export const selectTaskMessageCount = (state) => state.taskChat.messageCount;
export const selectTaskUpdateMessageLoading = (state) => state.taskChat.updateTaskMessageLoading;
export const selectTaskDeleteMessageLoading = (state) => state.taskChat.deleteTaskMessageLoading;
export const selectTaskFirstMessagesLoaded = (state) => state.taskChat.firstMessagesLoaded;
export const selectActiveTabIndex = (state) => state.taskChat.activeTabIndex;
export const selectHasTaskChatUreadMessages = (state) => state.taskChat.hasUnreadMessages;
export const isTaskHistoryMessageLeft = (state) => state.taskChat.historyMessageLeftCount > 0;

export default reducer;
