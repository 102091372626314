import React from 'react';

import { useDispatch, useSelector } from 'hooks';
import { closeModal } from 'store/actions';
import { ModalConfirmation } from 'components';

import modalTypes from 'constants/modalTypes';
import { selectModalData } from 'store/reducers/modal';

const Confirmation = () => {
  const dispatch = useDispatch();
  const modalData = useSelector(selectModalData);
  const primaryButtonLoading = useSelector((state) =>
    modalData?.primaryButtonLoadingSelector ? modalData?.primaryButtonLoadingSelector(state) : false
  );
  const secondaryButtonLoading = useSelector((state) =>
    modalData?.secondaryButtonLoadingSelector ? modalData?.secondaryButtonLoadingSelector(state) : false
  );

  const handleSecondaryButtonPress = () => {
    dispatch(closeModal(modalTypes.CONFIRMATION));
  };

  const {
    title = '',
    text = '',
    onSecondaryButtonPress = handleSecondaryButtonPress,
    onPrimaryButtonPress,
    hasPrimaryButton,
    hasSecondaryButton,
    primaryButtonTitle = '',
    secondaryButtonTitle = 'Ok'
  } = modalData || {};

  return (
    <ModalConfirmation
      maxWidth={null}
      modalType={modalTypes.CONFIRMATION}
      smallPaddings={false}
      title={title}
      text={text}
      hideModal={() => closeModal(modalTypes.ERROR)}
      {...(hasSecondaryButton
        ? {
            secondaryButton: {
              title: secondaryButtonTitle,
              onClick: onSecondaryButtonPress,
              disabled: primaryButtonLoading || secondaryButtonLoading,
              loading: secondaryButtonLoading
            }
          }
        : {})}
      {...(hasPrimaryButton
        ? {
            primaryButton: {
              title: primaryButtonTitle,
              onClick: onPrimaryButtonPress,
              disabled: primaryButtonLoading || secondaryButtonLoading,
              loading: primaryButtonLoading
            }
          }
        : {})}
    />
  );
};

export default Confirmation;
