export const getCenteredBoundsLiteral = (bounds, center) => {
  // vertical bounds centering
  const northOffset = Math.abs(bounds.getNorthEast().lat() - center.lat);
  const southOffset = Math.abs(bounds.getSouthWest().lat() - center.lat);
  const latExtendValue = Math.abs(southOffset - northOffset);

  // horizontal bounds centering
  const westOffset = Math.abs(bounds.getNorthEast().lng() - center.lng);
  const eastOffset = Math.abs(bounds.getSouthWest().lng() - center.lng);
  const lngExtendValue = Math.abs(westOffset - eastOffset);

  const latLngBoundsLiteral = {
    north: bounds.getNorthEast().lat() + (southOffset > northOffset ? latExtendValue : 0),
    east: bounds.getNorthEast().lng() + (eastOffset > westOffset ? lngExtendValue : 0),
    south: bounds.getSouthWest().lat() - (southOffset > northOffset ? 0 : latExtendValue),
    west: bounds.getSouthWest().lng() - (eastOffset > westOffset ? 0 : lngExtendValue)
  };

  return latLngBoundsLiteral;
};
