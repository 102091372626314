import { call, takeEvery, put } from 'redux-saga/effects';

import api_constants from 'constants/api';
import actionTypes from 'constants/actionTypes';
import modalTypes from 'constants/modalTypes';
import errors from 'constants/errors';
import { openModal } from 'store/actions';
import { refreshTaskInfo } from 'store/actions/service';
import Api from 'services/ApiService';

function* scheduleVisit(action) {
  const {
    payload: { task_id, visit }
  } = action;
  try {
    const response = yield call(Api.amplifyPost, `${api_constants.SERVICE}/${task_id}/operator_visit`, visit);
    if (!response || !response?.id || response.err) {
      throw new Error(errors.DEFAULT);
    }
    yield put({
      type: actionTypes.SCHEDULE_VISIT_SUCCESS
    });

    yield put(refreshTaskInfo({ task_id, silent: true }));
    yield put(
      openModal(modalTypes.RESULT, {
        title: 'Visit is scheduled',
        text: 'All parties will be notified.'
      })
    );
  } catch (err) {
    yield put(
      openModal(modalTypes.ERROR, {
        errorMessage: errors.DEFAULT
      })
    );
    yield put({
      type: actionTypes.SCHEDULE_VISIT_ERROR,
      payload: err
    });
  }
}

export default function* watchScheduleVisit() {
  yield takeEvery(actionTypes.SCHEDULE_VISIT_FETCH, scheduleVisit);
}
