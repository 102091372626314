import React from 'react';

import { useCallback, useDispatch, useFilters, useMemo, useSelector } from 'hooks';

import { defaultQueryParams } from 'common/enums/queryParams';
import { siblingPaginationTypes } from 'common/enums/navigation';
import routes from 'constants/routes';
import { getCustomerList } from 'store/actions/customer';
import {
  selectCustomerListError,
  selectCustomerList,
  selectCustomerListLoading,
  selectCustomersTotalCount
} from 'store/reducers/customer';
import { getColumns, getRows } from './helpers';

import { PaginationFullScreen, Table } from 'components';

const CustomerDashboard = ({ isNested }) => {
  const dispatch = useDispatch();
  const list = useSelector(selectCustomerList);
  const totalCount = useSelector(selectCustomersTotalCount);
  const listLoading = useSelector(selectCustomerListLoading);
  const hasError = useSelector(selectCustomerListError);

  const handleFetchCustomerList = useCallback(
    (updatedFilters = {}) => {
      dispatch(
        getCustomerList({
          ...defaultQueryParams,
          ...updatedFilters
        })
      );
    },
    [dispatch]
  );

  const { filters, handleChangePage, handleChangeSorting, handleRowClick, handleRowContextClick } = useFilters({
    onFetchList: handleFetchCustomerList,
    instanceRoute: routes.CUSTOMER,
    siblingPaginationType: siblingPaginationTypes.CUSTOMER,
    totalCount,
    hasActiveMenuItemChange: isNested
  });

  const columns = useMemo(() => getColumns({ menuItem: filters.menu_item }), [filters.menu_item]);
  const data = useMemo(() => getRows({ list }), [list]);

  return (
    <PaginationFullScreen
      count={totalCount}
      currentPage={Number(filters.page)}
      onPageChange={handleChangePage}
      length={list?.length}
      loading={listLoading}
      searchTerm={filters.search}
      hasError={hasError}
      hasNavigationTabs={!filters.promo_code_id}>
      <Table
        onSort={handleChangeSorting}
        onRowClick={handleRowClick}
        onRowContextClick={handleRowContextClick}
        searchTerm={filters.search}
        columns={columns}
        data={data}
        sortingDirection={filters.sorting_direction}
        sortingColumnName={filters.sorting_column}
      />
    </PaginationFullScreen>
  );
};

export default CustomerDashboard;
