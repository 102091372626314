import { call, takeLatest, put } from 'redux-saga/effects';
import api_constants from 'constants/api';
import actionTypes from 'constants/actionTypes';
import Api from 'services/ApiService';

function* getTwilioAccessToken(action) {
  const { setDeviceToken } = action.payload;

  try {
    const response = yield call(Api.amplifyPost, `${api_constants.TWILIO_VOICE}/token`);
    if (!response) {
      throw new Error();
    }

    const { access_token } = response;
    setDeviceToken({ accessToken: access_token });
  } catch (err) {
    console.log(err);
  }
}

export default function* watchGetTwilioAccessToken() {
  yield takeLatest(actionTypes.GET_TWILIO_VOICE_TOKEN, getTwilioAccessToken);
}
