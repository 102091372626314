import React, { useRef, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import StaticDatePicker from '@mui/lab/StaticDatePicker';
import DateAdapter from '@mui/lab/AdapterMoment';

import { closeModal, scheduleVisit } from 'store/actions';
import modalTypes from 'constants/modalTypes';
import styles from 'constants/styles';
import dates from 'constants/dates';

import { generateTimesArray, getStartTimeInitialId } from './helpers';

import { Button, Dropdown, Modal, IconButton } from 'components';
import {
  ButtonsWrapper,
  ModalTitle,
  ContentWrapper,
  Line,
  SecondLine,
  TimeWrapper,
  RelativeWrapper,
  dropdownMenuStyle,
  Divider
} from './styled';
import { selectModalData } from 'store/reducers/modal';

const ExecutionDate = () => {
  const modalData = useSelector(selectModalData);
  const startValues = useRef(generateTimesArray()).current;

  const [dateVisit, setDateVisit] = useState(moment('00:00', dates.TIME_24));
  const [timeVisitId, setTimeVisitId] = useState(getStartTimeInitialId({ times: startValues, date: modalData.date }));
  const dispatch = useDispatch();
  const hideModal = () => {
    dispatch(closeModal(modalTypes.EXECUTION_DATE));
  };

  const onScheduleClick = () => {
    const dateString = dateVisit.format(dates.DATE_ISO);
    const date = moment(dateString + 'T' + startValues.find(({ id }) => id === timeVisitId)?.value);

    modalData.setDate(date.format(modalData.allUsers ? 'YYYY-MM-DD' : 'YYYY-MM-DD HH:mm'));
  };

  const handleTimeVisitChange = (itemIndex) => {
    if (itemIndex === timeVisitId) {
      return;
    }

    setTimeVisitId(itemIndex);
  };

  const handleDateVisitChange = (value) => {
    setDateVisit(value);
  };

  return (
    <Modal
      modalType={modalTypes.EXECUTION_DATE}
      hideModal={hideModal}
      padding="0"
      maxHeight={608}
      minWidth={1}
      maxWidth={548}>
      <ModalTitle>Set execution date</ModalTitle>
      <ContentWrapper>
        <LocalizationProvider dateAdapter={DateAdapter}>
          <RelativeWrapper>
            <StaticDatePicker
              value={modalData.date || dateVisit}
              onChange={handleDateVisitChange}
              minDate={moment()}
              renderInput={() => null}
              showDaysOutsideCurrentMonth
              disableCloseOnSelect
              reduceAnimations
              displayStaticWrapperAs="desktop"
              views={['day']}
              components={{
                RightArrowButton: ({ onClick }) => <IconButton onClick={onClick} iconName="arrowFillRight" />,
                LeftArrowButton: ({ onClick }) => <IconButton onClick={onClick} iconName="arrowFillLeft" />
              }}
            />
          </RelativeWrapper>
          <SecondLine />
          {!modalData.allUsers && (
            <TimeWrapper>
              <Dropdown
                value={startValues.find(({ id }) => timeVisitId === id)}
                containerBackground={styles.colors.LIGHT_GRAY_2}
                type="square"
                placeholder="Select time"
                options={startValues}
                onChange={handleTimeVisitChange}
                width={372}
                maxHeight={215}
                fill
                centered
                dropdownMenuStyle={dropdownMenuStyle}
                scrollToItemBlock="center"
              />
            </TimeWrapper>
          )}
        </LocalizationProvider>
        <ButtonsWrapper>
          <Button variant="primary" onClick={hideModal}>
            Cancel
          </Button>
          <Divider />
          <Button onClick={onScheduleClick} disabled={!dateVisit.isValid()}>
            Select
          </Button>
        </ButtonsWrapper>
      </ContentWrapper>
    </Modal>
  );
};

export default ExecutionDate;
