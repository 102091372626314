import React from 'react';

import { useCallback, useDispatch, useFilters, useSelector, useMemo } from 'hooks';
import { useCustomFilters } from 'navigation/hooks';
import { getColumns, getRows } from './helpers';

import { siblingPaginationTypes } from 'common/enums/navigation';
import { defaultQueryParams } from 'common/enums/queryParams';
import { columnNames } from 'common/enums/sortings';
import routes from 'constants/routes';
import { csvFileTypes } from 'common/enums/app';

import { getQuotationList } from 'store/actions';
import { selectQuotes } from 'store/reducers/invoice';
import { selectActiveMenuItem } from 'store/reducers/navigation';

import { PaginationFullScreen, Table } from 'components';

export const Quotes = () => {
  const dispatch = useDispatch();
  const activeMenuItem = useSelector(selectActiveMenuItem);
  const { instances: list, count: totalCount, loading: listLoading } = useSelector(selectQuotes);
  const { hasCustomFilters } = useCustomFilters();

  const handleFetchTaskList = useCallback(
    (updatedFilters = {}) => {
      dispatch(
        getQuotationList({
          ...defaultQueryParams,
          ...updatedFilters
        })
      );
    },
    [dispatch]
  );

  const { filters, handleChangePage, handleChangeSorting, handleDownloadCSV, handleRowClick, handleRowContextClick } =
    useFilters({
      csvFileType: csvFileTypes.QUOTATION,
      onFetchList: handleFetchTaskList,
      instanceRoute: routes.SERVICE,
      instanceIdFieldName: columnNames.JOB_ID,
      siblingPaginationType: siblingPaginationTypes.QUOTATION_JOB,
      totalCount
    });

  const columns = useMemo(
    () => getColumns({ tabId: filters.tab_id, onDownloadCSV: handleDownloadCSV }),
    [filters.tab_id, handleDownloadCSV]
  );
  const data = useMemo(() => getRows({ list }), [list]);

  return (
    <PaginationFullScreen
      count={totalCount}
      currentPage={Number(filters.page)}
      onPageChange={handleChangePage}
      length={list?.length}
      loading={listLoading}
      searchTerm={filters.search}
      hasNavigationTabs={activeMenuItem.HAS_TABS}
      hasFilters={hasCustomFilters}>
      <Table
        onSort={handleChangeSorting}
        onRowClick={handleRowClick}
        onRowContextClick={handleRowContextClick}
        searchTerm={filters.search}
        columns={columns}
        data={data}
        sortingDirection={filters.sorting_direction}
        sortingColumnName={filters.sorting_column}
      />
    </PaginationFullScreen>
  );
};

export default Quotes;
