export const getRows = ({ list }) =>
  list.map((review) => ({
    id: review.id,
    service_demand_id: review.service_demand_id,
    will_work_again: review.will_work_again,
    date_created: review.date_created,
    customer_given_name: review.user?.full_name,
    customer_id: review.user_id,
    provider_given_name: review.provider?.full_name,
    provider_id: review.service_provider_id,
    pro_stage: review.provider?.pro_stage,
    description: review.comment,
    operational_area_id: review.service_request?.operational_area_id,
    hasRowHighlight: review.is_status_badge
  }));
