import React from 'react';

import { navigationTabs } from 'common/enums/navigation';
import { useRouter } from 'hooks';

import ReferralDashboard from '../../pages/ReferralDashboard';
import CustomerDashboard from '../../pages/CustomerDashboard';

const CustomerTabs = () => {
  const { queryParams } = useRouter();
  const filters = queryParams.getAll();

  if (navigationTabs.REFERRAL.TYPE === filters.tab_id) {
    return <ReferralDashboard />;
  }

  if (!filters.tab_id) {
    return null;
  }

  return <CustomerDashboard />;
};

export default CustomerTabs;
