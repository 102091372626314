import { call, takeEvery, put } from 'redux-saga/effects';
import api_constants from 'constants/api';
import actionTypes from 'constants/actionTypes';
import Api from 'services/ApiService';
import { getBadges } from 'store/actions';

function* updateConversation(action) {
  const data = action.payload;
  const { id } = data;

  try {
    const response = yield call(Api.amplifyPut, `${api_constants.COMMUNICATION_SMS}/conversation/${id}`, data);
    if (!response) {
      throw new Error();
    }

    yield put({
      type: actionTypes.UPDATE_CONVERSATION_SUCCESS,
      payload: response
    });

    yield put(getBadges());
  } catch (err) {
    yield put({
      type: actionTypes.UPDATE_CONVERSATION_ERROR
    });
    console.log(err);
  }
}

export default function* watchUpdateConversation() {
  yield takeEvery(actionTypes.UPDATE_CONVERSATION_FETCH, updateConversation);
}
