import { cellTypes } from 'common/enums/table';
import { campaignsTable } from '../enums';
import { formatDateTimeStamp, formatLocalDateTimeStamp } from 'utils/transformers';
import styles from 'constants/styles';
import { getCohortValue } from 'common/helpers/campaigns';
import { searchHighlightTypes } from 'common/enums/app';

export const getColumns = () => {
  return [
    {
      id: campaignsTable.ID.FILTER_NAME,
      title: campaignsTable.ID.TITLE,
      cellType: cellTypes.TEXT,
      minWidth: 80,
      accessor: ({ id }) => ({
        text: String(id)
      })
    },
    {
      id: campaignsTable.TITLE.FILTER_NAME,
      title: campaignsTable.TITLE.TITLE,
      cellType: cellTypes.TEXT,
      minWidth: 250,
      accessor: ({ title }) => ({
        hasHighlight: true,
        highlightType: searchHighlightTypes.FULL_TEXT,
        text: title
      })
    },
    {
      id: campaignsTable.DATETIME_CREATED.FILTER_NAME,
      title: campaignsTable.DATETIME_CREATED.TITLE,
      cellType: cellTypes.TEXT,
      minWidth: 175,
      accessor: ({ date_created }) => ({
        text: formatDateTimeStamp(date_created)
      })
    },
    {
      id: campaignsTable.DATETIME_EXECUTE.FILTER_NAME,
      title: campaignsTable.DATETIME_EXECUTE.TITLE,
      cellType: cellTypes.TEXT,
      minWidth: 175,
      accessor: ({ datetime_execute, is_executed }) => ({
        ...(is_executed || !datetime_execute ? {} : { textColor: styles.colors.DARK_GREEN }),
        text: datetime_execute ? formatLocalDateTimeStamp(datetime_execute) : '-'
      })
    },
    {
      id: campaignsTable.COHORT.FILTER_NAME,
      title: campaignsTable.COHORT.TITLE,
      cellType: cellTypes.TEXT,
      minWidth: 250,
      hasSorting: false,
      accessor: ({ cohort_type, csv_cohort_name, execute_area_list, recipients_list }) => ({
        text: getCohortValue({ cohort_type, csv_cohort_name, service_areas: execute_area_list, recipients_list }) || '-'
      })
    },
    {
      id: campaignsTable.ARTICLE_AREA_TO_DISPLAY.FILTER_NAME,
      title: campaignsTable.ARTICLE_AREA_TO_DISPLAY.TITLE,
      cellType: cellTypes.TEXT,
      minWidth: 250,
      hasSorting: false,
      accessor: ({ article_area_list }) => ({
        text: !article_area_list?.length
          ? 'All Install Base'
          : article_area_list?.includes('ALL')
          ? 'All Install Base'
          : `${article_area_list?.join(', ')}${article_area_list?.length ? ';' : ''}` || '-'
      })
    }
    // TODO DEV-1536
    // {
    //   id: campaignsTable.TOTAL_SENT.FILTER_NAME,
    //   title: campaignsTable.TOTAL_SENT.TITLE,
    //   cellType: cellTypes.TEXT,
    //   minWidth: 80,
    //   accessor: ({ count_audience }) => ({
    //     hasHighlight: true,
    //     text: count_audience
    //   })
    // },
    // {
    //   id: campaignsTable.TOTAL_VIEWS.FILTER_NAME,
    //   title: campaignsTable.TOTAL_VIEWS.TITLE,
    //   cellType: cellTypes.TEXT,
    //   minWidth: 80,
    //   accessor: ({ count_recieved }) => ({
    //     hasHighlight: true,
    //     text: count_recieved
    //   })
    // },
    // {
    //   id: campaignsTable.JOBS_CREATED.FILTER_NAME,
    //   title: campaignsTable.JOBS_CREATED.TITLE,
    //   cellType: cellTypes.TEXT,
    //   minWidth: 80,
    //   accessor: ({ count_jobs_created }) => ({
    //     hasHighlight: true,
    //     text: count_jobs_created
    //   })
    // }
  ];
};
