import React from 'react';

import { Icon, LoadingDots } from 'components';
import styles from 'constants/styles';

import { SpinnerContainer, CallButton, MuteButton, CallEndButton } from './styled';

const VoiceControls = ({ isReady, isInitiated, isOngoing, isMuted, onMuteChange, onCallStart, onCallEnd }) => {
  if (!isReady) {
    return (
      <SpinnerContainer>
        <LoadingDots left={0} top={1} />
      </SpinnerContainer>
    );
  }
  if (!isOngoing) {
    return <Icon name="call2" onClick={onCallStart} disabled={isInitiated} clickable size={40} />;
  }
  return (
    <>
      <Icon name={isMuted ? 'microphoneCross2' : 'microphone2'} size={40} onClick={onMuteChange} clickable />

      <Icon name="callEnd2" onClick={onCallEnd} clickable size={40} margin="0 0 0 16px" />
    </>
  );
};

export default VoiceControls;
