import React from 'react';

import { useCallback, useDispatch, useFilters, useSelector, useMemo } from 'hooks';
import { useCustomFilters } from 'navigation/hooks';
import { getColumns, getRows } from './helpers';

import { sidebarMenuItems, siblingPaginationTypes } from 'common/enums/navigation';
import { defaultQueryParams } from 'common/enums/queryParams';
import routes from 'constants/routes';
import modalTypes from 'constants/modalTypes';

import { getServiceList, openModal } from 'store/actions';
import {
  selectServiceList,
  selectServiceListLoading,
  selectServiceTotalCount,
  selectServiceListError
} from 'store/reducers/service';
import { selectActiveMenuItem } from 'store/reducers/navigation';

import { PaginationFullScreen, Table } from 'components';

export const ServiceDashboard = () => {
  const dispatch = useDispatch();
  const activeMenuItem = useSelector(selectActiveMenuItem);
  const list = useSelector(selectServiceList);
  const listLoading = useSelector(selectServiceListLoading);
  const totalCount = useSelector(selectServiceTotalCount);
  const hasError = useSelector(selectServiceListError);

  const { hasCustomFilters } = useCustomFilters();

  const hasCustomRowClick = useCallback(
    (instance) =>
      activeMenuItem.TYPE === sidebarMenuItems.REQUESTED.TYPE &&
      !instance?.service_provider_id &&
      !instance?.was_assigned_to_pro,
    [activeMenuItem.TYPE]
  );

  const handleFetchTaskList = useCallback(
    (updatedFilters = {}) => {
      dispatch(
        getServiceList({
          ...defaultQueryParams,
          ...updatedFilters
        })
      );
    },
    [dispatch]
  );

  const handleCustomRowClick = (instance) => {
    dispatch(
      openModal(modalTypes.ASSIGN_PROVIDER_MAP, {
        jobId: instance.id,
        serviceArea: instance?.service_area
      })
    );
  };

  const { filters, handleChangePage, handleChangeSorting, handleRowClick, handleRowContextClick } = useFilters({
    onFetchList: handleFetchTaskList,
    hasCustomRowClick,
    handleCustomRowClick,
    instanceRoute: routes.SERVICE,
    siblingPaginationType: siblingPaginationTypes.JOB,
    totalCount
  });

  const columns = useMemo(
    () => getColumns({ menuItemType: activeMenuItem.TYPE, tabId: filters.tab_id }),
    [activeMenuItem.TYPE, filters.tab_id]
  );
  const data = useMemo(() => getRows({ list }), [list]);

  return (
    <PaginationFullScreen
      count={totalCount}
      currentPage={Number(filters.page)}
      onPageChange={handleChangePage}
      length={list?.length}
      loading={listLoading}
      searchTerm={filters.search}
      hasNavigationTabs={activeMenuItem.HAS_TABS}
      hasError={hasError}
      hasFilters={hasCustomFilters}>
      <Table
        onSort={handleChangeSorting}
        onRowClick={handleRowClick}
        onRowContextClick={handleRowContextClick}
        searchTerm={filters.search}
        columns={columns}
        data={data}
        sortingDirection={filters.sorting_direction}
        sortingColumnName={filters.sorting_column}
      />
    </PaginationFullScreen>
  );
};

export default ServiceDashboard;
