import { call, takeLatest, put, select } from 'redux-saga/effects';
import api_constants from 'constants/api';
import actionTypes from 'constants/actionTypes';
import Api from 'services/ApiService';

import { updateConversation } from 'store/actions/communication';
import { selectCommunicationListByUserId } from 'store/reducers/communication';
import { generateQueryParams } from 'common/helpers/httpRequest';
import { smsMessageTransformer } from 'utils';

function* getCommunicationSmsList({ payload: { page = 1, id_lt, user_id, hasConversationBadgeUpdate } }) {
  try {
    const filterParams = {
      ...(id_lt ? { id_lt } : { page }),
      user_id
    };
    const response = yield call(
      Api.amplifyGet,
      `${api_constants.COMMUNICATION_SMS}${generateQueryParams(filterParams)}`
    );
    if (!response?.results) {
      throw new Error();
    }

    const messageList = response.results.map((item) => smsMessageTransformer(item));
    yield put({
      type: actionTypes.GET_COMMUNICATION_SMS_LIST_SUCCESS,
      payload: {
        user_id,
        id_lt,
        page,
        count: response.count,
        list: messageList
      }
    });

    if (!hasConversationBadgeUpdate) {
      return;
    }

    const conversation = yield select((state) => selectCommunicationListByUserId(state, user_id));
    if (conversation?.id) {
      yield put(updateConversation({ id: conversation?.id, is_status_badge: false }));
    }
  } catch (err) {
    yield put({
      type: actionTypes.GET_COMMUNICATION_SMS_LIST_ERROR,
      payload: {
        user_id
      }
    });
    console.log(err);
  }
}

export default function* watchGetCommunicationSmsList() {
  yield takeLatest(actionTypes.GET_COMMUNICATION_SMS_LIST_FETCH, getCommunicationSmsList);
}
