import actionTypes from 'constants/actionTypes';

const defaultState = {
  loading: false,
  list: [],
  count: null
};

function reducer(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.GET_ADMIN_LIST_FETCH: {
      return {
        ...state,
        loading: true
      };
    }

    case actionTypes.GET_ADMIN_LIST_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    }

    case actionTypes.GET_ADMIN_LIST_ERROR: {
      return {
        ...state,
        loading: false
      };
    }

    default:
      return state;
  }
}

export const selectAdminList = (state) => state.admin.list;
export const selectAdminListLoading = (state) => state.admin.loading;
export const selectAdminCount = (state) => state.admin.count;

export default reducer;
