import styled from 'styled-components';
import { Link as ReactRouterLink } from 'react-router-dom';

export const BundledTasks = styled.span`
  color: ${({ theme }) => theme.colors.GRAY};
  font-size: 12px;
  font-weight: 600;
  height: 24px;
  margin-left: 6px;
`;

export const Link = styled(ReactRouterLink)`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.GRAY};
  &:hover {
    color: ${({ theme }) => theme.colors.DARK_GREEN};
  }
`;
