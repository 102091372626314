import { apiPageSizes } from 'common/enums/queryParams';
import { unreadMessageSeparator } from 'common/enums/chat';
import actionTypes from 'constants/actionTypes';
import { renderSystemMessage, removeDuplicated } from 'utils';

const defaultState = {
  supportChats: {},
  activeTabIndex: 0,
  updateMessageLoading: false,
  deleteMessageLoading: false
};

function reducer(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.GET_SUPPORT_MESSAGE_LIST_FETCH: {
      const { idGt, idLt, userId, firstMessagesLoaded } = action.payload;
      return {
        ...state,
        supportChats: {
          ...state.supportChats,
          [userId]: {
            ...state.supportChats[userId],
            firstMessagesLoaded: firstMessagesLoaded,
            ...(idGt || idLt
              ? {}
              : {
                  messageList: [],
                  messageCount: 0,
                  historyMessageLeftCount: 0
                })
          }
        }
      };
    }

    case actionTypes.GET_SUPPORT_MESSAGE_LIST_SUCCESS: {
      const { idGt, idLt, userId, count, setIsOpen, messageList } = action.payload;

      // adding 1 page messages
      if (!idGt && !idLt) {
        return {
          ...state,
          supportChats: {
            ...state.supportChats,
            [userId]: {
              ...state.supportChats[userId],
              messageList: [
                ...state.supportChats[userId].messageList.filter((message) => message._id.includes('-')),
                ...messageList
              ],
              messageCount: count,
              historyMessageLeftCount: count - apiPageSizes.DEFAULT,
              firstMessagesLoaded: true
            }
          }
        };
      }

      // adding history messages
      if (idLt) {
        return {
          ...state,
          supportChats: {
            ...state.supportChats,
            [userId]: {
              ...state.supportChats[userId],
              messageList: removeDuplicated([...state.supportChats[userId].messageList, ...messageList], '_id'),
              historyMessageLeftCount: count - apiPageSizes.DEFAULT,
              firstMessagesLoaded: true
            }
          }
        };
      }

      // because of long pulling specific
      // backend sometimes gives us a corrupted message without media link and text when we're sending an image (it happens during executing post request)
      // that's why we need to remove it from the list
      const upToDateMessageState = messageList.filter((item) => item.image || item.text);

      // adding new messages
      const hasUnreadMessages =
        state.supportChats[userId].hasUnreadMessages ||
        (!setIsOpen && upToDateMessageState.some(({ is_system, admin_author_id }) => !admin_author_id && !is_system));

      const unreadSystemMessageIndex =
        (state.supportChats[userId].unreadSystemMessageIndex || 0) + upToDateMessageState.length;

      // create array with new received messages + (messages sending + messages with less idGt)
      const updatedMessageList = [
        ...upToDateMessageState.map((newMessage) => {
          const localMessage = state.supportChats[userId].messageList.find(({ _id }) => newMessage._id === _id);
          return {
            ...newMessage,
            ...(localMessage?.media ? { media: localMessage?.media } : {})
          };
        }),
        ...state.supportChats[userId].messageList.filter(
          (message) => message._id.includes('-') || Number(message._id) <= Number(idGt)
        )
      ];

      return {
        ...state,
        supportChats: {
          ...state.supportChats,
          [userId]: {
            ...state.supportChats[userId],
            firstMessagesLoaded: true,
            messageList: removeDuplicated(
              [
                ...updatedMessageList.slice(0, unreadSystemMessageIndex),
                ...(unreadSystemMessageIndex > 0 &&
                (setIsOpen || state.activeTabIndex === 1 || state.activeTabIndex === 2)
                  ? [
                      renderSystemMessage({
                        ...unreadMessageSeparator,
                        date_created: updatedMessageList[unreadSystemMessageIndex]?.createdAt
                      })
                    ]
                  : []),
                ...updatedMessageList.slice(unreadSystemMessageIndex)
              ],
              '_id'
            ),
            hasUnreadMessages,
            unreadSystemMessageIndex
          }
        }
      };
    }

    case actionTypes.GET_SUPPORT_MESSAGE_LIST_ERROR: {
      const { userId } = action.payload;
      return {
        ...state,
        supportChats: {
          ...state.supportChats,
          [userId]: {
            ...state.supportChats[userId],
            messageListLoading: false
          }
        }
      };
    }

    case actionTypes.GET_SUPPORT_LINK_PREVIEWS_SUCCESS: {
      const {
        userId,
        updatedPreview,
        updatedPreview: { message_id }
      } = action.payload;

      const messageList = state.supportChats[userId]?.messageList?.map((message) => {
        if (message._id !== message_id) {
          return message;
        }

        const updatedLinkList = message.link_list.map((link) => ({
          ...updatedPreview,
          ...link
        }));

        return {
          ...message,
          link_list: updatedLinkList,
          link_list_loaded: message.link_list_loaded + 1
        };
      });

      return {
        ...state,
        supportChats: {
          ...state.supportChats,
          [userId]: {
            ...state.supportChats[userId],
            messageList
          }
        }
      };
    }

    case actionTypes.CREATE_SUPPORT_MESSAGE_FETCH: {
      const {
        message: { user_id },
        messageTransformed
      } = action.payload;
      const unreadSystemMessageIndex = state.supportChats[user_id].unreadSystemMessageIndex;
      return {
        ...state,
        supportChats: {
          ...state.supportChats,
          [user_id]: {
            ...state.supportChats[user_id],
            messageList: [messageTransformed, ...state.supportChats[user_id].messageList],
            messageCount: state.supportChats[user_id].messageCount + 1,
            ...(unreadSystemMessageIndex ? { unreadSystemMessageIndex: unreadSystemMessageIndex + 1 } : {})
          }
        }
      };
    }

    case actionTypes.GET_SUPPORT_MESSAGE_BY_ID_SUCCESS:
    case actionTypes.CREATE_SUPPORT_MESSAGE_SUCCESS: {
      const {
        message: { user_id },
        message,
        transformedId
      } = action.payload;

      const isOutgoingMessageInList = state.supportChats[user_id].messageList.some(
        (item) => item._id === transformedId
      );

      if (!isOutgoingMessageInList) {
        return state;
      }

      // sometimes backend gives us in long pulling response a duplicated message
      // with media link, that's why we need to remove it from the list;
      // (it happens before post success)
      const upToDateMessageState = state.supportChats[user_id].messageList.filter((item) => item._id !== message._id);
      const messageList = upToDateMessageState.map((item) =>
        item._id === transformedId ? { ...message, media: item.media } : item
      );
      return {
        ...state,
        supportChats: {
          ...state.supportChats,
          [user_id]: {
            ...state.supportChats[user_id],
            messageList,
            hasUnreadMessages: false
          }
        }
      };
    }

    case actionTypes.CREATE_SUPPORT_MESSAGE_ERROR: {
      const {
        message: { user_id },
        message,
        transformedId
      } = action.payload;

      const isMessageInList = state.supportChats[user_id].messageList.some((item) => item._id === transformedId);

      if (!isMessageInList) {
        return state;
      }

      return {
        ...state,
        supportChats: {
          ...state.supportChats,
          [user_id]: {
            ...state.supportChats[user_id],
            messageList: state.supportChats[user_id].map((item) =>
              item._id === transformedId
                ? {
                    ...message,
                    deliveryError: true
                  }
                : item
            )
          }
        }
      };
    }

    case actionTypes.RESET_USER_UNREAD_MESSAGES_MARK: {
      const user_id = action.payload;
      return {
        ...state,
        supportChats: {
          ...state.supportChats,
          [user_id]: {
            ...state.supportChats[user_id],
            hasUnreadMessages: false
          }
        }
      };
    }

    case actionTypes.RESET_USER_UNREAD_MESSAGES_DIVIDER: {
      const user_id = action.payload;
      return {
        ...state,
        supportChats: {
          ...state.supportChats,
          [user_id]: {
            ...state.supportChats[user_id],
            messageList: state.supportChats[user_id]?.messageList?.filter((message) => !message.system),
            unreadSystemMessageIndex: 0
          }
        }
      };
    }

    case actionTypes.CHANGE_ACTIVE_TAB: {
      return {
        ...state,
        activeTabIndex: action.payload
      };
    }

    // delete
    case actionTypes.DELETE_SUPPORT_MESSAGE_FETCH: {
      const { userID, message } = action.payload;

      return {
        ...state,
        deleteMessageLoading: true
      };
    }
    case actionTypes.DELETE_SUPPORT_MESSAGE_SUCCESS: {
      const { userID, message } = action.payload;

      return {
        ...state,
        deleteMessageLoading: false,
        supportChats: {
          ...state.supportChats,
          [userID]: {
            ...state.supportChats[userID],
            messageList: state.supportChats[userID].messageList.filter((item) => item._id !== message._id)
          }
        }
      };
    }
    case actionTypes.DELETE_SUPPORT_MESSAGE_ERROR: {
      return {
        ...state,
        deleteMessageLoading: false
      };
    }

    // update
    case actionTypes.UPDATE_SUPPORT_MESSAGE_FETCH: {
      const { userID, message } = action.payload;

      return {
        ...state,
        updateMessageLoading: true,
        supportChats: {
          ...state.supportChats,
          [userID]: {
            ...state.supportChats[userID],
            messageList: state.supportChats[userID].messageList.map((item) =>
              item._id === message._id ? message : item
            )
          }
        }
      };
    }
    case actionTypes.UPDATE_SUPPORT_MESSAGE_SUCCESS: {
      const { userID, message } = action.payload;

      return {
        ...state,
        updateMessageLoading: false,
        supportChats: {
          ...state.supportChats,
          [userID]: {
            ...state.supportChats[userID],
            messageList: state.supportChats[userID].messageList.map((item) =>
              item._id === message._id ? message : item
            )
          }
        }
      };
    }
    case actionTypes.UPDATE_SUPPORT_MESSAGE_ERROR: {
      return {
        ...state,
        updateMessageLoading: false
      };
    }

    default: {
      return state;
    }
  }
}

export const selectSupportMessageList = (state, userId) => state.supportChat.supportChats[userId]?.messageList || [];
export const selectSupportMessageCount = (state, userId) => state.supportChat.supportChats[userId]?.messageCount || 0;
export const selectUpdateMessageLoading = (state) => state.supportChat.updateMessageLoading;
export const selectDeleteMessageLoading = (state) => state.supportChat.deleteMessageLoading;
export const selectSupportFirstMessagesLoaded = (state, userId) =>
  state.supportChat.supportChats[userId]?.firstMessagesLoaded || false;
export const selectHasUserChatUreadMessages = (state, userId) =>
  state.supportChat.supportChats[userId]?.hasUnreadMessages || false;
export const isSupportHistoryMessageLeft = (state, userId) =>
  state.supportChat.supportChats[userId]?.historyMessageLeftCount > 0;

export default reducer;
