import React from 'react';

import styles from 'constants/styles';
import { CloseButton } from 'components';
import { formatters } from 'utils';
import { FilterWrapper, FilterValue, FilterHeader } from '../../styled';

const DateFilter = ({ filterName = 'Selected Date', startDate = null, endDate = null, onRemoveFilter = () => {} }) => {
  if (!startDate) {
    return null;
  }

  return (
    <>
      <FilterHeader capitalize marginRight={9}>
        {filterName}
      </FilterHeader>
      <FilterWrapper marginRight={14}>
        <FilterValue>
          {formatters.dateUTC(startDate)} - {formatters.dateUTC(endDate)}
        </FilterValue>
        <CloseButton size={18} iconSize={10} iconColor={styles.colors.BLACK} onClick={onRemoveFilter} />
      </FilterWrapper>
    </>
  );
};

export default DateFilter;
