import React from 'react';
import { connect } from 'react-redux';
import { closeModal } from 'store/actions';
import { Modal } from 'components';
import modalTypes from 'constants/modalTypes';
import { selectModalData } from 'store/reducers/modal';

const InnerHtml = ({ closeModal, modalData }) => (
  <Modal
    maxWidth={modalData?.maxWidth || null}
    modalType={modalTypes.INNER_HTML}
    smallPaddings={false}
    text={modalData.errorMessage}
    hideModal={() => closeModal(modalTypes.INNER_HTML)}
    closeButton>
    <div dangerouslySetInnerHTML={{ __html: modalData.html }} />
  </Modal>
);

const mapStateToProps = (state) => ({
  modalData: selectModalData(state)
});

const mapDispatchToProps = {
  closeModal
};

export default connect(mapStateToProps, mapDispatchToProps)(InnerHtml);
