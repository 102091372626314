import React from 'react';
import { useFieldArray } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';

import { IconButton } from 'components';
import { Header, Item } from './components';

const EditableItemsList = ({
  arrayFieldName,
  clearErrors,
  control,
  tooltip,
  totalFieldName,
  totalName,
  priceRequired = true
}) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: arrayFieldName,
    keyName: 'idv4'
  });

  const getDefaultValueWithIdv4 = () => ({
    description: '',
    price: '',
    amount_display: '',
    idv4: uuidv4()
  });

  const onDeleteItem = ({ index }) => {
    remove(index);
  };

  const onAddItem = () => {
    clearErrors(totalFieldName);
    append(getDefaultValueWithIdv4());
  };

  return (
    <>
      <Header totalName={totalName} tooltip={tooltip} />
      {fields.map((item, index) => (
        <Item
          key={item.idv4}
          arrayFieldName={arrayFieldName}
          arrayNameItem={`${arrayFieldName}.${index}`}
          control={control}
          index={index}
          priceRequired={priceRequired}
          onDeleteClick={() => onDeleteItem({ index })}
        />
      ))}
      <IconButton
        onClick={onAddItem}
        text={'Add more'}
        height={24}
        width={102}
        iconName="plus"
        backgroundIconSize={12}
      />
    </>
  );
};

EditableItemsList.propTypes = {
  description: PropTypes.string,
  price: PropTypes.number
};

export { EditableItemsList };
