import { call, takeLatest, put } from 'redux-saga/effects';
import api_constants from 'constants/api';
import actionTypes from 'constants/actionTypes';
import errors from 'constants/errors';
import Api from 'services/ApiService';
import { mediaObjectTypes } from 'common/enums/app';

function* getTaskQuotationList({ payload: { serviceId } }) {
  try {
    const response = yield call(
      Api.amplifyGet,
      `${api_constants.QUOTATION}?desc_order&service_request_id=${serviceId}`
    );

    if (!response || !response?.results) {
      throw new Error(errors.DEFAULT);
    }

    yield put({
      type: actionTypes.GET_TASK_QUOTATION_LIST_SUCCESS,
      payload:
        response.results.map((item) => ({
          ...item,
          is_quotation: true
        })) || []
    });

    yield put({
      type: actionTypes.GET_MEDIA_URLS_FOR_LIST_FETCH,
      payload: {
        instances: response.results,
        type: mediaObjectTypes.QUOTATION_IMAGES
      }
    });

    yield put({
      type: actionTypes.GET_MEDIA_URL_FOR_LIST_FETCH,
      payload: {
        instances: response.results,
        type: mediaObjectTypes.QUOTATION_PDF,
        media_field: 'pdf_invoice_media'
      }
    });
  } catch (err) {
    yield put({
      type: actionTypes.GET_TASK_QUOTATION_LIST_ERROR
    });
  }
}

export default function* watchGetTaskQuotationList() {
  yield takeLatest(actionTypes.GET_TASK_QUOTATION_LIST_FETCH, getTaskQuotationList);
}
