import React from 'react';
import { useSelector } from 'react-redux';

import { Switch } from 'components';
import { useDispatch } from 'hooks';
import modalTypes from 'constants/modalTypes';
import activeTimezones from 'constants/timezones';
import { openModal } from 'store/actions';
import { selectActiveTimezone } from 'store/reducers/app';

import { SwitchLabel, SwitchWrapper, BGUncheckedColor } from './styled';

const TimezoneToggle = () => {
  const dispatch = useDispatch();
  const activeTimezone = useSelector(selectActiveTimezone);
  const isChecked = activeTimezone === activeTimezones.EST.value;

  const handleToggleEnvironment = (value) => {
    dispatch(
      openModal(modalTypes.TIMEZONE, { timezone: value ? activeTimezones.EST.value : activeTimezones.PST.value })
    );
  };

  return (
    <>
      <SwitchLabel>PST - EST</SwitchLabel>
      <SwitchWrapper>
        <Switch checked={isChecked} onChange={handleToggleEnvironment} BGUnchecked={BGUncheckedColor} />
      </SwitchWrapper>
    </>
  );
};

export default TimezoneToggle;
