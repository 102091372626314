import styled from 'styled-components';

import { INVOICE_AMOUNT_INPUT_WIDTH, INVOICE_FORM_MARGIN_RIGHT } from '../../../../constants/invoice';

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  margin-right: ${INVOICE_FORM_MARGIN_RIGHT}px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  min-width: ${INVOICE_AMOUNT_INPUT_WIDTH + INVOICE_FORM_MARGIN_RIGHT}px;
  margin: 12px 0;
`;

export const FieldName = styled.label`
  font-weight: 500;
  font-size: 12px;
  line-height: 1.17;
  color: ${({ theme }) => theme.colors.DARK_GREY};
  margin: 3px 0 0 0;
`;

export const Subtitle = styled.div`
  font-weight: 500;
  font-size: 10px;
  line-height: 1.2;
  color: ${({ theme }) => theme.colors.DARK_GREY_2};
  max-width: 141px;
  margin: 4px 0 0 0;
`;
