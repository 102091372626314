import { call, takeLatest, put } from 'redux-saga/effects';

import api_constants from 'constants/api';
import errors from 'constants/errors';
import actionTypes from 'constants/actionTypes';
import modalTypes from 'constants/modalTypes';
import { openModal } from 'store/actions';
import Api from 'services/ApiService';
import { uploadPublicFile } from 'common/helpers/media';

function* createCampaign({ payload }) {
  const { bannerPicture, postPicture, execution, onSuccessCallback, ...values } = payload;
  try {
    const image_preview_source = yield call(uploadPublicFile, {
      customPrefix: 'public/campaigns/',
      file: bannerPicture.file
    });

    const image_source = yield call(uploadPublicFile, {
      customPrefix: 'public/campaigns/',
      file: postPicture.file
    });
    let uploadedCSVPath = '';
    if (execution.csv_cohort_path && typeof execution.csv_cohort_path !== 'string') {
      uploadedCSVPath = yield call(uploadPublicFile, {
        customPrefix: 'public/campaigns/cohorts/',
        file: execution.csv_cohort_path
      });

      if (!uploadedCSVPath) {
        throw new Error('CSV file failed to upload. Please try again');
      }
    }

    if (!image_source || !image_preview_source) {
      throw new Error('Images failed to upload. Please try again');
    }

    const response = yield call(Api.amplifyPost, api_constants.CAMPAIGN, {
      ...values,
      execution: {
        ...execution,
        ...(uploadedCSVPath && execution.csv_cohort_name ? { csv_cohort_path: uploadedCSVPath } : {})
      },
      article: {
        ...values.article,
        image_preview_source,
        image_source
      }
    });

    if (!response || !response?.id || response.err) {
      throw new Error(response?.err.response?.data.error_message);
    }

    yield put({
      type: actionTypes.CREATE_CAMPAIGN_SUCCESS
    });

    if (onSuccessCallback) {
      onSuccessCallback({ campaign_id: response.id });
    }
    yield put(
      openModal(modalTypes.RESULT, {
        title: `The "${response?.campaign_push?.template_title}"\ncampaign successfully created`
      })
    );
  } catch (err) {
    yield put({
      type: actionTypes.CREATE_CAMPAIGN_ERROR
    });

    yield put(
      openModal(modalTypes.ERROR, {
        errorMessage: err?.message || errors.DEFAULT
      })
    );
  }
}

export default function* watchCreateCampaign() {
  yield takeLatest(actionTypes.CREATE_CAMPAIGN_FETCH, createCampaign);
}
