import React from 'react';
import PropTypes from 'prop-types';
import { formatters } from 'utils';
import { Title, Item, Value, Description, Amount } from './styled';

const ItemsList = ({ items, title, amount, isItemized }) => {
  if (!amount && (!items || !items?.length)) {
    return null;
  }
  if (isItemized && !items?.length) {
    return null;
  }

  if (amount && (!items || !items.length)) {
    return (
      <Title>
        <pre>{`${title}  -   ${formatters.dollarCurrencyDB(amount)}`}</pre>
      </Title>
    );
  }

  return (
    <>
      <Title>{title}</Title>
      {items.map((item, index) => (
        <Item key={index}>
          <Value bold>
            <Description>{item.description}</Description>
            <Amount>{formatters.dollarCurrencyDB(item.price)}</Amount>
          </Value>
          <Value>{item.details}</Value>
        </Item>
      ))}
    </>
  );
};

ItemsList.propTypes = {
  description: PropTypes.string,
  price: PropTypes.number
};

export { ItemsList };
