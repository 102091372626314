import styled from 'styled-components';
import { breadcrumbLinkStyle } from './components/Breadcrumb/styled';

export const BreadcrumbsWrapper = styled.div`
  display: flex;
  align-items: center;
  z-index: 1;
  font-weight: 400;
  font-size: 20px;
  line-height: 1.2;
  color: ${({ theme }) => theme.colors.WHITE};
  max-width: calc(100vw - ${({ theme }) => theme.layout.navigationWidth}px - 200px);
`;

export const IconWrapper = styled.div`
  margin: 0 10px 0 13px;
  display: flex;
  align-items: center;
`;

export const BreadcrumbElipsis = styled.div`
  ${breadcrumbLinkStyle}

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
