import React from 'react';
import { useWatch } from 'react-hook-form';

import { IconButton, HookForm } from 'components';
import { fieldTypes } from 'common/enums/form';
import { INVOICE_FORM_MARGIN_RIGHT } from '../../../../../../constants/invoice';
import {
  ItemWrapper,
  ListRow,
  inputWrapperStyle,
  descriptionInputWrapperStyle,
  priceInputWrapperStyle,
  textareaStyle,
  detailsAreaStyle
} from './styled';

const Item = ({ arrayFieldName, arrayNameItem, control, index, priceRequired, onDeleteClick }) => {
  const values = useWatch({ control }); // We need to resize input in realtime, but getValues works with some delay, so we use useWatch
  const priceLength = values[arrayFieldName][index]?.price?.length;
  return (
    <>
      <ListRow>
        <HookForm.ControlledTextarea
          key="invoice-item-description"
          name={`${arrayNameItem}.description`}
          control={control}
          placeholder="Line Item Name, e.g. Kitchen Repair"
          fieldType={fieldTypes.INVOICE_TEXT}
          rules={{
            required: true,
            maxHeight: 220,
            maxLength: 240
          }}
          wrapperStyle={{
            ...inputWrapperStyle,
            ...descriptionInputWrapperStyle
          }}
          style={textareaStyle}
        />
        <HookForm.Input
          key="invoice-item-price"
          name={`${arrayNameItem}.price`}
          control={control}
          fieldType={priceRequired ? fieldTypes.DOLLAR_CURRENCY : fieldTypes.DOLLAR_CURRENCY_ZERO}
          rules={{
            required: priceRequired
          }}
          wrapperStyle={{
            ...inputWrapperStyle,
            ...priceInputWrapperStyle,
            paddingTop: 5,
            width: priceInputWrapperStyle.width - 50 + priceLength * 10
          }}
        />
        <ItemWrapper>
          <IconButton
            iconName="closeFill2"
            size={16}
            onClick={onDeleteClick}
            margin={`0 ${INVOICE_FORM_MARGIN_RIGHT - 36}px 0 4px`}
          />
        </ItemWrapper>
      </ListRow>
      <ListRow>
        <HookForm.ControlledTextarea
          key="invoice-item-details"
          name={`${arrayNameItem}.details`}
          reset={true}
          control={control}
          placeholder="Description of the work or materials needed (optional but suggested)"
          fieldType={fieldTypes.INVOICE_TEXT}
          rules={{
            required: false,
            maxHeight: 240,
            maxLength: 1000
          }}
          wrapperStyle={{
            ...inputWrapperStyle,
            ...descriptionInputWrapperStyle
          }}
          style={detailsAreaStyle}
        />
      </ListRow>
    </>
  );
};

export { Item };
