import actionTypes from 'constants/actionTypes';

export const getReviewList = (payload) => ({
  type: actionTypes.GET_REVIEW_LIST_FETCH,
  payload
});

export const updateReview = (payload) => ({
  type: actionTypes.UPDATE_REVIEW_FETCH,
  payload
});
