import { call, takeLatest, put } from 'redux-saga/effects';

import { apiPageSizes } from 'common/enums/queryParams';
import api_constants from 'constants/api';
import actionTypes from 'constants/actionTypes';
import errors from 'constants/errors';
import Api from 'services/ApiService';
import { chatMessageTransformer } from 'utils';

function* getTaskMessageList({ payload: { serviceId, idGt, idLt, setIsOpen, limit = apiPageSizes.DEFAULT } }) {
  try {
    const response = yield call(
      Api.amplifyGet,
      `${api_constants.SUPPORT_REQUEST}?limit=${limit}&service_request_id=${serviceId}${idGt ? `&id_gt=${idGt}` : ''}${
        idLt ? `&id_lt=${idLt}` : ''
      }${!setIsOpen ? `&set_is_open=${setIsOpen}` : ''}`
    );
    if (!response || !response?.results) {
      throw new Error(errors.DEFAULT);
    }
    const messageList = response.results.map((item) => chatMessageTransformer(item));

    yield put({
      type: actionTypes.GET_TASK_MESSAGE_LIST_SUCCESS,
      payload: {
        count: response.count,
        messageList,
        setIsOpen,
        idGt,
        idLt
      }
    });
    yield put({
      type: actionTypes.GET_TASK_LINK_PREVIEWS_FETCH,
      payload: {
        messageList
      }
    });
  } catch (err) {
    yield put({
      type: actionTypes.GET_TASK_MESSAGE_LIST_ERROR
    });
    console.log(err);
  }
}

export default function* watchGetTaskMessageList() {
  yield takeLatest(actionTypes.GET_TASK_MESSAGE_LIST_FETCH, getTaskMessageList);
}
