import actionTypes from 'constants/actionTypes';

export const changeHasGoBackButton = (data) => ({
  type: actionTypes.CHANGE_HAS_GO_BACK_BUTTON,
  payload: data
});

export const setActiveMenuItem = (payload) => ({
  type: actionTypes.SET_ACTIVE_MENU_ITEM,
  payload
});

export const saveLocationSearch = (payload) => ({
  type: actionTypes.SAVE_LOCATION_SEARCH,
  payload
});

export const toggleNotificationsPopup = (payload) => ({
  type: actionTypes.TOGGLE_HAS_NOTIFICATIONS_POPUP,
  payload
});
