import styled from 'styled-components';

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 24px 48px 24px 24px;
  margin-left: 48px;
  background-color: ${({ theme }) => theme.colors.DARK_1};
`;

export const HeaderSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  margin: 3px 31px 3px 0;
`;

export const CloseButtonAbsolute = styled.div`
  position: absolute;
  top: 14px;
  right: 26px;
`;

export const AddressSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  margin: 3px 0;
`;

export const PropRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${({ justify }) => justify || 'space-between'};
  margin: 3px 0;
`;

export const PropColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  margin: 3px 18px 3px 4px;
`;

export const PictureRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: ${({ justifyContent }) => (justifyContent ? justifyContent : 'flex-start')};
  padding: 7px 0;
`;

export const picturePreviewStyle = {
  width: '118px',
  height: '70px',
  margin: '0 8px 8px 0',
  borderRadius: '4px'
};

export const placeholderStyle = {
  display: 'none'
};

export const DescriptionSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  margin: 3px 0;
`;

export const HeaderLabel = styled.label`
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  color: ${({ theme }) => theme.colors.INVOICE_TEXT};
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

export const PropTitle = styled.label`
  font-weight: 500;
  font-size: 12px;
  line-height: 1.7;
  color: ${({ theme }) => theme.colors.DARK_GREY};
  margin: 4px 0;
`;

export const PropValue = styled.label`
  font-weight: 400;
  font-size: 14px;
  line-height: 1.7;
  color: ${({ theme }) => theme.colors.INVOICE_TEXT};
`;
