import styled from 'styled-components';
import styles from 'constants/styles';
import 'react-image-lightbox/style.css';

export const StaticContainer = styled.div`
  min-height: calc(100vh - ${styles.layout.headerHeight}px);
  max-height: calc(100vh - ${styles.layout.headerHeight}px);
  overflow: overlay;
  flex: 1;
`;

export const LoadingContainer = styled.div`
  display: flex;
  flex: 1;
  padding: 50px;
  justify-content: center;
  position: relative;
  height: calc(100vh - ${styles.layout.headerHeight}px);
`;
