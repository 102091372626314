import { jobQuotedStatuses } from 'common/enums/invoice';
import { cellTypes } from 'common/enums/table';
import dates from 'constants/dates';
import { navigationTabs } from 'common/enums/navigation';

import { getAuthorByType, prepareStageTableCell } from 'common/helpers/job';
import { formatters, formatDateShortTimeStampSmall } from 'utils';

import { taskTable } from '../enums';

export const getColumns = ({ tab_id }) => {
  return [
    {
      id: taskTable.STAGE.COLUMN_NAME,
      title: taskTable.STAGE.TITLE,
      cellType: cellTypes.LABEL,
      hasSorting: true,
      minWidth: 180,
      accessor: ({ admin_status, datetime_visit, datetime_window_end, is_past_scheduled }) => {
        const data = prepareStageTableCell({ admin_status, datetime_visit, datetime_window_end, is_past_scheduled });
        return {
          ...data,
          style: {
            ...data.style,
            paddingLeft: 14
          }
        };
      }
    },
    {
      id: taskTable.ID.COLUMN_NAME,
      title: taskTable.ID.TITLE,
      cellType: cellTypes.TEXT,
      minWidth: 70,
      hasSorting: true,
      accessor: ({ id }) => ({
        text: String(id),
        hasHighlight: true
      })
    },
    {
      id: taskTable.HO_GIVEN_NAME.COLUMN_NAME,
      title: taskTable.HO_GIVEN_NAME.TITLE,
      cellType: cellTypes.USER_NAME,
      minWidth: 140,
      hasSorting: true,
      accessor: ({ customer }) => ({
        given_name: customer.given_name,
        family_name: customer.family_name,
        stage: customer.stage,
        hasHighlight: true
      })
    },
    {
      id: taskTable.PRO_GIVEN_NAME.COLUMN_NAME,
      title: taskTable.PRO_GIVEN_NAME.TITLE,
      cellType: cellTypes.USER_NAME,
      minWidth: 140,
      hasSorting: true,
      accessor: ({ provider = {}, pro_stage }) => ({
        given_name: provider.given_name,
        family_name: provider.family_name,
        pro_stage,
        hasHighlight: true
      })
    },
    [navigationTabs.PAYMENT.TYPE, navigationTabs.AR.TYPE].includes(tab_id)
      ? {
          id: taskTable.DATETIME_COMPLETED.COLUMN_NAME,
          title: taskTable.DATETIME_COMPLETED.TITLE,
          cellType: cellTypes.TEXT,
          minWidth: 145,
          hasSorting: true,
          accessor: ({ datetime_completed }) => ({
            text: formatters.dateWithTimezone(datetime_completed, dates.DATE)
          })
        }
      : {
          id: taskTable.DATETIME_CREATED.COLUMN_NAME,
          title: taskTable.DATETIME_CREATED.TITLE,
          cellType: cellTypes.TEXT,
          minWidth: 145,
          hasSorting: true,
          accessor: ({ date_created }) => ({
            text: formatters.dateWithTimezone(date_created, dates.DATE)
          })
        },
    {
      id: taskTable.IS_QUOTED.COLUMN_NAME,
      hasSorting: true,
      minWidth: 100,
      title: taskTable.IS_QUOTED.TITLE,
      cellType: cellTypes.LABEL,
      accessor: ({ is_quoted }) => {
        const jobQuotedStatus =
          Object.values(jobQuotedStatuses).find(({ TYPE }) => TYPE === is_quoted) || jobQuotedStatuses.DEFAULT;
        return {
          alignLeft: jobQuotedStatus === jobQuotedStatuses.DEFAULT,
          color: jobQuotedStatus.COLOR,
          text: jobQuotedStatus.NAME
        };
      }
    },
    {
      id: taskTable.AMOUNT_ESTIMATED.COLUMN_NAME,
      hasSorting: true,
      minWidth: 130,
      title: taskTable.AMOUNT_ESTIMATED.TITLE,
      cellType: cellTypes.TEXT,
      accessor: ({ expected_value }) => ({
        text: Boolean(expected_value) ? formatters.dollarCurrencyDB(expected_value) : '-'
      })
    },
    {
      id: taskTable.AMOUNT_INVOICED.COLUMN_NAME,
      title: taskTable.AMOUNT_INVOICED.TITLE,
      cellType: cellTypes.TEXT,
      hasSorting: true,
      minWidth: 150,
      accessor: ({ amount_invoiced }) => ({
        text: Boolean(amount_invoiced) ? formatters.dollarCurrencyDB(amount_invoiced) : '-'
      })
    },
    ...([navigationTabs.PAYMENT.TYPE, navigationTabs.AR.TYPE].includes(tab_id)
      ? [
          {
            id: taskTable.DAYS_DELAY.COLUMN_NAME,
            title: taskTable.DAYS_DELAY.TITLE,
            cellType: cellTypes.TEXT,
            hasSorting: true,
            maxWidth: 70,
            accessor: ({ date_created }) => ({
              text: Math.round((Date.parse(new Date()) - Date.parse(date_created)) / 86400000)
            })
          }
        ]
      : []),

    {
      id: taskTable.TOTAL_PAID.COLUMN_NAME,
      title: taskTable.TOTAL_PAID.TITLE,
      cellType: cellTypes.TEXT,
      minWidth: 135,
      accessor: ({ amount_total_paid }) => ({
        text: amount_total_paid ? formatters.dollarCurrencyDB(amount_total_paid) : '-'
      })
    },
    {
      id: taskTable.LAST_MESSAGE.COLUMN_NAME,
      hasSorting: false,
      title: taskTable.LAST_MESSAGE.TITLE,
      cellType: cellTypes.RICH_TEXT,
      minWidth: 200,
      accessor: ({ last_message }) => {
        return {
          datetime: formatters.messageDate(last_message?.datetime_sent),
          text: last_message?.message_body,
          secondText: getAuthorByType({ author: last_message?.author_name, type: last_message?.author_type })
        };
      }
    },
    {
      id: taskTable.LAST_NOTE.COLUMN_NAME,
      title: taskTable.LAST_NOTE.TITLE,
      cellType: cellTypes.RICH_TEXT,
      minWidth: 200,
      hasSorting: false,
      accessor: ({ datetime_last_note, last_note }) => ({
        datetime: formatDateShortTimeStampSmall(datetime_last_note),
        text: last_note
      })
    }
  ];
};
