export const getRows = ({ list }) => {
  return list.map((agent) => ({
    id: agent.id,
    profile_image_url: agent.profile_image_url,
    user: agent.user,
    given_name: agent.user?.given_name,
    family_name: agent.user?.family_name,
    phone_number: agent.user?.phone_number,
    email: agent.user?.email,
    company_name: agent.company?.name,
    position: agent.position
  }));
};
