import styled from 'styled-components';
import styles from 'constants/styles';

export const ReasonWrapper = styled.div`
  min-height: 24px;
  padding: 3px 20px;
  background-color: ${({ backgroundColor = styles.colors.WHITE }) => backgroundColor};

  font-weight: 400;
  font-size: 13px;
  line-height: 1.307;

  display: flex;
  flex-direction: row;
`;

export const ReasonGroup = styled.div`
  margin-right: 5px;
`;

export const ReasonTab = styled.div`
  flex: 1;
  white-space: pre-line;
`;
