import { environments } from 'common/enums/app';

export const amplifyConfig = {
  product: {
    Auth: {
      identityPoolId: 'us-east-1:c92cf295-bf6e-4007-bb14-ca1a82aba624',
      region: 'us-east-1',
      userPoolId: 'us-east-1_UDNwdJzwl',
      userPoolWebClientId: '4pstnrmus55at5hf7pqonfrii0'
    },
    API: {
      endpoints: [
        {
          name: 'mainApi',
          endpoint: environments.PRODUCT.ENDPOINT
        }
      ]
    },
    Storage: {
      AWSS3: {
        bucket: 'dobby-product-media',
        region: 'us-east-1',
        customPrefix: {
          public: ''
        }
      }
    }
  },
  staging: {
    Auth: {
      identityPoolId: 'us-east-1:c13d83cf-4c55-427e-954b-f5a7b6c8c224',
      region: 'us-east-1',
      userPoolId: 'us-east-1_C8WK3z0jO',
      userPoolWebClientId: '5spdl9bui38ouq4773c7n73igs'
    },
    API: {
      endpoints: [
        {
          name: 'mainApi',
          endpoint: environments.STAGING.ENDPOINT
        }
      ]
    },
    Storage: {
      AWSS3: {
        bucket: 'dobby-staging-media',
        region: 'us-east-1',
        customPrefix: {
          public: ''
        }
      }
    }
  },
  develop: {
    Auth: {
      identityPoolId: 'eu-central-1:bda17b28-4986-4a33-8c16-f8d0e19409a2',
      region: 'eu-central-1',
      userPoolId: 'eu-central-1_RqZ6djSy1',
      userPoolWebClientId: '2sijcvfb7eon4qeucj13jgc1cl'
    },
    API: {
      endpoints: [
        {
          name: 'mainApi',
          endpoint: environments.DEVELOP.ENDPOINT
        }
      ]
    },
    Storage: {
      AWSS3: {
        bucket: 'dobby-develop-media',
        region: 'eu-central-1',
        customPrefix: {
          public: ''
        }
      }
    }
  },
  local: {
    Auth: {
      identityPoolId: 'us-east-1:c13d83cf-4c55-427e-954b-f5a7b6c8c224',
      region: 'us-east-1',
      userPoolId: 'us-east-1_C8WK3z0jO',
      userPoolWebClientId: '5spdl9bui38ouq4773c7n73igs'
    },
    API: {
      endpoints: [
        {
          name: 'mainApi',
          endpoint: environments.LOCAL.ENDPOINT
        }
      ]
    }
  }
};
