import styled from 'styled-components';
import styles from 'constants/styles';

export const ModalTitle = styled.div`
  width: 100%;
  min-height: 48px;
  background: ${({ theme }) => theme.colors.DATE_MODAL_TITLE_BG};
  font-weight: 400;
  font-size: 20px;
  line-height: 1.2;
  color: ${({ theme }) => theme.colors.DATE_MODAL_TITLE};
  display: flex;
  align-items: center;
  padding-left: 24px;
  ${({ theme }) => !theme.darkMode && `border-bottom: 1px solid ${theme.colors.LIGHT_GRAY}`}
`;

export const ButtonsWrapper = styled.div`
  align-self: stretch;
  display: flex;
  flex: 1;
  justify-content: space-between;
  margin-top: 38px;
  min-width: 325px;
`;
export const Divider = styled.div`
  margin: 0 10px;
`;
export const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 23px 24px 40px 24px;
  background: ${({ theme }) => theme.colors.DATE_MODAL_BG};
`;

export const Line = styled.hr`
  background-color: ${styles.colors.LIGHT_GRAY};
  height: 1px;
  margin: 0;
  border-width: 0px;
  width: 100%;
`;

export const SecondLine = styled.div`
  background-color: ${({ theme }) => theme.colors.DATE_MODAL_DIVIDER};
  height: 1px;
  width: 100%;
  margin: 30px 0 24px 0;
`;

export const RelativeWrapper = styled.div`
  position: relative;
  display: flex;
  & > div {
    background-color: unset;
    overflow: overlay;
  }
  div[role='presentation'] {
    font-weight: 500;
    font-size: 16px;
    line-height: 1;
    color: ${({ theme }) => theme.colors.DATE_MODAL_WEEK};
  }
  .css-18puooo {
    width: 8px;
  }
  .css-1dozdou {
    padding: 0 20px 0 34px;
  }
  .MuiPickersDay-root {
    width: 32px;
    height: 32px;
    margin 4px;
  }
  .MuiButtonBase-root {
    font-weight: 400;
    font-size: 14px;
    line-height: 1.14;
    color: ${({ theme }) => theme.colors.DATE_MODAL_WEEK};
    background: unset;
    width: 32px;
    height: 32px;
    margin 4px;
   ${({ theme }) =>
     theme.darkMode &&
     `&: hover {
    background: ${theme.colors.DARK_GREY_7};
  }`}
  }
  ${({ theme }) =>
    theme.darkMode &&
    `.Mui-selected {
    background: ${theme.colors.DARK_GREEN_2} !important;
  }`}
  .Mui-disabled {
    font-weight: 400;
    font-size: 14px;
    line-height: 1.14;
    color: ${({ theme }) => theme.colors.DARK_GREY_2} !important;
    margin 4px;
  }
  .MuiTypography-root {
    font-weight: 400;
    font-size: 14px;
    line-height: 1.14;
    width: 32px;
    height: 32px;
    color: ${({ theme }) => theme.colors.DATE_MODAL_WEEK};
    margin 4px;
  }
  .PrivatePickersSlideTransition-root {
    min-height: auto;
  }
`;

export const TimeWrapper = styled.div`
  display: flex;
  align-items: baseline;
  width: 100%;
  justify-content: center;
  p {
    margin: 0 20px;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.14;
    color: ${({ theme }) => theme.colors.DATE_MODAL_WEEK};
  }
`;

export const dropdownMenuStyle = {
  top: -145
};
