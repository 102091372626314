import { call, takeLatest, put, select } from 'redux-saga/effects';
import api_constants from 'constants/api';
import actionTypes from 'constants/actionTypes';
import Api from 'services/ApiService';
import { getServiceList } from 'store/actions';
import { selectTaskImageUrls } from 'store/reducers/runtimeCache';

function* getServiceInstance(action) {
  const { serviceId: id } = action.payload;
  try {
    const response = yield call(Api.amplifyGet, `${api_constants.SERVICE}/${id}`);
    const { err } = response;
    if (response && !err) {
      yield put({
        type: actionTypes.GET_SERVICE_INSTANCE_SUCCESS,
        payload: response
      });
      const signedImageUrls = yield select(selectTaskImageUrls);
      if (response.media.length && !signedImageUrls[id]?.length) {
        yield put({
          type: actionTypes.SIGN_SERVICE_IMAGE_URLS_FETCH,
          payload: {
            media: response.media,
            serviceId: id
          }
        });
      }

      yield put(
        getServiceList({
          admin_status: null,
          customer_id: response.user_id,
          provider_id: response.service_provider_id || null,
          provider_is_null: true,
          is_paid: 0,
          is_open: 1,
          page: 1,
          isBundle: true
        })
      );
    } else {
      yield put({
        type: actionTypes.GET_SERVICE_INSTANCE_ERROR
      });
    }
  } catch (err) {
    console.log(err);
  }
}

export default function* watchGetServiceInstance() {
  yield takeLatest(actionTypes.GET_SERVICE_INSTANCE_FETCH, getServiceInstance);
}
