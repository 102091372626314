import { call, takeLatest, put, select } from 'redux-saga/effects';

import { apiPageSizes } from 'common/enums/queryParams';
import api_constants from 'constants/api';
import actionTypes from 'constants/actionTypes';
import errors from 'constants/errors';
import Api from 'services/ApiService';
import { saveLocationSearch } from 'store/actions/navigation';
import {
  isTestTasksVisible,
  selectFilteredAdminList,
  selectFilteredGeoLocationList,
  selectAdminInfo
} from 'store/reducers/app';
import { selectStoredServiceListFilters } from 'store/reducers/service';

import { generateQueryParams, prepareCustomFilters } from 'common/helpers/httpRequest';

function* getServiceList({ payload }) {
  const {
    priority_task,
    tag_id,
    page,
    order_by,
    customer_id,
    provider_id,
    owner_id = null,
    operational_area_id,
    provider_is_null,
    is_paid = null,
    is_open = null,
    search = null,
    is_my_jobs = false,
    admin_status_group,
    skip_spectora,
    isBundle = false,
    limit = apiPageSizes.DEFAULT,
    is_stored_filter = false,
    hasSavingQueryParams,
    menuItemType,
    locationSearch,
    ...otherFilters
  } = payload;

  const testTasksVisible = yield select(isTestTasksVisible);
  const filteredAdminList = yield select(selectFilteredAdminList);
  const filteredGeoLocationList = yield select(selectFilteredGeoLocationList);
  let storedFilters = {};
  if (is_stored_filter) {
    storedFilters = yield select(selectStoredServiceListFilters);
  }

  const opAreasIDString = operational_area_id
    ? operational_area_id
    : filteredGeoLocationList.length
    ? filteredGeoLocationList.join(',')
    : '';

  let ownersIDString = null;
  if (is_my_jobs) {
    const adminInfo = yield select(selectAdminInfo);
    ownersIDString = adminInfo?.id?.toString();
  } else if (owner_id) {
    ownersIDString = owner_id;
  } else if (filteredAdminList.length) {
    ownersIDString = filteredAdminList.map(({ id }) => id).join(',');
  }

  try {
    const queryParams = generateQueryParams({
      // core filters
      limit,
      page,
      order_by,
      // global filters
      search,
      operational_area_id: opAreasIDString,
      owner_id: isBundle ? null : ownersIDString,
      ...(testTasksVisible ? {} : { test_status: 0 }),
      // local filters
      customer_id,
      ...(provider_is_null ? { provider_id: `${provider_id}${provider_id !== null ? ',null' : ''}` } : { provider_id }),
      is_paid,
      is_open,
      priority_task,
      skip_spectora,
      admin_status_group,
      tag_id,
      // custom filters
      ...prepareCustomFilters(otherFilters),
      ...storedFilters
    });

    const response = yield call(Api.amplifyGet, `${api_constants.SERVICE}${queryParams}`);

    if (!response?.results) {
      throw new Error(errors.DEFAULT);
    }

    yield put({
      type: actionTypes.GET_SERVICE_LIST_SUCCESS,
      payload: {
        isBundle,
        totalCount: response.count,
        service: response.results,
        page
      }
    });

    if (hasSavingQueryParams) {
      yield put(saveLocationSearch({ menuItemType, locationSearch }));
    }
  } catch (err) {
    yield put({
      type: actionTypes.GET_SERVICE_LIST_ERROR,
      payload: {
        isBundle
      }
    });
    console.log(err);
  }
}

export default function* watchGetServiceList() {
  yield takeLatest(actionTypes.GET_SERVICE_LIST_FETCH, getServiceList);
}
