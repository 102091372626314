import styled from 'styled-components';
import styles from 'constants/styles';

export const RelativeWrapper = styled.div`
  position: relative;
  display: flex;
  & > div {
    background-color: unset;
    overflow: auto;
  }
  div[role='presentation'] {
    font-weight: 500;
    font-size: 16px;
    line-height: 1;
    color: ${({ theme }) => theme.colors.DATE_MODAL_WEEK};
  }
  .css-18puooo {
    width: 8px;
  }
  .css-1dozdou {
    padding: 0 20px 0 34px;
  }
  .MuiPickersDay-root {
    width: 32px;
    height: 32px;
    margin 4px;
  }
  .MuiButtonBase-root {
    font-weight: 400;
    font-size: 14px;
    line-height: 1.14;
    color: ${({ theme }) => theme.colors.DATE_MODAL_WEEK};
    background: unset;
    width: 32px;
    height: 32px;
    margin 4px;
   ${({ theme }) =>
     theme.darkMode &&
     `&: hover {
    background: ${theme.colors.DARK_GREY_7};
  }`}
  }
  ${({ theme }) =>
    theme.darkMode &&
    `.Mui-selected {
    background: ${theme.colors.DARK_GREEN_2} !important;
  }`}
  .Mui-disabled {
    font-weight: 400;
    font-size: 14px;
    line-height: 1.14;
    color: ${({ theme }) => theme.colors.DARK_GREY_2} !important;
    margin 4px;
  }
  .MuiTypography-root {
    font-weight: 400;
    font-size: 14px;
    line-height: 1.14;
    width: 32px;
    height: 32px;
    color: ${({ theme }) => theme.colors.DATE_MODAL_WEEK};
    margin 4px;
  }
  .PrivatePickersSlideTransition-root {
    min-height: auto;
  }
`;
