import styled from 'styled-components';
import styles from 'constants/styles';
import { INVOICE_AMOUNT_INPUT_WIDTH, INVOICE_FORM_MARGIN_RIGHT } from '../../../../constants/invoice';

export const AdditionalInfo = styled.div`
  display: flex;
  flex: 1;
  margin: 6px 0 0 0;
`;

export const BorderedAdditionalInfo = styled(AdditionalInfo)`
  margin-right: ${INVOICE_FORM_MARGIN_RIGHT}px;
  border: 1px solid ${styles.colors.YELLOW};
  border-radius: 8px;
  padding: 10px 2px 9px 10px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  min-width: ${INVOICE_AMOUNT_INPUT_WIDTH + INVOICE_FORM_MARGIN_RIGHT}px;
  margin: 12px 0;
`;

export const ListItemInfo = styled.div`
  display: flex;
  font-weight: 600;
  font-size: 10px;
  line-height: 1.5;
  margin: 0 8px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.INVOICE_TEXT};
`;

export const IconInfo = styled.div`
  font-weight: 700;
  font-size: 10px;
  line-height: 1.2;
  text-transform: uppercase;
  margin-left: 8px;
  color: ${({ theme }) => theme.colors.DARK_GREY};
`;

export const ListMarker = styled(ListItemInfo)`
  margin: 0 0 0 8px;
`;

export const IconWrapper = styled.div`
  display: flex;
`;
