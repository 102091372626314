import styled from 'styled-components';
import styles from 'constants/styles';

export const IconColor = styles.colors.WHITE;

export const ErrorMessage = styled.div`
  color: ${({ theme }) => theme.colors.TEXT};
  font-size: 18px;
  margin-bottom: 10px;
`;

export const ReportWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 40vh;
`;

export const ControlPanel = styled.div`
  display: flex;
  justify-content: start;
  width: 100%;
  margin-bottom: 20px;
  gap: 10px;
`;

export const TableWrapper = styled.div`
  width: 100%;
  overflow-x: scroll;
  height: 100%;
`;

export const ReportTable = styled.table`
  border-spacing: 0;
  border: 1px solid ${({ theme }) => theme.colors.WHITE};
  width: 100%;
`;

export const TableHeader = styled.tr`
  height: 60px;
  background-color: ${({ theme }) => theme.colors.PRIMARY};

  border-bottom: 1px solid ${({ theme }) => theme.colors.WHITE};
`;

export const TableHeaderCell = styled.th`
  height: 100%;
  color: ${({ theme }) => theme.colors.TEXT};
  font-weight: bold;
  font-size: 12px;
  padding: 0 5px;
  text-align: center;

  border-right: 1px solid ${({ theme }) => theme.colors.WHITE};
  border-bottom: 1px solid ${({ theme }) => theme.colors.WHITE};
  &:last-child {
    border-right: none;
  }
`;

export const TableRow = styled.tr`
  height: 48px;
  color: ${({ theme }) => theme.colors.TEXT};

  border-bottom: 1px solid ${({ theme }) => theme.colors.WHITE};
`;

export const TableBodyCell = styled.td`
  height: 100%;
  font-size: 12px;
  padding: 0 5px;
  text-align: center;

  border-right: 1px solid ${({ theme }) => theme.colors.WHITE};
  &:last-child {
    border-right: none;
  }
`;

export const CopyToClipboardHighlight = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.TEXT};
  z-index: 1;
`;
