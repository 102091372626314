import React from 'react';
import { useDispatch, useSelector, useState } from 'hooks';

import modalTypes from 'constants/modalTypes';

import { Modal, Icon } from 'components';

import { closeModal } from 'store/actions';
import { selectModalData } from 'store/reducers/modal';
import { selectOwnerReport } from 'store/reducers/service';

import { downloadCSV, formatters } from 'utils';

import { reportColumns } from './reportFormat';
import {
  ErrorMessage,
  ReportWrapper,
  ControlPanel,
  CopyToClipboardHighlight,
  IconColor,
  TableWrapper,
  ReportTable,
  TableHeader,
  TableHeaderCell,
  TableRow,
  TableBodyCell
} from './styled';

const OwnerReport = () => {
  const dispatch = useDispatch();
  const isSuccess = useSelector(selectModalData);
  const { data = [], length = 0 } = useSelector(selectOwnerReport);
  const [isCopied, setIsCopied] = useState(false);

  const convertToCsv = (data, delimiter = ',') => {
    const header = reportColumns.map(({ accessor }) => accessor).join(delimiter);
    const rows = data.map((row) => reportColumns.map(({ accessor }) => row[accessor]).join(delimiter));
    return [header, ...rows].join('\n');
  };

  const onDownloadClick = () => {
    const csvData = convertToCsv(data);
    const utf8Encoder = new TextEncoder('utf-8');
    // Download CSV works only with binary data
    downloadCSV({
      data: utf8Encoder.encode(csvData),
      fileName: 'owner_report.csv'
    });
  };
  const onCopyToClipboard = () => {
    const csvData = convertToCsv(data, '\t');
    navigator.clipboard.writeText(csvData);
    setIsCopied(true);
  };
  const onModalClose = () => dispatch(closeModal(modalTypes.OWNER_REPORT));

  if (!isSuccess) {
    return (
      <Modal modalType={modalTypes.OWNER_REPORT} title="Owner Report" hideModal={onModalClose}>
        <ErrorMessage>Something went wrong!</ErrorMessage>
      </Modal>
    );
  }

  const controls = (
    <ControlPanel>
      <Icon name="download" color={IconColor} onClick={onDownloadClick} />
      <Icon name="copy" color={IconColor} onClick={onCopyToClipboard} />
      <CopyToClipboardHighlight isCopied={isCopied}>
        {isCopied ? 'Copied!' : 'Copy to clipboard'}
      </CopyToClipboardHighlight>
    </ControlPanel>
  );
  // Vertical view for a single owner
  if (length === 1) {
    return (
      <Modal modalType={modalTypes.OWNER_REPORT} title="Owner Report" hideModal={onModalClose}>
        <ReportWrapper>
          {controls}
          <TableWrapper>
            <ReportTable>
              <TableHeader>
                <TableHeaderCell key="option">Metrics</TableHeaderCell>
                <TableHeaderCell key="value">Value</TableHeaderCell>
              </TableHeader>
              {reportColumns.map(({ Header, accessor, width, formatterName }) => (
                <TableRow key={accessor}>
                  <TableBodyCell key={accessor} width={`${width}px`}>
                    {Header}
                  </TableBodyCell>
                  {data?.map((row, index) => (
                    <TableBodyCell key={index} width={`${width}px`}>
                      {formatterName ? formatters[formatterName](row[accessor]) : row[accessor]}
                    </TableBodyCell>
                  ))}
                </TableRow>
              ))}
            </ReportTable>
          </TableWrapper>
        </ReportWrapper>
      </Modal>
    );
  }

  return (
    <Modal modalType={modalTypes.OWNER_REPORT} title="Owner Report" hideModal={onModalClose}>
      <ReportWrapper>
        {controls}
        <TableWrapper>
          <ReportTable>
            <TableHeader>
              {reportColumns.map(({ Header, accessor, width }) => (
                <TableHeaderCell key={accessor} width={`${width}px`}>
                  {Header}
                </TableHeaderCell>
              ))}
            </TableHeader>
            {data?.map((row, index) => (
              <TableRow key={index}>
                {reportColumns.map(({ accessor, width, formatterName }) => (
                  <TableBodyCell key={accessor} width={`${width}px`}>
                    {formatterName ? formatters[formatterName](row[accessor]) : row[accessor]}
                  </TableBodyCell>
                ))}
              </TableRow>
            ))}
          </ReportTable>
        </TableWrapper>
      </ReportWrapper>
    </Modal>
  );
};

export default OwnerReport;
