import React from 'react';

import { useSelector } from 'hooks';
import { systemStatuses } from 'common/enums/job';
import { selectServiceInstance, selectServiceInstanceLoading } from 'store/reducers/service';
import { selectTaskImageUrls } from 'store/reducers/runtimeCache';

import { LoadingSpinner, MediaGallery, NotExist } from 'components';
import {
  Description,
  HomeInfoRow,
  JobCoreInfo,
  JobNotes,
  JobTimingRow,
  JobSystemRow,
  UserInfoRow,
  LinkToApp
} from './containers';
import { UnfulfilledReason, SnoozedMessage } from './components';
import {
  HighlitedColumn,
  LoadingContainer,
  ServiceInfoSection,
  Links,
  FieldTitle,
  labelStyle,
  wrapperStyle,
  mediaStyle,
  placeholderStyle,
  mediaWrapperStyle
} from './styled';

export const TaskInfo = ({ jobId }) => {
  const job = useSelector(selectServiceInstance);
  const jobLoading = useSelector(selectServiceInstanceLoading);

  const signedImageUrls = useSelector(selectTaskImageUrls);

  const imageUrls = signedImageUrls[job.id];

  if (job.id !== jobId && jobLoading) {
    return (
      <LoadingContainer>
        <LoadingSpinner text="Please wait" type="logo" />
      </LoadingContainer>
    );
  }

  if (!job.id) {
    return <NotExist name="Job" id={jobId} />;
  }

  return (
    <ServiceInfoSection>
      {Boolean(job.status === systemStatuses.CANCELED && job.unfulfilled_reason) && (
        <UnfulfilledReason reasonId={job.unfulfilled_reason} additionalInfo={job.additional_info} />
      )}
      {Boolean(job.is_hold_status && job.date_end_hold) && <SnoozedMessage snoozeDate={job.date_end_hold} />}
      <UserInfoRow />
      <JobCoreInfo />
      <JobTimingRow />
      <HomeInfoRow />
      <Description />
      <MediaGallery
        media={job?.media}
        label="Attached Media"
        isOriginalForPreview
        mediaUrls={imageUrls}
        labelStyle={labelStyle}
        wrapperStyle={wrapperStyle}
        mediaStyle={mediaStyle}
        mediaWrapperStyle={mediaWrapperStyle}
        placeholderStyle={placeholderStyle}
        loading={job?.media?.length !== imageUrls?.length}
        showScroll
      />
      <Links>
        <FieldTitle>Job links</FieldTitle>
        <div>
          <LinkToApp name="Dobby app" link={`https://hellodobby.com/link/app/job?sid=${jobId}`} />
        </div>
        <LinkToApp name="DobbyPro app" link={`https://hellodobby.com/link/pro/job?sid=${jobId}`} />
      </Links>
      <HighlitedColumn>
        <JobSystemRow />
        <JobNotes jobId={jobId} />
      </HighlitedColumn>
    </ServiceInfoSection>
  );
};

export default TaskInfo;
