import { all, call, takeLatest, put } from 'redux-saga/effects';

import api_constants from 'constants/api';
import errors from 'constants/errors';
import actionTypes from 'constants/actionTypes';
import modalTypes from 'constants/modalTypes';
import { openModal } from 'store/actions';
import Api from 'services/ApiService';
import { uploadPublicFile } from 'common/helpers/media';

function* editCampaign({ payload }) {
  const { bannerPicture, postPicture, onSuccessCallback, campaign, campaign_push, execution, article, cohort_type } =
    payload;
  try {
    const image_preview_source = bannerPicture.file
      ? yield call(uploadPublicFile, {
          customPrefix: 'public/campaigns/',
          file: bannerPicture.file
        })
      : bannerPicture.previewUrl;

    const image_source = postPicture?.file
      ? yield call(uploadPublicFile, {
          customPrefix: 'public/campaigns/',
          file: postPicture.file
        })
      : postPicture.previewUrl;

    let uploadedCSVPath = '';
    if (execution.csv_cohort_path && typeof execution.csv_cohort_path !== 'string') {
      uploadedCSVPath = yield call(uploadPublicFile, {
        customPrefix: 'public/campaigns/cohorts/',
        file: execution.csv_cohort_path
      });
      if (!uploadedCSVPath) {
        throw new Error('CSV file failed to upload. Please try again');
      }
    }

    if (!image_source || !image_preview_source) {
      throw new Error('Images failed to upload. Please try again');
    }

    const [responseCampaign, responseCampaignExecute, resposeCampaignPush, responseArticle] = yield all([
      call(Api.amplifyPut, `${api_constants.CAMPAIGN}/${campaign?.id}`, { cohort_type }),
      call(Api.amplifyPut, `${api_constants.CAMPAIGN_EXECUTE}/${campaign?.campaign_execute?.id}`, {
        ...execution,
        ...(uploadedCSVPath && execution.csv_cohort_name ? { csv_cohort_path: uploadedCSVPath } : {})
      }),
      call(Api.amplifyPut, `${api_constants.CAMPAIGN_PUSH}/${campaign?.campaign_push?.id}`, campaign_push),
      call(Api.amplifyPut, `${api_constants.ARTICLE}/${campaign?.article?.id}`, {
        ...article,
        image_source,
        image_preview_source
      })
    ]);

    if (!responseCampaign || !responseCampaign?.id || responseCampaign.err) {
      throw new Error(responseCampaign?.err.response?.data.error_message);
    }

    if (!responseCampaignExecute || !responseCampaignExecute?.id || responseCampaignExecute.err) {
      throw new Error(responseCampaignExecute?.err.response?.data.error_message);
    }

    if (!resposeCampaignPush || !resposeCampaignPush?.id || resposeCampaignPush.err) {
      throw new Error(resposeCampaignPush?.err.response?.data.error_message);
    }

    if (!responseArticle || !responseArticle?.id || responseArticle.err) {
      throw new Error(responseArticle?.err.response?.data.error_message);
    }

    yield put({
      type: actionTypes.EDIT_CAMPAIGN_SUCCESS
    });

    if (onSuccessCallback) {
      onSuccessCallback({ hasEdit: true, campaign_id: responseCampaign.id });
    }

    yield put(
      openModal(modalTypes.RESULT, {
        title: `The "${resposeCampaignPush?.template_title}"\ncampaign successfully edited`
      })
    );
  } catch (err) {
    yield put({
      type: actionTypes.EDIT_CAMPAIGN_ERROR
    });

    yield put(
      openModal(modalTypes.ERROR, {
        errorMessage: err?.message || errors.DEFAULT
      })
    );
  }
}

export default function* watchEditCampaign() {
  yield takeLatest(actionTypes.EDIT_CAMPAIGN_FETCH, editCampaign);
}
