import { call, takeEvery, put } from 'redux-saga/effects';

import { apiPageSizes } from 'common/enums/queryParams';
import api_constants from 'constants/api';
import actionTypes from 'constants/actionTypes';
import Api from 'services/ApiService';

function* getAgentLocationList({ payload }) {
  const { page = 1, limit = apiPageSizes.DEFAULT, agent_id, is_active = true } = payload;
  const isActivated = is_active ? '1,2' : '0,3';
  try {
    const response = yield call(
      Api.amplifyGet,
      `${api_constants.AGENT_LOCATION}?page=${page}&limit=${limit}&real_estate_agent_id=${agent_id}&is_activated=${isActivated}&empty_location=true`
    );
    if (response && response.results) {
      yield put({
        type: actionTypes.GET_AGENT_LOCATION_LIST_SUCCESS,
        payload: {
          count: response.count,
          list: response.results,
          page,
          is_active,
          agent_id
        }
      });
    } else {
      yield put({
        type: actionTypes.GET_AGENT_LOCATION_LIST_ERROR,
        payload: {
          agent_id,
          is_active
        }
      });
    }
  } catch (err) {
    console.log(err);
  }
}

export default function* watchGetAgentLocationList() {
  yield takeEvery(actionTypes.GET_AGENT_LOCATION_LIST_FETCH, getAgentLocationList);
}
