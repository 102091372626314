import { call, takeLatest, put } from 'redux-saga/effects';
import api_constants from 'constants/api';
import actionTypes from 'constants/actionTypes';
import Api from 'services/ApiService';

function* getProviderAreaList() {
  try {
    const response = yield call(Api.amplifyGet, `${api_constants.SERVICE_AREA}/`);
    if (response) {
      yield put({
        type: actionTypes.GET_PROVIDER_AREAS_SUCCESS,
        payload: response
      });
    } else {
      yield put({
        type: actionTypes.GET_PROVIDER_AREAS_ERROR
      });
    }
  } catch (err) {
    console.log(err);
  }
}

export default function* watchGetProviderAreaList() {
  yield takeLatest(actionTypes.GET_PROVIDER_AREAS_FETCH, getProviderAreaList);
}
