import actionTypes from 'constants/actionTypes';

export const createPayment = (payload) => ({
  type: actionTypes.CREATE_PAYMENT_FETCH,
  payload
});

export const createPayout = (payload) => ({
  type: actionTypes.CREATE_PAYOUT_FETCH,
  payload
});

export const createQuotation = (payload) => ({
  type: actionTypes.CREATE_QUOTATION_FETCH,
  payload
});

export const deletePayment = (payload) => ({
  type: actionTypes.DELETE_PAYMENT_FETCH,
  payload
});

export const deleteQuotation = (payload) => ({
  type: actionTypes.DELETE_QUOTATION_FETCH,
  payload
});

export const getPaymentList = (payload) => ({
  type: actionTypes.GET_PAYMENT_LIST_FETCH,
  payload
});

export const getQuotationList = (payload) => ({
  type: actionTypes.GET_QUOTATION_LIST_FETCH,
  payload
});

export const toggleInvoiceForm = (payload) => ({
  type: actionTypes.TOGGLE_INVOICE_FORM,
  payload
});

export const updatePayment = (payload) => ({
  type: actionTypes.UPDATE_PAYMENT_FETCH,
  payload
});

export const closePayment = (payload) => ({
  type: actionTypes.CLOSE_PAYMENT_FETCH,
  payload
});

export const updateQuotation = (payload) => ({
  type: actionTypes.UPDATE_QUOTATION_FETCH,
  payload
});
