import { call, takeLatest, put } from 'redux-saga/effects';
import api_constants from 'constants/api';
import actionTypes from 'constants/actionTypes';
import Api from 'services/ApiService';

function* getRewards({ payload }) {
  const { user_id, page = 1 } = payload;
  try {
    const response = yield call(
      Api.amplifyGet,
      `${api_constants.REWARD}?page=${page}&status=0${user_id ? `&user_id=${user_id}` : ''}`
    );
    if (response && response.results) {
      yield put({
        type: actionTypes.GET_REWARDS_SUCCESS,
        payload: {
          user_id,
          totalCount: response.count,
          rewards: response.results
        }
      });
    } else {
      yield put({
        type: actionTypes.GET_REWARDS_ERROR
      });
    }
  } catch (err) {
    console.log(err);
  }
}

export default function* watchGetRewards() {
  yield takeLatest(actionTypes.GET_REWARDS_FETCH, getRewards);
}
