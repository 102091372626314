import React, { useEffect, useState } from 'react';
import { reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router';

import { useMemo } from 'hooks';
import { requestSources } from 'common/enums/campaigns';
import { adminStatuses, jobCategories, jobTypes, timeframeEstimateList } from 'common/enums/job';
import { modalIconProps } from 'common/enums/modal';
import errors from 'constants/errors';

import { selectCustomerLocation } from 'store/reducers/customer';
import messages from 'constants/messages';
import modalTypes from 'constants/modalTypes';
import styles from 'constants/styles';
import routes from 'constants/routes';
import { Modal, ModalConfirmation, ReduxForm, Button, AsyncSelectWrapper, MediaUploader } from 'components';
import { closeModal, createRequest, updateRequest } from 'store/actions';
import { selectServiceFormLoading } from 'store/reducers/service';
import { selectModalType, selectModalData } from 'store/reducers/modal';
import searchProvidersList from 'services/providers/SearchProvidersList';
import searchCustomersList from 'services/customers/SearchCustomersList';

import { FlexRow, Row, ContentWrapper, Heading, Label } from './styled';
import { generateConfirmationSubheading } from './helpers/job';
import { useTheme } from 'styled-components';

const enabledJobTypes = Object.values(jobTypes).filter((item) => item.is_active);
const getCategoriesForJobType = ({ jobTypeId }) =>
  jobCategories.filter(({ job_types }) => job_types?.includes(jobTypeId));

const defaultValues = {
  user_id: null,
  service_provider_id: null,
  job_category_id: getCategoriesForJobType({ jobTypeId: jobTypes.NORMAL.id })?.[0]?.id,
  job_type: jobTypes.NORMAL.id,
  timeframe_estimate: timeframeEstimateList[1].id,
  admin_status: adminStatuses.REQUESTED.TYPE,
  description: ''
};

const Task = ({
  modalType = modalTypes.TASK,
  openedModalType,
  modalData = {},
  isServiceCreateLoading,
  closeModal,
  initialize,
  valid,
  handleSubmit,
  updateRequest,
  createRequest,
  selectedDescription,
  selectedJobTypeId,
  selectedJobCategoryId,
  selectedTimeframeEstimate,
  change,
  customerLocation
}) => {
  const { isEditing = false, isPrefilled = false, taskInstance = {} } = modalData;

  const [attachments, setAttachments] = useState([]);
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);
  const [selectedProviderId, setSelectedProviderId] = useState(null);
  const [isCustomerSelectValid, setCustomerSelectValid] = useState(true);
  const [isConfirmationOpened, setIsConfirmationOpened] = useState(false);
  const [submitResult, setSubmitResult] = useState({ isOpen: false, result: true });

  const navigate = useNavigate();

  const availableTimeframeEstimates = timeframeEstimateList.filter((estimate) =>
    estimate.job_types.includes(selectedJobTypeId || jobTypes.NORMAL.id)
  );

  useEffect(() => {
    if (openedModalType === modalTypes.TASK) {
      const { taskInstance = {} } = modalData;
      const {
        user_id = defaultValues.user_id,
        service_provider_id = defaultValues.service_provider_id,
        job_category_id = defaultValues.job_category_id,
        job_type = defaultValues.job_type,
        timeframe_estimate = defaultValues.timeframe_estimate,
        admin_status = defaultValues.admin_status,
        description = defaultValues.description,
        media = []
      } = taskInstance;

      initialize({
        job_category_id,
        job_type,
        timeframe_estimate,
        admin_status,
        description
      });
      setSelectedCustomerId(user_id);
      setSelectedProviderId(service_provider_id);

      const attachmentsFromMedia = media.map(({ media_id, media }) => ({
        media_id,
        file: { id: media_id, name: media.original_name },
        previewUrl: media.thumbnail_path,
        isUploading: false
      }));
      setAttachments(attachmentsFromMedia);
    }
    setSelectedCustomerId(modalData?.userDetails?.id || taskInstance.user_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalData]);

  useEffect(() => {
    // to change selected category on each job type change
    const categories = getCategoriesForJobType({
      jobTypeId: selectedJobTypeId
    });
    if (selectedJobCategoryId && categories.some((category) => category.id === selectedJobCategoryId)) {
      return;
    }
    change('job_category_id', categories[0]?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedJobTypeId]);

  useEffect(() => {
    // to change selected timeframe estimate on each job type change
    if (!selectedJobTypeId) {
      return;
    }
    if (selectedTimeframeEstimate && availableTimeframeEstimates.some(({ id }) => id === selectedTimeframeEstimate)) {
      return;
    }
    change('timeframe_estimate', availableTimeframeEstimates[0]?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedJobTypeId]);

  const onModalClose = () => {
    closeModal();
    setAttachments([]);
  };

  const handleCloseTaskResult = () => {
    setIsConfirmationOpened(false);
    setSubmitResult({ isOpen: false, result: true });
    closeModal(modalTypes.TASK);
  };

  const handleOpenNewJob = (jobId) => {
    navigate(`${routes.SERVICE}/${jobId}`);
    handleCloseTaskResult();
  };

  const onSubmitForm = (data) => {
    if (selectedCustomerId === null) {
      setCustomerSelectValid(false);
      return false;
    }

    const extendedData = {
      ...data,
      user_id: selectedCustomerId,
      service_provider_id: selectedProviderId,
      request_source_type: requestSources.GRYFFINDOR,
      request_source_name: 'Create job',
      attachments,
      onRequestProcessed: (isProcessed, jobId) => {
        setSubmitResult({ isOpen: true, result: isProcessed });

        if (!isEditing && isProcessed) {
          setTimeout(() => handleOpenNewJob(jobId), 3000);
        }
      }
    };
    if (isEditing) {
      updateRequest({
        ...extendedData,
        id: taskInstance.id
      });
    } else {
      createRequest(extendedData);
    }
  };

  const handleAttachedFiles = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const { files } = e.target;
    if (files && files.length) {
      const uploadedAttachments = Array.from(files, (file) => {
        const previewUrl = URL.createObjectURL(file);
        return { file, previewUrl, isUploading: false };
      });
      setAttachments([...attachments, ...uploadedAttachments]);
    }
  };

  const removeAttachedFile = (mediaId) => (event) => {
    event.stopPropagation();
    event.preventDefault();

    setAttachments(attachments.filter((_item, index) => index !== mediaId));
  };

  const confirmationSubheading = useMemo(
    () =>
      generateConfirmationSubheading({
        job: taskInstance,
        formValues: {
          attachments,
          timeframe_estimate: selectedTimeframeEstimate,
          description: selectedDescription
        }
      }),
    [taskInstance, attachments, selectedTimeframeEstimate, selectedDescription]
  );
  const theme = useTheme();
  return (
    <Modal
      modalType={modalType}
      hideModal={onModalClose}
      closeButton
      heading={isEditing ? `Edit Job ID ${taskInstance.id || ''}` : 'Create New Job'}
      middlePaddings
      padding="0"
      maxWidth={0}
      width={776}
      minWidth={776}
      maxHeight={0}
      background={theme.colors.DARK_2}>
      <ContentWrapper>
        <Row>
          <AsyncSelectWrapper
            label="HO Name (ID)"
            placeholder="Find homeowner"
            initialValue={
              isEditing || isPrefilled || modalData?.userDetails
                ? {
                    id: modalData?.userDetails?.id || taskInstance.user_id,
                    user: modalData?.userDetails || taskInstance.user
                  }
                : {}
            }
            disabled
            searchValueList={searchCustomersList}
            onSelectedValueChange={(user) => setSelectedCustomerId(user?.id)}
            isValid={isCustomerSelectValid}
            isDisabled={modalData?.userDetails}
            invalidMessage={messages.formErrors.HOMEOWNER_REQUIRED}
          />
          <AsyncSelectWrapper
            label="Pro Name (ID)"
            placeholder="Find a Pro"
            initialValue={
              (isEditing || isPrefilled) && taskInstance.service_provider_id
                ? { id: taskInstance.service_provider_id, user: taskInstance.service_provider.user }
                : {}
            }
            searchValueList={(values) => {
              const preparedValues = {
                ...values,
                operational_area_id: modalData?.userDetails
                  ? customerLocation?.operational_area_id
                  : taskInstance?.operational_area_id
              };
              return searchProvidersList(preparedValues);
            }}
            onSelectedValueChange={(user) => setSelectedProviderId(user?.id)}
          />
        </Row>

        <FlexRow>
          <ReduxForm.SelectWithLabel
            name="job_type"
            label="Type"
            valuePlaceholder={
              isEditing || isPrefilled
                ? Object.values(jobTypes).find(({ id }) => id === taskInstance?.job_type)?.name
                : ''
            }
            placeholder="Choose Type"
            width={300}
            options={enabledJobTypes}
          />
        </FlexRow>
        <FlexRow>
          <ReduxForm.SelectWithLabel
            name="job_category_id"
            label="Category"
            valuePlaceholder={
              isPrefilled
                ? jobCategories.find(
                    ({ id, job_types }) =>
                      id === taskInstance?.job_category_id &&
                      job_types?.some((jobType) => jobType === taskInstance?.job_type)
                  )?.name
                : ''
            }
            placeholder="Choose Category"
            width={300}
            options={getCategoriesForJobType({
              jobTypeId: selectedJobTypeId
            })}
          />
        </FlexRow>
        <FlexRow>
          <ReduxForm.SelectWithLabel
            name="timeframe_estimate"
            label="Timeframe estimate"
            placeholder="Choose timeframe"
            width={300}
            options={availableTimeframeEstimates}
          />
        </FlexRow>
        <FlexRow>
          <ReduxForm.Textarea name="description" label="Add Description" placeholder="" rows={6} width={360} />
        </FlexRow>
        <Label>Attach Media</Label>
        <MediaUploader files={attachments} setFiles={setAttachments} />
        <FlexRow>
          <Button title="Cancel" variant="primary" margin="0 20px 0 0" onClick={onModalClose} />
          <Button
            title={isEditing ? 'Edit' : 'Create'}
            onClick={() => setIsConfirmationOpened(true)}
            disabled={!valid || isServiceCreateLoading}
          />
        </FlexRow>
      </ContentWrapper>
      <Modal
        visible={isConfirmationOpened}
        hideModal={() => setIsConfirmationOpened(false)}
        closeButton
        backgroundColor={styles.colors.WHITE}
        middlePaddings
        maxWidth={0}
        maxHeight={0}>
        <FlexRow>
          <Heading>{`Confirm job ${isEditing ? 'editing' : 'creation'}?`}</Heading>
        </FlexRow>
        {Boolean(confirmationSubheading) && (
          <FlexRow>
            <Label>{confirmationSubheading}</Label>
          </FlexRow>
        )}
        <FlexRow>
          <Button
            title={'No'}
            variant="primary"
            margin="0 20px 0 0"
            onClick={() => setIsConfirmationOpened(false)}
            disabled={isServiceCreateLoading}
          />
          <Button title={'Yes'} onClick={handleSubmit(onSubmitForm)} loading={isServiceCreateLoading} />
        </FlexRow>
      </Modal>
      <ModalConfirmation
        visible={submitResult.isOpen}
        title={submitResult.result ? `Successful job ${isEditing ? 'editing' : 'creation'}` : errors.DEFAULT}
        text={submitResult.result ? 'Changes are saved' : 'Check the error logs'}
        iconProps={submitResult.result ? modalIconProps.TASK_OK : modalIconProps.TASK_ERROR}
        padding="40px 25px 20px 25px"
        hideModal={handleCloseTaskResult}
        secondaryButton={{
          title: 'Ok',
          onClick: handleCloseTaskResult
        }}
      />
    </Modal>
  );
};

const selector = formValueSelector('taskForm');

const mapStateToProps = (state) => ({
  customerLocation: selectCustomerLocation(state),
  openedModalType: selectModalType(state),
  modalData: selectModalData(state),
  isServiceCreateLoading: selectServiceFormLoading(state),
  selectedJobTypeId: selector(state, 'job_type'),
  selectedJobCategoryId: selector(state, 'job_category_id'),
  selectedTimeframeEstimate: selector(state, 'timeframe_estimate'),
  selectedDescription: selector(state, 'description')
});
const mapDispatchToProps = {
  closeModal,
  updateRequest,
  createRequest
};

const createReduxForm = reduxForm({
  form: 'taskForm',
  fields: [
    'user_id',
    'service_provider_id',
    'job_category_id',
    'job_type',
    'description',
    'timeframe_estimate',
    'admin_status'
  ],
  initialValues: defaultValues
});

export default connect(mapStateToProps, mapDispatchToProps)(createReduxForm(Task));
