import { wsEvents } from 'common/enums/notification';

export const findWsEventType = ({ action, type, data }) =>
  Object.values(wsEvents).find(({ EVENT }) => {
    if (EVENT.type && EVENT.type !== type) {
      return false;
    }

    if (EVENT.action && EVENT.action !== action) {
      return false;
    }

    if (!EVENT.data) {
      return true;
    }

    const isDataMismatch = Object.keys(EVENT.data).some((key) => data[key] !== EVENT.data[key]);

    return !isDataMismatch;
  });
