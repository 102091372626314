import { markerTypes } from '../enums';

export const findMarkerType = ({ selectedProId, selectedVisitId, service_provider_id, visit_id, isHovered }) => {
  if (selectedVisitId && visit_id === selectedVisitId) {
    return markerTypes.SELECTED_VISIT;
  }

  if (selectedProId === service_provider_id) {
    return markerTypes.SELECTED_PRO;
  }

  if (isHovered) {
    return markerTypes.HOVERED;
  }

  if (!selectedProId) {
    return markerTypes.DEFAULT;
  }

  return markerTypes.DISABLED;
};
