import { call, takeLatest, put } from 'redux-saga/effects';
import api_constants from 'constants/api';
import actionTypes from 'constants/actionTypes';
import modalTypes from 'constants/modalTypes';
import Api from 'services/ApiService';
import { chatMessageTransformer } from 'utils';
import { openModal } from 'store/actions/modal';
import errors from 'constants/errors';
import messages from 'constants/messages';

function* updateSupportMessage({ payload }) {
  const { message, description, userID, onSuccess } = payload;
  try {
    const response = yield call(Api.amplifyPut, `${api_constants.SUPPORT_REQUEST}/${message._id}`, {
      message: description
    });
    if (response.err) {
      throw new Error(errors.DEFAULT);
    }
    const newMessage = chatMessageTransformer(response);
    yield put({
      type: actionTypes.UPDATE_SUPPORT_MESSAGE_SUCCESS,
      payload: { message: newMessage, userID: userID }
    });
    if (onSuccess) {
      onSuccess();
    }
  } catch (err) {
    yield put({
      type: actionTypes.UPDATE_SUPPORT_MESSAGE_ERROR
    });
    yield put(
      openModal(modalTypes.RESULT, {
        title: messages.requstSucceed.UPDATE_MESSAGE_TITLE,
        text: messages.requstSucceed.UPDATE_MESSAGE_TEXT
      })
    );
  }
}

export default function* watchUpdateSupportMessage() {
  yield takeLatest(actionTypes.UPDATE_SUPPORT_MESSAGE_FETCH, updateSupportMessage);
}
