import React from 'react';
import { createSearchParams as createQueryParams } from 'react-router-dom';

import { sidebarMenuItems } from 'common/enums/navigation';
import { isHOCohortMenuItem } from 'common/helpers/navigation';
import { getDefaultNavigationParams } from 'common/helpers/queryParams';

import {
  MenuItemsDivider,
  NavigationTitleBadge,
  NavigationLink,
  NavigationSubHeader,
  NavigationTitle,
  ListItem
} from './styled';

const MenuItem = ({ active, onMenuItemChange, menuItem, menuBadge }) => {
  const isSectionHeader = [
    sidebarMenuItems.JOB_MANAGEMENT.TYPE,
    sidebarMenuItems.JOB_STAGES.TYPE,
    sidebarMenuItems.CRM.TYPE,
    sidebarMenuItems.MARKETING.TYPE
  ].includes(menuItem.TYPE);

  if (isSectionHeader) {
    return (
      <ListItem>
        <NavigationTitle bigTopPadding>{menuItem.NAME}</NavigationTitle>
      </ListItem>
    );
  }

  if (menuItem.TYPE === sidebarMenuItems.DIVIDER.TYPE) {
    return <MenuItemsDivider />;
  }

  const isFirstLevelTitle = [
    sidebarMenuItems.ANALYTICS?.TYPE,
    sidebarMenuItems.REWARDS?.TYPE,
    sidebarMenuItems.COMMUNICATION?.TYPE
  ].includes(menuItem.TYPE);

  const isThirdLevelMenuItem = isHOCohortMenuItem(menuItem.TYPE);
  const badgeCount = menuItem.COUNTER_NAME && menuBadge;
  const defaultNavigationParams = getDefaultNavigationParams({ menuItem });

  const search = createQueryParams(defaultNavigationParams).toString();

  return (
    <ListItem>
      <NavigationLink
        active={active}
        onClick={onMenuItemChange({ menuItem, search })}
        to={{ pathname: menuItem.ROUTE, search }}>
        {isFirstLevelTitle && (
          <NavigationTitle>
            {menuItem.NAME} {Boolean(badgeCount) && <NavigationTitleBadge>{badgeCount}</NavigationTitleBadge>}
          </NavigationTitle>
        )}
        {!isFirstLevelTitle && (
          <NavigationSubHeader isThirdLevelMenuItem={isThirdLevelMenuItem}>
            <span>{menuItem.NAME}</span>
            {Boolean(badgeCount) && <NavigationTitleBadge>{badgeCount}</NavigationTitleBadge>}
          </NavigationSubHeader>
        )}
      </NavigationLink>
    </ListItem>
  );
};

export default MenuItem;
