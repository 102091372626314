import { call, takeLatest, put } from 'redux-saga/effects';
import api_constants from 'constants/api';
import actionTypes from 'constants/actionTypes';
import { openModal } from 'store/actions/modal';
import modalTypes from 'constants/modalTypes';
import Api from 'services/ApiService';
import { oneClickActions } from 'common/enums/user';

function* updateUserInstance(action) {
  const {
    payload: { id, userGroupId, updateAction, ...user }
  } = action;
  try {
    const response = yield call(Api.amplifyPut, `${api_constants.USER}/${id}`, user);
    if (response) {
      yield put({
        type: actionTypes.UPDATE_USER_INSTANCE_SUCCESS,
        payload: {
          user: response,
          userGroupId,
          updateAction,
          id
        }
      });
    } else {
      yield put({
        type: actionTypes.UPDATE_USER_INSTANCE_ERROR,
        payload: {
          id,
          userGroupId,
          updateAction
        }
      });
    }
    if (updateAction === oneClickActions.IS_ACTIVE) {
      yield put(openModal(modalTypes.USER_TOGGLE_IS_ACTIVATE_RESULT));
    }
  } catch (err) {
    yield put({
      type: actionTypes.UPDATE_USER_INSTANCE_ERROR,
      payload: {
        id,
        userGroupId,
        updateAction
      }
    });
    if (updateAction === oneClickActions.IS_ACTIVE) {
      yield put(openModal(modalTypes.USER_TOGGLE_IS_ACTIVATE_RESULT));
    }
    console.log(err);
  }
}

export default function* watchUpdateUserInstance() {
  yield takeLatest(actionTypes.UPDATE_USER_INSTANCE_FETCH, updateUserInstance);
}
