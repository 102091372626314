import actionTypes from 'constants/actionTypes';

export const getPromoCodeList = (data) => ({
  type: actionTypes.GET_PROMO_CODE_LIST_FETCH,
  payload: data
});

export const createPromoCode = (data) => ({
  type: actionTypes.CREATE_PROMO_CODE_FETCH,
  payload: data
});

export const updatePromoCode = (data) => ({
  type: actionTypes.UPDATE_PROMO_CODE_FETCH,
  payload: data
});

export const createReward = (payload) => ({
  type: actionTypes.CREATE_REWARD_FETCH,
  payload
});

export const deleteReward = (payload) => ({
  type: actionTypes.DELETE_REWARD_FETCH,
  payload
});

export const expirePromoCode = (payload) => ({
  type: actionTypes.EXPIRE_PROMO_CODE_FETCH,
  payload
});

export const getReferralList = (payload) => ({
  type: actionTypes.GET_REFERRAL_LIST_FETCH,
  payload
});
