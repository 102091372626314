import React from 'react';
import { useWatch } from 'react-hook-form';

import { Icon } from 'components';

import styles from 'constants/styles';
import { invoiceTypes, commissionTypes } from 'common/enums/invoice';
import { additionalCommissionInfo } from '../../../../constants';

import { formatters } from 'utils';
import { getAdditionalPayoutCommission } from '../../helpers/invoices';

import {
  AdditionalInfo,
  BorderedAdditionalInfo,
  Column,
  ListItemInfo,
  IconInfo,
  IconWrapper,
  ListMarker
} from './styled';

export const CommissionDebt = ({
  control,
  watchFieldName,
  defaultWatchValue = [],
  invoiceType,
  commissionType,
  current_commission_debt = 0,
  depositTotalProviderPayout
}) => {
  const [percent_commission, amount_tip, deposit, labor, material, amount_deposit, amount_labor_cost, amount_material] =
    useWatch({
      control,
      name: watchFieldName,
      defaultValue: defaultWatchValue
    });

  const additionalPayoutCommission = getAdditionalPayoutCommission({
    invoice_type: invoiceType,
    amount_tip,
    deposit,
    labor,
    material,
    amount_deposit,
    amount_labor_cost,
    amount_material,
    percent_commission: parseFloat(percent_commission || 0) * 100,
    current_commission_debt
  });

  if (commissionType !== commissionTypes.SUB_FEE) {
    return null;
  }

  if (invoiceType === invoiceTypes.DEPOSIT.value) {
    return (
      <Column>
        <AdditionalInfo>
          <IconWrapper>
            <Icon name="info" backgroundColor={styles.colors.DARK_BLUE} backgroundSize={16} size={3.5} />
          </IconWrapper>
          <IconInfo>{additionalCommissionInfo.DEPOSIT}</IconInfo>
        </AdditionalInfo>
      </Column>
    );
  }

  if ([invoiceTypes.PARTIAL.value, invoiceTypes.FINAL.value].includes(invoiceType)) {
    return (
      <Column>
        <BorderedAdditionalInfo>
          <IconWrapper>
            <Icon name="info" backgroundColor={styles.colors.DARK_BLUE} backgroundSize={16} size={3.5} />
          </IconWrapper>
          <IconWrapper>
            <Column>
              <IconWrapper>
                <ListMarker> &bull; </ListMarker>
                <ListItemInfo>
                  {additionalCommissionInfo.DEPOSIT_COMMISSION_DEBT(
                    formatters.dollarCurrencyDB(depositTotalProviderPayout)
                  )}
                </ListItemInfo>
              </IconWrapper>
              {Boolean(additionalPayoutCommission) && (
                <IconWrapper>
                  <ListMarker> &bull; </ListMarker>
                  <ListItemInfo>
                    {additionalCommissionInfo.COMMISSION(formatters.dollarCurrencyDB(additionalPayoutCommission))}
                  </ListItemInfo>
                </IconWrapper>
              )}
            </Column>
          </IconWrapper>
        </BorderedAdditionalInfo>
      </Column>
    );
  }

  return null;
};
