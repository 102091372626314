import React, { useCallback, useEffect } from 'react';
import { useMemo, useDispatch, useSelector, useRouter } from 'hooks';

import { conversationTypes } from 'common/enums/chat';
import { getAgentInstance, deletePersistentNotification } from 'store/actions';
import { resetUserChatUnreadMessagesDivider, resetUserChatUnreadMessagesMark } from 'store/actions/supportChat';

import { selectAgentInstance, selectAgentInstanceLoading } from 'store/reducers/agent';
import { isTestTasksVisible } from 'store/reducers/app';

import { userGroups } from 'common/enums/user';
import styles from 'constants/styles';
import routes from 'constants/routes';
import { Chat, UserDetails, AgentLocationLists, Questions } from 'containers';
import { LoadingSpinner, OUTDATED_TABS, NotExist } from 'components';

import { ActionContainer, LoadingContainer, ColumnContainer, Sections } from './styled';

const AgentPage = () => {
  const dispatch = useDispatch();
  const {
    params,
    location: { state: locationState }
  } = useRouter();
  const {
    instance = {},
    instanceLoading = false,
    testTasksVisible
  } = useSelector((state) => ({
    instance: selectAgentInstance(state),
    instanceLoading: selectAgentInstanceLoading(state),
    testTasksVisible: isTestTasksVisible(state)
  }));
  const agentId = useMemo(() => Number(params.agent_id), [params.agent_id]);

  const deleteSupportChatPersistentNotifications = useCallback(() => {
    if (agentId && !locationState?.openByNotif) {
      dispatch(
        deletePersistentNotification({
          user_id: agentId,
          conversation_type: conversationTypes.SUPPORT
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agentId, locationState, testTasksVisible]);

  useEffect(() => {
    dispatch(getAgentInstance(agentId));
    deleteSupportChatPersistentNotifications();

    return () => {
      dispatch(resetUserChatUnreadMessagesDivider(agentId));
      dispatch(resetUserChatUnreadMessagesMark(agentId));
    };
  }, [agentId, deleteSupportChatPersistentNotifications, dispatch]);

  const handleHomeownerNavigate = ({ customerId }) => {
    window.open(`${routes.CUSTOMER}/${customerId}`, '_blank');
  };

  if (instance.id !== agentId && instanceLoading) {
    return (
      <LoadingContainer>
        <LoadingSpinner text="Please wait" type="logo" />
      </LoadingContainer>
    );
  }

  if (!instance.id) {
    return <NotExist name="Agent" id={agentId} />;
  }

  const agentDetails = {
    user: {
      ...instance.user,
      about: instance.about,
      additional_user_id: instance.id,
      company_name: instance.company_name || instance.company?.name,
      team: instance.team,
      position: instance.position,
      website_link: instance.website_link,
      profile_image_thumbnail: instance.profile_image_url
    },
    deviceData: instance.platform,
    userGroupId: userGroups.AGENT.TYPE
  };

  return (
    <Sections>
      <ColumnContainer>
        <UserDetails {...agentDetails} />
      </ColumnContainer>
      <ActionContainer>
        <OUTDATED_TABS
          headers={['Direct Chat', 'Check the Price', 'Invited Homeowners']}
          minHeight={`calc(100vh - ${styles.layout.headerHeight}px)`}
          boldFont>
          <Chat userId={agentId} userInstance={instance.user} />
          <Questions hasAgent />
          <AgentLocationLists agentId={agentId} openInstance={handleHomeownerNavigate} />
        </OUTDATED_TABS>
      </ActionContainer>
    </Sections>
  );
};

export default AgentPage;
