import { call, takeLatest, put } from 'redux-saga/effects';
import api_constants from 'constants/api';
import actionTypes from 'constants/actionTypes';
import Api from 'services/ApiService';
import { openModal } from 'store/actions/modal';
import errors from 'constants/errors';
import modalTypes from 'constants/modalTypes';
import { getNotificationList } from 'store/actions/notification';

function* deletePersistentNotification({ payload }) {
  const {
    notification_id = null,
    conversation_type = null,
    service_provider_id = null,
    request_id = null,
    user_id = null,
    reloadNotificationList = true,
    isClearAll
  } = payload;
  try {
    yield call(Api.amplifyDel, `${api_constants.NOTIFICATION}${notification_id || ''}`, {
      ...(request_id ? { request_id } : {}),
      ...(user_id ? { user_id, conversation_type } : {}),
      ...(service_provider_id && { service_provider_id })
    });
    yield put({
      type: actionTypes.DELETE_PERSISTENT_NOTIFICATION_SUCCESS,
      payload
    });

    if (reloadNotificationList && !isClearAll) {
      yield put(getNotificationList({ page: 1 }));
    }

    if (isClearAll) {
      yield put(
        openModal(modalTypes.CLEAR_ALL_PERSISTENT_NOTIFICATIONS_RESULT, {
          title: 'Success',
          text: `All notifications have been cleared from notification bar.`
        })
      );
    }
  } catch (err) {
    yield put({
      type: actionTypes.DELETE_PERSISTENT_NOTIFICATION_ERROR,
      payload
    });

    if (isClearAll) {
      yield put(
        openModal(modalTypes.CLEAR_ALL_PERSISTENT_NOTIFICATIONS_RESULT, {
          title: 'Error',
          text: errors.DEFAULT
        })
      );
    }
  }
}

export default function* watchDeletePersistentNotification() {
  yield takeLatest(actionTypes.DELETE_PERSISTENT_NOTIFICATION_FETCH, deletePersistentNotification);
}
