export const getRows = ({ list }) => {
  return list.map((job) => ({
    id: job.id,
    amount_total_paid: job.amount_total_paid,
    admin_status: job.admin_status,
    datetime_visit: job.datetime_visit,
    datetime_window_end: job.datetime_window_end,
    job_category_name: job.job_category_name,
    date_created: job.date_created,
    datetime_completed: job.datetime_completed,
    customer: job.user,
    service_provider: job.service_provider?.user,
    pro_stage: job.service_provider?.pro_stage,
    warning_type: job.warning_type,
    warning_value: job.warning_value,
    is_past_scheduled: job.is_past_scheduled,
    is_warning_enabled: job.is_warning_enabled,
    is_warning_checked: job.is_warning_checked,
    datetime_last_note: job.datetime_last_note,
    last_note: job.last_note
  }));
};
