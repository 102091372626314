import { all, takeLatest, put } from 'redux-saga/effects';
import actionTypes from 'constants/actionTypes';

const taskActions = {
  MAIN: [actionTypes.GET_SERVICE_INSTANCE_FETCH, actionTypes.GET_SERVICE_PROGRESS_LIST_FETCH],
  PAYMENT: [actionTypes.GET_TASK_QUOTATION_LIST_FETCH, actionTypes.GET_TASK_PAYMENT_LIST_FETCH],
  ADDITIONAL: [actionTypes.GET_SERVICE_LOCATION_FETCH, actionTypes.GET_SERVICE_NOTE_LIST_FETCH]
};

function* refreshTaskInfo({ payload: { task_id, silent, isAllInfo, isPaymentInfo } }) {
  const payload = { serviceId: task_id, silent };
  try {
    yield all(
      [
        ...taskActions.MAIN,
        ...(isAllInfo || isPaymentInfo ? taskActions.PAYMENT : []),
        ...(isAllInfo ? taskActions.ADDITIONAL : [])
      ].map((type) => put({ type, payload }))
    );
  } catch (err) {
    console.log(err);
  }
}

export default function* watchRefreshTaskInfo() {
  yield takeLatest(actionTypes.REFRESH_TASK_INFO, refreshTaskInfo);
}
