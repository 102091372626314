import React from 'react';
import { useSelector } from 'react-redux';

import { useDispatch } from 'hooks';
import { setDarkMode } from 'store/actions';
import { isDarkMode } from 'store/reducers/app';
import { Switch } from 'components';

import { SwitchWrapper, SwitchLabel } from './styled';

const DarkModeToggle = () => {
  const dispatch = useDispatch();
  const hasDarkMode = useSelector(isDarkMode);

  const handleToggleTheme = (value) => {
    dispatch(setDarkMode(value));
  };

  return (
    <>
      <SwitchLabel>{`${hasDarkMode ? 'Dark' : 'Light'} mode`}</SwitchLabel>
      <SwitchWrapper>
        <Switch checked={hasDarkMode} onChange={handleToggleTheme} />
      </SwitchWrapper>
    </>
  );
};

export default DarkModeToggle;
