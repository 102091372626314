import styled from 'styled-components';

export const DateInput = styled.div`
  margin: 5px 0 16px 0;
  input {
    color: ${({ theme }) => theme.colors.DARK_WHITE0} !important;
  }
`;

export const Content = styled.div`
  padding: 24px;
  width: 100%;
`;

export const ButtonsWrapper = styled.div`
  background: ${({ theme }) => theme.colors.DARK_3};
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
`;
