import React from 'react';

import signOut from 'services/auth/SignOut';
import { DropdownMenu, IconButton } from 'components';
import { NotificationIconWrapper, UnreadNotificationMark, FilterButtons } from './styled';
import styles from 'constants/styles';

const ProfileSettings = ({
  hasCustomFiltersButton,
  hasNotificationsBadge,
  onToggleCustomFiltersForm,
  onToggleOwnerReportForm,
  onOpenNotificationsPopup
}) => {
  const profileMenuOptions = [
    {
      id: 1,
      name: 'Sign Out',
      onClick: signOut
    }
  ];

  return (
    <>
      {hasCustomFiltersButton && (
        <FilterButtons>
          <IconButton iconName="filters" square size={32} onClick={onToggleCustomFiltersForm} />
          <IconButton iconName="file" square size={32} onClick={onToggleOwnerReportForm} />
        </FilterButtons>
      )}
      <NotificationIconWrapper onClick={onOpenNotificationsPopup}>
        <IconButton iconName="bellFill" square size={32} />
        {hasNotificationsBadge && <UnreadNotificationMark />}
      </NotificationIconWrapper>
      <DropdownMenu
        options={profileMenuOptions}
        placeholder="My Profile"
        menuType="avatar"
        buttonColor={styles.colors.LIGHT_GRAY_2}
      />
    </>
  );
};

export default ProfileSettings;
