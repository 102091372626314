import { siblingPaginationTypes } from 'common/enums/navigation';
import { apiPageSizes } from 'common/enums/queryParams';
import routes from 'constants/routes';

import {
  selectAgentListPage,
  selectAgentInstanceId,
  selectAgentDashboard,
  selectAgentTotalCount
} from 'store/reducers/agent';
import {
  selectCustomerListPage,
  selectCustomerInstanceId,
  selectCustomerList,
  selectCustomersTotalCount,
  selectCustomerReviews,
  selectCustomerReviewsCount,
  selectCustomerReviewsPage
} from 'store/reducers/customer';
import { selectPaymentsList, selectPaymentsCount, selectPaymentsPage } from 'store/reducers/invoice';
import { selectQuotationList, selectQuotationCount, selectQuotationPage } from 'store/reducers/invoice';
import { selectReferralCount, selectReferralList, selectReferralPage } from 'store/reducers/promoCode';
import {
  selectProviderInstanceId,
  selectProviderDashboard,
  selectProviderListPage,
  selectProvidersTotalCount,
  selectProviderReviews,
  selectProviderReviewsCount,
  selectProviderReviewsPage
} from 'store/reducers/provider';

import { selectReviewCount, selectReviewList, selectReviewPage } from 'store/reducers/review';
import {
  selectServiceInstanceId,
  selectServiceList,
  selectServiceTotalCount,
  selectJobsListPage
} from 'store/reducers/service';
import { getProviderJobsSelectors, getCustomerJobsSelectors } from './getSelectors';
import {
  selectCampaignsInstanceId,
  selectCampaignsList,
  selectCampaignsListPage,
  selectCampaignsListTotalCount
} from 'store/reducers/campaigns';

export const getPageSelectors = ({ jobType, paginationType } = {}) => {
  switch (paginationType) {
    case siblingPaginationTypes.AGENT:
      return {
        name: 'Agent',
        route: routes.AGENT,
        selectInstanceId: selectAgentInstanceId,
        selectList: selectAgentDashboard,
        selectTotalCount: selectAgentTotalCount,
        selectPage: selectAgentListPage
      };
    case siblingPaginationTypes.PROVIDER:
      return {
        name: 'Provider',
        route: routes.PROVIDER,
        selectInstanceId: selectProviderInstanceId,
        selectList: selectProviderDashboard,
        selectTotalCount: selectProvidersTotalCount,
        selectPage: selectProviderListPage
      };
    case siblingPaginationTypes.CUSTOMER:
      return {
        name: 'Homeowner',
        route: routes.CUSTOMER,
        selectInstanceId: selectCustomerInstanceId,
        selectList: selectCustomerList,
        selectTotalCount: selectCustomersTotalCount,
        selectPage: selectCustomerListPage
      };
    case siblingPaginationTypes.CUSTOMER_JOB:
      return {
        name: `Homeowner's Job`,
        route: routes.SERVICE,
        pageSize: apiPageSizes.SMALL,
        selectInstanceId: selectServiceInstanceId,
        ...getCustomerJobsSelectors(jobType)
      };
    case siblingPaginationTypes.PAYMENT_JOB:
      return {
        name: `Job`,
        route: routes.SERVICE,
        selectInstanceId: selectServiceInstanceId,
        selectList: selectPaymentsList,
        selectTotalCount: selectPaymentsCount,
        selectPage: selectPaymentsPage,
        isPayment: true
      };
    case siblingPaginationTypes.QUOTATION_JOB:
      return {
        name: `Job`,
        route: routes.SERVICE,
        selectInstanceId: selectServiceInstanceId,
        selectList: selectQuotationList,
        selectTotalCount: selectQuotationCount,
        selectPage: selectQuotationPage,
        isPayment: true
      };
    case siblingPaginationTypes.PROVIDER_JOB:
      return {
        name: `Provider's Job`,
        route: routes.SERVICE,
        pageSize: apiPageSizes.SMALL,
        selectInstanceId: selectServiceInstanceId,
        ...getProviderJobsSelectors(jobType)
      };
    case siblingPaginationTypes.REFERRAL_CUSTOMER:
      return {
        name: 'Homeowner',
        route: routes.CUSTOMER,
        selectInstanceId: selectCustomerInstanceId,
        selectList: selectReferralList,
        selectTotalCount: selectReferralCount,
        selectPage: selectReferralPage
      };
    case siblingPaginationTypes.REFERRAL_PROVIDER:
      return {
        name: 'Provider',
        route: routes.PROVIDER,
        selectInstanceId: selectProviderInstanceId,
        selectList: selectReferralList,
        selectTotalCount: selectReferralCount,
        selectPage: selectReferralPage
      };
    case siblingPaginationTypes.REVIEW_JOB:
      return {
        route: routes.SERVICE,
        selectInstanceId: selectServiceInstanceId,
        selectList: selectReviewList,
        selectTotalCount: selectReviewCount,
        selectPage: selectReviewPage
      };
    case siblingPaginationTypes.CUSTOMER_JOB_REVIEW:
      return {
        route: routes.SERVICE,
        selectInstanceId: selectServiceInstanceId,
        selectList: selectCustomerReviews,
        selectTotalCount: selectCustomerReviewsCount,
        selectPage: selectCustomerReviewsPage
      };
    case siblingPaginationTypes.PROVIDER_JOB_REVIEW:
      return {
        route: routes.SERVICE,
        selectInstanceId: selectServiceInstanceId,
        selectList: selectProviderReviews,
        selectTotalCount: selectProviderReviewsCount,
        selectPage: selectProviderReviewsPage
      };
    case siblingPaginationTypes.CAMPAIGNS:
      return {
        name: 'Campaign',
        route: routes.CAMPAIGNS,
        selectInstanceId: selectCampaignsInstanceId,
        selectList: selectCampaignsList,
        selectTotalCount: selectCampaignsListTotalCount,
        selectPage: selectCampaignsListPage
      };
    case siblingPaginationTypes.JOB:
    default:
      return {
        route: routes.SERVICE,
        selectInstanceId: selectServiceInstanceId,
        selectList: selectServiceList,
        selectTotalCount: selectServiceTotalCount,
        selectPage: selectJobsListPage
      };
  }
};
