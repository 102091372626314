import React from 'react';
import moment from 'moment-timezone';

import dates from 'constants/dates';
import { SnoozeWrapper, SnoozeDate } from './styled';

export const SnoozedMessage = ({ snoozeDate }) => {
  const snoozeDateObject = moment(snoozeDate);
  snoozeDateObject.set({ hour: 6, minute: 0, second: 0, millisecond: 0 });
  const snoozeDateFormattedString = snoozeDateObject.format(dates.DATETIME_FULL_DAY);

  return (
    <SnoozeWrapper>
      The job will be staying on-hold till <SnoozeDate>{snoozeDateFormattedString}</SnoozeDate>.
    </SnoozeWrapper>
  );
};
