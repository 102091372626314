import React from 'react';
import { connect } from 'react-redux';

import { getFullName } from 'common/helpers/user';
import { updateJobField, closeModal } from 'store/actions';
import { ModalConfirmation } from 'components';
import modalTypes from 'constants/modalTypes';
import { selectModalData } from 'store/reducers/modal';
import { selectUpdateJobFieldLoading } from 'store/reducers/service';

import { ModalText, BoldTextWrapper } from './styled';

const AssignTaskOwner = ({ closeModal, updateJobField, loading, modalData }) => {
  const assignTaskOwner = () => {
    updateJobField({
      job_id: modalData.id,
      body: {
        closedModalType: modalTypes.ASSIGN_TASK_OWNER,
        owner_id: modalData?.taskOwner.id || null
      }
    });
  };

  return (
    <>
      <ModalConfirmation
        modalType={modalTypes.ASSIGN_TASK_OWNER}
        title="Please confirm assignment."
        text={
          <ModalText>
            Confirm assignment as the job owner{' '}
            <BoldTextWrapper>
              {getFullName(modalData?.taskOwner ?? {})} (ID {modalData?.id}).
            </BoldTextWrapper>
          </ModalText>
        }
        hideModal={() => closeModal(modalTypes.ASSIGN_TASK_OWNER)}
        primaryButton={{
          title: 'Cancel',
          disabled: loading,
          onClick: () => closeModal(modalTypes.ASSIGN_TASK_OWNER)
        }}
        secondaryButton={{
          title: 'Confirm',
          disabled: loading,
          loading,
          onClick: assignTaskOwner
        }}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  modalData: selectModalData(state),
  loading: selectUpdateJobFieldLoading(state)
});

const mapDispatchToProps = {
  closeModal,
  updateJobField
};

export default connect(mapStateToProps, mapDispatchToProps)(AssignTaskOwner);
