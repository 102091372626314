import { customJobFilters } from 'common/enums/filters';

export const oneFieldRequiredResolver = (data) => {
  let error = null;
  const isSomeField = Object.values(data).some(Boolean);
  if (!isSomeField) {
    error = 'At least one filter is required';
  }

  return {
    values: error ? {} : data,
    errors: error
      ? {
          [customJobFilters.ADMIN_STATUS.TYPE]: error
        }
      : {}
  };
};
