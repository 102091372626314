import { call, takeLatest, put, select } from 'redux-saga/effects';

import { apiPageSizes } from 'common/enums/queryParams';
import api_constants from 'constants/api';
import actionTypes from 'constants/actionTypes';
import { isTestTasksVisible, selectFilteredAdminList, selectFilteredGeoLocationList } from 'store/reducers/app';
import Api from 'services/ApiService';
import { getFormattedNotification } from 'common/helpers/notification';

function* getNotificationList({ payload: { page = 1, limit = apiPageSizes.SMALL } = {} }) {
  try {
    const isTest = yield select(isTestTasksVisible);
    const filteredAdminList = yield select(selectFilteredAdminList);
    const filteredGeoLocationList = yield select(selectFilteredGeoLocationList);
    const ownersIdString = filteredAdminList.length ? filteredAdminList.map(({ id }) => id).join(',') : null;
    const geoLocationsIdString = filteredGeoLocationList.length ? filteredGeoLocationList.join(',') : null;

    const response = yield call(
      Api.amplifyGet,
      `${api_constants.NOTIFICATION}?limit=${limit}&page=${page}${ownersIdString ? `&owner_id=${ownersIdString}` : ''}${
        geoLocationsIdString ? `&operational_area_id=${geoLocationsIdString}` : ''
      }${isTest ? '' : '&is_test=0'}`
    );

    if (!response?.results) {
      throw new Error();
    }

    yield put({
      type: actionTypes.GET_NOTIFICATION_LIST_SUCCESS,
      payload: {
        persistentList: response.results?.map(getFormattedNotification),
        page,
        totalCount: response.count
      }
    });
  } catch (err) {
    yield put({
      type: actionTypes.GET_NOTIFICATION_LIST_ERROR
    });
    console.log(err);
  }
}

export default function* watchGetNotificationList() {
  yield takeLatest(actionTypes.GET_NOTIFICATION_LIST_FETCH, getNotificationList);
}
