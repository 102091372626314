import styled from 'styled-components';

export const Heading = styled.div`
  font-weight: 400;
  font-size: 20px;
  line-height: 1.2;
  color: ${({ theme }) => theme.colors.WHITE};
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 24px;
`;

export const Content = styled.div`
  padding: 32px 24px;
  width: 100%;
`;
