import React from 'react';

import { getFullName } from 'common/helpers/user';
import { Modal, Button } from 'components';
import styles from 'constants/styles';
import { BoldText, ButtonsWrapper, FlexRow, Label, ModalText, ModalTitle } from './styled';

export const AssignProviderResult = ({ visible, onCloseModal, onProceedModal, modalData }) => {
  return (
    <Modal
      visible={visible}
      hideModal={onCloseModal}
      closeButton
      backgroundColor={styles.colors.WHITE}
      middlePaddings
      maxWidth={0}
      maxHeight={0}>
      {!modalData?.hasError && <ModalTitle>Notification sent.</ModalTitle>}
      <FlexRow>
        {modalData?.hasError ? (
          <ModalText>
            <BoldText>Something went wrong. Please, try again later</BoldText>
          </ModalText>
        ) : (
          <Label>
            <BoldText>{getFullName(modalData?.service_provider?.user || {})}</BoldText> will receive notification about
            new job <BoldText>(ID {modalData?.job_id}).</BoldText>
          </Label>
        )}
      </FlexRow>
      <ButtonsWrapper>
        <Button title={'OK'} onClick={onProceedModal} />
      </ButtonsWrapper>
    </Modal>
  );
};
