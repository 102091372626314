import { call, takeLatest, put } from 'redux-saga/effects';

import api_constants from 'constants/api';
import actionTypes from 'constants/actionTypes';
import errors from 'constants/errors';
import Api from 'services/ApiService';
import { closeModal, openModal } from 'store/actions';
import modalTypes from 'constants/modalTypes';
import messages from 'constants/messages';

function* deleteSupportMessage({ payload }) {
  const { message, userID } = payload;
  try {
    const response = yield call(Api.amplifyDel, `${api_constants.SUPPORT_REQUEST}/${message._id}`);
    if (response.err) {
      throw new Error(errors.DEFAULT);
    }
    yield put({
      type: actionTypes.DELETE_SUPPORT_MESSAGE_SUCCESS,
      payload: { message, userID }
    });
    yield put(closeModal(modalTypes.DELETE_CHAT_MESSAGE));
  } catch (err) {
    yield put({
      type: actionTypes.DELETE_SUPPORT_MESSAGE_ERROR
    });
    yield put(
      openModal(modalTypes.RESULT, {
        title: messages.requstSucceed.DELETE_MESSAGE_TITLE,
        text: messages.requstSucceed.DELETE_MESSAGE_TEXT
      })
    );
  }
}

export default function* watchDeleteSupportMessage() {
  yield takeLatest(actionTypes.DELETE_SUPPORT_MESSAGE_FETCH, deleteSupportMessage);
}
