import React from 'react';

import { useCallback, useDispatch, useFilters, useMemo, useSelector } from 'hooks';

import { defaultQueryParams } from 'common/enums/queryParams';
import { siblingPaginationTypes } from 'common/enums/navigation';
import routes from 'constants/routes';

import { getProviderList } from 'store/actions/provider';
import {
  selectProviderListError,
  selectProviderDashboard,
  selectProviderDashboardLoading,
  selectProvidersTotalCount
} from 'store/reducers/provider';
import { getColumns, getRows } from './helpers';

import { PaginationFullScreen, Table } from 'components';

const ProviderDashboard = ({ hasAvatarColumn }) => {
  const dispatch = useDispatch();
  const list = useSelector(selectProviderDashboard);
  const listLoading = useSelector(selectProviderDashboardLoading);
  const totalCount = useSelector(selectProvidersTotalCount);
  const hasError = useSelector(selectProviderListError);

  const handleFetchCustomerList = useCallback(
    (updatedFilters = {}) => {
      dispatch(
        getProviderList({
          ...defaultQueryParams,
          ...updatedFilters
        })
      );
    },
    [dispatch]
  );

  const { filters, handleChangePage, handleChangeSorting, handleRowClick, handleRowContextClick } = useFilters({
    onFetchList: handleFetchCustomerList,
    instanceRoute: routes.PROVIDER,
    siblingPaginationType: siblingPaginationTypes.PROVIDER,
    totalCount
  });

  const columns = useMemo(
    () => getColumns({ hasAvatarColumn, tabId: filters.tab_id }),
    [filters.tab_id, hasAvatarColumn]
  );
  const data = useMemo(() => getRows({ list }), [list]);

  return (
    <PaginationFullScreen
      count={totalCount}
      currentPage={Number(filters.page)}
      onPageChange={handleChangePage}
      length={list?.length}
      loading={listLoading}
      searchTerm={filters.search}
      hasError={hasError}
      hasNavigationTabs>
      <Table
        onSort={handleChangeSorting}
        onRowClick={handleRowClick}
        onRowContextClick={handleRowContextClick}
        searchTerm={filters.search}
        columns={columns}
        data={data}
        sortingDirection={filters.sorting_direction}
        sortingColumnName={filters.sorting_column}
      />
    </PaginationFullScreen>
  );
};

export default ProviderDashboard;
