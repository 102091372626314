import { call, takeLatest, put, select } from 'redux-saga/effects';

import Api from 'services/ApiService';

import { userGroups } from 'common/enums/user';
import api_constants from 'constants/api';
import errors from 'constants/errors';
import actionTypes from 'constants/actionTypes';
import { saveLocationSearch } from 'store/actions/navigation';
import { isTestTasksVisible } from 'store/reducers/app';
import { generateQueryParams } from 'common/helpers/httpRequest';

function* getReferralList({
  payload: {
    page = 1,
    target_group_id = userGroups.CUSTOMER.TYPE,
    source_user_id,
    search,
    order_by,
    hasSavingQueryParams,
    menuItemType,
    locationSearch
  } = {}
}) {
  const testTasksVisible = yield select(isTestTasksVisible);
  const filterParams = {
    page,
    target_group_id,
    source_user_id,
    search,
    order_by,
    ...(testTasksVisible ? {} : { is_test: 0 })
  };
  try {
    const response = yield call(Api.amplifyGet, `${api_constants.REFERRAL}${generateQueryParams(filterParams)}`);

    if (!response) {
      throw new Error(errors.DEFAULT);
    }

    yield put({
      type: actionTypes.GET_REFERRAL_LIST_SUCCESS,
      payload: {
        results: response.results,
        count: response.count,
        page
      }
    });

    if (hasSavingQueryParams) {
      yield put(saveLocationSearch({ menuItemType, locationSearch }));
    }
  } catch (err) {
    yield put({
      type: actionTypes.GET_REFERRAL_LIST_ERROR
    });
  }
}

export default function* watchGetReferralList() {
  yield takeLatest(actionTypes.GET_REFERRAL_LIST_FETCH, getReferralList);
}
