export const generateConfirmationSubheading = ({ job, formValues }) => {
  const isImportantFieldsChanged =
    job.description !== formValues.description || job.timeframe_estimate !== formValues.timeframe_estimate;
  const isMediaRemoved = formValues.attachments?.length !== job.media?.length;
  const isMediaAdded = formValues.attachments?.some(({ media_id }) => !media_id);

  if (isImportantFieldsChanged || isMediaRemoved || isMediaAdded) {
    return 'All parties will be notified';
  }
  return 'New information will be displayed in Dobby app';
};
