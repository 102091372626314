import React from 'react';

import { useCallback, useDispatch, useFilters, useMemo, useSelector } from 'hooks';

import { siblingPaginationTypes } from 'common/enums/navigation';
import { defaultQueryParams } from 'common/enums/queryParams';
import routes from 'constants/routes';

import { getReviewList, updateReview } from 'store/actions/review';
import {
  selectReviewIsLoading,
  selectReviewCount,
  selectReviewList,
  selectReviewListError
} from 'store/reducers/review';
import { getRows, getColumns } from './helpers';

import { PaginationFullScreen, Table } from 'components';

const Reviews = () => {
  const dispatch = useDispatch();
  const list = useSelector(selectReviewList);
  const listLoading = useSelector(selectReviewIsLoading);
  const totalCount = useSelector(selectReviewCount);
  const hasError = useSelector(selectReviewListError);

  const handleFetchReviewList = useCallback(
    (updatedFilters = {}) => {
      dispatch(
        getReviewList({
          ...defaultQueryParams,
          ...updatedFilters
        })
      );
    },
    [dispatch]
  );

  const handleCustomRowClick = (instance) => {
    if (!instance.hasRowHighlight) {
      return;
    }
    dispatch(updateReview({ id: instance.id, is_status_badge: false }));
  };

  const hasExtraActionOnRowClick = useCallback((instance) => instance.hasRowHighlight, []);

  const { filters, handleChangePage, handleChangeSorting, handleRowClick, handleRowContextClick } = useFilters({
    onFetchList: handleFetchReviewList,
    handleCustomRowClick,
    hasExtraActionOnRowClick,
    instanceRoute: routes.SERVICE,
    instanceIdFieldName: 'service_demand_id',
    siblingPaginationType: siblingPaginationTypes.REVIEW_JOB,
    totalCount
  });

  const columns = useMemo(() => getColumns(), []);
  const data = useMemo(() => getRows({ list }), [list]);

  return (
    <PaginationFullScreen
      count={totalCount}
      currentPage={Number(filters.page)}
      onPageChange={handleChangePage}
      length={list?.length}
      loading={listLoading}
      searchTerm={filters.search}
      hasError={hasError}
      hasNavigationTabs>
      <Table
        onSort={handleChangeSorting}
        onRowClick={handleRowClick}
        onRowContextClick={handleRowContextClick}
        searchTerm={filters.search}
        columns={columns}
        data={data}
        sortingDirection={filters.sorting_direction}
        sortingColumnName={filters.sorting_column}
      />
    </PaginationFullScreen>
  );
};

export default Reviews;
