import api_constants from 'constants/api';
import Api from 'services/ApiService';
import { formatSearchTerm } from 'common/helpers/queryParams';

export default function searchProvidersList({ search, is_test, operational_area_id }) {
  if (search) {
    return Api.amplifyGet(
      `${api_constants.SERVICE_PROVIDER}?page=1&search=${formatSearchTerm(
        search
      )}&is_full_active=1&is_cognito_active=1${is_test ? '' : '&is_test=0'}${
        operational_area_id ? `&service_area=${operational_area_id}` : ''
      }`
    )
      .then((response) => {
        if (response && response.results) {
          return response.results;
        }

        return [];
      })
      .catch((err) => console.log(err));
  }
  return null;
}
