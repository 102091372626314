import { all, takeEvery, put } from 'redux-saga/effects';
import actionTypes from 'constants/actionTypes';
import { getImageFromS3 } from 'utils';
import errors from 'constants/errors';

function* getMediaUrls({ payload: { media, id, type } }) {
  try {
    const mediaObjectsWithPath = media.filter(({ media }) => media?.path);
    const mediaObjects = mediaObjectsWithPath.map(({ media: { path } }) => getImageFromS3(path));
    const mediaFiles = (yield all(mediaObjects)).map((mediaFile, index) => ({
      media_file: mediaFile,
      media_type: mediaObjectsWithPath[index].media_type
    }));

    const successMediaFiles = mediaFiles.filter(({ media_file }) => !(media_file instanceof Error));
    const mediaBlobs = yield all(successMediaFiles.map(({ media_file }) => media_file.blob()));
    const urls = mediaBlobs.map((props, index) => ({
      url: URL.createObjectURL(props),
      media_type: successMediaFiles[index].media_type
    }));

    if (!urls) {
      throw new Error(errors.GET_EMPTY_MEDIA);
    }
    yield put({
      type: actionTypes.GET_MEDIA_URLS_SUCCESS,
      payload: { urls, id, type }
    });
  } catch (err) {
    yield put({
      type: actionTypes.GET_MEDIA_URLS_ERROR
    });
  }
}

export default function* watchGetMediaUrls() {
  yield takeEvery(actionTypes.GET_MEDIA_URLS_FETCH, getMediaUrls);
}
