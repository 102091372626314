import { call, takeLatest, put } from 'redux-saga/effects';

import api_constants from 'constants/api';
import actionTypes from 'constants/actionTypes';
import errors from 'constants/errors';
import Api from 'services/ApiService';
import { chatMessageTransformer } from 'utils';

function* getTaskMessageById({ payload: { id } }) {
  try {
    const response = yield call(Api.amplifyGet, `${api_constants.SUPPORT_REQUEST}/${id}`);
    if (!response) {
      throw new Error(errors.DEFAULT);
    }
    yield put({
      type: actionTypes.GET_TASK_MESSAGE_BY_ID_SUCCESS,
      payload: {
        message: chatMessageTransformer(response),
        transformedId: id
      }
    });
  } catch (err) {
    yield put({
      type: actionTypes.GET_TASK_MESSAGE_BY_ID_ERROR
    });
    console.log(err);
  }
}

export default function* watchGetServiceMessageById() {
  yield takeLatest(actionTypes.GET_TASK_MESSAGE_BY_ID_FETCH, getTaskMessageById);
}
