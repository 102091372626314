import React from 'react';
import { connect } from 'react-redux';
import modalTypes from 'constants/modalTypes';
import { selectJobInvoicesLoading } from 'store/reducers/invoice';
import { closeModal, openModal, updateQuotation } from 'store/actions';
import { ModalConfirmation } from 'components';
import { selectModalData } from 'store/reducers/modal';

const ApproveQuotation = ({
  loading,
  modalData: { service_request_id = null, id } = {},
  closeModal,
  updateQuotation
}) => {
  const closeApproveModal = () => {
    closeModal(modalTypes.APPROVE_QUOTATION);
  };

  const onConfirm = () => {
    updateQuotation({
      data: {
        is_accepted: true,
        service_request_id,
        id
      }
    });
  };

  return (
    <ModalConfirmation
      modalType={modalTypes.APPROVE_QUOTATION}
      hideModal={closeApproveModal}
      title={`Approve the quote?`}
      text={`You are going to approve the quote for the HO.\nThe HO will be notified about the approval from the OPS team.`}
      primaryButton={{
        title: 'Cancel',
        disabled: loading,
        onClick: closeApproveModal
      }}
      secondaryButton={{
        title: 'Approve',
        onClick: onConfirm,
        loading: loading,
        disabled: loading
      }}
    />
  );
};

const mapStateToProps = (state) => ({
  modalData: selectModalData(state),
  loading: selectJobInvoicesLoading(state)
});

const mapDispatchToProps = {
  openModal,
  closeModal,
  updateQuotation
};

export default connect(mapStateToProps, mapDispatchToProps)(ApproveQuotation);
