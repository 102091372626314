import { adminStatuses } from 'common/enums/job';

const RECENT_15_DAYS = 15;
const RECENT_30_DAYS = 30;

const COMPLETED_STATUSES = [adminStatuses.COMPLETED.TYPE, adminStatuses.PAID.TYPE, adminStatuses.UNFULFILLED.TYPE];

export default {
  RECENT_15_DAYS,
  RECENT_30_DAYS,
  COMPLETED_STATUSES
};
