export const getRows = ({ list }) => {
  return list.map((campaign) => ({
    article_area_list: campaign.article_area_list,
    csv_cohort_name: campaign.csv_cohort_name,
    cohort_type: campaign.cohort_type,
    date_created: campaign.date_created,
    datetime_execute: campaign.datetime_execute,
    execute_area_list: campaign.execute_area_list,
    id: campaign.id,
    is_executed: campaign.is_executed,
    recipients_list: campaign.recipients_list,
    title: campaign.title
  }));
};
