import { buttonSourceNames, requestSources } from 'common/enums/campaigns';

const getButtonName = (requestSourceName, providerName) => {
  const name =
    Object.values(buttonSourceNames).find((buttonSourceName) => requestSourceName === buttonSourceName.TYPE)?.NAME ??
    '';
  return requestSourceName === buttonSourceNames.ONE_CLICK_BOOKING.TYPE ? `${name} - ${providerName}` : name;
};

export const getRequestSourceByType = ({
  requestSourceId,
  requestSourceName,
  requestSource,
  requestSourceType,
  providerName
}) => {
  switch (requestSourceType) {
    case requestSources.BUTTON: {
      return `Dobby${
        requestSourceName ? ` : ${getButtonName(requestSourceName, providerName) || requestSourceName}` : ''
      }`;
    }
    case requestSources.CAMPAIGN: {
      if (requestSourceName) {
        return `${requestSource}${` : ${requestSourceName}`}`;
      }

      return `${requestSource}${requestSourceId ? ` : ${requestSourceId}` : ''}`;
    }
    case requestSources.INSPECTION_REPORT: {
      return 'Dobby : Inspection report list';
    }
    case requestSources.SPECTORA: {
      return 'Spectora : Inspection scheduling';
    }
    case requestSources.REMINDER:
    case requestSources.ARTICLE:
    case requestSources.CHECKLIST:
    case requestSources.BANNER: {
      return `Dobby : Campaign - ${requestSourceName ? `${requestSourceName}` : ''}`;
    }
    case requestSources.GRYFFINDOR: {
      return `G${requestSourceName ? ` : ${requestSourceName}` : ''}`;
    }
    case requestSources.AI: {
      return requestSourceName || 'Dobby : Check the price';
    }
    default: {
      return '-';
    }
  }
};
