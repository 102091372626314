import { call, takeLatest, put } from 'redux-saga/effects';
import api_constants from 'constants/api';
import actionTypes from 'constants/actionTypes';
import modalTypes from 'constants/modalTypes';
import errors from 'constants/errors';

import Api from 'services/ApiService';
import { getBadges, openModal } from 'store/actions';
import { refreshTaskInfo } from 'store/actions/service';
import { getInvoiceTypeParam } from 'common/helpers/invoice';
import { getUpdatedMediaIds } from 'common/helpers/media';

function* updatePayment({
  payload: {
    data,
    data: { deposit, amount_deposit, is_final_payment, service_request_id: task_id },
    files,
    hasModeration
  }
}) {
  try {
    const mediaIds = files?.length ? yield call(getUpdatedMediaIds, { files, hasMediaType: true }) : [];

    const response = yield call(Api.amplifyPut, `${api_constants.PAYMENT}/${data.id}`, {
      ...data,
      ...(files ? { media: mediaIds } : {})
    });

    if (!response?.id) {
      throw new Error(response?.error_message || response?.err?.message || response?.err?.name || errors.DEFAULT);
    }

    yield put({
      type: actionTypes.UPDATE_PAYMENT_SUCCESS,
      payload: response
    });

    const invoiceTypeLabel = getInvoiceTypeParam({
      isDeposit: Boolean(deposit?.length || amount_deposit),
      is_final_payment,
      paramName: 'confirmationLabel'
    });
    yield put(
      openModal(modalTypes.RESULT, {
        title: `${invoiceTypeLabel} ${hasModeration ? 'Sent' : 'Saved'}`,
        text: hasModeration ? `The homeowner & pro will be notified.` : 'Changes are saved'
      })
    );

    if (data.is_moderated) {
      yield put(getBadges());
    }
  } catch (err) {
    yield put({
      type: actionTypes.UPDATE_PAYMENT_ERROR
    });
    yield put(
      openModal(modalTypes.ERROR, {
        errorMessage: err?.message || err?.name || errors.DEFAULT
      })
    );
    console.log(err);
  } finally {
    yield put(refreshTaskInfo({ task_id, silent: true, isPaymentInfo: true }));
  }
}

export default function* watchUpdatePayment() {
  yield takeLatest(actionTypes.UPDATE_PAYMENT_FETCH, updatePayment);
}
