import actionTypes from 'constants/actionTypes';
import { modalTypes } from '../../constants';
import { oneClickActions } from 'common/enums/user';

const defaultList = {
  loading: false,
  list: [],
  page: 0,
  count: null
};

const defaultState = {
  badges: null,
  userIsLoading: true,
  userInstance: {},
  deleteCognitoUserResult: false,
  updateInstanceIsActivateResult: false,
  noteListLoading: false,
  updateNoteLoading: false,
  deleteNoteLoading: false,
  noteList: [],
  questions: defaultList,
  noteListCount: 0
};

function reducer(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.GET_BADGES_SUCCESS: {
      return {
        ...state,
        badges: action.payload
      };
    }
    case actionTypes.GET_BADGES_ERROR: {
      return {
        ...state,
        badges: null
      };
    }

    case actionTypes.DELETE_COGNITO_USER_SUCCESS: {
      return {
        ...state,
        deleteCognitoUserResult: 'success'
      };
    }

    case actionTypes.DELETE_COGNITO_USER_ERROR: {
      return {
        ...state,
        deleteCognitoUserResult: 'error'
      };
    }

    case actionTypes.GET_USER_INSTANCE_FETCH: {
      return {
        ...state,
        userIsLoading: true
      };
    }
    case actionTypes.GET_USER_INSTANCE_SUCCESS: {
      const { user } = action.payload;

      return {
        ...state,
        userIsLoading: false,
        userInstance: user
      };
    }
    case actionTypes.GET_USER_INSTANCE_ERROR: {
      return {
        ...state,
        userIsLoading: false
      };
    }

    case actionTypes.UPDATE_USER_INSTANCE_SUCCESS: {
      const { updateAction } = action.payload;
      if (updateAction === oneClickActions.IS_ACTIVE) {
        return {
          ...state,
          updateInstanceIsActivateResult: 'success'
        };
      }
      return state;
    }
    case actionTypes.UPDATE_USER_INSTANCE_ERROR: {
      const { updateAction } = action.payload;
      if (updateAction === oneClickActions.IS_ACTIVE) {
        return {
          ...state,
          updateInstanceIsActivateResult: 'error'
        };
      }
      return state;
    }

    case actionTypes.CLOSE_MODAL: {
      if (
        action.payload.modalType === modalTypes.USER_TOGGLE_IS_ACTIVATE_RESULT &&
        state.updateInstanceIsActivateResult
      ) {
        return {
          ...state,
          updateInstanceIsActivateResult: null
        };
      }
      return state;
    }

    case actionTypes.GET_USER_NOTE_LIST_FETCH: {
      const { silent = false } = action.payload;
      return {
        ...state,
        noteListLoading: !silent,
        noteList: [],
        noteListCount: 0
      };
    }
    case actionTypes.GET_USER_NOTE_LIST_SUCCESS: {
      return {
        ...state,
        noteListLoading: false,
        noteList: action.payload.noteList.reverse(),
        noteListCount: action.payload.count
      };
    }
    case actionTypes.GET_USER_NOTE_LIST_ERROR: {
      return {
        ...state,
        notesListLoading: false
      };
    }

    case actionTypes.CREATE_USER_NOTE_FETCH: {
      return {
        ...state,
        noteList: [action.payload, ...state.noteList]
      };
    }

    case actionTypes.UPDATE_USER_NOTE_FETCH: {
      return {
        ...state,
        updateNoteLoading: true
      };
    }

    case actionTypes.UPDATE_USER_NOTE_SUCCESS:
    case actionTypes.CREATE_USER_NOTE_SUCCESS: {
      const noteIndex = state.noteList.findIndex((item) => item.id === action.payload.id);

      if (noteIndex >= 0) {
        const noteList = [
          ...state.noteList.slice(0, noteIndex),
          action.payload.note,
          ...state.noteList.slice(noteIndex + 1)
        ];

        return {
          ...state,
          noteList,
          updateNoteLoading: false
        };
      }

      return {
        ...state,
        updateNoteLoading: false
      };
    }

    case actionTypes.UPDATE_USER_NOTE_ERROR: {
      return {
        ...state,
        updateNoteLoading: false
      };
    }

    case actionTypes.CREATE_USER_NOTE_ERROR: {
      const noteIndex = state.noteList.findIndex((item) => item.id === action.payload.id);

      if (noteIndex >= 0) {
        const noteList = [...state.noteList.slice(0, noteIndex), ...state.noteList.slice(noteIndex + 1)];

        return {
          ...state,
          noteList,
          updateNoteLoading: false
        };
      }

      return {
        ...state,
        updateNoteLoading: false
      };
    }

    case actionTypes.DELETE_USER_NOTE_FETCH: {
      return {
        ...state,
        deleteNoteLoading: true
      };
    }

    case actionTypes.DELETE_USER_NOTE_SUCCESS:
    case actionTypes.DELETE_USER_NOTE_ERROR: {
      return {
        ...state,
        deleteNoteLoading: false
      };
    }

    case actionTypes.GET_USER_QUESTIONS_FETCH: {
      const { page } = action.payload;

      return {
        ...state,
        questions: {
          ...state.questions,
          list: page === 1 ? [] : state.questions.list,
          loading: true
        }
      };
    }

    case actionTypes.GET_USER_QUESTIONS_SUCCESS: {
      const { list, page, count } = action.payload;

      return {
        ...state,
        questions: {
          list: page > 1 ? [...state.questions.list, ...list] : list,
          page,
          count,
          loading: false
        }
      };
    }

    case actionTypes.GET_USER_QUESTIONS_ERROR: {
      return {
        ...state,
        questions: {
          ...state.questions,
          loading: false
        }
      };
    }

    default:
      return state;
  }
}

export const selectBadges = (state) => state.user.badges;

export const selectDeleteCognitoUserResult = (state) => state.user.deleteCognitoUserResult;
export const selectUpdateUserIsActivateResult = (state) => state.user.updateInstanceIsActivateResult;

export const selectUserNoteListLoading = (state) => state.user.noteListLoading;
export const selectUserNoteList = (state) => state.user.noteList;
export const selectUserNoteListCount = (state) => state.user.noteListCount;
export const selectUserUpdateNoteLoading = (state) => state.user.updateNoteLoading;
export const selectUserDeleteNoteLoading = (state) => state.user.deleteNoteLoading;
export const selectUserQuestions = (state) => state.user.questions;

export const selectUserIsLoading = (state) => state.user.userIsLoading;
export const selectUserInstance = (state) => state.user.userInstance;

export default reducer;
