import styled from 'styled-components';

export const ColorsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  background-color: ${({ theme }) => theme.colors.WHITE};
  p {
    background-color: ${({ theme }) => theme.colors.WHITE}80;
    font-size: 14px;
  }
`;

export const ColorsList = styled.div`
  display: flex;
  flex: 1;

  flex-direction: column;
  p {
    background-color: ${({ theme }) => theme.colors.WHITE}80;
    font-size: 8px;
  }
`;

export const ColorItem = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  border: 1px solid ${({ theme }) => theme.colors.WHITE}80;
  p {
    background-color: transparent;
    color: ${({ theme }) => theme.colors.WHITE};
    font-size: 8px;
  }
`;

export const Rect = styled.div`
  height: ${({ size }) => size || 140}px;
  width: ${({ size }) => size || 140}px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ backgroundColor }) => backgroundColor};

  p {
    background-color: ${({ theme }) => theme.colors.WHITE}80;
    color: ${({ theme }) => theme.colors.BLACK};
    font-size: 12px;
  }
`;
