import { call, takeLatest, put } from 'redux-saga/effects';
import api_constants from 'constants/api';
import actionTypes from 'constants/actionTypes';
import Api from 'services/ApiService';

function* getUserInstance(action) {
  const {
    payload: { userID }
  } = action;
  try {
    const response = yield call(Api.amplifyGet, `${api_constants.USER}/${userID}`);
    if (!response) {
      throw new Error();
    }

    yield put({
      type: actionTypes.GET_USER_INSTANCE_SUCCESS,
      payload: {
        user: response
      }
    });
  } catch (err) {
    yield put({
      type: actionTypes.GET_USER_INSTANCE_ERROR,
      payload: { userID }
    });
    console.log(err);
  }
}

export default function* watchGetUserInstance() {
  yield takeLatest(actionTypes.GET_USER_INSTANCE_FETCH, getUserInstance);
}
