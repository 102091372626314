import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector, useForm, useWatch } from 'hooks';

import {
  closeModal,
  // openModal,
  sendTestCampaignEvent
} from 'store/actions';
import modalTypes from 'constants/modalTypes';
import { sendTestCampainEventResolver } from 'common/helpers/formValidators';

import {
  Button,
  // Dropdown,
  IdsInput,
  // HookForm,
  Modal
} from 'components';

import { selectSendTestLoading } from 'store/reducers/campaigns';
import { selectModalData } from 'store/reducers/modal';

import {
  // DateInput,
  Content,
  ButtonsWrapper
} from './styled';

const TestEvent = () => {
  const modalData = useSelector(selectModalData);
  const loading = useSelector(selectSendTestLoading);
  const dispatch = useDispatch();
  const hideModal = () => dispatch(closeModal(modalTypes.TEST_EVENT));
  const {
    control,
    setValue,
    handleSubmit,
    formState: { isValid }
  } = useForm({
    mode: 'onChange',
    defaultValues: { test_recipient_ids: modalData.test_recipient_ids || [] },
    resolver: yupResolver(sendTestCampainEventResolver)
  });
  const values = useWatch({ control });

  const handleSetIDs = (newValue) => {
    setValue('test_recipient_ids', newValue, { shouldValidate: true });
  };

  const handleSendEvent = ({ test_recipient_ids }) => {
    dispatch(
      sendTestCampaignEvent({
        test_recipient_ids: test_recipient_ids.map((value) => Number(value)),
        executionId: modalData.executionId
      })
    );
  };

  return (
    <Modal
      closeButton
      modalType={modalTypes.TEST_EVENT}
      hideModal={hideModal}
      padding="0"
      heading="Send Test Event"
      minWidth={420}>
      <Content>
        <IdsInput
          label={'Cohort*'}
          values={values.test_recipient_ids}
          onChange={handleSetIDs}
          noticeStyle={{
            textAlign: 'left'
          }}
          notice={`You can enter only 20 ID’s. Use “Space Bar” to add another ID.\nThe campaign will be executed right after you click on “send” button.`}
        />
        {/* <DateInput
          onClick={() =>
            dispatch(
              openModal(modalTypes.EXECUTION_DATE, {
                setDate: (date) => {
                  dispatch(openModal(modalTypes.TEST_EVENT, { ...value, date }));
                },
                date: value.date
              })
            )
          }>
          <HookForm.Input clickOnly name="date" register={register} label="Execution date*" placeholder="Select Date" />
        </DateInput> */}
        {/* <HookForm.Checkbox
          green
          smallLabelFont
          labelFontWeight={700}
          control={control}
          name={'alsoSend'}
          label={'Send now'}
        /> */}
      </Content>
      <ButtonsWrapper>
        <Button variant="primary" onClick={hideModal} disabled={loading}>
          Cancel
        </Button>
        <Button onClick={handleSubmit(handleSendEvent)} loading={loading} disabled={!isValid}>
          Send
        </Button>
      </ButtonsWrapper>
    </Modal>
  );
};

export default TestEvent;
