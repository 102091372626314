import React, { useEffect, useRef } from 'react';
import { useSelector } from 'hooks';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Button, LoadingSpinner, Icon } from 'components';
import modalTypes from 'constants/modalTypes';
import { invoiceStatuses } from 'common/enums/invoice';

import {
  selectJobInvoices,
  selecteditableInvoiceId,
  isInvoiceFormVisible,
  selectJobPayments
} from 'store/reducers/invoice';
import { selectServiceInstance, selectServiceInstanceLoading } from 'store/reducers/service';
import { openModal, toggleInvoiceForm } from 'store/actions';
import {
  PaymentSection,
  LoadingContainer,
  ChargeButtonWrapper,
  CreateInvoiceButton,
  newInvoiceBtnStyles
} from './styled';

import { dynamicPlatformFee } from 'common/enums/invoice';
import { getInvoiceType } from 'common/helpers/invoice';

import { InvoiceDetails, InvoiceForm } from '../';
import { quoteStatuses } from '../../constants';
import { generateInitialValues, sumProviderPayout } from '../../helpers';

const PaymentTab = ({
  serviceId,
  serviceInstance,
  invoices,
  toggleInvoiceForm,
  taskInstance: {
    user: { full_name },
    commission_value: staticCommissionPercent
  },
  isTaskInstanceLoading,
  openModal,
  editableInvoiceId,
  isFormVisible
}) => {
  const payments = useSelector(selectJobPayments);
  // sort invoices by date created
  const orderedInvoices = invoices.sort((a, b) => new Date(b.date_created) - new Date(a.date_created));

  const isFinalInvoiceExists = invoices.some((item) => item.is_final_payment);
  const hasNotValidatedInvoice = invoices.some((item) => item.status === invoiceStatuses.PENDING_ADMIN.TYPE);
  const toBePayout = invoices.some((item) => item.amount_provider_payout === null);
  const quotations = invoices.filter((item) => item.hasOwnProperty('is_accepted'));
  const approvedQuote = quotations.find(({ status }) => status === quoteStatuses.ACCEPTED.TYPE);
  const depositTotalProviderPayout = sumProviderPayout(invoices);
  const isAddChargeButton = Boolean(
    serviceInstance.is_open &&
      !isFinalInvoiceExists &&
      !hasNotValidatedInvoice &&
      !isTaskInstanceLoading &&
      !isFormVisible
  );
  const isPayoutAllButton = toBePayout && !isFormVisible;

  const toggleForm = (invoiceId = null) => {
    toggleInvoiceForm({ invoiceId });
  };

  const showDeletePaymentModal = (invoice) => {
    openModal(modalTypes.DELETE_INVOICE_CONFIRMATION, {
      invoice,
      reward: invoice.reward?.id ? invoice.reward : null
    });
  };

  const showPayoutConfirmation = (invoice) => {
    openModal(modalTypes.PAYOUT_CONFIRMATION, {
      amount_total_payout: invoice?.amount_total_payout,
      invoice_id: invoice?.id,
      task_id: serviceId
    });
  };
  const handleApproveQuotation = (invoiceId) => {
    openModal(modalTypes.APPROVE_QUOTATION, {
      service_request_id: serviceId,
      id: invoiceId
    });
  };
  const handleCloseInvoice = (invoice) => {
    openModal(modalTypes.CLOSE_INVOICE, {
      invoice
    });
  };

  const handleOpenValidateInvoiceModal = (invoice) => {
    const hasNotValidatedDeposits = invoices.some(
      (item) => !item.is_final_payment && item.status === invoiceStatuses.PENDING_ADMIN.TYPE
    );
    const hasNotValidatedQuotes = quotations.some((item) => item.status === invoiceStatuses.PENDING_ADMIN.TYPE);
    if (invoice.is_final_payment && (hasNotValidatedQuotes || hasNotValidatedDeposits)) {
      openModal(modalTypes.RESULT, {
        title: `The Final Payment invoice can’t be validated when there is also pending “not validated“ ${
          hasNotValidatedQuotes ? 'Quote' : 'Deposit Payment'
        }`
      });
      return;
    }

    openModal(modalTypes.VALIDATE_INVOICE, {
      invoiceType: getInvoiceType({
        is_final_payment: invoice.is_final_payment,
        is_quotation: invoice.is_quotation
      }),
      invoice_id: invoice.id,
      is_final_payment: invoice.is_final_payment,
      job_id: invoice.service_request_id
    });
  };

  const editableInvoice = invoices.find(({ id }) => id === editableInvoiceId);
  const defaultCommission = staticCommissionPercent || dynamicPlatformFee.BIG.PERCENT_AMOUNT_BE;

  const ref = useRef(null);
  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTo(0, 0);
    }
  }, [invoices.length]);
  const onPayout = (invoice) => {
    if (serviceInstance?.service_provider_id) {
      showPayoutConfirmation(invoice);
    } else {
      openModal(modalTypes.RESULT, {
        title: "The payout hasn't been sent",
        text: 'No Pro currently attached to this job'
      });
    }
  };
  return (
    <PaymentSection ref={ref}>
      {isAddChargeButton && (
        <CreateInvoiceButton onClick={() => toggleForm(null)}>
          <Icon name="add" backgroundSize={16} style={newInvoiceBtnStyles} />
          New invoice
        </CreateInvoiceButton>
      )}
      {serviceInstance.id !== serviceId && isTaskInstanceLoading && (
        <LoadingContainer>
          <LoadingSpinner text="Please wait" type="logo" />
        </LoadingContainer>
      )}

      {/* Create form */}
      {isFormVisible && editableInvoiceId === null && (
        <InvoiceForm
          isFirstInvoice={!invoices?.length}
          autocompleteOptions={quotations}
          initialValues={generateInitialValues({ defaultCommission, hasApprovedQuote: Boolean(approvedQuote) })}
          reward={serviceInstance?.referral_reward}
          depositTotalProviderPayout={depositTotalProviderPayout}
          hasApprovedQuote={Boolean(approvedQuote)}
          hasStaticCommissionPercent={Boolean(approvedQuote)}
        />
      )}
      {/* Edit form */}
      {isFormVisible && editableInvoiceId && (
        <InvoiceForm
          invoice={editableInvoice}
          initialValues={generateInitialValues({ invoice: editableInvoice, defaultCommission })}
          reward={editableInvoice.reward?.id ? editableInvoice.reward : null}
          depositTotalProviderPayout={depositTotalProviderPayout}
          hasStaticCommissionPercent={approvedQuote?.commission_value === editableInvoice.commission_value}
        />
      )}
      {/* Invoices (payments) */}
      {!isFormVisible &&
        orderedInvoices.map((invoice) => (
          <InvoiceDetails
            invoice={invoice}
            key={`payment-card-${invoice.id}`}
            onEdit={() => toggleForm(invoice.id)}
            onApprove={() => handleApproveQuotation(invoice.id)}
            onValidate={() => handleOpenValidateInvoiceModal(invoice)}
            onRemove={() => showDeletePaymentModal(invoice)}
            onPayout={() => onPayout(invoice)}
            onClose={() => handleCloseInvoice(invoice)}
            existingPayments={payments}
            reward={invoice.reward?.id ? invoice.reward : null}
            full_name={full_name}
          />
        ))}
      <ChargeButtonWrapper withMargin={isAddChargeButton || isPayoutAllButton}>
        {isPayoutAllButton && <Button title="PAYOUT ALL" variant="primary" onClick={showPayoutConfirmation} />}
      </ChargeButtonWrapper>
    </PaymentSection>
  );
};

PaymentTab.propTypes = {
  invoices: PropTypes.array.isRequired
};

PaymentTab.defaultProps = {
  invoices: []
};

const mapStateToProps = (state) => ({
  invoices: selectJobInvoices(state),
  taskInstance: selectServiceInstance(state),
  isTaskInstanceLoading: selectServiceInstanceLoading(state),
  editableInvoiceId: selecteditableInvoiceId(state),
  isFormVisible: isInvoiceFormVisible(state)
});

const mapDispatchToProps = {
  openModal,
  toggleInvoiceForm
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentTab);
