import React from 'react';
import { useTheme } from 'hooks';

import styles from 'constants/styles';
import { ColorItem, ColorsList, ColorsWrapper, Rect } from './styled';

const ColorsStoryBook = () => {
  const theme = useTheme();
  const themeColors = Object.entries(theme.colors);
  return (
    <ColorsList>
      <ColorsWrapper>
        {Object.entries(styles.colors).map(([key, value]) => (
          <>
            <Rect key={key} backgroundColor={value} size={80}>
              <p>{value}</p>
            </Rect>
          </>
        ))}
      </ColorsWrapper>
      <ColorsList>
        {Object.entries(styles.colors).map(([key, value]) => (
          <ColorItem>
            <Rect key={key} backgroundColor={value}>
              <p>
                {key}
                {'\n'}
                {value}
              </p>
            </Rect>
            <ColorsList>
              {themeColors
                .filter(([themeKey, themeValue]) => themeValue === value)
                .map(([themeKey]) => (
                  <p>{themeKey}</p>
                ))}
            </ColorsList>
          </ColorItem>
        ))}
      </ColorsList>
    </ColorsList>
  );
};

export default ColorsStoryBook;
