import React from 'react';

import { useCallback, useDispatch, useFilters, useMemo, useSelector } from 'hooks';

import { defaultQueryParams } from 'common/enums/queryParams';
import { siblingPaginationTypes } from 'common/enums/navigation';
import routes from 'constants/routes';

import { getAgentList } from 'store/actions/agent';
import {
  selectAgentListError,
  selectAgentDashboard,
  selectAgentDashboardLoading,
  selectAgentTotalCount
} from 'store/reducers/agent';
import { getColumns, getRows } from './helpers';

import { PaginationFullScreen, Table } from 'components';

const AgentDashboard = () => {
  const dispatch = useDispatch();
  const list = useSelector(selectAgentDashboard);
  const listLoading = useSelector(selectAgentDashboardLoading);
  const totalCount = useSelector(selectAgentTotalCount);
  const hasError = useSelector(selectAgentListError);

  const handleFetchAgentList = useCallback(
    (updatedFilters = {}) => {
      dispatch(
        getAgentList({
          ...defaultQueryParams,
          ...updatedFilters
        })
      );
    },
    [dispatch]
  );

  const { filters, handleChangePage, handleChangeSorting, handleRowClick, handleRowContextClick } = useFilters({
    onFetchList: handleFetchAgentList,
    instanceRoute: routes.AGENT,
    siblingPaginationType: siblingPaginationTypes.AGENT,
    totalCount
  });

  const columns = useMemo(() => getColumns(), []);
  const data = useMemo(() => getRows({ list }), [list]);

  return (
    <PaginationFullScreen
      count={totalCount}
      currentPage={Number(filters.page)}
      onPageChange={handleChangePage}
      length={list?.length}
      loading={listLoading}
      searchTerm={filters.search}
      hasError={hasError}
      hasNavigationTabs>
      <Table
        onSort={handleChangeSorting}
        onRowClick={handleRowClick}
        onRowContextClick={handleRowContextClick}
        searchTerm={filters.search}
        columns={columns}
        data={data}
        sortingDirection={filters.sorting_direction}
        sortingColumnName={filters.sorting_column}
      />
    </PaginationFullScreen>
  );
};

export default AgentDashboard;
