import styled from 'styled-components';
import styles from 'constants/styles';

export const TopNavigationWrapper = styled.div`
  background: ${styles.colors.WHITE};
  height: ${styles.layout.headerHeight}px;
  position: relative;
  z-index: 2;
`;

export const TopNavBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${styles.layout.headerHeight}px;
  padding: 12px 16px 12px 24px;
  position: relative;
  z-index: 5;
  background: ${({ theme }) => theme.colors.TOP_NAVBAR};
  ${({ theme }) =>
    theme.darkMode
      ? `border-left: 1px solid ${theme.colors.TOP_NAVBAR_BORDER};`
      : 'border-bottom: 1px solid rgba(0, 0, 0, 0.1);'}

  max-width: calc(100vw - ${({ theme }) => theme.layout.navigationWidth}px);
`;

export const RightBlock = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: ${({ hasFiltersButton }) => (hasFiltersButton ? 32 : 28)}%;
`;
