import { call, takeLatest, put } from 'redux-saga/effects';
import api_constants from 'constants/api';
import actionTypes from 'constants/actionTypes';
import modalTypes from 'constants/modalTypes';
import errors from 'constants/errors';

import Api from 'services/ApiService';
import { openModal, closeModal } from 'store/actions';

import { getInvoiceTypeParam } from 'common/helpers/invoice';
import { getMediaIds } from 'common/helpers/media';

function* closePayment({ payload }) {
  const { attachedFiles, ...data } = payload;
  try {
    let note_media_id = [];
    if (attachedFiles?.length) {
      const files = attachedFiles.map((file) => ({ file }));
      const mediaIdList = yield call(getMediaIds, { files, sub_folder: 'note' });
      note_media_id = mediaIdList;
    }

    const response = yield call(Api.amplifyPut, `${api_constants.PAYMENT}/${data.id}`, {
      ...data,
      note_media_id
    });

    if (!response?.id) {
      throw new Error(response?.error_message || response?.err?.message || response?.err?.name || errors.DEFAULT);
    }

    yield put({
      type: actionTypes.CLOSE_PAYMENT_SUCCESS,
      payload: response
    });
    yield put(closeModal(modalTypes.CLOSE_INVOICE_CONFIRMATION));
    yield put({
      type: actionTypes.SET_SERVICE_PAID
    });
    yield put(
      openModal(modalTypes.RESULT, {
        title: `${getInvoiceTypeParam({
          ...response,
          isDeposit: Boolean(response?.deposit?.length || response?.amount_deposit)
        })} closed`,
        text: 'The homeowner & pro will be notified.'
      })
    );
  } catch (err) {
    yield put({
      type: actionTypes.CLOSE_PAYMENT_ERROR
    });
    yield put(
      openModal(modalTypes.ERROR, {
        errorMessage: err?.message || err?.name || errors.DEFAULT
      })
    );
    console.log(err);
  }
}

export default function* watchClosePayment() {
  yield takeLatest(actionTypes.CLOSE_PAYMENT_FETCH, closePayment);
}
