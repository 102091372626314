import { call, takeLatest, put } from 'redux-saga/effects';

import api_constants from 'constants/api';
import actionTypes from 'constants/actionTypes';
import Api from 'services/ApiService';

function* getUserNoteList(action) {
  const { payload } = action;
  const { userId, page = 1, noCallLogs } = payload;

  try {
    const response = yield call(
      Api.amplifyGet,
      `${api_constants.USER}/${userId}/note?page=${page}${noCallLogs ? '&twilio_call_id=null' : ''}`
    );
    if (response && response.results) {
      yield put({
        type: actionTypes.GET_USER_NOTE_LIST_SUCCESS,
        payload: {
          count: response.count,
          noteList: response.results
        }
      });
    } else {
      yield put({
        type: actionTypes.GET_USER_NOTE_LIST_ERROR
      });
    }
  } catch (err) {
    console.log(err);
  }
}

export default function* watchGetUserNoteList() {
  yield takeLatest(actionTypes.GET_USER_NOTE_LIST_FETCH, getUserNoteList);
}
