import { call, takeEvery, put } from 'redux-saga/effects';

import { openModal } from 'store/actions/modal';
import { refreshTaskInfo } from 'store/actions/service';
import api_constants from 'constants/api';
import actionTypes from 'constants/actionTypes';
import errors from 'constants/errors';
import modalTypes from 'constants/modalTypes';
import Api from 'services/ApiService';

function* bundleServices(action) {
  const {
    payload: { id, ...data }
  } = action;

  try {
    const response = yield call(
      Api.amplifyPost,
      `${api_constants.SERVICE}/${id}${api_constants.BUNDLE_SERVICES}`,
      data
    );
    if (!response || response?.err) {
      throw new Error(errors.DEFAULT);
    }

    yield put({
      type: actionTypes.BUNDLE_SERVICES_SUCCESS,
      payload: response
    });

    yield put(
      openModal(modalTypes.BUNDLE_SERVICES_RESULT, {
        count: data.requests.length + 1,
        job_id: id
      })
    );

    yield put(refreshTaskInfo({ task_id: id, silent: true, isAllInfo: true }));
  } catch (err) {
    yield put({
      type: actionTypes.BUNDLE_SERVICES_ERROR
    });
    yield put(openModal(modalTypes.BUNDLE_SERVICES_RESULT, { hasError: true }));
    console.log(err);
  }
}

export default function* watchBundleServices() {
  yield takeEvery(actionTypes.BUNDLE_SERVICES_FETCH, bundleServices);
}
