import React from 'react';
import { Marker } from '@react-google-maps/api';

import marker from 'static/images/marker.svg';

const CustomerMarker = ({ job, position }) => {
  if (!job?.id) {
    return null;
  }

  return <Marker icon={{ url: marker }} position={position} />;
};

export default CustomerMarker;
