import React from 'react';
import { useDispatch, useRouter, useSelector } from 'hooks';
import modalTypes from 'constants/modalTypes';
import { openModal } from 'store/actions';
import {
  selectServiceInstance,
  selectServiceInstanceLoading,
  selectServiceBundleTotalCount,
  selectServiceBundleListLoading
} from 'store/reducers/service';
import { IconButton } from 'components';
import { hasJobBundleButton } from './helpers/job';
import { IconMargin } from './styled';

const BundleJobButton = () => {
  const dispatch = useDispatch();

  const jobLoading = useSelector(selectServiceInstanceLoading);
  const job = useSelector(selectServiceInstance);
  const jobBundleTotalCount = useSelector(selectServiceBundleTotalCount);
  const bundleListLoading = useSelector(selectServiceBundleListLoading);

  const { location } = useRouter();

  const handleBundleClick = () => {
    dispatch(openModal(modalTypes.BUNDLE_SERVICES));
  };

  const isBundleButton = hasJobBundleButton({
    pathname: location.pathname,
    admin_status: job?.admin_status,
    count: jobBundleTotalCount
  });

  if (jobLoading || bundleListLoading || !isBundleButton) {
    return null;
  }
  return (
    <IconMargin>
      <IconButton size={24} iconName="plus" iconSize={12} onClick={handleBundleClick} />
    </IconMargin>
  );
};

export default BundleJobButton;
