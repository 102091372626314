import { call, takeLatest, put } from 'redux-saga/effects';

import api_constants from 'constants/api';
import actionTypes from 'constants/actionTypes';
import Api from 'services/ApiService';

function* getServiceNoteList(action) {
  const { payload } = action;
  const { serviceId, page = 1 } = payload;
  try {
    const response = yield call(Api.amplifyGet, `${api_constants.SERVICE_REQUEST}/${serviceId}/note?page=${page}`);
    if (response && response.results) {
      yield put({
        type: actionTypes.GET_SERVICE_NOTE_LIST_SUCCESS,
        payload: {
          count: response.count,
          noteList: response.results
        }
      });
    } else {
      yield put({
        type: actionTypes.GET_SERVICE_NOTE_LIST_ERROR
      });
    }
  } catch (err) {
    console.log(err);
  }
}

export default function* watchGetServiceNoteList() {
  yield takeLatest(actionTypes.GET_SERVICE_NOTE_LIST_FETCH, getServiceNoteList);
}
