import styled from 'styled-components';
import { INVOICE_FORM_MARGIN_RIGHT, INVOICE_DIVIDER_MARGIN } from '../../constants/invoice';

export const InvoiceWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 24px 0 13px;
  ${({ isRejected, theme }) =>
    isRejected &&
    ` * {
  color: ${theme.colors.DARK_GREY_2} !important;
}`};
`;

export const InvoiceSection = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 16px;
  hr {
    margin-top: 16px;
  }
`;

export const MainHeading = styled.div`
  font-weight: 400;
  font-size: 20px;
  line-height: 1.2;
  color: ${({ theme }) => theme.colors.INVOICE_TEXT};
`;

export const MainTotalHeading = styled.span`
  margin: 0;
  font-weight: 400;
  font-size: 20px;
  line-height: 1.2;
  color: ${({ theme }) => theme.colors.WHITE};
  word-break: break-all;
  white-space: pre;
`;

export const SummaryHeading = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 1.14;
  margin-top: 11px;
  color: ${({ theme }) => theme.colors.INVOICE_TEXT};
  display: flex;
  align-items: center;
`;
export const MediaWrapper = styled.div`
  margin-left: 16px;
`;

export const MediaBeforeWrapper = styled.div`
  margin-left: 16px;
  margin-bottom: 8px;
`;

export const HeadingCell = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 14px;
`;

export const HeadingFlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 14px;
  padding-bottom: 4px;
  justify-content: center;
`;

export const HeadingFlexRow = styled.div`
  display: flex;
  align-items: center;
`;

export const HeadingTotalCell = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: row;
  flex: 1;
  align-items: center;
  margin: 25px 0 10px;
`;

export const StatusLabelCell = styled.div`
  display: flex;
`;

export const Column = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex: 1;
  max-width: ${({ fillWidth }) => (fillWidth ? '100%' : '250px')};
  ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom}`};
  ${({ column }) => column && 'flex-direction: column; align-items: start'};
`;
export const Wrapper = styled.div`
  display: flex;
  width: 100%;
`;
export const InvoiceRow = styled.div`
  display: flex;
  margin: 16px 0 7px;
  justify-content: flex-start;
  min-height: 40px;
`;

export const SummaryColumn = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  margin: 8px 30px 0 0;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  min-height: 40px;
`;

export const PercentColumn = styled.div`
  max-width: 80px;
  flex: 1;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.33;
  word-break: break-word;
  white-space: pre-wrap;
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
  justify-content: flex-start;
  margin-left: ${({ marginLeft = 3 }) => marginLeft}px;
  color: ${({ theme }) => theme.colors.INVOICE_TEXT};
  ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom}`};
`;

export const PayoutWrapper = styled.div`
  margin-top: 10px;
`;
export const SummaryRow = styled.div`
  margin-right: ${INVOICE_FORM_MARGIN_RIGHT}px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  ${({ maxHeight }) => maxHeight && `max-height: ${maxHeight}px`};
  ${({ payout }) => payout && `justify-content: space-between`};
`;

export const DividerMarginBlock = styled.div`
  margin-top: ${INVOICE_DIVIDER_MARGIN}px;
`;

export const Divider = styled.hr`
  background-color: ${({ theme }) => theme.colors.DARK_3};
  height: 1px;
  margin: 0;
  border-width: 0px;
`;

export const StatusLabel = styled.div`
  background-color: ${({ theme }) => theme.colors.DARK_2};
  color: ${({ color }) => color} !important;
  font-size: 12px;
  line-height: 1.33;
  border-radius: 20px;
  padding: 4px 24px;
  min-width: 105px;
  text-align: center;
`;

export const ActionsWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 8px 0 14px;
`;

export const RightActionsSection = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: center;
`;

export const Title = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 1.17;
  margin: 5px 0;
  flex: 1;
  color: ${({ theme }) => theme.colors.DARK_GREY};
`;

export const SummaryTitle = styled(Title)`
  margin-top: 8px;
  margin-bottom: 0;
`;

export const Value = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 1.33;

  color: ${({ theme }) => theme.colors.INVOICE_TEXT};
  margin: 0;
  word-break: break-word;
  white-space: pre-wrap;
`;

export const DateTimeValue = styled(Value)`
  font-weight: 500;
  font-size: 10px;
  line-height: 1.2;
  color: ${({ theme }) => theme.colors.DARK_GREY_2};
`;

export const CurrencyValue = styled.pre`
  font-weight: 400;
  font-size: 12px;
  line-height: 1.33;
  word-break: break-word;
  white-space: pre-wrap;
  color: ${({ theme }) => theme.colors.INVOICE_TEXT};
`;

export const SummaryCurrencyValue = styled(CurrencyValue)`
  margin: 8px 0 0;
`;

export const TotalPaymentRow = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  padding: 0 0 21px 0;
`;

export const PdfLink = styled(Value)`
  font-weight: 500;
  font-size: 10px;
  line-height: 1.2;
  color: ${({ theme }) => theme.colors.INVOICE_TEXT} !important;
  text-decoration: underline;
  cursor: pointer;
  margin: 0 0 8px;
  z-index: 11;
  &:hover {
    color: ${({ theme }) => theme.colors.DARK_GREEN} !important;
  }
`;

export const TooltipText = styled.div`
  bold {
    font-weight: 700;
  }
  font-size: 10px;
  color: ${({ theme }) => theme.colors.DARK_1};
  line-height: 1.5;
  ${({ marginLeft }) => marginLeft && `margin-left: ${marginLeft}px`};
  &:before {
    content: '';
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.DARK_1};
    display: inline-block;
    margin: 0 6px 0 4px;
    transform: translateY(-50%);
  }
`;

export const Notes = styled.div`
  margin-top: 20px;
`;

export const NoteTitle = styled.div`
  font-weight: 700;
  color: ${({ theme }) => theme.colors.WHITE};
  margin-bottom: 10px;
`;

export const NoteBody = styled.div`
  font-size: 12px;
  line-height: 1.33;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.colors.WHITE};
`;

export const NoteFile = styled.span`
  font-weight: 500;
  cursor: pointer;
  font-size: 10px;
  line-height: 1.2;
  text-decoration: underline;
  &:hover {
    color: ${({ theme }) => theme.colors.DARK_GREEN};
  }
  color: ${({ theme }) => theme.colors.WHITE};
`;

export const InfoItem = styled.div`
  font-weight: 500;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.DARK_GREY};
  margin-bottom: 10px;
`;
