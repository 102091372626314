import React from 'react';
import { connect } from 'react-redux';

import { closeModal } from 'store/actions';
import { selectModalData } from 'store/reducers/modal';

import modalTypes from 'constants/modalTypes';
import { ModalConfirmation } from 'components';

const CancelInvoiceFormConfirmation = ({ modalData: { isEditing, onConfirm } = {}, closeModal }) => {
  const closeConfirmationModal = () => {
    closeModal(modalTypes.CANCEL_INVOICE_FORM_CONFIRMATION);
  };

  return (
    <ModalConfirmation
      modalType={modalTypes.CANCEL_INVOICE_FORM_CONFIRMATION}
      hideModal={closeConfirmationModal}
      title={`Cancel invoice ${isEditing ? 'editing' : 'creation'}?`}
      text={`Changes that you made won't be saved.`}
      primaryButton={{
        title: 'No',
        onClick: closeConfirmationModal
      }}
      secondaryButton={{
        title: 'Yes',
        onClick: onConfirm
      }}
    />
  );
};

const mapStateToProps = (state) => ({
  modalData: selectModalData(state)
});

const mapDispatchToProps = {
  closeModal
};

export default connect(mapStateToProps, mapDispatchToProps)(CancelInvoiceFormConfirmation);
