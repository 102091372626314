import React from 'react';

import { useSelector, useTheme } from 'hooks';
import { isTestTasksVisible } from 'store/reducers/app';
import { Checkbox, Tooltip, Icon } from 'components';

import { useTestFilter } from 'navigation/hooks';
import { Info, TestTaskTooltipText } from './styled';

const TestFilterToggle = () => {
  const theme = useTheme();
  const hasTestTasks = useSelector(isTestTasksVisible);
  const handleToggleTestFilter = useTestFilter();

  return (
    <>
      <Checkbox
        checked={!hasTestTasks}
        label={'Hide Test Jobs'}
        green
        smallLabelFont
        labelTextColor={theme.colors.WHITE}
        labelFontWeight={700}
        onChange={handleToggleTestFilter}
      />
      <Info>
        <Tooltip
          title={
            <TestTaskTooltipText>
              <li>Exclude jobs from the list</li>
              <li>Disable all notifications for the test jobs</li>
            </TestTaskTooltipText>
          }
          inline
          left={0}
          bottom={28}
          minWidth={200}
          wrappedComponent={
            <Icon
              backgroundColor={theme.colors.INFO_BG}
              color={theme.colors.INFO_ICON}
              backgroundSize={12}
              size={2}
              name="info"
            />
          }
        />
      </Info>
    </>
  );
};

export default TestFilterToggle;
