import styled from 'styled-components';
import styles from 'constants/styles';

export const Sections = styled.div`
  display: flex;
  width: 100%;
`;

export const ActionContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  border-left: 1px solid ${styles.colors.LIGHT_GRAY};
  overflow: hidden;
`;

export const LoadingContainer = styled.div`
  display: flex;
  flex: 1;
  padding: 50px;
  justify-content: center;
  position: relative;
  height: calc(100vh - ${styles.layout.headerHeight}px);
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  max-height: calc(100vh - ${styles.layout.headerHeight}px);
  overflow: overlay;
  min-width: 60%;
`;
