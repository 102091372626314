import { call, takeLatest, put } from 'redux-saga/effects';

import actionTypes from 'constants/actionTypes';
import api_constants from 'constants/api';
import errors from 'constants/errors';
import modalTypes from 'constants/modalTypes';
import { modalIconProps, modalProps } from 'common/enums/modal';

import Api from 'services/ApiService';
import { openModal } from 'store/actions/modal';
import { getCustomerInstance } from 'store/actions/customer';
import { getServiceLocation } from 'store/actions/service';

function* uploadCustomerInspectionReport({ payload: { reportType, user_id, file, task_id } }) {
  try {
    const mediaResponse = yield call(Api.amplifyPost, api_constants.MEDIA, {
      original_name: file.name,
      sub_folder: 'report'
    });
    if (!mediaResponse || !mediaResponse?.upload_url || !mediaResponse.upload_url.length) {
      throw new Error(errors.DEFAULT);
    }

    const uploadResponse = yield call(Api.s3Put, mediaResponse.upload_url, file, { 'Content-Type': file.type });
    if (!uploadResponse || !uploadResponse?.ok) {
      throw new Error(errors.DEFAULT);
    }

    const response = yield call(Api.amplifyPost, api_constants.INSPECTION_REPORT, {
      report_type: reportType,
      media_id: mediaResponse.id,
      user_id
    });

    if (!response || response?.err) {
      throw new Error(errors.DEFAULT);
    }

    yield put({
      type: actionTypes.UPLOAD_CUSTOMER_INSPECTION_REPORT_SUCCESS,
      payload: user_id
    });
    yield put(
      openModal(modalTypes.RESULT, {
        primaryButtonTitle: 'GOT IT',
        text: `${file?.name}\n\n\nThe current report has been successfully sent to the HO’s account.`,
        iconProps: modalIconProps.INSPECTION_OK,
        modalProps: modalProps.INSPECTION_OK
      })
    );

    if (task_id) {
      yield put(getServiceLocation({ serviceId: task_id }));
      return;
    }
    yield put(getCustomerInstance(user_id));
  } catch (err) {
    yield put({
      type: actionTypes.UPLOAD_CUSTOMER_INSPECTION_REPORT_ERROR,
      payload: user_id
    });
    yield put(
      openModal(modalTypes.ERROR, {
        errorMessage: errors.DEFAULT
      })
    );
  }
}

export default function* watchUploadCustomerInspectionReport() {
  yield takeLatest(actionTypes.UPLOAD_CUSTOMER_INSPECTION_REPORT_FETCH, uploadCustomerInspectionReport);
}
