import { call, put, takeEvery } from 'redux-saga/effects';

import { apiPageSizes } from 'common/enums/queryParams';
import api_constants from 'constants/api';
import actionTypes from 'constants/actionTypes';
import errors from 'constants/errors';
import Api from 'services/ApiService';
import { chatMessageTransformer } from 'utils';

function* getSupportMessageList({ payload: { idGt, idLt, userId, setIsOpen, limit = apiPageSizes.DEFAULT } }) {
  try {
    const response = yield call(
      Api.amplifyGet,
      `${api_constants.SUPPORT_REQUEST}?limit=${limit}&user_id=${userId}${idGt ? '&id_gt=' + idGt : ''}${
        idLt ? '&id_lt=' + idLt : ''
      }${setIsOpen === false ? `&set_is_open=${setIsOpen}` : ''}`
    );

    if (!response || !response?.results) {
      throw new Error(errors.DEFAULT);
    }
    const messageList = response.results.map((item) => chatMessageTransformer(item));

    yield put({
      type: actionTypes.GET_SUPPORT_MESSAGE_LIST_SUCCESS,
      payload: {
        count: response.count,
        userId,
        messageList,
        idGt,
        idLt,
        setIsOpen
      }
    });
    yield put({
      type: actionTypes.GET_SUPPORT_LINK_PREVIEWS_FETCH,
      payload: {
        messageList,
        userId
      }
    });
  } catch (err) {
    yield put({
      type: actionTypes.GET_SUPPORT_MESSAGE_LIST_ERROR,
      payload: {
        message: errors.DEFAULT,
        userId
      }
    });
    console.log(err);
  }
}

export default function* watchGetSupportMessageList() {
  yield takeEvery(actionTypes.GET_SUPPORT_MESSAGE_LIST_FETCH, getSupportMessageList);
}
