import { Auth } from 'aws-amplify';
import { useEffect } from 'hooks';
import { PRINT_AWS_CREDENTIALS } from 'common/constants/app';

/***
 * print AWS credentials for executing requests from Postman
 */
const useAwsCredentials = () => {
  useEffect(() => {
    // TODO: (Veniamin) move in config when it will be in the project
    if (PRINT_AWS_CREDENTIALS) {
      try {
        Auth.currentCredentials().then((data) => {
          console.log(
            'Autorization: AWS Signature',
            '\naccessKey',
            data.accessKeyId,
            '\nSecretKey',
            data.secretAccessKey,
            '\nAWS Region: us-east-1',
            '\nService Name: execute-api',
            '\nSessionToken:',
            data.sessionToken
          );
        });
      } catch (error) {}
    }
  }, []);
};

export default useAwsCredentials;
