import React from 'react';

import { useCallback, useDispatch, useFilters, useMemo, useSelector } from 'hooks';

import { csvFileTypes } from 'common/enums/app';
import { siblingPaginationTypes } from 'common/enums/navigation';
import { userGroups } from 'common/enums/user';

import { getReferralList } from 'store/actions/promoCode';
import {
  selectReferralListError,
  selectReferralListLoading,
  selectReferralList,
  selectReferralCount
} from 'store/reducers/promoCode';
import { Table, PaginationFullScreen } from 'components';

import { getColumns, getRows } from './helpers';

const ReferralDashboard = ({ userGroupId = userGroups.CUSTOMER.TYPE }) => {
  const dispatch = useDispatch();

  const listLoading = useSelector(selectReferralListLoading);
  const list = useSelector(selectReferralList);
  const totalCount = useSelector(selectReferralCount);
  const hasError = useSelector(selectReferralListError);

  const handleFetchReferralList = useCallback(
    (updatedFilters = {}) => {
      dispatch(getReferralList(updatedFilters));
    },
    [dispatch]
  );

  const instanceRoute = useMemo(
    () => Object.values(userGroups).find((userGroup) => userGroup.TYPE === userGroupId)?.ROUTE,
    [userGroupId]
  );
  const siblingPaginationType = useMemo(
    () =>
      userGroupId === userGroups.CUSTOMER.TYPE
        ? siblingPaginationTypes.REFERRAL_CUSTOMER
        : siblingPaginationTypes.REFERRAL_PROVIDER,
    [userGroupId]
  );

  const { filters, handleChangePage, handleChangeSorting, handleRowClick, handleRowContextClick, handleDownloadCSV } =
    useFilters({
      csvFileType: csvFileTypes.REFERRAL,
      onFetchList: handleFetchReferralList,
      instanceRoute,
      instanceIdFieldName: 'target_user_id',
      siblingPaginationType,
      totalCount
    });

  const columns = useMemo(() => getColumns({ onDownloadCSV: handleDownloadCSV }), [handleDownloadCSV]);
  const data = useMemo(() => getRows({ list }), [list]);

  return (
    <PaginationFullScreen
      count={totalCount}
      currentPage={Number(filters.page)}
      onPageChange={handleChangePage}
      length={list?.length}
      loading={listLoading}
      searchTerm={filters.search}
      hasError={hasError}
      hasNavigationTabs>
      <Table
        onSort={handleChangeSorting}
        onRowClick={handleRowClick}
        onRowContextClick={handleRowContextClick}
        searchTerm={filters.search}
        columns={columns}
        data={data}
        sortingDirection={filters.sorting_direction}
        sortingColumnName={filters.sorting_column}
      />
    </PaginationFullScreen>
  );
};

export default ReferralDashboard;
