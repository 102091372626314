import React from 'react';
import { HookForm } from 'components';
import { CustomFieldsTooltip } from '../../../';

export const PushNotification = ({ control, onPushTitleChange }) => {
  return (
    <>
      <div
        style={{
          fontSize: '20px',
          marginTop: '28px',
          color: '#FFFFFF'
        }}>
        Push settings
      </div>
      <div style={{ margin: '16px 0 8px 0' }}>
        <HookForm.ControlledTextarea
          name="campaign_push.template_title"
          control={control}
          label="Heading*"
          placeholder="Enter heading"
          renderTooltip={() => <CustomFieldsTooltip />}
          lengthInfoPrefix="Restricted length"
          rules={{
            maxLength: 30
          }}
          hasSmallLegthTitle
          onCustomChange={onPushTitleChange}
          style={{
            height: '35px',
            minHeight: '35px'
          }}
        />
      </div>
      <HookForm.ControlledTextarea
        name="campaign_push.template_body"
        control={control}
        label="Description*"
        placeholder="Enter description"
        renderTooltip={() => <CustomFieldsTooltip />}
        lengthInfoPrefix="Restricted length"
        rules={{
          maxLength: 170
        }}
        hasSmallLegthTitle
        style={{
          minHeight: '80px'
        }}
      />
    </>
  );
};
