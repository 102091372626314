import { all, takeEvery, put, select } from 'redux-saga/effects';
import actionTypes from 'constants/actionTypes';
import { selectMediaUrlsByType } from 'store/reducers/runtimeCache';

// download media list for each of list item
function* getMediaUrlsForList({ payload: { instances, type } }) {
  try {
    const cachedUrls = yield select(selectMediaUrlsByType(type));
    const uncachedInstances = instances.filter(({ media, id }) => media.length && !cachedUrls[id]?.length);
    const uncachedInstancesPromises = uncachedInstances.map(({ media, id }) =>
      put({
        type: actionTypes.GET_MEDIA_URLS_FETCH,
        payload: { media, id, type }
      })
    );

    yield all(uncachedInstancesPromises);
  } catch (error) {
    yield put({
      type: actionTypes.GET_MEDIA_URLS_FOR_LIST_ERROR
    });
  }
}

export default function* watchGetMediaUrlsForList() {
  yield takeEvery(actionTypes.GET_MEDIA_URLS_FOR_LIST_FETCH, getMediaUrlsForList);
}
