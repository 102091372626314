import React from 'react';
import { useDispatch, useSelector } from 'hooks';

import { invoiceTypes } from 'common/enums/invoice';
import modalTypes from 'constants/modalTypes';
import { selectJobInvoicesLoading } from 'store/reducers/invoice';
import { closeModal, updateQuotation, updatePayment } from 'store/actions';
import { ModalConfirmation } from 'components';
import { selectModalData } from 'store/reducers/modal';

const ValidateInvoice = () => {
  const dispatch = useDispatch();
  const modalData = useSelector(selectModalData);
  const loading = useSelector(selectJobInvoicesLoading);
  const { invoiceType, job_id = null, invoice_id, is_final_payment } = modalData || {};

  const handleCloseModal = () => {
    dispatch(closeModal(modalTypes.VALIDATE_INVOICE));
  };

  const handleValidateInvoice = () => {
    if (invoiceType.TYPE === invoiceTypes.QUOTATION.TYPE) {
      dispatch(
        updateQuotation({
          data: {
            is_moderated: true,
            service_request_id: job_id,
            id: invoice_id
          },
          hasModeration: true
        })
      );
      return;
    }

    dispatch(
      updatePayment({
        data: {
          is_moderated: true,
          service_request_id: job_id,
          is_final_payment,
          id: invoice_id
        },
        hasModeration: true
      })
    );
  };

  return (
    <ModalConfirmation
      modalType={modalTypes.VALIDATE_INVOICE}
      hideModal={handleCloseModal}
      title={`Are you sure?`}
      text={`The ${invoiceType.confirmationLabel} will be sent to HO.\nThe Pro will receive the notification also.`}
      primaryButton={{
        title: 'Cancel',
        disabled: loading,
        onClick: handleCloseModal
      }}
      secondaryButton={{
        title: 'Send to HO',
        onClick: handleValidateInvoice,
        loading: loading,
        disabled: loading
      }}
    />
  );
};

export default ValidateInvoice;
