import { invoiceTypes } from 'common/enums/invoice';

import { sumPropInArray, sumArray } from './arrayHelpers';

export const getPayoutSum = ({
  invoice_type,
  amount_tip,
  deposit,
  labor,
  material,
  flat_rate,
  items,
  amount_deposit,
  amount_labor_cost,
  amount_material
}) => {
  if (invoice_type === invoiceTypes.DEPOSIT.value) {
    const depositTotal = deposit?.length ? sumPropInArray(deposit) : amount_deposit;
    return sumArray([depositTotal]);
  }

  const tipTotal = parseFloat(amount_tip || 0);
  if (items && items.length) {
    return (parseFloat(sumArray([sumPropInArray(items)])) + parseFloat(tipTotal)).toFixed(2);
  }

  const laborTotal = labor?.length ? sumPropInArray(labor) : amount_labor_cost;
  const materialTotal = material?.length ? sumPropInArray(material) : amount_material;
  const flatRateTotal = sumPropInArray(flat_rate);
  return sumArray([laborTotal, materialTotal, flatRateTotal, amount_tip]);
};

export const getAmountCharge = ({
  invoice_type,
  amount_tip,
  deposit,
  labor,
  material,
  flat_rate,
  items,
  amount_display_deposit,
  amount_display_labor_cost,
  amount_display_material
}) => {
  if (invoice_type === invoiceTypes.DEPOSIT.value) {
    return ((deposit?.length ? sumPropInArray(deposit, 'amount_display') : amount_display_deposit) / 100).toFixed(2);
  }

  const tipTotal = parseFloat(amount_tip || 0);
  if (items && items.length) {
    return (sumPropInArray(items, 'amount_display') / 100 + parseFloat(tipTotal)).toFixed(2);
  }

  const laborTotal = labor?.length ? sumPropInArray(labor, 'amount_display') : amount_display_labor_cost;
  const materialTotal = material?.length ? sumPropInArray(material, 'amount_display') : amount_display_material;
  const flatRateTotal = sumPropInArray(flat_rate, 'amount_display');
  return (parseInt(sumArray([laborTotal, materialTotal, flatRateTotal, tipTotal])) / 100).toFixed(2);
};

export const getFee = (invoiceFields) => {
  const amount_charge = parseFloat(getAmountCharge(invoiceFields));
  const amount_payout = parseFloat(getPayoutSum(invoiceFields));
  // Fee which depends only on payout to pro and fee %
  // Equal for all invoices
  const commission = amount_charge - amount_payout;
  return (commission || 0).toFixed(2);
};

export const getAmountStripeFee = (invoiceFields) => {
  const { percent_stripe_fee = 0 } = invoiceFields;
  const amountCharge = parseFloat(getAmountCharge(invoiceFields) || 0);
  return (Math.round(amountCharge * parseFloat(percent_stripe_fee)) / 100.0).toFixed(2);
};

export const getTotalPrice = (invoiceFields) => {
  const amountCharge = parseFloat(getAmountCharge(invoiceFields) || 0);
  const amountStripeFee = parseFloat(getAmountStripeFee(invoiceFields) || 0);

  const hoPayment = parseInt(parseInt(amountCharge * 100) + parseInt(amountStripeFee * 100)) / 100;
  return hoPayment.toFixed(2);
};

export default {
  getAmountCharge,
  getAmountStripeFee,
  getTotalPrice,
  getPayoutSum,
  getFee
};
