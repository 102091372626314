import { call, takeLatest, put } from 'redux-saga/effects';
import Api from 'services/ApiService';
import api_constants from 'constants/api';
import actionTypes from 'constants/actionTypes';
import errors from 'constants/errors';

function* getCustomerLocation({ payload }) {
  const { customerId: id } = payload;
  try {
    const response = yield call(Api.amplifyGet, `${api_constants.CUSTOMER_LOCATION}?user_id=${id}`);
    const [location] = response?.results || [];

    if (!location) {
      throw new Error(errors.DEFAULT);
    }
    yield put({
      type: actionTypes.GET_CUSTOMER_LOCATION_SUCCESS,
      payload: location
    });
  } catch (err) {
    console.log(err);
    yield put({
      type: actionTypes.GET_CUSTOMER_LOCATION_ERROR
    });
  }
}

export default function* watchGetCustomerLocation() {
  yield takeLatest(actionTypes.GET_CUSTOMER_LOCATION_FETCH, getCustomerLocation);
}
