import { call, takeLatest, put } from 'redux-saga/effects';
import api_constants from 'constants/api';
import actionTypes from 'constants/actionTypes';
import Api from 'services/ApiService';

function* getAgentInstance(action) {
  const { payload: id } = action;
  try {
    const response = yield call(
      Api.amplifyGet,
      `${api_constants.REAL_ESTATE_AGENT}/${id}`
    );
    if (response) {
      yield put({
        type: actionTypes.GET_AGENT_INSTANCE_SUCCESS,
        payload: response
      });
    } else {
      yield put({
        type: actionTypes.GET_AGENT_INSTANCE_ERROR
      });
    }
  } catch (err) {
    console.log(err);
  }
}

export default function* watchGetAgentInstance() {
  yield takeLatest(actionTypes.GET_AGENT_INSTANCE_FETCH, getAgentInstance);
}
