import React from 'react';

import { formatters } from 'utils';
import { getAmountCharge, getAmountStripeFee } from '../../helpers/invoices';
import { useInvoiceTotalValues } from '../../hooks/useInvoiceTotalValues';

import { Button, Line } from 'components';
import { ButtonRow, Value, ValueWrapper, Title, Row, Separator, dividerStyle } from './styled';

export const InvoiceTotal = ({
  commissionType,
  control,
  invoice,
  invoiceType,
  onCancelForm,
  onSubmitForm,
  hasCancelButtonDisabled,
  hasSubmitDisabled
}) => {
  const { percent_stripe_fee, payoutSum, invoiceValues } = useInvoiceTotalValues({
    commissionType,
    control,
    invoice,
    invoiceType
  });
  // Do not calculate values in floating point
  // it can lead to rounding errors
  const amountCharge = parseInt(getAmountCharge(invoiceValues) * 100);

  const markup = amountCharge - payoutSum;
  const serviceFee = parseInt(
    getAmountStripeFee({
      percent_stripe_fee,
      ...invoiceValues
    }) * 100
  );

  const totalPrice = amountCharge + serviceFee;
  const totalGMV = totalPrice;
  const dobbyMargin = amountCharge - payoutSum;
  return (
    <>
      <Row secondary>
        <Title>Pro Payout</Title>
        <ValueWrapper>
          <Value>{formatters.dollarCurrencyDB(payoutSum) || '0.00'}</Value>
        </ValueWrapper>
      </Row>

      <Row secondary>
        <Title>Dobby’s Operating Margin</Title>
        <ValueWrapper>
          <Value>{formatters.dollarCurrencyDB(markup) || '0.00'}</Value>
        </ValueWrapper>
      </Row>

      <Row secondary>
        <Title>Stripe Fee</Title>
        <ValueWrapper>
          <Value>{formatters.dollarCurrencyDB(serviceFee) || '0.00'}</Value>
        </ValueWrapper>
      </Row>

      <Row>
        <Title>HO Payment</Title>
        <ValueWrapper>
          <Value>{formatters.dollarCurrencyDB(totalPrice) || '0.00'}</Value>
        </ValueWrapper>
      </Row>

      <Line lineStyle={dividerStyle} />

      <Row secondary marginTop={0}>
        <Title>Dobby's Net Margin</Title>
        <ValueWrapper accent>
          <Value>
            {/* {dobbyMargin < 0 ? '- $' : '$'} */}
            {formatters.dollarCurrencyDB(Math.abs(dobbyMargin)) || '0.00'}
          </Value>
        </ValueWrapper>
      </Row>

      <Row secondary>
        <Title>GMV Before Discounts</Title>
        <ValueWrapper>
          <Value>{formatters.dollarCurrencyDB(totalGMV) || '0.00'}</Value>
        </ValueWrapper>
      </Row>
      <ButtonRow>
        <Button disabled={hasCancelButtonDisabled} variant="primary" onClick={onCancelForm}>
          Cancel
        </Button>
        <Separator />
        <Button disabled={hasSubmitDisabled || totalPrice <= 0} onClick={onSubmitForm}>
          Preview
        </Button>
      </ButtonRow>
    </>
  );
};
