import React from 'react';

import { useDispatch, useSelector } from 'hooks';
import modalTypes from 'constants/modalTypes';

import { closeModal } from 'store/actions';
import { deletePersistentNotification } from 'store/actions/notification';
import { selectClearAllPersistentNotificationsLoading } from 'store/reducers/notification';

import { ModalConfirmation } from 'components';

const ClearAllNotificationsConfirmation = () => {
  const dispatch = useDispatch();

  const loading = useSelector(selectClearAllPersistentNotificationsLoading);

  const onConfirmModal = () => {
    dispatch(deletePersistentNotification({ isClearAll: true }));
  };

  const onCloseModal = () => {
    dispatch(closeModal(modalTypes.CLEAR_ALL_PERSISTENT_NOTIFICATIONS));
  };

  return (
    <ModalConfirmation
      maxWidth={null}
      modalType={modalTypes.CLEAR_ALL_PERSISTENT_NOTIFICATIONS}
      smallPaddings={false}
      title="Clear all notifications?"
      hideModal={onCloseModal}
      primaryButton={{
        title: 'Cancel',
        onClick: onCloseModal,
        disabled: loading
      }}
      secondaryButton={{
        title: 'Confirm',
        onClick: onConfirmModal,
        disabled: loading,
        loading
      }}
    />
  );
};

export default ClearAllNotificationsConfirmation;
