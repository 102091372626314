import React from 'react';

import { useDispatch } from 'hooks';
import { useSearchFilter, useCustomFilters, useTopNavigation } from 'navigation/hooks';
import { prepareEmptyJobFilters } from 'common/helpers/job';

import { toggleNotificationsPopup } from 'store/actions/navigation';

import { SearchInput, IconButton } from 'components';
import {
  BreadcrumbsNavigation,
  NotificationsPopup,
  SiblingsDetailsPagination,
  TabsNavigation,
  TaskFiltersForm,
  OwnerReportForm,
  TaskOwnerSelect
} from './containers';
import { GeoLocationSelect } from 'containers';
import { ProfileSettings, FiltersHeader } from './components';
import { TopNavigationWrapper, TopNavBar, RightBlock } from './styled';
import { useNavigate } from 'react-router-dom';
import routes from 'constants/routes';

const TopNavigation = () => {
  const [hasOwnerReportForm, setHasOwnerReportForm] = React.useState(false);
  const handleToggleOwnerReportForm = () => setHasOwnerReportForm(!hasOwnerReportForm);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    hasNotificationsBadge,
    hasTaskOwnerSelect,
    hasGeoLocationSelect,
    hasNavigationTabs,
    hasCreateCampaignButton
  } = useTopNavigation({
    init: true
  });
  const { searchType, searchValue, searchPlaceholder, handleSearch } = useSearchFilter();
  const {
    coreNavigationFilters,
    customFilters,
    hasCustomFilters,
    hasCustomFiltersForm,
    hasCustomFiltersButton,
    handleRemoveFilter,
    handleRemoveAllFilters,
    handleSubmitCustomFiltersForm,
    handleToggleCustomFiltersForm
  } = useCustomFilters();

  const handleOpenNotificationsPopup = () => {
    dispatch(toggleNotificationsPopup(true));
  };

  return (
    <>
      <TopNavigationWrapper>
        <TopNavBar>
          <BreadcrumbsNavigation />
          <RightBlock hasFiltersButton={hasCustomFiltersButton}>
            <SiblingsDetailsPagination />
            {hasTaskOwnerSelect && <TaskOwnerSelect />}
            {hasGeoLocationSelect && <GeoLocationSelect />}
            {hasCreateCampaignButton && (
              <IconButton
                onClick={() => navigate(routes.CAMPAIGN_CREATE)}
                margin="0 16px 0 0"
                text={'Create new campaign'}
                height={24}
                width={166}
                iconName="plus"
                backgroundIconSize={12}
              />
            )}
            {Boolean(searchType) && (
              <SearchInput
                onSearch={handleSearch}
                searchType={searchType}
                placeholder={searchPlaceholder}
                defaultSearch={searchValue}
              />
            )}
            <ProfileSettings
              hasCustomFiltersButton={hasCustomFiltersButton}
              hasNotificationsBadge={hasNotificationsBadge}
              onToggleCustomFiltersForm={handleToggleCustomFiltersForm}
              onToggleOwnerReportForm={handleToggleOwnerReportForm}
              onOpenNotificationsPopup={handleOpenNotificationsPopup}
            />
          </RightBlock>
        </TopNavBar>
      </TopNavigationWrapper>
      {hasCustomFiltersForm && (
        <TaskFiltersForm
          onToggleForm={handleToggleCustomFiltersForm}
          onSubmitForm={handleSubmitCustomFiltersForm}
          initialValues={customFilters}
          defaultResetValues={prepareEmptyJobFilters()}
        />
      )}
      {hasOwnerReportForm && <OwnerReportForm onToggleForm={handleToggleOwnerReportForm} />}
      {hasCustomFilters && (
        <FiltersHeader
          onRemoveAllFilters={handleRemoveAllFilters}
          onRemoveFilter={handleRemoveFilter}
          hasFilters={hasCustomFilters}
          filters={customFilters}
          coreNavigationFilters={coreNavigationFilters}
        />
      )}
      <NotificationsPopup />
      {hasNavigationTabs && <TabsNavigation />}
    </>
  );
};

export default TopNavigation;
