import { formatterTypes } from 'common/enums/form';
import { regexExpressions } from 'common/enums/regex';
import { capitalize } from 'common/helpers/transformers';
import moment from 'moment-timezone';
import dates from 'constants/dates';

const placeSpaces = (string) => string.replace(/ /g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
const placeCommas = (string) => string.replace(/,/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');

const formatCurrency = (value) => {
  if (!value) return '';
  if (value[value.length - 1] !== '.' && isNaN(parseFloat(value[value.length - 1], 10))) {
    return placeCommas(value.slice(0, -1));
  } else {
    const numberParts = parseFloat(value).toFixed(2).split('.');
    if (numberParts[1]?.length > 2) {
      return placeCommas(numberParts[0] + '.' + numberParts[1].slice(0, 2));
    }
  }
  return placeCommas(value);
};

const formatCurrencyView = (value) => {
  if (!value) return '0.00';
  const numberParts = parseFloat(value).toFixed(2).split('.');
  if (numberParts[1]?.length > 2) {
    return placeCommas(numberParts[0] + '.' + numberParts[1].slice(0, 2));
  }

  return placeCommas(parseFloat(value).toFixed(2));
};

export const humanizedDate = (datetime) => {
  const utcOffset = moment(datetime).utcOffset();
  const CURRENT = moment();
  const notificationDate = datetime ? moment(datetime).add(utcOffset, 'minutes') : moment();
  const NOW = CURRENT.clone().startOf('minute');
  const TODAY = CURRENT.clone().startOf('day');
  const YESTERDAY = CURRENT.clone().subtract(1, 'days').startOf('day');

  if (notificationDate.isSame(NOW, 'm')) {
    return 'Just Now';
  }
  if (notificationDate.isSame(TODAY, 'd')) {
    return formatters.dateUTC(notificationDate, dates.TIME);
  }
  if (notificationDate.isSame(YESTERDAY, 'd')) {
    return `yesterday at ${formatters.dateUTC(notificationDate, dates.TIME)}`;
  }
  return formatters.dateWithTimezone(notificationDate, dates.DATETIME_FULL_YEAR_A);
};

export const formatWithUnderscores = (string) => {
  return string.replace(/[^\w\s]/g, '').replace(/\s+(?=[a-zA-Z])/g, '_');
};

export const formatters = {
  amount: (value) => {
    if (!value) return '';
    return placeSpaces(value);
  },
  commaSeparated: (value) => {
    if (!value) return '';
    return placeCommas(value.toString());
  },
  currency: formatCurrency,
  currencyView: (value) => formatCurrencyView(value),
  discountCurrency: (value) => {
    const formattedValue = formatCurrency(value.toString());
    return formattedValue ? '- $' + formattedValue : '';
  },
  dollarCurrencyDB: (value, options) => {
    const prefix = options?.withSpace ? '$ ' : '$';
    return prefix + formatCurrencyView((value / 100)?.toString());
  },
  dollarCurrencyInput: (value) => {
    const formattedValue = formatCurrency(value?.toString());
    return formattedValue ? '$' + formattedValue : '';
  },
  dollarCurrencyInputZero: (value) => {
    const formattedValue = formatCurrency(value?.toString());
    return formattedValue ? '$' + formattedValue : '';
  },
  currencyInput: (value) => formatCurrency(value?.toString()),
  dollarCurrencyView: (value) => {
    const formattedValue = formatCurrencyView(value.toString());
    return formattedValue ? '$' + formattedValue : '$0.00';
  },
  dollarNumber: (value) => {
    const formattedValue = formatCurrency(value.toString());
    return formattedValue ? '$' + formattedValue : '';
  },
  date: (value, prevValue) => {
    if (!value) return value;
    const valueOnlyNumbers = value.replace(/[^\d]/g, '');
    const prevValueOnlyNumbers = prevValue && prevValue.replace(/[^\d]/g, '');

    // Enable backspacing:
    // if the user is backspacing and they backspace a forward slash, the date's
    // numbers won't be affected so just return the value.
    if (valueOnlyNumbers === prevValueOnlyNumbers) return value;

    const month = valueOnlyNumbers.slice(0, 2);
    const day = valueOnlyNumbers.slice(2, 4);
    const year = valueOnlyNumbers.slice(4, 8);

    if (valueOnlyNumbers.length < 2) return `${month}`;
    if (valueOnlyNumbers.length === 2) return `${month}`;
    if (valueOnlyNumbers.length < 4) return `${month}/${day}`;
    if (valueOnlyNumbers.length === 4) return `${month}/${day}`;
    if (valueOnlyNumbers.length > 4) return `${month}/${day}/${year}`;
  },
  messageDate: (value) => {
    const localDate = moment(value).add(moment(value).utcOffset(), 'minutes');
    const today = new Date();

    const prettyDate = moment(localDate).format(dates.DATETIME_FULL);

    const ms = moment(moment(today).format(dates.DATETIME_FULL), dates.DATETIME_FULL).diff(
      moment(prettyDate, dates.DATETIME_FULL)
    );

    if (ms >= 172_800_000) {
      return moment(localDate).format(dates.DATETIME_FULL_YEAR);
    }

    if (ms >= 86_400_000) {
      const isYesterday =
        Number(moment(today).format('DD')) - Number(moment(prettyDate, dates.DATETIME_FULL).format('DD'));
      return isYesterday
        ? `yesterday at ${moment(localDate).format('hh:mm a')}`
        : moment(localDate).format(dates.DATETIME_FULL_YEAR);
    }

    if (ms >= 3_600_000) {
      return `${moment.duration(ms).hours()}h ago`;
    }

    return `${moment.duration(ms).minutes() || 1} min ago`;
  },
  dateUTC: (value, format = dates.DATE_SHORT) => (value ? moment(value).format(format) : value),
  dateWithTimezone: (value, format = dates.DATE_SHORT) =>
    value ? moment(value).add(moment(value).utcOffset(), 'minutes').format(format) : value,
  humanizedDate,
  timeWindow: ({ startValue, endValue, isAddUtcOfffset, isIcon, isDayOfWeek }) => {
    if (!startValue || !endValue) {
      return;
    }

    const currentYear = new Date().getFullYear();
    const isYear = new Date(endValue).getFullYear() !== currentYear;

    const startDate = moment(startValue).add(isAddUtcOfffset ? moment(startValue).utcOffset() : 0, 'minutes');
    const endDate = moment(endValue).add(isAddUtcOfffset ? moment(endValue).utcOffset() : 0, 'minutes');
    const startFormattedPart = startDate.format(
      `${isIcon ? '📅 ' : ''}${isDayOfWeek ? 'ddd, ' : ''}MMM Do${isYear ? '[,] YYYY' : ''}[,] h${
        startDate.minutes() ? ':mma' : 'a'
      }`
    );

    const endFormattedPart = endDate.format(endDate.minutes() ? 'h:mma' : 'ha');
    return `${startFormattedPart}-${endFormattedPart}`;
  },
  default: (value) => value,
  name: (value) => capitalize(value),
  percent: (value) => {
    return formatCurrency(parseFloat(value).toString()) + '%';
  },
  percentDB: (value) => {
    return formatCurrency((value / 100).toString()) + '%';
  },
  [formatterTypes.INVOICE_TEXT]: (value) => value.replace(regexExpressions.INVOICE_TEXT, ''),
  [formatterTypes.DATETIME_EXECUTION_LOCAL]: (value) =>
    value?.trim() ? moment(value).format(dates.DATETIME_FULL_YEAR_A_2) : '',
  [formatterTypes.DATE_EXECUTION]: (value) => (value?.trim() ? moment(value).format(dates.DATE) : '')
};
