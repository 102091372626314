import styled from 'styled-components';
import styles from 'constants/styles';

export const FlexRow = styled.div`
  display: flex;
  flex: 1;
  padding: 13px 0 5px;

  &:first-child {
    padding: 18px 0 5px;
  }
  &:last-child {
    padding: 28px 0 0;
    justify-content: space-between;
  }
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ContentFormWrapper = styled.form`
  padding: 2px 24px 40px 24px;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
`;

export const Label = styled.label`
  font-weight: 600;
  font-size: 12px;
  line-height: 1.17;
  margin-bottom: 5px;
  color: ${styles.colors.GRAY};
`;
export const RelativeWrapper = styled.div`
  .css-4l7j15 {
    background: ${({ theme }) => theme.colors.DARK_GREY_3};
  }

  .css-18puooo {
    width: 8px;
  }
  .css-1dozdou {
    padding: 0 20px 0 34px;
  }
  .css-h7tmkn, .css-l0iinn, .PrivatePickersYear-yearButton {
    color: ${({ theme }) => theme.colors.WHITE};
  }
  .css-7lip4c, .css-12ha4i7, .css-a1rc6s, .Mui-selected, .MuiTabs-indicator, .css-2ujp1m {
    background: ${({ theme }) => theme.colors.DARK_GREEN_2} !important;
  }
  .css-2ujp1m {
    border-color: ${({ theme }) => theme.colors.DARK_GREEN_2};
  }

  .MuiPickersDay-root {
    width: 32px;
    height: 32px;
    margin 4px;
  }
  .MuiButtonBase-root {
    font-weight: 400;
    font-size: 14px;
    line-height: 1.14;
    color: ${({ theme }) => theme.colors.DATE_MODAL_WEEK};
    background: unset;
    width: 32px;
    height: 32px;
    margin 4px;
   ${({ theme }) =>
     theme.darkMode &&
     `&: hover {
    background: ${theme.colors.DARK_GREY_7};
  }`}
  }
  .Mui-disabled {
    font-weight: 400;
    font-size: 14px;
    line-height: 1.14;
    color: ${({ theme }) => theme.colors.DARK_GREY_2} !important;

  }
  .MuiTypography-root {
    font-weight: 400;
    font-size: 14px;
    line-height: 1.14;
    width: 32px;
    color: ${({ theme }) => theme.colors.DATE_MODAL_WEEK};
    margin 4px;
  }

  .MuiTab-root {
    background-color: unset !important;
  }
  .Mui-selected {
   .MuiSvgIcon-root {
    color: ${({ theme }) => theme.colors.DARK_GREEN_2} !important;
   }
  }
  
`;

export const DateButton = styled.div`
  border-radius: 4px;
  width: 100%;
  padding: 10px;

  font-weight: 400;
  font-size: 14px;
  line-height: 1.14;
  color: ${({ theme }) => theme.colors.WHITE};
  position: relative;
  cursor: pointer;
  background: ${({ theme }) => theme.colors.DARK_GREY_3};
`;

export const Heading = styled.span`
  flex: 1;
  font-weight: bold;
  font-size: 24px;
  line-height: 1.2;
  color: ${({ theme }) => theme.colors.WHITE};
`;

export const FieldSpace = styled.div`
  width: 24px;
`;

export const ArrowDateButton = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
`;

export const rewardNameStyle = {
  width: 240,
  fontWeight: 500
};

export const rewardTitleStyle = {
  width: 400,
  fontWeight: 500
};

export const rewardAmountStyle = {
  width: 136,
  fontWeight: 500,
  textAlign: 'right'
};

export const rewardAmountWrapperStyle = {
  minWidth: 136
};
