import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { withAuthenticator } from '@aws-amplify/ui-react';

import { Sidebar, TopNavigation } from 'navigation';
import { NotificationAlerts } from './containers';
import { sidebarMenuItems } from 'common/enums/navigation';
import routes from 'constants/routes';
import { ColumnFlex, Content, NavigationPlaceholder, RowFlex } from './styled';

import AgentDashboard from '../../pages/AgentDashboard';
import AgentPage from '../../pages/AgentPage';
// import Analytics from '../../pages/Analytics';
import AnalyticsTask from '../../pages/AnalyticsTask';
import { CampaignPage } from '../../pages/CampaignPage/CampaignPage';
import Communication from '../../pages/Communication';
import CustomerDashboard from '../../pages/CustomerDashboard';
import CustomerPage from '../../pages/CustomerPage';
import CustomerTabs from '../../pages/CustomerTabs/CustomerTabs';
import Payments from '../../pages/Payments/Payments';
import InvoicePendingTabs from '../../pages/InvoicePendingTabs/InvoicePendingTabs';
// import PromoCodes from '../../pages/PromoCodes';
import ProviderPage from '../../pages/ProviderPage';
import ProviderTabs from '../../pages/ProviderTabs/ProviderTabs';

import ServiceDashboard from '../../pages/ServiceDashboard';
import CampaignsDashboard from '../../pages/CampaignsDashboard';
import CampaignForm from '../../pages/CampaignForm/CampaignForm';
import PriorityTasks from '../../pages/PriorityTasks';
import NotFound from '../../pages/NotFound';

const ServicePage = lazy(() => import('../../pages/ServicePage')); // 103 kb

export const MainNavigation = () => (
  <RowFlex>
    <NavigationPlaceholder />

    <ColumnFlex>
      <TopNavigation />
      <Content>
        <Suspense fallback={<div />}>
          <Routes>
            <Route path="/" element={<PriorityTasks />} />
            <Route path={routes.SERVICE} element={<ServiceDashboard />} />
            <Route path={`${routes.SERVICE}/:job_id`} element={<ServicePage />} />
            <Route path={routes.PAYMENTS} element={<Payments />} />
            <Route path={routes.INVOICE_PENDING} element={<InvoicePendingTabs />} />
            <Route path={`${routes.CUSTOMER}/:customer_id${routes.SERVICE}/:job_id`} element={<ServicePage />} />
            <Route path={`${routes.PROVIDER}/:provider_id${routes.SERVICE}/:job_id`} element={<ServicePage />} />
            <Route path={routes.CUSTOMER} element={<CustomerTabs />} />
            <Route path={`${routes.CUSTOMER}/:customer_id`} element={<CustomerPage />} />
            <Route path={`${routes.SERVICE}/:job_id${routes.CUSTOMER}/:customer_id`} element={<CustomerPage />} />
            <Route path={routes.PROVIDER} element={<ProviderTabs />} />
            <Route path={`${routes.PROVIDER}/:provider_id`} element={<ProviderPage />} />
            <Route path={`${routes.SERVICE}/:job_id${routes.PROVIDER}/:provider_id`} element={<ProviderPage />} />
            <Route path={routes.AGENT} element={<AgentDashboard />} />
            <Route path={`${routes.AGENT}/:agent_id`} element={<AgentPage />} />
            {/* // Analytics tab disabled */}
            {/* <Route path={routes.ANALYTICS} element={<Analytics />} /> */}
            <Route path={routes.ANALYTICS_JOBS} element={<AnalyticsTask />} />
            {/* <Route path={routes.PROMO_CODES} element={<PromoCodes />} /> */}
            <Route path={routes.PROMO_CODES_CUSTOMERS} element={<CustomerDashboard isNested />} />
            <Route path={routes.COMMUNICATION} element={<Communication />} />
            <Route path={routes.CAMPAIGNS} element={<CampaignsDashboard />} />
            <Route path={`${routes.CAMPAIGNS}/:campaign_id`} element={<CampaignPage />} />
            <Route path={`${routes.CAMPAIGNS}/:campaign_id/edit`} element={<CampaignForm />} />
            <Route path={routes.CAMPAIGN_CREATE} element={<CampaignForm />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
      </Content>
    </ColumnFlex>

    <Sidebar items={sidebarMenuItems} />

    <NotificationAlerts />
  </RowFlex>
);

export default withAuthenticator(MainNavigation, { hideSignUp: true });
