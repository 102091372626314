import React from 'react';

import { useRouter } from 'hooks';
import { analyticMetrics } from 'common/enums/analytics';
import { getMetricNameByType, getPeriodTimeByType } from './helpers/analytics';
import { Breadcrumb } from '../../components';

const MetricBreadcrumb = () => {
  const { queryParams } = useRouter();
  const filters = queryParams.getAll();

  const currentMetric = Object.values(analyticMetrics).find(({ TYPE }) => TYPE === Number(filters.metric_type));

  const {
    metric_type,
    period_type,
    [currentMetric?.FILTERS?.START_DATE_TYPE]: startDate,
    [currentMetric?.FILTERS?.END_DATE_TYPE]: endDate
  } = filters;

  const metricName = getMetricNameByType(Number(metric_type));
  const periodTime = getPeriodTimeByType({ startDate, endDate, periodType: Number(period_type) });

  return <Breadcrumb isActive breadcrumbName={`${metricName} - ${periodTime}`} />;
};

export default MetricBreadcrumb;
