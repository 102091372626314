import Api from 'services/ApiService';
import api_constants from 'constants/api';

const uploadMedia = async ({ file, media_type, mime_type = null, sub_folder = null, hasMediaType }) => {
  const getMediaUrl = Api.amplifyPost(api_constants.MEDIA, {
    original_name: file.name,
    media_type: mime_type,
    sub_folder
  });

  const uploadMedia = getMediaUrl.then((mediaUploadUrl) => {
    if (!mediaUploadUrl) {
      return Promise.reject(null);
    }

    return Api.s3Put(mediaUploadUrl.upload_url, file, mime_type ? { 'Content-Type': mime_type } : null);
  });

  const [mediaUploadUrl, uploadMediaResponse] = await Promise.all([getMediaUrl, uploadMedia]);

  if (!(uploadMediaResponse || mediaUploadUrl.id)) {
    return Promise.reject(null);
  }

  return hasMediaType ? { media_id: mediaUploadUrl.id, media_type } : mediaUploadUrl.id;
};

export default uploadMedia;
