import { call, takeLatest, put } from 'redux-saga/effects';
import api_constants from 'constants/api';
import actionTypes from 'constants/actionTypes';
import Api from 'services/ApiService';

function* getServiceLocation(action) {
  const { serviceId: id } = action.payload;
  try {
    const response = yield call(
      Api.amplifyGet,
      `${api_constants.SERVICE_REQUEST}/${id}/location`
    );
    const { err } = response;
    if (response && !err) {
      yield put({
        type: actionTypes.GET_SERVICE_LOCATION_SUCCESS,
        payload: response
      });
    } else {
      yield put({
        type: actionTypes.GET_SERVICE_LOCATION_ERROR
      });
    }
  } catch (err) {
    console.log(err);
  }
}

export default function* watchGetServiceLocation() {
  yield takeLatest(actionTypes.GET_SERVICE_LOCATION_FETCH, getServiceLocation);
}
