import { userGroups } from 'common/enums/user';
import actionTypes from 'constants/actionTypes';
import { oneClickActions } from 'common/enums/user';

const defaultServiceList = {
  loading: false,
  updateCustomerLocationLoading: false,
  list: [],
  page: 0,
  count: null
};

const defaultState = {
  closedServiceList: defaultServiceList,
  instance: {
    user: {}
  },
  referrals: defaultServiceList,
  location: {},
  instanceLoading: false,
  list: [],
  hasListError: false,
  rewards: [],
  reviews: [],
  listLoading: false,
  reviewsLoading: false,
  openServiceList: defaultServiceList,
  recentlyCompletedServiceList: defaultServiceList,
  cancelledServiceList: defaultServiceList,
  page: 1,
  totalCount: 0,
  reviewsCount: 0,
  reviewsPage: 0,
  uploadPropertyReportLoading: false,
  updateInstanceLoading: false,
  dobbyCashLoading: false,
  dobbyCash: {},
  dobbyCashTransactions: [],
  dobbyCashTransactionsLoading: false,
  manageRewardLoading: false,
  tagsLoading: {}
};

function reducer(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.GET_CUSTOMER_LIST_FETCH: {
      return {
        ...state,
        hasListError: false,
        listLoading: true
      };
    }
    case actionTypes.GET_CUSTOMER_LIST_SUCCESS: {
      const { page, totalCount, users = [] } = action.payload || {};
      return {
        ...state,
        list: users,
        hasListError: false,
        listLoading: false,
        page,
        totalCount
      };
    }

    case actionTypes.GET_CUSTOMER_LIST_ERROR: {
      return {
        ...state,
        list: [],
        listLoading: false,
        hasListError: true,
        page: 1,
        totalCount: 0
      };
    }

    case actionTypes.UPLOAD_CUSTOMER_INSPECTION_REPORT_FETCH: {
      if (state.instance.id === Number(action.payload.user_id)) {
        return {
          ...state,
          uploadPropertyReportLoading: true
        };
      }
      return state;
    }

    case actionTypes.UPLOAD_CUSTOMER_INSPECTION_REPORT_SUCCESS: {
      if (state.instance.id === Number(action.payload)) {
        return {
          ...state,
          uploadPropertyReportLoading: false,
          instance: {
            ...state.instance,
            is_report_uploaded: true
          }
        };
      }
      return state;
    }

    case actionTypes.UPLOAD_CUSTOMER_INSPECTION_REPORT_ERROR: {
      if (state.instance.id === Number(action.payload)) {
        return {
          ...state,
          uploadPropertyReportLoading: false
        };
      }
      return state;
    }

    case actionTypes.GET_CUSTOMER_INSTANCE_FETCH: {
      return {
        ...state,
        openServiceList: action.payload !== state.instance?.id ? defaultServiceList : state.openServiceList,
        closedServiceList: action.payload !== state.instance?.id ? defaultServiceList : state.closedServiceList,
        uploadPropertyReportLoading: false,
        instanceLoading: true
      };
    }

    case actionTypes.GET_CUSTOMER_INSTANCE_SUCCESS: {
      return {
        ...state,
        instance: action.payload,
        instanceLoading: false
      };
    }

    case actionTypes.GET_CUSTOMER_INSTANCE_ERROR: {
      return {
        ...state,
        instanceLoading: false
      };
    }

    case actionTypes.GET_CUSTOMER_REVIEWS_FETCH: {
      return {
        ...state,
        reviewsLoading: true
      };
    }

    case actionTypes.GET_CUSTOMER_REVIEWS_SUCCESS: {
      return {
        ...state,
        reviews: action.payload.reset ? action.payload.reviews : [...state.reviews, ...action.payload.reviews],
        reviewsCount: action.payload.count,
        reviewsLoading: false,
        reviewsPage: action.payload.page
      };
    }

    case actionTypes.GET_CUSTOMER_REVIEWS_ERROR: {
      return {
        ...state,
        reviewsLoading: false
      };
    }

    case actionTypes.GET_CUSTOMER_LOCATION_SUCCESS: {
      return {
        ...state,
        location: action.payload
      };
    }

    // potentially add loading state for location fetch
    case actionTypes.GET_CUSTOMER_LOCATION_FETCH:
    case actionTypes.GET_CUSTOMER_LOCATION_ERROR: {
      return {
        ...state,
        location: {}
      };
    }

    case actionTypes.GET_USER_SERVICE_LIST_FETCH: {
      const { is_open, fieldName } = action.payload;
      const listName = fieldName || (is_open ? 'openServiceList' : 'closedServiceList');
      return {
        ...state,
        [listName]: {
          ...state[listName],
          list: state[listName].list,
          loading: true
        }
      };
    }

    case actionTypes.GET_USER_SERVICE_LIST_SUCCESS: {
      const { is_open, list, page, count, customer_id, fieldName } = action.payload;
      const listName = fieldName || (is_open ? 'openServiceList' : 'closedServiceList');
      if (customer_id === state.instance.id) {
        return {
          ...state,
          [listName]: {
            list: page > 1 ? [...state[listName].list, ...list] : list,
            page,
            count,
            loading: false
          }
        };
      }
      return state;
    }

    case actionTypes.GET_USER_SERVICE_LIST_ERROR: {
      const { is_open, customer_id, fieldName } = action.payload;
      const listName = fieldName || (is_open ? 'openServiceList' : 'closedServiceList');
      if (customer_id === state.instance.id) {
        return {
          ...state,
          [listName]: {
            ...state[listName],
            loading: false
          }
        };
      }
      return state;
    }

    case actionTypes.UPDATE_USER_INSTANCE_SUCCESS: {
      const { userGroupId, user, id } = action.payload;
      if (userGroupId === userGroups.CUSTOMER.TYPE && id === state.instance?.user?.id) {
        return {
          ...state,
          instance: {
            ...state.instance,
            user: {
              ...state.instance.user,
              ...user
            }
          }
        };
      }
      return state;
    }

    case actionTypes.DELETE_COGNITO_USER_ERROR:
    case actionTypes.DELETE_COGNITO_USER_SUCCESS: {
      const { userGroupId, id } = action.payload;
      if (userGroupId === userGroups.CUSTOMER.TYPE && id === state.instance?.user?.id) {
        return {
          ...state,
          instance: {
            ...state.instance,
            user: {
              ...state.instance.user,
              ...action.payload
            }
          }
        };
      }
      return state;
    }

    case actionTypes.GET_REWARDS_SUCCESS: {
      const { rewards } = action.payload;
      return {
        ...state,
        rewards
      };
    }

    case actionTypes.GET_REWARDS_ERROR: {
      return {
        ...state,
        rewards: []
      };
    }

    case actionTypes.UPDATE_CUSTOMER_INSTANCE_FETCH: {
      const { updateAction, data } = action.payload;
      const isCustomerTagChanged = [
        oneClickActions.IS_TROUBLED,
        oneClickActions.REMOVE_IS_TROUBLED,
        oneClickActions.IS_NON_ICP,
        oneClickActions.REMOVE_IS_NON_ICP
      ].includes(updateAction);
      return {
        ...state,
        updateInstanceLoading: true,
        ...(isCustomerTagChanged ? { tagsLoading: { ...state.tagsLoading, [data?.stage]: true } } : {})
      };
    }

    case actionTypes.UPDATE_CUSTOMER_INSTANCE_SUCCESS: {
      const { user, location, customer, skipNestedUpdate, updateAction } = action.payload;
      const isCustomerTagChanged = [
        oneClickActions.IS_TROUBLED,
        oneClickActions.REMOVE_IS_TROUBLED,
        oneClickActions.IS_NON_ICP,
        oneClickActions.REMOVE_IS_NON_ICP
      ].includes(updateAction);
      if (skipNestedUpdate) {
        return {
          ...state,
          updateInstanceLoading: false,
          instance: {
            ...state.instance,
            ...customer
          },
          ...(isCustomerTagChanged ? { tagsLoading: { ...state.tagsLoading, [customer?.stage]: false } } : {})
        };
      }
      return {
        ...state,
        instance: {
          ...state.instance,
          ...customer,
          user
        },
        location: {
          ...state.location,
          ...location
        },
        ...(isCustomerTagChanged ? { tagsLoading: { ...state.tagsLoading, [customer?.stage]: false } } : {}),
        updateInstanceLoading: false
      };
    }

    case actionTypes.UPDATE_CUSTOMER_INSTANCE_ERROR: {
      const { updateAction, data } = action.payload;
      const isCustomerTagChanged = [
        oneClickActions.IS_TROUBLED,
        oneClickActions.REMOVE_IS_TROUBLED,
        oneClickActions.IS_NON_ICP,
        oneClickActions.REMOVE_IS_NON_ICP
      ].includes(updateAction);
      return {
        ...state,
        updateInstanceLoading: false,
        ...(isCustomerTagChanged ? { tagsLoading: { ...state.tagsLoading, [data?.stage]: false } } : {})
      };
    }

    case actionTypes.UPDATE_CUSTOMER_LOCATION_FETCH: {
      return {
        ...state,
        updateCustomerLocationLoading: true
      };
    }

    case actionTypes.UPDATE_CUSTOMER_LOCATION_SUCCESS:
    case actionTypes.UPDATE_CUSTOMER_LOCATION_ERROR: {
      return {
        ...state,
        updateCustomerLocationLoading: false
      };
    }

    case actionTypes.CREATE_REWARD_FETCH: {
      return {
        ...state,
        rewards: [...state.rewards, action.payload]
      };
    }
    case actionTypes.DELETE_REWARD_ERROR: {
      return {
        ...state,
        deleteCognitoUserLoading: false
      };
    }
    case actionTypes.DELETE_REWARD_SUCCESS: {
      const { id } = action.payload;

      return {
        ...state,
        rewards: state.rewards.filter((item) => item.id !== id),
        deleteRewardLoading: false
      };
    }
    case actionTypes.DELETE_REWARD_FETCH: {
      return {
        ...state,
        deleteRewardLoading: true
      };
    }
    case actionTypes.CREATE_REWARD_SUCCESS: {
      const { tmpId, data } = action.payload;

      return {
        ...state,
        rewards: state.rewards.map((item) => (item.id === tmpId ? data : item))
      };
    }

    case actionTypes.CREATE_REWARD_ERROR: {
      const { tmpId } = action.payload;
      return {
        ...state,
        rewards: state.rewards.filter((item) => item.id !== tmpId)
      };
    }

    case actionTypes.GET_CUSTOMER_REFERRALS_FETCH: {
      const { page } = action.payload;

      return {
        ...state,
        referrals: {
          ...state.referrals,
          list: page === 1 ? [] : state.referrals.list,
          loading: true
        }
      };
    }

    case actionTypes.GET_CUSTOMER_REFERRALS_SUCCESS: {
      const { list, page, count } = action.payload;

      return {
        ...state,
        referrals: {
          list: page > 1 ? [...state.referrals.list, ...list] : list,
          page,
          count,
          loading: false
        }
      };
    }

    case actionTypes.GET_CUSTOMER_REFERRALS_ERROR: {
      return {
        ...state,
        referrals: {
          ...state.referrals,
          loading: false
        }
      };
    }

    case actionTypes.MANAGE_CUSTOMER_REWARD_FETCH: {
      return {
        ...state,
        manageRewardLoading: true
      };
    }
    case actionTypes.MANAGE_CUSTOMER_REWARD_SUCCESS:
    case actionTypes.MANAGE_CUSTOMER_REWARD_ERROR: {
      return {
        ...state,
        manageRewardLoading: false
      };
    }

    case actionTypes.GET_CUSTOMER_DOBBY_CASH_FETCH: {
      return {
        ...state,
        dobbyCashLoading: true
      };
    }
    case actionTypes.GET_CUSTOMER_DOBBY_CASH_SUCCESS: {
      return {
        ...state,
        dobbyCash: action.payload,
        dobbyCashLoading: false
      };
    }

    case actionTypes.GET_CUSTOMER_DOBBY_CASH_ERROR: {
      return {
        ...state,
        dobbyCashLoading: false
      };
    }

    case actionTypes.GET_CUSTOMER_DOBBY_CASH_TRANSACTIONS_FETCH: {
      return {
        ...state,
        dobbyCashTransactionsLoading: true
      };
    }

    case actionTypes.GET_CUSTOMER_DOBBY_CASH_TRANSACTIONS_SUCCESS: {
      return {
        ...state,
        dobbyCashTransactions: action.payload,
        dobbyCashTransactionsLoading: false
      };
    }

    case actionTypes.GET_CUSTOMER_DOBBY_CASH_TRANSACTIONS_ERROR: {
      return {
        ...state,
        dobbyCashTransactionsLoading: false
      };
    }
    default:
      return state;
  }
}

// selectors
export const selectCustomerList = (state) => state.customer.list;
export const selectCustomerListError = (state) => state.customer.hasListError;
export const selectCustomerListLoading = (state) => state.customer.listLoading;
export const selectCustomerListPage = (state) => state.customer.page;
export const selectCustomersTotalCount = (state) => state.customer.totalCount;
export const selectCustomerInstanceLoading = (state) => state.customer.instanceLoading;
export const selectCustomerInstance = (state) => state.customer.instance;
export const selectCustomerInstanceId = (state) => state.customer.instance?.id;
export const selectCustomerLocation = (state) => state.customer.location;
export const selectUploadPropertyReportLoading = (state) => state.customer.uploadPropertyReportLoading;
export const selectRewards = (state) => state.customer.rewards;
export const selectDeleteRewardLoading = (state) => state.customer.deleteRewardLoading;
export const selectUpdateCustomerLocation = (state) => state.customer.updateCustomerLocationLoading;
export const selectUpdateCustomerInstanceLoading = (state) => state.customer.updateInstanceLoading;
export const selectCustomerReferrals = (state) => state.customer.referrals;
export const selectManageRewardLoading = (state) => state.customer.manageRewardLoading;
export const selectCustomerDobbyCash = (state) => state.customer.dobbyCash;
export const selectCustomerDobbyCashLoading = (state) => state.customer.dobbyCashLoading;
export const selectDobbyCashTransactions = (state) => state.customer.dobbyCashTransactions;
export const selectDobbyCashTransactionsLoading = (state) => state.customer.dobbyCashTransactionsLoading;
export const selectCustomerTagsLoading = (state) => state.customer.tagsLoading;

// customer's jobs
export const selectCustomerActiveServiceList = (state) => state.customer.openServiceList;
export const selectCustomerRecentlyCompletedServiceList = (state) => state.customer.recentlyCompletedServiceList;
export const selectCustomerCompletedServiceList = (state) => state.customer.closedServiceList;
export const selectCustomerCancelledServiceList = (state) => state.customer.cancelledServiceList;
export const selectCustomerActiveJobs = (state) => state.customer.openServiceList.list;
export const selectCustomerActiveJobsPage = (state) => state.customer.openServiceList.page;
export const selectCustomerActiveJobsCount = (state) => state.customer.openServiceList.count;
export const selectCustomerCompletedJobs = (state) => state.customer.closedServiceList.list;
export const selectCustomerCompletedJobsPage = (state) => state.customer.closedServiceList.page;
export const selectCustomerCompletedJobsCount = (state) => state.customer.closedServiceList.count;
export const selectCustomerCancelledJobs = (state) => state.customer.cancelledServiceList.list;
export const selectCustomerCancelledJobsPage = (state) => state.customer.cancelledServiceList.page;
export const selectCustomerCancelledJobsCount = (state) => state.customer.cancelledServiceList.count;
// customer's reviews
export const selectCustomerReviews = (state) => state.customer.reviews;
export const selectCustomerReviewsLoading = (state) => state.customer.reviewsLoading;
export const selectCustomerReviewsCount = (state) => state.customer.reviewsCount;
export const selectCustomerReviewsPage = (state) => state.customer.reviewsPage;

export default reducer;
