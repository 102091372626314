import React from 'react';
import { createSearchParams as createQueryParams } from 'react-router-dom';

import { getDefaultNavigationParams } from 'common/helpers/queryParams';
import { useSelector } from 'hooks';
import { selectActiveLocationSearch, selectActiveMenuItem } from 'store/reducers/navigation';

import { Breadcrumb } from '../../components';

const MenuItemBreadcrumb = ({ location, match }) => {
  // if we have nested list clickable breadcrumb - use this logic for it
  // const pathnamePartsLength = location.pathname?.split('/')?.filter(Boolean)?.length;
  // const nestedIndex = pathnamePartsLength ? pathnamePartsLength - 1 : 0;

  const activeMenuItem = useSelector(selectActiveMenuItem);
  const activeLocationSearch = useSelector(selectActiveLocationSearch);
  const defaultNavigationParams = getDefaultNavigationParams({ menuItem: activeMenuItem });
  const locationSearch = activeLocationSearch?.[0] || createQueryParams(defaultNavigationParams).toString() || '';

  return (
    <Breadcrumb
      isActive={location.pathname === match.pathname}
      pathname={activeMenuItem.ROUTE}
      search={locationSearch}
      breadcrumbName={activeMenuItem.NAME}
    />
  );
};

export default MenuItemBreadcrumb;
