import { call, takeLatest, put } from 'redux-saga/effects';
import api_constants from 'constants/api';
import actionTypes from 'constants/actionTypes';
import Api from 'services/ApiService';
import { mapSkillsToOptions } from 'common/helpers/user';
import errors from 'constants/errors';

function* getSkills({ payload }) {
  const { search, callback, page = 1 } = payload;
  try {
    const response = yield call(
      Api.amplifyGet,
      `${api_constants.SKILL}?page=${page}${search ? `&search=${search}` : ''}&order_by=-id`
    );

    if (!response?.results) {
      throw new Error(errors.DEFAULT);
    }

    yield put({
      type: actionTypes.GET_SKILLS_SUCCESS,
      payload: response
    });

    if (callback) {
      callback(mapSkillsToOptions({ skills: response.results }));
    }
  } catch (err) {
    yield put({
      type: actionTypes.GET_SKILLS_ERROR
    });
  }
}

export default function* watchGetSkills() {
  yield takeLatest(actionTypes.GET_SKILLS_FETCH, getSkills);
}
