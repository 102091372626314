import React from 'react';
import { Storage } from 'aws-amplify';
import { videoExtensions } from 'common/enums/media';
import { convertUrlToS3Config } from 'utils';

import { Notes, NoteTitle, NoteBody, NoteFile } from './styled';

export const InvoiceNotes = ({
  isOfflinePaid,
  paymentNote,
  noteMedia,
  notesMedia,
  setPdfViewer,
  setImagePreview,
  setVideoPlayer
}) => {
  if (!Boolean(isOfflinePaid)) {
    return null;
  }

  const handleOpenFile = async ({ media_id, file_format, original_name, path }) => {
    const url = notesMedia[media_id][0];
    if (file_format === 'pdf') {
      setPdfViewer({ url, name: original_name });
    } else if (Object.values(videoExtensions).includes(`.${file_format}`)) {
      const config = convertUrlToS3Config(path);
      const signedUrl = await Storage.get(config.Key);
      setVideoPlayer(signedUrl);
    } else {
      setImagePreview(url);
    }
  };

  return (
    <Notes>
      <NoteTitle>Notes</NoteTitle>

      {Boolean(paymentNote) && <NoteBody>{paymentNote}</NoteBody>}
      {Boolean(noteMedia.length) && (
        <div>
          {noteMedia.map(({ media_id, media: { original_name, file_format, path } }) => {
            return (
              <div key={media_id}>
                <NoteFile onClick={() => handleOpenFile({ media_id, file_format, original_name, path })}>
                  {original_name}
                </NoteFile>
              </div>
            );
          })}
        </div>
      )}
      {!paymentNote && !noteMedia.length && <NoteBody>-</NoteBody>}
    </Notes>
  );
};
