import styled from 'styled-components';

export const FieldValue = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.INVOICE_TEXT};
  display: inline-flex;
  align-items: center;
  height: 24px;
  a {
    text-decoration: underline;
    margin-left: 5px;
    color: ${({ theme }) => theme.colors.INVOICE_TEXT};
    &:hover {
      color: ${({ theme }) => theme.colors.DARK_GREEN};
    }
  }
`;

export const Circle = styled.div`
  border-radius: 50%;
  width: 3px;
  height: 3px;
  background: ${({ theme }) => theme.colors.WHITE};
  margin: 0 10px 0 6px;
`;

export const Copied = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.DARK_GREEN};
  margin-left: 5px;
`;
