import React from 'react';
import { useDispatch, useSelector } from 'hooks';
import moment from 'moment-timezone';

import dates from 'constants/dates';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import StaticDatePicker from '@mui/lab/StaticDatePicker';
import DateAdapter from '@mui/lab/AdapterMoment';
import { closeModal, openModal } from 'store/actions';
import modalTypes from 'constants/modalTypes';

import { RelativeWrapper } from './styled';
import { Modal, IconButton } from 'components';

import { selectModalData } from 'store/reducers/modal';

const PaymentDate = () => {
  const modalData = useSelector(selectModalData);

  const dispatch = useDispatch();
  const hideModal = () => {
    dispatch(
      openModal(modalTypes.CLOSE_INVOICE, {
        ...modalData,
        closeInvoiceData: { ...modalData.data, datetime_paid: null }
      })
    );
  };

  const paidDate = modalData?.data.datetime_paid;
  const handleChange = (date) => {
    dispatch(
      openModal(modalTypes.CLOSE_INVOICE, {
        ...modalData,
        closeInvoiceData: { ...modalData.data, datetime_paid: moment(date).format(dates.DATE_SHORT) }
      })
    );
  };
  const value = moment(paidDate, dates.DATE_SHORT).format(dates.DATETIME_ISO);

  return (
    <Modal modalType={modalTypes.PAYMENT_DATE} hideModal={hideModal} padding="0" maxHeight={608} minWidth={1}>
      <RelativeWrapper>
        <LocalizationProvider dateAdapter={DateAdapter}>
          <StaticDatePicker
            value={value}
            onChange={handleChange}
            renderInput={() => null}
            showDaysOutsideCurrentMonth={false}
            disableCloseOnSelect
            reduceAnimations
            disableFuture
            displayStaticWrapperAs="desktop"
            views={['day']}
            components={{
              RightArrowButton: ({ onClick }) => <IconButton onClick={onClick} iconName="arrowFillRight" />,
              LeftArrowButton: ({ onClick }) => <IconButton onClick={onClick} iconName="arrowFillLeft" />
            }}
          />
        </LocalizationProvider>
      </RelativeWrapper>
    </Modal>
  );
};
export default PaymentDate;
