import { columnNames } from 'common/enums/sortings';

export const providerTable = {
  AVATAR: {
    TITLE: '',
    FILTER_NAME: columnNames.AVATAR
  },
  ID: {
    TITLE: 'ID',
    FILTER_NAME: columnNames.ID
  },
  GIVEN_NAME: {
    TITLE: 'First Name',
    FILTER_NAME: columnNames.GIVEN_NAME
  },
  FAMILY_NAME: {
    TITLE: 'Last Name',
    FILTER_NAME: columnNames.FAMILY_NAME
  },
  PHONE_NUMBER: {
    TITLE: 'Phone number',
    FILTER_NAME: columnNames.PHONE_NUMBER
  },
  LOCATION: {
    TITLE: 'Location',
    FILTER_NAME: columnNames.AREA_LIST
  },
  PRIMARY_CATEGORY: {
    TITLE: 'Primary category',
    FILTER_NAME: columnNames.PRIMARY_CATEGORY
  },
  SECONDARY_CATEGORY: {
    TITLE: 'Secondary category',
    FILTER_NAME: columnNames.SECONDARY_CATEGORY
  },
  ONE_CLICK_AREAS: {
    TITLE: '1-click area',
    FILTER_NAME: columnNames.ONE_CLICK_AREAS
  },
  ONE_CLICK_CATEGORY: {
    TITLE: '1-click category',
    FILTER_NAME: columnNames.ONE_CLICK_CATEGORY
  },
  AUTO_ASSIGN_AREAS: {
    TITLE: 'Auto assignment area',
    FILTER_NAME: columnNames.AUTO_ASSIGN_AREAS
  },
  AUTO_ASSIGN_CATEGORY: {
    TITLE: 'Auto assignment category',
    FILTER_NAME: columnNames.AUTO_ASSIGN_CATEGORY
  },
  AMOUNT_TOTAL_EARNED: {
    TITLE: 'Total money earned',
    FILTER_NAME: columnNames.AMOUNT_TOTAL_EARNED
  },
  AVERAGE_JOB_VALUE: {
    TITLE: 'Average job value',
    FILTER_NAME: columnNames.AVERAGE_JOB_VALUE
  },
  COUNT_TASK_ASSIGNMENTS_SENT: {
    TITLE: 'Jobs sent',
    FILTER_NAME: columnNames.COUNT_TASK_ASSIGNMENTS_SENT
  },
  COUNT_TASK_ACCEPTED: {
    TITLE: 'Accepted',
    FILTER_NAME: columnNames.COUNT_TASK_ACCEPTED
  },
  COUNT_TASK_ONGOING: {
    TITLE: 'Ongoing',
    FILTER_NAME: columnNames.COUNT_TASK_ONGOING
  },
  COUNT_TASK_SCHEDULED: {
    TITLE: 'Scheduled',
    FILTER_NAME: columnNames.COUNT_TASK_SCHEDULED
  },
  COUNT_TASK_COMPLETED: {
    TITLE: 'Completed',
    FILTER_NAME: columnNames.COUNT_TASK_COMPLETED
  },
  COUNT_TASK_UNFULFILLED: {
    TITLE: 'Unfulfilled',
    FILTER_NAME: columnNames.COUNT_TASK_UNFULFILLED
  },
  DAYS_SINCE_SIGNUP: {
    TITLE: 'Days Since sign-up',
    FILTER_NAME: columnNames.DAYS_SINCE_SIGNUP
  },
  REG_DATE: {
    TITLE: 'Reg. date',
    FILTER_NAME: columnNames.DATETIME_CREATED
  },
  DATETIME_LAST_ACTIVITY: {
    TITLE: 'Last activity date',
    FILTER_NAME: columnNames.DATETIME_LAST_ACTIVITY
  },
  PLATFORM_VERSION: {
    TITLE: 'Platform version',
    FILTER_NAME: columnNames.PLATFORM_VERSION
  },
  APP_VERSION: {
    TITLE: 'App version',
    FILTER_NAME: columnNames.APP_VERSION
  },
  NOTIFICATIONS_PERMISSION: {
    TITLE: 'Notifications',
    FILTER_NAME: columnNames.NOTIFICATIONS_PERMISSION
  }
};
