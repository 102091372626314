import styled from 'styled-components';

export const Footer = styled.div`
  background: #262e30;
  height: 88px;
  display: flex;

  align-items: center;
  justify-content: ${({ justifyContent = 'flex-end' }) => justifyContent};

  padding: 0 24px;
  position: fixed;
  width: calc(100% - 195px);
  bottom: 0;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 20px;
`;
