import React, { useEffect } from 'react';

import { useState } from 'hooks';
import { IconButton } from 'components';
import { useHoverWithCallback } from 'hooks';
import { Copied, FieldValue, Circle } from './styled';

const LinkToApp = ({ link, name }) => {
  const [hoverRef, isHovered] = useHoverWithCallback();

  const [isCopied, setIsCopied] = useState(false);
  useEffect(() => setIsCopied(false), [isHovered]);
  return (
    <FieldValue ref={hoverRef}>
      <Circle />
      {name}:
      <a href={link} target="_blank">
        {link}
      </a>
      {isHovered && (
        <>
          <IconButton
            square
            bigText
            margin="0 0 2px 8px"
            height={24}
            width={56}
            onClick={() => {
              navigator.clipboard.writeText(link);
              setIsCopied(true);
            }}
            text="Copy"
          />
          {isCopied && <Copied>Copied</Copied>}
        </>
      )}
    </FieldValue>
  );
};

export default LinkToApp;
