import { call, takeLatest, put } from 'redux-saga/effects';
import api_constants from 'constants/api';
import actionTypes from 'constants/actionTypes';
import { openModal } from 'store/actions/modal';
import modalTypes from 'constants/modalTypes';
import Api from 'services/ApiService';

function* createSkill({ payload: name }) {
  try {
    const response = yield call(Api.amplifyPost, api_constants.SKILL, { name });
    yield put({
      type: actionTypes.CREATE_SKILL_SUCCESS,
      payload: response
    });
    yield put(
      openModal(modalTypes.RESULT, {
        title: `The “${response.name || ''}” skill has been created`
      })
    );
  } catch (err) {
    yield put({
      type: actionTypes.CREATE_SKILL_ERROR
    });
  }
}

export default function* watchCreateSkill() {
  yield takeLatest(actionTypes.CREATE_SKILL_FETCH, createSkill);
}
