import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { reducer as formReducer } from 'redux-form';

import admin from './admin';
import agent from './agent';
import analytics from './analytics';
import app from './app';
import communication from './communication';
import customer from './customer';
import invoice from './invoice';
import loading from './loading';
import modal from './modal';
import navigation from './navigation';
import provider from './provider';
import service from './service';
import supportChat from './supportChat';
import taskChat from './taskChat';
import review from './review';
import runtimeCache from './runtimeCache';
import notification from './notification';
import promoCode from './promoCode';
import user from './user';
import campaigns from './campaigns';

const appPersistConfig = {
  key: 'app',
  storage: storage
};

const rootReducer = combineReducers({
  app: persistReducer(appPersistConfig, app),
  form: formReducer,
  runtimeCache,
  communication,
  campaigns,
  customer,
  invoice,
  loading,
  modal,
  navigation,
  notification,
  provider,
  admin,
  agent,
  analytics,
  service,
  promoCode,
  review,
  supportChat,
  taskChat,
  user
});

export default rootReducer;
