import styled from 'styled-components';
import styles from 'constants/styles';

export const AttachedRewardRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const FieldName = styled.label`
  color: ${({ theme }) => theme.colors.DARK_GREY};
  font-weight: 500;
  font-size: 12px;
  line-height: 1.17;
`;

export const FieldWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 27px;

  ${FieldName} {
    margin-bottom: 3px;
  }
`;

export const Heading = styled.h2`
  font-weight: 700;
  font-size: 14px;
  line-height: 1.14;

  color: ${({ theme }) => theme.colors.INVOICE_TEXT};
`;

export const RewardCurency = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 1.17;
  color: ${({ theme }) => theme.colors.INVOICE_TEXT};
  display: flex;
  align-self: center;
  margin-bottom: 5px;
  white-space: nowrap;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Error = styled.label`
  font-weight: 600;
  font-size: 12px;
  line-height: 1.17;
  margin-bottom: 5px;
  color: ${styles.colors.RED};
  margin-top: 5px;
`;

export const discountInputWrapperStyle = {
  marginRight: 8,
  marginTop: 4,
  marginBottom: 4,
  flex: 1
};

export const ownInputWrapperStyle = {
  marginTop: 4,
  marginBottom: 4
};
