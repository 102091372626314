import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { closeModal, deletePayment, deleteQuotation } from 'store/actions';
import { selectModalData } from 'store/reducers/modal';
import { selectDeleteInvoiceLoading } from 'store/reducers/invoice';

import modalTypes from 'constants/modalTypes';
import { formatters } from 'utils';
import { ModalConfirmation } from 'components';

const DeleteInvoiceConfirmation = ({
  loading = false,
  modalData: { invoice = {} } = {},
  closeModal,
  deletePayment,
  deleteQuotation
}) => {
  const formattedPrice = formatters.dollarCurrencyDB(invoice.amount_total_charge || 0);

  const closeDeleteModal = () => {
    closeModal(modalTypes.DELETE_INVOICE_CONFIRMATION);
  };

  const onConfirm = () => {
    if (invoice.is_quotation) {
      deleteQuotation({
        invoice,
        formattedPrice
      });
      return;
    }
    deletePayment({
      invoice,
      formattedPrice
    });
  };

  return (
    <ModalConfirmation
      modalType={modalTypes.DELETE_INVOICE_CONFIRMATION}
      hideModal={closeDeleteModal}
      title={`Remove ${invoice.is_quotation ? 'quotation' : 'charge'} to HO\nfor the ${formattedPrice}?`}
      text={`The HO won’t see this ${invoice.is_quotation ? 'quotation' : 'bill'} anymore.`}
      primaryButton={{
        title: 'Cancel',
        disabled: loading,
        onClick: closeDeleteModal
      }}
      secondaryButton={{
        title: 'Confirm',
        onClick: onConfirm,
        loading: loading,
        disabled: loading
      }}
    />
  );
};

DeleteInvoiceConfirmation.propTypes = {
  deletePayment: PropTypes.func.isRequired,
  deleteQuotation: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  modalData: selectModalData(state),
  loading: selectDeleteInvoiceLoading(state)
});

const mapDispatchToProps = {
  deletePayment,
  deleteQuotation,
  closeModal
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteInvoiceConfirmation);
