// React/Redux
import { call, takeLatest, put } from 'redux-saga/effects';
// Store
import { openModal, closeModal } from 'store/actions/modal';
import { getServiceProgressList } from 'store/actions/service';
// Services
import Api from 'services/ApiService';
// Constants
import api_constants from 'constants/api';
import actionTypes from 'constants/actionTypes';
import modalTypes from 'constants/modalTypes';
import errors from 'constants/errors';

function* snoozeTask({ payload }) {
  const { task_id, date_end_hold, snoozeDateFormattedString } = payload;

  try {
    const response = yield call(Api.amplifyPut, `${api_constants.SERVICE}/${task_id}`, { date_end_hold });

    if (!response || response?.err) {
      throw new Error(errors.DEFAULT);
    }

    yield put(closeModal(modalTypes.SNOOZE_TASK));

    yield put({
      type: actionTypes.SNOOZE_TASK_SUCCESS,
      payload: response
    });

    yield put(
      getServiceProgressList({
        serviceId: task_id,
        silent: true
      })
    );

    if (date_end_hold) {
      // case task was put on-hold
      yield put(
        openModal(modalTypes.RESULT, {
          title: `The job ID ${task_id} moved to On-Hold status.`,
          text: `The job will come back to the current list on ${snoozeDateFormattedString}.`,
          modalProps: {
            minWidth: 500,
            maxWidth: 540
          }
        })
      );
    } else {
      // case task was resumed
      yield put(
        openModal(modalTypes.RESULT, {
          title: `The job ID ${task_id}\nmoved from the hold-on list successfully.`,
          modalProps: {
            minWidth: 500,
            maxWidth: 520
          }
        })
      );
    }
  } catch (err) {
    console.log(err);
    yield put(
      openModal(modalTypes.ERROR, {
        errorMessage: errors.DEFAULT
      })
    );
    yield put({
      type: actionTypes.SNOOZE_TASK_ERROR
    });
  }
}

export default function* watchSnoozeTask() {
  yield takeLatest(actionTypes.SNOOZE_TASK_FETCH, snoozeTask);
}
