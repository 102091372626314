import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import moment from 'moment-timezone';

import { useSelector, useDispatch, useRouter } from 'hooks';

import { getOwnerReport, openModal, getServiceList } from 'store/actions';
import { getFullName } from 'common/helpers/user';
import { ownerReportFields } from 'common/enums/reports';
import { operationalAreaIDs } from 'common/enums/location';
import modalTypes from 'constants/modalTypes';

import { selectAdminList, selectAdminListLoading } from 'store/reducers/admin';
import { isTestTasksVisible } from 'store/reducers/app';
import { selectOwnerReportLoading } from 'store/reducers/service';
import { HookForm, RightSidebar, Button, DropdownMultiSelect } from 'components';

import { FieldsWrapper, StyledForm, FieldRow, FieldLabel, FormFooter } from './styled';

const OwnerReportForm = ({ onToggleForm }) => {
  const dispatch = useDispatch();
  const ownerReportLoading = useSelector(selectOwnerReportLoading);

  const admins = useSelector(selectAdminList);
  const adminsLoading = useSelector(selectAdminListLoading);
  const isTest = useSelector(isTestTasksVisible);

  const [selectedOwners, setSelectedOwners] = useState([]);
  const [selectedGeography, setSelectedGeography] = useState([]);
  const { queryParams } = useRouter();

  const {
    control,
    reset,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors }
  } = useForm({
    defaultValues: {},
    mode: 'onChange'
  });

  const handleFormReset = () => {
    reset({}, { keepIsValid: false });
    setSelectedOwners([]);
    setSelectedGeography([]);
  };

  const openReportModal = (isSuccess) => {
    dispatch(openModal(modalTypes.OWNER_REPORT, isSuccess));
  };

  const handleFormSubmit = (data) => {
    dispatch(
      getOwnerReport({
        ...data,
        is_test: isTest,
        as_of_date: data.as_of_date
          ? moment(data.as_of_date.format('YYYY-MM-DD')).tz('America/Los_Angeles').utc() // Report by EST timezone
          : null,
        owner: selectedOwners.map(({ id }) => id),
        geography: selectedGeography.map(({ id }) => id),
        onRequestProcessed: openReportModal
      })
    );
    dispatch(
      getServiceList({
        owner_id: selectedOwners.map(({ id }) => id).join(','),
        operational_area_id: selectedGeography.map(({ id }) => id).join(','),
        is_test: isTest,
        order_by: 'id',
        skip_spectora: data[ownerReportFields.EXCLUDE_INSPECTIONS.TYPE],
        original_date_created_gte: data[ownerReportFields.DATETIME_CREATED_FROM.TYPE],
        original_date_created_lte: data[ownerReportFields.DATETIME_CREATED_TO.TYPE],
        storeFilter: true
      })
    );
    queryParams.setAll({
      page: 1,
      sorting_column: 'id',
      sorting_direction: 'descending',
      menu_item: 'all',
      is_stored_filter: true
    });
  };

  const taskOwnersList = admins?.length ? [...admins.map(({ user }) => user)] : [];
  const geoLocationsList = Object.values(operationalAreaIDs).map(({ NAME, TYPE }) => ({ id: TYPE, name: NAME }));
  const transformOptionText = (option) => `${option?.name}`;

  const handleFormSubmitError = (errors, e) => console.log(errors, e);

  // MultiSelect component text transforming functions
  const transformSelectedValuesText = (option) => `${option?.given_name}`;
  const transformMenuOptionsText = ({ given_name, family_name }) => getFullName({ given_name, family_name });

  return (
    <RightSidebar visible onSidebarClose={onToggleForm} title="Job Metrics">
      <StyledForm onSubmit={handleSubmit(handleFormSubmit, handleFormSubmitError)} onReset={handleFormReset}>
        <FieldsWrapper>
          <FieldLabel>{ownerReportFields.GEOGRAPHY.NAME}</FieldLabel>
          <FieldRow>
            <DropdownMultiSelect
              options={geoLocationsList}
              selectedOptions={selectedGeography}
              onApplySelection={setSelectedGeography}
              isDisabled={!geoLocationsList.length}
              selectPlaceholderText={'Select Location'}
              selectAllOptionText={'All Locations'}
              selectedValuesBeforeTruncate={4}
              transformSelectedValuesText={transformOptionText}
              transformMenuOptionsText={transformOptionText}
              hasImages={false}
              hasCloseButton
              applyOnItemSelection={false}
              selectedValuesWidth={170}
              dropdownOptionsWidth={250}
              dropdownOptionsHeight={50}
            />
          </FieldRow>

          <FieldLabel>{ownerReportFields.OWNER.NAME}</FieldLabel>
          <FieldRow>
            <DropdownMultiSelect
              options={taskOwnersList}
              selectedOptions={selectedOwners}
              extraOptions={[]}
              onApplySelection={setSelectedOwners}
              isDisabled={adminsLoading}
              isLoading={adminsLoading}
              selectPlaceholderText={'Select Owner'}
              selectAllOptionText={'All Jobs'}
              selectedItemsStringTruncateLimit={20}
              transformSelectedValuesText={transformSelectedValuesText}
              transformMenuOptionsText={transformMenuOptionsText}
              hasCloseButton
              applyOnItemSelection={false}
              selectedValuesWidth={220}
              dropdownOptionsWidth={260}
              dropdownOptionsHeight={50}
            />
          </FieldRow>

          <FieldLabel>{ownerReportFields.DATETIME_CREATED_FROM.NAME}</FieldLabel>
          <FieldRow marginBottom={24}>
            <HookForm.DateRangePicker
              startDateName={ownerReportFields.DATETIME_CREATED_FROM.TYPE}
              endDateName={ownerReportFields.DATETIME_CREATED_TO.TYPE}
              getValues={getValues}
              setValue={setValue}
            />
          </FieldRow>

          <FieldLabel>{ownerReportFields.DATETIME_AS_OF.NAME}</FieldLabel>
          <FieldRow marginBottom={24}>
            <HookForm.DatePickerMui
              control={control}
              name="as_of_date"
              disablePast={false}
              disableFuture={true}
              rules={{ required: false }}
              placeholder="Report as of Date"
              error={errors?.expiration_datetime}
            />
          </FieldRow>

          <FieldLabel>{ownerReportFields.EXCLUDE_INSPECTIONS.NAME}</FieldLabel>
          <FieldRow>
            <HookForm.Checkbox name={ownerReportFields.EXCLUDE_INSPECTIONS.TYPE} control={control} />
          </FieldRow>

          <FieldLabel>{ownerReportFields.EXCLUDE_DUPLICATES.NAME}</FieldLabel>
          <FieldRow>
            <HookForm.Checkbox name={ownerReportFields.EXCLUDE_DUPLICATES.TYPE} control={control} />
          </FieldRow>
        </FieldsWrapper>
        <FormFooter>
          <Button type="submit" title="Get report" disabled={ownerReportLoading} />
          <Button type="reset" title="Clear all criteria" />
        </FormFooter>
      </StyledForm>
    </RightSidebar>
  );
};

export default OwnerReportForm;
