import { columnNames } from 'common/enums/sortings';

export const taskTable = {
  ID: {
    TITLE: 'ID',
    COLUMN_NAME: columnNames.ID
  },
  HO_GIVEN_NAME: {
    TITLE: 'HO Name',
    COLUMN_NAME: columnNames.HO_GIVEN_NAME
  },
  HO_ID: {
    TITLE: 'HO ID',
    COLUMN_NAME: columnNames.HO_ID
  },
  PRO_GIVEN_NAME: {
    TITLE: 'PRO Name',
    COLUMN_NAME: columnNames.PRO_GIVEN_NAME
  },
  PRO_ID: {
    TITLE: 'PRO ID',
    COLUMN_NAME: columnNames.PRO_ID
  },
  STAGE: {
    TITLE: 'Stage',
    COLUMN_NAME: columnNames.STAGE
  },
  DATETIME_ACCEPTED: {
    TITLE: 'Accepted Date',
    COLUMN_NAME: columnNames.DATETIME_ACCEPTED
  },
  DATETIME_CREATED: {
    TITLE: 'Request Date',
    COLUMN_NAME: columnNames.DATETIME_CREATED
  },
  DATETIME_COMPLETED: {
    TITLE: 'Completed Date',
    COLUMN_NAME: columnNames.DATETIME_COMPLETED
  },
  DATETIME_CANCELLED: {
    TITLE: 'Canceled Date',
    COLUMN_NAME: columnNames.DATETIME_CANCELLED
  },
  DATETIME_VISIT_CURRENT: {
    TITLE: 'Scheduled Date',
    COLUMN_NAME: columnNames.DATETIME_VISIT_CURRENT
  },
  DATETIME_VISIT: {
    TITLE: 'Scheduled Date',
    COLUMN_NAME: columnNames.DATETIME_VISIT
  },
  DATETIME_QUOTED: {
    TITLE: 'Quoted Date',
    COLUMN_NAME: columnNames.DATETIME_QUOTED
  },
  DATETIME_HOLD: {
    TITLE: 'On-Hold Till',
    COLUMN_NAME: columnNames.DATETIME_HOLD
  },
  JOB_CATEGORY_NAME: {
    TITLE: 'Request Cat',
    COLUMN_NAME: columnNames.JOB_CATEGORY_NAME
  },
  IS_QUOTED: {
    TITLE: 'Quoted',
    COLUMN_NAME: columnNames.IS_QUOTED
  },
  AMOUNT_ESTIMATED: {
    TITLE: 'Est. Amount',
    COLUMN_NAME: columnNames.AMOUNT_ESTIMATED
  },
  DATETIME_CREATED_INVOICE_PENDING: {
    TITLE: 'Invoice Date',
    COLUMN_NAME: columnNames.DATETIME_CREATED_INVOICE_PENDING
  },
  INVOICE_TYPE: {
    TITLE: 'Invoice Type',
    COLUMN_NAME: columnNames.INVOICE_TYPE
  },
  AMOUNT_INVOICED: {
    TITLE: 'Invoice Amount',
    COLUMN_NAME: columnNames.AMOUNT_INVOICED
  },
  TOTAL_PAID: {
    TITLE: 'Total Amount',
    COLUMN_NAME: columnNames.TOTAL_PAID
  },
  WARNING: {
    TITLE: 'Early Warning',
    COLUMN_NAME: columnNames.WARNING
  },
  LAST_NOTE: {
    TITLE: 'Last Note',
    COLUMN_NAME: columnNames.LAST_NOTE
  },
  LAST_MESSAGE: {
    TITLE: 'Last Message',
    COLUMN_NAME: columnNames.LAST_MESSAGE
  },
  UNFULFILLED_REASON: {
    TITLE: 'Reason Code',
    COLUMN_NAME: columnNames.UNFULFILLED_REASON
  }
};
