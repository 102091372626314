import React, { useMemo } from 'react';
import { connect, useSelector } from 'react-redux';
import moment from 'moment-timezone';

import { openModal } from 'store/actions';
import { jobTags, adminStatuses } from 'common/enums/job';

import modalTypes from 'constants/modalTypes';
import { modalProps } from 'common/enums/modal';

import { DropdownMenu } from 'components';
import { DataRecordAdditionalActions } from './styled';

import { updateJobField } from 'store/actions';
import { selectTagsLoading } from 'store/reducers/service';
import constants from './constants';

const AdditionalActions = ({
  openModal,
  job,
  updateJobField,
  job: { id: task_id, datetime_visit, date_end_hold, is_hold_status, status, admin_status } = {}
}) => {
  const showEditTaskModal = () => {
    openModal(modalTypes.TASK, { isEditing: true, taskInstance: job });
  };

  const showCancelVisitModal = () => {
    openModal(modalTypes.CANCEL_VISIT, { task_id });
  };

  const showCancelReasonModal = () => {
    openModal(modalTypes.CANCEL_TASK_REASON, {
      task_id,
      modalProps: modalProps.CANCEL_TASK_REASON
    });
  };

  const showDuplicateConfirmationModal = () => {
    openModal(modalTypes.DUPLICATE_TASK, { task_id });
  };

  const showReopenConfirmationModal = () => {
    openModal(modalTypes.REOPEN_TASK, { task_id });
  };

  const showUnbundleConfirmationModal = () => {
    openModal(modalTypes.UNBUNDLE_TASK, { task_id });
  };

  const showSnoozeTaskModal = () => {
    openModal(modalTypes.SNOOZE_TASK, { task_id, date_end_hold });
  };

  const handleOpenCreateTaskModal = () => {
    openModal(modalTypes.TASK, {
      isPrefilled: true,
      taskInstance: {
        job_category_id: job.job_category_id,
        job_type: job.job_type,
        user_id: job.user_id,
        user: job.user,
        operational_area_id: job.operational_area_id
      }
    });
  };

  const handleCloseSilently = () => {
    const isJobWithPayout = job?.payment?.some(({ amount_provider_payout }) => amount_provider_payout === null);
    openModal(isJobWithPayout ? modalTypes.CLOSE_TASK_SILENTLY_WITH_PAYOUT : modalTypes.CLOSE_TASK_SILENTLY, {
      task_id
    });
  };

  const isTagExist = (tag) => job?.tag_list?.some(({ id }) => tag.TYPE === id);

  const addTag = (tag) => {
    updateJobField({
      tag: tag.TYPE,
      job_id: job.id,
      body: {
        tag_list: [...job?.tag_list, { id: tag.TYPE }],
        resultModal: { modalType: modalTypes.RESULT, title: `The job was added to the “${tag.NAME}” list` }
      }
    });
  };
  const removeTag = (tag) => {
    updateJobField({
      job_id: job.id,
      tag: tag.TYPE,
      body: {
        tag_list: job?.tag_list.filter(({ id }) => id !== tag.TYPE),
        resultModal: { modalType: modalTypes.RESULT, title: `The job was removed from the “${tag.NAME}” list` }
      }
    });
  };

  const handleToggleJobChatConfirmation = () => {
    openModal(modalTypes.TOGGLE_JOB_CHAT, {
      is_service_chat_closed: job?.is_service_chat_closed,
      job_id: job.id
    });
  };

  const saraToddTag = useMemo(() => job?.tag_list?.find(({ id }) => id === jobTags.TROUBLED.TYPE), [job]);

  const isClosedRecently = (job) => {
    const datetimeFinished = job.datetime_paid || job.datetime_completed || job.datetime_canceled;
    const currentStamp = moment();
    const finishedStamp = moment(datetimeFinished);
    const duration = moment.duration(currentStamp.diff(finishedStamp));
    const durationDays = duration.asDays();

    return constants.COMPLETED_STATUSES.includes(job.admin_status) && durationDays < constants.RECENT_15_DAYS;
  };

  const tagsLoading = useSelector(selectTagsLoading);

  const options = [
    ...(isClosedRecently(job)
      ? [
          {
            id: 12,
            name: 'Reopen Job',
            onClick: showReopenConfirmationModal
          }
        ]
      : []),
    {
      id: 2,
      name: 'Duplicate Job',
      onClick: showDuplicateConfirmationModal
    },
    {
      id: 6,
      name: 'Create Job',
      onClick: handleOpenCreateTaskModal
    },
    ...(!isTagExist(jobTags.TROUBLED)
      ? [
          {
            id: 11,
            name: 'Add to Troubled',
            onClick: () => addTag(jobTags.TROUBLED),
            isLoading: tagsLoading[jobTags.TROUBLED.TYPE]
          }
        ]
      : []),
    ...(isTagExist(jobTags.TROUBLED) && saraToddTag?.is_allowed_delete
      ? [
          {
            id: 11,
            name: 'Remove from Troubled',
            onClick: () => removeTag(jobTags.TROUBLED),
            isLoading: tagsLoading[jobTags.TROUBLED.TYPE],
            selected: true
          }
        ]
      : []),
    ...(isTagExist(jobTags.LOVE_LETTER)
      ? [
          {
            id: 10,
            name: 'Remove from Love Letter',
            onClick: () => removeTag(jobTags.LOVE_LETTER),
            isLoading: tagsLoading[jobTags.LOVE_LETTER.TYPE],
            selected: true
          }
        ]
      : [
          {
            id: 10,
            name: 'Add to Love Letter',
            onClick: () => addTag(jobTags.LOVE_LETTER),
            isLoading: tagsLoading[jobTags.LOVE_LETTER.TYPE]
          }
        ]),
    ...(isTagExist(jobTags.LARGE)
      ? [
          {
            id: 9,
            name: 'Remove from Large Jobs',
            onClick: () => removeTag(jobTags.LARGE),
            isLoading: tagsLoading[jobTags.LARGE.TYPE],
            selected: true
          }
        ]
      : [
          {
            id: 9,
            name: 'Add to Large Jobs',
            onClick: () => addTag(jobTags.LARGE),
            isLoading: tagsLoading[jobTags.LARGE.TYPE]
          }
        ]),
    ...(job?.is_open && !date_end_hold && !is_hold_status
      ? [
          {
            id: 7,
            name: 'Add to On-Hold',
            onClick: showSnoozeTaskModal
          }
        ]
      : []),
    ...(job?.is_open && date_end_hold && is_hold_status
      ? [
          {
            id: 7,
            name: 'Remove from On-Hold',
            onClick: showSnoozeTaskModal,
            selected: true
          }
        ]
      : []),
    ...(job?.is_open
      ? [
          {
            id: 3,
            name: 'Edit',
            onClick: showEditTaskModal
          },
          {
            id: 1,
            name: 'Cancel Job',
            onClick: showCancelReasonModal
          }
        ]
      : []),
    ...(job?.is_open && datetime_visit
      ? [
          {
            id: 4,
            name: 'Cancel Visit',
            onClick: showCancelVisitModal
          }
        ]
      : []),
    ...(job?.is_open
      ? [
          {
            id: 8,
            name: 'Archive Job',
            onClick: handleCloseSilently
          }
        ]
      : []),
    ...(adminStatuses.BUNDLED.TYPE === admin_status
      ? [
          {
            id: 5,
            name: 'Unbundle Job',
            onClick: showUnbundleConfirmationModal
          }
        ]
      : []),
    ...(job?.is_service_chat_closed && [adminStatuses.COMPLETED.TYPE, adminStatuses.PAID.TYPE].includes(admin_status)
      ? [
          {
            id: 13,
            name: 'Open the chat',
            onClick: handleToggleJobChatConfirmation
          }
        ]
      : []),
    ...(!job?.is_service_chat_closed && [adminStatuses.COMPLETED.TYPE, adminStatuses.PAID.TYPE].includes(admin_status)
      ? [
          {
            id: 13,
            name: 'Close the chat',
            onClick: handleToggleJobChatConfirmation
          }
        ]
      : [])
  ];

  if (!options.length) {
    return null;
  }

  return (
    <DataRecordAdditionalActions alignItems="flex-end">
      <DropdownMenu dropdownWidth={200} options={options} placeholder="Actions" margin="0" />
    </DataRecordAdditionalActions>
  );
};

const mapDispatchToProps = {
  openModal,
  updateJobField
};

export default connect(null, mapDispatchToProps)(AdditionalActions);
