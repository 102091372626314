import styled from 'styled-components';

export const BadgeText = styled.div`
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
`;

export const Pill = styled.div`
  padding: 4px 16px;
  border-radius: 20px;
  font-size: 12px;
  margin: 0 8px 3px 0;

  background: ${({ theme }) => theme.colors.DARK_2};
  color: ${({ theme, color }) => color || theme.colors.WHITE};
`;
