import { useCallback, useMemo, useReloadTable, useRouter, useState } from 'hooks';

import { addressStringFilters } from 'common/enums/filters';
import routes from 'constants/routes';
import { defaultParams, defaultQueryParams } from 'common/enums/queryParams';
import { sidebarMenuItems } from 'common/enums/navigation';
import { prepareJobFiltersFormValues, prepareHiddenFiltersFormValues } from 'common/helpers/job';
import { findNavigationTab } from 'common/helpers/navigation';
import { groupFilters } from 'common/helpers/queryParams';
import { useActiveMenuItem, useReplaceHistory } from 'navigation/hooks';

/***
 * reloads table informatiion on switch test tasks checkbox
 */
const useCustomFilters = () => {
  const [hasCustomFiltersForm, setHasTaskFiltersForm] = useState(false);

  const { queryParams, pathname } = useRouter();
  const filters = queryParams.getAll();

  const { handleReloadTable } = useReloadTable();
  const { activeMenuItem } = useActiveMenuItem();
  const { handleReplaceHistory } = useReplaceHistory();

  const { customFilters, coreNavigationFilters } = useMemo(() => groupFilters(filters), [filters]);

  const hasJobFilters = useMemo(
    () => routes.SERVICE === pathname && Boolean(Object.values(customFilters)?.length),
    [pathname, customFilters]
  );
  const hasCustomFiltersButton = useMemo(() => routes.SERVICE === pathname, [pathname]);

  const hasNavigationChange = useCallback(() => {
    const hasMenuItemChange = pathname === routes.SERVICE && filters.menu_item !== sidebarMenuItems.ALL_JOBS.TYPE;
    const newSortingFilters = hasMenuItemChange ? defaultQueryParams : {};

    return {
      hasMenuItemChange,
      newSortingFilters
    };
  }, [filters.menu_item, pathname]);

  const getNavigationMenuItem = useCallback(
    ({ hasMenuItemChange }) => {
      const menuItem = hasMenuItemChange ? sidebarMenuItems.ALL_JOBS : activeMenuItem;
      const tabItem = findNavigationTab({ menuItemType: menuItem.TYPE, tabId: filters.tab_id });

      return {
        menuItem,
        tabItem
      };
    },
    [activeMenuItem, filters.tab_id]
  );

  const handleToggleCustomFiltersForm = () => setHasTaskFiltersForm((value) => !value);

  const handleRemoveFilter = (firstFilter, secondFilter, thirdFilter) => () => {
    const { coreNavigationFilters, commonFilters, customFilters } = groupFilters(filters);
    const {
      [firstFilter]: firstRemovedFilter,
      [secondFilter]: secondRemovedFilter,
      [thirdFilter]: thirdRemovedFilter,
      ...otherCustomFilters
    } = customFilters;

    const navigationFilters = {
      ...coreNavigationFilters,
      ...commonFilters,
      ...otherCustomFilters
    };

    const locationSearch = handleReplaceHistory({ filters: navigationFilters });

    handleReloadTable({
      newNavigationFilters: coreNavigationFilters,
      newRequestFilters: {
        ...commonFilters,
        ...otherCustomFilters
      },
      resetCustomFilters: true,
      locationSearch
    });
  };

  const handleRemoveAllFilters = () => {
    const { coreNavigationFilters, commonFilters } = groupFilters(filters);

    const locationSearch = handleReplaceHistory({
      filters: {
        ...coreNavigationFilters,
        ...commonFilters
      }
    });

    handleReloadTable({
      newNavigationFilters: coreNavigationFilters,
      newRequestFilters: commonFilters,
      resetCustomFilters: true,
      locationSearch
    });
  };

  const handleSubmitCustomFiltersForm = (values) => {
    const { hasMenuItemChange, newSortingFilters } = hasNavigationChange();
    const { menuItem, tabItem } = getNavigationMenuItem({ hasMenuItemChange });

    handleToggleCustomFiltersForm();

    const navigationFilters = {
      ...newSortingFilters,
      menu_item: menuItem.TYPE,
      ...(tabItem?.TYPE ? { tab_id: tabItem.TYPE } : {}),
      ...(filters[addressStringFilters.PROVIDER_FULL_NAME.TYPE]
        ? { [addressStringFilters.PROVIDER_FULL_NAME.TYPE]: filters[addressStringFilters.PROVIDER_FULL_NAME.TYPE] }
        : {})
    };

    const commonFilters = {
      ...(filters.search ? { search: filters.search } : {}),
      [defaultParams.PAGE.NAME]: defaultParams.PAGE.DEFAULT_VALUE,
      ...prepareJobFiltersFormValues(values),
      ...prepareHiddenFiltersFormValues(filters)
    };

    const locationSearch = handleReplaceHistory({
      filters: {
        ...commonFilters,
        ...navigationFilters
      }
    });

    if (!hasMenuItemChange) {
      handleReloadTable({
        newNavigationFilters: navigationFilters,
        newRequestFilters: commonFilters,
        resetCustomFilters: true,
        locationSearch
      });
    }
  };

  return {
    customFilters,
    coreNavigationFilters,
    hasCustomFilters: hasJobFilters,
    hasCustomFiltersForm,
    hasCustomFiltersButton,
    handleRemoveFilter,
    handleRemoveAllFilters,
    handleSubmitCustomFiltersForm,
    handleToggleCustomFiltersForm
  };
};

export default useCustomFilters;
