import styled, { css } from 'styled-components';
import styles from 'constants/styles';
import 'react-image-lightbox/style.css';

export const IconWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const FlexColumn = `
  display: flex;
  flex-direction: column;
`;

const FlexRow = `
  display: flex;
  flex-direction: row;
`;

export const NoteListWrapper = styled.div`
  ${FlexColumn};

  overflow-y: overlay;
  max-height: 221px;
`;

export const AddNoteWrapper = styled.div`
  padding: 0 24px;
`;

export const FieldTitle = styled.span`
  font-weight: 500;
  font-size: 12px;
  line-height: 1.17;
  background: ${({ theme }) => theme.colors.DARK_3};
  color: ${({ theme }) => theme.colors.WHITE};
  padding: 9px 0 9px 24px;
  border-radius: 8px 8px 0px 0px;
`;

export const LoadingContainerMedium = styled.div`
  ${FlexRow};
  justify-content: center;
`;

export const NoteContainer = styled.div`
  ${FlexRow};
  margin: 16px;
`;

export const NoteData = styled.div`
  ${FlexColumn};
  margin-left: 8px;
  padding-left: 16px;
  border-left: 1px solid ${({ theme }) => theme.colors.DARK_4};
`;

export const NoteTitleRow = styled.div`
  ${FlexRow};
  align-items: flex-end;
`;

export const NoteTitle = styled.p`
  margin-bottom: 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.33;

  color: ${({ theme }) => theme.colors.INVOICE_TEXT};
`;

export const NoteCreated = styled.p`
  font-weight: 400;
  font-size: 8px;
  line-height: 1.25;

  color: ${({ theme }) => theme.colors.DARK_GREY};

  margin: 0 0 0 9px;
  text-transform: uppercase;
`;

export const NoteDescription = styled(NoteTitle)`
  margin: 8px 0 0;
`;

export const FilesFlexRow = styled.div`
  ${FlexRow};
  flex-wrap: wrap;
`;

export const NoteFile = styled.div`
  ${FlexRow};
  align-items: flex-end;
  margin: 8px 20px 0 0;

  ${({ clickable, theme }) =>
    clickable &&
    `
        &:hover {
            cursor: pointer;
            span {
                color: ${theme.colors.DARK_GREEN};
            }

            div svg path {
                stroke: ${theme.colors.DARK_GREEN};
            }
        }
    `}
`;

export const NoteFileName = styled.span`
  color: ${({ theme }) => theme.colors.INVOICE_TEXT};
  font-size: 12px;
  font-weight: 600;
  line-height: 1.7;
  margin: 0 5px;
`;

export const NoteFormContainer = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 1.7;
  color: ${styles.colors.BLACK};
  max-width: 692px;
  position: relative;
`;

export const NoteFormInputSection = styled.div`
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 9px;
`;

export const NoteInput = styled.textarea`
  padding: 8px 16px;

  background: ${({ theme }) => theme.colors.NOTES_TEXTAREA};
  border-radius: 4px;
  border: none;
  resize: none;
  width: 100%;
  min-width: 400px;
  &:focus {
    outline-width: 0;
  }
`;

export const AttachmentLabel = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-right: 20px;
  color: ${({ theme }) => theme.colors.DARK_GREY};

  font-weight: 500;
  font-size: 12px;
  line-height: 1.17;

  cursor: pointer;
`;

export const NoteFormActions = styled.div`
  ${FlexRow};
  align-items: center;
  justify-content: space-between;
`;

export const ReminderWrapper = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 12px;
  line-height: 1.7;
  color: ${({ theme }) => theme.colors.INVOICE_TEXT};
  ${({ margin }) => margin && `margin: ${margin}`};

  & > div:first-child {
    margin-right: 7px;
  }
`;

export const SubmitNoteButton = styled.button`
  font-family: inherit;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;

  background: ${({ theme }) => theme.colors.RED};
  color: ${({ theme }) => theme.colors.WHITE};
  min-width: 85px;
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 4px;
  border: none;
  padding: 3px 6px;
  &:hover {
    filter: brightness(1.1);
  }
  &:disabled {
    background: ${({ theme }) => theme.colors.DISABLED_RED};
  }
`;
export const TooltipBody = styled.div`
  display: flex;
`;
export const CloseNoteForm = styled.div`
  position: absolute;
  top: -7px;
  right: -9px;
  &:hover {
    cursor: pointer;
    filter: brightness(1.1);
  }
`;

export const tooltipStyle = css`
  padding: 0 0 4px 0;
  width: 356px;
  max-width: 356px;
  height: 221px;
`;
