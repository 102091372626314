import styled from 'styled-components';

export const UploadInput = styled.input`
  position: absolute !important;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden;
`;

export const UploadButton = styled.div`
  min-width: 102px;
  min-height: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: #434b4e;
  border-radius: 4px;
  color: #ffffff;
`;

export const UploadedFileName = styled.div`
  font-weight: 700;
  color: #ffffff;
  margin: 0 8px 0 6px;
`;

export const UploadFile = styled.div`
  display: flex;
  align-items: center;
`;

export const UploadFileInfo = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 1.17;
  color: #ffffff;
  display: flex;
  gap: 3px;
  margin-top: 8px;
`;

export const ErrorUpload = styled.div`
  font-weight: 500;
  font-size: 12px;
  color: #ffc25f;
  margin-left: 10px;
  white-space: nowrap;
`;
