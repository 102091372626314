import React from 'react';

import { useSelector } from 'hooks';
import { UserName } from 'components';
import { selectProviderInstance, selectProviderInstanceLoading } from 'store/reducers/provider';
import { selectServiceInstance, selectServiceInstanceLoading } from 'store/reducers/service';

import { Breadcrumb } from '../../components';

const ProviderBreadcrumb = ({ match, location }) => {
  const { provider_id } = match.params;

  const job = useSelector(selectServiceInstance);
  const jobLoading = useSelector(selectServiceInstanceLoading);
  const provider = useSelector(selectProviderInstance);
  const providerLoading = useSelector(selectProviderInstanceLoading);

  const hasLoading = jobLoading || providerLoading;

  const isNestedPath = Boolean(job?.id && location.pathname.split('/')[4] === job?.id?.toString());
  const providerInstance = isNestedPath ? job?.service_provider?.user : provider;
  const selectedProvider = {
    given_name: hasLoading ? '' : providerInstance?.given_name?.trim(),
    family_name: `${hasLoading ? '' : providerInstance?.family_name?.trim() || ''} (${provider_id})`,
    pro_stage: hasLoading ? '' : (isNestedPath ? job?.service_provider : provider)?.pro_stage
  };

  return (
    <Breadcrumb hasLoading={hasLoading} isActive={match.pathname === location.pathname} pathname={match.pathname}>
      <UserName
        given_name={selectedProvider?.given_name}
        family_name={selectedProvider?.family_name}
        pro_stage={selectedProvider?.pro_stage}
        disabled={match.pathname === location.pathname}
      />
    </Breadcrumb>
  );
};

export default ProviderBreadcrumb;
