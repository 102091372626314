export const getRows = ({ list }) => {
  return list.map(({ source_user, target_user = {}, count_service_active = 0, count_service_completed = 0, id }) => ({
    id,
    source_user_id: source_user.id,
    source_full_name: source_user.full_name,
    source_group_id: source_user.group_id,
    source_pro_stage: source_user.provider?.pro_stage,
    target_user_id: target_user.id,
    target_group_id: target_user.group_id,
    target_full_name: target_user.full_name,
    target_phone_number: target_user.phone_number,
    target_email: target_user.email,
    target_date_created: target_user.date_created,
    target_pro_stage: target_user.provider?.pro_stage,
    count_service_active,
    count_service_completed
  }));
};
