import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment-timezone';

import dates from 'constants/dates';
import { closeModal } from 'store/actions';
import { selectModalData } from 'store/reducers/modal';
import { selectJobInvoicesLoading } from 'store/reducers/invoice';
import { commissionTypes } from 'common/enums/invoice';
import modalTypes from 'constants/modalTypes';
import { getInvoiceTypeParam } from 'common/helpers/invoice';
import { formatters } from 'utils';

import { Button, Line, MediaGallery, Modal, StyledTooltip, Icon } from 'components';

import {
  ContentWrapper,
  MarginSection,
  ModalTitle,
  SectionTitle,
  Label,
  Value,
  Amount,
  Description,
  FlexRow,
  TotalWrapper,
  labelStyle,
  valueStyle,
  lineStyle,
  Flex
} from './styled';

const InvoiceConfirmation = ({
  loading = false,
  closeModal,
  modalData: {
    invoiceAmount = 0,
    invoice = {},
    task_id,
    onConfirm = () => {},
    invoice: {
      amount_tip,
      is_final_payment,
      filesAfter,
      filesBefore,
      percent_stripe_fee,
      amount_stripe_fee,
      percent_commission,
      purpose_note,
      labor,
      material,
      flat_rate,
      amount_platform_fee,
      amount_provider_fee,
      is_moderated,
      is_accepted,
      commission_type
    } = {},
    is_quotation
  } = {}
}) => {
  const onClose = () => {
    closeModal(modalTypes.CREATE_INVOICE_CONFIRMATION);
  };

  const onInvoiceConfirm = () => {
    onConfirm({
      invoice,
      task_id,
      is_quotation
    });
  };

  const invoiceTypeLabel = getInvoiceTypeParam({ is_final_payment, is_quotation });
  const isMarginCommission = commission_type === commissionTypes.MARGIN;
  const commmissionTypeLabel = isMarginCommission ? 'Margin' : 'Subtracted Fee';
  const estimateDuration = parseInt((invoice.estimate_duration / invoice.estimate_duration_unit).toFixed(0), 10);

  const isInvoiceButton = is_moderated && is_quotation && is_accepted === null;
  return (
    <Modal
      modalType={modalTypes.CREATE_INVOICE_CONFIRMATION}
      hideModal={onClose}
      padding="0"
      minWidth={424}
      maxHeight={0}>
      <ModalTitle>{`Confirm ${invoiceTypeLabel}`}</ModalTitle>
      <ContentWrapper>
        <SectionTitle>Invoice Type</SectionTitle>
        <Label>Type</Label>
        <Value>{invoiceTypeLabel}</Value>
        <MarginSection>
          <Label>Commission Type</Label>
          <Value>{`${commmissionTypeLabel} commission`}</Value>
        </MarginSection>
        {is_final_payment && (
          <MediaGallery
            media={filesBefore}
            label="Attached Media BEFORE"
            labelStyle={labelStyle}
            placeholderStyle={valueStyle}
          />
        )}
        <MediaGallery
          media={filesAfter}
          label={`Attached Media${is_final_payment ? ' AFTER' : ''}`}
          labelStyle={labelStyle}
          placeholderStyle={valueStyle}
        />
        <MarginSection>
          <Label>{is_quotation ? 'Expected Results' : 'Job Results'}</Label>
          <Value>
            <span>{purpose_note || '-'}</span>
          </Value>
          <Line lineStyle={lineStyle} />

          <SectionTitle>Job Amount</SectionTitle>

          {/* Labor */}
          <Label>Labor</Label>
          {Boolean(labor?.length) ? (
            labor.map((item, index) => (
              <Fragment key={`labor-${index}`}>
                <Value bold>
                  <Description>{item.description}</Description>
                  <Amount>{formatters.dollarCurrencyView(item.price)}</Amount>
                </Value>
                <Value>{item.details}</Value>
              </Fragment>
            ))
          ) : (
            <Value>
              <span>-</span>
            </Value>
          )}

          {/* Materials */}
          <Label>Materials</Label>
          {Boolean(material?.length) ? (
            material.map((item, index) => (
              <Fragment key={`material-${index}`}>
                <Value bold>
                  <Description>{item.description}</Description>
                  <Amount>{formatters.dollarCurrencyView(item.price)}</Amount>
                </Value>
                <Value>{item.details}</Value>
              </Fragment>
            ))
          ) : (
            <Value>
              <span>-</span>
            </Value>
          )}

          {/* Flat Rate */}
          <Label>Flat Rate</Label>
          {Boolean(flat_rate?.length) ? (
            flat_rate.map((item, index) => (
              <Fragment key={`flat_rate-${index}`}>
                <Value bold>
                  <Description>{item.description}</Description>
                  <Amount>{formatters.dollarCurrencyView(item.price)}</Amount>
                </Value>
                <Value>{item.details}</Value>
              </Fragment>
            ))
          ) : (
            <Value>
              <span>-</span>
            </Value>
          )}
          <Value />
          <Line lineStyle={lineStyle} />
          {is_quotation && (
            <>
              <SectionTitle>Timeline & Deposit</SectionTitle>
              <Label>Expiration Date of the Quote </Label>
              <Value>
                {is_moderated && invoice.datetime_expire
                  ? moment(invoice.datetime_expire).format(dates.DATETIME_FULL_HUMAN)
                  : invoice.expire_duration
                  ? `This quote will expire in ${invoice.expire_duration} days after the quote is sent.`
                  : 'This quote will not expire.'}
              </Value>

              <Label>Approximated time to complete the job</Label>
              <Value>{`${estimateDuration} ${invoice.estimate_duration_unit === 24 ? 'days' : 'hours'}`}</Value>

              <Label>Deposit Payment Request</Label>
              <Value>
                {invoice.percent_deposit ? `${parseInt(parseFloat(invoice.percent_deposit / 100.0).toFixed(2))}%` : '-'}
              </Value>

              <Line lineStyle={lineStyle} />
            </>
          )}

          <Label>Stripe Fee</Label>
          <Value>
            {formatters.percent(percent_stripe_fee || '0')} - {formatters.dollarCurrencyDB(amount_stripe_fee || 0)}
          </Value>
          <Flex>
            <div>
              <Label>Dobby’s Operating Margin</Label>
              <Value>
                {formatters.percent(percent_commission || '0')} -{' '}
                {formatters.dollarCurrencyDB((isMarginCommission ? amount_platform_fee : amount_provider_fee) || 0)}
              </Value>
            </div>
            <StyledTooltip arrow placement="top-end" title="This number won’t be visible separately in the Dobby app">
              <div>
                <Icon name="infoOutlined" />
              </div>
            </StyledTooltip>
          </Flex>

          {Boolean(amount_tip) && (
            <>
              <Label>Tips to Pro</Label>
              <Value>{formatters.dollarCurrencyDB(amount_tip || '0')}</Value>
            </>
          )}
          <TotalWrapper>
            <SectionTitle>Total Price - {formatters.dollarCurrencyDB(invoiceAmount)}</SectionTitle>
          </TotalWrapper>
        </MarginSection>
      </ContentWrapper>
      <MarginSection margin="24px">
        <FlexRow>
          <Button title="Edit" variant="primary" onClick={onClose} style={{ marginRight: '10px' }} />
          <Button
            title={isInvoiceButton ? 'Invoice HO' : 'Save'}
            onClick={onInvoiceConfirm}
            loading={loading}
            disabled={loading}
            style={{ marginLeft: '10px' }}
          />
        </FlexRow>
      </MarginSection>
    </Modal>
  );
};

InvoiceConfirmation.propTypes = {
  closeModal: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  modalData: selectModalData(state),
  loading: selectJobInvoicesLoading(state)
});

const mapDispatchToProps = {
  closeModal
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceConfirmation);
