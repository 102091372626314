import { useWatch } from 'hooks';
import { commissionTypes } from 'common/enums/invoice';

import { makeDisplayValues } from '../../../helpers/transformers';
import { getPayoutSum } from '../helpers/invoices';

export const useInvoiceTotalValues = ({ commissionType, control, invoice, invoiceType }) => {
  const [percent_stripe_fee, percent_commission, amount_tip, flat_rate, labor, material] = useWatch({
    control,
    name: ['percent_stripe_fee', 'percent_commission', 'amount_tip', 'flat_rate', 'labor', 'material'],
    defaultValue: [
      invoice?.percent_stripe_fee,
      invoice?.percent_commission,
      invoice?.amount_tip,
      invoice?.flat_rate,
      invoice?.labor,
      invoice?.material
    ]
  });

  const displayValues = makeDisplayValues({
    isMarginCommission: commissionType === commissionTypes.MARGIN,
    percent_commission,
    flat_rate,
    labor,
    material
  });

  const invoiceValues = {
    invoice_type: invoiceType,
    commission_type: commissionType,
    amount_tip,
    percent_commission,
    ...displayValues
  };

  const payoutSum = getPayoutSum(invoiceValues) * 100;

  return {
    payoutSum,
    percent_stripe_fee,
    invoiceValues
  };
};
