import styled from 'styled-components';

export const SwitchLabel = styled.span`
  font-size: 14px;
  font-weight: 700;
  line-height: 1.14;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.WHITE};
  padding-bottom: 3px;
`;

export const SwitchWrapper = styled.div`
  margin: 0 24px 0 0;
`;
