import { cellTypes } from 'common/enums/table';
import dates from 'constants/dates';
import { avatarTableCellStyle } from 'constants/styles';
import { formatters, getUserIcon } from 'utils';

import { customerTable } from '../enums';
import { sidebarMenuItems } from 'common/enums/navigation';

export const getColumns = ({ menuItem }) => {
  return [
    // {
    //   id: customerTable.AVATAR.FILTER_NAME,
    //   title: customerTable.AVATAR.TITLE,
    //   cellType: cellTypes.AVATAR,
    //   hasSorting: false,
    //   width: 60,
    //   accessor: ({ profile_image_url, user }) => ({
    //     url: profile_image_url,
    //     icon: getUserIcon(user),
    //     style: avatarTableCellStyle
    //   })
    // },
    {
      id: customerTable.ID.FILTER_NAME,
      title: customerTable.ID.TITLE,
      cellType: cellTypes.TEXT,
      width: 80,
      accessor: ({ id }) => ({
        hasHighlight: true,
        text: String(id)
      })
    },
    {
      id: customerTable.GIVEN_NAME.FILTER_NAME,
      title: customerTable.GIVEN_NAME.TITLE,
      cellType: cellTypes.USER_NAME,
      minWidth: 120,
      accessor: ({ given_name, stage }) => ({
        hasHighlight: true,
        given_name: given_name,
        family_name: '',
        stage: stage
      })
    },
    {
      id: customerTable.FAMILY_NAME.FILTER_NAME,
      title: customerTable.FAMILY_NAME.TITLE,
      cellType: cellTypes.TEXT,
      minWidth: 120,
      accessor: ({ family_name }) => ({
        hasHighlight: true,
        text: family_name
      })
    },
    // ...(sidebarMenuItems?.HOMEOWNERS.TYPE === menuItem
    //   ? [
    //       {
    //         id: customerTable.DATETIME_CREATED.FILTER_NAME,
    //         title: customerTable.DATETIME_CREATED.TITLE,
    //         cellType: cellTypes.TEXT,
    //         width: 80,
    //         minWidth: 120,
    //         accessor: ({ date_created }) => ({
    //           text: formatters.dateWithTimezone(date_created, dates.DATE)
    //         })
    //       }
    //     ]
    //   : []),
    {
      id: customerTable.LIFETIME_SPEND.FILTER_NAME,
      title: customerTable.LIFETIME_SPEND.TITLE,
      cellType: cellTypes.TEXT,
      width: 80,
      accessor: ({ amount_total_spent }) => ({
        text: amount_total_spent ? formatters.dollarCurrencyDB(String(amount_total_spent || 0)) : '-'
      })
    },
    {
      id: customerTable.LIFETIME_MARGIN.FILTER_NAME,
      title: customerTable.LIFETIME_MARGIN.TITLE,
      cellType: cellTypes.TEXT,
      minWidth: 120,
      accessor: ({ lifetime_margin, lifetime_margin_percent }) => ({
        text: lifetime_margin
          ? `${formatters.dollarCurrencyDB(String(lifetime_margin || 0))}${
              lifetime_margin_percent ? ` (${lifetime_margin_percent}%)` : ''
            }`
          : '-'
      })
    },
    {
      id: customerTable.COUNT_TASK_COMPLETED.FILTER_NAME,
      title: customerTable.COUNT_TASK_COMPLETED.TITLE,
      width: 80,
      cellType: cellTypes.TEXT,
      accessor: ({ service_completed }) => ({
        text: service_completed ? service_completed : '0'
      })
    },
    {
      id: customerTable.DAYS_SINCE_SIGN_UP.FILTER_NAME,
      title: customerTable.DAYS_SINCE_SIGN_UP.TITLE,
      cellType: cellTypes.TEXT,
      width: 100,
      accessor: ({ days_since_sign_up }) => ({
        text: days_since_sign_up || days_since_sign_up === 0 ? `${days_since_sign_up}` : '-'
      })
    },
    {
      id: customerTable.DAYS_SINCE_LAST_REQUESTED.FILTER_NAME,
      title: customerTable.DAYS_SINCE_LAST_REQUESTED.TITLE,
      cellType: cellTypes.TEXT,
      width: 100,
      accessor: ({ days_since_last_requested }) => ({
        text: days_since_last_requested || days_since_last_requested === 0 ? `${days_since_last_requested}` : '-'
      })
    },
    {
      id: customerTable.DAYS_SINCE_LAST_COMPLETED.FILTER_NAME,
      title: customerTable.DAYS_SINCE_LAST_COMPLETED.TITLE,
      cellType: cellTypes.TEXT,
      width: 100,
      accessor: ({ days_since_last_completed }) => ({
        text: days_since_last_completed || days_since_last_completed === 0 ? `${days_since_last_completed}` : '-'
      })
    },
    {
      id: customerTable.DAYS_SINCE_LAST_OPEN_APP.FILTER_NAME,
      title: customerTable.DAYS_SINCE_LAST_OPEN_APP.TITLE,
      cellType: cellTypes.TEXT,
      width: 120,
      accessor: ({ days_since_last_open_app }) => ({
        text: days_since_last_open_app || days_since_last_open_app === 0 ? `${days_since_last_open_app}` : '-'
      })
    },
    {
      id: customerTable.DAYS_SINCE_LAST_ACTIVE.FILTER_NAME,
      title: customerTable.DAYS_SINCE_LAST_ACTIVE.TITLE,
      cellType: cellTypes.TEXT,
      width: 100,
      accessor: ({ days_since_last_active }) => ({
        text: days_since_last_active || days_since_last_active === 0 ? `${days_since_last_active}` : '-'
      })
    },
    {
      id: customerTable.INVITED_BY.FILTER_NAME,
      title: customerTable.INVITED_BY.TITLE,
      cellType: cellTypes.TEXT,
      accessor: ({ lead_source }) => ({
        hasHighlight: true,
        text: lead_source
      })
    }
  ];
};
