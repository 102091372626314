import { call, takeEvery, put, all } from 'redux-saga/effects';
import api_constants from 'constants/api';
import actionTypes from 'constants/actionTypes';
import Api from 'services/ApiService';

function* createServiceNote(action) {
  const { payload } = action;
  let { attachedFiles, ...note } = payload;

  try {
    if (attachedFiles) {
      const mediaIdList = yield all(
        attachedFiles.map((media) =>
          call(async (media) => {
            const mediaUploadUrl = await Api.amplifyPost(api_constants.MEDIA, {
              original_name: media.name,
              sub_folder: 'note'
            });

            if (mediaUploadUrl) {
              const uploadResponse = await Api.s3Put(mediaUploadUrl.upload_url, media, { 'Content-Type': media.type });
              if (uploadResponse) {
                return mediaUploadUrl.id;
              } else {
                return (media = null);
              }
            }
          }, media)
        )
      );

      note.media = mediaIdList;
    }

    const response = yield call(
      Api.amplifyPost,
      `${api_constants.SERVICE_REQUEST}/${note.service_request_id}/note`,
      note
    );
    if (response) {
      yield put({
        type: actionTypes.CREATE_SERVICE_NOTE_SUCCESS,
        payload: {
          note: response,
          id: note.id
        }
      });
    } else {
      yield put({
        type: actionTypes.CREATE_SERVICE_NOTE_ERROR,
        payload: { id: note.id }
      });
    }
  } catch (err) {
    console.log(err);
  }
}

export default function* watchCreateServiceNote() {
  yield takeEvery(actionTypes.CREATE_SERVICE_NOTE_FETCH, createServiceNote);
}
