import { call, takeLatest, put } from 'redux-saga/effects';
import api_constants from 'constants/api';
import actionTypes from 'constants/actionTypes';
import modalTypes from 'constants/modalTypes';
import Api from 'services/ApiService';
import { openModal } from 'store/actions';
import { refreshTaskInfo } from 'store/actions/service';
import errors from 'constants/errors';
import { formatters } from 'utils';

function* createPayout(action) {
  const {
    payload: { task_id, invoice_id }
  } = action;
  try {
    const response = yield call(
      Api.amplifyPost,
      api_constants.PAYMENT_PAYOUT,
      invoice_id ? { invoice_id } : { service_id: task_id }
    );

    if (!response || response?.error_message || !response?.currency) {
      throw new Error(response?.error_message || errors.DEFAULT);
    }

    yield put({
      type: actionTypes.CREATE_PAYOUT_SUCCESS,
      payload: response
    });
    yield put(
      openModal(modalTypes.RESULT, {
        title: `${
          invoice_id ? `${formatters.dollarCurrencyDB(response?.amount || 0)} ` : ''
        } Pro Payout was successfully sent`,
        modalProps: {
          padding: '40px 55px 20px'
        }
      })
    );
  } catch (err) {
    const errorText = err?.message || err?.message;
    yield put({
      type: actionTypes.CREATE_PAYOUT_ERROR,
      payload: errorText || errors.DEFAULT
    });
    yield put(
      openModal(modalTypes.RESULT, {
        title: `The payout${invoice_id ? ' ' : ' for all or some invoices '}hasn't been sent`,
        text: errorText || errors.STRIPE_PAYOUT
      })
    );
    console.log(err);
  } finally {
    yield put(refreshTaskInfo({ task_id, silent: true, isPaymentInfo: true }));
  }
}

export default function* watchCreatePayout() {
  yield takeLatest(actionTypes.CREATE_PAYOUT_FETCH, createPayout);
}
