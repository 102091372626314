import { call, takeLatest, put } from 'redux-saga/effects';

import { requestSources } from 'common/enums/campaigns';
import api_constants from 'constants/api';
import actionTypes from 'constants/actionTypes';
import { openModal, closeModal } from 'store/actions';
import Api from 'services/ApiService';

import modalTypes from 'constants/modalTypes';
import routes from 'constants/routes';
import errors from 'constants/errors';

function* duplicateService({ payload: id }) {
  try {
    const response = yield call(Api.amplifyPost, `${api_constants.SERVICE}/${id}${api_constants.DUPLICATE_TASK}`, {
      request_source_type: requestSources.GRYFFINDOR,
      request_source_name: 'Duplicate job'
    });

    if (response && response.err) {
      throw new Error(errors.DEFAULT);
    }

    yield put(closeModal(modalTypes.DUPLICATE_TASK));
    yield put({
      type: actionTypes.DUPLICATE_TASK_SUCCESS
    });
    window.open(`${routes.SERVICE}/${response.id}`, '_blank');
  } catch (err) {
    yield put(
      openModal(modalTypes.ERROR, {
        errorMessage: errors.DEFAULT
      })
    );
    yield put({
      type: actionTypes.DUPLICATE_TASK_ERROR
    });
  }
}

export default function* watchDuplicateService() {
  yield takeLatest(actionTypes.DUPLICATE_TASK_FETCH, duplicateService);
}
