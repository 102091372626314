import { call, takeLatest, put, select } from 'redux-saga/effects';

import { userGroups } from 'common/enums/user';
import api_constants from 'constants/api';
import actionTypes from 'constants/actionTypes';
import { saveLocationSearch } from 'store/actions/navigation';
import { isTestTasksVisible, selectFilteredGeoLocationList } from 'store/reducers/app';
import Api from 'services/ApiService';
import { formatSearchTerm } from 'common/helpers/queryParams';

function* getProviderList(action) {
  const {
    payload: {
      page,
      order_by,
      search = '',
      service_job = '',
      skip_category_filtering = false,
      is_parked = null,
      is_core = null,
      is_full_active = null,
      is_api_active = null,
      is_active = null,
      is_cognito_active = null,
      is_pre_filled = null,
      hasSavingQueryParams,
      menuItemType,
      locationSearch,
      pro_stage = null
    }
  } = action;
  const testTasksVisible = yield select(isTestTasksVisible);
  const filteredGeoLocationList = yield select(selectFilteredGeoLocationList);
  const serviceAreasQueryParam = filteredGeoLocationList.length ? filteredGeoLocationList.join(',') : '';

  try {
    const response = yield call(
      Api.amplifyGet,
      `${api_constants.SERVICE_PROVIDER}?page=${page}&group_id=${userGroups.PROVIDER.TYPE}${
        order_by ? `&order_by=${order_by}` : ''
      }${search ? `&search=${formatSearchTerm(search)}` : ''}${service_job ? `&service_job=${service_job}` : ''}${
        skip_category_filtering ? `&skip_category_filtering=${skip_category_filtering}` : ''
      }${serviceAreasQueryParam ? `&service_area=${serviceAreasQueryParam}` : ''}${
        is_full_active !== null ? `&is_full_active=${Number(is_full_active)}` : ''
      }${is_active !== null ? `&is_active=${Number(is_active)}` : ''}${
        is_api_active !== null ? `&is_api_active=${Number(is_api_active)}` : ''
      }${is_cognito_active !== null ? `&is_cognito_active=${Number(is_cognito_active)}` : ''}${
        is_pre_filled !== null ? `&is_pre_filled=${Number(is_pre_filled)}` : ''
      }${pro_stage !== null ? `&pro_stage=${pro_stage}` : ''}${testTasksVisible ? '' : '&is_test=0'}${
        is_core !== null ? `&is_core=${is_core}` : ''
      }${is_parked !== null ? `&is_parked=${is_parked}` : ''}`
    );

    if (!response?.results) {
      throw new Error();
    }
    yield put({
      type: actionTypes.GET_PROVIDER_LIST_SUCCESS,
      payload: {
        page,
        totalCount: response.count,
        users: response.results
      }
    });

    if (hasSavingQueryParams) {
      yield put(saveLocationSearch({ menuItemType, locationSearch }));
    }
  } catch (err) {
    yield put({
      type: actionTypes.GET_PROVIDER_LIST_ERROR
    });
    console.log(err);
  }
}

export default function* watchGetProviderList() {
  yield takeLatest(actionTypes.GET_PROVIDER_LIST_FETCH, getProviderList);
}
