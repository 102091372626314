export const getRows = ({ list }) => {
  return list.map((quote) => ({
    id: quote.id,
    amount_total_charge: quote.amount_total_charge,
    customer: quote.customer,
    customer_id: quote.user_id,
    date_created: quote.date_created,
    datetime_last_note: quote.service_request?.datetime_last_note,
    last_message: quote.service_request?.last_job_chat_message,
    last_note: quote.service_request?.last_note,
    provider: quote.provider,
    provider_id: quote.provider_id,
    service_name: quote.service_request?.service_name,
    service_request_id: quote.service_request_id
  }));
};
