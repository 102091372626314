import actionTypes from 'constants/actionTypes';
import { adminStatuses } from 'common/enums/job';

const defaultState = {
  serviceListLoading: false,
  serviceList: [],
  hasListError: false,
  serviceListFilters: {},
  serviceListPage: 1,
  serviceTotalCount: 0,
  serviceInstanceLoading: false,
  serviceBundleList: [],
  serviceBundleTotalCount: 0,
  serviceBundleListLoading: false,
  updateJobFieldLoading: false,
  serviceInstance: {},
  serviceFormLoading: false,
  locationLoading: true,
  location: {},
  serviceProAssignBundleLoading: false,
  serviceProAssignBundle: {},
  serviceProgressListLoading: true,
  serviceProgressNextPageLoading: true,
  serviceProgressList: [],
  serviceProgressCount: 0,
  serviceProgressListPage: 1,
  serviceNoteListLoading: true,
  serviceNoteList: [],
  serviceNoteCount: 0,
  ownerReportLoading: false,
  ownerReport: {},
  updateServiceNoteLoading: false,
  deleteServiceNoteLoading: false,
  bundleServicesLoading: false,
  updateTaskStatusLoading: false,
  uploadPropertyReportLoading: false,
  unreadMessagesByCategories: {},
  tagsLoading: {}
};

function reducer(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.GET_SERVICE_LIST_FETCH: {
      const { silent = false, storeFilter = false, isBundle, ...filters } = action.payload;
      if (isBundle) {
        return {
          ...state,
          serviceBundleListLoading: !silent
        };
      }
      return {
        ...state,
        ...(storeFilter ? { serviceListFilters: filters } : {}),
        serviceListLoading: !silent,
        hasListError: false
      };
    }

    case actionTypes.GET_SERVICE_LIST_SUCCESS: {
      const { totalCount, service, page, isBundle } = action.payload;
      if (isBundle) {
        return {
          ...state,
          serviceBundleList: service,
          serviceBundleTotalCount: totalCount,
          serviceBundleListLoading: false
        };
      }
      return {
        ...state,
        serviceList: service,
        serviceListPage: page,
        serviceTotalCount: totalCount,
        serviceListLoading: false,
        hasListError: false
      };
    }

    case actionTypes.GET_SERVICE_LIST_ERROR: {
      const { isBundle } = action.payload;
      return {
        ...state,
        serviceListLoading: false,
        serviceList: [],
        hasListError: !isBundle,
        serviceBundleListLoading: false,
        serviceBundleList: []
      };
    }

    case actionTypes.GET_SERVICE_INSTANCE_FETCH: {
      const { silent = false } = action.payload;
      return {
        ...state,
        ...(silent ? {} : { location: {} }),
        serviceInstanceLoading: !silent
      };
    }
    case actionTypes.GET_SERVICE_INSTANCE_SUCCESS: {
      return {
        ...state,
        serviceInstance: action.payload,
        serviceInstanceLoading: false
      };
    }
    case actionTypes.GET_SERVICE_INSTANCE_ERROR: {
      return {
        ...state,
        serviceInstanceLoading: false,
        serviceInstance: {}
      };
    }

    case actionTypes.UPDATE_CUSTOMER_LOCATION_SUCCESS: {
      const { square, estimate_value, year_built } = action.payload;
      return {
        ...state,
        location: {
          ...state.location,
          square,
          estimate_value,
          year_built
        }
      };
    }

    case actionTypes.GET_SERVICE_LOCATION_FETCH: {
      const { silent = false } = action.payload;
      return {
        ...state,
        locationLoading: !silent,
        ...(silent ? {} : { location: {} })
      };
    }
    case actionTypes.GET_SERVICE_LOCATION_SUCCESS: {
      return {
        ...state,
        locationLoading: false,
        location: action.payload
      };
    }
    case actionTypes.GET_SERVICE_LOCATION_ERROR: {
      return {
        ...state,
        locationLoading: false,
        location: {}
      };
    }

    case actionTypes.GET_PRO_ASSIGN_BUNDLE_FETCH: {
      return {
        ...state,
        serviceProAssignBundleLoading: true
      };
    }
    case actionTypes.GET_PRO_ASSIGN_BUNDLE_SUCCESS: {
      return {
        ...state,
        serviceProAssignBundle: action.payload,
        serviceProAssignBundleLoading: false
      };
    }
    case actionTypes.GET_PRO_ASSIGN_BUNDLE_ERROR: {
      return {
        ...state,
        serviceProAssignBundle: {},
        serviceProAssignBundleLoading: false
      };
    }

    case actionTypes.SNOOZE_TASK_FETCH:
    case actionTypes.UPDATE_JOB_FIELD_FETCH: {
      const { tag } = action.payload;

      return {
        ...state,
        updateJobFieldLoading: true,
        tagsLoading: { ...state.tagsLoading, [tag]: true }
      };
    }

    case actionTypes.UPDATE_JOB_FIELD_SUCCESS: {
      const { data, tag } = action.payload;

      return {
        ...state,
        serviceInstance: data,
        updateJobFieldLoading: false,
        tagsLoading: { ...state.tagsLoading, [tag]: false }
      };
    }

    case actionTypes.UPDATE_JOB_FIELD_ERROR: {
      const { tag } = action.payload;

      return {
        ...state,
        updateJobFieldLoading: false,
        tagsLoading: { ...state.tagsLoading, [tag]: false }
      };
    }

    case actionTypes.SNOOZE_TASK_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        serviceInstance: payload,
        updateJobFieldLoading: false
      };
    }

    case actionTypes.SNOOZE_TASK_ERROR: {
      return {
        ...state,
        updateJobFieldLoading: false
      };
    }

    case actionTypes.GET_SERVICE_PROGRESS_LIST_FETCH: {
      const { silent = false, nextPage = false } = action.payload;
      return {
        ...state,
        serviceProgressListLoading: !nextPage && !silent,
        serviceProgressNextPageLoading: nextPage,
        serviceProgressList: nextPage ? state.serviceProgressList : [],
        serviceProgressListCount: 0
      };
    }
    case actionTypes.GET_SERVICE_PROGRESS_LIST_SUCCESS: {
      return {
        ...state,
        serviceProgressListLoading: false,
        serviceProgressNextPageLoading: false,
        serviceProgressList: action.payload.nextPage
          ? [...state.serviceProgressList, ...action.payload.progressList]
          : action.payload.progressList,
        serviceProgressListCount: action.payload.count,
        serviceProgressListPage: action.payload.page
      };
    }
    case actionTypes.GET_SERVICE_PROGRESS_LIST_ERROR: {
      return {
        ...state,
        serviceProgressListLoading: false,
        serviceProgressNextPageLoading: false
      };
    }

    case actionTypes.GET_SERVICE_NOTE_LIST_FETCH: {
      const { silent = false } = action.payload;
      return {
        ...state,
        serviceNoteListLoading: !silent,
        serviceNoteList: [],
        serviceNoteListCount: 0
      };
    }
    case actionTypes.GET_SERVICE_NOTE_LIST_SUCCESS: {
      return {
        ...state,
        serviceNoteListLoading: false,
        serviceNoteList: action.payload.noteList.reverse(),
        serviceNoteListCount: action.payload.count
      };
    }
    case actionTypes.GET_SERVICE_NOTE_LIST_ERROR: {
      return {
        ...state,
        serviceNotesListLoading: false
      };
    }

    case actionTypes.CREATE_SERVICE_NOTE_FETCH: {
      return {
        ...state,
        serviceNoteList: [action.payload, ...state.serviceNoteList]
      };
    }

    case actionTypes.UPDATE_SERVICE_NOTE_FETCH: {
      return {
        ...state,
        updateServiceNoteLoading: true
      };
    }

    case actionTypes.UPDATE_SERVICE_NOTE_SUCCESS:
    case actionTypes.CREATE_SERVICE_NOTE_SUCCESS: {
      const noteIndex = state.serviceNoteList.findIndex((item) => item.id === action.payload.id);

      if (noteIndex >= 0) {
        const noteList = [
          ...state.serviceNoteList.slice(0, noteIndex),
          action.payload.note,
          ...state.serviceNoteList.slice(noteIndex + 1)
        ];

        return {
          ...state,
          serviceNoteList: noteList,
          updateServiceNoteLoading: false
        };
      }

      return {
        ...state,
        updateServiceNoteLoading: false
      };
    }
    case actionTypes.SET_SERVICE_PAID: {
      return {
        ...state,
        serviceInstance: {
          ...state.serviceInstance,
          admin_status: adminStatuses.PAID.TYPE
        }
      };
    }
    case actionTypes.UPDATE_SERVICE_NOTE_ERROR: {
      return {
        ...state,
        updateServiceNoteLoading: false
      };
    }

    case actionTypes.CREATE_SERVICE_NOTE_ERROR: {
      const noteIndex = state.serviceNoteList.findIndex((item) => item.id === action.payload.id);

      if (noteIndex >= 0) {
        const noteList = [...state.serviceNoteList.slice(0, noteIndex), ...state.serviceNoteList.slice(noteIndex + 1)];

        return {
          ...state,
          serviceNoteList: noteList,
          updateServiceNoteLoading: false
        };
      }

      return {
        ...state,
        updateServiceNoteLoading: false
      };
    }

    case actionTypes.BUNDLE_SERVICES_FETCH: {
      return {
        ...state,
        bundleServicesLoading: true
      };
    }

    case actionTypes.BUNDLE_SERVICES_SUCCESS: {
      return {
        ...state,
        serviceInstance: action.payload,
        bundleServicesLoading: false
      };
    }

    case actionTypes.BUNDLE_SERVICES_ERROR: {
      return {
        ...state,
        serviceInstance: {
          ...state.serviceInstance,
          bundleError: true
        },
        bundleServicesLoading: false
      };
    }

    case actionTypes.DUPLICATE_TASK_FETCH:
    case actionTypes.UNBUNDLE_TASK_FETCH: {
      return {
        ...state,
        updateTaskStatusLoading: true
      };
    }

    case actionTypes.UNBUNDLE_TASK_SUCCESS:
    case actionTypes.UNBUNDLE_TASK_ERROR:
    case actionTypes.DUPLICATE_TASK_SUCCESS:
    case actionTypes.DUPLICATE_TASK_ERROR: {
      return {
        ...state,
        updateTaskStatusLoading: false
      };
    }

    case actionTypes.UPLOAD_CUSTOMER_INSPECTION_REPORT_FETCH: {
      if (state.serviceInstance?.user_id === Number(action.payload.user_id)) {
        return {
          ...state,
          uploadPropertyReportLoading: true
        };
      }
      return state;
    }

    case actionTypes.UPLOAD_CUSTOMER_INSPECTION_REPORT_ERROR:
    case actionTypes.UPLOAD_CUSTOMER_INSPECTION_REPORT_SUCCESS: {
      if (state.serviceInstance?.user_id === Number(action.payload)) {
        return {
          ...state,
          uploadPropertyReportLoading: false
        };
      }
      return state;
    }

    case actionTypes.GET_UNREAD_MESSAGED_BY_CATEGORIES_SUCCESS: {
      return {
        ...state,
        unreadMessagesByCategories: action.payload
      };
    }

    case actionTypes.DELETE_SERVICE_NOTE_FETCH: {
      return {
        ...state,
        deleteServiceNoteLoading: true
      };
    }

    case actionTypes.DELETE_SERVICE_NOTE_SUCCESS:
    case actionTypes.DELETE_SERVICE_NOTE_ERROR: {
      return {
        ...state,
        deleteServiceNoteLoading: false
      };
    }

    case actionTypes.CREATE_REQUEST_FETCH:
    case actionTypes.UPDATE_REQUEST_FETCH: {
      return {
        ...state,
        serviceFormLoading: true
      };
    }
    case actionTypes.CREATE_REQUEST_SUCCESS:
    case actionTypes.CREATE_REQUEST_ERROR:
    case actionTypes.UPDATE_REQUEST_SUCCESS:
    case actionTypes.UPDATE_REQUEST_ERROR: {
      return {
        ...state,
        serviceFormLoading: false
      };
    }

    case actionTypes.GET_OWNER_REPORT_FETCH: {
      return {
        ...state,
        ownerReportLoading: true
      };
    }
    case actionTypes.GET_OWNER_REPORT_SUCCESS: {
      return {
        ...state,
        ownerReportLoading: false,
        ownerReport: action.payload
      };
    }
    case actionTypes.GET_OWNER_REPORT_ERROR: {
      return {
        ...state,
        ownerReportLoading: false,
        ownerReport: {}
      };
    }

    default:
      return state;
  }
}

// selectors
export const selectJobsListPage = (state) => state.service.serviceListPage;
export const selectServiceList = (state) => state.service.serviceList;
export const selectServiceListError = (state) => state.service.hasListError;
export const selectServiceListLoading = (state) => state.service.serviceListLoading;
export const selectServiceTotalCount = (state) => state.service.serviceTotalCount;
export const selectServiceBundleList = (state) => state.service.serviceBundleList;
export const selectServiceBundleListLoading = (state) => state.service.serviceBundleListLoading;
export const selectServiceBundleTotalCount = (state) => state.service.serviceBundleTotalCount;
export const selectServiceInstance = (state) => state.service.serviceInstance;
export const selectServiceInstanceId = (state) => state.service.serviceInstance?.id;
export const selectUpdateJobFieldLoading = (state) => state.service.updateJobFieldLoading;
export const selectServiceInstanceLoading = (state) => state.service.serviceInstanceLoading;
export const selectServiceLocation = (state) => state.service.location;
export const selectServiceLocationLoading = (state) => state.service.locationLoading;
export const selectStoredServiceListFilters = (state) => state.service.serviceListFilters;

export const selectServiceProAssignBundleLoading = (state) => state.service.serviceProAssignBundleLoading;
export const selectServiceProAssignBundle = (state) => state.service.serviceProAssignBundle;

export const selectServiceProgressListLoading = (state) => state.service.serviceProgressListLoading;
export const selectServiceProgressNextPageLoading = (state) => state.service.serviceProgressNextPageLoading;
export const selectServiceProgressList = (state) => state.service.serviceProgressList;
export const selectServiceProgressListCount = (state) => state.service.serviceProgressListCount;
export const selectServiceProgressListPage = (state) => state.service.serviceProgressListPage;

export const selectServiceNoteListLoading = (state) => state.service.serviceNoteListLoading;
export const selectServiceNoteList = (state) => state.service.serviceNoteList;
export const selectServiceNoteListCount = (state) => state.service.selectServiceNoteListCount;

export const selectUpdateServiceNoteLoading = (state) => state.service.updateServiceNoteLoading;

export const selectBundleLoading = (state) => state.service.bundleServicesLoading;
export const selectUpdateTaskStatusLoading = (state) => state.service.updateTaskStatusLoading;

export const selectServiceUploadPropertyReportLoading = (state) => state.service.uploadPropertyReportLoading;

export const selectUnreadMessagesByCategories = (state) => state.service.unreadMessagesByCategories;

export const selectDeleteServiceNoteLoading = (state) => state.service.deleteServiceNoteLoading;

export const selectServiceFormLoading = (state) => state.service.serviceFormLoading;

export const selectTagsLoading = (state) => state.service.tagsLoading;

export const selectOwnerReportLoading = (state) => state.service.ownerReportLoading;
export const selectOwnerReport = (state) => state.service.ownerReport;

export default reducer;
