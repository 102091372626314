import actionTypes from 'constants/actionTypes';
import { oneClickActions } from 'common/enums/user';

const defaultState = {
  deleteCognitoUserLoading: false,
  updateActivationLoading: false,
  updateMarkAsTestLoading: false,
  updateMarkAsTeamMemberLoading: false,
  updateIsCreateJobEnabledLoading: false,
  updateIsRemindPaymentLoading: false,
  rewardsLoading: false,
  scheduleVisitLoading: false,
  chatExtendOrCloseLoading: false
};

function reducer(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.DELETE_COGNITO_USER_FETCH: {
      return {
        ...state,
        deleteCognitoUserLoading: true
      };
    }

    case actionTypes.DELETE_COGNITO_USER_ERROR:
    case actionTypes.DELETE_COGNITO_USER_SUCCESS: {
      return {
        ...state,
        deleteCognitoUserLoading: false
      };
    }

    case actionTypes.UPDATE_USER_INSTANCE_FETCH: {
      const { updateAction } = action.payload;
      if (updateAction === oneClickActions.IS_ACTIVE) {
        return {
          ...state,
          updateActivationLoading: true
        };
      }
      if (updateAction === oneClickActions.IS_TEST) {
        return {
          ...state,
          updateMarkAsTestLoading: true
        };
      }
      if (updateAction === oneClickActions.IS_TEAM_MEMBER) {
        return {
          ...state,
          updateMarkAsTeamMemberLoading: true
        };
      }
      return state;
    }

    case actionTypes.UPDATE_USER_INSTANCE_ERROR:
    case actionTypes.UPDATE_USER_INSTANCE_SUCCESS: {
      const { updateAction } = action.payload;
      if (updateAction === oneClickActions.IS_ACTIVE) {
        return {
          ...state,
          updateActivationLoading: false
        };
      }
      if (updateAction === oneClickActions.IS_TEST) {
        return {
          ...state,
          updateMarkAsTestLoading: false
        };
      }
      if (updateAction === oneClickActions.IS_TEAM_MEMBER) {
        return {
          ...state,
          updateMarkAsTeamMemberLoading: false
        };
      }
      return state;
    }

    case actionTypes.UPDATE_CUSTOMER_INSTANCE_FETCH: {
      const { updateAction } = action.payload;
      if (updateAction === oneClickActions.IS_CREATE_JOB_ENABLED) {
        return {
          ...state,
          updateIsCreateJobEnabledLoading: true
        };
      }
      if (updateAction === oneClickActions.IS_REMIND_PAYMENT) {
        return {
          ...state,
          updateIsRemindPaymentLoading: true
        };
      }
      return state;
    }
    case actionTypes.UPDATE_CUSTOMER_INSTANCE_ERROR:
    case actionTypes.UPDATE_CUSTOMER_INSTANCE_SUCCESS: {
      const { updateAction } = action.payload;
      if (updateAction === oneClickActions.IS_CREATE_JOB_ENABLED) {
        return {
          ...state,
          updateIsCreateJobEnabledLoading: false
        };
      }
      if (updateAction === oneClickActions.IS_REMIND_PAYMENT) {
        return {
          ...state,
          updateIsRemindPaymentLoading: false
        };
      }
      return state;
    }

    case actionTypes.GET_REWARDS_FETCH: {
      return {
        ...state,
        rewardsLoading: true
      };
    }
    case actionTypes.GET_REWARDS_SUCCESS:
    case actionTypes.GET_REWARDS_ERROR: {
      return {
        ...state,
        rewardsLoading: false
      };
    }

    case actionTypes.SCHEDULE_VISIT_FETCH: {
      return {
        ...state,
        scheduleVisitLoading: true
      };
    }

    case actionTypes.SCHEDULE_VISIT_SUCCESS:
    case actionTypes.SCHEDULE_VISIT_ERROR: {
      return {
        ...state,
        scheduleVisitLoading: false
      };
    }

    case actionTypes.CHAT_EXTEND_OR_CLOSE_FETCH: {
      return {
        ...state,
        chatExtendOrCloseLoading: true
      };
    }

    case actionTypes.CHAT_EXTEND_OR_CLOSE_SUCCESS:
    case actionTypes.CHAT_EXTEND_OR_CLOSE_ERROR: {
      return {
        ...state,
        chatExtendOrCloseLoading: false
      };
    }

    default:
      return state;
  }
}

export const selectDeleteCognitoUserLoading = (state) => state.loading.deleteCognitoUserLoading;
export const selectUpdateUserActivationLoading = (state) => state.loading.updateActivationLoading;
export const selectMarkUserAsTestLoading = (state) => state.loading.updateMarkAsTestLoading;
export const selectMarkUserAsTeamMemberLoading = (state) => state.loading.updateMarkAsTeamMemberLoading;
export const selectMarkIsCreateJobEnabled = (state) => state.loading.updateIsCreateJobEnabledLoading;
export const selectMarkIsRemindPayment = (state) => state.loading.updateIsRemindPaymentLoading;
export const isRewardsLoading = (state) => state.loading.rewardsLoading;
export const selectScheduleVisitLoading = (state) => state.loading.scheduleVisitLoading;
export const selectChatExtendOrCloseLoading = (state) => state.loading.chatExtendOrCloseLoading;

export default reducer;
