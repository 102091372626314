import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useForm } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';

import { debounce } from 'utils';
import modalTypes from 'constants/modalTypes';
import styles from 'constants/styles';
import { Modal, ModalConfirmation, Button, HookForm } from 'components';
import { closeModal, getPromoCodeList, createReward } from 'store/actions';
import { selectPromoCodeList, selectCreateRewardLoading } from 'store/reducers/promoCode';
import { selectModalType, selectModalData } from 'store/reducers/modal';

import { ContentFormWrapper, FlexRow, dropdownStyles } from './styled';

const RewardCreate = ({
  modalType = modalTypes.REWARD,
  closeModal,
  modalData: { userId } = {},
  promoCodeList = [],
  isCreateRewardLoading,
  getPromoCodeList,
  createReward
}) => {
  const {
    control,
    watch,
    handleSubmit,
    formState: { errors }
  } = useForm();
  const selectedPromoCodeId = watch('promo_code_id');

  const [isConfirmationOpened, setIsConfirmationOpened] = useState(false);
  useEffect(() => {
    getPromoCodeList({ userId });
  }, []);

  const onModalClose = () => closeModal();
  const onSubmitForm = (formData) => {
    createReward({
      id: uuidv4(),
      user_id: userId,
      ...formData
    });

    setIsConfirmationOpened(false);
  };
  const validateAndConfirm = handleSubmit(() => setIsConfirmationOpened(true));

  const promoOptions = promoCodeList.map((promo) => ({
    id: promo.referral_code,
    name: `${promo.referral_code} - $${(promo.amount || 0) / 100}`
  }));
  const onSearchPromo = debounce((searchValue) => getPromoCodeList({ referral_code: searchValue, userId }), 500);

  return (
    <Modal
      modalType={modalType}
      hideModal={onModalClose}
      closeButton
      heading={'Add New Reward'}
      backgroundColor={styles.colors.WHITE}
      padding={'0px'}
      maxWidth={424}
      minWidth={424}
      maxHeight={0}
      overflowMode="none">
      <ContentFormWrapper>
        <FlexRow>
          <HookForm.Select
            name="promo_code_id"
            control={control}
            rules={{ required: true }}
            error={errors.promo_code_id}
            placeholder="Select reward"
            options={promoOptions}
            width={376}
            withSearch
            onSearch={onSearchPromo}
            style={dropdownStyles}
            searchPlaceholder="Enter reward name"
          />
        </FlexRow>
        <FlexRow>
          <Button title="Cancel" variant="primary" margin="0 20px 0 0" onClick={onModalClose} />
          <Button
            title="Add"
            onClick={validateAndConfirm}
            loading={isCreateRewardLoading}
            disabled={isCreateRewardLoading}
          />
        </FlexRow>
      </ContentFormWrapper>
      <ModalConfirmation
        visible={isConfirmationOpened}
        maxWidth={null}
        smallPaddings={false}
        title={`Add "${selectedPromoCodeId}"?`}
        hideModal={() => setIsConfirmationOpened(false)}
        primaryButton={{
          title: 'No',
          onClick: () => setIsConfirmationOpened(false),
          disabled: isCreateRewardLoading
        }}
        secondaryButton={{
          title: 'Yes',
          onClick: handleSubmit(onSubmitForm),
          disabled: isCreateRewardLoading,
          loading: isCreateRewardLoading
        }}
      />
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  openedModalType: selectModalType(state),
  modalData: selectModalData(state),
  promoCodeList: selectPromoCodeList(state),
  isCreateRewardLoading: selectCreateRewardLoading(state)
});

const mapDispatchToProps = {
  closeModal,
  getPromoCodeList,
  createReward
};

export default connect(mapStateToProps, mapDispatchToProps)(RewardCreate);
