import styled from 'styled-components';

export const Info = styled.div`
  margin: 0 0 0 6px;
  display: flex;
  align-items: flex-start;
`;

export const TestTaskTooltipText = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  padding-left: 15px;

  & > li:first-child {
    margin-bottom: 5px;
  }
  & > li:before {
    font-size: 8px;
    content: '●';
    position: absolute;
    transform: translateY(25%);
    color: ${({ theme }) => theme.colors.TOOLTIP_TEXT};
    margin-left: -12px;
  }
`;
