// React
import React, { memo, useEffect } from 'react';
import { useHover, useOnScreen, useTheme } from 'hooks';
// Utils
import routes from 'constants/routes';
import { getFullName } from 'common/helpers/user';
import { formatters } from 'utils';
// Components
import { Button, Icon, UserName, StyledTooltip, DropdownSection } from 'components';
import { ProviderNotes } from '../';
import WorkTogetherBadge from '../WorkTogetherBadge/WorkTogetherBadge';
// Styles
import {
  ProviderCardContainer,
  ContentContainer,
  HeaderSection,
  BadgeSection,
  PillsSection,
  StatusNameBlock,
  Label,
  Divider,
  ProviderNavLink,
  Footer,
  DropDownWrapper,
  Flex,
  FooterMain,
  Pill,
  TooltipText
} from './styled';

const ProviderItem = ({
  isSelected,
  isSomeVisitSelected,
  isVisitHovered,
  id,
  upcoming_events,
  family_name,
  given_name,
  amount_average_earned,
  count_service_completed,
  count_service_ongoing,
  count_jobs_accepted,
  onCardClick,
  onAssignClick,
  onToggleHover,
  count_of_notes,
  is_notes_exists,
  is_new_provider,
  is_worked_together,
  is_worked_together_review,
  provider_skills,
  percent_completed,
  service_area,
  percent_positive_reviews
}) => {
  const theme = useTheme();
  const [hoverRef, isHovered] = useHover();
  const isVisible = useOnScreen(hoverRef);

  useEffect(() => {
    onToggleHover({ isHovered, providerId: id });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHovered]);

  useEffect(() => {
    if (isSelected && !isVisible && hoverRef.current) {
      hoverRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    }
  }, [isSelected, isVisible, hoverRef]);

  const handleCardClick = () => {
    onCardClick(id);
  };

  const handleStopPropagation = (e) => {
    e.stopPropagation();
  };

  const handleAssignClick = (e) => {
    e.stopPropagation();
    onAssignClick({
      id,
      given_name,
      family_name,
      serviceArea: service_area
    });
  };

  const name = getFullName({ given_name, family_name });
  const isHighlighted = isSelected || isVisitHovered;

  return (
    <ProviderCardContainer
      isHighlighted={isHighlighted}
      onClick={handleCardClick}
      ref={hoverRef}
      disabled={
        !upcoming_events?.length ||
        (isSelected && !isSomeVisitSelected) ||
        (isSelected && upcoming_events?.length === 1)
      }>
      <ContentContainer>
        <HeaderSection>
          <StatusNameBlock>
            <ProviderNavLink
              rel="noopener noreferrer"
              target="_blank"
              to={`${routes.PROVIDER}/${id}`}
              onClick={handleStopPropagation}
              color={!name ? theme.colors.TEXT_DISABLED : theme.colors.TEXT}>
              <UserName given_name={name || 'No Pro Name'} disabled={!name} />
            </ProviderNavLink>
            {is_notes_exists && <ProviderNotes userId={id} notesCount={count_of_notes} />}
          </StatusNameBlock>
          <Label>{formatters.dollarCurrencyDB((amount_average_earned || 0)?.toString() || '$0.00')}</Label>
          <div>
            <Button smallSize title="Assign Pro" onClick={handleAssignClick} />
          </div>
        </HeaderSection>
        <BadgeSection>
          {is_new_provider && (
            <Pill key={'New Pro'} color={theme.colors.DARK_GREEN}>
              New Pro
            </Pill>
          )}
          {is_worked_together && (
            <WorkTogetherBadge
              is_worked_together={is_worked_together}
              is_worked_together_review={is_worked_together_review}
            />
          )}
          {service_area?.map(({ id, name, full_name }, i) => (
            <Pill key={id}>
              <StyledTooltip placement="top" arrow title={full_name}>
                <TooltipText>{name}</TooltipText>
              </StyledTooltip>
            </Pill>
          ))}
        </BadgeSection>
        <div>
          <Footer>
            <FooterMain>
              <Flex>
                <StyledTooltip placement="top" arrow title="Completion Rate">
                  <TooltipText>{percent_completed.toFixed()}%</TooltipText>
                </StyledTooltip>
                &#9679;
                <StyledTooltip placement="top" arrow title="Accepted Jobs">
                  <TooltipText>{count_jobs_accepted} accepted</TooltipText>
                </StyledTooltip>
                &#9679;
                <StyledTooltip placement="top" arrow title="Ongoing Jobs">
                  <TooltipText>{count_service_ongoing} open</TooltipText>
                </StyledTooltip>
                &#9679;
                <StyledTooltip placement="top" arrow title="Completed Jobs">
                  <TooltipText>{count_service_completed} completed</TooltipText>
                </StyledTooltip>
                &#9679;
                <StyledTooltip placement="top" arrow title="Positive reviews">
                  <TooltipText>
                    {`${percent_positive_reviews || 0}%`} <Icon name="thumbUp" size={12} />
                  </TooltipText>
                </StyledTooltip>
              </Flex>
            </FooterMain>
          </Footer>
          <DropDownWrapper>
            <PillsSection>
              {Boolean(provider_skills?.length)
                ? provider_skills.map(({ skill: { id, name } }) => <Pill key={id}>{name}</Pill>)
                : '-'}
            </PillsSection>
          </DropDownWrapper>
        </div>
      </ContentContainer>
      <Divider />
    </ProviderCardContainer>
  );
};

export default memo(ProviderItem);
