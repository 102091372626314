import styled from 'styled-components';

export const Title = styled.div`
  display: flex;
  flex: 1;
  margin-top: 8px;
  color: ${({ theme }) => theme.colors.DARK_GREY};
  font-weight: 500;
  font-size: 12px;
  line-height: 1.17;
  ${({ display }) =>
    display
      ? `
  color: ${({ theme }) => theme.colors.WHITE};
  margin: 0;
`
      : ''}
`;

export const Item = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0 0 0 0;

  font-weight: 400;
  font-size: 12px;
  line-height: 1.33;
  color: ${({ theme }) => theme.colors.WHITE};
  span {
    word-break: break-word;
    white-space: pre-wrap;
  }

  ${({ display }) =>
    display
      ? `
    color: ${({ theme }) => theme.colors.WHITE};
    line-height: 1.2;
  `
      : ''}
`;

export const Value = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  margin-top: 8px;
  color: ${({ theme }) => theme.colors.WHITE};
  font-size: 14px;
  line-height: 1.14;

  word-break: break-word;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;

  max-width: 100%;
  overflow: wrap;

  ${({ bold }) =>
    bold &&
    `
    font-weight: 600;
  `}
`;

export const Description = styled.div`
  flex: 1;
  flex-basis: 100px;
  text-align: left;
`;

export const Amount = styled.span`
  flex-basis: 100px;
  text-align: right;
`;
