import React from 'react';

import { useDispatch, useSelector } from 'hooks';
import { updateJobField } from 'store/actions';
import { selectServiceInstance, selectUpdateJobFieldLoading } from 'store/reducers/service';
import { getFullName } from 'common/helpers/user';
import { Modal, Button } from 'components';
import styles from 'constants/styles';

import { BoldText, FlexRow, FlexColumn, Header, Label, cancelBtnStyle, confirmBtnStyle } from './styled';

export const AssignProviderConfirmation = ({ visible, onCloseModal, onOpenResultModal, modalData, jobId }) => {
  const dispatch = useDispatch();
  const loading = useSelector(selectUpdateJobFieldLoading);
  const serviceInstance = useSelector(selectServiceInstance);

  const handleAssignResult = (modalData) => {
    onCloseModal();
    onOpenResultModal(modalData);
  };

  const handleAssignProvider = () => {
    dispatch(
      updateJobField({
        job_id: jobId,
        body: {
          previous_provider_id: serviceInstance.service_provider_id,
          service_provider_id: modalData?.provider?.id
        },
        callback: handleAssignResult
      })
    );
  };

  return (
    <Modal
      visible={visible}
      hideModal={onCloseModal}
      closeButton
      backgroundColor={styles.colors.WHITE}
      middlePaddings
      maxWidth={0}
      maxHeight={0}>
      <FlexRow>
        <Header>Please confirm assignment</Header>
      </FlexRow>
      <FlexRow>
        <Label>
          <BoldText>{getFullName(modalData?.provider || {})}</BoldText> will receive notification about new job{' '}
          <BoldText>(ID {jobId})</BoldText>
        </Label>
      </FlexRow>
      <FlexColumn>
        <Button title="Cancel" variant="primary" style={cancelBtnStyle} onClick={onCloseModal} disabled={loading} />

        <Button title="Confirm" style={confirmBtnStyle} onClick={handleAssignProvider} loading={loading} />
      </FlexColumn>
    </Modal>
  );
};
