import React, { useEffect, useRef } from 'react';
import { createSearchParams as createQueryParams } from 'react-router-dom';
import { Icon } from 'components';
import { defaultQueryParams } from 'common/enums/queryParams';
import { useMemo, useRouter, useReloadTable, useSelector } from 'hooks';
import { topNavigationTabs } from 'common/enums/navigation';
import { useActiveMenuItem } from 'navigation/hooks';
import { selectBadges } from 'store/reducers/user';

import { Tabs } from 'components';
import { Wrapper, EmptySpace, Button, TabsWrapper } from './styled';
import { useState } from 'react';

const TabsNavigation = () => {
  const { handleReloadTable } = useReloadTable();
  const { activeMenuItem } = useActiveMenuItem();

  const { queryParams } = useRouter();
  const badges = useSelector(selectBadges);
  const filters = useMemo(() => queryParams.getAll(), [queryParams]);
  const tabsEnum = topNavigationTabs[filters.menu_item] || {};

  const tabs = useMemo(
    () =>
      Object.values(tabsEnum).map((tab) => {
        return {
          ...tab,
          count: badges?.[tab.COUNTER_NAME] || 0
        };
      }),
    [tabsEnum, badges]
  );

  const handleTabChange = (tab) => {
    const { tab_id, menu_item, sorting_column, sorting_direction, ...otherFilters } = filters;
    const menuFilters = {
      menu_item: activeMenuItem.TYPE,
      tab_id: tab.TYPE || tabsEnum.DEFAULT.TYPE
    };

    const sortingParams = {
      ...defaultQueryParams,
      ...(activeMenuItem?.SORTING_PARAMS || {}),
      ...(tab?.SORTING_PARAMS || {}),
      page: 1
    };

    const navigationFilters = {
      ...otherFilters,
      ...menuFilters,
      ...(tab.NO_FILTERS ? {} : sortingParams)
    };
    queryParams.setAll(navigationFilters);

    const locationSearch = createQueryParams(navigationFilters).toString();
    handleReloadTable({
      newTab: tab,
      newNavigationFilters: sortingParams,
      newRequestFilters: {
        page: 1
      },
      locationSearch
    });
  };
  const [isShowScroll, setIsShowScroll] = useState(false);
  const [scrollPosition, setScrollPosition] = useState();

  const ref = useRef(null);
  const tabsRef = useRef(null);

  const updateScrollPosition = () => {
    setScrollPosition(tabsRef.current?.getBoundingClientRect()?.left);
  };

  useEffect(() => {
    if (ref && ref.current) {
      ref.current.addEventListener('scroll', updateScrollPosition, false);
      const currentRef = ref.current;
      return function cleanup() {
        currentRef.removeEventListener('scroll', updateScrollPosition, false);
      };
    }
  }, []);

  useEffect(() => {
    setIsShowScroll(ref.current?.scrollWidth > ref.current?.clientWidth);
  }, [activeMenuItem]);

  const scrollLeft = () => ref.current?.scrollTo({ left: 0, behavior: 'smooth' });
  const scrollRight = () => ref.current?.scrollTo({ left: 10000, behavior: 'smooth' });
  return (
    <TabsWrapper>
      <Wrapper ref={ref}>
        <div ref={tabsRef}>
          <Tabs
            tabs={tabs}
            onTabChange={handleTabChange}
            boldFont
            scrollLeft={scrollLeft}
            scrollRight={scrollRight}
            // uncomment to enable badges
            hasCounters
          />
        </div>
        <EmptySpace />
      </Wrapper>
      {isShowScroll &&
        (scrollPosition < 192 ? (
          <Button onClick={scrollLeft}>
            <Icon name="leftArrow" />
          </Button>
        ) : (
          <Button right onClick={scrollRight}>
            <Icon name="leftArrow" />
          </Button>
        ))}
    </TabsWrapper>
  );
};

export default TabsNavigation;
