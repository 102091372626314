import { adminStatuses } from 'common/enums/job';
import routes from 'constants/routes';

export const hasJobBundleButton = ({ pathname, admin_status, count }) => {
  const canAdminStatusBundle = [
    adminStatuses.REQUESTED.TYPE,
    adminStatuses.ASSIGNED.TYPE,
    adminStatuses.ACCEPTED.TYPE,
    adminStatuses.SCHEDULED.TYPE
  ].includes(admin_status);

  const splittedPathname = pathname.split('/');
  const isJobDetailsRoute =
    Boolean(splittedPathname[splittedPathname.length - 1]) &&
    `/${splittedPathname[splittedPathname.length - 2]}` === routes.SERVICE;

  return count > 1 && canAdminStatusBundle && isJobDetailsRoute;
};
