import { call, takeLatest, put, select } from 'redux-saga/effects';
import qs from 'query-string';

import Api from 'services/ApiService';
import api_constants from 'constants/api';
import actionTypes from 'constants/actionTypes';
import { saveLocationSearch } from 'store/actions/navigation';
import { isTestTasksVisible, selectFilteredGeoLocationList } from 'store/reducers/app';

function* getReviewList({ payload }) {
  const {
    page,
    order_by,
    search = '',
    operational_area_id,
    hasSavingQueryParams,
    menuItemType,
    locationSearch
  } = payload;
  const testTasksVisible = yield select(isTestTasksVisible);
  const filteredGeoLocationList = yield select(selectFilteredGeoLocationList);
  const opAreasIDString = operational_area_id
    ? operational_area_id
    : filteredGeoLocationList.length
    ? filteredGeoLocationList.join(',')
    : '';
  const queryString = qs.stringify(
    {
      page,
      order_by,
      search,
      is_test: testTasksVisible ? '' : '0',
      ...(opAreasIDString ? { operational_area_id: opAreasIDString } : {})
    },
    { skipEmptyString: true }
  );

  try {
    const response = yield call(Api.amplifyGet, `${api_constants.REVIEW}?${queryString}`);

    if (!response?.results) {
      throw new Error(response?.error_message);
    }

    yield put({
      type: actionTypes.GET_REVIEW_LIST_SUCCESS,
      payload: {
        count: response.count,
        list: response.results,
        page
      }
    });

    if (hasSavingQueryParams) {
      yield put(saveLocationSearch({ menuItemType, locationSearch }));
    }
  } catch (err) {
    yield put({
      type: actionTypes.GET_REVIEW_LIST_ERROR
    });
  }
}

export default function* watchGetReviewList() {
  yield takeLatest(actionTypes.GET_REVIEW_LIST_FETCH, getReviewList);
}
