import React from 'react';

import { useDispatch, useSelector } from 'hooks';
import { closeModal } from 'store/actions';
import { selectModalData } from 'store/reducers/modal';
import modalTypes from 'constants/modalTypes';
import { ModalConfirmation } from 'components';
import { ModalText } from './styled';

const BundleResult = () => {
  const dispatch = useDispatch();
  const modalData = useSelector(selectModalData);

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  if (modalData.hasError) {
    return (
      <ModalConfirmation
        modalType={modalTypes.BUNDLE_SERVICES_RESULT}
        text={
          <ModalText>
            <strong>Something went wrong. Please, try again later</strong>
          </ModalText>
        }
        hideModal={handleCloseModal}
        secondaryButton={{
          title: 'Ok',
          onClick: handleCloseModal
        }}
      />
    );
  }

  return (
    <ModalConfirmation
      modalType={modalTypes.BUNDLE_SERVICES_RESULT}
      title={`${modalData.count} jobs successfuly bundled.`}
      text={
        <ModalText>
          <strong>Everything is collected under the job ID {modalData.job_id}</strong>
        </ModalText>
      }
      hideModal={handleCloseModal}
      secondaryButton={{
        title: 'Take a look',
        onClick: handleCloseModal
      }}
    />
  );
};

export default BundleResult;
