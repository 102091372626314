import { all, takeEvery, put, select } from 'redux-saga/effects';
import actionTypes from 'constants/actionTypes';
import { selectMediaUrlsByType } from 'store/reducers/runtimeCache';

// download 1 media for each of list item
function* getMediaUrlForList({ payload: { instances, type, media_field = 'media' } }) {
  try {
    const cachedUrls = yield select(selectMediaUrlsByType(type));
    const uncachedInstances = instances.filter((instance) => instance[media_field]?.path && !cachedUrls[instance.id]);
    const uncachedInstancesPromises = uncachedInstances.map((instance) =>
      put({
        type: actionTypes.GET_MEDIA_URL_FETCH,
        payload: { path: instance[media_field]?.path, id: instance.id, type }
      })
    );

    yield all(uncachedInstancesPromises);
  } catch (error) {
    yield put({
      type: actionTypes.GET_MEDIA_URL_FOR_LIST_ERROR
    });
  }
}

export default function* watchGetMediaUrlForList() {
  yield takeEvery(actionTypes.GET_MEDIA_URL_FOR_LIST_FETCH, getMediaUrlForList);
}
