import { call, takeLatest, put } from 'redux-saga/effects';
import api_constants from 'constants/api';
import actionTypes from 'constants/actionTypes';
import { openModal } from 'store/actions';
import Api from 'services/ApiService';

function* deleteProviderReview({ payload: { reviewId, service_demand_id, resultModalType } }) {
  try {
    const response = yield call(Api.amplifyDel, `${api_constants.REVIEW}/${reviewId}`);

    if (response && response.err) {
      yield put(
        openModal(resultModalType, {
          deleteReviewError: true
        })
      );

      yield put({
        type: actionTypes.DELETE_PROVIDER_REVIEW_ERROR
      });
    } else {
      yield put(openModal(resultModalType, { service_demand_id }));
      yield put({
        type: actionTypes.DELETE_PROVIDER_REVIEW_SUCCESS
      });
    }
  } catch (err) {
    console.log(err);
  }
}

export default function* watchDeleteProviderReview() {
  yield takeLatest(actionTypes.DELETE_PROVIDER_REVIEW_FETCH, deleteProviderReview);
}
