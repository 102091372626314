import { analyticMetrics, analyticPeriods } from 'common/enums/analytics';
import moment from 'moment-timezone';
import dates from 'constants/dates';

export const getMetricNameByType = (metricType) =>
  Object.values(analyticMetrics).find((metric) => metric.TYPE === metricType)?.NAME || '';

export const getPeriodTimeByType = ({ startDate, endDate, periodType }) => {
  const selectedPeriod = Object.values(analyticPeriods).find((period) => period.TYPE === periodType);

  if (!startDate || !endDate || !selectedPeriod) {
    return '';
  }

  const periods = {
    [analyticPeriods.DAY.TYPE]: () => moment(startDate).format(`${dates.MONTH_NAME} ${dates.DAY}, ${dates.YEAR}`),
    [analyticPeriods.WEEK.TYPE]: () => {
      const firstDayOfWeek = moment(startDate).format(`${dates.MONTH_NAME} ${dates.DAY}, ${dates.YEAR}`);
      const lastDayOfWeek = moment(endDate).format(`${dates.MONTH_NAME} ${dates.DAY}, ${dates.YEAR}`);

      return `${firstDayOfWeek} - ${lastDayOfWeek}`;
    },
    [analyticPeriods.MONTH.TYPE]: () => moment(startDate).format(`${dates.MONTH_NAME} ${dates.YEAR}`),
    [analyticPeriods.YEAR.TYPE]: () => moment(startDate).format(dates.YEAR)
  };

  return periods[selectedPeriod.TYPE]();
};
