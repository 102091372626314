// React/Redux
import React, { lazy, useState } from 'react';
import { connect } from 'react-redux';
import { useTheme } from 'styled-components';
import moment from 'moment';
// Util libs
import PropTypes from 'prop-types';
import Lightbox from 'react-image-lightbox';
// Store
import {
  selectPaymentImageUrlsById,
  selectPaymentPDFUrlById,
  selectQuotationImageUrlsById,
  selectQuotationPDFUrlById,
  selectNotesMedia
} from 'store/reducers/runtimeCache';
import { selectAdminInfo } from 'store/reducers/app';
import { adminPermissionStatuses } from 'common/enums/app';
import { invoiceStatuses, invoiceTypes, invoiceItemTypes, paymentInvoiceStatuses } from 'common/enums/invoice';
import { mediaTypes } from 'common/enums/app';
// Utils
import { getInvoiceType } from 'common/helpers/invoice';
import { formatters } from 'utils';
import { getInvoiceStatus, generatePDFname } from '../../helpers';
import { isOfflinePayment } from './helpers/invoice';
// Constants
import styles from 'constants/styles';
import dates from 'constants/dates';
import { commissionTypes } from 'common/enums/invoice';
import { quoteStatuses, paymentStatuses } from '../../constants';
// Components
import { IconButton, MediaGallery, Line, Tooltip, Reward } from 'components';
import { HistoryInvoiceRow, ItemsList, InvoiceSummary, InvoiceValidation, InvoiceNotes } from './components';
import { VideoPlayer } from 'modals/common';
// Styles
import {
  RightActionsSection,
  InvoiceWrapper,
  InvoiceSection,
  MainHeading,
  HeadingCell,
  HeadingFlexColumn,
  HeadingFlexRow,
  InvoiceRow,
  Divider,
  DividerMarginBlock,
  Column,
  CurrencyValue,
  StatusLabel,
  StatusLabelCell,
  ActionsWrapper,
  Title,
  Value,
  DateTimeValue,
  PdfLink,
  MediaWrapper,
  MediaBeforeWrapper
} from './styled';

const PDFViewer = lazy(() => import('components/PDFViewer'));

const InvoiceDetails = ({
  invoice: {
    amount_customer_reward,
    commission_type,
    custom_reward_purpose,
    amount_provider_payout,
    amount_deposit,
    amount_labor_cost,
    amount_material,
    date_created,
    datetime_sent,
    datetime_paid,
    expire_duration,
    estimate_duration,
    datetime_expire,
    labor,
    deposit,
    material,
    items,
    is_final_payment,
    is_paid,
    is_editable,
    purpose_note,
    media,
    is_itemized,
    is_quotation,
    is_accepted,
    type_of_payment,
    payment_note,
    payment_history,
    note_media,
    status,
    invoice_status
  },
  invoice,
  imageBeforeUrls,
  imageAfterUrls,
  reward,
  onEdit,
  onApprove,
  onValidate,
  onRemove,
  onPayout,
  onClose,
  existingPayments,
  full_name,
  pdfUrl,
  adminInfo,
  notesMedia
}) => {
  const theme = useTheme();
  const [pdfViewer, setPdfViewer] = useState({});
  const [imagePreview, setImagePreview] = useState('');
  const [videoPlayerUrl, setVideoPlayerUrl] = useState('');
  const { LABEL, COLOR } = getInvoiceStatus({ status, is_quotation, invoice_status });
  const isCustomDiscount = Boolean(custom_reward_purpose && amount_customer_reward);
  const isOldDeposit = !is_quotation && Boolean(deposit?.length || amount_deposit);
  const isMarginFee = commission_type === commissionTypes.MARGIN;
  const amount_reward = reward?.id ? reward?.amount : 0;
  // filter payment date_craeted with invoice date_created
  const isPaymentExists =
    existingPayments?.some(
      (payment) =>
        payment.is_moderated &&
        payment?.datetime_sent &&
        datetime_sent &&
        moment(payment?.datetime_sent) > moment(date_created)
    ) || false;

  const hasAdminPermission = adminInfo?.permission?.some(
    (permission) => permission?.position === adminPermissionStatuses.ADMIN.POSITION_ID
  );
  const pdfName = generatePDFname({
    date: date_created,
    isDeposit: isOldDeposit,
    is_final_payment,
    is_quotation,
    full_name
  });
  const isOfflinePaid = is_paid && isOfflinePayment(type_of_payment);
  const flatRateItems = items.filter((item) => item.item_section === invoiceItemTypes.FLAT.TYPE);
  const amountFlatRate = flatRateItems.reduce((acc, { price }) => acc + price, 0);
  // Action buttons
  const isActionButtons = Boolean(
    (!is_quotation &&
      !is_paid &&
      amount_provider_payout === null &&
      !(invoice_status === paymentInvoiceStatuses.PROCESSING)) ||
      is_quotation
  );
  // Display edit button conditions
  const isEditDisplay =
    is_editable &&
    !isOldDeposit &&
    ((!is_quotation && !is_paid) || (is_quotation && is_accepted !== false && !isPaymentExists));
  // Display delete button conditions
  const isDeleteDisplay = Boolean(
    (!is_quotation && !is_paid && amount_provider_payout === null) ||
      (is_quotation && [quoteStatuses.PENDING_ADMIN.TYPE, quoteStatuses.PENDING_CUSTOMER.TYPE].includes(status))
  );

  const { label, TYPE: invoice_type } =
    getInvoiceType({ isDeposit: isOldDeposit, is_final_payment, is_quotation }) || {};

  const mediaBefore = media?.filter(({ media_type }) => media_type === mediaTypes.BEFORE);
  const mediaAfter = media?.filter(({ media_type }) => media_type === mediaTypes.AFTER);
  return (
    <InvoiceWrapper isRejected={is_accepted === invoiceStatuses.REJECTED.VALUE}>
      <InvoiceSection>
        <InvoiceRow>
          <HeadingCell>
            <HeadingFlexRow>
              <HeadingFlexColumn>
                <MainHeading>{label}</MainHeading>
                {Boolean(datetime_paid || date_created) && (
                  <DateTimeValue>
                    {formatters.dateWithTimezone(datetime_paid || date_created, dates.DATETIME)}
                  </DateTimeValue>
                )}
              </HeadingFlexColumn>
              <StatusLabelCell>
                <StatusLabel
                  color={
                    !is_quotation && invoice_status === paymentInvoiceStatuses.PROCESSING
                      ? styles.colors.DARK_YELLOW
                      : COLOR
                  }>
                  {!is_quotation && invoice_status === paymentInvoiceStatuses.PROCESSING ? 'Processing' : LABEL}
                </StatusLabel>
              </StatusLabelCell>
            </HeadingFlexRow>
            {Boolean(pdfUrl) && (
              <PdfLink
                onClick={() => {
                  if (pdfUrl) {
                    setPdfViewer({ name: pdfName, url: pdfUrl });
                  }
                }}>
                {pdfName}
              </PdfLink>
            )}
          </HeadingCell>
          <RightActionsSection>
            {isActionButtons && (
              <ActionsWrapper>
                {/* Close invoice */}
                {!is_quotation && status === paymentStatuses.PENDING_CUSTOMER.TYPE && (
                  <IconButton square bigText text="Close" width={63} height={24} margin="0 4px 0" onClick={onClose} />
                )}

                {/* Approve quote */}
                {is_quotation && hasAdminPermission && status === quoteStatuses.PENDING_CUSTOMER.TYPE && (
                  <IconButton
                    iconName="checkIn"
                    margin="0 4px"
                    iconSize={14}
                    iconColor={styles.colors.WHITE}
                    onClick={onApprove}
                  />
                )}
                {/* Edit invoice */}
                {isEditDisplay && (
                  <IconButton
                    iconName="editFill"
                    onClick={onEdit}
                    margin="0 4px"
                    iconSize={12}
                    iconColor={styles.colors.WHITE}
                  />
                )}

                {/* Delete invoice */}
                {isDeleteDisplay && (
                  <IconButton
                    iconName="close2"
                    margin="0 0 0 4px"
                    iconSize={9.5}
                    iconColor={styles.colors.WHITE}
                    onClick={onRemove}
                  />
                )}
              </ActionsWrapper>
            )}
            <InvoiceValidation invoiceStatus={status} onOpenValidationConfirmation={onValidate} />
          </RightActionsSection>
        </InvoiceRow>

        {is_quotation && (
          <InvoiceRow>
            <Column column>
              <Title>Expiration Date of the Quote</Title>
              {datetime_expire ? (
                <Value>{formatters.dateWithTimezone(datetime_expire, dates.DATETIME_FULL_HUMAN)}</Value>
              ) : expire_duration ? (
                <Value>{`This quote will expire in ${expire_duration} days after the quote is sent.`}</Value>
              ) : (
                <Value>This quote will not expire</Value>
              )}
            </Column>
            <Column column>
              <Title>Approximated time to complete the job</Title>
              <Value>
                {estimate_duration && estimate_duration / 24 >= 1
                  ? `${parseInt(estimate_duration / 24)} day${estimate_duration / 24 > 1.5 ? 's' : ''}`
                  : `${estimate_duration} hours`}
              </Value>
            </Column>
          </InvoiceRow>
        )}

        <Column column fillWidth marginBottom="10px">
          <Title>{is_quotation ? 'Expected Results' : 'Job Results'}</Title>
          <Value>{purpose_note || '-'}</Value>
        </Column>
      </InvoiceSection>
      {invoice_type === invoiceTypes.FINAL.TYPE && (
        <MediaBeforeWrapper>
          <MediaGallery
            media={mediaBefore}
            label="Attached Media BEFORE"
            mediaUrls={imageBeforeUrls}
            loading={mediaBefore?.length !== imageBeforeUrls?.length}
            isOriginalForPreview
          />
        </MediaBeforeWrapper>
      )}
      <MediaWrapper>
        <MediaGallery
          media={mediaAfter}
          label={`Attached Media${invoice_type === invoiceTypes.FINAL.TYPE ? ' AFTER' : ''}`}
          mediaUrls={imageAfterUrls}
          loading={mediaAfter?.length !== imageAfterUrls?.length}
          isOriginalForPreview
        />
      </MediaWrapper>

      <InvoiceSection>
        <ItemsList title="Deposit" items={deposit} amount={amount_deposit} />
        {isOfflinePaid ? (
          <>
            <HistoryInvoiceRow
              initial="Initiated"
              paid={is_paid ? 'Paid' : 'Initiated'}
              isPaid={isOfflinePaid}
              valueColor={theme.colors.DARK_GREY}
              textAlign="center"
            />
            <HistoryInvoiceRow
              title="Labor"
              isPaid={isOfflinePaid}
              initial={formatters.dollarCurrencyDB((payment_history?.amount_labor_cost || 0) - amountFlatRate)}
              paidItem={formatters.dollarCurrencyDB()}
              paid={formatters.dollarCurrencyDB(
                labor ? labor.reduce((acc, { price }) => acc + price, 0) : amount_labor_cost
              )}
            />
            <HistoryInvoiceRow
              title="Material"
              isPaid={isOfflinePaid}
              initial={formatters.dollarCurrencyDB(payment_history?.amount_material || 0)}
              paid={formatters.dollarCurrencyDB(
                material ? material.reduce((acc, { price }) => acc + price, 0) : amount_material
              )}
            />
            <HistoryInvoiceRow
              title="Flat Rate"
              isPaid={isOfflinePaid}
              initial={formatters.dollarCurrencyDB(amountFlatRate)}
              paid={formatters.dollarCurrencyDB(
                flatRateItems ? flatRateItems.reduce((acc, { price }) => acc + price, 0) : 0
              )}
            />
          </>
        ) : (
          <>
            <ItemsList title="Labor" items={labor} amount={amount_labor_cost} isItemized={is_itemized} />
            <ItemsList title="Materials" items={material} amount={amount_material} isItemized={is_itemized} />
            <ItemsList title="Flat Rate" items={flatRateItems} amount={0} isItemized={is_itemized} />
          </>
        )}
        <Title>Discounts</Title>
        {isCustomDiscount ? (
          <CurrencyValue>
            {`${custom_reward_purpose} (- ${formatters.dollarCurrencyDB(amount_customer_reward || 0)})`}{' '}
          </CurrencyValue>
        ) : reward?.promo_code_id ? (
          <Tooltip
            title={reward?.description}
            maxWidth={300}
            wrappedComponent={<Reward promocodeId={reward?.promo_code_id} />}
          />
        ) : (
          <Value>-</Value>
        )}

        <DividerMarginBlock />
        <Divider />
        <InvoiceSummary
          invoice={invoice}
          amountReward={amount_reward}
          isMarginFee={isMarginFee}
          onPayout={onPayout}
          isOfflinePaid={isOfflinePaid}
        />

        <Line />
        <InvoiceNotes
          isOfflinePaid={isOfflinePaid}
          paymentNote={payment_note}
          noteMedia={note_media}
          notesMedia={notesMedia}
          setPdfViewer={setPdfViewer}
          setImagePreview={setImagePreview}
          setVideoPlayer={setVideoPlayerUrl}
        />
      </InvoiceSection>
      {pdfViewer.url && (
        <PDFViewer
          mediaUrl={pdfViewer.url}
          onViewerClose={() => setPdfViewer({})}
          fileNameGenerator={() => pdfViewer.name}
        />
      )}
      {Boolean(videoPlayerUrl) && <VideoPlayer signedUrl={videoPlayerUrl} onModalClose={() => setVideoPlayerUrl('')} />}
      {imagePreview && <Lightbox mainSrc={imagePreview} onCloseRequest={() => setImagePreview('')} />}
    </InvoiceWrapper>
  );
};

InvoiceDetails.propTypes = {
  invoice: PropTypes.object,
  onEdit: PropTypes.func.isRequired,
  onApprove: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onPayout: PropTypes.func
};

const mapStateToProps = (state, { invoice: { id, is_quotation } }) => ({
  imageBeforeUrls: is_quotation
    ? selectQuotationImageUrlsById(state, { id, media_type: mediaTypes.BEFORE })
    : selectPaymentImageUrlsById(state, { id, media_type: mediaTypes.BEFORE }),
  imageAfterUrls: is_quotation
    ? selectQuotationImageUrlsById(state, { id, media_type: mediaTypes.AFTER })
    : selectPaymentImageUrlsById(state, { id, media_type: mediaTypes.AFTER }),
  pdfUrl: is_quotation ? selectQuotationPDFUrlById(state, id) : selectPaymentPDFUrlById(state, id),
  notesMedia: selectNotesMedia(state),
  adminInfo: selectAdminInfo(state)
});

export default connect(mapStateToProps)(InvoiceDetails);
