import styled from 'styled-components';

export const NotificationsWrapper = styled.div`
  position: absolute;
  top: 54px;
  right: 0;
  padding-right: 24px;
  z-index: 6;
  overflow: hidden;
`;
