import { OVERLAP_THRESHOLD, OVERLAP_SHIFT } from '../constants';

const isOverlapping = (marker1, marker2) =>
  Math.abs(marker1.longitude - marker2.longitude) < OVERLAP_THRESHOLD &&
  Math.abs(marker1.latitude - marker2.latitude) < OVERLAP_THRESHOLD;

const shiftCoordinates = (markers, marker) => {
  let hasOverlap = true;
  let overlapShift = 0;

  while (hasOverlap) {
    let shiftedMarker = {
      longitude: marker.longitude + overlapShift,
      latitude: marker.latitude + overlapShift
    };
    const overlapingCoordinates = markers.some(
      (preparedMarker) => preparedMarker.visit_id !== marker.visit_id && isOverlapping(shiftedMarker, preparedMarker)
    );
    if (!overlapingCoordinates) {
      hasOverlap = false;
    } else {
      overlapShift += OVERLAP_SHIFT;
    }
  }

  if (!overlapShift) {
    return marker;
  }
  return {
    ...marker,
    longitude: marker.longitude + overlapShift,
    latitude: marker.latitude + overlapShift
  };
};

/**
 * modifies coordinates of overlapping markers
 */
export const prepareVisitMarkers = (providers) => {
  const visitMarkers = providers?.reduce((agregated, item) => {
    if (!item.upcoming_events?.length) {
      return agregated;
    }

    const markers = [...agregated];
    if (item.upcoming_events) {
      item.upcoming_events.forEach((marker) => {
        markers.push(shiftCoordinates(markers, marker));
      });
    }

    return markers;
  }, []);

  return visitMarkers || [];
};
