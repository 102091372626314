import React from 'react';

import { useDispatch, useSelector } from 'hooks';
import { updateJobField } from 'store/actions';
import { selectUpdateJobFieldLoading } from 'store/reducers/service';
import { Modal, Button } from 'components';
import styles from 'constants/styles';

import { FlexRow, FlexColumn, Header, Label, cancelBtnStyle, confirmBtnStyle } from './styled';
import { getFullName } from 'common/helpers/user';

const ConfirmWorkingArea = ({ visible, onCloseModal, onOpenResultModal, modalData, jobId, serviceArea }) => {
  const dispatch = useDispatch();
  const loading = useSelector(selectUpdateJobFieldLoading);

  const handleAssignResult = (modalData) => {
    onCloseModal();
    onOpenResultModal(modalData);
  };

  const handleAssignProvider = () => {
    dispatch(
      updateJobField({
        job_id: jobId,
        body: { service_provider_id: modalData?.provider?.id },
        callback: handleAssignResult
      })
    );
  };
  const provider = modalData?.provider;

  const fullName = provider ? getFullName(provider) : '';

  return (
    <Modal
      visible={visible}
      hideModal={onCloseModal}
      closeButton
      backgroundColor={styles.colors.WHITE}
      middlePaddings
      maxWidth={600}
      maxHeight={0}>
      <FlexRow>
        <Header>
          {fullName} is not serving the current working area ({serviceArea?.name})
        </Header>
      </FlexRow>
      <FlexRow>
        <Label>
          <bold>
            {provider?.given_name} {provider?.family_name}'s
          </bold>{' '}
          current working areas:{' '}
          <bold>{provider?.serviceArea?.length ? provider?.serviceArea?.map(({ name }) => name).join('; ') : '-'}</bold>
        </Label>
      </FlexRow>
      <FlexColumn>
        <Button title="Cancel" variant="primary" style={cancelBtnStyle} onClick={onCloseModal} disabled={loading} />

        <Button title="Assign Anyway" style={confirmBtnStyle} onClick={handleAssignProvider} loading={loading} />
      </FlexColumn>
    </Modal>
  );
};

export default ConfirmWorkingArea;
