import actionTypes from 'constants/actionTypes';

const defaultState = {
  avatar: {},
  avatarOriginal: {},
  taskImageUrls: {},
  paymentImageUrls: {},
  paymentPDFUrls: {},
  quotationImageUrls: {},
  quotationPDFUrls: {},
  paymentNotes: {}
};

function reducer(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.GET_AVATAR_FETCH: {
      if (state.avatar[action.payload.url] === undefined) {
        let avatar = { ...state.avatar };
        avatar[action.payload.url] = null;

        return {
          ...state,
          avatar
        };
      }
      return state;
    }
    case actionTypes.GET_AVATAR_SUCCESS: {
      let avatar = { ...state.avatar };
      avatar[action.payload.url] = action.payload.blobUrl;

      return {
        ...state,
        avatar
      };
    }

    case actionTypes.GET_AVATAR_ORIGINAL_FETCH: {
      if (state.avatarOriginal[action.payload.id] === undefined) {
        let avatarOriginal = { ...state.avatarOriginal };
        avatarOriginal[action.payload.id] = null;

        return {
          ...state,
          avatarOriginal
        };
      }
      return state;
    }
    case actionTypes.GET_AVATAR_ORIGINAL_SUCCESS: {
      let avatarOriginal = { ...state.avatarOriginal };
      avatarOriginal[action.payload.id] = action.payload.blobUrl;

      return {
        ...state,
        avatarOriginal
      };
    }

    case actionTypes.SIGN_SERVICE_IMAGE_URLS_SUCCESS: {
      const { imageUrls, serviceId } = action.payload;
      return {
        ...state,
        taskImageUrls: {
          ...state.taskImageUrls,
          [serviceId]: imageUrls
        }
      };
    }

    case actionTypes.GET_MEDIA_URL_SUCCESS: {
      const { url, id, type } = action.payload;
      if (!type) {
        return state;
      }

      return {
        ...state,
        [type]: {
          ...state[type],
          [id]: url
        }
      };
    }

    case actionTypes.GET_MEDIA_URLS_SUCCESS: {
      const { urls, id, type } = action.payload;
      if (!type) {
        return state;
      }

      return {
        ...state,
        [type]: {
          ...state[type],
          [id]: urls
        }
      };
    }

    case actionTypes.UPDATE_PAYMENT_SUCCESS: {
      const { id } = action.payload;
      return {
        ...state,
        paymentImageUrls: {
          ...state.paymentImageUrls,
          [id]: null
        },
        paymentPDFUrls: {
          [id]: null
        }
      };
    }

    case actionTypes.UPDATE_QUOTATION_SUCCESS: {
      const { id } = action.payload;
      return {
        ...state,
        quotationImageUrls: {
          ...state.quotationImageUrls,
          [id]: null
        },
        quotationPDFUrls: {
          ...state.quotationPDFUrls,
          [id]: null
        }
      };
    }

    default:
      return state;
  }
}

// selectors
export const selectMediaUrlsByType = (type) => (state) => state.runtimeCache[type] || [];

export const selectAvatar = (state) => state.runtimeCache.avatar;
export const selectAvatarOriginalByUserId = (state, id) => state.runtimeCache.avatarOriginal[id];
export const selectTaskImageUrls = (state) => state.runtimeCache.taskImageUrls;

export const selectPaymentImageUrlsById = (state, { id, media_type }) =>
  state.runtimeCache.paymentImageUrls[id]?.filter((file) => file.media_type === media_type).map(({ url }) => url) || [];
export const selectPaymentPDFUrlById = (state, id) => state.runtimeCache.paymentPDFUrls[id];
export const selectNotesMedia = (state) => state.runtimeCache.paymentNotes;
export const selectQuotationImageUrlsById = (state, { id, media_type }) =>
  state.runtimeCache.quotationImageUrls[id]?.filter((file) => file.media_type === media_type).map(({ url }) => url) ||
  [];
export const selectQuotationPDFUrlById = (state, id) => state.runtimeCache.quotationPDFUrls[id];

export default reducer;
