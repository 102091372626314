import styled from 'styled-components';
import styles from 'constants/styles';

export const Content = styled.div`
  display: flex;
  flex: 1;
`;

export const Navigation = styled.div`
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  width: ${styles.layout.navigationWidth}px;
  background-color: ${({ theme }) => theme.colors.SECONDARY};
`;

export const NavigationPlaceholder = styled.div`
  display: flex;
  min-width: ${styles.layout.navigationWidth}px;
  width: ${styles.layout.navigationWidth}px;
  background-color: ${styles.colors.BRAND_DARK};
`;

export const HeaderImage = styled.img`
  margin: 20px 29px 14px 0;
  width: 119px;
  height: 52.51px;
  align-self: center;
`;

export const NavBar = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: ${styles.layout.navigationWidth}px;
  height: 100%;
  position: relative;
  overflow: overlay;
  z-index: 7;
  padding-bottom: 10px;
  max-height: calc(100vh - 240px);
`;

export const LogoText = styled.div`
  font-weight: 600;
  font-size: 13px;
  margin: -2px 0 0 0;
  letter-spacing: 0.14em;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.ACCENT_1};
`;

export const NavigationHolder = styled.ul`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  width: 100%;
  padding: 10px 0 0 0;
`;

export const NavigationLinkHolder = styled.div`
  padding: 0 0 14px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.DARK_1};
  width: 100%;
`;

export const LogoWrapper = styled.div`
  padding: 30px 0 0 25px;
`;

export const RowFlex = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  max-width: 100%;
  background-color: ${({ theme }) => theme.colors.TABLE_BG};
`;

export const ColumnFlex = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: overlay;
`;
