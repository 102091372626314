import { call, takeLatest, put } from 'redux-saga/effects';

import api_constants from 'constants/api';
import errors from 'constants/errors';
import actionTypes from 'constants/actionTypes';
import modalTypes from 'constants/modalTypes';
import { openModal } from 'store/actions';
import Api from 'services/ApiService';

function* sendTestCampaignEvent({ payload }) {
  const { executionId, test_recipient_ids } = payload;

  try {
    const response = yield call(Api.amplifyPost, `${api_constants.CAMPAIGN_EXECUTE}/${executionId}/test`, {
      test_recipient_ids
    });

    if (!response || !response?.notified_users || response.err) {
      throw new Error(response?.err.response?.data.error_message);
    }

    yield put({
      type: actionTypes.SEND_TEST_CAMPAIGN_EVENT_SUCCESS
    });

    yield put(
      openModal(modalTypes.RESULT, {
        title: `Test event has been sent`,
        text: `To next IDs:\n${response.notified_users?.join(', ') || '-'}`,
        textStyle: {
          textAlign: 'left',
          alignSelf: 'flex-start'
        }
      })
    );
  } catch (err) {
    yield put({
      type: actionTypes.SEND_TEST_CAMPAIGN_EVENT_ERROR
    });

    yield put(
      openModal(modalTypes.ERROR, {
        errorMessage: err?.message || errors.DEFAULT
      })
    );
  }
}

export default function* watchSendTestCampaignEvent() {
  yield takeLatest(actionTypes.SEND_TEST_CAMPAIGN_EVENT_FETCH, sendTestCampaignEvent);
}
