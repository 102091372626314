import { call, takeLatest, put } from 'redux-saga/effects';

import api_constants from 'constants/api';
import actionTypes from 'constants/actionTypes';
import errors from 'constants/errors';
import Api from 'services/ApiService';
import { chatMessageTransformer } from 'utils';

function* getSupportMessageById({ payload: { id, user_id } }) {
  try {
    const response = yield call(Api.amplifyGet, `${api_constants.SUPPORT_REQUEST}/${id}?user_id=${user_id}`);
    if (!response) {
      throw new Error(errors.DEFAULT);
    }
    yield put({
      type: actionTypes.GET_SUPPORT_MESSAGE_BY_ID_SUCCESS,
      payload: {
        message: chatMessageTransformer(response),
        transformedId: id
      }
    });
  } catch (err) {
    yield put({
      type: actionTypes.GET_SUPPORT_MESSAGE_BY_ID_ERROR
    });
    console.log(err);
  }
}

export default function* watchGetSupportMessageById() {
  yield takeLatest(actionTypes.GET_SUPPORT_MESSAGE_BY_ID_FETCH, getSupportMessageById);
}
