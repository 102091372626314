import { call, takeEvery, put } from 'redux-saga/effects';

import api_constants from 'constants/api';
import actionTypes from 'constants/actionTypes';
import errors from 'constants/errors';
import Api from 'services/ApiService';
import { chatMessageTransformer } from 'utils';

function* sendSupportMessage({ payload }) {
  let { message } = payload;

  try {
    if (message.media) {
      const media = message.media;
      const mediaUploadUrl = yield call(Api.amplifyPost, api_constants.MEDIA, {
        original_name: media.name,
        sub_folder: 'support_chat'
      });

      if (mediaUploadUrl) {
        const uploadResponse = yield call(Api.s3Put, mediaUploadUrl.upload_url, media, { 'Content-Type': media.type });
        if (uploadResponse) {
          message.media = mediaUploadUrl.id;
        } else {
          message.media = null;
        }
      }
    }

    const response = yield call(Api.amplifyPost, `${api_constants.SUPPORT_REQUEST}`, message);
    if (!response) {
      throw new Error(errors.DEFAULT);
    }
    const newMessage = chatMessageTransformer(response);
    yield put({
      type: actionTypes.CREATE_SUPPORT_MESSAGE_SUCCESS,
      payload: {
        message: newMessage,
        transformedId: message.id
      }
    });
    yield put({
      type: actionTypes.GET_SUPPORT_LINK_PREVIEWS_FETCH,
      payload: {
        messageList: [newMessage],
        userId: newMessage.user_id
      }
    });
  } catch (err) {
    yield put({
      type: actionTypes.CREATE_SUPPORT_MESSAGE_ERROR,
      payload: {
        message: message,
        transformedId: message.id
      }
    });
    console.log(err);
  }
}

export default function* watchSendSupportMessage() {
  yield takeEvery(actionTypes.CREATE_SUPPORT_MESSAGE_FETCH, sendSupportMessage);
}
