import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Amplify } from 'aws-amplify';

import { useDispatch } from 'hooks';
import signOut from 'services/auth/SignOut';
import { setDevEnvironment } from 'store/actions';
import { setEnvironment } from 'store/actions';
import { isDevEnvironment } from 'store/reducers/app';
import { Switch } from 'components';

import { SwitchLabel, SwitchWrapper } from './styled';
import { amplifyConfig } from './amplifyConfig';
import { environments } from 'common/enums/app';
import { environment } from 'common/constants/app';

Amplify.configure(environment === environments.LOCAL.TYPE ? amplifyConfig.local : amplifyConfig.product);

const EnvironmentToggle = () => {
  const dispatch = useDispatch();
  const hasDevEnvironment = useSelector(isDevEnvironment);

  useEffect(() => {
    if (environment === environments.LOCAL.TYPE) {
      return;
    }
    if (hasDevEnvironment) {
      Amplify.configure(amplifyConfig.staging);
      dispatch(setEnvironment(environments.STAGING.TYPE));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleToggleEnvironment = (value) => {
    dispatch(setDevEnvironment(!value));
    dispatch(setEnvironment(!value ? environments.STAGING.TYPE : environments.PRODUCT.TYPE));

    Amplify.configure(value ? amplifyConfig.product : amplifyConfig.staging);
    // TODO: (Veniamin) replace with session switch
    signOut();
  };

  if (environment === environments.LOCAL.TYPE) {
    return <SwitchLabel>Local</SwitchLabel>;
  }

  return (
    <>
      <SwitchLabel>{hasDevEnvironment ? 'Development' : 'Production'}</SwitchLabel>
      <SwitchWrapper>
        <Switch checked={!hasDevEnvironment} onChange={handleToggleEnvironment} />
      </SwitchWrapper>
    </>
  );
};

export default EnvironmentToggle;
