import React, { Fragment } from 'react';

import { useSelector } from 'hooks';
import routes from 'constants/routes';
import { selectServiceLocation } from 'store/reducers/service';

import { Breadcrumb } from '../../components';
import { BundledTasks, Link } from './styled';

const JobBreadcrumb = ({ match, location }) => {
  const { job_id } = match.params;
  const jobAdditionalInfo = useSelector(selectServiceLocation);

  const bundledJobs = jobAdditionalInfo?.bundled;
  const isJobBundled = bundledJobs?.length && location.pathname.split('/').length < 4;

  const breadcrumbName = `Job ID ${job_id}`;

  if (!isJobBundled) {
    return (
      <Breadcrumb
        breadcrumbName={breadcrumbName}
        isActive={match.pathname === location.pathname}
        pathname={match.pathname}
      />
    );
  }

  return (
    <>
      <Breadcrumb
        breadcrumbName={breadcrumbName}
        isActive={match.pathname === location.pathname}
        pathname={match.pathname}
      />
      <BundledTasks>
        {bundledJobs.map((bundledTask, index) => (
          <Fragment key={`bundled-task-${bundledTask.id}`}>
            {Boolean(index === 0) && '('}
            <Link to={`${routes.SERVICE}/${bundledTask.id}`} rel="noopener noreferrer" target="_blank">
              {bundledTask.id}
            </Link>
            {Boolean(index !== bundledJobs.length - 1) ? ', ' : ')'}
          </Fragment>
        ))}
      </BundledTasks>
    </>
  );
};

export default JobBreadcrumb;
