import styled from 'styled-components';

export const NotificationIconWrapper = styled.button`
  padding: 0;
  margin: 0 12px;
  position: relative;
`;

export const FilterButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
`;

export const UnreadNotificationMark = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.DARK_GREEN_2};
  border: 2px solid ${({ theme }) => theme.colors.DARK_2};
  position: absolute;
  top: -2px;
  right: -2px;
`;
