import { call, takeLatest, put } from 'redux-saga/effects';
import api_constants from 'constants/api';
import actionTypes from 'constants/actionTypes';
import Api from 'services/ApiService';

function* updateUserNote(action) {
  const {
    payload: { user_id, id, description, reminder_counter }
  } = action;
  try {
    const response = yield call(
      Api.amplifyPut,
      `${api_constants.USER}/note/${id}`,
      {
        description,
        user_id,
        reminder_counter,
        media: []
      }
    );
    if (response) {
      yield put({
        type: actionTypes.UPDATE_USER_NOTE_SUCCESS,
        payload: {
          note: response,
          id: response.id
        }
      });
    } else {
      yield put({
        type: actionTypes.UPDATE_USER_NOTE_ERROR
      });
    }
  } catch (err) {
    console.log(err);
  }
}

export default function* watchUpdateUserNote() {
  yield takeLatest(actionTypes.UPDATE_USER_NOTE_FETCH, updateUserNote);
}
