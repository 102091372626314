import { columnNames } from 'common/enums/sortings';

export const analyticsTaskTable = {
  DOWNLOAD_CSV_BUTTON: {
    TITLE: '',
    FILTER_NAME: columnNames.DOWNLOAD_CSV_BUTTON
  },
  PAID: {
    TITLE: 'Paid',
    FILTER_NAME: columnNames.AMOUNT_PAID
  },
  STAGE: {
    TITLE: 'Stage',
    FILTER_NAME: columnNames.STAGE
  },
  REQUEST_ID: {
    TITLE: 'Request ID',
    FILTER_NAME: columnNames.ID
  },
  JOB_CATEGORY_NAME: {
    TITLE: 'Category',
    FILTER_NAME: columnNames.JOB_CATEGORY_NAME
  },
  REQUESTED: {
    TITLE: 'Requested',
    FILTER_NAME: columnNames.DATETIME_CREATED
  },
  COMPLETED: {
    TITLE: 'Completed',
    FILTER_NAME: columnNames.DATETIME_COMPLETED
  },
  HO_ID: {
    TITLE: 'HO ID',
    FILTER_NAME: columnNames.HO_ID
  },
  HO_NAME: {
    TITLE: 'HO Name',
    FILTER_NAME: columnNames.HO_GIVEN_NAME
  },
  PRO_ID: {
    TITLE: 'Pro ID',
    FILTER_NAME: columnNames.PRO_ID
  },
  PRO_NAME: {
    TITLE: 'Pro Name',
    FILTER_NAME: columnNames.PRO_GIVEN_NAME
  },
  WARNING: {
    TITLE: 'Early Warning',
    FILTER_NAME: columnNames.WARNING
  },
  LAST_NOTE: {
    TITLE: 'Last Note',
    FILTER_NAME: columnNames.LAST_NOTE
  }
};
