import { takeEvery, put, call } from 'redux-saga/effects';
import actionTypes from 'constants/actionTypes';
import { getImageFromS3 } from 'utils';
import errors from 'constants/errors';

function* getMediaUrl({ payload: { path, id, type } }) {
  try {
    if (!path) {
      throw new Error(errors.GET_EMPTY_MEDIA);
    }

    const mediaFile = yield call(getImageFromS3, path);
    if (mediaFile instanceof Error) {
      throw new Error(errors.GET_EMPTY_MEDIA);
    }

    const mediaBlob = yield mediaFile.blob();
    const url = URL.createObjectURL(mediaBlob);

    if (!url) {
      throw new Error(errors.GET_EMPTY_MEDIA);
    }
    yield put({
      type: actionTypes.GET_MEDIA_URL_SUCCESS,
      payload: { url, id, type }
    });
  } catch (err) {
    yield put({
      type: actionTypes.GET_MEDIA_URL_ERROR
    });
  }
}

export default function* watchGetMediaUrl() {
  yield takeEvery(actionTypes.GET_MEDIA_URL_FETCH, getMediaUrl);
}
