export const getRows = ({ list }) => {
  return list.map((payment) => ({
    amount_customer_paid: payment.amount_customer_paid,
    amount_total_charge: payment.amount_total_charge,
    customer: payment.customer,
    customer_id: payment.user_id,
    date_created: payment.date_created,
    datetime_last_note: payment.service_request?.datetime_last_note,
    datetime_paid: payment.datetime_paid,
    id: payment.id,
    is_final_payment: payment.is_final_payment,
    last_message: payment.service_request?.last_job_chat_message,
    last_note: payment.service_request?.last_note,
    provider: payment.provider,
    provider_id: payment.provider_id,
    service_name: payment.service_request?.service_name,
    service_request_id: payment.service_request_id
  }));
};
