import React from 'react';
import { Icon, IconButton } from 'components';
import styles from 'constants/styles';
import { PaidBorder, PaidTitle } from './styled';

export const Payout = ({ showButton, onPayout }) => {
  if (showButton) {
    return (
      <IconButton square height={18} width={80} onClick={onPayout}>
        <span>Send Payout</span>
      </IconButton>
    );
  }

  return (
    <PaidBorder>
      <Icon name="okOutlined" size={10} backgroundSize={10} color={styles.colors.DARK_GREEN_2} />
      <PaidTitle>
        <span>Paid Out</span>
      </PaidTitle>
    </PaidBorder>
  );
};
