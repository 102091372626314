import React from 'react';
import content from 'constants/content';
import { ReasonWrapper, ReasonGroup, ReasonTab } from './styled';

export const UnfulfilledReason = ({ reasonId, additionalInfo = '' }) => {
  const reason = content.unfulfilledReasonList.find((item) => item.id === reasonId);
  if (reason === undefined) {
    return null;
  }

  const reasonGroup = content.unfulfilledGroupList.find((item) => item.id === reason.groupId) || {};
  const isOtherReason = reasonGroup.id === 11;
  return (
    <ReasonWrapper backgroundColor={reasonGroup.color}>
      <ReasonGroup>{reasonGroup.name}:</ReasonGroup>
      <ReasonTab>{isOtherReason ? additionalInfo : reason.name}</ReasonTab>
    </ReasonWrapper>
  );
};
