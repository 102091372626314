import { call, takeLatest, put } from 'redux-saga/effects';

import apiConstants from 'constants/api';
import actionTypes from 'constants/actionTypes';
import Api from 'services/ApiService';

function* getOwnerReport(action) {
  const {
    payload: { onRequestProcessed, ...formData }
  } = action;

  try {
    const response = yield call(Api.amplifyPost, `${apiConstants.ANALYTIC_METRICS}/jobs/owner`, formData);

    if (!response) {
      throw new Error(response?.error_message);
    }

    yield put({
      type: actionTypes.GET_OWNER_REPORT_SUCCESS,
      payload: response
    });

    onRequestProcessed(true);
  } catch (err) {
    yield put({
      type: actionTypes.GET_OWNER_REPORT_ERROR,
      payload: err?.message
    });

    onRequestProcessed(false);
    console.log(err);
  }
}

export default function* watchGetOwnerReport() {
  yield takeLatest(actionTypes.GET_OWNER_REPORT_FETCH, getOwnerReport);
}
