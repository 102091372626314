import styled from 'styled-components';

export const Label = styled.div`
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  margin-right: 8px;

  ${({ isHighLighted, color }) =>
    isHighLighted
      ? `
        font-size: 14px;
        line-height: 16px;
        font-weight: 700;
    `
      : `
        color: ${color || '#97A0A0'};
    `}

  &>a {
    color: ${({ theme }) => theme.colors.DARK_GREEN_2};
    text-decoration: underline;
  }

  & > a:hover {
    cursor: pointer;
  }
`;

export const Row = styled.div`
  display: flex;
  margin: 12px 0 0;
  min-height: 24px;
  align-items: center;
`;
