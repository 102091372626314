import React from 'react';
import routes from 'constants/routes';

import { Breadcrumb } from './components';

import {
  AgentBreadcrumb,
  CampaignBreadcrumb,
  CampaignsCustomerBreadcrumb,
  CustomerBreadcrumb,
  JobBreadcrumb,
  MenuItemBreadcrumb,
  MetricBreadcrumb,
  ProviderBreadcrumb
} from './containers';
import { BreadcrumbElipsis } from './styled';

export const breadcrumbRoutes = [
  {
    path: routes.PRIORITY_TASKS,
    breadcrumb: MenuItemBreadcrumb
  },
  {
    path: routes.SERVICE,
    breadcrumb: MenuItemBreadcrumb
  },
  {
    path: routes.PAYMENTS,
    breadcrumb: MenuItemBreadcrumb
  },
  {
    path: routes.INVOICE_PENDING,
    breadcrumb: MenuItemBreadcrumb
  },
  {
    path: `${routes.SERVICE}/:job_id`,
    breadcrumb: JobBreadcrumb
  },
  {
    path: `${routes.CUSTOMER}/:customer_id${routes.SERVICE}/:job_id`,
    breadcrumb: JobBreadcrumb
  },
  {
    path: `${routes.PROVIDER}/:provider_id${routes.SERVICE}/:job_id`,
    breadcrumb: JobBreadcrumb
  },
  {
    path: routes.CUSTOMER,
    breadcrumb: MenuItemBreadcrumb
  },
  {
    path: `${routes.CUSTOMER}/:customer_id`,
    breadcrumb: CustomerBreadcrumb
  },
  {
    path: `${routes.SERVICE}/:job_id${routes.CUSTOMER}/:customer_id`,
    breadcrumb: CustomerBreadcrumb
  },
  {
    path: routes.PROVIDER,
    breadcrumb: MenuItemBreadcrumb
  },
  {
    path: `${routes.PROVIDER}/:provider_id`,
    breadcrumb: ProviderBreadcrumb
  },
  {
    path: `${routes.SERVICE}/:job_id${routes.PROVIDER}/:provider_id`,
    breadcrumb: ProviderBreadcrumb
  },
  {
    path: routes.AGENT,
    breadcrumb: MenuItemBreadcrumb
  },
  {
    path: `${routes.AGENT}/:agent_id`,
    breadcrumb: AgentBreadcrumb
  },
  // Analytics tab disabled
  // {
  //   path: routes.ANALYTICS,
  //   breadcrumb: MenuItemBreadcrumb
  // },
  {
    path: routes.PROMO_CODES,
    breadcrumb: MenuItemBreadcrumb
  },
  {
    path: routes.PROMO_CODES_CUSTOMERS,
    breadcrumb: CampaignsCustomerBreadcrumb
  },
  {
    path: routes.CAMPAIGNS,
    breadcrumb: MenuItemBreadcrumb
  },
  {
    path: `${routes.CAMPAIGNS}/:campaign_id`,
    breadcrumb: CampaignBreadcrumb
  },
  {
    path: routes.CAMPAIGN_CREATE,
    breadcrumb: ({ location: { search } }) => (
      <Breadcrumb
        isActive
        pathname={routes.CAMPAIGN_CREATE}
        breadcrumbName={search === '?is_preview=true' ? 'Review Campaign Before Creation' : 'Create New Campaign'}
      />
    )
  },
  {
    path: `${routes.CAMPAIGNS}/:campaign_id/edit`,
    breadcrumb: ({ location: { search } }) => (
      <BreadcrumbElipsis active>
        <Breadcrumb
          isActive
          pathname={`${routes.CAMPAIGNS}/:campaign_id/edit`}
          breadcrumbName={search === '?is_preview=true' ? 'Review Campaign Before Saving' : 'Edit'}
        />
      </BreadcrumbElipsis>
    )
  },
  {
    path: routes.PROMO_CODES_CUSTOMERS,
    breadcrumb: CampaignsCustomerBreadcrumb
  },
  {
    path: routes.ANALYTICS_JOBS,
    breadcrumb: MetricBreadcrumb
  },
  {
    path: routes.COMMUNICATION,
    breadcrumb: MenuItemBreadcrumb
  }
];
