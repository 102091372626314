import styled from 'styled-components';
import styles from 'constants/styles';

export const HighlitedColumn = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.HIGHLIGHTED_ROW};
  ${({ theme }) => !theme.darkMode && `border-bottom: 1px solid ${theme.colors.LIGHT_GRAY};`}
  padding: 18px 0 0;
`;

export const LoadingContainer = styled.div`
  display: flex;
  flex: 1;
  padding: 50px;
  justify-content: center;
  position: relative;
  height: calc(100vh - ${styles.layout.headerHeight}px);
`;

export const ServiceInfoSection = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const labelStyle = {
  color: styles.colors.GRAY,
  fontSize: '12px',
  fontWeight: 500,
  lineHeight: 1.25,
  marginBottom: '5px',
  padding: '0 24px'
};

export const FieldTitle = styled.div`
  color: ${({ theme }) => theme.colors.GRAY};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 6px;
`;

export const Links = styled.div`
  padding: 10px 24px 20px 24px;
`;

export const wrapperStyle = {
  padding: '0 24px 10px 24px'
};

export const mediaStyle = { width: 164, height: 88 };
export const mediaWrapperStyle = { marginRight: 12, borderRadius: 4 };

export const placeholderStyle = { margin: '0 0 10px 24px' };
