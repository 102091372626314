import { call, takeEvery, put } from 'redux-saga/effects';

import { apiPageSizes } from 'common/enums/queryParams';
import api_constants from 'constants/api';
import actionTypes from 'constants/actionTypes';
import Api from 'services/ApiService';

function* getCustomerReferrals({ payload }) {
  const { page = 1, userId } = payload;

  try {
    const response = yield call(
      Api.amplifyGet,
      `${api_constants.REFERRAL}?page=${page}&source_user_id=${userId}&order_by=-source_user_id&target_group_id=1`
    );

    yield put({
      type: actionTypes.GET_CUSTOMER_REFERRALS_SUCCESS,
      payload: {
        count: response.count,
        list: response.results,
        page,
        user_id: userId
      }
    });
  } catch (err) {
    yield put({
      type: actionTypes.GET_CUSTOMER_REFERRALS_ERROR,
      payload: {
        user_id: userId
      }
    });
  }
}

export default function* watchGetCustomerReferrals() {
  yield takeEvery(actionTypes.GET_CUSTOMER_REFERRALS_FETCH, getCustomerReferrals);
}
