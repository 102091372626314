import { call, put, takeEvery } from 'redux-saga/effects';

import { filterMessagesWithLinksPreview } from 'common/helpers/chat';
import api_constants from 'constants/api';
import actionTypes from 'constants/actionTypes';
import Api from 'services/ApiService';

function* getLinkPreviews(action) {
  const {
    payload: { messageList, userId },
    type
  } = action;
  try {
    const messagesLinks = filterMessagesWithLinksPreview(messageList);
    for (const link_obj of messagesLinks) {
      const linkData = yield call(Api.amplifyPost, api_constants.PREVIEW, {
        url: link_obj.preview_url
      });

      if (linkData) {
        const updatedPreview = {
          ...link_obj,
          preview_title: linkData.siteName,
          preview_header: linkData.header,
          preview_description: linkData.description,
          preview_image_url: linkData.image_url
        };

        if (type === actionTypes.GET_TASK_LINK_PREVIEWS_FETCH) {
          yield put({
            type: actionTypes.GET_TASK_LINK_PREVIEWS_SUCCESS,
            payload: updatedPreview
          });
        } else {
          yield put({
            type: actionTypes.GET_SUPPORT_LINK_PREVIEWS_SUCCESS,
            payload: {
              updatedPreview,
              userId
            }
          });
        }
      }
    }
  } catch (err) {
    console.log(err);
  }
}

export default function* watchGetLinkPreviews() {
  yield takeEvery(
    [actionTypes.GET_TASK_LINK_PREVIEWS_FETCH, actionTypes.GET_SUPPORT_LINK_PREVIEWS_FETCH],
    getLinkPreviews
  );
}
