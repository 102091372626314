import React from 'react';
import { cellTypes } from 'common/enums/table';
import dates from 'constants/dates';
import styles from 'constants/styles';
import { Icon } from 'components';
import { formatters } from 'utils';
import { referralTable, groupToString } from '../enums';

export const getColumns = ({ onDownloadCSV }) => {
  return [
    {
      title: referralTable.DOWNLOAD_CSV_BUTTON.TITLE,
      accessor: referralTable.DOWNLOAD_CSV_BUTTON.FILTER_NAME,
      hasSorting: false,
      onDownloadCSV,
      Header: () => (
        <Icon name="download" size={14} backgroundSize={24} onClick={onDownloadCSV} color={styles.colors.WHITE} />
      )
    },
    {
      id: referralTable.SOURCE_USER_ID.FILTER_NAME,
      title: referralTable.SOURCE_USER_ID.TITLE,
      cellType: cellTypes.TEXT,
      minWidth: 105,
      accessor: ({ source_user_id }) => ({
        hasHighlight: true,
        text: String(source_user_id)
      })
    },

    {
      id: referralTable.SOURCE_USER_NAME.FILTER_NAME,
      title: referralTable.SOURCE_USER_NAME.TITLE,
      cellType: cellTypes.USER_NAME,
      minWidth: 120,
      accessor: ({ source_full_name, source_pro_stage }) => ({
        hasHighlight: true,
        given_name: source_full_name,
        pro_stage: source_pro_stage
      })
    },
    {
      id: referralTable.SOURCE_USER_GROUP_ID.FILTER_NAME,
      title: referralTable.SOURCE_USER_GROUP_ID.TITLE,
      cellType: cellTypes.TEXT,
      accessor: ({ source_group_id }) => ({
        text: groupToString[source_group_id]
      })
    },
    {
      id: referralTable.TARGET_USER_ID.FILTER_NAME,
      title: referralTable.TARGET_USER_ID.TITLE,
      cellType: cellTypes.TEXT,
      minWidth: 105,
      accessor: ({ target_user_id }) => ({
        hasHighlight: true,
        text: String(target_user_id)
      })
    },
    {
      id: referralTable.TARGET_USER_NAME.FILTER_NAME,
      title: referralTable.TARGET_USER_NAME.TITLE,
      cellType: cellTypes.USER_NAME,
      minWidth: 170,
      accessor: ({ target_full_name, target_pro_stage }) => ({
        hasHighlight: true,
        given_name: target_full_name,
        pro_stage: target_pro_stage
      })
    },
    {
      id: referralTable.PHONE_NUMBER.FILTER_NAME,
      title: referralTable.PHONE_NUMBER.TITLE,
      cellType: cellTypes.TEXT,
      minWidth: 162,
      accessor: ({ target_phone_number }) => ({
        hasHighlight: true,
        highlightType: 'phone_number',
        text: target_phone_number
      })
    },
    {
      id: referralTable.EMAIL.FILTER_NAME,
      title: referralTable.EMAIL.TITLE,
      cellType: cellTypes.TEXT,
      minWidth: 150,
      accessor: ({ target_email }) => ({
        text: target_email
      })
    },
    {
      id: referralTable.DATETIME_CREATED.FILTER_NAME,
      title: referralTable.DATETIME_CREATED.TITLE,
      cellType: cellTypes.RICH_TEXT,
      minWidth: 110,
      accessor: ({ target_date_created }) => ({
        text: formatters.dateWithTimezone(target_date_created, dates.DATETIME_FULL_YEAR_SECONDS)
      })
    },
    {
      id: referralTable.TARGET_JOB_COUNT_ACTIVE.FILTER_NAME,
      title: referralTable.TARGET_JOB_COUNT_ACTIVE.TITLE,
      cellType: cellTypes.TEXT,
      hasSorting: false,
      accessor: ({ count_service_active = 0 }) => ({
        ...(count_service_active ? {} : { textColor: styles.colors.LIGHT_GRAY }),
        text: String(count_service_active)
      })
    },
    {
      id: referralTable.TARGET_JOB_COUNT_COMPLETED.FILTER_NAME,
      title: referralTable.TARGET_JOB_COUNT_COMPLETED.TITLE,
      cellType: cellTypes.TEXT,
      hasSorting: false,
      accessor: ({ count_service_completed = 0 }) => ({
        ...(count_service_completed ? {} : { textColor: styles.colors.LIGHT_GRAY }),
        text: String(count_service_completed)
      })
    }
  ];
};
