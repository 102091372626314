import { call, takeLatest, put, all } from 'redux-saga/effects';

import api_constants from 'constants/api';
import actionTypes from 'constants/actionTypes';
import Api from 'services/ApiService';
import uploadMedia from 'services/media/UploadMedia';
import { refreshTaskInfo } from 'store/actions';

function* mapAttachmentToMediaList(attachments = []) {
  const existedAttachments = attachments
    .filter((attachment) => Boolean(attachment.media_id))
    .map((attachment) => attachment.media_id);
  const newSelectedAttachments = attachments.filter((attachment) => attachment.media_id === undefined);

  const uploadedAttachments = yield all(
    newSelectedAttachments.map((attachment) =>
      uploadMedia({
        file: attachment.file,
        mime_type: attachment.file.type,
        sub_folder: attachment.sub_folder
      })
    )
  );

  return [...existedAttachments, ...uploadedAttachments];
}

function* updateRequest(action) {
  const {
    payload: { id, onRequestProcessed, attachments, ...serviceData }
  } = action;
  try {
    const mediaList = attachments && attachments.length ? yield call(mapAttachmentToMediaList, attachments) : [];
    const uploadedMediaList = mediaList.filter((media) => media !== null);
    let requestData = {
      id,
      ...serviceData
    };
    if (attachments || attachments === []) {
      requestData['media'] = uploadedMediaList;
    }
    const response = yield call(Api.amplifyPut, `${api_constants.SERVICE}/${id}`, requestData);

    if (!response?.id) {
      throw new Error(response?.error_message);
    }

    yield put(refreshTaskInfo({ task_id: response.id, silent: true }));
    yield put({
      type: actionTypes.SIGN_SERVICE_IMAGE_URLS_FETCH,
      payload: { serviceId: response.id, media: uploadedMediaList }
    });
    yield put({
      type: actionTypes.UPDATE_REQUEST_SUCCESS
    });
    onRequestProcessed && onRequestProcessed(true, response.id);
  } catch (err) {
    yield put({
      type: actionTypes.UPDATE_REQUEST_ERROR,
      payload: err?.message
    });
    onRequestProcessed && onRequestProcessed(false);
    console.log(err);
  }
}

export default function* watchUpdateRequest() {
  yield takeLatest(actionTypes.UPDATE_REQUEST_FETCH, updateRequest);
}
