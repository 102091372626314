import React, { useState } from 'react';

import Papa from 'papaparse';
import { useRef, useSelector } from 'hooks';
import { HookForm, Icon, IdsInput } from 'components';

import { selectProviderAreaList } from 'store/reducers/provider';

import { cohortTypes } from 'common/enums/campaigns';
import { generateServiceAreaListOptions } from 'common/helpers/user';

import { UploadInput, UploadButton, UploadedFileName, UploadFile, UploadFileInfo, ErrorUpload } from './styled';
import { errorMessage } from './enums';

export const CohortDetails = ({
  csv_cohort_name,
  cohort_type,
  control,
  recipients_list,
  onSetIDs,
  onScrollToEnd,
  onSetUploadedCSVFile
}) => {
  const areaList = useSelector(selectProviderAreaList);
  const uploadInputRef = useRef(null);
  const [isValid, setIsValid] = useState(true);

  const onUploadClick = () => uploadInputRef.current && uploadInputRef.current.click();

  const onUploadReportChange = (event) => {
    event.stopPropagation();
    event.preventDefault();
    const files = event.target.files;

    if (files && files.length > 0) {
      const uploadedFile = files[0];
      Papa.parse(uploadedFile, {
        complete: (res) => {
          const isFileValid =
            res.data[0]?.[0]?.toLowerCase() === 'id' && uploadedFile?.name.split('.')?.reverse()[0] === 'csv';
          if (isFileValid) {
            onSetUploadedCSVFile({
              file: uploadedFile,
              name: uploadedFile?.name
            });
            setIsValid(true);
          } else {
            setIsValid(false);
          }
        }
      });
    }
  };

  const handleRemoveFile = () => {
    setIsValid(true);
    onSetUploadedCSVFile();
  };

  if (String(cohort_type) === String(cohortTypes.RECIPIENTS_LIST)) {
    return <IdsInput values={recipients_list} onChange={onSetIDs} />;
  }

  if (String(cohort_type) === String(cohortTypes.CSV_FILE)) {
    return (
      <>
        <UploadFile>
          <UploadInput
            type="file"
            accept=".csv"
            ref={uploadInputRef}
            onChange={onUploadReportChange}
            label="Upload CSV"
          />
          <UploadButton onClick={onUploadClick}>Upload CSV</UploadButton>
          {csv_cohort_name &&
            (isValid ? (
              <>
                <UploadedFileName>{csv_cohort_name}</UploadedFileName>
                <Icon name="closeFill" size={16} clickable onClick={handleRemoveFile} />
              </>
            ) : (
              <ErrorUpload>* failed file upload. Please check the file or try to upload the file once more</ErrorUpload>
            ))}
        </UploadFile>
        <UploadFileInfo>
          <div>*</div>
          <div>
            <div>- {errorMessage.CSV_FORMAT}</div>
            <div>- {errorMessage.ID_COLUMN_NAME}</div>
          </div>
        </UploadFileInfo>
      </>
    );
  }

  if (String(cohort_type) === String(cohortTypes.WORKING_AREA)) {
    return (
      <HookForm.SelectMultiple
        name="execution.service_area_id_list"
        control={control}
        marginLeft={0}
        placeholder="Select working area"
        options={generateServiceAreaListOptions(areaList)}
        onMenuOpen={onScrollToEnd}
        // props for portaling since it placed in the bottom of the page and influence size of entire container
        // known issue: when scrolling page it jumps when user hover dropdown
        menuPortalTarget={document.body}
        menuPosition="fixed"
        portalPlacement="auto"
      />
    );
  }
  return null;
};
