import React from 'react';

import { useDispatch, useSelector, useRouter, useState } from 'hooks';
import modalTypes from 'constants/modalTypes';
import routes from 'constants/routes';
import { closeModal } from 'store/actions';
import { selectModalData } from 'store/reducers/modal';
import { CloseButton, Modal } from 'components';

import { AssignProviderContainer } from './containers';
import { AssignProviderConfirmation, AssignProviderResult } from './modals';
import { CloseButtonWrapper, ContentWrapper } from './styled';
import ConfirmWorkingArea from '../ConfirmWorkingArea/ConfirmWorkingArea';

const AssignProvider = () => {
  const dispatch = useDispatch();
  const { navigate } = useRouter();
  const { jobId, serviceArea } = useSelector(selectModalData);
  const [confirmationModal, setConfirmationModal] = useState({ visible: false, modalData: null });

  const [resultModal, setResultModal] = useState({ visible: false, modalData: null });

  const handleCloseAssignModal = () => dispatch(closeModal(modalTypes.ASSIGN_PROVIDER_MAP));
  const handleCloseAssignConfirmation = () => setConfirmationModal({ visible: false, modalData: null });
  const handleCloseResultModal = () => {
    setResultModal({ visible: false, modalData: null });
    dispatch(closeModal(modalTypes.ASSIGN_PROVIDER_RESULT_MAP));
  };

  const handleOpenResultModal = (modalData) => setResultModal({ visible: true, modalData });
  const handleTaskNavigate = () => {
    handleCloseAssignModal();
    const route = `${routes.SERVICE}/${jobId}`;
    navigate(route);
  };
  const isSameWorkingArea = confirmationModal?.modalData?.provider?.serviceArea?.some(
    ({ id }) => id === serviceArea?.id
  );

  return (
    <>
      <Modal
        borderRadius="0 0 0 0"
        modalType={modalTypes.ASSIGN_PROVIDER_MAP}
        hideModal={handleCloseAssignModal}
        overflowMode="clip"
        padding="0"
        marginLeft={0}
        marginRight={0}
        isFullscreen>
        <ContentWrapper>
          <CloseButtonWrapper>
            <CloseButton onClick={handleCloseAssignModal} iconSize={9} />
          </CloseButtonWrapper>
          <AssignProviderContainer jobId={jobId} onSetConfirmationModal={setConfirmationModal} />
        </ContentWrapper>
      </Modal>
      {isSameWorkingArea ? (
        <AssignProviderConfirmation
          modalData={confirmationModal.modalData}
          jobId={jobId}
          visible={confirmationModal.visible}
          onCloseModal={handleCloseAssignConfirmation}
          onOpenResultModal={handleOpenResultModal}
        />
      ) : (
        <ConfirmWorkingArea
          modalData={confirmationModal.modalData}
          jobId={jobId}
          visible={confirmationModal.visible}
          onCloseModal={handleCloseAssignConfirmation}
          onOpenResultModal={handleOpenResultModal}
          serviceArea={serviceArea}
        />
      )}

      <AssignProviderResult
        modalData={resultModal.modalData}
        visible={resultModal.visible}
        onCloseModal={handleCloseResultModal}
        onProceedModal={handleTaskNavigate}
      />
    </>
  );
};

export default AssignProvider;
