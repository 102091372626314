import { useMemo } from 'hooks';

import { findMenuItem, findNavigationTab } from 'common/helpers/navigation';
import { getDefaultNavigationParams } from 'common/helpers/queryParams';
import { topNavigationTabs } from 'common/enums/navigation';
import { defaultQueryParams } from 'common/enums/queryParams';
import { useEffect, useCallback, useDispatch, useReloadTable, useRouter, useSelector } from 'hooks';

import { changeHasGoBackButton, setActiveMenuItem } from 'store/actions/navigation';
import { selectActiveMenuItem } from 'store/reducers/navigation';

/***
 * checks if active menu item matches route
 */
const useActiveMenuItem = ({ init } = {}) => {
  const { queryParams, pathname } = useRouter();
  const filters = queryParams.getAll();
  const { handleReloadTable } = useReloadTable();
  const dispatch = useDispatch();
  const activeMenuItem = useSelector(selectActiveMenuItem);

  const findTab = useCallback(
    (menuItemType) => findNavigationTab({ menuItemType, tabId: filters.tab_id }),
    [filters.tab_id]
  );

  const rootPathname = useMemo(() => {
    const splittedPathname = pathname.split('/').filter(Boolean);
    const pathnameRoot = splittedPathname[0] || '';
    const isNestedPath = splittedPathname.length > 1;
    const isNestedPathParamId = isNestedPath && Boolean(Number(splittedPathname[1]));
    const pathnameRootNested = !isNestedPath || isNestedPathParamId ? '' : `/${splittedPathname[1]}`;
    return `/${pathnameRoot}${pathnameRootNested}`;
  }, [pathname]);

  const findNewNavigationParams = useCallback(() => {
    const splittedPathname = pathname.split('/').filter(Boolean);
    const pathnameRoot = splittedPathname[0] || '';
    const newMenuItem = findMenuItem({ pathname: `/${pathnameRoot}`, type: filters.menu_item });
    const newTab = findTab(newMenuItem?.TYPE);
    const isNestedPath = splittedPathname.length > 1;
    const newPathname = isNestedPath ? pathname : null;
    const isNestedPathParamId = isNestedPath && Boolean(Number(splittedPathname[1]));

    return {
      isNestedPath,
      isNestedPathParamId,
      newPathname,
      newTab,
      newMenuItem
    };
  }, [pathname, filters.menu_item, findTab]);

  /**
   * initial navigation params setting
   */
  useEffect(() => {
    if (!init || filters.menu_item || activeMenuItem?.TYPE) {
      return;
    }

    const { isNestedPathParamId, newMenuItem } = findNewNavigationParams();
    const navigationFilters = getDefaultNavigationParams({ menuItem: newMenuItem });

    if (newMenuItem && !isNestedPathParamId) {
      queryParams.setAll(navigationFilters);
      return;
    }

    // Address params set up is disabled
    if (isNestedPathParamId) {
      dispatch(setActiveMenuItem(newMenuItem));
      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [init, filters.menu_item, pathname, queryParams, activeMenuItem.TYPE]);

  const handleMenuItemClick = useCallback(
    ({ menuItem, search }) =>
      () => {
        dispatch(changeHasGoBackButton(false));
        dispatch(setActiveMenuItem(menuItem));

        // reloading of information on menu item change handles by useEffect
        // this is for reloading information on the active menu item click
        if (activeMenuItem.TYPE === menuItem.TYPE && filters.menu_item === menuItem.TYPE) {
          const tab = topNavigationTabs[menuItem.TYPE]?.DEFAULT || {};
          const sortingParams = {
            ...defaultQueryParams,
            ...(menuItem?.SORTING_PARAMS || {}),
            ...(tab?.SORTING_PARAMS || {}),
            page: 1
          };

          handleReloadTable({
            newRequestFilters: { page: 1, search: '' },
            newMenuItem: menuItem,
            newNavigationFilters: sortingParams,
            resetCustomFilters: true,
            locationSearch: search
          });
        }
      },
    [activeMenuItem.TYPE, dispatch, filters.menu_item, handleReloadTable]
  );

  /**
   * changes active menu item redux state on menu_item query params change
   * reloads info on menu_item param change
   */
  useEffect(() => {
    // No need to reload table if no menu_item set
    if (!init || !filters.menu_item) {
      return;
    }

    const { newPathname, newTab, newMenuItem } = findNewNavigationParams();

    // when page reloads with browser button
    if (activeMenuItem.TYPE === filters.menu_item) {
      handleReloadTable({
        newPathname,
        hasSavingQueryParams: !newPathname,
        newTab,
        newMenuItem
      });
      return;
    }

    if (newMenuItem) {
      dispatch(setActiveMenuItem(newMenuItem));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeMenuItem.TYPE, filters.menu_item, init, rootPathname]);

  return {
    activeMenuItem,
    handleMenuItemClick
  };
};

export default useActiveMenuItem;
