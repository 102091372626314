import React from 'react';
import { connect } from 'react-redux';
import { closeModal, duplicateService } from 'store/actions';
import { selectModalData } from 'store/reducers/modal';
import { selectUpdateTaskStatusLoading } from 'store/reducers/service';

import modalTypes from 'constants/modalTypes';

import { ModalConfirmation } from 'components';
import { ModalText, BoldTextWrapper } from './styled';

const DuplicateTask = ({ closeModal, duplicateServiceLoading, modalData: { task_id } = {}, duplicateService }) => {
  const onDuplicateTask = () => {
    if (!task_id) {
      return;
    }
    duplicateService(task_id);
  };

  const onModalClose = () => {
    closeModal(modalTypes.DUPLICATE_TASK);
  };

  return (
    <ModalConfirmation
      modalType={modalTypes.DUPLICATE_TASK}
      padding="40px 30px"
      title={`Please confirm duplication of the job`}
      text={
        <ModalText>
          <BoldTextWrapper>HO won't be notified about duplicated job</BoldTextWrapper>
        </ModalText>
      }
      hideModal={onModalClose}
      primaryButton={{
        disabled: duplicateServiceLoading,
        onClick: onModalClose
      }}
      secondaryButton={{
        title: 'Confirm',
        loading: duplicateServiceLoading,
        disabled: duplicateServiceLoading,
        onClick: onDuplicateTask
      }}
    />
  );
};

DuplicateTask.defaultProps = {
  serviceInstanceLoading: false
};

const mapStateToProps = (state) => ({
  modalData: selectModalData(state),
  duplicateServiceLoading: selectUpdateTaskStatusLoading(state)
});

const mapDispatchToProps = {
  closeModal,
  duplicateService
};

export default connect(mapStateToProps, mapDispatchToProps)(DuplicateTask);
