import styled from 'styled-components';
import styles from 'constants/styles';

export const ContentColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 24px 24px;
  border-left: 1px solid ${({ theme }) => theme.colors.DARK_2};
  min-width: 360px;
`;

export const HorizontalLine = styled.div`
  margin: 27px 0 11px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.DARK_GREY_2};
  ${({ hasDisabledOpacity }) => hasDisabledOpacity && `opacity: 0.3;`}
`;

export const RightSectionFlexRow = styled.div`
  display: flex;
  padding: 13px 0 5px;
  gap: 20px;
  &:first-child {
    padding: 18px 0 5px;
  }
`;

export const dropdownStyles = {
  background: styles.colors.DARK_GREY_3
};
