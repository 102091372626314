import styled from 'styled-components';

export const TooltipText = styled.div`
  font-size: 10px;
  color: ${({ theme }) => theme.colors.DARK_1};
  line-height: 1.5;
  ${({ marginLeft }) => marginLeft && `margin-left: ${marginLeft}px`};
  &:before {
    content: '';
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.DARK_1};
    display: inline-block;
    margin: 0 6px 0 4px;
    transform: translateY(-50%);
  }
`;
