import actionTypes from 'constants/actionTypes';
import { chatMessageTransformer } from 'utils';

export const getSupportMessageList = (payload) => ({
  type: actionTypes.GET_SUPPORT_MESSAGE_LIST_FETCH,
  payload
});

export const sendSupportMessage = (data) => ({
  type: actionTypes.CREATE_SUPPORT_MESSAGE_FETCH,
  payload: {
    message: data,
    messageTransformed: chatMessageTransformer(data)
  }
});

export const getSupportMessageById = (payload) => ({
  type: actionTypes.GET_SUPPORT_MESSAGE_BY_ID_FETCH,
  payload
});

export const resetUserChatUnreadMessagesMark = (payload) => ({
  type: actionTypes.RESET_USER_UNREAD_MESSAGES_MARK,
  payload
});

export const resetUserChatUnreadMessagesDivider = (payload) => ({
  type: actionTypes.RESET_USER_UNREAD_MESSAGES_DIVIDER,
  payload
});

export const deleteSupportMessage = (payload) => ({
  type: actionTypes.DELETE_SUPPORT_MESSAGE_FETCH,
  payload
});

export const updateSupportMessage = (payload) => ({
  type: actionTypes.UPDATE_SUPPORT_MESSAGE_FETCH,
  payload
});
