import { call, takeLatest, put } from 'redux-saga/effects';
import { Storage } from 'aws-amplify';
import api_constants from 'constants/api';
import actionTypes from 'constants/actionTypes';
import Api from 'services/ApiService';

function* getCustomerInspectionReport({ payload }) {
  const { user_id } = payload;
  const response = yield call(Api.amplifyGet, `${api_constants.INSPECTION_REPORT}?user_id=${user_id}`);
  const inner_path = response.results[0]?.media[0]?.media?.inner_path || '';
  if (response && inner_path.length > 0) {
    yield put({
      type: actionTypes.GET_CUSTOMER_INSPECTION_REPORT_SUCCESS,
      payload: payload.user_id
    });
    const signedUrl = yield call(Storage.get, inner_path);
    if (signedUrl) {
      payload.onSuccess(signedUrl);
    }
  } else {
    yield put({
      type: actionTypes.GET_CUSTOMER_INSPECTION_REPORT_ERROR
    });
  }
}

export default function* watchGetCustomerInspectionReport() {
  yield takeLatest(actionTypes.GET_CUSTOMER_INSPECTION_REPORT_FETCH, getCustomerInspectionReport);
}
