import { cellTypes } from 'common/enums/table';
import dates from 'constants/dates';
import { formatters, getOperationalAreaNameByID } from 'utils';

import { reviewTable } from '../enums';

export const getColumns = () => {
  return [
    {
      id: reviewTable.DATETIME_CREATED.FILTER_NAME,
      title: reviewTable.DATETIME_CREATED.TITLE,
      cellType: cellTypes.TEXT,
      hasSorting: true,
      minWidth: 90,
      accessor: ({ date_created }) => ({
        text: formatters.dateWithTimezone(date_created, dates.DATE_SHORT)
      })
    },
    {
      id: reviewTable.ID.FILTER_NAME,
      title: reviewTable.ID.TITLE,
      cellType: cellTypes.TEXT,
      width: 50,
      accessor: ({ id }) => {
        return {
          text: String(id),
          hasHighlight: true
        };
      }
    },
    {
      id: reviewTable.HO_GIVEN_NAME.FILTER_NAME,
      title: reviewTable.HO_GIVEN_NAME.TITLE,
      cellType: cellTypes.TEXT,
      minWidth: 150,
      accessor: ({ customer_given_name }) => {
        return {
          text: customer_given_name,
          hasHighlight: true
        };
      }
    },
    {
      id: reviewTable.HO_ID.FILTER_NAME,
      title: reviewTable.HO_ID.TITLE,
      cellType: cellTypes.TEXT,
      width: 100,
      minWidth: 100,
      accessor: ({ customer_id }) => {
        return {
          text: String(customer_id),
          hasHighlight: true
        };
      }
    },
    {
      id: reviewTable.PRO_GIVEN_NAME.FILTER_NAME,
      title: reviewTable.PRO_GIVEN_NAME.TITLE,
      minWidth: 150,
      cellType: cellTypes.USER_NAME,
      accessor: ({ provider_given_name, pro_stage }) => ({
        given_name: provider_given_name,
        pro_stage,
        hasHighlight: true
      })
    },
    {
      id: reviewTable.PRO_ID.FILTER_NAME,
      title: reviewTable.PRO_ID.TITLE,
      cellType: cellTypes.TEXT,
      width: 100,
      minWidth: 100,
      accessor: ({ provider_id }) => {
        return {
          text: String(provider_id),
          hasHighlight: true
        };
      }
    },
    {
      id: reviewTable.LOCATION.FILTER_NAME,
      title: reviewTable.LOCATION.TITLE,
      cellType: cellTypes.TEXT,
      hasSorting: false,
      width: 100,
      minWidth: 100,
      accessor: ({ operational_area_id }) => ({
        text: getOperationalAreaNameByID(operational_area_id),
        hasHighlight: true
      })
    },
    {
      id: reviewTable.WILL_WORK_AGAIN.FILTER_NAME,
      title: reviewTable.WILL_WORK_AGAIN.TITLE,
      width: 600,
      cellType: cellTypes.REVIEW,
      accessor: ({ will_work_again, description }) => {
        return {
          text: description,
          isEllipsis: true,
          isPositive: will_work_again
        };
      }
    }
  ];
};
