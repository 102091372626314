import styled from 'styled-components';

export const PaidBorder = styled.span`
  color: ${({ theme }) => theme.colors.INVOICE_TEXT};
  font-weight: 400;
  font-size: 12px;
  line-height: 1.33;
  border: 1px solid ${({ theme }) => theme.colors.DARK_4};
  padding 1px 8px;
  display: flex;
  align-items: center;
  width: 80px;
  border-radius: 30px;
`;

export const PaidTitle = styled.div`
  display: flex;
  flex: 1;
  padding: 0 0 0 8px;
  align-items: center;
  flex-wrap: wrap;
  span {
    word-break: break-all;
    white-space: pre;
  }
`;
