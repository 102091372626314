import styled from 'styled-components';
import styles from 'constants/styles';

export const DataRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 20px;
  background: ${({ theme }) => theme.colors.DARK_3};
`;

export const DataRecord = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${({ alignItems }) => (alignItems ? alignItems : 'flex-start')};
  margin: ${({ margin }) => (margin ? margin : '12px 0')};
  flex: ${({ flex }) => (flex ? flex : 1)};
`;

export const NotesWrapper = styled.div`
  background: ${({ theme }) => theme.colors.DARK_3};
  padding-top: 13px;
`;

export const Sections = styled.div`
  display: flex;
  width: 100%;
`;

export const ActionContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  border-left: 1px solid ${({ theme }) => theme.colors.DARK_2};
  overflow: hidden;
`;

export const LoadingContainer = styled.div`
  display: flex;
  padding: 50px;
  flex: 1;
  justify-content: center;
  position: relative;
  height: calc(100vh - ${styles.layout.headerHeight}px);
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  max-height: calc(100vh - ${styles.layout.headerHeight}px);
  overflow: overlay;
  min-width: 60%;
`;
