import React from 'react';
import { Icon } from 'components';
import { navigationTabs, sidebarMenuItems } from 'common/enums/navigation';
import { cellTypes } from 'common/enums/table';
import dates from 'constants/dates';
import styles from 'constants/styles';
import { formatters, formatDateShortTimeStampSmall } from 'utils';
import { getAuthorByType } from 'common/helpers/job';
import { invoiceTypes } from 'common/enums/invoice';
import { paymentTable } from '../enums';

export const getColumns = ({ tabId, menuItem, onDownloadCSV }) => {
  return [
    {
      title: paymentTable.DOWNLOAD_CSV_BUTTON.TITLE,
      accessor: paymentTable.DOWNLOAD_CSV_BUTTON.FILTER_NAME,
      hasSorting: false,
      onDownloadCSV,
      Header: () => (
        <Icon name="download" size={14} backgroundSize={24} onClick={onDownloadCSV} color={styles.colors.WHITE} />
      )
    },
    {
      id: paymentTable.JOB_ID.COLUMN_NAME,
      title: paymentTable.JOB_ID.TITLE,
      cellType: cellTypes.TEXT,
      minWidth: 70,
      accessor: ({ service_request_id }) => ({
        text: String(service_request_id),
        hasHighlight: true
      })
    },
    {
      id: paymentTable.HO_GIVEN_NAME.COLUMN_NAME,
      title: paymentTable.HO_GIVEN_NAME.TITLE,
      cellType: cellTypes.USER_NAME,
      minWidth: 120,
      accessor: ({ customer = {} }) => {
        return {
          given_name: customer.given_name,
          family_name: customer.family_name,
          stage: customer.stage,
          hasHighlight: true
        };
      }
    },
    {
      id: paymentTable.HO_ID.COLUMN_NAME,
      title: paymentTable.HO_ID.TITLE,
      cellType: cellTypes.TEXT,
      minWidth: 90,
      accessor: ({ customer_id }) => ({
        text: String(customer_id),
        hasHighlight: true
      })
    },

    {
      id: paymentTable.PRO_GIVEN_NAME.COLUMN_NAME,
      title: paymentTable.PRO_GIVEN_NAME.TITLE,
      cellType: cellTypes.USER_NAME,
      minWidth: 150,
      accessor: ({ provider = {} }) => ({
        given_name: provider.given_name,
        family_name: provider.family_name,
        pro_stage: provider.pro_stage,
        hasHighlight: true
      })
    },
    ...(menuItem === sidebarMenuItems.INVOICE_ADMIN_PENDING.TYPE
      ? [
          {
            id: paymentTable.PRO_ID.COLUMN_NAME,
            title: paymentTable.PRO_ID.TITLE,
            cellType: cellTypes.TEXT,
            minWidth: 120,
            accessor: ({ provider_id }) => ({
              text: String(provider_id),
              hasHighlight: true
            })
          }
        ]
      : []),
    {
      id: paymentTable.JOB_CATEGORY_NAME.COLUMN_NAME,
      title: paymentTable.JOB_CATEGORY_NAME.TITLE,
      cellType: cellTypes.TEXT,
      minWidth: 180,
      accessor: ({ service_name }) => ({
        text: service_name
      })
    },
    ...(menuItem === sidebarMenuItems.INVOICE_ADMIN_PENDING.TYPE
      ? [
          {
            id: paymentTable.DATETIME_CREATED.COLUMN_NAME,
            title: paymentTable.DATETIME_CREATED.TITLE,
            cellType: cellTypes.TEXT,
            minWidth: 150,
            accessor: ({ date_created }) => ({
              text: formatters.dateWithTimezone(date_created, dates.DATE)
            })
          }
        ]
      : [
          {
            id: paymentTable.DATETIME_PAID.COLUMN_NAME,
            title: paymentTable.DATETIME_PAID.TITLE,
            cellType: cellTypes.TEXT,
            minWidth: 150,
            accessor: ({ datetime_paid }) => ({
              text: datetime_paid ? formatters.dateWithTimezone(datetime_paid, dates.DATE) : '-'
            })
          }
        ]),
    ...([navigationTabs.ALL.TYPE].includes(tabId) || menuItem === sidebarMenuItems.INVOICE_ADMIN_PENDING.TYPE
      ? [
          {
            id: paymentTable.IS_FINAL_PAYMENT.COLUMN_NAME,
            title: paymentTable.IS_FINAL_PAYMENT.TITLE,
            cellType: cellTypes.TEXT,
            hasSorting: true,
            minWidth: 140,
            accessor: ({ is_final_payment }) => ({
              text: is_final_payment ? invoiceTypes.FINAL.TABLE_LABEL : invoiceTypes.PARTIAL.TABLE_LABEL
            })
          }
        ]
      : []),
    ...(menuItem === sidebarMenuItems.INVOICE_ADMIN_PENDING.TYPE
      ? [
          {
            id: paymentTable.AMOUNT_TOTAL_CHARGE.COLUMN_NAME,
            title: paymentTable.AMOUNT_TOTAL_CHARGE.TITLE,
            cellType: cellTypes.TEXT,
            hasSorting: true,
            minWidth: 150,
            accessor: ({ amount_total_charge }) => ({
              text: formatters.dollarCurrencyDB(amount_total_charge || 0)
            })
          }
        ]
      : [
          {
            id: paymentTable.AMOUNT_CUSTOMER_PAID.COLUMN_NAME,
            title: paymentTable.AMOUNT_CUSTOMER_PAID.TITLE,
            cellType: cellTypes.TEXT,
            hasSorting: true,
            minWidth: 150,
            accessor: ({ amount_customer_paid }) => ({
              text: formatters.dollarCurrencyDB(amount_customer_paid || 0)
            })
          }
        ]),
    {
      id: paymentTable.LAST_MESSAGE.COLUMN_NAME,
      hasSorting: false,
      title: paymentTable.LAST_MESSAGE.TITLE,
      cellType: cellTypes.RICH_TEXT,
      minWidth: 200,
      accessor: ({ last_message }) => {
        return {
          datetime: formatters.messageDate(last_message?.datetime_sent),
          text: last_message?.message,
          secondText: getAuthorByType({ author: last_message?.author_name, type: last_message?.author_type })
        };
      }
    },
    {
      id: paymentTable.LAST_NOTE.COLUMN_NAME,
      hasSorting: false,
      title: paymentTable.LAST_NOTE.TITLE,
      cellType: cellTypes.RICH_TEXT,
      minWidth: 200,
      accessor: ({ datetime_last_note, last_note }) => ({
        datetime: formatDateShortTimeStampSmall(datetime_last_note),
        text: last_note
      })
    }
  ];
};
