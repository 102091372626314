import React, { useEffect, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { useTheme, useDispatch, useSelector } from 'hooks';
import icon_close from 'static/images/close-icon.png';

import dates from 'constants/dates';
import styles from 'constants/styles';
import { formatters } from 'utils';
import { Chat as ChatComponent } from 'components';
import { MessageBubble } from 'components/Chat';

import { Icon, LoadingSpinner } from 'components';
import { Message } from './components';

import { getCommunicationSmsList, createCommunicationSms } from 'store/actions/communication';
import { selectSmsList } from 'store/reducers/communication';

import {
  Time,
  SystemMessageText,
  LoadingContainer,
  ChatWrapper,
  ChatPlaceholder,
  LoadingMessageAttachmentContainer
} from './styled';
import { debounce } from 'utils';

const sendButtonStyles = {
  height: 40,
  width: 40,
  borderRadius: '50%',
  alignItems: 'center',
  justifyContent: 'center'
};

const ChatContainer = ({ conversation, hasCustomerChat }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { list, loading, count } = useSelector((state) => selectSmsList(state, conversation?.user_id));

  const fetchMessageList = useCallback(
    // debounce is needed no to send the same request twice on chat opening
    debounce(({ page = 1, init, id_lt }) => {
      if (!conversation?.user_id) {
        return;
      }

      const getListArgs = {
        page,
        user_id: conversation.user_id,
        hasConversationBadgeUpdate: conversation.is_status_badge,
        conversation_id: conversation?.id,
        id_lt,
        init
      };

      dispatch(getCommunicationSmsList(getListArgs));
    }, 400),
    [conversation, dispatch]
  );

  useEffect(() => {
    if (conversation?.user_id) {
      fetchMessageList({ page: 1, init: true });
    }
  }, [conversation?.user_id]);

  useEffect(() => {
    // handles reloading of sms list when conversation updated on WS event
    if (conversation?.is_status_badge) {
      fetchMessageList({ page: 1, init: !list.length });
    }
  }, [conversation?.is_status_badge]);

  const onEndReached = () => {
    if (list.length >= count && !loading) {
      return;
    }

    fetchMessageList({
      id_lt: list[list.length - 1]._id
    });
  };

  const renderSend = ({ onClick, disabled, text }) => {
    const sendButtonBackground = {
      backgroundColor: !text ? styles.colors.DISABLED_RED : styles.colors.RED
    };
    return (
      <div onClick={onClick} disabled={!text} style={{ ...sendButtonStyles, ...sendButtonBackground, display: 'flex' }}>
        <Icon name="send" size={18} />
      </div>
    );
  };

  const handleMessageSend = (userMessages) => {
    if (!conversation?.user_id) {
      return;
    }

    const messageText = userMessages[0] && userMessages[0].text;
    const createdAt = userMessages[0] && userMessages[0].createdAt.toISOString().split('.')[0];
    if (messageText) {
      const newMessage = {
        id: uuidv4(),
        date_created: createdAt,
        message: messageText,
        user_id: conversation.user_id
      };

      dispatch(createCommunicationSms(newMessage));
    }
  };

  const renderMessageText = ({ message, isCurrentUser }) => {
    if (!message) {
      return null;
    }
    return <Message key={message._id} currentMessage={message} isCurrentUser={isCurrentUser} />;
  };

  const renderTime = ({ message }) => (
    <Time>
      {message.deliveryError || message.updateError ? (
        <Icon src={icon_close} size={10} backgroundSize={16} backgroundColor={styles.colors.RED} />
      ) : null}
      {formatters.dateUTC(message.createdAt, dates.TIME)}
    </Time>
  );

  const renderBubble = (props) => {
    const { isCurrentUser } = props;
    const incomingBubleColor = hasCustomerChat ? theme.colors.MESSAGE_HO : theme.colors.MESSAGE_PRO_AGENT;
    return (
      <MessageBubble
        {...props}
        color={isCurrentUser ? theme.colors.MESSAGE_OPS : incomingBubleColor}
        userID={null}
        hasChatActionsDisabled
      />
    );
  };

  const renderTicks = ({ _id, author_id }) => (
    <LoadingMessageAttachmentContainer>
      {!author_id && _id?.includes('-') && <LoadingSpinner />}
    </LoadingMessageAttachmentContainer>
  );

  const renderSystemMessage = ({ message }) => {
    return <SystemMessageText>{` ${message.text} `}</SystemMessageText>;
  };

  const renderEmptyMessage = () => {
    if (loading && !list.length) {
      return (
        <LoadingContainer>
          <LoadingSpinner text="Please wait" type="logo" />
        </LoadingContainer>
      );
    }
    if (!list.length) {
      return (
        <ChatPlaceholder>
          <Icon size={175} name="feather" />
          <span>Just leave your message here...</span>
        </ChatPlaceholder>
      );
    }
    return null;
  };

  const renderLoading = () => <span>Loading...</span>;

  return (
    <ChatWrapper>
      <ChatComponent
        currentUser={{ _id: 'support' }}
        isMessagesLoading={false}
        isInputToolbarDisabled={!conversation?.user_id}
        messages={list}
        keyExtractor={(item) => `${item._id}_${item.link_list_loaded}_${Boolean(item.thumb_image_url)}`}
        onMessagesEnd={onEndReached}
        placeholder="Type here ..."
        onMessageSend={handleMessageSend}
        maxLength={1600}
        overwriteComponents={{
          sendButton: renderSend,
          emptyMessageList: renderEmptyMessage,
          loadingIndicator: renderLoading,
          messageSystem: renderSystemMessage,
          messageBubble: renderBubble,
          messageText: renderMessageText,
          messageTime: renderTime,
          avatar: null,
          messageTicks: renderTicks
        }}
      />
    </ChatWrapper>
  );
};

export default ChatContainer;
