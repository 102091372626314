import styled from 'styled-components';

export const NoteFile = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin: 8px 20px 0 0;
`;

export const DateInput = styled.div`
  input {
    color: white !important;
  }
`;
