import { columnNames } from 'common/enums/sortings';

export const agentTable = {
  AVATAR: {
    TITLE: '',
    FILTER_NAME: columnNames.AVATAR
  },
  ID: {
    TITLE: 'User ID',
    FILTER_NAME: columnNames.ID
  },
  GIVEN_NAME: {
    TITLE: 'First Name',
    FILTER_NAME: columnNames.GIVEN_NAME
  },
  FAMILY_NAME: {
    TITLE: 'Last Name',
    FILTER_NAME: columnNames.FAMILY_NAME
  },
  PHONE_NUMBER: {
    TITLE: 'Phone number',
    FILTER_NAME: columnNames.PHONE_NUMBER
  },
  EMAIL: {
    TITLE: 'Email',
    FILTER_NAME: columnNames.EMAIL
  },
  COMPANY_NAME: {
    TITLE: 'Company',
    FILTER_NAME: columnNames.COMPANY_NAME
  },
  POSITION: {
    TITLE: 'Position',
    FILTER_NAME: columnNames.POSITION
  }
};
