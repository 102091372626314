import { call, takeLatest, put } from 'redux-saga/effects';

import errors from 'constants/errors';
import api_constants from 'constants/api';
import actionTypes from 'constants/actionTypes';
import Api from 'services/ApiService';
import modalTypes from 'constants/modalTypes';
import { getServiceInstance, openModal } from 'store/actions';

function* chatExtendOrClose({ payload: { service_request_id, action } }) {
  try {
    const response = yield call(Api.amplifyPost, api_constants.CHAT_EXTEND_OR_CLOSE, { service_request_id, action });

    if (response?.err?.message) {
      throw new Error(response?.err?.message);
    }

    yield put({
      type: actionTypes.CHAT_EXTEND_OR_CLOSE_SUCCESS
    });

    if (action === 'close') {
      yield put(
        openModal(modalTypes.RESULT, {
          title: 'The chat has been closed',
          text: `The job has been moved from the “Recently Completed“ tab to the “Jobs History“`
        })
      );
    } else {
      yield put(
        openModal(modalTypes.RESULT, {
          title: 'The chat has been Opened',
          text: `The job has been moved from the “Jobs History“ tab to the “Recently Completed“ tab`
        })
      );
    }
  } catch (err) {
    yield put({
      type: actionTypes.CHAT_EXTEND_OR_CLOSE_ERROR,
      payload: err?.message
    });
    yield put(
      openModal(modalTypes.ERROR, {
        errorMessage: err?.message || errors.DEFAULT
      })
    );
    console.log(err);
  } finally {
    yield put(getServiceInstance({ serviceId: service_request_id, silent: true }));
  }
}

export default function* watchChatExtendOrClose() {
  yield takeLatest(actionTypes.CHAT_EXTEND_OR_CLOSE_FETCH, chatExtendOrClose);
}
