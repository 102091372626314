import api_constants from 'constants/api';
import Api from 'services/ApiService';
import { formatSearchTerm } from 'common/helpers/queryParams';

export default function searchCustomersList({ search, is_test }) {
  if (search) {
    return Api.amplifyGet(
      `${api_constants.CUSTOMER}?page=1&search=${formatSearchTerm(search)}&is_full_active=1&is_cognito_active=1${
        is_test ? '' : '&is_test=0'
      }`
    )
      .then((response) => {
        if (response && response.results) {
          return response.results;
        }

        return [];
      })
      .catch((err) => console.log(err));
  }
  return null;
}
