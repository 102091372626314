import { call, takeLatest, put } from 'redux-saga/effects';
import api_constants from 'constants/api';
import errors from 'constants/errors';
import actionTypes from 'constants/actionTypes';
import Api from 'services/ApiService';
import { formatSearchTerm } from 'common/helpers/queryParams';

function* getCampaignsList({ payload }) {
  const { order_by, search, page, is_executed = null } = payload;
  try {
    const response = yield call(
      Api.amplifyGet,
      `${api_constants.CAMPAIGN}?page=${page}${order_by ? `&order_by=${order_by}` : ''}${
        search ? `&search=${formatSearchTerm(search)}` : ''
      }${is_executed !== null ? `&is_executed=${is_executed}` : ''}`
    );

    if (!(response || response.results)) {
      throw new Error(errors.DEFAULT);
    }

    yield put({
      type: actionTypes.GET_CAMPAIGNS_LIST_SUCCESS,
      payload: {
        page,
        totalCount: response.count,
        list: response.results
      }
    });
  } catch (err) {
    yield put({
      type: actionTypes.GET_CAMPAIGNS_LIST_ERROR
    });
  }
}

export default function* watchGetCampaignsList() {
  yield takeLatest(actionTypes.GET_CAMPAIGNS_LIST_FETCH, getCampaignsList);
}
