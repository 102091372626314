import styled from 'styled-components';

export const FlexRow = styled.div`
  display: flex;
  flex: 1;
  padding: 13px 0 5px;
  &:first-child {
    padding: 18px 0 5px;
  }
  &:last-child {
    padding: 28px 0 0;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  padding: 0 24px 22px 24px;
`;

export const Label = styled.label`
  font-weight: 600;
  font-size: 12px;
  line-height: 1.7;
  color: ${({ theme }) => theme.colors.DARK_GREY};
`;

export const Heading = styled.span`
  flex: 1;
  font-weight: bold;
  font-size: 24px;
  line-height: 1.2;
  color: ${({ theme }) => theme.colors.INVOICE_TEXT};
`;

export const Row = styled.div`
  display: flex;
  gap: 24px;
  margin-top: 20px;
  & > * {
    flex: auto;
    width: 100%;
  }
`;
