import React, { useEffect } from 'react';
import { Wrapper, Title, Subtitle, StyledLink } from './styled';

const NotFound = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Wrapper>
      <Title>404</Title>
      <Subtitle>We can't find this page</Subtitle>
      <StyledLink to="/">Try to start from the homepage</StyledLink>
    </Wrapper>
  );
};

export default NotFound;
