import { API } from 'aws-amplify';
// import store from 'store';
import { environments } from 'common/enums/app';
import { environment } from 'common/constants/app';

const apiEndpoint =
  environment === environments.LOCAL.TYPE ? environments.LOCAL.ENDPOINT : environments.PRODUCT.ENDPOINT;
// const apiEndpoint = 'https://staging.api.hellodobby.com/v0';
// const apiEndpoint = 'https://develop.api.hellodobby.com/v0';
// useLocalhost
// const apiEndpoint = 'http://localhost:5000'

const handleSessionError = () => {
  // Alert.alert(
  //     'Authentication error',
  //     'Your session has been experid, please sign in again.',
  //     [{ text: 'OK', onPress: () => {} }],
  //     { cancelable: false }
  // );
  // store.dispatch(signOut());
};

// const checkError = error => {
//     if (error.message && error.message.indexOf('401') !== -1) {
//         handleSessionError();
//     } else {
//         return error;
//     }
// };

const statusHelper = (response) => {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else if (response.status === 401) {
    handleSessionError();
  }
  return Promise.reject(new Error(response.statusText));
};

class Api {
  static get(url, params = {}) {
    return fetch(url, params)
      .then(statusHelper)
      .then((data) => {
        return data;
      })
      .catch((err) => {
        return err;
      });
  }

  static getHTML(url, params = {}) {
    return fetch(url, params)
      .then((response) => (response.ok ? response.text() : Promise.reject(new Error(response.statusText))))
      .then((data) => {
        return data;
      })
      .catch((err) => {
        return err;
      });
  }

  static apiGet(
    path,
    params = {
      mode: 'no-cors'
    }
  ) {
    return fetch(`${apiEndpoint}${path}`, params)
      .then((res) => statusHelper(res))
      .then((data) => {
        return data.json();
      })
      .catch((err) => {
        return { err };
      });
  }

  static apiPost(path, data, headers = { 'Content-Type': 'application/json' }) {
    const params = {
      method: 'POST',
      headers
    };
    if (data) {
      params.body = JSON.stringify(data);
    }
    return fetch(`${apiEndpoint}${path}`, params)
      .then((res) => statusHelper(res))
      .then((data) => {
        return data.json();
      })
      .catch((err) => {
        return { err };
      });
  }

  static apiPut(path, body, headers = { 'Content-Type': 'application/pdf' }) {
    return fetch(path, {
      method: 'PUT',
      headers,
      body
    })
      .then((res) => statusHelper(res))
      .then((data) => {
        return data;
      })
      .catch((err) => {
        return { err };
      });
  }

  static s3Put(path, body, headers = { 'Content-Type': 'application/octet-stream' }) {
    return fetch(path, {
      method: 'PUT',
      headers,
      body
    })
      .then((res) => statusHelper(res))
      .then((data) => {
        return data;
      })
      .catch((err) => {
        return { err };
      });
  }

  static amplifyGet(path, options = {}) {
    return API.get('mainApi', path, options)
      .then((data) => {
        return data;
      })
      .catch((err) => {
        return { err };
      });
  }

  static amplifyPost(path, body) {
    return API.post('mainApi', path, {
      body
    })
      .then((data) => {
        return data;
      })
      .catch((err) => {
        return { err };
      });
  }

  static amplifyPut(path, body) {
    return API.put('mainApi', path, { body })
      .then((data) => {
        return data;
      })
      .catch((err) => {
        return { err };
      });
  }

  static amplifyDel(path, body) {
    return API.del('mainApi', path, { body })
      .then((data) => {
        return data;
      })
      .catch((err) => {
        return { err };
      });
  }
}

export default Api;
