import { call, takeLatest, put } from 'redux-saga/effects';

import api_constants from 'constants/api';
import errors from 'constants/errors';
import actionTypes from 'constants/actionTypes';
import modalTypes from 'constants/modalTypes';
import { openModal } from 'store/actions';
import Api from 'services/ApiService';

function* createReward({ payload }) {
  const { id, ...data } = payload;
  const { promo_code_id } = data;

  try {
    const response = yield call(Api.amplifyPost, api_constants.REWARD, data);

    // check amplify error
    if (!response || response.error_message || response.err) {
      throw new Error(response?.err);
    }

    yield put({
      type: actionTypes.CREATE_REWARD_SUCCESS,
      payload: {
        data: response,
        tmpId: id
      }
    });

    yield put(
      openModal(modalTypes.RESULT, {
        title: `Reward "${promo_code_id}" has been added`
      })
    );
  } catch (err) {
    yield put({
      type: actionTypes.CREATE_REWARD_ERROR,
      payload: { tmpId: id }
    });

    yield put(
      openModal(modalTypes.ERROR, {
        errorMessage: err?.message || err?.name || errors.DEFAULT
      })
    );
  }
}

export default function* watchCreateReward() {
  yield takeLatest(actionTypes.CREATE_REWARD_FETCH, createReward);
}
