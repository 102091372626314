import React from 'react';
import moment from 'moment-timezone';

import dates from 'constants/dates';
import { paymentInvoiceStatuses, paymentMethods } from 'common/enums/invoice';
import { useTheme } from 'hooks';
import { formatters } from 'utils';

import { getFormattedPercent, getPaymentMethod } from '../helpers/invoice';
import { HistoryInvoiceRow, Payout, SummaryTitleTooltip } from './';
import { HeadingTotalCell, MainTotalHeading, SummaryHeading, FlexRow, customFlexRowCSS } from './styled';

export const InvoiceSummary = ({
  invoice: {
    amount_total_payout,
    amount_customer_reward,
    amount_commission,
    amount_total_charge,
    amount_credit_adjustment,
    amount_dobby_fee,
    amount_stripe_fee,
    amount_tip,
    datetime_paid,
    fail_commission_value,
    is_final_payment,
    is_paid,
    is_moderated,
    is_quotation,
    invoice_status,
    percent_stripe_fee,
    percent_commission,
    type_of_payment,
    amount_dobby_cash_bonus,
    last4,
    is_transfered,
    payment_history
  },
  amountReward,
  isDeposit,
  isMarginFee,
  isOfflinePaid,
  onPayout
}) => {
  const theme = useTheme();

  const discount = (parseInt(parseFloat(amount_customer_reward || 0) * 100) || amountReward) / 100;
  // For old invoices
  const commissionWithDiscount = !isDeposit
    ? amount_commission - discount
    : discount <= amount_total_payout
    ? 0 - discount
    : 0 - amount_total_payout;
  const dobbyMargin = isMarginFee
    ? amount_dobby_fee - discount - (fail_commission_value || 0)
    : commissionWithDiscount - (fail_commission_value || 0);
  const totalGMV =
    amount_total_payout + amount_dobby_fee + amount_commission + amount_stripe_fee - (fail_commission_value || 0);
  const hoPayment = amount_total_charge - (amount_dobby_cash_bonus || 0);
  const amountPaidByCard =
    (amount_total_charge || 0) - (amount_credit_adjustment || 0) - (amount_dobby_cash_bonus || 0) || 0;

  const historyMarkup = payment_history?.amount_dobby_fee + (payment_history?.commission || 0);
  const historyMargin = historyMarkup;

  const HeadingTitle = (
    <SummaryHeading>
      Summary
      <SummaryTitleTooltip isMarginFee={isMarginFee} />
    </SummaryHeading>
  );

  const ProPayoutTitle = (
    <FlexRow customCSS={customFlexRowCSS}>
      <span>Pro Payout</span>
      {!is_quotation && is_moderated && (
        <div>
          <Payout showButton={!is_transfered} onPayout={onPayout} />
        </div>
      )}
    </FlexRow>
  );

  return (
    <>
      <HistoryInvoiceRow
        title={HeadingTitle}
        initial="Initiated"
        paid={isOfflinePaid ? (is_paid ? 'Paid' : 'Initiated') : ''}
        isPaid={isOfflinePaid}
        valueColor={theme.colors.DARK_GREY}
      />
      {/* SummaryColumn */}
      <HistoryInvoiceRow
        title={ProPayoutTitle}
        isPaid={isOfflinePaid}
        initial={formatters.dollarCurrencyDB(payment_history?.amount_total_payout || 0)}
        paid={formatters.dollarCurrencyDB(amount_total_payout)}
      />
      {is_final_payment && (
        <HistoryInvoiceRow
          title="Tips"
          isPaid={isOfflinePaid}
          initial={formatters.dollarCurrencyDB(payment_history?.amount_tip || 0)}
          initialSecondary={`(${formatters.percentDB(
            (((payment_history?.amount_tip || 0) / (payment_history?.amount_total_charge || 1)) * 10000).toFixed(0)
          )})`}
          paid={formatters.dollarCurrencyDB(amount_tip)}
          paidSecondary={`(${formatters.percentDB(((amount_tip / hoPayment) * 10000).toFixed(0))})`}
        />
      )}
      <HistoryInvoiceRow
        title="Dobby's Operating Margin"
        isPaid={isOfflinePaid}
        initial={formatters.dollarCurrencyDB(
          payment_history?.amount_platform_fee || payment_history?.amount_provider_fee || 0
        )}
        initialSecondary={`(${formatters.percentDB(payment_history?.commission_value)})`}
        paid={formatters.dollarCurrencyDB(isMarginFee ? amount_dobby_fee : amount_commission)}
        paidSecondary={`(${formatters.percentDB(percent_commission)})`}
      />
      {(Boolean(is_paid) || invoice_status === paymentInvoiceStatuses.PROCESSING) && (
        <HistoryInvoiceRow
          title="DobbyCash Reward"
          isPaid={isOfflinePaid}
          initial={formatters.dollarCurrencyDB(0)}
          initialSecondary={getFormattedPercent({
            partialValue: 0,
            totalValue: payment_history?.amount_total_charge
          })}
          paid={(Boolean(discount) ? '- ' : '') + formatters.dollarCurrencyDB(discount)}
          paidSecondary={getFormattedPercent({ partialValue: discount, totalValue: amount_total_charge })}
        />
      )}
      <HistoryInvoiceRow
        title="Stripe Fee"
        isPaid={isOfflinePaid}
        initial={formatters.dollarCurrencyDB(payment_history?.amount_stripe_fee)}
        initialSecondary={`(${formatters.percentDB(payment_history?.percent_stripe_fee)})`}
        paid={formatters.dollarCurrencyDB(amount_stripe_fee)}
        paidSecondary={`(${formatters.percentDB(percent_stripe_fee)})`}
      />
      {Boolean(fail_commission_value) && (
        <HistoryInvoiceRow
          title="Stripe Failure Fee"
          isPaid={isOfflinePaid}
          initial={'- ' + formatters.dollarCurrencyDB(fail_commission_value)}
          paid={'- ' + formatters.dollarCurrencyDB(fail_commission_value)}
        />
      )}
      <HistoryInvoiceRow
        title="Dobby's Net Margin"
        isPaid={isOfflinePaid}
        initial={
          (historyMargin < 0 ? '- ' : '') + formatters.dollarCurrencyDB(historyMargin * Math.sign(historyMargin))
        }
        initialSecondary={getFormattedPercent({
          partialValue: historyMargin,
          totalValue: (payment_history?.amount_total_payout || 1) - (payment_history?.amount_tip || 0)
        })}
        paid={(dobbyMargin < 0 ? '- ' : '') + formatters.dollarCurrencyDB(dobbyMargin * Math.sign(dobbyMargin))}
        paidSecondary={getFormattedPercent({
          partialValue: dobbyMargin,
          totalValue: (amount_total_payout || 1) - (amount_tip || 0)
        })}
      />
      <HistoryInvoiceRow
        title="GMV Before Discounts"
        isPaid={isOfflinePaid}
        initial={formatters.dollarCurrencyDB(payment_history?.amount_total_charge || 0)}
        paid={formatters.dollarCurrencyDB(totalGMV)}
      />
      {/* HO Payment */}
      <HistoryInvoiceRow
        title={<MainTotalHeading>HO Payment</MainTotalHeading>}
        isPaid={isOfflinePaid}
        initial={
          <HeadingTotalCell>
            <MainTotalHeading>
              {formatters.dollarCurrencyDB(payment_history?.amount_total_charge || 0)}
            </MainTotalHeading>
          </HeadingTotalCell>
        }
        paid={
          <HeadingTotalCell>
            <MainTotalHeading>{formatters.dollarCurrencyDB(hoPayment)}</MainTotalHeading>
          </HeadingTotalCell>
        }
      />
      {datetime_paid && (
        <HistoryInvoiceRow
          title="Payment Date"
          isPaid={isOfflinePaid}
          initial="-"
          paid={moment(datetime_paid).format(dates.DATE)}
        />
      )}
      {Boolean(is_paid) && Object.values(paymentMethods).includes(type_of_payment) && (
        <HistoryInvoiceRow
          title="Payment Method"
          isPaid={isOfflinePaid}
          initial="-"
          paid={getPaymentMethod(type_of_payment, { cardNumber: last4 })}
        />
      )}
      {Boolean(is_paid) && (
        <HistoryInvoiceRow
          title="Pre-paid by DobbyCash"
          isPaid={isOfflinePaid}
          initial="-"
          paid={formatters.dollarCurrencyDB(amount_credit_adjustment || 0)}
        />
      )}
      {Boolean(is_paid) && (
        <HistoryInvoiceRow
          title="Paid at Completion"
          isPaid={isOfflinePaid}
          initial="-"
          paid={formatters.dollarCurrencyDB(amountPaidByCard || 0)}
        />
      )}
    </>
  );
};
