import React from 'react';

import { useDispatch, useSelector } from 'hooks';
import { closeModal } from 'store/actions';
import { selectServiceInstance } from 'store/reducers/service';
import modalTypes from 'constants/modalTypes';
import styles from 'constants/styles';

import { Modal } from 'components';
import { Bundle } from './containers';

const BundleJobs = () => {
  const dispatch = useDispatch();
  const { id, user_id, service_provider_id } = useSelector(selectServiceInstance);

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  return (
    <Modal
      modalType={modalTypes.BUNDLE_SERVICES}
      hideModal={handleCloseModal}
      backgroundColor={styles.colors.WHITE}
      bundleModal={true}>
      <Bundle
        bundleJobFilters={{
          admin_status: null,
          customer_id: user_id,
          provider_id: service_provider_id || null,
          provider_is_null: true,
          is_paid: 0,
          is_open: 1,
          page: 1
        }}
        serviceId={id}
      />
    </Modal>
  );
};

export default BundleJobs;
