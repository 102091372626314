import React from 'react';

import { useWatch, useTheme } from 'hooks';
import { HookForm, IconButton } from 'components';
import { Label, Row } from './styled';
import { campaignNavigationTypes } from 'common/enums/campaigns';
import { DOBBY_APP_DEEP_LINK_PREFIX } from 'common/constants/app';
import { fieldTypes } from 'common/enums/form';
import { CreateJobNavigation } from '../../../';

export const ButtonCTA = ({ register, control, onSetButtonHidden, setValue }) => {
  const navigation_type = useWatch({ control, name: 'article.navigation_type' });
  const is_button_hidden = useWatch({ control, name: 'article.is_button_hidden' });
  const theme = useTheme();
  return (
    <>
      <Row>
        <Label isHighLighted={!is_button_hidden}>CTA Button</Label>
        <IconButton
          onClick={() => onSetButtonHidden(!is_button_hidden)}
          text={is_button_hidden ? 'Add' : null}
          height={24}
          width={is_button_hidden ? 72 : 24}
          iconName={is_button_hidden ? 'plus' : 'close'}
          backgroundIconSize={12}
        />
      </Row>
      {!is_button_hidden && (
        <>
          <div style={{ margin: '6px 0 0 0' }}>
            <HookForm.Input
              name="article.button_title"
              control={control}
              label="Title*"
              placeholder="Enter Title"
              lengthInfoPrefix="Restricted length"
              rules={{
                maxLength: 32
              }}
            />
          </div>
          <div style={{ margin: '16px 0 16px 0' }}>
            <HookForm.Radio
              label="Navigation*"
              name="article.navigation_type"
              disabled
              control={control}
              row
              options={[
                {
                  name: campaignNavigationTypes.HOME_PAGE.TITLE,
                  value: campaignNavigationTypes.HOME_PAGE.TYPE
                },
                {
                  name: campaignNavigationTypes.JOB_CREATION.TITLE,
                  value: campaignNavigationTypes.JOB_CREATION.TYPE
                },
                {
                  name: campaignNavigationTypes.REDIRECT_LINK.TITLE,
                  value: campaignNavigationTypes.REDIRECT_LINK.TYPE
                }
              ]}
            />
          </div>
          {Number(navigation_type) === campaignNavigationTypes.JOB_CREATION.TYPE && (
            <CreateJobNavigation
              control={control}
              register={register}
              name_prefix="article.navigation_payload"
              setValue={setValue}
            />
          )}
          {Number(navigation_type) === campaignNavigationTypes.REDIRECT_LINK.TYPE && (
            <HookForm.ControlledTextarea
              name="article.redirect_link"
              control={control}
              reset
              fieldType={fieldTypes.LINK}
              placeholder={DOBBY_APP_DEEP_LINK_PREFIX}
              hasSmallLegthTitle
              style={{
                height: '35px',
                minHeight: '35px'
              }}
              renderNotice={() => (
                <Label color={theme.colors.DARK_GREY_2} style={{ marginTop: 4 }}>
                  The list of all available links are stored{' '}
                  <a
                    href="https://halosinsurance.atlassian.net/l/cp/QYt38115"
                    rel="noopener noreferrer"
                    target="_blank">
                    here
                  </a>
                  .
                </Label>
              )}
            />
          )}
        </>
      )}
    </>
  );
};
