import { all, call, takeLatest, put } from 'redux-saga/effects';
import { closeModal } from 'store/actions/modal';
import api_constants from 'constants/api';
import actionTypes from 'constants/actionTypes';
import modalTypes from 'constants/modalTypes';
import Api from 'services/ApiService';

function* updateAgentInstance(action) {
  const {
    payload: { data, userId, agentId }
  } = action;
  try {
    let [profile_image_id, mediaUploadUrl] = [null, null];
    const { media } = data;

    if (media) {
      mediaUploadUrl = yield call(Api.amplifyPost, api_constants.MEDIA, {
        original_name: media.name,
        sub_folder: 'avatar'
      });
      if (mediaUploadUrl) {
        const uploadResponse = yield call(Api.s3Put, mediaUploadUrl.upload_url, media, { 'Content-Type': media.type });
        if (uploadResponse) {
          profile_image_id = mediaUploadUrl.id;
        }
      }
    }
    const avatarId = profile_image_id ? { profile_image_id } : {};

    // TODO: (unassigned) to not send extra request when only one instance changed
    const [responseUser, responseAgent] = yield all([
      call(Api.amplifyPut, `${api_constants.USER}/${userId}`, {
        given_name: data.given_name,
        family_name: data.family_name,
        email: data.email,
        ...avatarId
      }),
      call(Api.amplifyPut, `${api_constants.REAL_ESTATE_AGENT}/${agentId}`, {
        position: data.position,
        company_name: data.company_name,
        about: data.about,
        website_link: data.website_link
      })
    ]);

    if (!responseUser || !responseAgent) {
      throw new Error();
    }

    yield put({
      type: actionTypes.UPDATE_AGENT_INSTANCE_SUCCESS,
      payload: {
        ...responseAgent,
        user: responseUser
      }
    });
  } catch (err) {
    console.log(err);
    yield put({
      type: actionTypes.UPDATE_AGENT_INSTANCE_ERROR
    });
  } finally {
    yield put(closeModal(modalTypes.EDIT_USER));
  }
}

export default function* watchUpdateAgent() {
  yield takeLatest(actionTypes.UPDATE_AGENT_INSTANCE_FETCH, updateAgentInstance);
}
