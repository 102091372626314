import { paymentMethods } from 'common/enums/invoice';
import { invoiceStatuses } from 'common/enums/invoice';

export const INVOICE_FORM_MARGIN_RIGHT = 0;
export const INVOICE_DIVIDER_MARGIN = 18;
export const INVOICE_AMOUNT_INPUT_WIDTH = 90;

export const additionalCommissionInfo = {
  BIG_PAYMENT: 'reduced as the Labor+Material amount more than $5,999',
  DEPOSIT: 'Platform Fee from the job amount will be deducted on the next charge',
  DEPOSIT_COMMISSION_DEBT: (value) => `${value} previously initiated as the “Deposit payment”.`,
  COMMISSION: (value) =>
    `additional ${value} from the current job amount will be deducted as the Dobby's Operating Margin`
};

export const OFFLINE_PAYMENT_TYPES = [paymentMethods.CASH, paymentMethods.TRANSFER];

export const paymentStatuses = {
  PENDING_ADMIN: invoiceStatuses.PENDING_ADMIN,
  PENDING_CUSTOMER: invoiceStatuses.PENDING_CUSTOMER,
  PAID: invoiceStatuses.COMPLETED
};

export const quoteStatuses = {
  PENDING_ADMIN: invoiceStatuses.PENDING_ADMIN,
  PENDING_CUSTOMER: invoiceStatuses.PENDING_CUSTOMER,
  ACCEPTED: {
    ...invoiceStatuses.COMPLETED,
    LABEL: 'Approved'
  },
  REJECTED: invoiceStatuses.REJECTED
};
