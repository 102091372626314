import React from 'react';
import { ProgressCreated } from './styled';

export const ProgressAuthor = ({ author }) => {
  const { given_name = '', group_id } = author || {};
  switch (group_id) {
    case 1:
      return <ProgressCreated>BY HOMEOWNER</ProgressCreated>;
    case 3:
      return <ProgressCreated>BY {given_name} (OPS TEAM)</ProgressCreated>;
    default:
      return null;
  }
};
