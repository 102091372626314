import actionTypes from 'constants/actionTypes';

const defaultState = {
  listLoading: false,
  hasListError: false,
  count: 0,
  list: [],
  page: 0
};

function reducer(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.GET_REVIEW_LIST_FETCH: {
      return {
        ...state,
        listLoading: true,
        hasListError: false
      };
    }
    case actionTypes.GET_REVIEW_LIST_SUCCESS: {
      return {
        ...state,
        listLoading: false,
        hasListError: false,
        count: action.payload.count,
        page: action.payload.page,
        list: action.payload.list
      };
    }
    case actionTypes.GET_REVIEW_LIST_ERROR: {
      return {
        ...state,
        listLoading: false,
        hasListError: true,
        count: 0,
        list: []
      };
    }

    default:
      return state;
  }
}

// selectors
export const selectReviewIsLoading = (state) => state.review.listLoading;
export const selectReviewListError = (state) => state.review.hasListError;
export const selectReviewCount = (state) => state.review.count;
export const selectReviewList = (state) => state.review.list;
export const selectReviewPage = (state) => state.review.page;

export default reducer;
