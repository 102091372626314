export const getRows = ({ list }) => {
  return list.map((service) => ({
    admin_status: service.admin_status,
    amount_invoiced: service.amount_invoiced,
    amount_total_paid: service.amount_total_paid,
    customer: service.user,
    date_created: service.date_created,
    datetime_accepted: service.datetime_accepted,
    datetime_canceled: service.datetime_canceled,
    datetime_completed: service.datetime_completed,
    datetime_hold: service.date_end_hold,
    datetime_last_note: service.datetime_last_note,
    datetime_visit: service.datetime_visit,
    datetime_window_end: service.datetime_window_end,
    datetime_quote: service.datetime_quote,
    expected_value: service.expected_value,
    id: service.id,
    is_quoted: service.is_quoted,
    is_past_scheduled: service.is_past_scheduled,
    is_warning_checked: service.is_warning_checked,
    is_warning_enabled: service.is_warning_enabled,
    invoice_pending: service.invoice_pending,
    hasRowHighlight: service.is_status_badge,
    job_category_name: service.job_category_name,
    last_note: service.last_note,
    provider: service.service_provider?.user,
    pro_stage: service.service_provider?.pro_stage,
    service_provider_id: service.service_provider_id,
    unfulfilled_reason: service.unfulfilled_reason,
    unfulfilled_reason_data: service.unfulfilled_reason_data,
    warning_type: service.warning_type,
    warning_value: service.warning_value,
    was_assigned_to_pro: service.was_assigned_to_pro,
    last_message: service.last_job_chat_message,
    service_area: service.service_area
  }));
};
