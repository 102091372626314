import { useDispatch, useSelector } from 'hooks';
import React from 'react';
import { selectExpirePromoCodeLoading } from 'store/reducers/promoCode';
import { closeModal } from 'store/actions';
import modalTypes from 'constants/modalTypes';
import { expirePromoCode } from 'store/actions';
import { ModalConfirmation } from 'components';

import { selectModalData } from 'store/reducers/modal';

const DeleteReward = ({ user }) => {
  const dispatch = useDispatch();
  const expirePromoCodeLoading = useSelector(selectExpirePromoCodeLoading);
  const onModalClose = () => dispatch(closeModal(modalTypes.EXPIRE_PROMO_CODE));
  const { referralCode } = useSelector(selectModalData);

  return (
    <ModalConfirmation
      modalType={modalTypes.EXPIRE_PROMO_CODE}
      title={`The ${referralCode} will be removed from all linked HOs accounts`}
      hideModal={onModalClose}
      primaryButton={{
        title: 'Cancel',
        disabled: expirePromoCodeLoading,
        onClick: onModalClose
      }}
      secondaryButton={{
        title: 'Expire',
        loading: expirePromoCodeLoading,
        disabled: expirePromoCodeLoading,
        onClick: () => dispatch(expirePromoCode({ referralCode }))
      }}
    />
  );
};

export default DeleteReward;
