import { call, takeEvery, put, delay } from 'redux-saga/effects';
import actionTypes from 'constants/actionTypes';
import { getImageFromS3 } from 'utils';

function* getAvatar({ payload: { url, retryAttempts, hasDelay } }) {
  try {
    // We need the delay for avatars on the details view
    // because backend has lag for generating thumbnail after editing it
    // backend puts url without uploaded thumbnail file in the API
    if (hasDelay) {
      yield delay(1000);
    }

    const response = yield call(getImageFromS3, url);

    if (response instanceof Error) {
      throw new Error(response);
    }

    if (response instanceof Error) {
      throw new Error();
    }

    if (response.blob === undefined) {
      yield put({
        type: actionTypes.GET_AVATAR_SUCCESS,
        payload: {
          url,
          blobUrl: ''
        }
      });
      // Skip empty avatar
      return;
    }

    const blob = yield response.blob();
    const blobUrl = URL.createObjectURL(blob);

    if (!blobUrl) {
      throw new Error();
    }

    yield put({
      type: actionTypes.GET_AVATAR_SUCCESS,
      payload: {
        url,
        blobUrl
      }
    });
  } catch (err) {
    yield put({
      type: actionTypes.GET_AVATAR_ERROR
    });
    console.log(err);
    if (retryAttempts > 0 && url) {
      yield delay(2000);
      yield put({
        type: actionTypes.GET_AVATAR_FETCH,
        payload: {
          url,
          retryAttempts: retryAttempts - 1
        }
      });
    }
  }
}

export default function* watchGetAvatar() {
  yield takeEvery(actionTypes.GET_AVATAR_FETCH, getAvatar);
}
