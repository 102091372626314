import React from 'react';
import { getFullName } from 'common/helpers/user';
import { SearchInput, Icon, StyledTooltip } from 'components';

import { HeaderArea, HeaderWrapper, FiltersRow, Label, PillWrapper, ResetFilter, SearchWrapper } from './styled';
import { GeoLocationSelect } from 'containers';

export const Header = ({ selectedProvider, onRemoveVisit, onRemoveProvider, hasResetVisit, onSearch }) => {
  const name = getFullName({ given_name: selectedProvider?.given_name, family_name: selectedProvider?.family_name });

  return (
    <>
      <HeaderWrapper>
        <HeaderArea>
          <SearchWrapper>
            <SearchInput
              isFullWidth
              placeholder={'Search Pro by name/ID/category/skill'}
              onSearch={onSearch}
              hideClear
            />
            <StyledTooltip
              arrow
              placement="top-end"
              title={
                <div>
                  <div>
                    Use “<strong>c=category_name</strong>” to search Pros by category
                  </div>
                  <div>
                    Use “<strong>s=skill_name</strong>” to search Pros by skill{' '}
                  </div>
                </div>
              }>
              <div style={{ position: 'absolute', top: '1px', right: 0 }}>
                <Icon name="infoOutlined" />
              </div>
            </StyledTooltip>
          </SearchWrapper>
          <GeoLocationSelect right="0px" />
        </HeaderArea>
      </HeaderWrapper>
      <FiltersRow>
        {Boolean(selectedProvider?.id) && (
          <PillWrapper onClick={onRemoveProvider}>
            <Label>{name || 'No Pro Name'}</Label>
            <Icon size={16} name={'closeFill3'} />
          </PillWrapper>
        )}
        {hasResetVisit && <ResetFilter onClick={onRemoveVisit}>Show all schedules</ResetFilter>}
      </FiltersRow>
    </>
  );
};
