import actionTypes from 'constants/actionTypes';

const defaultState = {
  promoCodeListLoading: false,
  promoCodeList: [],
  promoCodeCount: 0,
  loadingPromoCodeId: '',
  expirePromoCodeLoading: false,
  createRewardLoading: false,
  referralListLoading: false,
  hasReferralListError: false,
  referralList: [],
  referralCount: 0,
  referralPage: 0
};

function reducer(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.GET_PROMO_CODE_LIST_FETCH: {
      return {
        ...state,
        promoCodeListLoading: true
      };
    }
    case actionTypes.GET_PROMO_CODE_LIST_SUCCESS: {
      return {
        ...state,
        promoCodeList: action.payload.results,
        promoCodeCount: action.payload.count,
        promoCodeListLoading: false
      };
    }
    case actionTypes.GET_PROMO_CODE_LIST_ERROR: {
      return {
        ...state,
        promoCodeListLoading: false
      };
    }

    case actionTypes.CREATE_PROMO_CODE_FETCH: {
      const { referral_code } = action.payload;
      return {
        ...state,
        loadingPromoCodeId: referral_code,
        promoCodeList: [...state.promoCodeList, action.payload]
      };
    }
    case actionTypes.CREATE_PROMO_CODE_SUCCESS: {
      const { referral_code } = action.payload;

      return {
        ...state,
        loadingPromoCodeId: '',
        promoCodeList: state.promoCodeList.map((item) => (item.referral_code === referral_code ? action.payload : item))
      };
    }
    case actionTypes.CREATE_PROMO_CODE_ERROR: {
      const { referral_code } = action.payload;
      return {
        ...state,
        loadingPromoCodeId: '',
        promoCodeList: state.promoCodeList.filter((item) => item.referral_code !== referral_code)
      };
    }

    case actionTypes.UPDATE_PROMO_CODE_FETCH: {
      const { referral_code } = action.payload;

      return {
        ...state,
        loadingPromoCodeId: referral_code,
        promoCodeList: state.promoCodeList.map((item) =>
          item.referral_code === referral_code ? { ...item, ...action.payload } : item
        )
      };
    }
    case actionTypes.UPDATE_PROMO_CODE_SUCCESS: {
      const { referral_code } = action.payload;

      return {
        ...state,
        loadingPromoCodeId: '',
        promoCodeList: state.promoCodeList.map((item) => (item.referral_code === referral_code ? action.payload : item))
      };
    }
    case actionTypes.UPDATE_PROMO_CODE_ERROR: {
      return {
        ...state,
        loadingPromoCodeId: ''
      };
    }
    case actionTypes.EXPIRE_PROMO_CODE_FETCH: {
      return {
        ...state,
        expirePromoCodeLoading: true
      };
    }
    case actionTypes.EXPIRE_PROMO_CODE_SUCCESS: {
      const { referral_code } = action.payload;

      return {
        ...state,
        expirePromoCodeLoading: false,
        promoCodeList: state.promoCodeList.map((item) => (item.referral_code === referral_code ? action.payload : item))
      };
    }
    case actionTypes.EXPIRE_PROMO_CODE_ERROR: {
      return {
        ...state,
        expirePromoCodeLoading: false
      };
    }

    case actionTypes.CREATE_REWARD_FETCH: {
      return {
        ...state,
        createRewardLoading: true
      };
    }
    case actionTypes.CREATE_REWARD_SUCCESS:
    case actionTypes.CREATE_REWARD_ERROR: {
      return {
        ...state,
        createRewardLoading: false
      };
    }

    case actionTypes.GET_REFERRAL_LIST_FETCH: {
      return {
        ...state,
        referralListLoading: true,
        hasReferralListError: false
      };
    }
    case actionTypes.GET_REFERRAL_LIST_SUCCESS: {
      const { results, count, page } = action.payload;
      return {
        ...state,
        referralListLoading: false,
        hasReferralListError: false,
        referralList: results,
        referralCount: count,
        referralPage: page
      };
    }
    case actionTypes.GET_REFERRAL_LIST_ERROR: {
      return {
        ...state,
        referralListLoading: false,
        hasReferralListError: true
      };
    }

    default:
      return state;
  }
}

// selectors
// promo codes
export const selectPromoCodeListLoading = (state) => state.promoCode.promoCodeListLoading;
export const selectPromoCodeList = (state) => state.promoCode.promoCodeList;
export const selectPromoCodeCount = (state) => state.promoCode.promoCodeCount;
export const selectLoadingPromoCodeId = (state) => state.promoCode.loadingPromoCodeId;
export const selectExpirePromoCodeLoading = (state) => state.promoCode.expirePromoCodeLoading;
// rewards
export const selectCreateRewardLoading = (state) => state.promoCode.createRewardLoading;
// referrals
export const selectReferralListLoading = (state) => state.promoCode.referralListLoading;
export const selectReferralListError = (state) => state.promoCode.hasReferralListError;
export const selectReferralList = (state) => state.promoCode.referralList;
export const selectReferralCount = (state) => state.promoCode.referralCount;
export const selectReferralPage = (state) => state.promoCode.referralPage;

export default reducer;
