import actionTypes from 'constants/actionTypes';
import { chatMessageTransformer } from 'utils';

export const getTaskMessageList = (data) => ({
  type: actionTypes.GET_TASK_MESSAGE_LIST_FETCH,
  payload: data
});

export const sendTaskMessage = (data) => ({
  type: actionTypes.CREATE_TASK_MESSAGE_FETCH,
  payload: {
    message: data,
    messageTransformed: chatMessageTransformer(data)
  }
});

export const getTaskMessageById = (payload) => ({
  type: actionTypes.GET_TASK_MESSAGE_BY_ID_FETCH,
  payload
});

export const changeActiveTab = (data) => ({
  type: actionTypes.CHANGE_ACTIVE_TAB,
  payload: data
});

export const resetTaskChatUnreadMessagesMark = () => ({
  type: actionTypes.RESET_TASK_UNREAD_MESSAGES_MARK
});

export const resetTaskChatUnreadMessagesDivider = () => ({
  type: actionTypes.RESET_TASK_UNREAD_MESSAGES_DIVIDER
});

export const deleteTaskMessage = (payload) => ({
  type: actionTypes.DELETE_TASK_MESSAGE_FETCH,
  payload
});

export const updateTaskMessage = (payload) => ({
  type: actionTypes.UPDATE_TASK_MESSAGE_FETCH,
  payload
});
