import { userGroups } from 'common/enums/user';
import actionTypes from 'constants/actionTypes';

const defaultServiceList = {
  count: null,
  list: [],
  loading: false,
  page: 0
};

const defaultState = {
  closedServiceList: defaultServiceList,
  instance: {
    user: {}
  },
  instanceLoading: false,
  list: [],
  hasListError: false,
  reviews: [],
  areaList: [],
  areaListLoading: false,
  reviewsLoading: false,
  reviewsCount: 0,
  reviewsPage: 0,
  listLoading: false,
  openServiceList: defaultServiceList,
  recentlyCompletedServiceList: defaultServiceList,
  cancelledServiceList: defaultServiceList,
  page: 1,
  totalCount: 0,
  updateInstanceLoading: false,
  skills: [],
  loadingSkills: false,
  loadingSkillsUpdate: false,
  loadingSkillsCreate: false,
  skillsTotalCount: 0
};

function reducer(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.GET_PROVIDER_LIST_FETCH: {
      return {
        ...state,
        listLoading: true,
        hasListError: false
      };
    }

    case actionTypes.GET_PROVIDER_LIST_SUCCESS: {
      const { page, totalCount, users = [] } = action.payload || {};
      return {
        ...state,
        list: users,
        listLoading: false,
        hasListError: false,
        page,
        totalCount
      };
    }

    case actionTypes.GET_PROVIDER_LIST_ERROR: {
      return {
        ...state,
        list: [],
        listLoading: false,
        hasListError: true,
        page: 1,
        totalCount: 0
      };
    }

    case actionTypes.GET_SKILLS_FETCH: {
      return {
        ...state,
        skillsLoading: true
      };
    }
    case actionTypes.CREATE_SKILL_FETCH: {
      return {
        ...state,
        loadingSkillsCreate: true
      };
    }
    case actionTypes.CREATE_SKILL_SUCCESS: {
      return {
        ...state,
        skills: [action.payload, ...state.skills],
        loadingSkillsCreate: false
      };
    }
    case actionTypes.CREATE_SKILL_ERROR: {
      return {
        ...state,
        loadingSkillsCreate: false
      };
    }
    case actionTypes.UPDATE_SKILL_FETCH: {
      return {
        ...state,
        loadingSkillsUpdate: true
      };
    }
    case actionTypes.UPDATE_SKILL_SUCCESS: {
      return {
        ...state,
        skills: state.skills.map((skill) => (skill.id === action.payload?.id ? action.payload : skill)),
        loadingSkillsUpdate: false
      };
    }
    case actionTypes.UPDATE_SKILL_ERROR: {
      return {
        ...state,
        loadingSkillsUpdate: false
      };
    }
    case actionTypes.GET_SKILLS_SUCCESS: {
      const { page = 1, results, count } = action.payload || {};
      return {
        ...state,
        skills: results,
        skillsLoading: false,
        page,
        skillsTotalCount: count
      };
    }
    case actionTypes.GET_SKILLS_ERROR: {
      return {
        ...state,
        skillsLoading: false
      };
    }

    case actionTypes.GET_PROVIDER_AREAS_FETCH: {
      return {
        ...state,
        areaListLoading: true
      };
    }
    case actionTypes.GET_PROVIDER_AREAS_SUCCESS: {
      const { results = [] } = action.payload;
      return {
        ...state,
        areaList: results,
        areaListLoading: false
      };
    }
    case actionTypes.GET_PROVIDER_AREAS_ERROR: {
      return {
        ...state,
        areaListLoading: false
      };
    }

    case actionTypes.GET_PROVIDER_INSTANCE_FETCH: {
      return {
        ...state,
        openServiceList: action.payload !== state.instance?.id ? defaultServiceList : state.openServiceList,
        closedServiceList: action.payload !== state.instance?.id ? defaultServiceList : state.closedServiceList,
        instanceLoading: true
      };
    }

    case actionTypes.GET_PROVIDER_INSTANCE_SUCCESS: {
      return {
        ...state,
        instance: action.payload,
        instanceLoading: false
      };
    }

    case actionTypes.GET_PROVIDER_INSTANCE_ERROR: {
      return {
        ...state,
        instanceLoading: false
      };
    }

    case actionTypes.UPDATE_PROVIDER_INSTANCE_FETCH: {
      return {
        ...state,
        updateInstanceLoading: true
      };
    }

    case actionTypes.UPDATE_PROVIDER_INSTANCE_SUCCESS: {
      return {
        ...state,
        instance: action.payload,
        updateInstanceLoading: false
      };
    }

    case actionTypes.UPDATE_PROVIDER_INSTANCE_ERROR: {
      return {
        ...state,
        updateInstanceLoading: false
      };
    }

    case actionTypes.GET_USER_SERVICE_LIST_FETCH: {
      const { is_open, fieldName } = action.payload;
      const listName = fieldName || (is_open ? 'openServiceList' : 'closedServiceList');
      return {
        ...state,
        [listName]: {
          ...state[listName],
          list: state[listName].list,
          loading: true
        }
      };
    }

    case actionTypes.GET_USER_SERVICE_LIST_SUCCESS: {
      const { is_open, list, page, count, provider_id, fieldName } = action.payload;
      const listName = fieldName || (is_open ? 'openServiceList' : 'closedServiceList');
      if (provider_id === state.instance.id) {
        return {
          ...state,
          [listName]: {
            list: page > 1 ? [...state[listName].list, ...list] : list,
            page,
            count,
            loading: false
          }
        };
      }
      return state;
    }

    case actionTypes.GET_USER_SERVICE_LIST_ERROR: {
      const { is_open, provider_id, fieldName } = action.payload;
      const listName = fieldName || (is_open ? 'openServiceList' : 'closedServiceList');
      if (provider_id === state.instance.id) {
        return {
          ...state,
          [listName]: {
            ...state[listName],
            loading: false
          }
        };
      }
      return state;
    }

    case actionTypes.UPDATE_USER_INSTANCE_SUCCESS: {
      const { userGroupId, user, id } = action.payload;
      if (userGroupId === userGroups.PROVIDER.TYPE && id === state.instance?.user?.id) {
        return {
          ...state,
          instance: {
            ...state.instance,
            user: {
              ...state.instance.user,
              ...user
            }
          }
        };
      }
      return state;
    }

    case actionTypes.DELETE_COGNITO_USER_ERROR:
    case actionTypes.DELETE_COGNITO_USER_SUCCESS: {
      const { userGroupId, id } = action.payload;
      if (userGroupId === userGroups.PROVIDER.TYPE && id === state.instance?.user?.id) {
        return {
          ...state,
          instance: {
            ...state.instance,
            user: {
              ...state.instance.user,
              ...action.payload
            }
          }
        };
      }
      return state;
    }

    case actionTypes.GET_PROVIDER_REVIEWS_FETCH: {
      return {
        ...state,
        reviewsLoading: true
      };
    }

    case actionTypes.GET_PROVIDER_REVIEWS_SUCCESS: {
      return {
        ...state,
        reviews: action.payload.reset ? action.payload.reviews : [...state.reviews, ...action.payload.reviews],
        reviewsCount: action.payload.count,
        reviewsLoading: false,
        reviewsPage: action.payload.page
      };
    }

    case actionTypes.GET_PROVIDER_REVIEWS_ERROR: {
      return {
        ...state,
        reviewsLoading: false
      };
    }

    case actionTypes.DELETE_PROVIDER_REVIEW_FETCH: {
      return {
        ...state,
        deleteReviewLoading: true
      };
    }

    case actionTypes.DELETE_PROVIDER_REVIEW_SUCCESS: {
      return {
        ...state,
        deleteReviewLoading: false
      };
    }

    case actionTypes.DELETE_PROVIDER_REVIEW_ERROR: {
      return {
        ...state,
        deleteReviewLoading: false
      };
    }

    default:
      return state;
  }
}

// selectors
export const selectProviderDashboard = (state) => state.provider.list;
export const selectProviderListError = (state) => state.provider.hasListError;
export const selectProviderDashboardLoading = (state) => state.provider.listLoading;
export const selectProviderAreaList = (state) => state.provider.areaList;
export const selectProviderAreaListLoading = (state) => state.provider.areaListLoading;
export const selectProviderListPage = (state) => state.provider.page;
export const selectProvidersTotalCount = (state) => state.provider.totalCount;
export const selectProviderInstance = (state) => state.provider.instance;
export const selectProviderInstanceId = (state) => state.provider.instance?.id;
export const selectProviderInstanceLoading = (state) => state.provider.instanceLoading;
export const selectUpdateInstanceInListLoadingId = (state) => state.provider.updateInstanceInListLoadingId;
export const selectUpdateProviderInstanceLoading = (state) => state.provider.updateInstanceLoading;
export const selectSkillsLoading = (state) => state.provider.skillsLoading;
export const selectSkillsUpdateLoading = (state) => state.provider.loadingSkillsUpdate;
export const selectSkillsCreateLoading = (state) => state.provider.loadingSkillsCreate;
export const selectSkills = (state) => state.provider.skills;
export const selectSkillsTotalCount = (state) => state.provider.skillsTotalCount;

// pro's jobs
export const selectProviderActiveServiceList = (state) => state.provider.openServiceList;
export const selectProviderRecentlyCompletedServiceList = (state) => state.provider.recentlyCompletedServiceList;
export const selectProviderCompletedServiceList = (state) => state.provider.closedServiceList;
export const selectProviderCancelledServiceList = (state) => state.provider.cancelledServiceList;
export const selectProviderActiveJobs = (state) => state.provider.openServiceList.list;
export const selectProviderActiveJobsPage = (state) => state.provider.openServiceList.page;
export const selectProviderActiveJobsCount = (state) => state.provider.openServiceList.count;
export const selectProviderCompletedJobs = (state) => state.provider.closedServiceList.list;
export const selectProviderCompletedJobsPage = (state) => state.provider.closedServiceList.page;
export const selectProviderCompletedJobsCount = (state) => state.provider.closedServiceList.count;
export const selectProviderCancelledJobs = (state) => state.provider.cancelledServiceList.list;
export const selectProviderCancelledJobsPage = (state) => state.provider.cancelledServiceList.page;
export const selectProviderCancelledJobsCount = (state) => state.provider.cancelledServiceList.count;

// pro's reviews
export const selectProviderReviews = (state) => state.provider.reviews;
export const selectProviderReviewsLoading = (state) => state.provider.reviewsLoading;
export const selectProviderReviewsCount = (state) => state.provider.reviewsCount;
export const selectProviderReviewsPage = (state) => state.provider.reviewsPage;
export const selectDeleteReviewLoading = (state) => state.provider.deleteReviewLoading;

export default reducer;
