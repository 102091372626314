import React, { useEffect } from 'react';

import { useDispatch, useRouter, useMemo, useSelector, useState, useTheme } from 'hooks';
import { conversationTypes } from 'common/enums/chat';
import { getProviderInstance, deletePersistentNotification, getUserNoteList } from 'store/actions';
import { resetUserChatUnreadMessagesDivider, resetUserChatUnreadMessagesMark } from 'store/actions/supportChat';
import { selectProviderInstance, selectProviderInstanceLoading } from 'store/reducers/provider';

import { siblingPaginationTypes } from 'common/enums/navigation';
import { userGroups } from 'common/enums/user';
import routes from 'constants/routes';
import { LoadingSpinner, OUTDATED_TABS, Button, NotExist } from 'components';
import { Chat, UserDetails, UserServiceLists, Reviews, Notes } from 'containers';
import styles from 'constants/styles';

import {
  ActionContainer,
  ColumnContainer,
  LoadingContainer,
  Sections,
  DataRow,
  DataRecord,
  NotesWrapper
} from './styled';

const ProviderPage = () => {
  const dispatch = useDispatch();
  const {
    params,
    location: { state: locationState }
  } = useRouter();
  const instance = useSelector(selectProviderInstance);
  const instanceLoading = useSelector(selectProviderInstanceLoading);
  const providerId = useMemo(() => Number(params.provider_id), [params.provider_id]);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [isNoteForm, setNoteForm] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    dispatch(getProviderInstance(providerId));
    dispatch(getUserNoteList({ userId: providerId }));
    if (providerId && locationState?.openByNotif) {
      setActiveTabIndex(1);
    }

    return () => {
      dispatch(resetUserChatUnreadMessagesDivider(providerId));
      dispatch(resetUserChatUnreadMessagesMark(providerId));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providerId]);

  const deleteSupportChatPersistentNotifications = () => {
    dispatch(deletePersistentNotification({ user_id: providerId, conversation_type: conversationTypes.SUPPORT }));
  };

  const handleTabChange = (index) => {
    setActiveTabIndex(index);
    if (index === 1) {
      deleteSupportChatPersistentNotifications();
      return;
    }
    if (index === 2) {
      dispatch(deletePersistentNotification({ service_provider_id: providerId }));
    }
  };

  const handleToggleNoteForm = () => {
    setNoteForm((value) => !value);
  };

  if (instance.id !== providerId && instanceLoading) {
    return (
      <LoadingContainer>
        <LoadingSpinner text="Please wait" type="logo" />
      </LoadingContainer>
    );
  }

  if (!instance.id) {
    return <NotExist name="Provider" id={providerId} />;
  }
  const providerDetails = {
    user: {
      ...instance.user,
      stripe_account_id: instance.stripe_account_id,
      is_report_uploaded: instance.is_report_uploaded,
      preferred_name: instance.preferred_name,
      about: instance.about,
      additional_user_id: instance.id,
      external_author_1: instance.external_author_1,
      external_author_2: instance.external_author_2,
      external_author_3: instance.external_author_3,
      external_review_1: instance.external_review_1,
      external_review_2: instance.external_review_2,
      external_review_3: instance.external_review_3,
      amount_total_earned: instance.amount_total_earned,
      amount_average_earned: instance.amount_average_earned,
      category: instance.category,
      primary_category: instance.primary_category,
      secondary_category: instance.secondary_category,
      count_jobs_assigned: instance.count_jobs_assigned,
      count_jobs_accepted: instance.count_jobs_accepted,
      count_jobs_ongoing: instance.count_jobs_ongoing,
      count_jobs_scheduled: instance.count_jobs_scheduled,
      count_jobs_completed: instance.count_jobs_completed,
      count_jobs_unfulfilled: instance.count_jobs_unfulfilled,
      personal_logo_media_url: instance.personal_logo_media?.path,
      service_area_list: instance.service_area_list,
      percent_positive_reviews: instance.percent_positive_reviews,
      percent_completed: instance.percent_completed,
      percent_completed_per_period: instance.percent_completed_per_period,
      percent_scheduled: instance.percent_scheduled,
      provider_skills: instance.provider_skills,
      lifetime_margin: instance.lifetime_margin,
      lifetime_margin_percent: instance.lifetime_margin_percent,
      is_core: instance.is_core,
      is_parked: instance.is_parked,
      pro_stage: instance.pro_stage,
      auto_assign_service_area: instance.auto_assign?.service_area || [],
      auto_assign_service_area_list: instance.auto_assign?.service_area_name || [],
      auto_assign_service_id: instance.auto_assign?.service_id,
      one_click_service_area: instance.one_click?.service_area || [],
      one_click_service_area_list: instance.one_click?.service_area_name || [],
      one_click_service_id: instance.one_click?.service_id
    },

    deviceData: instance.platform,
    userGroupId: userGroups.PROVIDER.TYPE
  };
  return (
    <Sections>
      <ColumnContainer>
        <UserDetails {...providerDetails} />
        <NotesWrapper>
          <Notes
            type="provider"
            showForm={isNoteForm}
            userId={providerId}
            openEditNoteForm={handleToggleNoteForm}
            closeEditNoteForm={handleToggleNoteForm}
            displayNotesLoading
          />
        </NotesWrapper>
        <DataRow>
          <DataRecord>
            {!isNoteForm && <Button title="Add Notes" variant="primary" onClick={handleToggleNoteForm} />}
          </DataRecord>
        </DataRow>
      </ColumnContainer>
      <ActionContainer>
        <OUTDATED_TABS
          headers={['Jobs', 'Direct Chat', 'Reviews']}
          border
          headerWrapperStyle={{ paddingLeft: '8px' }}
          onTabChange={handleTabChange}
          bg={theme.colors.DARK_1}
          minHeight={`calc(100vh - ${styles.layout.headerHeight}px)`}
          defaultTabIndex={activeTabIndex}
          boldFont>
          <UserServiceLists
            providerId={providerId}
            userGroupId={userGroups.PROVIDER.TYPE}
            routePrefix={`${routes.PROVIDER}/${providerId}`}
            siblingPaginationType={siblingPaginationTypes.PROVIDER_JOB}
            rootId={providerId}
          />
          <Chat userId={providerId} userInstance={instance.user} />
          <Reviews providerId={providerId} />
        </OUTDATED_TABS>
      </ActionContainer>
    </Sections>
  );
};

export default ProviderPage;
