import styled from 'styled-components';
import styles from 'constants/styles';

export const ProgressCreated = styled.span`
  color: ${styles.colors.GRAY};
  font-weight: 600;
  font-size: 10px;
  margin-left: 8px;
  letter-spacing: 0.5px;
  line-height: 18px;
  text-transform: uppercase;
`;
