import { call, takeLatest, put } from 'redux-saga/effects';

import api_constants from 'constants/api';
import errors from 'constants/errors';
import actionTypes from 'constants/actionTypes';
import modalTypes from 'constants/modalTypes';
import messages from 'constants/messages';
import { openModal } from 'store/actions';
import Api from 'services/ApiService';

function* createPromoCode({ payload }) {
  const { referral_code } = payload;
  try {
    const response = yield call(Api.amplifyPost, api_constants.PROMO_CODE, payload);

    if (!response || response.err) {
      throw new Error(response?.err.response?.data.error_message);
    }

    yield put({
      type: actionTypes.CREATE_PROMO_CODE_SUCCESS,
      payload: {
        data: response
      }
    });

    yield put(
      openModal(modalTypes.RESULT, {
        title: `Reward "${referral_code}" has been created`,
        text: messages.requstSucceed.PROMO_CODE_TEXT
      })
    );
  } catch (err) {
    yield put({
      type: actionTypes.CREATE_PROMO_CODE_ERROR,
      payload: { referral_code }
    });

    yield put(
      openModal(modalTypes.ERROR, {
        errorMessage: err?.message || errors.DEFAULT
      })
    );
  }
}

export default function* watchCreatePromoCode() {
  yield takeLatest(actionTypes.CREATE_PROMO_CODE_FETCH, createPromoCode);
}
