import { cellTypes } from 'common/enums/table';
import { avatarTableCellStyle } from 'constants/styles';
import { getUserIcon } from 'utils';
import dates from 'constants/dates';
import { formatters } from 'utils';
import { joinWithSemicolon } from 'common/helpers/transformers';
import { navigationTabs } from 'common/enums/navigation';
import { jobCategories } from 'common/enums/job';

import close_icon from 'static/images/close-icon.png';
import ok_icon from 'static/images/ok-icon.png';

import { providerTable } from '../enums';
import { deviceNotificationStatus } from 'common/enums/app';

export const getColumns = ({ hasAvatarColumn, tabId }) => {
  return [
    ...(hasAvatarColumn
      ? [
          {
            id: providerTable.AVATAR.FILTER_NAME,
            title: providerTable.AVATAR.TITLE,
            cellType: cellTypes.AVATAR,
            hasSorting: false,
            width: 60,
            accessor: ({ profile_image_url, user }) => ({
              url: profile_image_url,
              icon: getUserIcon(user),
              style: avatarTableCellStyle
            })
          }
        ]
      : []),
    {
      id: providerTable.ID.FILTER_NAME,
      title: providerTable.ID.TITLE,
      cellType: cellTypes.TEXT,
      minWidth: 80,
      accessor: ({ id }) => ({
        hasHighlight: true,
        text: String(id)
      })
    },
    {
      id: providerTable.GIVEN_NAME.FILTER_NAME,
      title: providerTable.GIVEN_NAME.TITLE,
      cellType: cellTypes.USER_NAME,
      minWidth: 200,
      accessor: ({ given_name, pro_stage }) => ({
        given_name,
        family_name: '',
        pro_stage
      })
    },
    {
      id: providerTable.FAMILY_NAME.FILTER_NAME,
      title: providerTable.FAMILY_NAME.TITLE,
      cellType: cellTypes.TEXT,
      minWidth: 120,
      accessor: ({ family_name }) => ({
        hasHighlight: true,
        text: family_name
      })
    },
    {
      id: providerTable.PHONE_NUMBER.FILTER_NAME,
      title: providerTable.PHONE_NUMBER.TITLE,
      cellType: cellTypes.TEXT,
      minWidth: 160,
      accessor: ({ phone_number }) => ({
        hasHighlight: true,
        highlightType: 'phone_number',
        text: phone_number
      })
    },
    {
      id: providerTable.LOCATION.FILTER_NAME,
      title: providerTable.LOCATION.TITLE,
      cellType: cellTypes.TEXT,
      minWidth: 120,
      hasSorting: false,
      accessor: ({ service_area_list }) => ({
        text: service_area_list?.length ? joinWithSemicolon(service_area_list) : 'Other'
      })
    },
    {
      id: providerTable.PRIMARY_CATEGORY.FILTER_NAME,
      title: providerTable.PRIMARY_CATEGORY.TITLE,
      cellType: cellTypes.TEXT,
      minWidth: 160,
      accessor: ({ primary_category }) => ({
        text: primary_category?.name
      })
    },
    {
      id: providerTable.SECONDARY_CATEGORY.FILTER_NAME,
      title: providerTable.SECONDARY_CATEGORY.TITLE,
      cellType: cellTypes.TEXT,
      hasSorting: false,
      minWidth: 200,
      accessor: ({ secondary_category }) => ({
        text: secondary_category?.map(({ name }) => name).join(', '),
        overflow: 'visible',
        display: 'flex'
      })
    },
    ...([navigationTabs.ONE_CLICK_BOOKING.TYPE].includes(tabId)
      ? [
          {
            id: providerTable.ONE_CLICK_AREAS.FILTER_NAME,
            title: providerTable.ONE_CLICK_AREAS.TITLE,
            cellType: cellTypes.TEXT,
            minWidth: 120,
            hasSorting: false,
            accessor: ({ one_click, hasAutoAreaCategoryDisabled }) => ({
              text:
                !hasAutoAreaCategoryDisabled && one_click?.service_area_name?.length
                  ? joinWithSemicolon(one_click.service_area_name)
                  : '-'
            })
          },
          {
            id: providerTable.ONE_CLICK_CATEGORY.FILTER_NAME,
            title: providerTable.ONE_CLICK_CATEGORY.TITLE,
            cellType: cellTypes.TEXT,
            minWidth: 120,
            hasSorting: false,
            accessor: ({ one_click, hasAutoAreaCategoryDisabled }) => ({
              text:
                (!hasAutoAreaCategoryDisabled &&
                  (jobCategories.find(({ id }) => id === one_click?.service_id)?.name || one_click?.service_id)) ||
                '-'
            })
          }
        ]
      : []),
    ...([navigationTabs.ONE_CLICK_BOOKING.TYPE, navigationTabs.NEW_HO_PRO.TYPE].includes(tabId)
      ? [
          {
            id: providerTable.AUTO_ASSIGN_AREAS.FILTER_NAME,
            title: providerTable.AUTO_ASSIGN_AREAS.TITLE,
            cellType: cellTypes.TEXT,
            minWidth: 120,
            hasSorting: false,
            accessor: ({ auto_assign, hasAutoAreaCategoryDisabled }) => ({
              text:
                !hasAutoAreaCategoryDisabled && auto_assign?.service_area_name?.length
                  ? joinWithSemicolon(auto_assign.service_area_name)
                  : '-'
            })
          },
          {
            id: providerTable.AUTO_ASSIGN_CATEGORY.FILTER_NAME,
            title: providerTable.AUTO_ASSIGN_CATEGORY.TITLE,
            cellType: cellTypes.TEXT,
            minWidth: 120,
            hasSorting: false,
            accessor: ({ auto_assign, hasAutoAreaCategoryDisabled }) => ({
              text:
                (!hasAutoAreaCategoryDisabled &&
                  (jobCategories.find(({ id }) => id === auto_assign?.service_id)?.name || auto_assign?.service_id)) ||
                '-'
            })
          }
        ]
      : []),
    {
      id: providerTable.AMOUNT_TOTAL_EARNED.FILTER_NAME,
      title: providerTable.AMOUNT_TOTAL_EARNED.TITLE,
      cellType: cellTypes.TEXT,
      minWidth: 135,
      accessor: ({ amount_total_earned }) => ({
        text: amount_total_earned ? formatters.dollarCurrencyDB(amount_total_earned) : '-'
      })
    },

    {
      id: providerTable.AVERAGE_JOB_VALUE.FILTER_NAME,
      title: providerTable.AVERAGE_JOB_VALUE.TITLE,
      cellType: cellTypes.TEXT,
      minWidth: 135,
      accessor: ({ amount_average_earned }) => ({
        text: amount_average_earned ? formatters.dollarCurrencyDB(amount_average_earned) : '-'
      })
    },
    {
      id: providerTable.COUNT_TASK_ASSIGNMENTS_SENT.FILTER_NAME,
      title: providerTable.COUNT_TASK_ASSIGNMENTS_SENT.TITLE,
      cellType: cellTypes.TEXT,
      accessor: ({ count_jobs_assigned }) => ({
        text: count_jobs_assigned ? count_jobs_assigned : '-'
      })
    },

    {
      id: providerTable.COUNT_TASK_ACCEPTED.FILTER_NAME,
      title: providerTable.COUNT_TASK_ACCEPTED.TITLE,
      cellType: cellTypes.TEXT,
      accessor: ({ count_accepted }) => ({
        text: count_accepted ? count_accepted : '-'
      })
    },
    {
      id: providerTable.COUNT_TASK_ONGOING.FILTER_NAME,
      title: providerTable.COUNT_TASK_ONGOING.TITLE,
      cellType: cellTypes.TEXT,
      accessor: ({ count_jobs_ongoing }) => ({
        text: count_jobs_ongoing ? count_jobs_ongoing : '-'
      })
    },
    {
      id: providerTable.COUNT_TASK_SCHEDULED.FILTER_NAME,
      title: providerTable.COUNT_TASK_SCHEDULED.TITLE,
      cellType: cellTypes.TEXT,
      accessor: ({ count_jobs_scheduled }) => ({
        text: count_jobs_scheduled ? count_jobs_scheduled : '-'
      })
    },
    {
      id: providerTable.COUNT_TASK_COMPLETED.FILTER_NAME,
      title: providerTable.COUNT_TASK_COMPLETED.TITLE,
      cellType: cellTypes.TEXT,
      accessor: ({ count_jobs_completed }) => ({
        text: count_jobs_completed ? count_jobs_completed : '-'
      })
    },
    {
      id: providerTable.COUNT_TASK_UNFULFILLED.FILTER_NAME,
      title: providerTable.COUNT_TASK_UNFULFILLED.TITLE,
      cellType: cellTypes.TEXT,
      accessor: ({ count_jobs_unfulfilled }) => ({
        text: count_jobs_unfulfilled ? count_jobs_unfulfilled : '-'
      })
    },
    {
      id: providerTable.DAYS_SINCE_SIGNUP.FILTER_NAME,
      title: providerTable.DAYS_SINCE_SIGNUP.TITLE,
      cellType: cellTypes.TEXT,
      minWidth: 123,
      hasSorting: false,
      accessor: ({ days_since_sign_up }) => ({
        text: days_since_sign_up
      })
    },
    {
      id: providerTable.REG_DATE.FILTER_NAME,
      title: providerTable.REG_DATE.TITLE,
      cellType: cellTypes.TEXT,
      minWidth: 110,
      hasSorting: false,
      accessor: ({ date_created }) => ({
        text: formatters.dateWithTimezone(date_created, dates.DATE)
      })
    },
    {
      id: providerTable.DATETIME_LAST_ACTIVITY.FILTER_NAME,
      title: providerTable.DATETIME_LAST_ACTIVITY.TITLE,
      cellType: cellTypes.TEXT,
      minWidth: 140,
      hasSorting: false,
      accessor: ({ date_login }) => ({
        text: formatters.dateWithTimezone(date_login, dates.DATE)
      })
    },
    {
      id: providerTable.PLATFORM_VERSION.FILTER_NAME,
      title: providerTable.PLATFORM_VERSION.TITLE,
      cellType: cellTypes.PLATFORM_VERSION,
      minWidth: 200,
      hasSorting: false,
      accessor: ({ platform }) => ({
        platform
      })
    },
    {
      id: providerTable.APP_VERSION.FILTER_NAME,
      title: providerTable.APP_VERSION.TITLE,
      cellType: cellTypes.APP_VERSION,
      minWidth: 200,
      accessor: ({ platform, user }) => ({
        platform,
        userGroupId: user.group_id
      })
    },
    {
      id: providerTable.NOTIFICATIONS_PERMISSION.FILTER_NAME,
      title: providerTable.NOTIFICATIONS_PERMISSION.TITLE,
      cellType: cellTypes.IMAGE,
      width: 90,
      hasSorting: false,
      accessor: ({ device_notification_status }) => ({
        imageSrc: [deviceNotificationStatus.ON.TYPE, deviceNotificationStatus.PROVISIONAL.TYPE].includes(
          device_notification_status
        )
          ? ok_icon
          : close_icon
      })
    }
  ];
};
