import React from 'react';

import { navigationTabs } from 'common/enums/navigation';
import { userGroups } from 'common/enums/user';
import { useMemo, useRouter } from 'hooks';

import ProviderDashboard from '../ProviderDashboard';
import ReferralDashboard from '../ReferralDashboard';
import Reviews from '../../pages/Reviews';
import Skills from '../Skills/Skills';

const ProviderTabs = () => {
  const { queryParams } = useRouter();
  const filters = queryParams.getAll();
  const hasAvatarColumn = useMemo(() => filters.tab_id === navigationTabs.ALL.TYPE, [filters.tab_id]);

  if (navigationTabs.REFERRAL.TYPE === filters.tab_id) {
    return <ReferralDashboard userGroupId={userGroups.PROVIDER.TYPE} />;
  }

  if (navigationTabs.SKILLS.TYPE === filters.tab_id) {
    return <Skills />;
  }
  if (navigationTabs.REVIEW.TYPE === filters.tab_id) {
    return <Reviews />;
  }

  if (!filters.tab_id) {
    return null;
  }

  return <ProviderDashboard hasAvatarColumn={hasAvatarColumn} />;
};

export default ProviderTabs;
