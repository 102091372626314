import React from 'react';

import { FlexRow, HeaderRow, ValueRow, Value, SecondaryValue } from './styled';

export const HistoryInvoiceRow = ({
  title = '',
  initial = '',
  initialSecondary = '',
  paid = '',
  paidSecondary = '',
  isPaid = false,
  valueColor
}) => {
  const hasTwoColumns = isPaid;

  return (
    <FlexRow>
      <HeaderRow>{title}</HeaderRow>
      {hasTwoColumns && (
        <ValueRow valueColor={valueColor} hasTwoColumns={hasTwoColumns}>
          <Value>{initial}</Value>
          {Boolean(initialSecondary) ? <SecondaryValue>{initialSecondary}</SecondaryValue> : null}
        </ValueRow>
      )}
      <ValueRow valueColor={valueColor} hasTwoColumns={hasTwoColumns}>
        <Value>{paid}</Value>
        {Boolean(paidSecondary) ? <SecondaryValue>{paidSecondary}</SecondaryValue> : null}
      </ValueRow>
    </FlexRow>
  );
};
