import React from 'react';

import { searchHighlightTypes, searchPrefixes } from 'common/enums/app';
import { prepareSearchForHighlightText } from 'common/helpers/transformers';
import { getCRMLinkByGroupId, getGroupAlias } from 'common/helpers/user';

import dates from 'constants/dates';
import { formatters } from 'utils';
import { HighlightedText } from 'components';

import {
  CardBorder,
  FlexContainer,
  FlexColumn,
  FlexOneColumn,
  FlexRow,
  FlexTitleRow,
  Dot,
  StampText,
  DelimiterBlock,
  FieldValue,
  UserNameNavLink,
  Line,
  phoneNumberTextStyle
} from './styled';

const ConversationCard = ({ conversation = {}, onCardClick = () => {}, searchTerm, isHighlighted }) => {
  const handleStopPropagation = (e) => {
    e.stopPropagation();
  };

  const { searchPrefixType, pureSearchValue } = prepareSearchForHighlightText({ search: searchTerm });

  const userLink = getCRMLinkByGroupId({ group_id: conversation.user?.group_id, id: conversation.user_id });
  const userGroupAlias = getGroupAlias({ group_id: conversation.user?.group_id });

  return (
    <CardBorder onClick={onCardClick} isHighlighted={isHighlighted}>
      <FlexContainer>
        <FlexColumn>
          <Dot size={9} isVisible={Boolean(conversation.is_status_badge)} />
        </FlexColumn>
        <FlexOneColumn>
          <FlexTitleRow>
            <UserNameNavLink rel="noopener noreferrer" target="_blank" to={userLink} onClick={handleStopPropagation}>
              {Boolean(userGroupAlias) && <HighlightedText text={`${userGroupAlias} - `} highlight={''} />}
              <HighlightedText
                textArray={[conversation.user?.given_name || '', conversation.user?.family_name || '']}
                highlight={
                  [searchPrefixes.PROVIDER_NAME.TYPE, searchPrefixes.CUSTOMER_NAME.TYPE].includes(
                    searchPrefixType?.TYPE
                  )
                    ? pureSearchValue
                    : ''
                }
              />
              {Boolean(conversation.user_id) && (
                <>
                  {' ('}
                  <HighlightedText
                    text={`${conversation.user_id || ''}`}
                    highlight={searchPrefixes.ID.TYPE === searchPrefixType?.TYPE ? pureSearchValue : ''}
                  />
                  {')'}
                </>
              )}
            </UserNameNavLink>
            <StampText>
              {formatters.dateWithTimezone(conversation.datetime_last_message, dates.DATETIME_FULL_YEAR_A)}
            </StampText>
          </FlexTitleRow>
          <FlexRow>
            <HighlightedText
              text={conversation.phone_number}
              highlight={searchPrefixes.PHONE_NUMBER.TYPE === searchPrefixType?.TYPE ? pureSearchValue : ''}
              type={searchHighlightTypes.PHONE_NUMBER}
              textStyle={phoneNumberTextStyle}
            />
          </FlexRow>
          <DelimiterBlock />
          <FlexTitleRow>
            <FieldValue>
              {conversation.last_message ? (
                <HighlightedText
                  text={conversation.last_message}
                  highlight={
                    searchPrefixes.MESSAGE.TYPE === searchPrefixType?.TYPE || !searchPrefixType ? pureSearchValue : ''
                  }
                  type={searchHighlightTypes.FULL_TEXT}
                />
              ) : (
                '<Empty message>'
              )}{' '}
            </FieldValue>
          </FlexTitleRow>
        </FlexOneColumn>
      </FlexContainer>
      <Line />
    </CardBorder>
  );
};

export default ConversationCard;
