import { call, takeLatest, put } from 'redux-saga/effects';

import api_constants from 'constants/api';
import actionTypes from 'constants/actionTypes';
import Api from 'services/ApiService';
import { getMediaIds } from 'common/helpers/media';

function* createRequest(action) {
  const {
    payload: { onRequestProcessed, attachments, ...serviceData }
  } = action;
  try {
    const mediaIds = attachments.length ? yield call(getMediaIds, { files: attachments }) : [];

    const response = yield call(Api.amplifyPost, api_constants.SERVICE, { ...serviceData, media: mediaIds });
    if (response?.id) {
      yield put({
        type: actionTypes.GET_SERVICE_INSTANCE_FETCH,
        payload: { serviceId: response.id, silent: true }
      });
      yield put({
        type: actionTypes.CREATE_REQUEST_SUCCESS
      });

      onRequestProcessed(true, response.id);
    } else {
      throw new Error(response?.error_message);
    }
  } catch (err) {
    yield put({
      type: actionTypes.CREATE_REQUEST_ERROR,
      payload: err?.message
    });
    onRequestProcessed(false);
    console.log(err);
  }
}

export default function* watchCreateRequest() {
  yield takeLatest(actionTypes.CREATE_REQUEST_FETCH, createRequest);
}
