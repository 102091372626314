import React, { useEffect } from 'react';
import { InfoWindow, OverlayView } from '@react-google-maps/api';

import { useHoverWithCallback, useMemo } from 'hooks';
import { formatters } from 'utils';
import dates from 'constants/dates';

import { findMarkerType } from './helpers/marker';
import { VisitPopup } from '..';
import { PillWrapper, Label } from './styled';
import { markerTypes } from './enums';

const VisitMarker = ({
  visit,
  selectedProId,
  selectedVisitId,
  onClosePopup,
  onMarkerClick,
  isProHovered,
  onSetHoveredVisit,
  onChangeHoveredVisit
}) => {
  const [hoverRef, isVisitHovered] = useHoverWithCallback();
  const {
    address,
    datetime_visit_start,
    datetime_visit_end,
    job_id,
    latitude,
    longitude,
    service_provider_id,
    visit_id
  } = visit;

  useEffect(() => {
    if (isVisitHovered) {
      onChangeHoveredVisit({ visit });
      return;
    }

    if (!isVisitHovered) {
      onChangeHoveredVisit({ visit, isReset: true });
      return;
    }
  }, [isVisitHovered, onChangeHoveredVisit, visit]);

  const markerType = useMemo(
    () =>
      findMarkerType({
        selectedProId,
        selectedVisitId,
        service_provider_id,
        visit_id,
        isHovered: isProHovered
      }),
    [isProHovered, selectedProId, selectedVisitId, service_provider_id, visit_id]
  );

  const position = { lat: latitude, lng: longitude };
  const formattedDate = formatters.dateWithTimezone(datetime_visit_start, dates.DATE_MAP_MARKER);
  const infoWindowOptions = { pixelOffset: new window.google.maps.Size(0, -8) };

  const handleMarkerClick = () => {
    if (markerType === markerTypes.SELECTED_VISIT) {
      return;
    }

    onMarkerClick({ visit_id, service_provider_id });
  };

  return (
    <>
      <OverlayView position={position} mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
        <PillWrapper markerType={markerType} onClick={handleMarkerClick} ref={hoverRef}>
          {markerType !== markerTypes.SELECTED_VISIT && <Label markerType={markerType}>{formattedDate}</Label>}
        </PillWrapper>
      </OverlayView>
      {selectedVisitId === visit_id && (
        <InfoWindow position={position} options={infoWindowOptions} zIndex={1}>
          <VisitPopup
            address={address}
            datetime_visit_start={datetime_visit_start}
            datetime_visit_end={datetime_visit_end}
            job_id={job_id}
            onCloseVisitPopup={onClosePopup}
          />
        </InfoWindow>
      )}
    </>
  );
};

export default VisitMarker;
