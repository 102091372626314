import styled from 'styled-components';
import { INVOICE_AMOUNT_INPUT_WIDTH } from '../../../../../../constants/invoice';

export const TitleRow = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  margin-bottom: 8px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`;

export const Title = styled.p`
  color: ${({ theme }) => theme.colors.DARK_GREY};
  font-weight: 500;
  font-size: 12px;
  line-height: 1.17;
  margin: 0;
  ${({ withTooltip }) => (withTooltip ? `margin-right: 7px;` : ``)}
`;

export const totalInputWrapperStyle = {
  width: INVOICE_AMOUNT_INPUT_WIDTH,
  minWidth: INVOICE_AMOUNT_INPUT_WIDTH,
  marginRight: 8
};
