import React from 'react';

import { useDispatch, useSelector } from 'hooks';

import { closeModal, setActiveTimezone } from 'store/actions';
import { ModalConfirmation } from 'components';
import modalTypes from 'constants/modalTypes';
import activeTimezones from 'constants/timezones';
import { selectModalData } from 'store/reducers/modal';

const Timezone = () => {
  const dispatch = useDispatch();
  const { timezone = activeTimezones.EST.value } = useSelector(selectModalData);

  const hideModal = () => dispatch(closeModal(modalTypes.TIMEZONE));
  const setTimezone = () => {
    dispatch(setActiveTimezone(timezone));
    dispatch(closeModal(modalTypes.TIMEZONE));
    setTimeout(() => {
      window.location.reload();
    }, 100);
  };

  return (
    <ModalConfirmation
      title={`Switch to ${timezone === activeTimezones.EST.value ? 'Eastern' : 'Pacific'} Standard Time?`}
      text={'All timestamps will be updated correspondingly throughout the Gryffindor.'}
      modalType={modalTypes.TIMEZONE}
      hideModal={hideModal}
      primaryButton={{
        title: 'Change Time Zone',
        onClick: setTimezone
      }}
      secondaryButton={{
        title: 'Close',
        onClick: hideModal
      }}
    />
  );
};

export default Timezone;
