import { call, takeLatest, put } from 'redux-saga/effects';
import api_constants from 'constants/api';
import actionTypes from 'constants/actionTypes';
import { openModal } from 'store/actions/modal';
import modalTypes from 'constants/modalTypes';
import Api from 'services/ApiService';

function* deleteCognitoUser(action) {
  const { id } = action.payload;
  try {
    const response = yield call(
      Api.amplifyDel,
      `${api_constants.USER}/${id}${api_constants.COGNITO}`
    );
    if (response && !response.err) {
      if (response.deleted) {
        yield put({
          type: actionTypes.DELETE_COGNITO_USER_SUCCESS,
          payload: {
            ...action.payload,
            ...response
          }
        });
      } else {
        yield put({
          type: actionTypes.DELETE_COGNITO_USER_ERROR,
          payload: {
            ...action.payload,
            ...response
          }
        });
      }
      yield put(openModal(modalTypes.DELETE_COGNITO_USER_RESULT));
    } else {
      yield put({
        type: actionTypes.DELETE_COGNITO_USER_ERROR,
        payload: action.payload
      });
      yield put(openModal(modalTypes.DELETE_COGNITO_USER_RESULT));
    }
  } catch (err) {
    yield put({
      type: actionTypes.DELETE_COGNITO_USER_ERROR,
      payload: action.payload
    });
    yield put(openModal(modalTypes.DELETE_COGNITO_USER_RESULT));
    console.log(err);
  }
}

export default function* watchDeleteCognitoUser() {
  yield takeLatest(actionTypes.DELETE_COGNITO_USER_FETCH, deleteCognitoUser);
}
