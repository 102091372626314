import React from 'react';
import { Route, Routes } from 'react-router-dom';

import NotFound from '../../pages/NotFound';
import { storybookMenuItems } from 'common/enums/navigation';
import { Sidebar } from 'navigation';
import { IconGallery, TypographyStoryBook } from 'components';
import { ColorsStoryBook } from './components';
import { RowFlex, NavigationPlaceholder, ColumnFlex, Content } from './styled';

export const StorybookNavigation = () => (
  <RowFlex>
    <NavigationPlaceholder />
    <ColumnFlex>
      <Content>
        <Routes>
          <Route path="/colors" element={<ColorsStoryBook />} />
          <Route path="/icons" element={<IconGallery />} />
          <Route path="/typography" element={<TypographyStoryBook />} />
          <Route path="/*" element={<NotFound />} />
        </Routes>
      </Content>
    </ColumnFlex>
    <Sidebar items={storybookMenuItems} />
  </RowFlex>
);

export default StorybookNavigation;
