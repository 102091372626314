import React from 'react';

import styles from 'constants/styles';
import { cellTypes } from 'common/enums/table';
import { prepareStageTableCell } from 'common/helpers/job';

import {
  formatters,
  formatDateStamp,
  formatDateShortTimeStampSmall,
  getWarningDescriptionById,
  getWarningColorById,
  getWarningNameById
} from 'utils';
import { Icon } from 'components';
import { analyticsTaskTable } from '../enums';

export const getColumns = ({ onDownloadCSV }) => {
  return [
    {
      title: analyticsTaskTable.DOWNLOAD_CSV_BUTTON.TITLE,
      accessor: analyticsTaskTable.DOWNLOAD_CSV_BUTTON.FILTER_NAME,
      hasSorting: false,
      Header: () => (
        <Icon name="download" size={14} backgroundSize={24} onClick={onDownloadCSV} color={styles.colors.WHITE} />
      )
    },
    {
      id: analyticsTaskTable.PAID.FILTER_NAME,
      title: analyticsTaskTable.PAID.TITLE,
      cellType: cellTypes.TEXT,
      minWidth: 100,
      hasSorting: false,
      accessor: (row) => {
        const { amount_total_paid } = row;

        return {
          text: Boolean(amount_total_paid) ? formatters.dollarCurrencyDB(amount_total_paid) : '',
          hasHighlight: true
        };
      }
    },
    {
      id: analyticsTaskTable.STAGE.FILTER_NAME,
      title: analyticsTaskTable.STAGE.TITLE,
      cellType: cellTypes.LABEL,
      accessor: ({ admin_status, datetime_visit, datetime_window_end, is_past_scheduled }) => {
        return prepareStageTableCell({
          admin_status,
          datetime_visit,
          datetime_window_end,
          is_past_scheduled
        });
      }
    },
    {
      id: analyticsTaskTable.REQUEST_ID.FILTER_NAME,
      title: analyticsTaskTable.REQUEST_ID.TITLE,
      cellType: cellTypes.TEXT,
      minWidth: 140,
      accessor: (row) => {
        const { id } = row;

        return {
          text: String(id),
          hasHighlight: true
        };
      }
    },
    {
      id: analyticsTaskTable.JOB_CATEGORY_NAME.FILTER_NAME,
      title: analyticsTaskTable.JOB_CATEGORY_NAME.TITLE,
      cellType: cellTypes.TEXT,
      accessor: ({ job_category_name }) => {
        return {
          text: job_category_name,
          hasHighlight: true
        };
      }
    },
    {
      id: analyticsTaskTable.REQUESTED.FILTER_NAME,
      title: analyticsTaskTable.REQUESTED.TITLE,
      cellType: cellTypes.TEXT,
      accessor: (row) => {
        const { date_created } = row;

        return {
          text: formatDateStamp(date_created),
          hasHighlight: true
        };
      }
    },
    {
      id: analyticsTaskTable.COMPLETED.FILTER_NAME,
      title: analyticsTaskTable.COMPLETED.TITLE,
      cellType: cellTypes.TEXT,
      accessor: (row) => {
        const { datetime_completed } = row;

        return {
          text: Boolean(datetime_completed) ? formatDateStamp(datetime_completed) : '',
          hasHighlight: true
        };
      }
    },
    {
      id: analyticsTaskTable.HO_ID.FILTER_NAME,
      title: analyticsTaskTable.HO_ID.TITLE,
      cellType: cellTypes.TEXT,
      minWidth: 90,
      accessor: (row) => {
        const { customer } = row;

        return {
          text: String(customer.id),
          hasHighlight: true
        };
      }
    },
    {
      id: analyticsTaskTable.HO_NAME.FILTER_NAME,
      title: analyticsTaskTable.HO_NAME.TITLE,
      cellType: cellTypes.USER_NAME,
      minWidth: 120,
      accessor: ({ customer }) => {
        return {
          given_name: customer.given_name,
          family_name: customer.family_name,
          stage: customer.stage,
          hasHighlight: true
        };
      }
    },
    {
      id: analyticsTaskTable.PRO_ID.FILTER_NAME,
      title: analyticsTaskTable.PRO_ID.TITLE,
      cellType: cellTypes.TEXT,
      minWidth: 100,
      accessor: (row) => {
        const { service_provider } = row;
        return {
          text: Boolean(service_provider) ? String(service_provider.id) : '',
          hasHighlight: true
        };
      }
    },
    {
      id: analyticsTaskTable.PRO_NAME.FILTER_NAME,
      title: analyticsTaskTable.PRO_NAME.TITLE,
      cellType: cellTypes.USER_NAME,
      minWidth: 120,
      accessor: ({ service_provider, pro_stage }) => ({
        given_name: service_provider?.given_name,
        family_name: service_provider?.family_name,
        pro_stage,
        hasHighlight: true
      })
    },
    {
      id: analyticsTaskTable.WARNING.FILTER_NAME,
      title: analyticsTaskTable.WARNING.TITLE,
      cellType: cellTypes.LABEL,
      minWidth: 120,
      accessor: (row) => {
        const { warning_type, warning_value, is_warning_checked, is_warning_enabled } = row;

        const hasEarlyWarning =
          is_warning_enabled &&
          !is_warning_checked &&
          warning_type !== null &&
          Boolean(getWarningDescriptionById(warning_type, warning_value));

        return hasEarlyWarning
          ? {
              description: getWarningDescriptionById(warning_type, warning_value),
              color: getWarningColorById(warning_type),
              text: getWarningNameById(warning_type),
              hasTooltip: true
            }
          : '';
      }
    },
    {
      id: analyticsTaskTable.LAST_NOTE.FILTER_NAME,
      hasSorting: false,
      title: analyticsTaskTable.LAST_NOTE.TITLE,
      cellType: cellTypes.RICH_TEXT,
      minWidth: 200,
      accessor: (row) => {
        const { datetime_last_note, last_note } = row;

        return {
          datetime: formatDateShortTimeStampSmall(datetime_last_note),
          text: last_note
        };
      }
    }
  ];
};
