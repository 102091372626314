import React from 'react';
import { connect } from 'react-redux';
import { closeModal } from 'store/actions';
import { ModalConfirmation } from 'components';
import modalTypes from 'constants/modalTypes';
import { selectModalData } from 'store/reducers/modal';

const Result = ({
  closeModal,
  modalData: {
    title,
    text,
    textStyle,
    primaryButtonTitle = 'Ok',
    padding = '40px 25px 40px 25px',
    iconProps = {},
    modalProps = {},
    onCustomClose
  }
}) => {
  const closeResult = () => {
    if (onCustomClose) {
      onCustomClose();
    }
    closeModal(modalTypes.RESULT);
  };

  return (
    <ModalConfirmation
      title={title}
      text={text}
      textStyle={textStyle}
      padding={padding}
      modalType={modalTypes.RESULT}
      hideModal={closeResult}
      {...modalProps}
      iconProps={iconProps}
      secondaryButton={{
        title: primaryButtonTitle,
        onClick: closeResult
      }}
    />
  );
};

const mapStateToProps = (state) => ({
  modalData: selectModalData(state)
});

const mapDispatchToProps = {
  closeModal
};

export default connect(mapStateToProps, mapDispatchToProps)(Result);
