import { call, takeLatest, put, select } from 'redux-saga/effects';
import { generateQueryParams } from 'common/helpers/httpRequest';
import api_constants from 'constants/api';
import actionTypes from 'constants/actionTypes';
import errors from 'constants/errors';
import Api from 'services/ApiService';
import { isTestTasksVisible, selectFilteredAdminList, selectFilteredGeoLocationList } from 'store/reducers/app';

function* getPaymentList({ payload: { order_by, page = 1, search, is_paid, is_final_payment, is_moderated } }) {
  try {
    const testTasksVisible = yield select(isTestTasksVisible);
    const filteredAdminList = yield select(selectFilteredAdminList);
    const filteredGeoLocationList = yield select(selectFilteredGeoLocationList);

    const opAreasIDString = filteredGeoLocationList.length ? filteredGeoLocationList.join(',') : '';
    const ownersIDString = filteredAdminList.length ? filteredAdminList.map(({ id }) => id).join(',') : null;

    const queryParams = generateQueryParams({
      page,
      order_by,
      search,
      operational_area_id: opAreasIDString,
      owner_id: ownersIDString,
      ...(testTasksVisible ? {} : { is_test: 0 }),
      ...(is_moderated ? {} : { is_moderated }),
      is_paid,
      is_final_payment
    });

    const response = yield call(Api.amplifyGet, `${api_constants.PAYMENT}${queryParams}`);

    if (!response || !response?.results) {
      throw new Error(errors.DEFAULT);
    }

    yield put({
      type: actionTypes.GET_PAYMENT_LIST_SUCCESS,
      payload: {
        instances: response.results || [],
        count: response.count || 0,
        page
      }
    });
  } catch (err) {
    yield put({
      type: actionTypes.GET_PAYMENT_LIST_ERROR
    });
  }
}

export default function* watchGetPaymentList() {
  yield takeLatest(actionTypes.GET_PAYMENT_LIST_FETCH, getPaymentList);
}
