import styled from 'styled-components';
import styles from 'constants/styles';

export const ButtonLink = styled.button`
  padding: 11px;
  width: 100%;
  background: transparent;
  margin-top: 10px;
  font-size: 14px;
  line-height: 1.2;
  color: ${styles.colors.BLACK};
  border: 1px solid ${styles.colors.LIGHT_GRAY};
  border-radius: 4px;

  &:hover {
    color: ${styles.colors.YELLOW};
  }
`;

export const EmptyCircle = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 10px;
  border: 1px solid ${styles.colors.WHITE};
`;

export const ItemLinkPreview = styled.div`
  border-color: ${styles.colors.LIGHT_GRAY_2};
  border-left-width: 3px;
  border-left-style: solid;
  margin: 5px 0 0 2px;
  padding-left: 10px;
  a {
    font-size: 14px;
    word-break: break-all;
  }
`;

export const MessageName = styled.p`
  font-weight: 500;
  font-size: 12px;
  line-height: 1.17;
  color: ${({ theme }) => theme.colors.GRAY};
  padding: 0;
  margin: 0;
`;

export const MessageTitle = styled(MessageName)`
  color: ${styles.colors.BLACK};
`;

export const MessageWrapper = styled.div`
  a {
    text-decoration: underline;
    color: ${({ isCurrentUser, theme }) => (isCurrentUser ? theme.colors.DARK_1 : theme.colors.WHITE)};
    &:hover {
      color: ${({ theme }) => theme.colors.DARK_BLUE_3};
    }
  }
`;

export const PlainSelectElement = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 2px 0;
  padding-right: 20px;
  padding: 8px 10px;
  background: ${({ selected, theme }) => `${theme.colors.WHITE}${selected ? 33 : 19}`};
  border-radius: 20px;
`;

export const Text = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 1.3;
  margin: 0;
  word-break: break-word;
  color: ${({ color, isCurrentUser, theme }) =>
    color ? color : isCurrentUser ? theme.colors.DARK_1 : theme.colors.WHITE};
  padding: 0;
  white-space: pre-wrap;
`;

export const TextMessageWrapper = styled.div`
  margin-left: ${({ marginSize = 0 }) => marginSize}px;
`;

export const VideoCallTextWrapper = styled.div`
  display: flex;
  align-items: center;
`;
export const VideoCallText = styled(Text)`
  margin-left: 8px;
`;
