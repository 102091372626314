import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useForm } from 'react-hook-form';
import moment from 'moment-timezone';

import dates from 'constants/dates';
import modalTypes from 'constants/modalTypes';
import styles from 'constants/styles';
import { Modal, Button, HookForm, DateTimePicker } from 'components';
import { fieldTypes } from 'common/enums/form';

import { openModal, closeModal } from 'store/actions';
import { createPromoCode, updatePromoCode } from 'store/actions/promoCode';
import { selectLoadingPromoCodeId } from 'store/reducers/promoCode';
import { selectModalType, selectModalData } from 'store/reducers/modal';
import { Icon } from 'components';

import {
  FlexRow,
  FlexColumn,
  ContentFormWrapper,
  Label,
  Heading,
  DateButton,
  rewardAmountStyle,
  rewardAmountWrapperStyle,
  rewardNameStyle,
  rewardTitleStyle,
  FieldSpace,
  ArrowDateButton,
  RelativeWrapper
} from './styled';

const defaultValues = {
  referral_code: '',
  amount: '',
  title: '',
  description: ''
};

const PromoCode = ({
  modalType = modalTypes.PROMO_CODE,
  closeModal,
  modalData = {},
  loadingPromoCodeId = '',
  onCreate,
  onUpdate
}) => {
  const { isEditing = false, promoCode = {} } = modalData;
  const { count_used = 0 } = promoCode;

  const [dateValue, setDateValue] = useState(null);
  const [selectDateValue, setSelectDateValue] = useState(false);
  const formDefaultValues = { ...defaultValues, ...promoCode };
  const {
    register,
    control,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm({ defaultValues, mode: 'onChange' });

  const [isConfirmationOpened, setIsConfirmationOpened] = useState(false);
  useEffect(() => {
    const { promoCode = {} } = modalData;
    if (promoCode.datetime_expiry) {
      setDateValue(moment(promoCode.datetime_expiry).add(moment(promoCode.datetime_expiry).utcOffset(), 'minutes'));
    }
    setValue('referral_code', promoCode.referral_code || '');
    setValue('amount', (promoCode.amount || 0) / 100 || '');
    setValue('title', promoCode.title || '');
    setValue('description', promoCode.description || '');
  }, [modalData]);

  const onModalClose = () => closeModal();
  const onSubmitForm = (data) => {
    const amount = parseInt(data.amount * 100);

    const datetime_expiry = dateValue
      ? moment(dateValue).subtract(moment(dateValue).utcOffset(), 'minutes').format(dates.DATETIME_ISO)
      : null;

    const extendedData = {
      ...data,
      amount,
      datetime_expiry
    };

    if (isEditing) {
      onUpdate(extendedData);
    } else {
      onCreate(extendedData);
    }

    setIsConfirmationOpened(false);
  };

  const isDisabledDate = (datetime) => datetime.isSameOrBefore();
  const validateAndConfirm = handleSubmit(() => setIsConfirmationOpened(true));

  return (
    <Modal
      modalType={modalType}
      hideModal={onModalClose}
      heading={
        isEditing
          ? `Edit Reward ${promoCode.referral_code ? '"' + promoCode.referral_code + '"' : ''}`
          : 'Create New Reward'
      }
      backgroundColor={styles.colors.WHITE}
      middlePaddings
      maxWidth={0}
      minWidth={440}
      padding={'0px'}
      maxHeight={0}>
      <ContentFormWrapper>
        <FlexRow>
          <HookForm.Input
            label="Reward Name"
            inputConfig={{ defaultValue: formDefaultValues.referral_code }}
            name="referral_code"
            register={register}
            rules={{
              required: true,
              pattern: {
                value: /^[A-Z0-9]*$/,
                message: 'Allowed only uppercase letters and numbers'
              }
            }}
            placeholder="REWARD2022"
            disabled={isEditing}
            error={errors.referral_code}
            style={rewardNameStyle}
          />
          <FieldSpace />
          <HookForm.Input
            label="Discount Amount"
            name="amount"
            control={control}
            fieldType={fieldTypes.DISCOUNT_CURRENCY}
            rules={{ required: true }}
            disabled={count_used > 0}
            style={rewardAmountStyle}
            wrapperStyle={rewardAmountWrapperStyle}
          />
        </FlexRow>
        <FlexRow>
          <HookForm.Input
            label="Reward Preview (short description in payment mode)"
            inputConfig={{ defaultValue: formDefaultValues.title }}
            name="title"
            register={register}
            rules={{ required: true, maxLength: 128 }}
            placeholder="$50 your discount"
            error={errors.title}
            style={rewardTitleStyle}
          />
        </FlexRow>
        <FlexRow>
          <HookForm.Textarea
            label="Description"
            rows={6}
            width={360}
            inputConfig={{ defaultValue: formDefaultValues.description }}
            name="description"
            register={register}
            rules={{ required: true, maxLength: 256 }}
            placeholder="Explanation about the reward"
            style={rewardTitleStyle}
            error={errors.description}
          />
        </FlexRow>
        <FlexRow>
          {/* TODO: (refactoring) (Veniamin) Convert into hook form compatible component */}
          <FlexColumn>
            <Label>Expiration Date (optional)</Label>
            <DateButton onClick={() => setSelectDateValue(true)}>
              {dateValue ? dateValue.format('YYYY-MM-DD HH:mm:ss') : 'Set Date'}
              <ArrowDateButton>
                <Icon name="arrowFillBottom" size={15} />
              </ArrowDateButton>
            </DateButton>
          </FlexColumn>
        </FlexRow>
        <FlexRow>
          <Button title="Cancel" variant="primary" margin="0 20px 0 0" onClick={onModalClose} />
          <Button
            title={isEditing ? 'Update' : 'Create'}
            onClick={validateAndConfirm}
            disabled={loadingPromoCodeId !== ''}
          />
        </FlexRow>
      </ContentFormWrapper>
      <Modal
        visible={selectDateValue}
        hideModal={() => setSelectDateValue(false)}
        padding="20px 30px"
        maxHeight={0}
        minWidth={1}>
        <ContentFormWrapper>
          <Label>Expiration Date (optional)</Label>
          <RelativeWrapper>
            <DateTimePicker
              setValue={(date) => setDateValue(moment(moment(date).format(dates.DATETIME_ISO_NO_SS)))}
              value={dateValue || moment().add(1, 'hour')}
              shouldDisableDate={isDisabledDate}
            />
          </RelativeWrapper>

          <FlexRow>
            <Button
              title="Set Up"
              onClick={() => setSelectDateValue(false)}
              disabled={dateValue && (!dateValue.isValid() || isDisabledDate(dateValue) || dateValue.isSameOrBefore())}
            />
          </FlexRow>
        </ContentFormWrapper>
      </Modal>
      <Modal
        visible={isConfirmationOpened}
        hideModal={() => setIsConfirmationOpened(false)}
        closeButton
        backgroundColor={styles.colors.WHITE}
        middlePaddings
        maxWidth={0}
        maxHeight={0}>
        <FlexRow>
          <Heading>{`Confirm reward ${isEditing ? 'updating' : 'creation'}?`}</Heading>
        </FlexRow>
        <FlexRow>
          <Button
            title={'No'}
            variant="primary"
            margin="0 20px 0 0"
            onClick={() => setIsConfirmationOpened(false)}
            disabled={loadingPromoCodeId !== ''}
          />
          <Button title={'Yes'} onClick={handleSubmit(onSubmitForm)} loading={loadingPromoCodeId !== ''} />
        </FlexRow>
      </Modal>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  openedModalType: selectModalType(state),
  modalData: selectModalData(state),
  loadingPromoCodeId: selectLoadingPromoCodeId(state)
});

const mapDispatchToProps = {
  openModal,
  closeModal,
  onCreate: createPromoCode,
  onUpdate: updatePromoCode
};

export default connect(mapStateToProps, mapDispatchToProps)(PromoCode);
