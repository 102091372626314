import React from 'react';
import { connect } from 'react-redux';

import { closeModal, deleteSupportMessage, deleteTaskMessage } from 'store/actions';
import { selectModalData } from 'store/reducers/modal';
import { selectDeleteMessageLoading } from 'store/reducers/supportChat';
import { selectTaskDeleteMessageLoading } from 'store/reducers/taskChat';

import modalTypes from 'constants/modalTypes';
import { ModalConfirmation } from 'components';

const DeleteMessage = ({
  deleteMessageLoading,
  deleteTaskMessageLoading,
  closeModal,
  modalData: { message, userID },
  deleteSupportMessage,
  deleteTaskMessage
}) => {
  const onDeleteModalClose = () => {
    closeModal(modalTypes.DELETE_CHAT_MESSAGE);
  };

  const onConfirm = () => {
    if (userID) {
      deleteSupportMessage({ message, userID });
    } else {
      deleteTaskMessage({ message: message });
    }
  };
  const loading = userID ? deleteMessageLoading : deleteTaskMessageLoading;
  return (
    <ModalConfirmation
      modalType={modalTypes.DELETE_CHAT_MESSAGE}
      hideModal={onDeleteModalClose}
      title={`Are you sure you want to delete this message?`}
      reverseButtons
      primaryButton={{
        title: 'Cancel',
        disabled: loading,
        onClick: onDeleteModalClose
      }}
      secondaryButton={{
        title: 'Delete',
        onClick: onConfirm,
        loading: loading,
        disabled: loading
      }}
    />
  );
};

const mapStateToProps = (state) => ({
  modalData: selectModalData(state),
  deleteMessageLoading: selectDeleteMessageLoading(state),
  deleteTaskMessageLoading: selectTaskDeleteMessageLoading(state)
});

const mapDispatchToProps = {
  closeModal,
  deleteSupportMessage,
  deleteTaskMessage
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteMessage);
