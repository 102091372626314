export const getRows = ({ jobs }) => {
  return jobs.map((service) => ({
    id: service.id,
    customer_given_name: service.user?.given_name,
    customer_family_name: service.user?.family_name,
    customer: service.user,
    provider_given_name: service.service_provider?.user?.given_name,
    provider_family_name: service.service_provider?.user?.family_name,
    pro_stage: service.service_provider?.pro_stage,
    date_created: service.date_created,
    job_category_name: service.job_category_name,
    job_type_name: service.job_type_name
  }));
};
