import actionTypes from 'constants/actionTypes';

export const getProviderList = (payload) => ({
  type: actionTypes.GET_PROVIDER_LIST_FETCH,
  payload
});

export const getProviderAreas = (payload) => ({
  type: actionTypes.GET_PROVIDER_AREAS_FETCH,
  payload
});

export const getProviderInstance = (payload) => ({
  type: actionTypes.GET_PROVIDER_INSTANCE_FETCH,
  payload
});

export const updateProviderInstance = (payload) => ({
  type: actionTypes.UPDATE_PROVIDER_INSTANCE_FETCH,
  payload
});

export const getProviderReviews = (payload) => ({
  type: actionTypes.GET_PROVIDER_REVIEWS_FETCH,
  payload
});

export const deleteProviderReview = (payload) => ({
  type: actionTypes.DELETE_PROVIDER_REVIEW_FETCH,
  payload
});

export const getSkills = (payload) => ({
  type: actionTypes.GET_SKILLS_FETCH,
  payload
});

export const createSkill = (payload) => ({
  type: actionTypes.CREATE_SKILL_FETCH,
  payload
});

export const updateSkill = (payload) => ({
  type: actionTypes.UPDATE_SKILL_FETCH,
  payload
});
