import React from 'react';
import { connect } from 'react-redux';

import { closeModal, updateJobField } from 'store/actions';
import { selectModalData } from 'store/reducers/modal';
import { selectUpdateJobFieldLoading } from 'store/reducers/service';

import modalTypes from 'constants/modalTypes';

import { ModalConfirmation } from 'components';

const CancelVisit = ({
  closeModal,
  modalData: { task_id, errorMessage } = {},
  updateJobField,
  updateJobFieldLoading
}) => {
  const onModalClose = () => {
    closeModal(modalTypes.CANCEL_VISIT);
  };

  const onResultClose = () => {
    closeModal(modalTypes.CANCEL_VISIT_RESULT);
  };

  const onCancelVisit = () => {
    if (!task_id) {
      return;
    }
    updateJobField({
      job_id: task_id,
      body: {
        datetime_visit: null,
        resultModalType: modalTypes.CANCEL_VISIT_RESULT
      }
    });
  };

  return (
    <>
      <ModalConfirmation
        modalType={modalTypes.CANCEL_VISIT}
        title={`Cancel scheduled visit?`}
        text="All parties will be notified."
        hideModal={onModalClose}
        secondaryButton={{
          onClick: onModalClose,
          disabled: updateJobFieldLoading,
          title: 'Keep the visit'
        }}
        primaryButton={{
          title: 'Cancel Visit',
          loading: updateJobFieldLoading,
          onClick: onCancelVisit
        }}
      />
      {/* TODO: (refactoring) (Olena) use result modal instead */}
      <ModalConfirmation
        modalType={modalTypes.CANCEL_VISIT_RESULT}
        title={errorMessage ? '' : 'Visit is canceled'}
        text={errorMessage}
        hideModal={onResultClose}
        secondaryButton={{
          title: 'Ok',
          onClick: onResultClose
        }}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  modalData: selectModalData(state),
  updateJobFieldLoading: selectUpdateJobFieldLoading(state)
});

const mapDispatchToProps = {
  updateJobField,
  closeModal
};

export default connect(mapStateToProps, mapDispatchToProps)(CancelVisit);
