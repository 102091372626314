import React from 'react';
import { openModal, closeModal } from 'store/actions';
import { useDispatch } from 'hooks';
import { ModalConfirmation } from 'components';
import modalTypes from 'constants/modalTypes';

const CloseSilentlyWithPayout = () => {
  const dispatch = useDispatch();

  return (
    <ModalConfirmation
      maxWidth={600}
      modalType={modalTypes.CLOSE_TASK_SILENTLY_WITH_PAYOUT}
      smallPaddings={false}
      title="The job has not “paid out to Pro” invoices. Would you still like to archive the job?"
      text="The Job will be stored under “maintenance history” with “Paid” status. HO won’t be notified about this action. Pro won't receive the payout."
      hideModal={() => dispatch(closeModal(modalTypes.CLOSE_TASK_SILENTLY_WITH_PAYOUT))}
      primaryButton={{
        title: 'Archive anyway',
        onClick: () => {
          dispatch(openModal(modalTypes.CLOSE_TASK_SILENTLY_FINAL));
        }
      }}
      secondaryButton={{
        title: 'Cancel',
        onClick: () => dispatch(closeModal(modalTypes.CLOSE_TASK_SILENTLY_WITH_PAYOUT))
      }}
    />
  );
};

export default CloseSilentlyWithPayout;
