import { userGroups } from 'common/enums/user';
import actionTypes from 'constants/actionTypes';

const defaultLocationList = {
  loading: false,
  list: [],
  page: 0,
  count: null
};

const defaultState = {
  activatedLocationList: defaultLocationList,
  notActivatedLocationList: defaultLocationList,
  instance: {
    user: {}
  },
  instanceLoading: false,
  list: [],
  hasListError: false,
  listLoading: false,
  page: 1,
  totalCount: 0,
  updateInstanceLoading: false,
  charges: [],
  chargesLoading: false
};

function reducer(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.GET_AGENT_LIST_FETCH: {
      return {
        ...state,
        hasListError: false,
        listLoading: true
      };
    }

    case actionTypes.GET_AGENT_LIST_SUCCESS: {
      const { page, totalCount, users = [] } = action.payload || {};
      return {
        ...state,
        list: users,
        hasListError: false,
        listLoading: false,
        page,
        totalCount
      };
    }

    case actionTypes.GET_AGENT_LIST_ERROR: {
      return {
        ...state,
        list: [],
        listLoading: false,
        hasListError: true,
        page: 1,
        totalCount: 0
      };
    }

    case actionTypes.GET_AGENT_INSTANCE_FETCH: {
      return {
        ...state,
        instanceLoading: true,
        activatedLocationList:
          action.payload !== state.instance?.id ? defaultLocationList : state.activatedLocationList,
        notActivatedLocationList:
          action.payload !== state.instance?.id ? defaultLocationList : state.notActivatedLocationList
      };
    }

    case actionTypes.GET_AGENT_INSTANCE_SUCCESS: {
      return {
        ...state,
        instance: action.payload,
        instanceLoading: false
      };
    }

    case actionTypes.GET_AGENT_INSTANCE_ERROR: {
      return {
        ...state,
        instanceLoading: false
      };
    }

    case actionTypes.UPDATE_AGENT_INSTANCE_FETCH: {
      return {
        ...state,
        updateInstanceLoading: true
      };
    }

    case actionTypes.UPDATE_AGENT_INSTANCE_SUCCESS: {
      return {
        ...state,
        instance: action.payload,
        updateInstanceLoading: false
      };
    }

    case actionTypes.UPDATE_AGENT_INSTANCE_ERROR: {
      return {
        ...state,
        updateInstanceLoading: false
      };
    }

    case actionTypes.UPDATE_USER_INSTANCE_SUCCESS: {
      const { userGroupId, user, id } = action.payload;
      if (userGroupId === userGroups.AGENT.TYPE && id === state.instance?.user?.id) {
        return {
          ...state,
          instance: {
            ...state.instance,
            user: {
              ...state.instance.user,
              ...user
            }
          }
        };
      }
      return state;
    }

    case actionTypes.DELETE_COGNITO_USER_ERROR:
    case actionTypes.DELETE_COGNITO_USER_SUCCESS: {
      const { userGroupId, id } = action.payload;
      if (userGroupId === userGroups.AGENT.TYPE && id === state.instance?.user?.id) {
        return {
          ...state,
          instance: {
            ...state.instance,
            user: {
              ...state.instance.user,
              ...action.payload
            }
          }
        };
      }
      return state;
    }

    case actionTypes.GET_AGENT_LOCATION_LIST_FETCH: {
      const { is_active, page } = action.payload;
      const listName = is_active ? 'activatedLocationList' : 'notActivatedLocationList';
      return {
        ...state,
        [listName]: {
          ...state[listName],
          list: page === 1 ? [] : state[listName].list,
          loading: true
        }
      };
    }

    case actionTypes.GET_AGENT_LOCATION_LIST_SUCCESS: {
      const { is_active, list, page, count, agent_id } = action.payload;
      const listName = is_active ? 'activatedLocationList' : 'notActivatedLocationList';
      if (agent_id === state.instance.id) {
        return {
          ...state,
          [listName]: {
            list: page > 1 ? [...state[listName].list, ...list] : list,
            page,
            count,
            loading: false
          }
        };
      }
      return state;
    }

    case actionTypes.GET_AGENT_LOCATION_LIST_ERROR: {
      const { is_active, agent_id } = action.payload;
      const listName = is_active ? 'activatedLocationList' : 'notActivatedLocationList';
      if (agent_id === state.instance.id) {
        return {
          ...state,
          [listName]: {
            ...state[listName],
            loading: false
          }
        };
      }
      return state;
    }

    case actionTypes.GET_AGENT_CHARGES_FETCH: {
      return {
        ...state,
        chargesLoading: true
      };
    }

    case actionTypes.GET_AGENT_CHARGES_SUCCESS: {
      return {
        ...state,
        charges: action.payload,
        chargesLoading: false
      };
    }

    case actionTypes.GET_AGENT_CHARGES_ERROR: {
      return {
        ...state,
        chargesLoading: false
      };
    }

    default:
      return state;
  }
}

// selectors
export const selectAgentDashboard = (state) => state.agent.list;
export const selectAgentListError = (state) => state.agent.hasListError;
export const selectAgentDashboardLoading = (state) => state.agent.listLoading;
export const selectAgentListPage = (state) => state.agent.page;
export const selectAgentTotalCount = (state) => state.agent.totalCount;
export const selectAgentInstance = (state) => state.agent.instance;
export const selectAgentInstanceId = (state) => state.agent.instance?.id;
export const selectAgentInstanceLoading = (state) => state.agent.instanceLoading;
export const selectUpdateAgentInstanceLoading = (state) => state.agent.updateInstanceLoading;
export const selectActivatedLocationList = (state) => state.agent.activatedLocationList;
export const selectNotActivatedLocationList = (state) => state.agent.notActivatedLocationList;
export const selectAgentCharges = (state) => state.agent.charges;
export const selectAgentChargesLoading = (state) => state.agent.chargesLoading;

export default reducer;
