import React from 'react';
import { Button, CampaignDetails } from 'components';
import { ButtonsWrapper, Footer } from './styled';
import { wrapperStyle } from '../../styled';

export const CampaignPreview = ({ hasEdit, onEditClick, onSubmitClick, values, bannerPicture, postPicture }) => {
  return (
    <div style={wrapperStyle}>
      <div
        style={{
          overflowY: 'overlay',
          height: 'calc(100vh - 144px)',
          marginBottom: '88px'
        }}>
        {/* <ConfirmTitle>Review campaign before creation</ConfirmTitle> */}
        <CampaignDetails
          article={values.article}
          articleServiceAreaList={values.article.service_area_id_list?.map(({ name }) => name)}
          campaignPush={values.campaign_push}
          cohortType={values.execution.cohort_type}
          execution={values.execution}
          executionServiceAreaList={values.execution.service_area_id_list?.map(({ name }) => name)}
          hasPreview
          bannerPicture={bannerPicture}
          postPicture={postPicture}
        />
      </div>
      <Footer>
        <ButtonsWrapper>
          <Button variant="primary" onClick={onEditClick}>
            Edit
          </Button>
          <Button onClick={onSubmitClick}>{hasEdit ? 'Save' : 'Create'}</Button>
        </ButtonsWrapper>
      </Footer>
    </div>
  );
};
