import React, { useEffect, useRef } from 'react';

export const AutoScroll = () => {
  let formRef = useRef();
  useEffect(() => {
    if (formRef?.scrollIntoView) {
      formRef.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }
  }, []);

  return (
    <div
      style={{ float: 'left', clear: 'both' }}
      ref={(el) => {
        formRef = el;
      }}
    />
  );
};
