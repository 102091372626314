import styled from 'styled-components';
import styles from 'constants/styles';
import { INVOICE_FORM_MARGIN_RIGHT, INVOICE_AMOUNT_INPUT_WIDTH } from '../../../../../../constants/invoice';

export const ListRow = styled.div`
  display: flex;
  flex: 1;
  margin-right: 20px;
  ${({ isRequired }) =>
    isRequired &&
    `
    padding-right: ${INVOICE_FORM_MARGIN_RIGHT - 8}px;
  `}
`;

export const ItemWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 12px;
`;

export const inputWrapperStyle = {
  marginRight: 8,
  marginTop: 4,
  marginBottom: 4
};

export const textareaStyle = {
  marginTop: 0,
  borderRadius: '4px',
  overflow: 'hidden',
  height: '32px',
  width: '80px',
  maxWidth: '100%',
  padding: '8px 16px'
};

export const detailsAreaStyle = {
  marginTop: 0,
  borderRadius: '4px',
  overflow: 'scroll',
  height: '81px',
  minHeight: '81px',
  maxHeight: '240px',
  width: '100%',
  maxWidth: '100%',
  padding: '8px 16px'
};

export const descriptionInputWrapperStyle = {
  flex: 1
};

export const priceInputWrapperStyle = {
  width: INVOICE_AMOUNT_INPUT_WIDTH,
  minWidth: INVOICE_AMOUNT_INPUT_WIDTH
};

export const Error = styled.label`
  font-weight: 600;
  font-size: 12px;
  line-height: 1.17;
  margin-bottom: 5px;
  color: ${styles.colors.RED};
  margin-top: 5px;
`;
