import React, { useState } from 'react';
import { useDispatch, useSelector } from 'hooks';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { selectModalData } from 'store/reducers/modal';

import { formatters } from 'utils';
import { addRewardResolver, getReduceRewardSchema } from 'common/helpers/formValidators';
import modalTypes from 'constants/modalTypes';
import styles from 'constants/styles';
import { Modal, Button, HookForm, Checkbox, ModalConfirmation } from 'components';

import { closeModal } from 'store/actions';

import { Title, Box, FlexCol, FlexRow, Wrapper, checkboxWrapperCSS } from './styled';
import { manageCustomerReward } from 'store/actions/customer';
import { selectManageRewardLoading } from 'store/reducers/customer';
import { selectAgentInstance } from 'store/reducers/agent';
import { fieldTypes } from 'common/enums/form';

const ManageReward = ({ modalType = modalTypes.MANAGE_REWARD }) => {
  const dispatch = useDispatch();
  const modalData = useSelector(selectModalData);
  const hasManageRewardLoading = useSelector(selectManageRewardLoading);
  const agentInstance = useSelector(selectAgentInstance);
  const isAgent = Boolean(agentInstance.id);

  const {
    control,
    handleSubmit,
    formState: { isValid },
    setValue,
    register,
    watch
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(
      modalData?.hasRewardReduce
        ? getReduceRewardSchema({ currentBalance: modalData?.currentBalance / 100 })
        : addRewardResolver
    )
  });
  const showNotification = watch('showNotification');
  const amount = watch('amount_charge');

  const handleClose = () => {
    dispatch(closeModal());
  };

  const handleFormSubmit = ({ notification_body, amount_charge, title, showNotification, action_description }) => {
    const values = showNotification ? { notification_body, title } : {};
    const description = action_description.length > 0 ? action_description : 'Manual adjustment';
    dispatch(
      manageCustomerReward({
        hasNotification: showNotification,
        ...values,
        user_id: modalData?.user_id,
        amount_charge: Number(amount_charge) * 100,
        hasRewardReduce: Boolean(modalData.hasRewardReduce),
        action_description: description
      })
    );
  };

  const [isShowConfirmation, setIsShowConfirmation] = useState(false);

  const handleChangeSendNotification = (e) => {
    setValue('showNotification', e.target.checked, { shouldValidate: true });
  };

  return (
    <Modal
      modalType={modalType}
      hideModal={handleClose}
      heading={`${modalData.hasRewardReduce ? 'Remove' : 'Add'} Dobby Reward`}
      backgroundColor={styles.colors.WHITE}
      middlePaddings
      maxWidth={424}
      minWidth={424}
      padding="0px"
      maxHeight={0}>
      <Wrapper>
        <FlexCol>
          <HookForm.Input
            control={control}
            placeholder="Enter amount"
            name="amount_charge"
            fieldType={{ formatterName: 'dollarCurrencyInput' }}
          />
          <HookForm.Textarea
            control={control}
            placeholder="Enter description"
            name="action_description"
            fieldType={fieldTypes.TEXT}
            register={register}
            rows={8}
          />
          {!modalData.hasRewardReduce && (
            <Checkbox
              green
              onChange={handleChangeSendNotification}
              checked={showNotification}
              label="Send Notification"
              wrapperCSS={checkboxWrapperCSS}
            />
          )}
        </FlexCol>
        {showNotification && (
          <>
            <Title>Send Push to the Dobby app</Title>
            <HookForm.Input
              control={control}
              hasSmallLegthTitle
              label="Heading*"
              lengthInfoPrefix="Restricted length"
              rules={{
                maxLength: 30
              }}
              name="title"
              placeholder="Enter heading"
            />
            <Box marginTop={8}>
              <HookForm.ControlledTextarea
                control={control}
                hasSmallLegthTitle
                rules={{
                  maxLength: 170
                }}
                name="notification_body"
                label="Description (optional)"
                lengthInfoPrefix="Restricted length"
                placeholder="Enter description"
                rows={4}
              />
            </Box>
          </>
        )}
        <Box marginTop={24}>
          <FlexRow>
            <Button variant="primary" onClick={handleClose}>
              Cancel
            </Button>
            <Button disabled={!isValid} onClick={() => setIsShowConfirmation(true)}>
              {modalData.hasRewardReduce ? 'Remove ' : 'Add '}
              Reward
            </Button>
          </FlexRow>
        </Box>
      </Wrapper>

      <ModalConfirmation
        visible={isShowConfirmation}
        title={`Confirm Dobby reward ${modalData.hasRewardReduce ? 'removal' : 'addition'}`}
        hideModal={() => setIsShowConfirmation(false)}
        primaryButton={{
          title: 'Keep Editing',
          onClick: () => setIsShowConfirmation(false),
          disabled: hasManageRewardLoading
        }}
        secondaryButton={{
          title: `${modalData.hasRewardReduce ? 'Remove' : 'Add'} $${formatters.commaSeparated(amount)} Reward`,
          onClick: handleSubmit(handleFormSubmit),
          disabled: hasManageRewardLoading,
          loading: hasManageRewardLoading
        }}
      />
    </Modal>
  );
};

export default ManageReward;
