import styled, { css } from 'styled-components';
import styles from 'constants/styles';

export const SpinnerContainer = styled.div`
  padding: 7px 0;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CallRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const UserAvatarWrapper = styled.div`
  margin-right: 15px;
`;

export const UserFullNameRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const NameDurationColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const UserFullName = styled.div`
  height: 28px;
  font-weight: 700;
  font-size: 24px;
  line-height: 1.29;

  color: ${({ theme }) => theme.colors.WHITE};

  margin-right: 80px;
`;

export const CallDuration = styled.div`
  height: 22px;
  font-weight: 500;
  font-size: 18px;
  line-height: 1.27;
  color: ${({ theme }) => theme.colors.DARK_GREY};
`;

export const Controls = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const baseButtonStyles = css`
  border: 0;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  box-shadow: ${styles.boxShadows.THIN_DARK};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4px;
  margin-right: 8px;
`;

export const CallButton = styled.button`
  ${baseButtonStyles}
  background: ${({ disabled }) => (disabled ? styles.colors.GREEN : styles.colors.WHITE)};
  &:hover {
    div svg path {
      fill: ${styles.colors.WHITE};
    }
    background: ${styles.colors.GREEN};
  }
`;

export const CallEndButton = styled.button`
  ${baseButtonStyles}
  background: ${styles.colors.RED};
  &:hover {
    div svg path {
      fill: ${styles.colors.BLACK};
    }
    background: ${styles.colors.WHITE};
  }
`;

export const MuteButton = styled.button`
  ${baseButtonStyles}
  border: 2px solid ${styles.colors.GRAY};
  background: ${({ isMuted = false }) => (isMuted ? '#EDEDEE' : styles.colors.WHITE)};
  margin-right: 16px;
  &:hover {
    background: ${({ isMuted = false }) => (isMuted ? styles.colors.WHITE : '#EDEDEE')};
  }
`;
