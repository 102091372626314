import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Wrapper = styled.section`
  padding-top: 30px;
  margin-bottom: 40px;
  position: relative;
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;
`;

export const Title = styled.h1`
  font-size: 50px;
  line-height: 1;
  text-align: center;
  margin-bottom: 16px;
  margin-top: 0;
  color: ${({ theme }) => theme.colors.WHITE};
`;

export const Subtitle = styled.h4`
  font-size: 18px;
  line-height: 1.66;
  margin: 0 0 40px 0;
  text-align: center;
  color: ${({ theme }) => theme.colors.WHITE};
`;

export const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.GREEN};
  text-decoration: none;
  text-align: center;
  position: relative;
  padding-left: 30px;
  padding-right: 30px;
  display: inline-block;

  &:before,
  &:after {
    transition: right 0.2s ease-in-out;
    position: absolute;
    right: 5px;
    display: block;
    content: '';
    border-top: 3px solid ${({ theme }) => theme.colors.GREEN};
    width: 14px;
  }
  &:before {
    transform: rotate(45deg);
    top: 10px;
  }
  &:after {
    transform: rotate(-45deg);
    top: 18px;
  }
  &:hover {
    &:before,
    &:after {
      right: 0;
    }
  }
`;
