import React from 'react';

import { useTheme, useWatch } from 'hooks';
import { providerStages, providerCohorts } from 'common/enums/user';
import { checkProviderAccess } from 'common/helpers/user';
import { HookForm } from 'components';

import { generateSpecialtiesListOptions, generateServiceAreaListOptions } from 'common/helpers/user';
import { generateProStageOptions } from './helpers/form';
import { ContentColumnWrapper, HorizontalLine, RightSectionFlexRow, dropdownStyles } from './styled';

export const ProTagSelects = ({
  control,
  areaList,
  initialValues,
  is_active,
  is_api_active,
  is_cognito_active,
  is_pre_filled
}) => {
  const theme = useTheme();

  const [proCohort, proStage, autoAssignServiceArea, oneClickServiceArea] = useWatch({
    control,
    name: ['pro_cohort', 'pro_stage', 'auto_assign_service_area', 'one_click_service_area'],
    defaultValue: [
      initialValues.pro_cohort,
      initialValues.pro_stage,
      initialValues.auto_assign_service_area,
      initialValues.one_click_service_area
    ]
  });

  const { hasProStage, hasParked, hasAutoAreaCategoryDisabled } = checkProviderAccess({
    is_pre_filled,
    is_api_active,
    is_active,
    is_cognito_active,
    pro_cohort: proCohort,
    pro_stage: initialValues.pro_stage
  });

  return (
    <ContentColumnWrapper>
      <RightSectionFlexRow>
        <HookForm.Radio
          label="Set access"
          name="pro_cohort"
          control={control}
          rules={{ required: true }}
          row
          options={[
            { name: 'N/A', value: providerCohorts.DEFAULT.TYPE },
            { name: 'Core', value: providerCohorts.CORE.TYPE },
            { name: 'Parked', value: providerCohorts.PARKED.TYPE }
          ]}
        />
      </RightSectionFlexRow>
      {hasProStage && (
        <RightSectionFlexRow>
          <HookForm.Select
            hasDisabledOpacity={hasParked}
            name="pro_stage"
            label="Stage"
            placeholder="Select Stage"
            control={control}
            options={generateProStageOptions()}
            style={dropdownStyles}
            hasHeaderHidden
            placeholderColor={theme.colors.DARK_GREY_2}
            width={311}
          />
        </RightSectionFlexRow>
      )}

      {[providerStages.ONE_CLICK_BOOKING.TYPE, providerStages.NEW_HO_PRO.TYPE].includes(proStage) && (
        <>
          <RightSectionFlexRow>
            {!hasAutoAreaCategoryDisabled && (
              <HookForm.SelectMultiple
                name="auto_assign_service_area"
                label="Auto assignment area"
                control={control}
                placeholder="Select auto assignmet area"
                options={generateServiceAreaListOptions(areaList)}
                defaultValue={initialValues.auto_assign_service_area}
              />
            )}
            {hasAutoAreaCategoryDisabled && (
              <HookForm.SelectMultiple
                hasDisabledOpacity
                name="auto_assign_service_area_parked"
                label="Auto assignment area"
                control={control}
                placeholder="Select auto assignmet area"
                options={generateServiceAreaListOptions(areaList)}
                defaultValue={[]}
              />
            )}
          </RightSectionFlexRow>

          {Boolean(autoAssignServiceArea?.length) && !hasAutoAreaCategoryDisabled && (
            <RightSectionFlexRow>
              <HookForm.Select
                name="auto_assign_service_id"
                label="Auto assignment category"
                placeholder="Select auto assignmet category"
                control={control}
                rules={{
                  required: true
                }}
                options={generateSpecialtiesListOptions()}
                style={dropdownStyles}
                hasHeaderHidden
                placeholderColor={theme.colors.DARK_GREY_2}
                width={311}
              />
            </RightSectionFlexRow>
          )}
        </>
      )}

      {[providerStages.ONE_CLICK_BOOKING.TYPE].includes(proStage) && (
        <>
          <HorizontalLine hasDisabledOpacity={hasAutoAreaCategoryDisabled} />
          <RightSectionFlexRow>
            {!hasAutoAreaCategoryDisabled && (
              <HookForm.SelectMultiple
                name="one_click_service_area"
                label="1-click booking area"
                control={control}
                placeholder="Select 1-click booking area"
                options={generateServiceAreaListOptions(areaList)}
                defaultValue={initialValues.one_click_service_area}
              />
            )}
            {/* We need to use pseudo select not to reset the value of one_click booking */}
            {hasAutoAreaCategoryDisabled && (
              <HookForm.SelectMultiple
                hasDisabledOpacity
                name="one_click_service_area_parked"
                label="1-click booking area"
                control={control}
                placeholder="Select 1-click booking area"
                options={generateServiceAreaListOptions(areaList)}
                defaultValue={[]}
              />
            )}
          </RightSectionFlexRow>
          {Boolean(oneClickServiceArea?.length) && !hasAutoAreaCategoryDisabled && (
            <RightSectionFlexRow>
              <HookForm.Select
                name="one_click_service_id"
                label="1-click booking category"
                placeholder="Select 1-click booking category"
                control={control}
                rules={{
                  required: true
                }}
                options={generateSpecialtiesListOptions()}
                style={dropdownStyles}
                hasHeaderHidden
                placeholderColor={theme.colors.DARK_GREY_2}
                width={311}
              />
            </RightSectionFlexRow>
          )}
        </>
      )}
    </ContentColumnWrapper>
  );
};
