import actionTypes from 'constants/actionTypes';
import { smsMessageTransformer, removeDuplicated } from 'utils';

const defaultSmsList = {
  count: 0,
  loading: false,
  list: [],
  page: 1
};

const defaultState = {
  listLoading: false,
  count: 0,
  list: [],
  smsList: {}
};

function reducer(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.GET_CONVERSATION_LIST_FETCH: {
      return {
        ...state,
        listLoading: true
      };
    }
    case actionTypes.GET_CONVERSATION_LIST_SUCCESS: {
      return {
        ...state,
        listLoading: false,
        count: action.payload.count,
        list: action.payload.list
      };
    }
    case actionTypes.GET_CONVERSATION_LIST_ERROR: {
      return {
        ...state,
        listLoading: false,
        count: 0,
        list: []
      };
    }
    case actionTypes.UPDATE_CONVERSATION_SUCCESS: {
      const data = action.payload;
      return {
        ...state,
        list: state.list.map((item) =>
          item.id === data.id
            ? {
                ...data,
                ...(item.message_id
                  ? {
                      message_id: item.message_id,
                      datetime_last_message: item.datetime_last_message,
                      last_message: item.last_message
                    }
                  : {})
              }
            : item
        )
      };
    }

    case actionTypes.GET_COMMUNICATION_SMS_LIST_FETCH: {
      const { user_id, init } = action.payload;
      return {
        ...state,
        smsList: {
          ...state.smsList,
          [user_id]: {
            ...(init || !state.smsList[user_id] ? defaultSmsList : state.smsList[user_id]),
            loading: true
          }
        }
      };
    }
    case actionTypes.GET_COMMUNICATION_SMS_LIST_SUCCESS: {
      const { user_id, id_lt, list } = action.payload;

      const updatedList = removeDuplicated(
        id_lt
          ? [...(state.smsList[user_id]?.list || []), ...(list || [])]
          : [...(list || []), ...(state.smsList[user_id]?.list || [])],
        '_id'
      );

      return {
        ...state,
        smsList: {
          ...state.smsList,
          [user_id]: {
            list: updatedList,
            count: action.payload.count,
            page: action.payload.page,
            loading: false
          }
        }
      };
    }
    case actionTypes.GET_COMMUNICATION_SMS_LIST_ERROR: {
      const { user_id } = action.payload;
      return {
        ...state,
        smsList: {
          ...state.smsList,
          [user_id]: defaultSmsList
        }
      };
    }

    case actionTypes.CREATE_COMMUNICATION_SMS_FETCH: {
      const { user_id } = action.payload;
      return {
        ...state,
        smsList: {
          ...state.smsList,
          [user_id]: {
            ...state.smsList[user_id],
            list: [smsMessageTransformer({ ...action.payload, direction: 'out' }), ...state.smsList[user_id].list],
            count: state.smsList[user_id].count + 1
          }
        }
      };
    }
    case actionTypes.CREATE_COMMUNICATION_SMS_SUCCESS: {
      const { tmpId, ...data } = action.payload;
      return {
        ...state,
        smsList: {
          ...state.smsList,
          [action.payload.user_id]: {
            ...state.smsList[action.payload.user_id],
            list: state.smsList[action.payload.user_id].list.map((item) => (item.id === tmpId ? data : item))
          }
        }
      };
    }

    case actionTypes.CREATE_COMMUNICATION_SMS_ERROR: {
      const { tmpId, user_id } = action.payload.tmpId;
      return {
        ...state,
        smsList: {
          ...state.smsList,
          [user_id]: {
            ...state.smsList[user_id],
            list: state.smsList[user_id].list.filter((item) => item.id !== tmpId),
            count: state.smsList[user_id].count - 1
          }
        }
      };
    }

    default:
      return state;
  }
}

// selectors
export const selectCommunicationListLoading = (state) => state.communication.listLoading;
export const selectCommunicationList = (state) => state.communication.list;
export const selectCommunicationListByUserId = (state, user_id) =>
  state.communication.list.find((item) => item.user_id === user_id);
export const selectCommunicationCount = (state) => state.communication.count;
export const selectSmsList = (state, user_id) => state.communication.smsList[user_id] || defaultSmsList;

export default reducer;
