import React from 'react';

import { closeModal } from 'store/actions';
import modalTypes from 'constants/modalTypes';

import { updateSkill } from 'store/actions/provider';
import { selectModalData } from 'store/reducers/modal';

import { Button, Modal, HookForm } from 'components';
import { useDispatch, useSelector } from 'hooks';
import { Heading, Buttons, Content } from './styled';
import { useForm, useWatch } from 'react-hook-form';
import { selectSkillsUpdateLoading } from 'store/reducers/provider';

const UpdateSkill = ({ modalType = modalTypes.UPDATE_SKILL }) => {
  const dispatch = useDispatch();
  const {
    skill: { name, id }
  } = useSelector(selectModalData);

  const { control } = useForm({
    mode: 'onChange',
    defaultValues: { name }
  });

  const values = useWatch({ control });
  const loading = useSelector(selectSkillsUpdateLoading);
  const hideModal = () => dispatch(closeModal(modalType));
  return (
    <Modal
      modalType={modalType}
      hideModal={hideModal}
      padding="0"
      maxHeight={608}
      heading={<Heading>Edit skill</Heading>}>
      <Content>
        <HookForm.Input name="name" control={control} placeholder="Enter skill name" />
        <Buttons>
          <Button variant="primary" onClick={hideModal}>
            Cancel
          </Button>
          <Button
            loading={loading}
            disabled={!values?.name?.trim() || values?.name?.length > 60}
            onClick={() => dispatch(updateSkill({ name: values?.name, id }))}>
            Rewrite
          </Button>
        </Buttons>
      </Content>
    </Modal>
  );
};

export default UpdateSkill;
