import { call, takeLatest, put } from 'redux-saga/effects';

import api_constants from 'constants/api';
import errors from 'constants/errors';
import actionTypes from 'constants/actionTypes';
import modalTypes from 'constants/modalTypes';
import { openModal } from 'store/actions';

import Api from 'services/ApiService';

function* expirePromoCode({ payload }) {
  const { referralCode } = payload;

  try {
    const response = yield call(Api.amplifyPut, `${api_constants.PROMO_CODE}/${referralCode}`, { suspend: true });

    if (!response || response.error_message || response.err) {
      throw new Error(response?.err);
    }

    yield put({
      type: actionTypes.EXPIRE_PROMO_CODE_SUCCESS,
      payload: response
    });

    yield put(
      openModal(modalTypes.RESULT, {
        title: `The ${referralCode} successfully expired`
      })
    );
  } catch (err) {
    yield put({
      type: actionTypes.EXPIRE_PROMO_CODE_ERROR
    });

    yield put(
      openModal(modalTypes.ERROR, {
        errorMessage: err?.message || err?.name || errors.DEFAULT
      })
    );
  }
}

export default function* watchExpirePromoCode() {
  yield takeLatest(actionTypes.EXPIRE_PROMO_CODE_FETCH, expirePromoCode);
}
