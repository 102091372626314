import React, { useState, useLayoutEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { bundleServices, getServiceList } from 'store/actions/service';
import { closeModal } from 'store/actions/modal';
import {
  selectServiceBundleList,
  selectServiceBundleListLoading,
  selectServiceBundleTotalCount,
  selectBundleLoading
} from 'store/reducers/service';
import { Button, LoadingSpinner } from 'components';
import { Table } from 'components';

import { getColumns, getRows } from './helpers';

import { BundleHeader, ButtonsWrapper, TableContainer, TableFooter, SelectedTaskCount, LoadingWrapper } from './styled';

const Bundle = ({
  serviceId,
  serviceList,
  bundleJobFilters,
  serviceListLoading,
  getServiceList,
  closeModal,
  bundleServices,
  bundleLoading
}) => {
  const [selectedServiceIds, setSelectedServiceIds] = useState([]);

  useLayoutEffect(() => {
    getServiceList({ ...bundleJobFilters, isBundle: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onServiceSelect =
    ({ id: task_id }) =>
    () => {
      if (selectedServiceIds.includes(task_id)) {
        setSelectedServiceIds(selectedServiceIds.filter((id) => task_id !== id));
        return;
      }
      setSelectedServiceIds([...selectedServiceIds, task_id]);
    };

  const isServiceSelected = (serviceId) => selectedServiceIds.includes(serviceId);

  const hasRowClickDisabled = useCallback(({ id: rowJobId }) => serviceId === rowJobId, [serviceId]);

  const onBundleSubmit = () => {
    bundleServices({
      id: serviceId,
      requests: selectedServiceIds
    });
  };

  if (serviceListLoading) {
    return (
      <LoadingWrapper>
        <LoadingSpinner text="Please wait" type="logo" />
      </LoadingWrapper>
    );
  }

  const targetJob = serviceList.find((service) => service.id === serviceId);
  const sourceJobs = serviceList.filter((service) => service.id !== serviceId);
  const mergedJobs = [...(targetJob ? [targetJob] : []), ...sourceJobs];

  return (
    <>
      <BundleHeader>Bundle Related Jobs</BundleHeader>
      <TableContainer>
        <Table
          onRowClick={onServiceSelect}
          hasRowClickDisabled={hasRowClickDisabled}
          columns={getColumns({
            currentTaskID: serviceId,
            isTaskSelected: isServiceSelected
          })}
          data={getRows({ jobs: mergedJobs })}
        />
      </TableContainer>
      <TableFooter>
        <SelectedTaskCount>{selectedServiceIds.length} Jobs selected</SelectedTaskCount>
        <ButtonsWrapper>
          <Button variant="primary" onClick={closeModal} title="Cancel" disabled={bundleLoading} />
          <Button
            onClick={onBundleSubmit}
            title="Bundle"
            disabled={!selectedServiceIds.length || bundleLoading}
            loading={bundleLoading}
          />
        </ButtonsWrapper>
      </TableFooter>
    </>
  );
};

const mapStateToProps = (state) => ({
  serviceList: selectServiceBundleList(state),
  serviceCount: selectServiceBundleTotalCount(state),
  serviceListLoading: selectServiceBundleListLoading(state),
  bundleLoading: selectBundleLoading(state)
});

const mapDispatchToProps = {
  bundleServices,
  closeModal,
  getServiceList
};

Bundle.propTypes = {
  serviceId: PropTypes.number.isRequired,
  bundleJobFilters: PropTypes.object.isRequired,
  serviceList: PropTypes.array.isRequired,
  serviceListLoading: PropTypes.bool.isRequired,
  getServiceList: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  bundleServices: PropTypes.func.isRequired,
  bundleLoading: PropTypes.bool.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(Bundle);
