import { call, takeLatest, put } from 'redux-saga/effects';
import api_constants from 'constants/api';
import actionTypes from 'constants/actionTypes';
import Api from 'services/ApiService';

import { getServiceLocation } from '../../actions/service';

function* updateCustomerLocation(action) {
  const {
    payload: { customerLocationId, serviceId, data }
  } = action;
  try {
    const response = yield call(Api.amplifyPut, `${api_constants.CUSTOMER_LOCATION}/${customerLocationId}`, data);
    if (response) {
      yield put({
        type: actionTypes.UPDATE_CUSTOMER_LOCATION_SUCCESS,
        payload: response
      });
      yield put(getServiceLocation({ serviceId, silent: true }));
    } else {
      throw new Error(response?.error_message);
    }
  } catch (err) {
    yield put({
      type: actionTypes.UPDATE_CUSTOMER_LOCATION_ERROR,
      payload: err?.message
    });
    console.log(err);
  }
}

export default function* watchUpdateCustomerLocation() {
  yield takeLatest(actionTypes.UPDATE_CUSTOMER_LOCATION_FETCH, updateCustomerLocation);
}
