import { call, takeLatest, put } from 'redux-saga/effects';
import api_constants from 'constants/api';
import actionTypes from 'constants/actionTypes';
import Api from 'services/ApiService';

function* getAgentCharges({ payload }) {
  try {
    const response = yield call(Api.amplifyGet, `${api_constants.AGENT_CHARGES}/?user_id=${payload}`);
    console.log('getAgentCharges response', response);
    yield put({
      type: actionTypes.GET_AGENT_CHARGES_SUCCESS,
      payload: response.results
    });
  } catch (error) {
    console.error(error);
    yield put({
      type: actionTypes.GET_AGENT_CHARGES_ERROR
    });
  }
}

export default function* watchGetCustomerDobbyCashTransactions() {
  yield takeLatest(actionTypes.GET_AGENT_CHARGES_FETCH, getAgentCharges);
}
