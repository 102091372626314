import actionTypes from 'constants/actionTypes';

export const getAgentList = (data) => ({
  type: actionTypes.GET_AGENT_LIST_FETCH,
  payload: data
});

export const updateAgentInstance = (data, recordIndex) => ({
  type: actionTypes.UPDATE_AGENT_INSTANCE_FETCH,
  payload: data,
  recordIndex: recordIndex
});

export const getAgentInstance = (id) => ({
  type: actionTypes.GET_AGENT_INSTANCE_FETCH,
  payload: id
});

export const getAgentLocationList = (payload) => ({
  type: actionTypes.GET_AGENT_LOCATION_LIST_FETCH,
  payload
});

export const getAgentCharges = (payload) => ({
  type: actionTypes.GET_AGENT_CHARGES_FETCH,
  payload
});
