import { columnNames } from 'common/enums/sortings';

export const customerTable = {
  AVATAR: {
    TITLE: '',
    FILTER_NAME: columnNames.AVATAR
  },
  ID: {
    TITLE: 'User ID',
    FILTER_NAME: columnNames.ID
  },
  GIVEN_NAME: {
    TITLE: 'First Name',
    FILTER_NAME: columnNames.GIVEN_NAME
  },
  FAMILY_NAME: {
    TITLE: 'Last Name',
    FILTER_NAME: columnNames.FAMILY_NAME
  },
  ADDRESS: {
    TITLE: 'Address',
    FILTER_NAME: columnNames.ADDRESS
  },
  DATETIME_CREATED: {
    TITLE: 'Signed Up',
    FILTER_NAME: columnNames.DATETIME_CREATED
  },
  LIFETIME_SPEND: {
    TITLE: 'Lifetime Spend',
    FILTER_NAME: columnNames.LIFETIME_SPEND
  },
  LIFETIME_MARGIN: {
    TITLE: 'Lifetime Margin',
    FILTER_NAME: columnNames.LIFETIME_MARGIN
  },
  COUNT_TASK_COMPLETED: {
    TITLE: '# of completed jobs',
    FILTER_NAME: columnNames.COUNT_TASK_COMPLETED
  },
  DAYS_SINCE_SIGN_UP: {
    TITLE: '# of days since sign up',
    FILTER_NAME: columnNames.DAYS_SINCE_SIGN_UP
  },
  DAYS_SINCE_LAST_REQUESTED: {
    TITLE: '# of days since last requested',
    FILTER_NAME: columnNames.DAYS_SINCE_LAST_REQUESTED
  },
  DAYS_SINCE_LAST_COMPLETED: {
    TITLE: '# of days since last completed',
    FILTER_NAME: columnNames.DAYS_SINCE_LAST_COMPLETED
  },
  DAYS_SINCE_LAST_OPEN_APP: {
    TITLE: '# of days since last open app',
    FILTER_NAME: columnNames.DAYS_SINCE_LAST_OPEN_APP
  },
  DAYS_SINCE_LAST_ACTIVE: {
    TITLE: '# of days since last communication received',
    FILTER_NAME: columnNames.DAYS_SINCE_LAST_ACTIVE
  },
  INVITED_BY: {
    TITLE: 'Invited By',
    FILTER_NAME: columnNames.INVITED_BY
  }
};
