import React from 'react';
import { useSelector } from 'hooks';
import { selectCampaignById, selectHasCampaignLoading } from 'store/reducers/campaigns';

import { Breadcrumb } from '../../components';
import { BreadcrumbElipsis } from '../../styled';

export const CampaignBreadcrumb = ({ match, location }) => {
  const { campaign_id } = match.params;
  const campaign = useSelector((state) => selectCampaignById(state, Number(campaign_id)));
  const hasLoading = useSelector(selectHasCampaignLoading);

  return (
    <BreadcrumbElipsis active={match.pathname === location.pathname}>
      <Breadcrumb
        hasLoading={hasLoading}
        breadcrumbName={!hasLoading ? campaign?.campaign_push?.template_title || campaign_id : ''}
        isActive={match.pathname === location.pathname}
        pathname={match.pathname}
      />
    </BreadcrumbElipsis>
  );
};
