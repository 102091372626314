import { call, takeEvery, put } from 'redux-saga/effects';
import api_constants from 'constants/api';
import actionTypes from 'constants/actionTypes';
import errors from 'constants/errors';
import Api from 'services/ApiService';

function* getAnalyticSnapshot() {
  try {
    const response = yield call(Api.amplifyGet, api_constants.ANALYTIC_SNAPSHOT);

    if (!response || response?.err) {
      throw new Error(errors.DEFAULT);
    }

    yield put({
      type: actionTypes.GET_ANALYTIC_SNAPSHOT_SUCCESS,
      payload: response
    });
  } catch (err) {
    yield put({
      type: actionTypes.GET_ANALYTIC_SNAPSHOT_ERROR
    });
    console.log(err);
  }
}

export default function* watchGetAnalyticSnapshot() {
  yield takeEvery(actionTypes.GET_ANALYTIC_SNAPSHOT_FETCH, getAnalyticSnapshot);
}
