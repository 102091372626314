import { sidebarMenuItems, navigationTabs } from 'common/enums/navigation';
import { cellTypes } from 'common/enums/table';
import { jobQuotedStatuses } from 'common/enums/invoice';
import { invoiceTypes } from 'common/enums/invoice';

import content from 'constants/content';
import dates from 'constants/dates';
import styles from 'constants/styles';

import {
  formatters,
  formatDateShortTimeStampSmall,
  getWarningDescriptionById,
  getWarningColorById,
  getWarningNameById
} from 'utils';
import { getTimeWindow, getAuthorByType, prepareStageTableCell } from 'common/helpers/job';

import { taskTable } from '../enums';

export const getColumns = ({ menuItemType, tabId }) => {
  const {
    REQUESTED,
    ASSIGNED,
    ACCEPTED,
    SCHEDULED,
    PAST_SCHEDULED,
    COMPLETED,
    INVOICED,
    INVOICE_ADMIN_PENDING,
    PAID,
    ALL_JOBS,
    UNFULFILLED,
    OWLERY
  } = sidebarMenuItems;
  return [
    ...([INVOICED.TYPE].includes(menuItemType)
      ? [
          {
            id: taskTable.STAGE.COLUMN_NAME,
            title: taskTable.STAGE.TITLE,
            cellType: cellTypes.LABEL,
            hasSorting: true,
            minWidth: 190,
            accessor: ({ admin_status, datetime_visit, datetime_window_end, is_past_scheduled }) =>
              prepareStageTableCell({ admin_status, datetime_visit, datetime_window_end, is_past_scheduled })
          }
        ]
      : []),
    {
      id: taskTable.ID.COLUMN_NAME,
      title: taskTable.ID.TITLE,
      cellType: cellTypes.TEXT,
      minWidth: 70,
      accessor: ({ id }) => ({
        text: String(id),
        hasHighlight: true
      })
    },
    {
      id: taskTable.HO_GIVEN_NAME.COLUMN_NAME,
      title: taskTable.HO_GIVEN_NAME.TITLE,
      cellType: cellTypes.USER_NAME,
      minWidth: 120,
      accessor: ({ customer }) => {
        return {
          given_name: customer.given_name,
          family_name: customer.family_name,
          stage: customer.stage,
          hasHighlight: true
        };
      }
    },
    ...(menuItemType !== OWLERY.TYPE
      ? [
          {
            id: taskTable.HO_ID.COLUMN_NAME,
            title: taskTable.HO_ID.TITLE,
            cellType: cellTypes.TEXT,
            minWidth: 90,
            hasSorting: false,
            accessor: ({ customer }) => ({
              text: String(customer.id),
              hasHighlight: true
            })
          }
        ]
      : []),
    ...(REQUESTED.TYPE !== menuItemType
      ? [
          {
            id: taskTable.PRO_GIVEN_NAME.COLUMN_NAME,
            title: taskTable.PRO_GIVEN_NAME.TITLE,
            cellType: cellTypes.USER_NAME,
            minWidth: 150,
            accessor: ({ provider = {}, pro_stage }) => ({
              given_name: provider.given_name,
              family_name: provider.family_name,
              pro_stage,
              hasHighlight: true
            })
          }
        ]
      : []),
    ...(![REQUESTED.TYPE, OWLERY.TYPE].includes(menuItemType)
      ? [
          {
            id: taskTable.PRO_ID.COLUMN_NAME,
            title: taskTable.PRO_ID.TITLE,
            cellType: cellTypes.TEXT,
            minWidth: 100,
            hasSorting: false,
            accessor: ({ provider = {} }) => {
              return {
                text: Boolean(provider.id) ? String(provider.id) : '',
                hasHighlight: true
              };
            }
          }
        ]
      : []),
    ...([ALL_JOBS.TYPE].includes(menuItemType)
      ? [
          {
            id: taskTable.STAGE.COLUMN_NAME,
            title: taskTable.STAGE.TITLE,
            cellType: cellTypes.LABEL,
            hasSorting: true,
            minWidth: 190,
            accessor: ({ admin_status, datetime_visit, datetime_window_end, is_past_scheduled }) =>
              prepareStageTableCell({ admin_status, datetime_visit, datetime_window_end, is_past_scheduled })
          }
        ]
      : []),
    ...([SCHEDULED.TYPE, PAST_SCHEDULED.TYPE].includes(menuItemType)
      ? [
          {
            id: taskTable.DATETIME_VISIT_CURRENT.COLUMN_NAME,
            title: taskTable.DATETIME_VISIT_CURRENT.TITLE,
            cellType: cellTypes.LABEL,
            minWidth: 200,
            accessor: ({ datetime_visit, datetime_window_end, is_past_scheduled }) => ({
              maxWidth: 230,
              isEllipsis: true,
              color: is_past_scheduled ? styles.colors.GRAY : styles.colors.DARK_GREEN,
              text: getTimeWindow({ datetime_visit, datetime_window_end })
            })
          }
        ]
      : []),
    ...([REQUESTED.TYPE, ASSIGNED.TYPE, ACCEPTED.TYPE, SCHEDULED.TYPE, PAST_SCHEDULED.TYPE, ALL_JOBS.TYPE].includes(
      menuItemType
    ) ||
    (menuItemType === OWLERY.TYPE && [navigationTabs.OWLERY.TYPE, navigationTabs.HOLD.TYPE].includes(tabId))
      ? [
          {
            id: taskTable.DATETIME_CREATED.COLUMN_NAME,
            title: taskTable.DATETIME_CREATED.TITLE,
            cellType: cellTypes.TEXT,
            minWidth: 150,
            accessor: ({ date_created }) => ({
              text: formatters.dateWithTimezone(date_created, dates.DATE)
            })
          }
        ]
      : []),
    ...(OWLERY.TYPE === menuItemType && navigationTabs.ACCEPTED.TYPE === tabId
      ? [
          {
            id: taskTable.DATETIME_ACCEPTED.COLUMN_NAME,
            title: taskTable.DATETIME_ACCEPTED.TITLE,
            cellType: cellTypes.TEXT,
            minWidth: 150,
            accessor: ({ datetime_accepted }) => ({
              text: formatters.dateWithTimezone(datetime_accepted, dates.DATE)
            })
          }
        ]
      : []),
    ...(OWLERY.TYPE === menuItemType && navigationTabs.PAST_SCHEDULED.TYPE === tabId
      ? [
          {
            id: taskTable.DATETIME_VISIT.COLUMN_NAME,
            title: taskTable.DATETIME_VISIT.TITLE,
            cellType: cellTypes.LABEL,
            minWidth: 200,
            accessor: ({ datetime_visit, datetime_window_end, is_past_scheduled }) => ({
              maxWidth: 230,
              isEllipsis: true,
              color: is_past_scheduled ? styles.colors.GRAY : styles.colors.DARK_GREEN,
              text: getTimeWindow({ datetime_visit, datetime_window_end })
            })
          }
        ]
      : []),
    ...(OWLERY.TYPE === menuItemType && navigationTabs.QUOTED_JOBS.TYPE === tabId
      ? [
          {
            id: taskTable.DATETIME_QUOTED.COLUMN_NAME,
            title: taskTable.DATETIME_QUOTED.TITLE,
            cellType: cellTypes.TEXT,
            minWidth: 150,
            accessor: ({ datetime_quote }) => ({
              text: formatters.dateWithTimezone(datetime_quote, dates.DATE)
            })
          }
        ]
      : []),
    ...(OWLERY.TYPE === menuItemType && [navigationTabs.HOLD.TYPE].includes(tabId)
      ? [
          {
            id: taskTable.DATETIME_HOLD.COLUMN_NAME,
            title: taskTable.DATETIME_HOLD.TITLE,
            cellType: cellTypes.TEXT,
            minWidth: 140,
            accessor: ({ datetime_hold }) => ({
              text: formatters.dateWithTimezone(datetime_hold, dates.DATE)
            })
          }
        ]
      : []),
    ...([COMPLETED.TYPE, PAID.TYPE, INVOICED.TYPE].includes(menuItemType)
      ? [
          {
            id: taskTable.DATETIME_COMPLETED.COLUMN_NAME,
            title: taskTable.DATETIME_COMPLETED.TITLE,
            cellType: cellTypes.TEXT,
            minWidth: 165,
            accessor: ({ datetime_completed }) => ({
              text: formatters.dateWithTimezone(datetime_completed, dates.DATE)
            })
          }
        ]
      : []),
    ...([UNFULFILLED.TYPE].includes(menuItemType)
      ? [
          {
            id: taskTable.DATETIME_CANCELLED.COLUMN_NAME,
            title: taskTable.DATETIME_CANCELLED.TITLE,
            cellType: cellTypes.TEXT,
            minWidth: 120,
            accessor: ({ datetime_canceled }) => ({
              text: formatters.dateWithTimezone(datetime_canceled, dates.DATE)
            })
          }
        ]
      : []),
    ...(OWLERY.TYPE !== menuItemType
      ? [
          {
            id: taskTable.JOB_CATEGORY_NAME.COLUMN_NAME,
            title: taskTable.JOB_CATEGORY_NAME.TITLE,
            cellType: cellTypes.TEXT,
            minWidth: 180,
            accessor: ({ job_category_name }) => ({
              text: job_category_name
            })
          }
        ]
      : []),
    ...([
      ACCEPTED.TYPE,
      SCHEDULED.TYPE,
      PAST_SCHEDULED.TYPE,
      COMPLETED.TYPE,
      INVOICED.TYPE,
      ALL_JOBS.TYPE,
      UNFULFILLED.TYPE,
      OWLERY.TYPE
    ].includes(menuItemType)
      ? [
          {
            id: taskTable.IS_QUOTED.COLUMN_NAME,
            hasSorting: true,
            minWidth: 100,
            title: taskTable.IS_QUOTED.TITLE,
            cellType: cellTypes.LABEL,
            accessor: ({ is_quoted }) => {
              const jobQuotedStatus =
                Object.values(jobQuotedStatuses).find(({ TYPE }) => TYPE === is_quoted) || jobQuotedStatuses.DEFAULT;
              return {
                alignLeft: jobQuotedStatus === jobQuotedStatuses.DEFAULT,
                color: jobQuotedStatus.COLOR,
                text: jobQuotedStatus.NAME
              };
            }
          },
          {
            id: taskTable.AMOUNT_ESTIMATED.COLUMN_NAME,
            hasSorting: true,
            minWidth: 130,
            title: taskTable.AMOUNT_ESTIMATED.TITLE,
            cellType: cellTypes.TEXT,
            accessor: ({ expected_value }) => ({
              text: Boolean(expected_value) ? formatters.dollarCurrencyDB(expected_value) : '-'
            })
          }
        ]
      : []),
    ...([INVOICE_ADMIN_PENDING.TYPE].includes(menuItemType)
      ? [
          {
            id: taskTable.DATETIME_CREATED_INVOICE_PENDING.COLUMN_NAME,
            title: taskTable.DATETIME_CREATED_INVOICE_PENDING.TITLE,
            cellType: cellTypes.TEXT,
            minWidth: 120,
            accessor: ({ invoice_pending }) => ({
              text: invoice_pending?.datetime_created
                ? formatters.dateWithTimezone(invoice_pending?.datetime_created, dates.DATE)
                : '-'
            })
          }
        ]
      : []),
    ...([INVOICE_ADMIN_PENDING.TYPE].includes(menuItemType)
      ? [
          {
            id: taskTable.INVOICE_TYPE.COLUMN_NAME,
            title: taskTable.INVOICE_TYPE.TITLE,
            cellType: cellTypes.TEXT,
            minWidth: 120,
            hasSorting: false,
            accessor: ({ invoice_pending }) => ({
              text:
                Object.values(invoiceTypes).find(({ TYPE }) => TYPE === invoice_pending?.invoice_type)?.tabelLabel ||
                '-'
            })
          }
        ]
      : []),
    ...([INVOICED.TYPE, INVOICE_ADMIN_PENDING.TYPE].includes(menuItemType)
      ? [
          {
            id: taskTable.AMOUNT_INVOICED.COLUMN_NAME,
            title: taskTable.AMOUNT_INVOICED.TITLE,
            cellType: cellTypes.TEXT,
            hasSorting: true,
            minWidth: 150,
            accessor: ({ amount_invoiced }) => ({
              text: Boolean(amount_invoiced) ? formatters.dollarCurrencyDB(amount_invoiced) : '-'
            })
          }
        ]
      : []),
    ...([PAID.TYPE].includes(menuItemType)
      ? [
          {
            id: taskTable.AMOUNT_ESTIMATED.COLUMN_NAME,
            hasSorting: true,
            minWidth: 130,
            title: taskTable.AMOUNT_ESTIMATED.TITLE,
            cellType: cellTypes.TEXT,
            accessor: ({ expected_value }) => ({
              text: Boolean(expected_value) ? formatters.dollarCurrencyDB(expected_value) : '-'
            })
          }
        ]
      : []),
    ...([PAID.TYPE, ALL_JOBS.TYPE, UNFULFILLED.TYPE].includes(menuItemType)
      ? [
          {
            id: taskTable.TOTAL_PAID.COLUMN_NAME,
            title: taskTable.TOTAL_PAID.TITLE,
            cellType: cellTypes.TEXT,
            minWidth: 135,
            accessor: ({ amount_total_paid }) => ({
              text: amount_total_paid ? formatters.dollarCurrencyDB(amount_total_paid) : '-'
            })
          }
        ]
      : []),
    ...(![INVOICE_ADMIN_PENDING.TYPE, UNFULFILLED.TYPE, OWLERY.TYPE].includes(menuItemType) &&
    tabId !== navigationTabs.PASSABLE.TYPE
      ? [
          {
            id: taskTable.WARNING.COLUMN_NAME,
            title: taskTable.WARNING.TITLE,
            cellType: cellTypes.LABEL,
            minWidth: 120,
            accessor: ({ is_warning_enabled, is_warning_checked, warning_type, warning_value }) => {
              const description = getWarningDescriptionById(warning_type, warning_value);
              const hasWarning = Boolean(
                is_warning_enabled && !is_warning_checked && warning_type !== null && description
              );
              return {
                description,
                color: getWarningColorById(warning_type),
                text: hasWarning ? getWarningNameById(warning_type) : '',
                hasTooltip: true
              };
            }
          }
        ]
      : []),
    // we reverted last message for completed jobs because Nadia and Jackie needed it for operations
    ...([OWLERY.TYPE, INVOICE_ADMIN_PENDING.TYPE, COMPLETED.TYPE].includes(menuItemType)
      ? [
          {
            id: taskTable.LAST_MESSAGE.COLUMN_NAME,
            hasSorting: tabId === navigationTabs.ACCEPTED.TYPE,
            title: taskTable.LAST_MESSAGE.TITLE,
            cellType: cellTypes.RICH_TEXT,
            minWidth: 200,
            accessor: ({ last_message }) => {
              return {
                datetime: formatters.messageDate(last_message?.datetime_sent),
                text: last_message?.message_body,
                secondText: getAuthorByType({ author: last_message?.author_name, type: last_message?.author_type })
              };
            }
          }
        ]
      : []),
    {
      id: taskTable.LAST_NOTE.COLUMN_NAME,
      hasSorting: false,
      title: taskTable.LAST_NOTE.TITLE,
      cellType: cellTypes.RICH_TEXT,
      minWidth: 200,
      accessor: ({ datetime_last_note, last_note }) => ({
        datetime: formatDateShortTimeStampSmall(datetime_last_note),
        text: last_note
      })
    },
    ...(menuItemType === UNFULFILLED.TYPE
      ? [
          {
            id: taskTable.UNFULFILLED_REASON.COLUMN_NAME,
            hasSorting: true,
            title: taskTable.UNFULFILLED_REASON.TITLE,
            cellType: cellTypes.HEADED_TEXT,
            minWidth: 250,
            accessor: ({ unfulfilled_reason, unfulfilled_reason_data }) => {
              const { group } = unfulfilled_reason_data;
              const { name } = content.unfulfilledGroupList?.find((item) => item.value === group) || {};
              const { name: description } =
                content.unfulfilledReasonList?.find((item) => item.value === unfulfilled_reason) || {};
              return {
                header: name,
                text: description
              };
            }
          }
        ]
      : [])
  ];
};
