import React from 'react';
import { CSSTransition } from 'react-transition-group';

import { useDispatch, useEffect, useRef, useSelector, useNotifications, useRouter, useSocket } from 'hooks';
import { useActiveMenuItem } from 'navigation/hooks';
import { conversationTypes } from 'common/enums/chat';
import { pageTypes, notificationTypes } from 'common/enums/notification';
import { userGroups } from 'common/enums/user';
import { getCurrentPageType, getFormattedNotification, isMatchGlobalFilters } from 'common/helpers/notification';

import {
  showNotificationAlert,
  removeNotificationAlert,
  addNotificationToPersistentList,
  deletePersistentNotification
} from 'store/actions/notification';
import { selectNotificationAlerts } from 'store/reducers/notification';
import { isTestTasksVisible, selectFilteredAdminList, selectFilteredGeoLocationList } from 'store/reducers/app';

import { Notification } from 'components';
import { findWsEventType } from './helpers/notification';
import { NotificationsWrapper } from './styled';

const NotificationAlerts = () => {
  const { activeMenuItem } = useActiveMenuItem();
  const dispatch = useDispatch();
  const { handleSidebarBadgesReload, handleBackgroundReload, handleNotificationNavigate } = useNotifications();
  const notificationTimeouts = useRef({});
  const { pathname, location } = useRouter();
  const socket = useSocket();

  const selectedAdmins = useSelector(selectFilteredAdminList);
  const selectedLocations = useSelector(selectFilteredGeoLocationList);
  const notificationAlerts = useSelector(selectNotificationAlerts);
  const isTestTasks = useSelector(isTestTasksVisible);

  useEffect(() => {
    if (socket !== null) {
      socket.addEventListener('message', handleToggleShowNotification);
    }
    return () => {
      if (socket !== null) {
        socket.removeEventListener('message', handleToggleShowNotification);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket, pathname, location.search, isTestTasks, selectedAdmins, selectedLocations, activeMenuItem?.TYPE]);

  const handleRemoveTimeoutById = (id) => {
    const { [id]: _, ...timeouts } = notificationTimeouts.current;
    notificationTimeouts.current = timeouts;
  };

  const handleAlertCLick = (notification) => {
    const { notificationId } = notification;
    dispatch(removeNotificationAlert(notificationId));
    handleNotificationNavigate(notification);

    clearTimeout(notificationTimeouts.current[notificationId]);
    handleRemoveTimeoutById(notificationId);
  };

  const handleHideAlert = ({ formattedNotification }) => {
    const { notificationId, isJobNotification, parsedData, notificationType } = formattedNotification;
    dispatch(removeNotificationAlert(notificationId));
    handleRemoveTimeoutById(notificationId);

    if (notificationType === notificationTypes.COMMUNICATION) {
      // we don't add sms to percictent notifications
      return;
    }

    const currentPageType = getCurrentPageType({ pathname, user_id: parsedData.user_id, job_id: parsedData.job_id });
    const isAgentNotification =
      parsedData.conversation_type === conversationTypes.SUPPORT && parsedData.group_id === userGroups.AGENT.TYPE;
    const isNotificationMatchCurrentPage =
      (currentPageType === pageTypes.CURRENT_AGENT && isAgentNotification) ||
      (currentPageType === pageTypes.CURRENT_JOB && isJobNotification);

    if (isNotificationMatchCurrentPage) {
      dispatch(deletePersistentNotification({ notification_id: notificationId }));
      return;
    }

    dispatch(addNotificationToPersistentList(formattedNotification));
  };

  const handleToggleShowNotification = ({ data: wsEventMessage }) => {
    const parsedWsEventMessage = JSON.parse(wsEventMessage);
    const parsedWsEventMessageData = JSON.parse(parsedWsEventMessage?.data);

    const wsEventType = findWsEventType({
      type: parsedWsEventMessage.type,
      action: parsedWsEventMessage.action,
      data: parsedWsEventMessageData
    });

    const matchGlobalFilters = isMatchGlobalFilters({
      data: {
        operational_area_id: parsedWsEventMessageData.operational_area_id,
        owner_id: parsedWsEventMessageData.owner_id,
        is_test: parsedWsEventMessageData.is_test
      },
      filters: {
        admins: selectedAdmins,
        locations: selectedLocations,
        is_test: isTestTasks
      }
    });

    if (!matchGlobalFilters) {
      return;
    }

    const formattedNotification = getFormattedNotification(parsedWsEventMessage);
    const { notificationId, isJobNotification, redirectType, parsedData, notificationType } = formattedNotification;

    if (!wsEventType || wsEventType.HAS_ALERT) {
      dispatch(showNotificationAlert(formattedNotification));
      notificationTimeouts.current[notificationId] = setTimeout(() => handleHideAlert({ formattedNotification }), 4000);
    }

    if (!wsEventType || wsEventType.RELOAD_PAGES?.length) {
      handleBackgroundReload({
        activeMenuItemType: activeMenuItem.TYPE,
        isJobNotification,
        redirectType,
        job_id: parsedData.job_id,
        user_id: parsedData.user_id,
        notificationType,
        wsEventType,
        parsedWsEventMessage,
        parsedWsEventMessageData
      });
    }

    if (wsEventType?.HAS_SIDEBAR_BADGES_UPDATE) {
      handleSidebarBadgesReload();
    }
  };

  return (
    <NotificationsWrapper>
      {Object.values(notificationAlerts).map((notification, id) => (
        <CSSTransition appear in unmountOnExit key={id} classNames="translate" timeout={300}>
          <Notification width="100%" hasAnimation notification={notification} onClick={handleAlertCLick} />
        </CSSTransition>
      ))}
    </NotificationsWrapper>
  );
};

export default NotificationAlerts;
