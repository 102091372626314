import styled from 'styled-components';
import styles from 'constants/styles';

export const SnoozeWrapper = styled.div`
  min-height: 24px;
  padding: 3px 20px;
  background-color: ${({ backgroundColor = styles.colors.LIGHT_GRAY_3 }) => backgroundColor};

  font-size: 13px;
  font-weight: 400;
  line-height: 1.307;

  display: inline;
`;

export const SnoozeDate = styled.span`
  font-weight: 600;
`;
