import React, { Fragment, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

import { getCRMLinkByGroupId, getFullName } from 'common/helpers/user';
import modalTypes from 'constants/modalTypes';
import routes from 'constants/routes';
import { formatPhone, getRelativeTime, openNestedAvailable } from 'utils';
import { useDispatch, useSelector, useRouter } from 'hooks';
import { getProviderInstance, openModal } from 'store/actions';
import { changeHasGoBackButton } from 'store/actions/navigation';
import { selectServiceInstance, selectServiceLocation } from 'store/reducers/service';
import { selectHasGoBackButton } from 'store/reducers/navigation';
import { Icon, IconButton, UserName, StyledTooltip } from 'components';

import { AdditionalActions } from '../';
import { DataRecord, FieldTitle } from '../styled';

import { CustomerInfo } from './components';
import {
  AssignPlaceholder,
  HighlitedRow,
  InvitedBy,
  NameWithOptions,
  ProviderButtonsHolder,
  ProviderRecordWrapper,
  SwitchBtnWrapper,
  UserPhoneNumber,
  callButtonStyle,
  userNameTextStyle,
  FlexWrapInfoRow,
  TooltipText
} from './styled';
import { selectProviderInstance } from 'store/reducers/provider';

const UserInfoRow = () => {
  const dispatch = useDispatch();
  const {
    location: { pathname }
  } = useRouter();
  const serviceInstance = useSelector(selectServiceInstance);
  const serviceLocation = useSelector(selectServiceLocation);
  const hasGoBackButton = useSelector(selectHasGoBackButton);
  const providerInstance = useSelector((state) => selectProviderInstance(state));
  const userInstance = providerInstance?.user;

  useEffect(() => {
    dispatch(getProviderInstance(serviceInstance.service_provider_id));
  }, [serviceInstance.service_provider_id]);

  const handleToggleProAssignModal = () => {
    dispatch(
      openModal(modalTypes.ASSIGN_PROVIDER_MAP, {
        jobId: serviceInstance?.id,
        serviceArea: serviceInstance?.service_area
      })
    );
  };

  const handleOpenUnassignProviderModal = () => {
    dispatch(
      openModal(modalTypes.UNASSIGN_PROVIDER, {
        user: serviceInstance?.service_provider.user,
        job_id: serviceInstance.id
      })
    );
  };

  const customerInstanceLink = () => {
    const {
      id,
      user: { id: userId }
    } = serviceInstance;
    const openNested = openNestedAvailable({
      pathname,
      hasGoBackButton
    });
    return {
      to: `${openNested ? `${routes.SERVICE}/${id}` : ''}${routes.CUSTOMER}/${userId}`,
      onClick: () => {
        if (!openNested && !hasGoBackButton) {
          dispatch(changeHasGoBackButton(true));
        }
      }
    };
  };

  const providerInstanceLink = () => {
    const {
      id,
      service_provider: {
        user: { id: providerId }
      }
    } = serviceInstance;

    const openNested = openNestedAvailable({
      pathname,
      hasGoBackButton
    });
    return {
      to: providerId ? `${openNested ? `${routes.SERVICE}/${id}` : ''}${routes.PROVIDER}/${providerId}` : '/',
      onClick: () => {
        if (!openNested && !hasGoBackButton) {
          dispatch(changeHasGoBackButton(true));
        }
      }
    };
  };

  const handleOpenCallModal = (userID) => {
    dispatch(openModal(modalTypes.TWILIO_VOICE, { userID, jobID: serviceInstance.id }));
  };

  const provider = serviceInstance.service_provider?.user;

  const hasProviderOptionalRow = Boolean(
    serviceInstance.service_provider_id && serviceLocation.provider_lead_source_user?.id
  );
  const hasCustomerOptionalRow = Boolean(serviceInstance.user && serviceInstance.user.email);
  return (
    <HighlitedRow>
      <CustomerInfo
        hasProviderOptionalRow={hasProviderOptionalRow}
        customerInstanceLink={customerInstanceLink}
        serviceInstance={serviceInstance}
        handleOpenCallModal={handleOpenCallModal}
      />
      <DataRecord flexDirection="row" justifyContent="space-between" margin="24px 0">
        <ProviderRecordWrapper>
          <FieldTitle>Pro (ID)</FieldTitle>

          {/* Pro assigned */}
          {serviceInstance.service_provider_id ? (
            <>
              <NameWithOptions alignItems="center" justifyContent="space-between">
                <ProviderButtonsHolder>
                  <NavLink {...providerInstanceLink()}>
                    <UserName
                      textStyle={userNameTextStyle}
                      given_name={provider.given_name}
                      family_name={provider.family_name}
                      pro_stage={serviceInstance.service_provider?.pro_stage}
                      id={provider.id}
                      is_active={provider.is_active}
                    />
                  </NavLink>
                  <IconButton
                    iconName="call"
                    style={callButtonStyle}
                    onClick={() => handleOpenCallModal(serviceInstance.service_provider_id)}
                  />
                  {serviceInstance.is_open && (
                    <SwitchBtnWrapper>
                      <IconButton iconName="switch" onClick={handleToggleProAssignModal} />
                    </SwitchBtnWrapper>
                  )}

                  {serviceInstance.is_open && serviceInstance.service_provider !== null ? (
                    <IconButton iconName="trash" onClick={handleOpenUnassignProviderModal} />
                  ) : null}
                </ProviderButtonsHolder>
                <AdditionalActions job={serviceInstance} />
              </NameWithOptions>
              {Boolean(userInstance?.date_login) ? (
                <UserPhoneNumber>
                  <div>{getRelativeTime(userInstance?.date_login)}</div>
                </UserPhoneNumber>
              ) : null}
              <UserPhoneNumber>
                {formatPhone(serviceInstance.service_provider?.user?.phone_number || '')}
              </UserPhoneNumber>
              {(hasCustomerOptionalRow || Boolean(serviceLocation.provider_lead_source_user?.id)) && (
                <InvitedBy>
                  {serviceLocation.provider_lead_source_user?.id ? (
                    <>
                      Invited By:{' '}
                      <NavLink
                        rel="noopener noreferrer"
                        target="_blank"
                        to={getCRMLinkByGroupId(serviceLocation.provider_lead_source_user)}>
                        {getFullName(serviceLocation.provider_lead_source_user)}
                      </NavLink>
                    </>
                  ) : (
                    ' '
                  )}
                </InvitedBy>
              )}
              <FlexWrapInfoRow>
                <StyledTooltip placement="top" arrow title="Completion Rate">
                  <TooltipText>{(serviceInstance.service_provider?.percent_completed || 0).toFixed()}%</TooltipText>
                </StyledTooltip>
                <div>&#9679;</div>
                <StyledTooltip placement="top" arrow title="Ongoing Jobs">
                  <TooltipText>{serviceInstance.service_provider?.count_service_ongoing || 0}</TooltipText>
                </StyledTooltip>
                <div>&#9679;</div>
                <StyledTooltip placement="top" arrow title="Completed Jobs">
                  <TooltipText>{serviceInstance.service_provider?.count_service_completed || 0}</TooltipText>
                </StyledTooltip>
                <div>&#9679;</div>
                <StyledTooltip placement="top" arrow title="Positive reviews">
                  <TooltipText>
                    {serviceInstance.service_provider?.count_positive_reviews || 0} <Icon name="thumbUp" size={12} />
                  </TooltipText>
                </StyledTooltip>
                <div>&#9679;</div>
                <StyledTooltip placement="top" arrow title="Negative reviews">
                  <TooltipText>
                    {serviceInstance.service_provider?.count_negative_reviews || 0} <Icon name="thumbDown" size={12} />
                  </TooltipText>
                </StyledTooltip>
                {serviceInstance.service_provider.service_area.map(({ id, name, full_name }, i) => (
                  <Fragment key={id}>
                    <div>&#9679;</div>
                    <StyledTooltip placement="top" arrow title={full_name}>
                      <TooltipText>{name}</TooltipText>
                    </StyledTooltip>
                  </Fragment>
                ))}
              </FlexWrapInfoRow>
            </>
          ) : null}

          {/* No Pro, task is opened */}
          {!serviceInstance.service_provider_id && serviceInstance.is_open ? (
            <NameWithOptions alignItems="center" justifyContent="space-between">
              <AssignPlaceholder onClick={handleToggleProAssignModal}>Assign a Pro</AssignPlaceholder>
              <AdditionalActions job={serviceInstance} />
            </NameWithOptions>
          ) : null}

          {/* No Pro, task is closed */}
          {!serviceInstance.service_provider_id && !serviceInstance.is_open ? (
            <NameWithOptions alignItems="center" justifyContent="flex-end">
              <AdditionalActions job={serviceInstance} />
            </NameWithOptions>
          ) : null}
        </ProviderRecordWrapper>
      </DataRecord>
    </HighlitedRow>
  );
};

export default UserInfoRow;
