import styled from 'styled-components';

export const Step = styled.div`
  font-weight: 500;
  font-size: 10px;
  color: #97a0a0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 174px;
  height: 28px;
  border-radius: 20px;
  ${({ active }) =>
    active &&
    `
  background: #353D41;
  border: 1px solid #66FFE3;
  color: #66FFE3;
  `}
`;

export const Footer = styled.div`
  background: #262e30;
  height: 88px;
  display: flex;

  align-items: center;
  justify-content: ${({ justifyContent = 'flex-end' }) => justifyContent};

  padding: 0 24px;
  position: fixed;
  width: calc(100% - 195px);
  bottom: 0;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 20px;
`;

export const wrapperStyle = {
  background: '#20292d',
  width: '100%',
  height: '100%',
  minHeight: 'calc(100vh - 56px)',
  color: '#F7F7F7'
};
