import React from 'react';
import { closeModal, openModal } from 'store/actions';
import { useDispatch } from 'hooks';
import { ModalConfirmation } from 'components';
import modalTypes from 'constants/modalTypes';

const CloseSilently = () => {
  const dispatch = useDispatch();
  return (
    <ModalConfirmation
      maxWidth={null}
      modalType={modalTypes.CLOSE_TASK_SILENTLY}
      smallPaddings={false}
      title="Archive the Job"
      text={`The Job will be stored under “maintenance history” with “Paid” status.\nHO won’t be notified about this action.`}
      hideModal={() => dispatch(closeModal(modalTypes.CLOSE_TASK_SILENTLY))}
      primaryButton={{
        title: 'Cancel',
        onClick: () => dispatch(closeModal(modalTypes.CLOSE_TASK_SILENTLY))
      }}
      secondaryButton={{
        title: 'Confirm',
        onClick: () => {
          dispatch(openModal(modalTypes.CLOSE_TASK_SILENTLY_FINAL));
        }
      }}
    />
  );
};

export default CloseSilently;
