import { call, takeLatest, put } from 'redux-saga/effects';
import api_constants from 'constants/api';
import actionTypes from 'constants/actionTypes';
import Api from 'services/ApiService';

function* getCustomerDobbyCash({ payload }) {
  const response = yield call(Api.amplifyGet, `${api_constants.DOBBY_CASH_BALANCE}/${payload}`);
  try {
    yield put({
      type: actionTypes.GET_CUSTOMER_DOBBY_CASH_SUCCESS,
      payload: response
    });
  } catch (error) {
    yield put({
      type: actionTypes.GET_CUSTOMER_DOBBY_CASH_ERROR
    });
  }
}

export default function* watchGetCustomerDobbyCash() {
  yield takeLatest(actionTypes.GET_CUSTOMER_DOBBY_CASH_FETCH, getCustomerDobbyCash);
}
