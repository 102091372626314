import React from 'react';

import { getFullName } from 'common/helpers/user';
import { useDispatch, useSelector } from 'hooks';
import { closeModal } from 'store/actions';
import { selectModalData } from 'store/reducers/modal';
import modalTypes from 'constants/modalTypes';
import { ModalConfirmation } from 'components';
import { ModalText } from './styled';

const UnassignProviderResult = () => {
  const dispatch = useDispatch();
  const modalData = useSelector(selectModalData);

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  return (
    <ModalConfirmation
      modalType={modalTypes.UNASSIGN_PROVIDER_RESULT}
      title="Success"
      text={
        <ModalText>
          <strong>{modalData.name}</strong> has been unassigned from the job <strong>(ID {modalData.job_id}).</strong>
        </ModalText>
      }
      hideModal={handleCloseModal}
      secondaryButton={{
        onClick: handleCloseModal
      }}
    />
  );
};

export default UnassignProviderResult;
