import React from 'react';

import { useSelector } from 'hooks';
import { timeframeEstimateList, jobFLows } from 'common/enums/job';
import { getTimeWindow } from 'common/helpers/job';
import { getWarningDescriptionById, getWarningColorById, getWarningNameById, clipTooltipText } from 'utils';
import { selectServiceInstance } from 'store/reducers/service';
import { Tooltip } from 'components';

import { DataRow, DataRecord, FieldTitle, FieldValue } from '../styled';
import { WarningLabel, WarningTooltipText, DateVisitLabel } from './styled';

const JobTimingRow = () => {
  const { job } = useSelector((state) => ({
    job: selectServiceInstance(state)
  }));

  const timeframeEstimate = timeframeEstimateList.find((estimate) => estimate?.id === job.timeframe_estimate);
  const isWarning = job.is_warning_enabled && !job.is_warning_checked && job.warning_type !== null;
  const jobFlow = Object.values(jobFLows).find(({ TYPE }) => TYPE === job.job_flow) || jobFLows.DEFAULT;

  const rowContent = [timeframeEstimate, isWarning, job.datetime_visit, job.datetime_visit, jobFlow];
  const rowContentLength = rowContent.filter(Boolean).length;

  if (!rowContentLength) {
    return null;
  }

  const warningFirstRow = clipTooltipText(getWarningDescriptionById(job.warning_type, job.warning_value), 0, 5);
  const warningSecondRow = clipTooltipText(getWarningDescriptionById(job.warning_type, job.warning_value), 5);
  const visitWindow = getTimeWindow({
    datetime_visit: job.datetime_visit,
    datetime_window_end: job.datetime_window_end
  });

  return (
    <DataRow>
      {Boolean(timeframeEstimate) && (
        <DataRecord>
          <FieldTitle>Timeframe Estimate</FieldTitle>
          <FieldValue>{timeframeEstimate?.name || '-'}</FieldValue>
        </DataRecord>
      )}
      {isWarning && (
        <DataRecord>
          <FieldTitle>Early Warning</FieldTitle>
          <Tooltip
            bottom={30}
            clickable
            direction="left"
            minWidth={250}
            title={
              <>
                <WarningTooltipText>{warningFirstRow}</WarningTooltipText>
                <WarningTooltipText>{warningSecondRow}</WarningTooltipText>
              </>
            }
            wrappedComponent={
              <WarningLabel color={getWarningColorById(job.warning_type)}>
                {getWarningNameById(job.warning_type)}
              </WarningLabel>
            }
          />
        </DataRecord>
      )}
      {job.datetime_visit && (
        <DataRecord flex={2}>
          <FieldTitle>Scheduled Date</FieldTitle>
          <FieldValue>
            <DateVisitLabel isPastScheduled={job.is_past_scheduled}>{visitWindow}</DateVisitLabel>
          </FieldValue>
        </DataRecord>
      )}
      {Boolean(jobFlow?.TYPE) && (
        <DataRecord>
          <FieldTitle flex={2}>Job Flow</FieldTitle>
          <FieldValue>{jobFlow.NAME || '-'}</FieldValue>
        </DataRecord>
      )}
      {new Array(rowContent.length - rowContentLength).fill(true).map((_, index) => (
        <DataRecord key={index} />
      ))}
    </DataRow>
  );
};

export default JobTimingRow;
