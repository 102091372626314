import styled from 'styled-components';

export const MapContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  width: 100%;
  min-width: 400px;
  min-height: 300px;
  padding: 20px;
`;

export const mapContainerStyle = {
  height: '100%'
};
