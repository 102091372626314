import { call, takeLatest, put } from 'redux-saga/effects';

import api_constants from 'constants/api';
import actionTypes from 'constants/actionTypes';
import Api from 'services/ApiService';

function* getServiceProgressList(action) {
  const { payload } = action;

  const { serviceId, page = 1, nextPage = false } = payload;

  try {
    const response = yield call(Api.amplifyGet, `${api_constants.SERVICE_REQUEST}/${serviceId}/progress?page=${page}`);
    if (response && response.results) {
      yield put({
        type: actionTypes.GET_SERVICE_PROGRESS_LIST_SUCCESS,
        payload: {
          count: response.count,
          progressList: response.results,
          page,
          nextPage
        }
      });
    } else {
      yield put({
        type: actionTypes.GET_SERVICE_PROGRESS_LIST_ERROR
      });
    }
  } catch (err) {
    console.log(err);
  }
}

export default function* watchGetServiceProgressList() {
  yield takeLatest(actionTypes.GET_SERVICE_PROGRESS_LIST_FETCH, getServiceProgressList);
}
