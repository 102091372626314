import React, { useState } from 'react';
import moment from 'moment-timezone';

import { formatDuration } from './helpers';

const DurationTimer = ({ isOngoing = false, startDateTime }) => {
  const [dateTimeNow, setDateTimeNow] = useState(null);

  if (isOngoing && startDateTime) {
    setTimeout(() => setDateTimeNow(moment()), 500);
  }

  if (!isOngoing || !startDateTime || !dateTimeNow) {
    return null;
  }

  const formattedDuration = formatDuration(dateTimeNow, startDateTime);
  return <>{formattedDuration}</>;
};

export default DurationTimer;
