import routes from 'constants/routes';

export const hasPaginationAvailable = ({ pathname, hasGoBackButton }) => {
  const [, rootPath, rootId, nestedPath] = pathname.split('/');

  const hasFirstLevelDetails = !nestedPath && !hasGoBackButton && Boolean(rootId);
  const isCrmDetailsRoute =
    hasFirstLevelDetails && [routes.CUSTOMER, routes.PROVIDER, routes.AGENT].includes(`/${rootPath}`);
  const isCampaignDetailsRoute = hasFirstLevelDetails && [routes.CAMPAIGNS].includes(`/${rootPath}`);

  const isJobDetailsRoute = !nestedPath && Boolean(rootId) && `/${rootPath}` === routes.SERVICE;
  const isNestedJobDetailsRoute = `/${nestedPath}` === routes.SERVICE;
  return isJobDetailsRoute || isNestedJobDetailsRoute || isCrmDetailsRoute || isCampaignDetailsRoute;
};
