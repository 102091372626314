import styled from 'styled-components';
import styles from 'constants/styles';

import { markerTypes } from './enums';

export const Label = styled.span`
  color: ${({ theme, markerType }) => {
    switch (markerType) {
      case markerTypes.SELECTED_PRO:
        return theme.colors.WHITE;
      case markerTypes.HOVERED:
        return theme.colors.WHITE;
      case markerTypes.DISABLED:
        return theme.colors.DARK_GREY;
      default:
        return theme.colors.DARK_1;
    }
  }};
`;

export const PillWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 24px;
  font-weight: 700;
  font-size: 14px;
  line-height: 1.14;
  cursor: pointer;
  border-radius: 20px;
  div > div:has(> &) {
    transform: translate(-50%, -50%);
    ${({ markerType }) => {
      if (markerType === markerTypes.HOVERED) {
        return `z-index: 10;`;
      }

      if (markerType === markerTypes.DISABLED) {
        return '';
      }

      if (markerType === markerTypes.SELECTED_VISIT) {
        return `z-index: 9; cursor: default;`;
      }

      if (markerType === markerTypes.SELECTED_PRO) {
        return `z-index: 8;`;
      }

      return '';
    }}
  }

  ${({ markerType, theme }) =>
    markerTypes.SELECTED_PRO !== markerType && `border: 1px solid ${theme.colors.DARK_GREY}`};
  background-color: ${({ markerType }) => {
    if (markerTypes.SELECTED_VISIT === markerType) {
      return styles.colors.BLACK_MARKER;
    }

    if (markerTypes.SELECTED_PRO === markerType) {
      return styles.colors.ASSIGNMENT_GREEN;
    }

    if (markerTypes.HOVERED === markerType) {
      return styles.colors.DARK_1;
    }

    return styles.colors.WHITE;
  }};
  ${({ markerType }) =>
    markerType === markerTypes.SELECTED_VISIT &&
    `
  border-width: 0;
  width: 12px;
  height: 12px;
  padding: 0;
`}
  box-shadow: ${({ theme }) => theme.boxShadows.THIN_DARK_4};

  div > div:has(> &):hover {
    z-index: 11;
  }

  div > div:has(> &):hover & {
    border: 1px solid
      ${({ markerType, theme }) =>
        markerType === markerTypes.SELECTED_VISIT ? theme.colors.DARK_1 : theme.colors.GRAY};
    background-color: ${styles.colors.DARK_1};
    ${Label} {
      color: ${({ theme, markerType }) =>
        markerType === markerTypes.DISABLED ? theme.colors.DARK_GREY : theme.colors.WHITE};
    }
  }
  ${({ markerType, theme }) =>
    markerType === markerTypes.DISABLED &&
    `
  background: ${theme.colors.WHITE};
  ${Label} {
    color: ${theme.colors.DARK_GREY};
  }
  div > div:has(> &):hover & {
    background: ${theme.colors.WHITE};
  }

`};
`;
