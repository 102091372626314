import { paymentMethods } from 'common/enums/invoice';
import { formatters } from 'utils';
import { OFFLINE_PAYMENT_TYPES } from '../../../constants/invoice';

export const getPaymentMethod = (id, { cardNumber }) => {
  switch (id) {
    case paymentMethods.CARD:
      return `****${cardNumber || ''}`;
    case paymentMethods.CASH:
      return 'Paid in cash';
    case paymentMethods.TRANSFER:
      return 'Wire transfer';
    case paymentMethods.ACH_DEBIT:
      if (cardNumber) {
        return `****${cardNumber || ''} (ACH)`;
      }
      return 'Wire transfer';

    default:
      return '-';
  }
};

export const getFormattedPercent = ({ partialValue, totalValue }) => {
  const percentValue = totalValue ? parseFloat((partialValue * 100) / totalValue).toFixed(2) : 0;
  return `(${formatters.percent(percentValue)})`;
};

export const isOfflinePayment = (paymentType) => {
  return OFFLINE_PAYMENT_TYPES.includes(paymentType);
};
