import styled from 'styled-components';

export const SwitchHolder = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 192px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 14px 0px 10px 24px;
  z-index: 10;
  background-color: ${({ theme }) => theme.colors.SECONDARY};
  border-top: 1px solid ${({ theme }) => theme.colors.BORDER_1};
`;

export const SwitchItemHolder = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0 0 16px 0px;
`;

export const SwitchTestJobsHolder = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0 0 10px 0px;
`;
