import React from 'react';

import { navigationTabs } from 'common/enums/navigation';
import { useRouter } from 'hooks';

import Quotes from '../Quotes/Quotes';
import Payments from '../Payments';

const InvoicePendingTabs = () => {
  const { queryParams } = useRouter();
  const filters = queryParams.getAll();

  if (navigationTabs.INVOICE_ADMIN_PENDING_PAYMENT.TYPE === filters.tab_id) {
    return <Payments />;
  }
  if (navigationTabs.INVOICE_ADMIN_PENDING_QUOTE.TYPE === filters.tab_id) {
    return <Quotes />;
  }

  return <Quotes />;
};

export default InvoicePendingTabs;
