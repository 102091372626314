import actionTypes from 'constants/actionTypes';

const defaultPaymentList = {
  page: 1,
  count: 0,
  instances: [],
  loading: false
};

const defaultState = {
  isInvoiceFormVisible: false,
  editableInvoiceId: null,
  deleteInvoiceLoading: false,
  jobPaymentList: [],
  jobPaymentInstanceLoading: false,
  jobPaymentListLoading: false,
  jobQuoteList: [],
  jobQuoteInstanceLoading: false,
  jobQuoteListLoading: false,
  jobPayoutLoading: false,
  paymentList: defaultPaymentList,
  quotationList: defaultPaymentList,
  isCloseLoading: false
};

function reducer(state = defaultState, action) {
  switch (action.type) {
    // get
    case actionTypes.GET_TASK_PAYMENT_LIST_FETCH: {
      return {
        ...state,
        jobPaymentListLoading: true
      };
    }

    case actionTypes.GET_TASK_QUOTATION_LIST_FETCH: {
      return {
        ...state,
        jobQuoteListLoading: true
      };
    }

    case actionTypes.GET_TASK_PAYMENT_LIST_SUCCESS: {
      return {
        ...state,
        isInvoiceFormVisible:
          !action.payload.length &&
          !state.jobQuoteList.length &&
          !state.jobQuoteInstanceLoading &&
          !state.jobPaymentInstanceLoading &&
          !state.jobQuoteListLoading,
        jobPaymentList: action.payload,
        jobPaymentListLoading: false
      };
    }

    case actionTypes.GET_TASK_QUOTATION_LIST_SUCCESS: {
      return {
        ...state,
        isInvoiceFormVisible:
          !action.payload.length &&
          !state.jobPaymentList.length &&
          !state.jobPaymentListLoading &&
          !state.jobQuoteInstanceLoading &&
          !state.jobPaymentInstanceLoading,
        jobQuoteList: action.payload,
        jobQuoteListLoading: false
      };
    }

    case actionTypes.GET_TASK_PAYMENT_LIST_ERROR: {
      return {
        ...state,
        isInvoiceFormVisible:
          !state.jobQuoteList.length &&
          !state.jobQuoteInstanceLoading &&
          !state.jobPaymentInstanceLoading &&
          !state.jobQuoteListLoading,
        jobPaymentList: [],
        jobPaymentListLoading: false
      };
    }

    case actionTypes.GET_TASK_QUOTATION_LIST_ERROR: {
      return {
        ...state,
        isInvoiceFormVisible:
          !state.jobPaymentList.length &&
          !state.jobPaymentListLoading &&
          !state.jobQuoteInstanceLoading &&
          !state.jobPaymentInstanceLoading,
        jobQuoteList: [],
        jobQuoteListLoading: false
      };
    }

    // create
    case actionTypes.CREATE_QUOTATION_FETCH: {
      return {
        ...state,
        jobQuoteInstanceLoading: true
      };
    }
    case actionTypes.CREATE_PAYMENT_FETCH: {
      return {
        ...state,
        jobPaymentInstanceLoading: true
      };
    }

    case actionTypes.CREATE_QUOTATION_SUCCESS: {
      return {
        ...state,
        jobQuoteInstanceLoading: false,
        isInvoiceFormVisible: false,
        editableInvoiceId: null,
        jobQuoteList: [action.payload, ...state.jobQuoteList]
      };
    }
    case actionTypes.CREATE_PAYMENT_SUCCESS: {
      return {
        ...state,
        jobPaymentInstanceLoading: false,
        isInvoiceFormVisible: false,
        editableInvoiceId: null,
        jobPaymentList: [action.payload, ...state.jobPaymentList]
      };
    }

    case actionTypes.CREATE_QUOTATION_ERROR: {
      return {
        ...state,
        jobQuoteInstanceLoading: false
      };
    }
    case actionTypes.CREATE_PAYMENT_ERROR: {
      return {
        ...state,
        jobPaymentInstanceLoading: false
      };
    }

    // update
    case actionTypes.UPDATE_QUOTATION_FETCH: {
      return {
        ...state,
        jobQuoteInstanceLoading: true
      };
    }
    case actionTypes.UPDATE_PAYMENT_FETCH: {
      return {
        ...state,
        jobPaymentInstanceLoading: true
      };
    }

    case actionTypes.UPDATE_QUOTATION_SUCCESS: {
      const { id } = action.payload;

      return {
        ...state,
        jobQuoteInstanceLoading: false,
        isInvoiceFormVisible: false,
        editableInvoiceId: null,
        jobQuoteList: state.jobQuoteList.map((item) => (item.id === id ? action.payload : item))
      };
    }
    case actionTypes.UPDATE_PAYMENT_SUCCESS: {
      const { id } = action.payload;

      return {
        ...state,
        jobPaymentInstanceLoading: false,
        isInvoiceFormVisible: false,
        editableInvoiceId: null,
        jobPaymentList: state.jobPaymentList.map((item) => (item.id === id ? action.payload : item))
      };
    }

    case actionTypes.UPDATE_QUOTATION_ERROR: {
      return {
        ...state,
        jobQuoteInstanceLoading: false
      };
    }
    case actionTypes.UPDATE_PAYMENT_ERROR: {
      return {
        ...state,
        jobPaymentInstanceLoading: false
      };
    }
    case actionTypes.CLOSE_PAYMENT_SUCCESS: {
      const { id } = action.payload;
      return {
        ...state,
        isCloseLoading: false,
        jobPaymentList: state.jobPaymentList.map((item) => (item.id === id ? action.payload : item))
      };
    }
    case actionTypes.CLOSE_PAYMENT_FETCH: {
      return {
        ...state,
        isCloseLoading: true
      };
    }
    case actionTypes.CLOSE_PAYMENT_ERROR: {
      return {
        ...state,
        isCloseLoading: false
      };
    }
    // delete
    case actionTypes.DELETE_PAYMENT_FETCH: {
      const { id } = action.payload.invoice;

      return {
        ...state,
        deleteInvoiceLoading: true,
        jobPaymentList: state.jobPaymentList.filter((item) => item.id !== id)
      };
    }

    case actionTypes.DELETE_PAYMENT_SUCCESS: {
      const { id } = action.payload.invoice;

      return {
        ...state,
        deleteInvoiceLoading: false,
        jobPaymentList: state.jobPaymentList.filter((item) => item.id !== id)
      };
    }

    case actionTypes.DELETE_PAYMENT_ERROR: {
      const { id } = action.payload.invoice;
      const isPayment = state.jobPaymentList.some((item) => item.id === id);
      if (!isPayment) {
        return {
          ...state,
          deleteInvoiceLoading: false,
          jobPaymentList: [...state.jobPaymentList, action.payload.invoice]
        };
      }

      return {
        ...state,
        deleteInvoiceLoading: false,
        jobPaymentList: state.jobPaymentList.map((item) => (item.id === id ? action.payload.invoice : item))
      };
    }

    case actionTypes.DELETE_QUOTATION_FETCH: {
      const { id } = action.payload.invoice;

      return {
        ...state,
        deleteInvoiceLoading: true,
        jobQuoteList: state.jobQuoteList.filter((item) => item.id !== id)
      };
    }

    case actionTypes.DELETE_QUOTATION_SUCCESS: {
      const { id } = action.payload.invoice;

      return {
        ...state,
        deleteInvoiceLoading: false,
        jobQuoteList: state.jobQuoteList.filter((item) => item.id !== id)
      };
    }

    case actionTypes.DELETE_QUOTATION_ERROR: {
      const { id } = action.payload.invoice;
      const isQuotation = state.jobQuoteList.some((item) => item.id === id);
      if (!isQuotation) {
        return {
          ...state,
          deleteInvoiceLoading: false,
          jobQuoteList: [...state.jobQuoteList, action.payload.invoice]
        };
      }

      return {
        ...state,
        deleteInvoiceLoading: false,
        jobQuoteList: state.jobQuoteList.map((item) => (item.id === id ? action.payload.invoice : item))
      };
    }

    case actionTypes.CREATE_PAYOUT_FETCH: {
      return {
        ...state,
        jobPayoutLoading: true
      };
    }

    case actionTypes.CREATE_PAYOUT_ERROR:
    case actionTypes.CREATE_PAYOUT_SUCCESS: {
      return {
        ...state,
        jobPayoutLoading: false
      };
    }

    case actionTypes.TOGGLE_INVOICE_FORM: {
      const { invoiceId } = action.payload || {};
      if (invoiceId) {
        return {
          ...state,
          isInvoiceFormVisible: true,
          editableInvoiceId: invoiceId
        };
      }

      if (state.jobQuoteList.length || state.jobPaymentList.length) {
        return {
          ...state,
          isInvoiceFormVisible: !state.isInvoiceFormVisible,
          editableInvoiceId: null
        };
      }

      return {
        ...state,
        isInvoiceFormVisible: true,
        editableInvoiceId: null
      };
    }

    case actionTypes.GET_PAYMENT_LIST_FETCH: {
      return {
        ...state,
        paymentList: {
          ...state.paymentList,
          loading: true
        }
      };
    }

    case actionTypes.GET_PAYMENT_LIST_SUCCESS: {
      return {
        ...state,
        paymentList: {
          ...action.payload,
          loading: false
        }
      };
    }

    case actionTypes.GET_PAYMENT_LIST_ERROR: {
      return {
        ...state,
        paymentList: defaultPaymentList
      };
    }

    case actionTypes.GET_QUOTATION_LIST_FETCH: {
      return {
        ...state,
        quotationList: {
          ...state.quotationList,
          loading: true
        }
      };
    }
    case actionTypes.GET_QUOTATION_LIST_SUCCESS: {
      return {
        ...state,
        quotationList: {
          ...action.payload,
          loading: false
        }
      };
    }
    case actionTypes.GET_QUOTATION_LIST_ERROR: {
      return {
        ...state,
        quotationList: defaultPaymentList
      };
    }

    default:
      return state;
  }
}

// selectors
export const isInvoiceFormVisible = (state) => state.invoice.isInvoiceFormVisible;
export const selecteditableInvoiceId = (state) => state.invoice.editableInvoiceId;
export const selectDeleteInvoiceLoading = (state) => state.invoice.deleteInvoiceLoading;

export const selectPayoutLoading = (state) => state.invoice.jobPayoutLoading;
export const selectIsCloseLoading = (state) => state.invoice.isCloseLoading;

export const selectJobInvoices = (state) => [...state.invoice.jobPaymentList, ...state.invoice.jobQuoteList];
export const selectJobInvoicesLoading = (state) =>
  state.invoice.jobQuoteInstanceLoading ||
  state.invoice.jobPaymentInstanceLoading ||
  state.invoice.jobQuoteListLoading ||
  state.invoice.jobPaymentListLoading;

export const selectJobPayments = (state) => state.invoice.jobPaymentList;
export const selectPayments = (state) => state.invoice.paymentList;
export const selectQuotes = (state) => state.invoice.quotationList;
export const selectPaymentsList = (state) => state.invoice.paymentList.instances;
export const selectPaymentsCount = (state) => state.invoice.paymentList.count;
export const selectPaymentsPage = (state) => state.invoice.paymentList.page;
export const selectQuotationList = (state) => state.invoice.quotationList.instances;
export const selectQuotationCount = (state) => state.invoice.quotationList.count;
export const selectQuotationPage = (state) => state.invoice.quotationList.page;

export default reducer;
