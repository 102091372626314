import { call, takeLatest, put, select } from 'redux-saga/effects';
import { generateQueryParams } from 'common/helpers/httpRequest';

import Api from 'services/ApiService';
import api_constants from 'constants/api';
import actionTypes from 'constants/actionTypes';
import errors from 'constants/errors';
import { isTestTasksVisible, selectFilteredAdminList, selectFilteredGeoLocationList } from 'store/reducers/app';

function* getBadges() {
  try {
    const testTasksVisible = yield select(isTestTasksVisible);
    const filteredAdminList = yield select(selectFilteredAdminList);
    const filteredGeoLocationList = yield select(selectFilteredGeoLocationList);
    const opAreasIDString = filteredGeoLocationList.length ? filteredGeoLocationList.join(',') : '';
    const ownersIDString = filteredAdminList.length ? filteredAdminList.map(({ id }) => id).join(',') : null;

    const queryString = generateQueryParams({
      ...(testTasksVisible ? {} : { is_test: 0 }),
      ...(opAreasIDString ? { operational_area_id: opAreasIDString } : {}),
      ...(ownersIDString ? { owner_id: ownersIDString } : {})
    });

    const response = yield call(Api.amplifyGet, `${api_constants.SIDEBAR_COUNTERS}${queryString}`);

    if (!response || response?.err) {
      throw new Error(errors.DEFAULT);
    }

    yield put({
      type: actionTypes.GET_BADGES_SUCCESS,
      payload: response
    });
  } catch (err) {
    console.log(err);
    yield put({
      type: actionTypes.GET_BADGES_ERROR
    });
  }
}

export default function* watchGetBadges() {
  yield takeLatest(actionTypes.GET_BADGES_FETCH, getBadges);
}
