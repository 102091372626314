import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { closeModal, createPayout } from 'store/actions';
import { selectModalData } from 'store/reducers/modal';
import { selectPayoutLoading } from 'store/reducers/invoice';
import modalTypes from 'constants/modalTypes';
import { formatters } from 'utils';
import { ModalConfirmation } from 'components';

const PayoutConfirmation = ({
  loading = false,
  modalData: { amount_total_payout, invoice_id = null, task_id } = {},
  closeModal,
  createPayout
}) => {
  const closeConfirmPayout = () => {
    closeModal(modalTypes.PAYOUT_CONFIRMATION);
  };

  const onConfirm = () => {
    createPayout({
      invoice_id,
      task_id
    });
  };

  return (
    <ModalConfirmation
      modalType={modalTypes.PAYOUT_CONFIRMATION}
      title={
        invoice_id
          ? `Payout to the PRO is ${formatters.dollarCurrencyDB(amount_total_payout)}`
          : 'Payout to the PRO for the all invoices'
      }
      hideModal={closeConfirmPayout}
      primaryButton={{
        title: 'Cancel',
        disabled: loading,
        onClick: closeConfirmPayout
      }}
      secondaryButton={{
        title: 'Confirm',
        onClick: onConfirm,
        loading: loading,
        disabled: loading
      }}
    />
  );
};

PayoutConfirmation.propTypes = {
  createPayout: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  modalData: selectModalData(state),
  loading: selectPayoutLoading(state)
});

const mapDispatchToProps = {
  closeModal,
  createPayout
};

export default connect(mapStateToProps, mapDispatchToProps)(PayoutConfirmation);
