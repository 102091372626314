import messages from 'constants/messages';

export const validators = {
  required: (value = '') => {
    return value.length === 0 ? messages.formErrors.REQUIRED : undefined;
  },
  requiredTrim: (value = '') => {
    return value.trim().length === 0 ? messages.formErrors.REQUIRED : undefined;
  },
  date: (value) => {
    if (!value) {
      return null;
    }
    const [month, day, year] = value.split('/').map(Number);

    if (!month || month > 12) {
      return messages.formErrors.MONTH;
    }
    if (!day || !(day >= 1 && day <= 31)) {
      return messages.formErrors.DAY;
    }
    if (!year) {
      return messages.formErrors.YEAR;
    }
  },
  email: (value) => {
    return value &&
      !/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ // eslint-disable-line no-useless-escape
        .test(String(value.trim()))
      ? messages.formErrors.EMAIL
      : undefined;
  },
  password: (value) => {
    return value &&
      !/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[\`|\~|\!|\@|\#|\$|\%|\^|\&|\*|\(|\)|\+|\=|\[|\{|\]|\}|\||\\|\'|\<|\,|\.|\>|\?|\/|\""|\;|\:|]).{8,}$/ // eslint-disable-line no-useless-escape
        .test(String(value))
      ? messages.formErrors.PASSWORD
      : undefined;
  },
  checkMinLength: (value) => {
    return value && value.length < 3 ? messages.formErrors.MINIMUM_SYMBOLS_REQUIRED : undefined;
  },
  equal: (value1, allValues) => (value1 !== allValues.password ? messages.formErrors.PASSWORDS_NOT_EQUAL : undefined),
  number: (value) => {
    return !/^\d+$/.test(value) ? messages.formErrors.NUMBER : undefined;
  },
  link: (value) =>
    value &&
    // eslint-disable-next-line
    !/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/i.test(value)
      ? messages.formErrors.NOT_A_LINK
      : undefined,
  loomEmbedLink: (value) =>
    value && !/^https:\/\/www\.loom\.com\/embed\/.+/.test(value) ? messages.formErrors.LOOM_EMBED_LINK : undefined,
  range: (nextFieldName) => (value, allValues) => value > allValues[nextFieldName] ? '' : undefined
};
