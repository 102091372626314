import { call, takeLatest, put, select } from 'redux-saga/effects';

import api_constants from 'constants/api';
import errors from 'constants/errors';
import actionTypes from 'constants/actionTypes';
import Api from 'services/ApiService';
import { saveLocationSearch } from 'store/actions/navigation';
import { generateQueryParams } from 'common/helpers/httpRequest';
import { isTestTasksVisible } from 'store/reducers/app';

function* getPromoCodeList({
  payload: { referral_code = '', userId = '', page = 1, hasSavingQueryParams, menuItemType, locationSearch } = {}
}) {
  try {
    const testTasksVisible = yield select(isTestTasksVisible);
    const queryParams = generateQueryParams({
      page,
      referral_code,
      user_id: userId,
      ...(testTasksVisible ? {} : { is_test: 0 })
    });

    const response = yield call(Api.amplifyGet, `${api_constants.PROMO_CODE}${queryParams}`);

    if (!response?.results || response?.error_message) {
      throw new Error(errors.DEFAULT);
    }

    yield put({
      type: actionTypes.GET_PROMO_CODE_LIST_SUCCESS,
      payload: {
        results: response.results,
        count: response.count
      }
    });

    if (hasSavingQueryParams) {
      yield put(saveLocationSearch({ menuItemType, locationSearch }));
    }
  } catch (err) {
    yield put({
      type: actionTypes.GET_PROMO_CODE_LIST_ERROR
    });
  }
}

export default function* watchGetPromoCodeList() {
  yield takeLatest(actionTypes.GET_PROMO_CODE_LIST_FETCH, getPromoCodeList);
}
