import { call, takeLatest, put } from 'redux-saga/effects';
import api_constants from 'constants/api';
import modalTypes from 'constants/modalTypes';
import actionTypes from 'constants/actionTypes';
import errors from 'constants/errors';
import { openModal } from 'store/actions';
import Api from 'services/ApiService';

function* deleteUserNote({ payload: note }) {
  try {
    const response = yield call(Api.amplifyDel, `${api_constants.USER}/${note.user_id}/note/${note.id}`);

    if (response && response.err) {
      yield put(
        openModal(modalTypes.ERROR, {
          errorMessage: response?.error_message || response?.err?.message || response?.err?.name || errors.DEFAULT
        })
      );
      yield put({
        type: actionTypes.DELETE_USER_NOTE_ERROR
      });
    } else {
      yield put(
        openModal(modalTypes.RESULT, {
          title: 'The note has been removed completely'
        })
      );
      yield put({
        type: actionTypes.DELETE_USER_NOTE_SUCCESS
      });
      yield put({
        type: actionTypes.GET_USER_NOTE_LIST_FETCH,
        payload: {
          userId: note.user_id
        }
      });
    }
  } catch (err) {
    yield put(
      openModal(modalTypes.ERROR, {
        errorMessage: err?.message || err?.name || errors.DEFAULT
      })
    );
    yield put({
      type: actionTypes.DELETE_USER_NOTE_ERROR
    });
  }
}

export default function* watchDeleteUserNote() {
  yield takeLatest(actionTypes.DELETE_USER_NOTE_FETCH, deleteUserNote);
}
