import { columnNames } from 'common/enums/sortings';

export const reviewTable = {
  ID: {
    TITLE: 'ID',
    FILTER_NAME: columnNames.ID
  },
  HO_GIVEN_NAME: {
    TITLE: 'HO Name',
    FILTER_NAME: columnNames.HO_GIVEN_NAME
  },
  HO_ID: {
    TITLE: 'HO ID',
    FILTER_NAME: columnNames.HO_ID
  },
  PRO_GIVEN_NAME: {
    TITLE: 'PRO Name',
    FILTER_NAME: columnNames.PRO_GIVEN_NAME
  },
  PRO_ID: {
    TITLE: 'PRO ID',
    FILTER_NAME: columnNames.PRO_ID
  },
  LOCATION: {
    TITLE: 'Location',
    FILTER_NAME: columnNames.OPERATIONAL_AREA_ID
  },
  WILL_WORK_AGAIN: {
    TITLE: 'Review',
    FILTER_NAME: columnNames.WILL_WORK_AGAIN
  },
  DATETIME_CREATED: {
    TITLE: 'Date',
    FILTER_NAME: columnNames.DATETIME_CREATED
  }
};
