import { call, takeLatest, put } from 'redux-saga/effects';
import api_constants from 'constants/api';
import actionTypes from 'constants/actionTypes';
import modalTypes from 'constants/modalTypes';
import Api from 'services/ApiService';
import errors from 'constants/errors';
import { getBadges, openModal } from 'store/actions';
import { refreshTaskInfo } from 'store/actions/service';

function* deleteQuotation({
  payload: {
    invoice: { service_request_id: task_id, id },
    invoice,
    formattedPrice
  }
}) {
  try {
    const response = yield call(Api.amplifyDel, `${api_constants.QUOTATION}/${id}`);
    if (response !== null && response !== '') {
      throw new Error(errors.DEFAULT);
    }
    yield put({
      type: actionTypes.DELETE_QUOTATION_SUCCESS,
      payload: {
        invoice: {
          ...invoice,
          is_quotation: true
        }
      }
    });
    yield put(
      openModal(modalTypes.RESULT, {
        title: `The quotation to HO\nfor the ${formattedPrice} successfully removed.`
      })
    );

    if (invoice && !invoice.is_moderated) {
      yield put(getBadges());
    }
  } catch (err) {
    yield put({
      type: actionTypes.DELETE_QUOTATION_ERROR,
      payload: {
        invoice: {
          ...invoice,
          is_quotation: true
        }
      }
    });
    yield put(openModal(modalTypes.ERROR));
  } finally {
    yield put(refreshTaskInfo({ task_id, silent: true, isPaymentInfo: true }));
  }
}

export default function* watchDeleteQuotation() {
  yield takeLatest(actionTypes.DELETE_QUOTATION_FETCH, deleteQuotation);
}
