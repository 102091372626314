import actionTypes from 'constants/actionTypes';

export const getAvatar = (payload) => ({
  type: actionTypes.GET_AVATAR_FETCH,
  payload
});

export const getAvatarOriginal = (url, id) => ({
  type: actionTypes.GET_AVATAR_ORIGINAL_FETCH,
  payload: { url, id }
});

export const getCSVFile = (payload) => ({
  type: actionTypes.GET_CSV_FILE_FETCH,
  payload
});
