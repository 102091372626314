import styled from 'styled-components';
import styles from 'constants/styles';

export const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 24px;
  left: 24px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${styles.colors.LIGHT_GRAY};
  z-index: 10;
`;

export const ContentWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
  height: 100%;
`;
