import React from 'react';

import { addressStringFilters, customJobFilters } from 'common/enums/filters';
import { adminStatuses } from 'common/enums/job';
import styles from 'constants/styles';
import { CloseButton } from 'components';
import { DateFilter } from './components';

import { FiltersContainer, FilterWrapper, FiltersWrapper, FilterValue, RemoveAllButton, FilterHeader } from './styled';

const FiltersHeader = ({ onRemoveAllFilters, onRemoveFilter, filters, coreNavigationFilters }) => {
  const adminStatus = Object.values(adminStatuses).find(
    (item) => String(item.TYPE) === filters[customJobFilters.ADMIN_STATUS.TYPE]
  );

  return (
    <FiltersContainer>
      <FiltersWrapper>
        <FilterHeader marginRight={17}>FILTERS:</FilterHeader>
        {Boolean(adminStatus?.NAME) && (
          <FilterWrapper marginRight={8}>
            <FilterValue>
              {customJobFilters.ADMIN_STATUS.NAME}: {adminStatus.NAME}
            </FilterValue>
            <CloseButton
              size={18}
              iconSize={10}
              iconColor={styles.colors.BLACK}
              onClick={onRemoveFilter(customJobFilters.ADMIN_STATUS.TYPE)}
            />
          </FilterWrapper>
        )}
        <DateFilter
          filterName={customJobFilters.DATETIME_CREATED_FROM.NAME}
          startDate={filters[customJobFilters.DATETIME_CREATED_FROM.TYPE]}
          endDate={filters[customJobFilters.DATETIME_CREATED_TO.TYPE]}
          onRemoveFilter={onRemoveFilter(
            customJobFilters.DATETIME_CREATED_FROM.TYPE,
            customJobFilters.DATETIME_CREATED_TO.TYPE
          )}
        />
        <DateFilter
          filterName={customJobFilters.DATETIME_COMPLETED_FROM.NAME}
          startDate={filters[customJobFilters.DATETIME_COMPLETED_FROM.TYPE]}
          endDate={filters[customJobFilters.DATETIME_COMPLETED_TO.TYPE]}
          onRemoveFilter={onRemoveFilter(
            customJobFilters.DATETIME_COMPLETED_FROM.TYPE,
            customJobFilters.DATETIME_COMPLETED_TO.TYPE
          )}
        />
        <DateFilter
          filterName={customJobFilters.DATETIME_CANCELED_FROM.NAME}
          startDate={filters[customJobFilters.DATETIME_CANCELED_FROM.TYPE]}
          endDate={filters[customJobFilters.DATETIME_CANCELED_TO.TYPE]}
          onRemoveFilter={onRemoveFilter(
            customJobFilters.DATETIME_CANCELED_FROM.TYPE,
            customJobFilters.DATETIME_CANCELED_TO.TYPE
          )}
        />
        {filters[customJobFilters.HAS_EARLY_WARNING.TYPE] && (
          <FilterWrapper marginRight={8}>
            <FilterValue>{customJobFilters.HAS_EARLY_WARNING.NAME}</FilterValue>
            <CloseButton
              size={18}
              iconSize={10}
              iconColor={styles.colors.BLACK}
              onClick={onRemoveFilter(customJobFilters.HAS_EARLY_WARNING.TYPE)}
            />
          </FilterWrapper>
        )}
        {filters[customJobFilters.HAS_UNREAD_MESSAGES.TYPE] && (
          <FilterWrapper marginRight={8}>
            <FilterValue>{customJobFilters.HAS_UNREAD_MESSAGES.NAME}</FilterValue>
            <CloseButton
              size={18}
              iconSize={10}
              iconColor={styles.colors.BLACK}
              onClick={onRemoveFilter(customJobFilters.HAS_UNREAD_MESSAGES.TYPE)}
            />
          </FilterWrapper>
        )}
        {Boolean(filters.jobs_lost && filters.provider_id) && (
          <FilterWrapper marginRight={8}>
            <FilterValue hasCapitalizeDisabled>
              {customJobFilters.JOBS_LOST.VALUES.find(({ VALUE }) => VALUE === filters.jobs_lost).NAME}:{' '}
              {coreNavigationFilters?.[addressStringFilters.PROVIDER_FULL_NAME.TYPE] || `Pro ${filters.provider_id}`}
            </FilterValue>
            <CloseButton
              size={18}
              iconSize={10}
              iconColor={styles.colors.BLACK}
              onClick={onRemoveFilter(
                customJobFilters.JOBS_LOST.TYPE,
                customJobFilters.PROVIDER_ID.TYPE,
                addressStringFilters.PROVIDER_FULL_NAME.TYPE
              )}
            />
          </FilterWrapper>
        )}
      </FiltersWrapper>
      <RemoveAllButton onClick={onRemoveAllFilters}>Remove All</RemoveAllButton>
    </FiltersContainer>
  );
};

export default FiltersHeader;
