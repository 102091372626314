import React from 'react';
import { connect } from 'react-redux';

import { MessageActions } from './styled';
import { openModal } from 'store/actions';
import modalTypes from 'constants/modalTypes';
import { DropdownMenuChatActions } from 'components';

const ChatActions = ({
  hasRightPosition,
  openModal,
  message,
  handleEdit,
  editingDisabled,
  userID,
  isCurrentUser,
  width
}) => {
  const handleDeleteConfirmation = () => {
    openModal(modalTypes.DELETE_CHAT_MESSAGE, { message, userID });
  };

  return (
    <MessageActions>
      <DropdownMenuChatActions
        onEdit={handleEdit}
        onDelete={handleDeleteConfirmation}
        editingDisabled={editingDisabled}
        margin="0"
        isCurrentUser={isCurrentUser}
        width={width}
        hasRightPosition={hasRightPosition}
      />
    </MessageActions>
  );
};

const mapDispatchToProps = {
  openModal
};

export default connect(null, mapDispatchToProps)(ChatActions);
