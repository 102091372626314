export const getRows = ({ list }) => {
  return list.map((customer) => ({
    date_created: customer.user?.date_created,
    id: customer.id,
    profile_image_url: customer.user?.profile_image_url,
    user: customer.user,
    given_name: customer.user?.given_name,
    family_name: customer.user?.family_name,
    address: customer.user?.address,
    lead_source: customer.user?.lead_source,
    amount_total_spent: customer?.amount_total_spent,
    lifetime_margin: customer?.lifetime_margin,
    lifetime_margin_percent: customer?.lifetime_margin_percent,
    service_completed: customer?.user?.service_completed,
    days_since_sign_up: customer?.days_since_sign_up,
    days_since_last_requested: customer?.days_since_last_requested,
    days_since_last_completed: customer?.days_since_last_completed,
    days_since_last_open_app: customer?.days_since_last_open_app,
    days_since_last_active: customer?.days_since_last_active,
    stage: customer?.stage
  }));
};
