import React from 'react';
import { useDispatch, useSelector } from 'hooks';
import { systemStatuses } from 'common/enums/job';
import modalTypes from 'constants/modalTypes';
import { closeModal } from 'store/actions';
import { selectModalData } from 'store/reducers/modal';
import { ModalConfirmation } from 'components';

const CloseSilentlyResult = () => {
  const dispatch = useDispatch();
  const modalData = useSelector(selectModalData);
  const handleResultModalClose = () => {
    dispatch(closeModal(modalTypes.CLOSE_TASK_SILENTLY_RESULT));
  };

  const isError = !modalData || modalData?.is_open || modalData?.status !== systemStatuses.FINAL_PAID;

  return (
    <ModalConfirmation
      maxWidth={null}
      modalType={modalTypes.CLOSE_TASK_SILENTLY_RESULT}
      smallPaddings={false}
      title={isError ? 'Error' : 'Success'}
      text={isError ? modalData.errorMessage : `The job ${modalData.id} has been closed silently`}
      hideModal={handleResultModalClose}
      secondaryButton={{
        title: 'Ok',
        onClick: handleResultModalClose
      }}
    />
  );
};

export default CloseSilentlyResult;
