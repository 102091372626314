import { call, takeLatest, put } from 'redux-saga/effects';
import Api from 'services/ApiService';
import api_constants from 'constants/api';
import actionTypes from 'constants/actionTypes';

function* getUserQuestions({ payload }) {
  const { userId, page } = payload;
  try {
    const response = yield call(
      Api.amplifyGet,
      `${api_constants.ASK}?user_id=${userId}&page=${page}order_by=date_created`
    );
    if (!response?.results) {
      throw new Error();
    }
    yield put({
      type: actionTypes.GET_USER_QUESTIONS_SUCCESS,
      payload: {
        count: response.count,
        list: response.results,
        page
      }
    });
  } catch (err) {
    yield put({
      type: actionTypes.GET_USER_QUESTIONS_ERROR
    });
  }
}

export default function* watchGetUserQuestions() {
  yield takeLatest(actionTypes.GET_USER_QUESTIONS_FETCH, getUserQuestions);
}
