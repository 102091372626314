import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const NavigationTitleBadge = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  padding: 2px 6px 1px;
  background: ${({ theme }) => theme.colors.YELLOW};
  border-radius: 20px;
  border: none;
  color: ${({ theme }) => theme.colors.TEXT};

  font-weight: 600;
  font-size: 12px;
  line-height: 1.2;
`;

export const MenuItemsDivider = styled.li`
  display: flex;
  align-self: stretch;

  height: 1px;
  padding-top: 1px;
  margin: 8px 15px 8px 24px;

  background-color: ${({ theme }) => theme.colors.WHITE};
`;

export const ListItem = styled.li`
  padding: 0;
  margin-bottom: 0;
  width: 100%;
`;

const menuItemStyle = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  color: ${({ theme }) => theme.colors.WHITE};
  font-size: 14px;
  line-height: 1;
`;

export const NavigationTitle = styled.div`
  ${menuItemStyle}
  font-weight: 700;
  text-transform: uppercase;
  margin: ${({ bigTopPadding }) => (bigTopPadding ? 14 : 7)}px 0 0 0;
  padding: 10px 10px 10px 24px;
  text-transform: uppercase;
`;

export const NavigationSubHeader = styled.div`
  ${menuItemStyle}
  font-weight: 400;
  line-height: 1.14;
  margin-top: 0;
  padding: 8px 5px 8px ${({ isThirdLevelMenuItem }) => (isThirdLevelMenuItem ? 60 : 40)}px;
`;

export const NavigationLink = styled(NavLink)`
  cursor: pointer;
  transition: box-shadow 0.2s ease;

  & ${NavigationSubHeader}, & ${NavigationTitle} {
    ${({ active, theme }) =>
      Boolean(active) &&
      `
      color: ${theme.colors.ACCENT_1};
      background: ${theme.colors.SIDEBAR_ACTIVE_SECTION};
    `}
  }

  & ${NavigationSubHeader}:hover, & ${NavigationTitle}:hover {
    ${({ theme }) =>
      `
      background: ${theme.colors.SIDEBAR_ACTIVE_SECTION};
    `}
  }
`;
