import { call, takeEvery, put } from 'redux-saga/effects';
import { apiPageSizes } from 'common/enums/queryParams';
import api_constants from 'constants/api';
import actionTypes from 'constants/actionTypes';
import Api from 'services/ApiService';

function* getUserServiceList(action) {
  const {
    payload: {
      is_open: payload_is_open,
      customer_id = null,
      provider_id = null,
      page = 1,
      limit = apiPageSizes.DEFAULT,
      admin_status,
      is_job_history,
      is_recently_completed,
      fieldName
    }
  } = action;

  const is_open = customer_id ? payload_is_open : null;

  try {
    const response = yield call(
      Api.amplifyGet,
      `${api_constants.SERVICE}?page=${page}&limit=${limit}${
        provider_id !== null ? `&provider_id=${provider_id}` : ''
      }${customer_id !== null ? `&customer_id=${customer_id}` : ''}${
        payload_is_open !== null ? `&is_open=${payload_is_open}` : ''
      }&order_by=-${payload_is_open ? 'date_created' : 'completed_list_last_active'}${
        admin_status !== undefined ? `&admin_status=${admin_status}` : ''
      }${is_recently_completed ? `&is_recently_completed=${is_recently_completed}` : ''}${
        is_job_history ? `&is_job_history=${is_job_history}` : ''
      }`
    );
    if (response && response.results) {
      yield put({
        type: actionTypes.GET_USER_SERVICE_LIST_SUCCESS,
        payload: {
          count: response.count,
          list: response.results,
          page,
          is_open: payload_is_open,
          provider_id,
          customer_id,
          fieldName
        }
      });
    } else {
      yield put({
        type: actionTypes.GET_USER_SERVICE_LIST_ERROR,
        payload: {
          provider_id,
          customer_id,
          is_open,
          fieldName
        }
      });
    }
  } catch (err) {
    console.log(err);
  }
}

export default function* watchGetUserServiceList() {
  yield takeEvery(actionTypes.GET_USER_SERVICE_LIST_FETCH, getUserServiceList);
}
