// React
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// Utils
import { getFullName } from 'common/helpers/user';
import { formatters } from 'utils';
// Constants
import { timeframeEstimateList } from 'common/enums/job';
import routes from 'constants/routes';
// Components
import { MediaGallery, LoadingSpinner } from 'components';
// Strore
import { selectTaskImageUrls } from 'store/reducers/runtimeCache';
// Styles
import {
  CardContainer,
  HeaderSection,
  AddressSection,
  PropRow,
  PropColumn,
  picturePreviewStyle,
  DescriptionSection,
  HeaderLabel,
  PropTitle,
  PropValue,
  placeholderStyle
} from './styled';

const CustomerPopup = ({ job, jobLoading, media, closeModal }) => {
  const { family_name, given_name, address, service_count, service_ongoing, service_completed, service_total_spent } =
    job?.user || {};
  const { id, timeframe_estimate, job_category_name, job_type_name, description, user_id } = job || {};

  const navigate = useNavigate();
  const timeframeEstimate = timeframeEstimateList.find((timeframe) => timeframe?.id === timeframe_estimate);
  const openTaskPage = () => {
    closeModal();
    const route = `${routes.SERVICE}/${id}`;
    navigate(route);
  };
  const signedImageUrls = useSelector(selectTaskImageUrls);

  const imageUrls = signedImageUrls[job.id];

  if (jobLoading) {
    return (
      <CardContainer>
        <LoadingSpinner color={'dark'} />
      </CardContainer>
    );
  }

  return (
    <CardContainer>
      <HeaderSection>
        <HeaderLabel onClick={openTaskPage}>{getFullName({ given_name, family_name, id: user_id })}</HeaderLabel>
      </HeaderSection>
      <AddressSection>
        <PropValue>{address}</PropValue>
      </AddressSection>

      {/* HO stats row */}
      <PropRow>
        <PropColumn>
          <PropTitle># submitted jobs</PropTitle>
          <PropValue>{service_count || '0'}</PropValue>
        </PropColumn>

        <PropColumn>
          <PropTitle># ongoing jobs</PropTitle>
          <PropValue>{service_ongoing || '0'}</PropValue>
        </PropColumn>

        <PropColumn>
          <PropTitle># completed jobs</PropTitle>
          <PropValue>{service_completed || '0'}</PropValue>
        </PropColumn>

        <PropColumn>
          <PropTitle>$ spent with us</PropTitle>
          <PropValue>
            {Boolean(service_total_spent) ? formatters.dollarCurrencyDB(service_total_spent?.toString()) : '$0'}
          </PropValue>
        </PropColumn>
      </PropRow>

      {/* Jobs props row */}
      <PropRow>
        <PropColumn>
          <PropTitle>Type</PropTitle>
          <PropValue>{job_type_name || '-'}</PropValue>
        </PropColumn>

        <PropColumn>
          <PropTitle>Category</PropTitle>
          <PropValue>{job_category_name || '-'}</PropValue>
        </PropColumn>

        <PropColumn>
          <PropTitle>Timeframe estimate</PropTitle>
          <PropValue>{timeframeEstimate?.name || '-'}</PropValue>
        </PropColumn>
      </PropRow>

      <DescriptionSection>
        {/* Pics here */}
        <PropColumn>
          <PropTitle>Description</PropTitle>
          <MediaGallery
            media={media}
            isOriginalForPreview
            mediaUrls={imageUrls}
            mediaStyle={picturePreviewStyle}
            placeholderStyle={placeholderStyle}
            loading={media?.length !== imageUrls?.length}
            showScroll
          />
          <PropValue>{description || '-'}</PropValue>
        </PropColumn>
      </DescriptionSection>
    </CardContainer>
  );
};

export default CustomerPopup;
