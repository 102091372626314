import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';

import LocalizationProvider from '@mui/lab/LocalizationProvider';
import StaticDatePicker from '@mui/lab/StaticDatePicker';
import DateAdapter from '@mui/lab/AdapterMoment';

import { closeModal, scheduleVisit } from 'store/actions';
import modalTypes from 'constants/modalTypes';
import styles from 'constants/styles';
import dates from 'constants/dates';
import { selectModalData } from 'store/reducers/modal';
import { selectScheduleVisitLoading } from 'store/reducers/loading';

import { MAX_VISIT_WINDOW, MIN_VISIT_WINDOW, MINUTES_IN_HOUR } from 'constants/visit';

import { generateTimesArray, getStartTimeInitialId, getEndTimeInitialId } from './helpers';

import { Button, Dropdown, Modal, IconButton } from 'components';
import {
  ButtonsWrapper,
  ModalTitle,
  ContentWrapper,
  Line,
  SecondLine,
  TimeWrapper,
  RelativeWrapper,
  dropdownMenuStyle,
  Divider
} from './styled';

const ScheduleVisit = ({
  modalType = modalTypes.SCHEDULE_VISIT,
  closeModal,
  title = 'Schedule the visit',
  scheduleVisit,
  modalData,
  loading
}) => {
  const startValues = useRef(generateTimesArray()).current;
  const endValues = useRef([
    ...startValues.slice(MIN_VISIT_WINDOW, MINUTES_IN_HOUR),
    ...startValues.slice(0, MIN_VISIT_WINDOW)
  ]).current;

  const [dateVisit, setDateVisit] = useState(moment('00:00', dates.TIME_24));
  const [timeVisitId, setTimeVisitId] = useState(getStartTimeInitialId(startValues));
  const [timeWindowEndId, setTimeWindowEndId] = useState(getEndTimeInitialId(endValues));

  const hideModal = () => closeModal(modalTypes.SCHEDULE_VISIT);

  const onScheduleClick = () => {
    const { task_id } = modalData;
    if (!task_id) {
      return;
    }

    const dateString = dateVisit.format(dates.DATE_ISO);
    const datetimeVisit = moment(dateString + 'T' + startValues.find(({ id }) => id === timeVisitId)?.value);
    const datetimeWindowEnd = moment(dateString + 'T' + endValues.find(({ id }) => id === timeWindowEndId)?.value);

    scheduleVisit({
      task_id,
      visit: {
        datetime_visit: datetimeVisit,
        datetime_window_end: datetimeWindowEnd.add(datetimeWindowEnd.isBefore(datetimeVisit) ? 1 : 0, 'days')
        // TODO: (hold) Add visit_type choosing when it be required
        // visit_type: 1
      }
    });
  };

  const handleTimeVisitChange = (itemIndex) => {
    if (itemIndex === timeVisitId) {
      return;
    }
    const currentDiff = timeWindowEndId - timeVisitId;
    setTimeVisitId(itemIndex);
    setTimeWindowEndId((endValues.length + itemIndex + currentDiff) % endValues.length);
  };

  const handleWindowEndChange = (itemIndex) => {
    if (itemIndex === timeWindowEndId) {
      return;
    }

    const indexDiff = itemIndex - timeVisitId;
    setTimeWindowEndId(itemIndex);

    if (indexDiff >= MIN_VISIT_WINDOW && indexDiff <= MAX_VISIT_WINDOW) {
      return;
    }

    const indexOffset = indexDiff > MAX_VISIT_WINDOW ? MAX_VISIT_WINDOW : MIN_VISIT_WINDOW;
    const newStartIndex = (startValues.length + itemIndex - indexOffset) % startValues.length;
    setTimeVisitId(newStartIndex);
    return;
  };

  return (
    <Modal modalType={modalType} hideModal={hideModal} padding="0" maxHeight={608} minWidth={1}>
      <ModalTitle>{title}</ModalTitle>
      <ContentWrapper>
        <LocalizationProvider dateAdapter={DateAdapter}>
          <RelativeWrapper>
            <StaticDatePicker
              value={dateVisit}
              onChange={setDateVisit}
              minDate={moment()}
              renderInput={() => null}
              showDaysOutsideCurrentMonth={false}
              disableCloseOnSelect
              reduceAnimations
              displayStaticWrapperAs="desktop"
              views={['day']}
              disabled={loading}
              components={{
                RightArrowButton: ({ onClick }) => <IconButton onClick={onClick} iconName="arrowFillRight" />,
                LeftArrowButton: ({ onClick }) => <IconButton onClick={onClick} iconName="arrowFillLeft" />
              }}
            />
          </RelativeWrapper>
          <SecondLine />
          <TimeWrapper>
            <Dropdown
              value={startValues.find(({ id }) => timeVisitId === id)}
              containerBackground={styles.colors.LIGHT_GRAY_2}
              type="square"
              placeholder="Select time"
              options={startValues}
              onChange={handleTimeVisitChange}
              width={160}
              centered
              disabled={loading}
              dropdownMenuStyle={dropdownMenuStyle}
              scrollToItemBlock="center"
            />
            <p> to </p>
            <Dropdown
              value={endValues.find(({ id }) => timeWindowEndId === id)}
              containerBackground={styles.colors.LIGHT_GRAY_2}
              type="square"
              centered
              placeholder="Select time"
              options={endValues}
              onChange={handleWindowEndChange}
              width={160}
              disabled={loading}
              dropdownMenuStyle={dropdownMenuStyle}
              scrollToItemBlock="center"
            />
          </TimeWrapper>
        </LocalizationProvider>
        <ButtonsWrapper>
          <Button variant="primary" onClick={hideModal}>
            Cancel
          </Button>
          <Divider />
          <Button onClick={onScheduleClick} loading={loading} disabled={!dateVisit.isValid()}>
            Schedule
          </Button>
        </ButtonsWrapper>
      </ContentWrapper>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  modalData: selectModalData(state),
  loading: selectScheduleVisitLoading(state)
});

const mapDispatchToProps = {
  closeModal,
  scheduleVisit
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleVisit);
