export const staticExtraOptions = [
  {
    id: 'null',
    given_name: 'Without Owner',
    user: {
      id: 'null',
      given_name: 'Without Owner'
    },
    isExtra: true
  }
];
