import { commissionTypes } from 'common/enums/invoice';

import subtractCalculation from './subtractCalculation';
import marginCalculation from './marginCalculation';

// Payment logic bridges
export const getAmountCharge = (data) => {
  const { commission_type } = data;
  if (commission_type === commissionTypes.SUB_FEE) {
    return subtractCalculation.getAmountCharge(data);
  }
  return marginCalculation.getAmountCharge(data);
};

export const getAmountStripeFee = (data) => {
  const { commission_type } = data;
  if (commission_type === commissionTypes.SUB_FEE) {
    return subtractCalculation.getAmountStripeFee(data);
  }
  return marginCalculation.getAmountStripeFee(data);
};

export const getTotalPrice = (data) => {
  const { commission_type } = data;
  if (commission_type === commissionTypes.SUB_FEE) {
    return subtractCalculation.getTotalPrice(data);
  }
  return marginCalculation.getTotalPrice(data);
};

export const getPayoutSum = (data) => {
  const { commission_type } = data;
  if (commission_type === commissionTypes.SUB_FEE) {
    return subtractCalculation.getPayoutSum(data);
  }
  return marginCalculation.getPayoutSum(data);
};

export const getFee = (data) => {
  const { commission_type } = data;
  if (commission_type === commissionTypes.SUB_FEE) {
    return subtractCalculation.getFee(data);
  }
  return marginCalculation.getFee(data);
};

// AdditionalPayoutCommission aplicable only for SubFee commission type
export const getAdditionalPayoutCommission = ({
  invoice_type,
  commission_type,
  amount_tip,
  deposit,
  labor,
  material,
  amount_deposit,
  amount_labor_cost,
  amount_material,
  percent_commission,
  current_commission_debt
}) => {
  if (commission_type === commissionTypes.MARGIN) {
    return 0;
  }

  const totalSum =
    subtractCalculation.getAmountCharge({
      invoice_type,
      amount_tip,
      deposit,
      labor,
      material,
      amount_deposit,
      amount_labor_cost,
      amount_material
    }) * 100;
  const payout = totalSum - (totalSum * percent_commission) / 10000;
  const additionalPayoutCommission = current_commission_debt > payout ? payout : current_commission_debt;
  return additionalPayoutCommission;
};
