import React from 'react';

import { useCallback, useDispatch, useFilters, useSelector, useTheme } from 'hooks';
import { useReplaceHistory } from 'navigation/hooks';
import routes from 'constants/routes';
import { PaginationFullScreen, SearchInput, LoadingSpinner, StyledTooltip, Icon } from 'components';
import { userGroups } from 'common/enums/user';

import { getConversationList } from 'store/actions/communication';
import {
  selectCommunicationListLoading,
  selectCommunicationList,
  selectCommunicationCount
} from 'store/reducers/communication';
import { debounce } from 'utils';

import ConversationCard from './components';
import { ChatContainer, NotesContainer } from './containers';

import {
  ConversationListContainer,
  ConversationWrapper,
  NoResultsHeader,
  LoadingContainer,
  SearchSection,
  SearchWrapper,
  ScrollContainer,
  TooltipText
} from './styled';

/**
 * Twilio SMS integration communication page
 */
const Communication = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { handleReplaceHistory } = useReplaceHistory();

  const listLoading = useSelector(selectCommunicationListLoading);
  const list = useSelector(selectCommunicationList);
  const totalCount = useSelector(selectCommunicationCount);

  const handleFetchCommunicationList = useCallback((updatedFilters = {}) => {
    dispatch(getConversationList(updatedFilters));
  }, []);

  const { filters, handleChangePage } = useFilters({
    onFetchList: handleFetchCommunicationList,
    listRoute: routes.COMMUNICATION
  });

  const onSearchDebounced = useCallback(
    debounce(({ nextSearchValue }) => {
      handleFetchCommunicationList({ search: nextSearchValue });
    }, 750),
    [handleFetchCommunicationList]
  );

  const handleSearch = useCallback(
    (searchText) => {
      const { user_id, message_id, ...restFilters } = filters;
      handleReplaceHistory({
        filters: {
          ...restFilters,
          search: searchText
        }
      });
      onSearchDebounced({ nextSearchValue: searchText });
    },
    [filters, handleReplaceHistory, onSearchDebounced]
  );

  const onCardClick = (item) => {
    handleReplaceHistory({
      filters: {
        ...filters,
        user_id: item.user_id,
        message_id: item.message_id || ''
      }
    });
  };

  const selectedConversation = list?.find(({ message_id, user_id }) =>
    filters.message_id ? filters.message_id === String(message_id) : String(user_id) === filters.user_id
  );
  return (
    <PaginationFullScreen
      count={totalCount}
      currentPage={Number(filters.page)}
      onPageChange={handleChangePage}
      length={list.length}
      hasEmptyPagination
      loading={listLoading && !filters.search && !list.length}>
      <ConversationListContainer>
        <SearchSection>
          <SearchWrapper>
            <SearchInput
              onSearch={handleSearch}
              // for resetting search value on changing filters
              searchType={filters.search ? 'Communication' : ''}
              placeholder={'Search by user name/phone number/message'}
              defaultSearch={filters.search}
              isFullWidth
            />
          </SearchWrapper>
          <StyledTooltip
            arrow
            placement="bottom-end"
            title={
              <TooltipText>
                {`To search enter:\np= provider name\nh= ho name\nid= user id\nm= message\nuse '+' to search by phone number`}
              </TooltipText>
            }>
            <div>
              <Icon
                backgroundColor={theme.colors.INFO_BG}
                color={theme.colors.INFO_ICON}
                backgroundSize={24}
                size={3}
                name="info"
              />
            </div>
          </StyledTooltip>
        </SearchSection>
        <ScrollContainer>
          {listLoading && Boolean(filters.search) ? (
            <LoadingContainer>
              <LoadingSpinner color="light" />
            </LoadingContainer>
          ) : (
            list.map((item) => (
              <ConversationCard
                key={`card-${item.id}-${item.message_id || ''}`}
                conversation={item}
                onCardClick={() => onCardClick(item)}
                searchTerm={filters.search}
                isHighlighted={
                  selectedConversation?.message_id
                    ? item.message_id === selectedConversation?.message_id
                    : item.user_id === selectedConversation?.user_id
                }
              />
            ))
          )}
          {!list?.length && !listLoading && Boolean(filters.search) && (
            <LoadingContainer>
              <NoResultsHeader>No conversations found.</NoResultsHeader>
            </LoadingContainer>
          )}
        </ScrollContainer>
      </ConversationListContainer>
      <ConversationWrapper>
        <ChatContainer
          conversation={selectedConversation}
          hasCustomerChat={selectedConversation?.user?.group_id === userGroups.CUSTOMER.TYPE}
        />
        <NotesContainer userId={selectedConversation?.user_id} />
      </ConversationWrapper>
    </PaginationFullScreen>
  );
};

export default Communication;
