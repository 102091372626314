import { useDispatch, useSelector } from 'hooks';
import React from 'react';
import { closeModal } from 'store/actions';
import modalTypes from 'constants/modalTypes';
import { Modal, Icon, Button, StyledTooltip } from 'components';
import { selectModalData } from 'store/reducers/modal';
import { Header, Title, Submitted, AnswerDate, Answer, ViewDetails } from './styled';
import { useTheme } from 'styled-components';
import { formatDateShortTimeStamp } from 'utils/transformers';

const QuestionDetails = () => {
  const dispatch = useDispatch();
  const onModalClose = () => dispatch(closeModal(modalTypes.QUESTION_DETAILS));
  const { question, answer, service_request_id, date_created } = useSelector(selectModalData);
  const theme = useTheme();
  const handleOpenDetails = () => {
    window.open(`/service/${service_request_id}`, '_blank');
  };
  return (
    <Modal modalType={modalTypes.QUESTION_DETAILS} hideModal={onModalClose} padding="40px 24px" minWidth={768}>
      <Header>
        <Title>{question}</Title>

        {service_request_id && (
          <StyledTooltip
            dark
            arrow
            placement="top-end"
            title={<ViewDetails onClick={handleOpenDetails}>View job details</ViewDetails>}>
            <Submitted>
              <Icon name="okOutlined" size={16} backgroundSize={16} color={theme.colors.DARK_GREEN_2} strokeWidth={2} />
              <div>Submitted</div>
            </Submitted>
          </StyledTooltip>
        )}
      </Header>
      <AnswerDate>{formatDateShortTimeStamp(date_created)}</AnswerDate>

      <Answer>{answer}</Answer>

      <Button onClick={onModalClose}>Close</Button>
    </Modal>
  );
};

export default QuestionDetails;
