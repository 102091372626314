import actionTypes from 'constants/actionTypes';

export const getUserInstance = (payload) => ({
  type: actionTypes.GET_USER_INSTANCE_FETCH,
  payload
});

export const deleteCognitoUser = (data) => ({
  type: actionTypes.DELETE_COGNITO_USER_FETCH,
  payload: data
});

export const getUserServiceList = (data) => ({
  type: actionTypes.GET_USER_SERVICE_LIST_FETCH,
  payload: data
});

export const updateUserInstance = (data) => ({
  type: actionTypes.UPDATE_USER_INSTANCE_FETCH,
  payload: data
});

export const getUserNoteList = (data) => ({
  type: actionTypes.GET_USER_NOTE_LIST_FETCH,
  payload: data
});

export const createUserNote = (data) => ({
  type: actionTypes.CREATE_USER_NOTE_FETCH,
  payload: data
});

export const updateUserNote = (data) => ({
  type: actionTypes.UPDATE_USER_NOTE_FETCH,
  payload: data
});

export const deleteUserNote = (data) => ({
  type: actionTypes.DELETE_USER_NOTE_FETCH,
  payload: data
});

export const getUserQuestions = (payload) => ({
  type: actionTypes.GET_USER_QUESTIONS_FETCH,
  payload
});
