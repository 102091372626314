import actionTypes from 'constants/actionTypes';

export const getServiceList = (payload) => ({
  type: actionTypes.GET_SERVICE_LIST_FETCH,
  payload
});

export const getServiceInstance = (payload) => ({
  type: actionTypes.GET_SERVICE_INSTANCE_FETCH,
  payload
});

export const updateJobField = (payload) => ({
  type: actionTypes.UPDATE_JOB_FIELD_FETCH,
  payload
});

export const getServiceProAssignBundle = (payload) => ({
  type: actionTypes.GET_PRO_ASSIGN_BUNDLE_FETCH,
  payload
});

export const getServiceLocation = (payload) => ({
  type: actionTypes.GET_SERVICE_LOCATION_FETCH,
  payload
});

export const getServiceProgressList = (payload) => ({
  type: actionTypes.GET_SERVICE_PROGRESS_LIST_FETCH,
  payload
});

export const getServiceNoteList = (payload) => ({
  type: actionTypes.GET_SERVICE_NOTE_LIST_FETCH,
  payload
});

export const createServiceNote = (payload) => ({
  type: actionTypes.CREATE_SERVICE_NOTE_FETCH,
  payload
});

export const updateServiceNote = (payload) => ({
  type: actionTypes.UPDATE_SERVICE_NOTE_FETCH,
  payload
});

export const bundleServices = (payload) => ({
  type: actionTypes.BUNDLE_SERVICES_FETCH,
  payload
});

export const duplicateService = (payload) => ({
  type: actionTypes.DUPLICATE_TASK_FETCH,
  payload
});

export const getUnreadMessagesByCategories = () => ({
  type: actionTypes.GET_UNREAD_MESSAGED_BY_CATEGORIES_FETCH
});

export const deleteServiceNote = (payload) => ({
  type: actionTypes.DELETE_SERVICE_NOTE_FETCH,
  payload
});

export const updateRequest = (payload) => ({
  type: actionTypes.UPDATE_REQUEST_FETCH,
  payload
});

export const createRequest = (payload) => ({
  type: actionTypes.CREATE_REQUEST_FETCH,
  payload
});

export const scheduleVisit = (payload) => ({
  type: actionTypes.SCHEDULE_VISIT_FETCH,
  payload
});

export const refreshTaskInfo = (payload) => ({
  type: actionTypes.REFRESH_TASK_INFO,
  payload
});

export const unbundleTask = (payload) => ({
  type: actionTypes.UNBUNDLE_TASK_FETCH,
  payload
});

export const snoozeTask = (payload) => ({
  type: actionTypes.SNOOZE_TASK_FETCH,
  payload
});

export const getOwnerReport = (payload) => ({
  type: actionTypes.GET_OWNER_REPORT_FETCH,
  payload
});

export const chatExtendOrClose = (payload) => ({
  type: actionTypes.CHAT_EXTEND_OR_CLOSE_FETCH,
  payload
});
