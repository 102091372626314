import React from 'react';
import { useDispatch, useSelector } from 'hooks';
import { openModal } from 'store/actions';
import { selectModalData } from 'store/reducers/modal';
import { selectIsCloseLoading } from 'store/reducers/invoice';
import { closePayment } from 'store/actions';
import modalTypes from 'constants/modalTypes';
import { ModalConfirmation } from 'components';

const DeleteInvoiceConfirmation = () => {
  const dispatch = useDispatch();

  const modalData = useSelector(selectModalData);
  const handleHideModal = () => {
    dispatch(openModal(modalTypes.CLOSE_INVOICE, { closeInvoiceData: modalData.closeInvoiceData }));
  };
  const isLoading = useSelector(selectIsCloseLoading);
  const onConfirm = () => {
    dispatch(closePayment(modalData.invoiceData));
  };

  return (
    <ModalConfirmation
      modalType={modalTypes.CLOSE_INVOICE_CONFIRMATION}
      hideModal={handleHideModal}
      title="Are you sure?"
      text="The homeowner will be notified about the invoice status update."
      primaryButton={{
        title: 'Confirm',
        onClick: onConfirm,
        loading: isLoading,
        disabled: isLoading
      }}
      secondaryButton={{
        title: 'Cancel',
        disabled: isLoading,
        onClick: handleHideModal
      }}
    />
  );
};

export default DeleteInvoiceConfirmation;
