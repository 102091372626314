import React from 'react';

import { useSelector } from 'hooks';
import { selectAgentInstance, selectAgentInstanceLoading } from 'store/reducers/agent';

import { Breadcrumb } from '../../components';

const CustomerBreadcrumb = ({ match, location }) => {
  const { agent_id } = match.params;
  const agent = useSelector(selectAgentInstance);
  const agentLoading = useSelector(selectAgentInstanceLoading);

  const breadcrumbName = `${agentLoading ? '' : agent?.user?.given_name || ''} (${agent_id})`;

  return (
    <Breadcrumb
      breadcrumbName={breadcrumbName}
      hasLoading={agentLoading}
      isActive={match.pathname === location.pathname}
      pathname={match.pathname}
    />
  );
};

export default CustomerBreadcrumb;
