import { all, call, takeLatest, put } from 'redux-saga/effects';
import api_constants from 'constants/api';
import actionTypes from 'constants/actionTypes';
import Api from 'services/ApiService';

import { getProviderInstance } from 'store/actions';
import { closeModal } from 'store/actions/modal';
import modalTypes from 'constants/modalTypes';

function* updateProviderInstance({ payload }) {
  const { data, userId, providerId, isServiceAreaListEdited } = payload;
  try {
    let [profile_image_id, mediaUploadUrl, personal_logo_media_id, logoMediaUploadUrl] = [null, null, null, null];
    const { media, logo_media } = data;

    // avatar
    if (media) {
      mediaUploadUrl = yield call(Api.amplifyPost, api_constants.MEDIA, {
        original_name: media.name,
        sub_folder: 'avatar'
      });
      if (mediaUploadUrl) {
        const uploadResponse = yield call(Api.s3Put, mediaUploadUrl.upload_url, media, { 'Content-Type': media.type });
        if (uploadResponse) {
          profile_image_id = mediaUploadUrl.id;
        }
      }
    }
    const avatarId = profile_image_id ? { profile_image_id } : {};

    // logo
    if (logo_media) {
      logoMediaUploadUrl = yield call(Api.amplifyPost, api_constants.MEDIA, {
        original_name: logo_media.name,
        sub_folder: 'logo_media'
      });
      if (logoMediaUploadUrl) {
        const uploadResponse = yield call(Api.s3Put, logoMediaUploadUrl.upload_url, logo_media, {
          'Content-Type': logo_media.type
        });
        if (uploadResponse) {
          personal_logo_media_id = logoMediaUploadUrl.id;
        }
      }
    }
    const logoId = personal_logo_media_id ? { personal_logo_media_id } : {};

    // Clean empty reviews
    const external_review = [
      { author: data.external_author_1, description: data.external_review_1 },
      { author: data.external_author_2, description: data.external_review_2 },
      { author: data.external_author_3, description: data.external_review_3 }
    ].filter((review) => Boolean(review.description) && Boolean(review.author));

    // Clean empty skills
    if (data?.provider_skills === null) {
      data.provider_skills = [];
    }
    // TODO: (unassigned) to not send extra request when only one instance changed
    const [, responseUser, responseProvider, responseAreaList] = yield all([
      call(
        Api.amplifyPost,
        `${api_constants.PROVIDER_SKILL}?service_provider_id=${providerId}`,
        data?.provider_skills?.map(({ skill_id, id }) => ({
          service_provider_id: providerId,
          skill_id: skill_id ?? id
        }))
      ),

      call(Api.amplifyPut, `${api_constants.USER}/${userId}`, {
        given_name: data.given_name,
        family_name: data.family_name,
        email: data.email,
        ...avatarId
      }),
      call(Api.amplifyPut, `${api_constants.SERVICE_PROVIDER}/${providerId}`, {
        pro_stage: data.pro_stage,
        auto_assign: data.auto_assign,
        one_click: data.one_click,
        primary_service_id: data.primary_service_id,
        secondary_category: data.secondary_category,
        category: data.category,
        about: data.about,
        preferred_name: data.preferred_name,
        is_core: data.is_core,
        is_parked: data.is_parked,
        ...logoId,
        external_review
      }),
      ...(isServiceAreaListEdited
        ? [
            call(Api.amplifyPut, `${api_constants.SERVICE_PROVIDER}/service_area/bulk_modify`, {
              service_provider_id: providerId,
              key: 'service_area_id',
              instances: data.service_area_list
            })
          ]
        : [])
    ]);

    if (!responseUser || !responseProvider) {
      throw new Error();
    }

    const service_area_list = isServiceAreaListEdited
      ? typeof responseAreaList !== 'object'
        ? responseAreaList?.map(({ service_area }) => service_area.alternative_name)
        : []
      : responseProvider?.service_area_list;
    yield put({
      type: actionTypes.UPDATE_PROVIDER_INSTANCE_SUCCESS,
      payload: {
        ...responseProvider,
        service_area_list,
        user: responseUser
      }
    });

    // Remove after backed fixed
    if (isServiceAreaListEdited) {
      yield put(getProviderInstance(providerId));
    }
  } catch (err) {
    yield put({
      type: actionTypes.UPDATE_PROVIDER_INSTANCE_ERROR
    });
    console.error(err);
  } finally {
    yield put(closeModal(modalTypes.EDIT_USER));
  }
}

export default function* watchUpdateProviderInstance() {
  yield takeLatest(actionTypes.UPDATE_PROVIDER_INSTANCE_FETCH, updateProviderInstance);
}
