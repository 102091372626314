import React, { Fragment } from 'react';

import { useBreadcrumbs, useDispatch, useEffect, useTheme, useRouter, useSelector } from 'hooks';
import { openNestedAvailable } from 'utils';
import { changeHasGoBackButton } from 'store/actions/navigation';
import { selectHasGoBackButton } from 'store/reducers/navigation';
import routes from 'constants/routes';
import { Icon, IconButton } from 'components';

import { breadcrumbRoutes } from './enums';
import { BundleJobButton } from './containers';
import { BreadcrumbsWrapper, IconWrapper } from './styled';

const BreadcrumbsNavigation = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const breadcrumbs = useBreadcrumbs(breadcrumbRoutes, { disableDefaults: true });
  const { navigate, location } = useRouter();
  const hasGoBackButton = useSelector(selectHasGoBackButton);

  useEffect(() => {
    const splittedPathname = location.pathname.split('/');
    const isDetailsPage = splittedPathname.length === 3;
    const isNestedBreadcrumbsAvailable = openNestedAvailable({ pathname: location.pathname, hasGoBackButton });

    if (!hasGoBackButton && isDetailsPage && !isNestedBreadcrumbsAvailable) {
      dispatch(changeHasGoBackButton(true));
      return;
    }

    const isDeepPathname = hasGoBackButton && [2, 5].includes(splittedPathname.length);
    if (isDeepPathname) {
      dispatch(changeHasGoBackButton(false));
      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);
  //  }, [location.pathname, navigationAction]);

  const handleNavigateBack = () => {
    navigate(-1);
  };

  return (
    <BreadcrumbsWrapper>
      {hasGoBackButton && (
        <IconButton size={32} iconName="goBack" margin="0 16px 0 0" iconSize={15} onClick={handleNavigateBack} />
      )}
      {breadcrumbs.map(({ breadcrumb }, breadcrumbIndex) => {
        const isFirstBreadcrumb = breadcrumbIndex === 0;
        const isLastBreadcrumb = breadcrumbIndex === breadcrumbs.length - 1;

        const isNested = !isLastBreadcrumb && hasGoBackButton;
        const isRootRoute = isFirstBreadcrumb && routes.PRIORITY_TASKS !== location.pathname;

        if (isNested || isRootRoute) {
          return null;
        }

        return (
          <Fragment key={`breadcrumb-${breadcrumbIndex}`}>
            {breadcrumb}
            {!isLastBreadcrumb && (
              <IconWrapper>
                <Icon backgroundSize={24} width={9} height={18} color={theme.colors.DARK_GREY} name="arrowFillRight" />
              </IconWrapper>
            )}
          </Fragment>
        );
      })}
      <BundleJobButton />
    </BreadcrumbsWrapper>
  );
};

export default BreadcrumbsNavigation;
