import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import styles from 'constants/styles';

export const CardBorder = styled.div`
  display: flex;
  flex: 1;
  cursor: pointer;
  flex-direction: column;
  padding: 24px 12px 0 12px;

  ${({ isHighlighted, theme }) => isHighlighted && `background-color: ${theme.colors.WHITE}33;`};
`;

export const Line = styled.div`
  padding-top: 30px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.DARK_GREY_3};
`;

export const FlexContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

export const FlexOneColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const FlexColumn = styled.div`
  align-self: flex-start;
  display: flex;
  flex-direction: column;
`;

export const FlexRow = styled.div`
  flex: 1;
  display: flex;
  white-space: pre-wrap;
`;

export const FlexTitleRow = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const UserNameNavLink = styled(NavLink)`
  font-weight: 700;
  font-size: 14px;
  line-height: 1.22;
  color: ${({ theme }) => theme.colors.WHITE};
  white-space: pre;
  display: flex;

  &:hover {
    text-decoration: underline;
  }
`;

export const StampText = styled.span`
  font-weight: 600;
  font-size: 10px;
  line-height: 1.5;

  text-align: right;
  margin-left: 10px;

  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.LIGHT_GRAY};
`;

export const DropDownWrapper = styled.div`
  margin-left: 15px;
  position: relative;
  right: -7px;
`;

export const DelimiterBlock = styled.div`
  flex: 1;
  width: 100%;

  margin: 13px 0 0;
`;

export const FieldValue = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 1.33;
  margin-top: 9px;
  word-break: break-word;
  color: ${({ theme }) => theme.colors.WHITE};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

export const Dot = styled.span`
  height: ${({ size }) => size || 12}px;
  width: ${({ size }) => size || 12}px;
  margin: 3px 5px 0 0;
  background-color: ${({ backgroundColor, isVisible }) =>
    isVisible ? backgroundColor || styles.colors.YELLOW : 'rgba(0,0,0,0.0)'};
  border-radius: 50%;
  display: inline-block;
`;

export const phoneNumberTextStyle = css`
  font-weight: 600;
  font-size: 14px;
  line-height: 1.3;
  color: ${({ theme }) => theme.colors.GRAY};
`;
