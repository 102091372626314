import React from 'react';

import { useDispatch, useSelector, useState } from 'hooks';
import { numberWithCommas, convertNumberToString, convertStringToNumber } from 'utils';
import { updateCustomerLocation } from 'store/actions/customer';
import { selectServiceInstance, selectServiceLocation } from 'store/reducers/service';
import { selectUpdateCustomerLocation } from 'store/reducers/customer';

import { EditableLabel } from '../';
import { DataRow, DataRecord, FieldTitle, FieldValue } from '../styled';
import { HomeValueRow } from './styled';

const HomeInfoRow = () => {
  const dispatch = useDispatch();
  const job = useSelector(selectServiceInstance);
  const location = useSelector(selectServiceLocation);
  const updateLocationLoading = useSelector(selectUpdateCustomerLocation);
  const [currentEditingField, setCurrentEditingField] = useState(null);

  const handleLocationItemUpdate =
    (fieldName) =>
    ({ inputField }) => {
      const parsedValue = convertStringToNumber(inputField);
      setCurrentEditingField(fieldName);

      if (parsedValue !== location[fieldName]) {
        dispatch(
          updateCustomerLocation({
            customerLocationId: location.customer_location_id,
            serviceId: job.id,
            data: {
              [fieldName]: parsedValue || null
            }
          })
        );
      }
    };

  return (
    <DataRow>
      <DataRecord flex={3}>
        <FieldTitle>Home Address</FieldTitle>
        <FieldValue>
          {location.address ? (
            job.customer?.home_listing ? (
              <a target="_blank" href={job.customer?.home_listing}>
                {location.address}
              </a>
            ) : (
              location.address
            )
          ) : (
            '-'
          )}
        </FieldValue>
        {location.unit_number ? <FieldValue>unit #{location.unit_number}</FieldValue> : null}
      </DataRecord>
      <DataRecord>
        <FieldTitle>Sold price</FieldTitle>
        <HomeValueRow>
          <EditableLabel
            disabled={!job.is_open}
            name="estimate_value"
            onBlur={handleLocationItemUpdate('estimate_value')}
            selectedEditingField={currentEditingField}
            sourceFieldLoading={updateLocationLoading}
            type="CURRENCY"
            value={
              location.estimate_value !== null ? numberWithCommas(convertNumberToString(location.estimate_value)) : ''
            }
          />
        </HomeValueRow>
      </DataRecord>
      <DataRecord>
        <FieldTitle>Year Built</FieldTitle>
        <EditableLabel
          disabled={!job.is_open}
          name="year_built"
          onBlur={handleLocationItemUpdate('year_built')}
          selectedEditingField={currentEditingField}
          sourceFieldLoading={updateLocationLoading}
          type="NUMBER"
          value={location.year_built !== null ? convertNumberToString(location.year_built) : ''}
        />
      </DataRecord>
      <DataRecord>
        <FieldTitle>SQFT</FieldTitle>
        <EditableLabel
          disabled={!job.is_open}
          name="square"
          onBlur={handleLocationItemUpdate('square')}
          selectedEditingField={currentEditingField}
          sourceFieldLoading={updateLocationLoading}
          type="NUMBER"
          value={location.square !== null ? numberWithCommas(convertNumberToString(location.square)) : ''}
        />
      </DataRecord>
    </DataRow>
  );
};

export default HomeInfoRow;
