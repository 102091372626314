import React from 'react';
import { Icon } from 'components';
import { cellTypes } from 'common/enums/table';
import dates from 'constants/dates';
import styles from 'constants/styles';
import { formatters, formatDateShortTimeStampSmall } from 'utils';
import { getAuthorByType } from 'common/helpers/job';
import { quoteTable } from '../enums';

export const getColumns = ({ tabId, onDownloadCSV }) => {
  return [
    {
      title: quoteTable.DOWNLOAD_CSV_BUTTON.TITLE,
      accessor: quoteTable.DOWNLOAD_CSV_BUTTON.FILTER_NAME,
      hasSorting: false,
      onDownloadCSV,
      Header: () => (
        <Icon name="download" size={14} backgroundSize={24} onClick={onDownloadCSV} color={styles.colors.WHITE} />
      )
    },
    {
      id: quoteTable.JOB_ID.COLUMN_NAME,
      title: quoteTable.JOB_ID.TITLE,
      cellType: cellTypes.TEXT,
      minWidth: 70,
      accessor: ({ service_request_id }) => ({
        text: String(service_request_id),
        hasHighlight: true
      })
    },
    {
      id: quoteTable.HO_GIVEN_NAME.COLUMN_NAME,
      title: quoteTable.HO_GIVEN_NAME.TITLE,
      cellType: cellTypes.USER_NAME,
      minWidth: 120,
      accessor: ({ customer = {} }) => {
        return {
          given_name: customer.given_name,
          family_name: customer.family_name,
          stage: customer.stage,
          hasHighlight: true
        };
      }
    },
    {
      id: quoteTable.HO_ID.COLUMN_NAME,
      title: quoteTable.HO_ID.TITLE,
      cellType: cellTypes.TEXT,
      minWidth: 90,
      accessor: ({ customer_id }) => ({
        text: String(customer_id),
        hasHighlight: true
      })
    },

    {
      id: quoteTable.PRO_GIVEN_NAME.COLUMN_NAME,
      title: quoteTable.PRO_GIVEN_NAME.TITLE,
      cellType: cellTypes.USER_NAME,
      minWidth: 150,
      accessor: ({ provider = {} }) => ({
        given_name: provider.given_name,
        family_name: provider.family_name,
        pro_stage: provider.pro_stage,
        hasHighlight: true
      })
    },
    {
      id: quoteTable.PRO_ID.COLUMN_NAME,
      title: quoteTable.PRO_ID.TITLE,
      cellType: cellTypes.TEXT,
      minWidth: 120,
      accessor: ({ provider_id }) => ({
        text: String(provider_id),
        hasHighlight: true
      })
    },
    {
      id: quoteTable.JOB_CATEGORY_NAME.COLUMN_NAME,
      title: quoteTable.JOB_CATEGORY_NAME.TITLE,
      cellType: cellTypes.TEXT,
      minWidth: 180,
      accessor: ({ service_name }) => ({
        text: service_name
      })
    },
    {
      id: quoteTable.DATETIME_CREATED.COLUMN_NAME,
      title: quoteTable.DATETIME_CREATED.TITLE,
      cellType: cellTypes.TEXT,
      minWidth: 150,
      accessor: ({ date_created }) => ({
        text: formatters.dateWithTimezone(date_created, dates.DATE)
      })
    },
    {
      id: quoteTable.AMOUNT_TOTAL_CHARGE.COLUMN_NAME,
      title: quoteTable.AMOUNT_TOTAL_CHARGE.TITLE,
      cellType: cellTypes.TEXT,
      hasSorting: true,
      minWidth: 150,
      accessor: ({ amount_total_charge }) => ({
        text: formatters.dollarCurrencyDB(amount_total_charge || 0)
      })
    },
    {
      id: quoteTable.LAST_MESSAGE.COLUMN_NAME,
      hasSorting: false,
      title: quoteTable.LAST_MESSAGE.TITLE,
      cellType: cellTypes.RICH_TEXT,
      minWidth: 200,
      accessor: ({ last_message }) => {
        return {
          datetime: formatters.messageDate(last_message?.datetime_sent),
          text: last_message?.message,
          secondText: getAuthorByType({ author: last_message?.author_name, type: last_message?.author_type })
        };
      }
    },
    {
      id: quoteTable.LAST_NOTE.COLUMN_NAME,
      hasSorting: false,
      title: quoteTable.LAST_NOTE.TITLE,
      cellType: cellTypes.RICH_TEXT,
      minWidth: 200,
      accessor: ({ datetime_last_note, last_note }) => ({
        datetime: formatDateShortTimeStampSmall(datetime_last_note),
        text: last_note
      })
    }
  ];
};
