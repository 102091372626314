import actionTypes from 'constants/actionTypes';

export const getNotificationList = (payload) => ({
  type: actionTypes.GET_NOTIFICATION_LIST_FETCH,
  payload
});

export const deletePersistentNotification = (payload) => ({
  type: actionTypes.DELETE_PERSISTENT_NOTIFICATION_FETCH,
  payload
});

export const addNotificationToPersistentList = (payload) => ({
  type: actionTypes.ADD_NOTIFICATION_TO_PERSISTENT_LIST,
  payload
});

export const showNotificationAlert = (payload) => ({
  type: actionTypes.SHOW_NOTIFICATION_ALERT,
  payload
});

export const removeNotificationAlert = (payload) => ({
  type: actionTypes.REMOVE_NOTIFICATION_ALERT,
  payload
});
