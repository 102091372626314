import styled, { css } from 'styled-components';

export const Title = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 1.14;
  color: ${({ theme }) => theme.colors.WHITE};
  margin: 24px 0 18px 0;
`;

export const NotificationWrapper = styled.div`
  margin-bottom: 24px;
`;

export const Wrapper = styled.div`
  margin: 32px 24px 24px 24px;
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
`;

export const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const Box = styled.div`
  margin-top: ${({ marginTop }) => marginTop}px;
`;

export const checkboxWrapperCSS = css`
  margin: 5px 0;
`;
