import React from 'react';

import { useTheme } from 'hooks';
import { commissionTypes } from 'common/enums/invoice';
import { StyledTooltip, Icon } from 'components';

import { summaryTooltipContent } from './enums';
import { TooltipText } from './styled';

export const SummaryTitleTooltip = ({ isMarginFee }) => {
  const theme = useTheme();

  return (
    <StyledTooltip
      arrow
      placement="top"
      title={
        <>
          {Object.values(summaryTooltipContent[isMarginFee ? commissionTypes.MARGIN : commissionTypes.SUB_FEE]).map(
            ({ tootipTextProps = {}, prefix, suffix, description, title }, index) => (
              <TooltipText {...tootipTextProps} key={index}>
                {prefix} <b>{title}</b>
                {suffix || ' = '}
                {description}
              </TooltipText>
            )
          )}
        </>
      }>
      <div>
        <Icon name="infoOutlined" color={theme.colors.DARK_4} />
      </div>
    </StyledTooltip>
  );
};
