import { call, takeLatest, put } from 'redux-saga/effects';
import api_constants from 'constants/api';
import errors from 'constants/errors';
import actionTypes from 'constants/actionTypes';
import Api from 'services/ApiService';
import { generateQueryParams } from 'common/helpers/httpRequest';

function* getAdminList({ payload }) {
  try {
    const response = yield call(Api.amplifyGet, `${api_constants.ADMIN}${generateQueryParams(payload)}`);

    if (!(response || response.results)) {
      throw new Error(errors.DEFAULT);
    }

    yield put({
      type: actionTypes.GET_ADMIN_LIST_SUCCESS,
      payload: {
        search: payload.search,
        count: response.count,
        list: response.results
      }
    });
  } catch (err) {
    yield put({
      type: actionTypes.GET_ADMIN_LIST_ERROR
    });
  }
}

export default function* watchGetAdminList() {
  yield takeLatest(actionTypes.GET_ADMIN_LIST_FETCH, getAdminList);
}
