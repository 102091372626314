// React/Redux
import React, { useState } from 'react';
import { connect } from 'react-redux';
// Libs
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
// UI Libs
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import StaticDatePicker from '@mui/lab/StaticDatePicker';
import DateAdapter from '@mui/lab/AdapterMoment';
// Store
import { closeModal, snoozeTask } from 'store/actions';
import { selectModalData } from 'store/reducers/modal';
import { selectUpdateJobFieldLoading } from 'store/reducers/service';
// Constants
import modalTypes from 'constants/modalTypes';
import dates from 'constants/dates';
// Components
import { Button, Modal } from 'components';
// Styles
import { ButtonsWrapper, ModalTitle, ContentWrapper, Line, RelativeWrapper } from './styled';

const SnoozeTask = ({ closeModal, snoozeTask, modalData, loading }) => {
  const [dateSnooze, setDateSnooze] = useState(moment('00:00', dates.TIME_24));
  const { task_id, date_end_hold } = modalData;
  const hideModal = () => closeModal(modalTypes.SNOOZE_TASK);
  const thisMoment = moment();

  const handleSetOnSnooze = () => {
    if (!task_id) {
      return;
    }

    // Set fixed hardcoded time of tasks' off-hold
    const snoozeDateObject = moment(dateSnooze.format(dates.DATE_ISO));
    snoozeDateObject.set({ hour: 6, minute: 0, second: 0, millisecond: 0 });
    const snoozeDateISOString = snoozeDateObject.toISOString(true);
    const snoozeDateFormattedString = snoozeDateObject.format(dates.DATETIME_FULL_DAY);

    snoozeTask({
      task_id,
      date_end_hold: snoozeDateISOString,
      snoozeDateFormattedString
    });
  };

  const handleSetOffSnooze = () => {
    if (!task_id) {
      return;
    }

    snoozeTask({
      task_id,
      date_end_hold: null
    });
  };

  return (
    <Modal modalType={modalTypes.SNOOZE_TASK} hideModal={hideModal} padding="34px 30px 24px" maxHeight={0} minWidth={1}>
      {!date_end_hold ? (
        <ContentWrapper>
          <ModalTitle>{'Select On-Hold duration date'}</ModalTitle>
          <LocalizationProvider dateAdapter={DateAdapter}>
            <Line />
            <RelativeWrapper>
              <StaticDatePicker
                value={dateSnooze}
                onChange={setDateSnooze}
                minDate={thisMoment}
                renderInput={() => null}
                showDaysOutsideCurrentMonth={false}
                disableCloseOnSelect
                reduceAnimations
                displayStaticWrapperAs="desktop"
                views={['day']}
                disabled={loading}
              />
            </RelativeWrapper>
          </LocalizationProvider>
          <ButtonsWrapper>
            <Button title="CANCEL" variant="primary" onClick={hideModal} />
            <Button title="SET UP" onClick={handleSetOnSnooze} loading={loading} disabled={!dateSnooze.isValid()} />
          </ButtonsWrapper>
        </ContentWrapper>
      ) : (
        <ContentWrapper>
          <ModalTitle>{`Do you want to move the job ID ${task_id} from the on-hold list?`}</ModalTitle>
          <ButtonsWrapper>
            <Button title="CANCEL" onClick={hideModal} />
            <Button
              title="MOVE BACK"
              onClick={handleSetOffSnooze}
              variant="primary"
              loading={loading}
              disabled={!dateSnooze.isValid()}
            />
          </ButtonsWrapper>
        </ContentWrapper>
      )}
    </Modal>
  );
};

SnoozeTask.propTypes = {
  closeModal: PropTypes.func.isRequired,
  snoozeTask: PropTypes.func.isRequired,
  modalData: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => ({
  modalData: selectModalData(state),
  loading: selectUpdateJobFieldLoading(state)
});

const mapDispatchToProps = {
  closeModal,
  snoozeTask
};

export default connect(mapStateToProps, mapDispatchToProps)(SnoozeTask);
