import { campaignNavigationTypes, cohortTypes } from 'common/enums/campaigns';

const defaultValues = {
  execution: {
    cohort_type: cohortTypes.WORKING_AREA
  },
  article: {
    is_button_hidden: true,
    navigation_type: campaignNavigationTypes.HOME_PAGE.TYPE,
    navigation_payload: {
      job_type: null
    }
  }
};

export const getDefaultFormValues = ({ campaign }) => {
  if (!campaign?.id) {
    return defaultValues;
  }

  const { cohort_type, campaign_push, campaign_execute, article } = campaign;
  const navigationType = [
    campaignNavigationTypes.EXTERNAL_LINK.TYPE,
    campaignNavigationTypes.INTERNAL_LINK.TYPE
  ].includes(article.navigation_type)
    ? campaignNavigationTypes.REDIRECT_LINK.TYPE
    : article.navigation_type;
  return {
    campaign_push: {
      template_title: campaign_push?.template_title,
      template_body: campaign_push?.template_body
    },
    execution: {
      csv_cohort_path: campaign_execute.csv_cohort_path || '',
      csv_cohort_name: campaign_execute.csv_cohort_name || '',
      cohort_type: cohort_type,
      datetime_execute: campaign_execute.datetime_execute,
      service_area_id_list: campaign_execute.service_areas?.map(({ id, name }) => ({
        id,
        value: id,
        name,
        label: name
      })),
      recipients_list: campaign_execute.recipients_list
    },
    article: {
      is_button_hidden: article.is_button_hidden,
      navigation_type: navigationType || defaultValues.article.navigation_type,
      navigation_payload: {
        request_source_name: article.navigation_payload.request_source_name || campaign_push?.template_title || '',
        job_type: article.navigation_payload.job_type ?? null,
        job_category_id: article.navigation_payload.job_category_id ?? null,
        job_description: article.navigation_payload.job_description ?? ''
      },
      service_area_id_list: article.service_areas?.map(({ id, name }) => ({ id, value: id, name, label: name })),
      title: article.title || null,
      background_color: article.background_color || null,
      article_title: article.article_title || null,
      description_html: article.description_html,
      button_title: article.button_title ?? '',
      cta: article.cta || null,
      source_link: article.source_link ? article.source_link.replace('https://dobbyhome.com/app/', '') : null,
      ...(article.navigation_type === campaignNavigationTypes.EXTERNAL_LINK.TYPE
        ? {
            redirect_link: article.external_link || ''
          }
        : {}),
      ...(article.navigation_type === campaignNavigationTypes.INTERNAL_LINK.TYPE
        ? {
            redirect_link: article.internal_link || ''
          }
        : {})
    }
  };
};
