import actionTypes from 'constants/actionTypes';

export const getCampaignsList = (payload) => ({
  type: actionTypes.GET_CAMPAIGNS_LIST_FETCH,
  payload
});

export const createCampaign = (payload) => ({
  type: actionTypes.CREATE_CAMPAIGN_FETCH,
  payload
});

export const sendTestCampaignEvent = (payload) => ({
  type: actionTypes.SEND_TEST_CAMPAIGN_EVENT_FETCH,
  payload
});

export const getCampaignById = (payload) => ({
  type: actionTypes.GET_CAMPAIGN_BY_ID_FETCH,
  payload
});

export const deleteCampaign = (payload) => ({
  type: actionTypes.DELETE_CAMPAIGN_FETCH,
  payload
});

export const editCampaign = (payload) => ({
  type: actionTypes.EDIT_CAMPAIGN_FETCH,
  payload
});
