import React from 'react';

import { useCallback, useDispatch, useFilters, useMemo, useSelector } from 'hooks';

import { defaultQueryParams } from 'common/enums/queryParams';
import { siblingPaginationTypes } from 'common/enums/navigation';
import routes from 'constants/routes';

import { getSkills } from 'store/actions/provider';
import { selectSkillsLoading, selectSkills, selectSkillsTotalCount } from 'store/reducers/provider';
import { getColumns, getRows } from './helpers';

import { PaginationFullScreen, Table } from 'components';

const Skills = () => {
  const dispatch = useDispatch();
  const list = useSelector(selectSkills);
  const listLoading = useSelector(selectSkillsLoading);
  const totalCount = useSelector(selectSkillsTotalCount);

  const handleFetchCustomerList = useCallback(
    (updatedFilters = {}) => {
      dispatch(
        getSkills({
          ...defaultQueryParams,
          ...updatedFilters
        })
      );
    },
    [dispatch]
  );

  const { filters, handleChangePage, handleChangeSorting, handleRowClick, handleRowContextClick } = useFilters({
    onFetchList: handleFetchCustomerList,
    instanceRoute: routes.PROVIDER,
    siblingPaginationType: siblingPaginationTypes.PROVIDER,
    totalCount
  });
  const page = Number(filters.page);
  const columns = useMemo(() => getColumns({ page }), [page]);
  const data = useMemo(() => getRows({ list }), [list]);

  return (
    <PaginationFullScreen
      count={totalCount}
      currentPage={page}
      onPageChange={handleChangePage}
      length={list?.length}
      loading={listLoading}
      searchTerm={filters.search}
      hasNavigationTabs>
      <Table
        noClick
        onSort={handleChangeSorting}
        onRowClick={handleRowClick}
        onRowContextClick={handleRowContextClick}
        searchTerm={filters.search}
        columns={columns}
        data={data}
        sortingDirection={filters.sorting_direction}
        sortingColumnName={filters.sorting_column}
      />
    </PaginationFullScreen>
  );
};

export default Skills;
