import React, { Component, lazy } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Storage } from 'aws-amplify';
import memoize from 'memoize-one';
import { LoadingSpinner, Icon, LoadingDots } from 'components';
import { ProgressAuthor } from './components';
import {
  selectServiceProgressListLoading,
  selectServiceProgressList,
  selectServiceProgressListCount,
  selectServiceProgressListPage,
  selectServiceProgressNextPageLoading
} from 'store/reducers/service';
import { selectJobInvoices } from 'store/reducers/invoice';
import { formatDateShortTimeStamp } from 'utils';
import {
  ProgressHeader,
  ProgressStack,
  ProgressAction,
  ProgressPointer,
  ProgressData,
  ProgressTitleRow,
  ProgressTitle,
  ProgressTitleNote,
  ProgressCreated,
  ProgressDescription,
  LoadingContainer,
  ProgressSection,
  ReviewAttachment,
  ReviewAttachmentIconWrapper,
  LoadMoreButton
} from './styled';
import { getPlanSliceIndex } from '../../helpers';
import { PLAN_LIST } from '../../constants';
import { styles } from '../../../../constants';
import { getServiceProgressList } from 'store/actions';
const Lightbox = lazy(() => import('react-image-lightbox'));

class ProgressPlan extends Component {
  state = {
    isLightBoxShown: false,
    mediaUrl: null
  };

  serviceToActionPlan = memoize((props) => {
    return PLAN_LIST.slice(getPlanSliceIndex(props));
  });

  openAttachedFile = (innerPath) => async () => {
    const signedUrl = await Storage.get(innerPath);
    this.setState({
      mediaUrl: signedUrl,
      isLightBoxShown: true
    });
  };

  closeImageLightBox = () =>
    this.setState({
      isLightBoxShown: false,
      mediaUrl: null
    });

  getNextPage = (page) => {
    this.props.getServiceProgressList({
      page,
      serviceId: this.props.serviceInstance.id,
      nextPage: true
    });
  };

  render() {
    const { isLightBoxShown, mediaUrl } = this.state;
    const {
      progressListLoading,
      serviceInstanceLoading,
      serviceInstance,
      progressList,
      serviceId,
      invoices,
      progressListCount,
      progressListPage,
      nextPageLoading
    } = this.props;
    const pageCount = Math.ceil(progressListCount / 50);
    const isLastPage = pageCount == progressListPage;
    const futureSteps = serviceInstanceLoading
      ? []
      : this.serviceToActionPlan({
          statusCode: serviceInstance.status,
          datetimeVisit: serviceInstance.datetime_visit,
          isOpen: serviceInstance.is_open,
          finalPayment: invoices?.find((invoice) => invoice.is_final_payment) || null,
          quotation: invoices?.find((invoice) => invoice.is_quotation) || null,
          isAnyPayment: invoices?.some((invoice) => !invoice.is_quotation)
        });
    return (
      <ProgressSection>
        {Boolean(!serviceInstanceLoading && serviceInstance.id) && <ProgressHeader>Workflow Progress</ProgressHeader>}
        {serviceInstance.id !== serviceId && progressListLoading && !serviceInstanceLoading ? (
          <LoadingContainer>
            <LoadingSpinner text="Please wait" type="logo" />
          </LoadingContainer>
        ) : !serviceInstance.id ? (
          <></>
        ) : (
          <ProgressStack>
            {progressList.map((item, index) => (
              <ProgressAction key={item.id} isLast={futureSteps.length === 0 && progressList.length === index + 1}>
                <ProgressPointer isActive={progressList.length === index + 1} />
                <ProgressData>
                  <ProgressTitleRow>
                    <ProgressTitle
                      isActive={progressList.length === index + 1}
                      color={
                        !(progressList?.length === index + 1) && item.title?.startsWith('HO Review')
                          ? styles.colors.WHITE
                          : undefined
                      }
                      isYellow={item.title?.startsWith('Failed Payout')}>
                      {item.title}
                    </ProgressTitle>
                    <ProgressAuthor author={item.author} />
                    <ProgressCreated>{formatDateShortTimeStamp(item.date_created)}</ProgressCreated>
                    {item?.title_note && (
                      <ProgressTitleNote isActive={progressList.length === index + 1}>
                        {item.title_note}
                      </ProgressTitleNote>
                    )}
                  </ProgressTitleRow>
                  <ProgressDescription
                    isActive={progressList.length === index + 1}
                    children={item.description}
                    color={
                      !(progressList?.length === index + 1) && item.title?.startsWith('HO Review')
                        ? styles.colors.WHITE
                        : undefined
                    }
                  />
                  {item.review_media && (
                    <ReviewAttachment onClick={this.openAttachedFile(item.review_media?.inner_path)}>
                      <ReviewAttachmentIconWrapper>
                        <Icon name="attachment" size={13} />
                      </ReviewAttachmentIconWrapper>
                      {item.review_media?.file_name || item.review_media?.original_name}
                    </ReviewAttachment>
                  )}
                </ProgressData>
              </ProgressAction>
            ))}
            {futureSteps.map((item, index) => (
              <ProgressAction key={'plan' + index} isLast={futureSteps.length > 0 && futureSteps.length === index + 1}>
                <ProgressPointer />
                <ProgressData>
                  <ProgressTitleRow>
                    <ProgressTitle future>{item.title}</ProgressTitle>
                  </ProgressTitleRow>
                </ProgressData>
              </ProgressAction>
            ))}
          </ProgressStack>
        )}
        {!serviceInstanceLoading && !isLastPage && (
          <LoadMoreButton onClick={() => this.getNextPage(progressListPage + 1)}>
            {nextPageLoading ? <LoadingDots /> : 'Load More...'}
          </LoadMoreButton>
        )}
        {isLightBoxShown && <Lightbox mainSrc={mediaUrl} onCloseRequest={this.closeImageLightBox} />}
      </ProgressSection>
    );
  }
}

ProgressPlan.propTypes = {
  progressList: PropTypes.array.isRequired,
  progressListLoading: PropTypes.bool.isRequired,
  progressListCount: PropTypes.number.isRequired
};

ProgressPlan.defaultProps = {
  progressList: [],
  progressListLoading: true,
  progressListCount: 0
};

const mapStateToProps = (state) => ({
  progressList: selectServiceProgressList(state),
  progressListLoading: selectServiceProgressListLoading(state),
  progressListCount: selectServiceProgressListCount(state),
  progressListPage: selectServiceProgressListPage(state),
  nextPageLoading: selectServiceProgressNextPageLoading(state),
  invoices: selectJobInvoices(state)
});

const mapDispatchToProps = {
  getServiceProgressList
};

export default connect(mapStateToProps, mapDispatchToProps)(ProgressPlan);
