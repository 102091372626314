import styled from 'styled-components';
import styles from 'constants/styles';

export const NotificationPopupShadow = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 25;
  display: ${({ visible }) => (visible ? 'block' : 'none')};
`;

export const NotificationPopupWrapper = styled.div`
  background-color: ${styles.colors.WHITE};
  box-shadow: ${({ theme }) => theme.boxShadows.THIN_DARK_3}
  border-radius: 8px;

  position: absolute;
  top: 61px;
  right: 72px;
  z-index: 25;
  max-height: 543px;
  min-width: 500px;
  overflow-y: hidden;
`;

export const NotificationListWrapper = styled.div`
  max-height: 480px;
  overflow-y: overlay;
  background: ${({ theme }) => theme.colors.NOTIFICATIONS};
`;

export const NotificationsListHeading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 24px;
  ${({ theme }) => theme.darkMode && `background: ${theme.colors.DARK_3}`};
  ${({ theme, isEmptyList }) => !theme.darkMode && !isEmptyList && `box-shadow: -2px 3px 6px rgba(0, 0, 0, 0.1);`};
`;

export const NotificationsListHeader = styled.div`
  font-weight: 400;
  font-size: 20px;
  line-height: 1.2;
  color: ${({ theme }) => theme.colors.INVOICE_TEXT};
`;

export const ClearAllStyledButton = styled.button`
  border: none;
  box-shadow: none;
  min-width: 80px;
  outline: 0;
  justify-content: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${styles.colors.LIGHT_GRAY_2};
  color: ${styles.colors.BLACK};
  text-transform: uppercase;
  letter-spacing: 1.2px;
  font-weight: 300;
  font-size: 10px;
  line-height: 15px;
  border-radius: 61px;
  padding: 4px 3px 5px;

  &:hover {
    color: ${styles.colors.YELLOW};
  }

  &:focus {
    outline: 0;
  }
`;

export const NotificationsDivider = styled.div`
  height: 1px;
  width: 100%;
  background: ${({ theme }) => theme.colors.NOTIFICATION_DIVIDER};
  width: 452px;
  margin: 0 0 0 24px;
`;
export const LoadingWrapper = styled(NotificationPopupWrapper)`
  width: 500px;
  height: 400px;
  min-height: 300px;
  background-color: ${styles.colors.WHITE};
`;

export const LoadMoreButton = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 1.14;
  width: 100%;
  cursor: pointer;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.DARK_GREY};
  background-color: ${({ theme }) => theme.colors.DARK_3};
  &:hover {
    background-color: ${({ theme }) => theme.colors.DARK_4};
    color: ${({ theme }) => theme.colors.WHITE};
  }

  &:focus {
    outline: 0;
  }
`;

export const EmptyPlaceholder = styled.div`
  padding: 15px 0;
  width: 100%;
  text-align: center;
  font-size: 14px;
  line-height: 1.2;
  color: ${({ theme }) => theme.colors.INVOICE_TEXT};
`;
