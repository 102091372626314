import styled from 'styled-components';
import styles from 'constants/styles';

export const reportTypeSelectStyles = {
  container: (provided) => ({
    ...provided
  }),
  control: (provided, state) => ({
    ...provided,
    minWidth: 168,
    background: styles.colors.WHITE,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: styles.colors.LIGHT_GRAY,
    borderRadius: 4,
    flex: 'none',
    boxShadow: state.isOpened && `0 0 0 1px ${styles.colors.GRAY}`
  }),
  valueContainer: (provided) => ({
    ...provided,
    color: styles.colors.BLACK,
    fontSize: 14
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none'
  }),
  option: (provided, state) => ({
    ...provided,
    color: styles.colors.BLACK,
    fontSize: 14,
    paddingRight: 10,
    backgroundColor: state.isFocused && styles.colors.LIGHT_GRAY
  })
};

export const FormHolder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const UploadFileInput = styled.input`
  position: absolute !important;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden;
`;

export const UploadFileButton = styled.button`
  border: none;
  box-shadow: none;
  border: 1px solid ${styles.colors.LIGHT_GRAY};
  border-radius: 4px;
  padding: 3px 38px;
  font-size: 14px;
  line-height: 1.2;
  width: 169px;
  background-color: ${styles.colors.WHITE};
  color: ${styles.colors.BLACK};

  &:disabled {
    color: ${styles.colors.LIGHT_GRAY};
  }
`;

export const UploadedFile = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 7px;
  max-width: 168px;
`;

export const UploadedFileName = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 1.7;
  color: ${({ theme }) => theme.colors.INVOICE_TEXT};
  padding-right: 2px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const UploadedFileClose = styled.button`
  border: none;
  box-shadow: none;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  outline: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${styles.colors.LIGHT_GRAY};

  &:focus {
    outline: 0;
  }
`;

export const ReportHeader = styled.span`
  width: 100%;
  text-align: center;

  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 15px;
`;

export const InputWrapper = styled.div`
  margin-bottom: ${({ marginBottom }) => marginBottom}px;
  display: flex;
  flex-direction: column;
`;

export const FieldTitle = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 1.7;
  color: ${styles.colors.GRAY};
  margin-bottom: 5px;
`;

export const DropdownArrow = styled.div`
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 9px solid ${styles.colors.LIGHT_GRAY};
`;
