import actionTypes from 'constants/actionTypes';

export const getCustomerInspectionReport = (data) => ({
  type: actionTypes.GET_CUSTOMER_INSPECTION_REPORT_FETCH,
  payload: data
});

export const getCustomerInstance = (id) => ({
  type: actionTypes.GET_CUSTOMER_INSTANCE_FETCH,
  payload: id
});

export const getCustomerLocation = (payload) => ({
  type: actionTypes.GET_CUSTOMER_LOCATION_FETCH,
  payload
});

export const getCustomerList = (data) => ({
  type: actionTypes.GET_CUSTOMER_LIST_FETCH,
  payload: data
});

export const uploadCustomerInspectionReport = (data) => ({
  type: actionTypes.UPLOAD_CUSTOMER_INSPECTION_REPORT_FETCH,
  payload: data
});

export const getRewards = (payload) => ({
  type: actionTypes.GET_REWARDS_FETCH,
  payload
});

export const getCustomerReviews = (payload) => ({
  type: actionTypes.GET_CUSTOMER_REVIEWS_FETCH,
  payload
});

export const updateCustomerInstance = (payload) => ({
  type: actionTypes.UPDATE_CUSTOMER_INSTANCE_FETCH,
  payload
});

export const updateCustomerLocation = (data) => ({
  type: actionTypes.UPDATE_CUSTOMER_LOCATION_FETCH,
  payload: data
});

export const getCustomerReferrals = (payload) => ({
  type: actionTypes.GET_CUSTOMER_REFERRALS_FETCH,
  payload
});

export const manageCustomerReward = (data) => ({
  type: actionTypes.MANAGE_CUSTOMER_REWARD_FETCH,
  payload: data
});

export const getCustomerDobbyCash = (data) => ({
  type: actionTypes.GET_CUSTOMER_DOBBY_CASH_FETCH,
  payload: data
});

export const getCustomerDobbyCashTransactions = (payload) => ({
  type: actionTypes.GET_CUSTOMER_DOBBY_CASH_TRANSACTIONS_FETCH,
  payload
});
