import React from 'react';
import { useDispatch, useSelector } from 'hooks';
import { closeModal, updateRequest, refreshTaskInfo } from 'store/actions';
import { selectModalData } from 'store/reducers/modal';
import { selectServiceFormLoading } from 'store/reducers/service';

import modalTypes from 'constants/modalTypes';

import { ModalConfirmation } from 'components';

import { ModalText, BoldTextWrapper } from './styled';

const ReopenTask = () => {
  const modalData = useSelector(selectModalData);
  const { task_id } = modalData;
  const taskStatusLoading = useSelector(selectServiceFormLoading);
  const dispatch = useDispatch();

  const onModalClose = () => {
    dispatch(closeModal(modalTypes.REOPEN_TASK));
  };

  const onModalSuccess = () => {
    dispatch(refreshTaskInfo({ task_id, silent: true, isPaymentInfo: true }));
    onModalClose();
  };

  const onReopenTask = () => {
    if (!task_id) {
      return;
    }
    dispatch(
      updateRequest({
        id: task_id,
        admin_status: 1,
        onRequestProcessed: onModalSuccess
      })
    );
  };

  return (
    <ModalConfirmation
      modalType={modalTypes.REOPEN_TASK}
      padding="40px 30px"
      title={`Please confirm reopen of the job`}
      text={
        <ModalText>
          <BoldTextWrapper>HO won't be notified about reopened job</BoldTextWrapper>
        </ModalText>
      }
      hideModal={onModalClose}
      primaryButton={{
        disabled: taskStatusLoading,
        onClick: onModalClose
      }}
      secondaryButton={{
        title: 'Confirm',
        loading: taskStatusLoading,
        disabled: taskStatusLoading,
        onClick: onReopenTask
      }}
    />
  );
};

export default ReopenTask;
