import styled from 'styled-components';
import styles from 'constants/styles';

export const CancelationReasonWrapper = styled.div`
  padding: 32px 24px;
  display: flex;
  flex-direction: column;

  flex: 1;
  width: 100%;
`;

export const FlexRow = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  padding: 13px 0 5px;

  &:first-child {
    padding: 18px 0 5px;
  }
  &:last-child {
    padding: 28px 0 0;
    justify-content: flex-end;
  }
`;

export const dropdownStyles = {
  background: styles.colors.DARK_GREY_3
};
