import styled from 'styled-components';
import styles from 'constants/styles';

export const BoldText = styled.span`
  font-weight: 700;
  color: ${({ theme }) => theme.colors.INVOICE_TEXT};
`;

export const FlexRow = styled.div`
  display: flex;
  flex: 1;
  padding: 13px 0 5px;
  &:first-child {
    padding: 18px 0 5px;
  }
  &:last-child {
    padding: 28px 0 20px;
    justify-content: space-between;
  }
`;

export const FlexColumn = styled.div`
  display: flex;
  flex: 1;
  margin: 24px 0 20px 0;
`;

export const Header = styled.span`
  font-weight: 600;
  font-size: 24px;
  line-height: 1;
  color: ${({ theme }) => theme.colors.INVOICE_TEXT};
  text-align: center;
`;

export const Label = styled.label`
  font-weight: 400;
  font-size: 12px;
  line-height: 1.7;
  color: ${({ theme }) => theme.colors.INVOICE_TEXT};
  bold {
    font-weight: 600;
  }
`;
export const cancelBtnStyle = { margin: '0 10px 0 0' };
export const confirmBtnStyle = { margin: '0 0 0 10px' };
