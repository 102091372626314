import actionTypes from 'constants/actionTypes';
import activeTimezones from 'constants/timezones';
import { environments } from '../../common/enums/app';

const defaultState = {
  isDevEnvironment: false,
  environment: environments.PRODUCT.TYPE,
  isTestTasksVisible: false,
  isDarkMode: true,
  adminInfo: {},
  filteredAdminList: [],
  filteredGeoLocationList: [],
  activeTimezone: activeTimezones.EST.value
};

function reducer(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.SET_ENVIRONMENT: {
      return {
        ...defaultState,
        environment: action.payload,
        isDevEnvironment: action.payload !== 'PRODUCT'
      };
    }
    case actionTypes.SET_DEV_ENVIRONMENT: {
      return {
        ...defaultState,
        isTestTasksVisible: state.isTestTasksVisible,
        isDevEnvironment: action.payload
      };
    }
    case actionTypes.SET_DARK_MODE: {
      return {
        ...state,
        isDarkMode: action.payload
      };
    }
    case actionTypes.SET_TEST_TASKS_VISIBILITY: {
      return {
        ...state,
        isTestTasksVisible: action.payload
      };
    }

    case actionTypes.GET_ADMIN_INFO_SUCCESS: {
      return {
        ...state,
        adminInfo: action.payload
      };
    }

    case actionTypes.SET_FILTERED_ADMIN_LIST: {
      return {
        ...state,
        filteredAdminList: action.payload || []
      };
    }

    case actionTypes.SET_FILTERED_GEOLOCATION_LIST: {
      return {
        ...state,
        filteredGeoLocationList: action.payload || []
      };
    }

    case actionTypes.SET_ACTIVE_TIMEZONE: {
      return {
        ...state,
        activeTimezone: action.payload
      };
    }

    default:
      return state;
  }
}

// selectors
export const isDevEnvironment = (state) => state.app.isDevEnvironment;
export const environment = (state) => state.app.environment;
export const isTestTasksVisible = (state) => state.app.isTestTasksVisible;
export const isDarkMode = (state) => state.app.isDarkMode;
export const selectAdminInfo = (state) => state.app.adminInfo;
export const selectAdminPermissions = (state) => state.app.adminInfo?.permission || [];
export const selectFilteredAdminList = (state) => state.app.filteredAdminList;
export const selectFilteredGeoLocationList = (state) => state.app.filteredGeoLocationList;
export const selectActiveTimezone = (state) => state.app.activeTimezone;

export const selectGlobalFilters = (state) => {
  const adminList = state.app.filteredAdminList;
  const geoList = state.app.filteredGeoLocationList;
  return {
    ...(state.app.isTestTasksVisible ? {} : { is_test: 0 }),
    operational_area_id: geoList?.length ? geoList.join(',') : '',
    owner_id: adminList?.length ? adminList.map(({ id }) => id).join(',') : ''
  };
};

export default reducer;
