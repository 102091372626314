import actionTypes from 'constants/actionTypes';

export const getBadges = (payload) => ({
  type: actionTypes.GET_BADGES_FETCH,
  payload
});

export const setEnvironment = (payload) => ({
  type: actionTypes.SET_ENVIRONMENT,
  payload
});

export const setDevEnvironment = (payload) => ({
  type: actionTypes.SET_DEV_ENVIRONMENT,
  payload
});

export const setDarkMode = (payload) => ({
  type: actionTypes.SET_DARK_MODE,
  payload
});

export const setTestTasksVisibility = (payload) => ({
  type: actionTypes.SET_TEST_TASKS_VISIBILITY,
  payload
});

export const getAdminInfo = (payload) => ({
  type: actionTypes.GET_ADMIN_INFO_FETCH,
  payload
});

export const setFilteredAdminList = (payload) => ({
  type: actionTypes.SET_FILTERED_ADMIN_LIST,
  payload
});

export const setFilteredGeoLocationList = (payload) => ({
  type: actionTypes.SET_FILTERED_GEOLOCATION_LIST,
  payload
});

export const setActiveTimezone = (payload) => ({
  type: actionTypes.SET_ACTIVE_TIMEZONE,
  payload
});
