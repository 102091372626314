import React from 'react';
import { useDispatch, useSelector } from 'hooks';
import { updateJobField, closeModal } from 'store/actions';
import { selectModalData } from 'store/reducers/modal';
import { selectUpdateJobFieldLoading } from 'store/reducers/service';
import { ModalConfirmation } from 'components';
import { systemStatuses } from 'common/enums/job';
import modalTypes from 'constants/modalTypes';

const CloseSilentlyFinal = () => {
  const dispatch = useDispatch();

  const modalData = useSelector(selectModalData);
  const loading = useSelector(selectUpdateJobFieldLoading);

  const { task_id } = modalData;

  const closeTask = (task_id) => {
    dispatch(
      updateJobField({
        job_id: task_id,
        body: {
          status: systemStatuses.FINAL_PAID,
          resultModalType: modalTypes.CLOSE_TASK_SILENTLY_RESULT
        }
      })
    );
  };

  return (
    <ModalConfirmation
      maxWidth={null}
      modalType={modalTypes.CLOSE_TASK_SILENTLY_FINAL}
      smallPaddings={false}
      title="Are you sure"
      hideModal={() => dispatch(closeModal(modalTypes.CLOSE_TASK_SILENTLY_FINAL))}
      primaryButton={{
        title: 'Cancel',
        onClick: () => dispatch(closeModal(modalTypes.CLOSE_TASK_SILENTLY_FINAL)),
        disabled: loading
      }}
      secondaryButton={{
        title: 'OK',
        onClick: () => {
          closeTask(task_id);
        },
        disabled: loading,
        loading
      }}
    />
  );
};

export default CloseSilentlyFinal;
