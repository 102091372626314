import React from 'react';
import { useDispatch, useSelector, useNavigate } from 'hooks';
import { deleteCampaign, closeModal, openModal } from 'store/actions';
import { selectCampaignById, selectDeleteCampaignLoading } from 'store/reducers/campaigns';
import modalTypes from 'constants/modalTypes';
import routes from 'constants/routes';

import { DropdownMenu } from 'components';
import { DataRecordAdditionalActions } from './styled';

export const MoreActions = ({ campaignId }) => {
  const campaign = useSelector((state) => selectCampaignById(state, campaignId));
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleNavigateToList = () => {
    navigate(-1);
  };

  const handleDeleteCampaign = () => {
    dispatch(
      deleteCampaign({
        campaignId,
        templateTitle: campaign.campaign_push?.template_title,
        onCustomClose: handleNavigateToList
      })
    );
  };

  const handleOpenSentTestEventModal = () => {
    if (!campaign.campaign_execute?.id) {
      return;
    }
    dispatch(
      openModal(modalTypes.TEST_EVENT, {
        executionId: campaign.campaign_execute?.id,
        test_recipient_ids: campaign.campaign_execute?.recipients_list
      })
    );
  };

  const handleOpenDeleteCampaignModal = () => {
    dispatch(
      openModal(modalTypes.CONFIRMATION, {
        title: 'Remove the campaign?',
        text: `The HO won’t see the campaign anymore.`,
        hasPrimaryButton: true,
        primaryButtonTitle: 'Cancel',
        secondaryButtonLoadingSelector: selectDeleteCampaignLoading,
        onSecondaryButtonPress: handleDeleteCampaign,
        onPrimaryButtonPress: () => dispatch(closeModal(modalTypes.CONFIRMATION)),
        hasSecondaryButton: true,
        secondaryButtonTitle: 'Confirm'
      })
    );
  };

  const handleOpenEditCampaign = () => {
    navigate(`${routes.CAMPAIGNS}/${campaignId}/edit`);
  };

  const options = [
    ...(campaign.id && !campaign.campaign_execute?.is_executed
      ? [
          {
            id: 1,
            name: 'Edit',
            onClick: handleOpenEditCampaign
          },
          {
            id: 2,
            name: 'Send Test Event',
            onClick: handleOpenSentTestEventModal
          },
          {
            id: 3,
            name: 'Delete',
            onClick: handleOpenDeleteCampaignModal
          }
        ]
      : [])
  ];

  if (!options.length) {
    return null;
  }

  return (
    <DataRecordAdditionalActions alignItems="flex-end">
      <DropdownMenu dropdownWidth={200} options={options} placeholder="Actions" margin="0" />
    </DataRecordAdditionalActions>
  );
};
