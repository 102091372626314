import { columnNames } from 'common/enums/sortings';

export const campaignsTable = {
  ID: {
    TITLE: 'ID',
    FILTER_NAME: columnNames.ID
  },
  TITLE: {
    TITLE: 'Name',
    FILTER_NAME: columnNames.TITLE
  },
  DATETIME_CREATED: {
    TITLE: 'Date Created',
    FILTER_NAME: columnNames.DATETIME_CREATED
  },
  DATETIME_EXECUTE: {
    TITLE: 'Execution Date',
    FILTER_NAME: columnNames.DATETIME_EXECUTE
  },
  COHORT: {
    TITLE: 'Cohort',
    FILTER_NAME: columnNames.COHORT
  },
  ARTICLE_AREA_TO_DISPLAY: {
    TITLE: 'Area to Display',
    FILTER_NAME: columnNames.ARTICLE_AREA_TO_DISPLAY
  }
  // TOTAL_SENT: {
  //   TITLE: 'Total Sent',
  //   FILTER_NAME: columnNames.TOTAL_SENT
  // },
  // TOTAL_VIEWS: {
  //   TITLE: 'Total Views',
  //   FILTER_NAME: columnNames.TOTAL_VIEWS
  // },
  // JOBS_CREATED: { TITLE: 'Jobs Created', FILTER_NAME: columnNames.JOBS_CREATED }
};
