import { call, takeLatest, put } from 'redux-saga/effects';
import api_constants from 'constants/api';
import actionTypes from 'constants/actionTypes';
import Api from 'services/ApiService';

function* updateServiceNote(action) {
  const {
    payload: { service_request_id, id, description, reminder_counter }
  } = action;
  try {
    const response = yield call(Api.amplifyPut, `${api_constants.SERVICE_REQUEST}/note/${id}`, {
      description,
      service_request_id,
      reminder_counter,
      media: []
    });
    if (response) {
      yield put({
        type: actionTypes.UPDATE_SERVICE_NOTE_SUCCESS,
        payload: {
          note: response,
          id: response.id
        }
      });
    } else {
      yield put({
        type: actionTypes.UPDATE_SERVICE_NOTE_ERROR
      });
    }
  } catch (err) {
    console.log(err);
  }
}

export default function* watchUpdateServiceNote() {
  yield takeLatest(actionTypes.UPDATE_SERVICE_NOTE_FETCH, updateServiceNote);
}
