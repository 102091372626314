import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { Device } from '@twilio/voice-sdk';
import moment from 'moment-timezone';

import modalTypes from 'constants/modalTypes';
import { Modal, LoadingDots, Avatar } from 'components';
import styles from 'constants/styles';
import { getFullName } from 'common/helpers/user';

import { selectModalData } from 'store/reducers/modal';
import { selectUserIsLoading, selectUserInstance } from 'store/reducers/user';
import { closeModal, getUserInstance, getTwilioVoiceAccessToken } from 'store/actions';

import VoiceControls from './VoiceControls';
import DurationTimer from './DurationTimer';
import {
  CallRow,
  UserAvatarWrapper,
  SpinnerContainer,
  UserFullNameRow,
  NameDurationColumn,
  UserFullName,
  CallDuration,
  Controls
} from './styled';

const TwilioVoice = ({
  modalType = modalTypes.TWILIO_VOICE,
  closeModal,
  modalData = {},
  getUserInstance,
  userIsLoading = true,
  userInstance = {},
  getTwilioVoiceAccessToken
}) => {
  const { userID = '', jobID = '' } = modalData;
  const { id, given_name, family_name, profile_image_url } = userInstance;

  const [deviceStatus, setDeviceStatus] = useState('');
  const [isReady, setIsReady] = useState(false);
  const [device, setDevice] = useState(null);
  const [call, setCall] = useState({});
  const [isInitiated, setIsInitiated] = useState(false);
  const [isOngoing, setIsOngoing] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [startDateTime, setStartDateTime] = useState(moment());

  const updateDeviceToken = useCallback(
    ({ accessToken }) => {
      device.updateToken(accessToken);
    },
    [device]
  );

  const initDeviceToken = useCallback(({ accessToken }) => {
    const initDevice = new Device(accessToken, {
      codecPreferences: ['opus', 'pcmu'],
      fakeLocalDTMF: true,
      enableRingingState: true
    });
    // device event listeners
    initDevice.on('registered', (device) => {
      setIsReady(true);
    });
    initDevice.on('error', (error) => {
      setDeviceStatus('ERROR: ' + error.message);
    });
    initDevice.on('tokenWillExpire', () => {
      getTwilioVoiceAccessToken({ setDeviceToken: updateDeviceToken });
    });
    initDevice.audio.incoming(false);
    initDevice.audio.outgoing(false);
    initDevice.audio.disconnect(false);
    setDevice(initDevice);
    setIsReady(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // fetchUserInfo
  useEffect(() => {
    getUserInstance({ userID });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Initializa Twilio Device
  useEffect(() => {
    getTwilioVoiceAccessToken({ setDeviceToken: initDeviceToken });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onMuteChange = (e) => {
    e.preventDefault();
    call.mute(!call.isMuted());
  };

  const onCallStart = async (e) => {
    e.preventDefault();
    setIsInitiated(true);
    const call = await device.connect({
      params: { user_id: userID, job_id: jobID }
    });
    call.on('accept', (connection) => {
      setDeviceStatus(connection.status());
      setStartDateTime(moment());
      setIsOngoing(true);
      setIsMuted(false);
    });
    call.on('disconnect', (connection) => {
      setDeviceStatus(connection.status());
      setIsOngoing(false);
    });
    call.on('mute', (isMuted, call) => {
      setIsMuted(isMuted);
    });
    setCall(call);
  };

  const onCallEnd = (e) => {
    e.preventDefault();
    setIsInitiated(false);
    device.disconnectAll();
  };

  const onModalClose = async () => {
    device && device.disconnectAll();
    closeModal();
  };

  const userFullName = getFullName({ given_name, family_name, id });
  return (
    <Modal
      modalType={modalType}
      hideModal={onModalClose}
      padding="32px 40px"
      backgroundColor={styles.colors.WHITE}
      middlePaddings
      maxWidth={0}
      minWidth={479}
      maxHeight={0}>
      {userIsLoading ? (
        <SpinnerContainer>
          <LoadingDots left={0} top={1} />
        </SpinnerContainer>
      ) : (
        <CallRow>
          <UserFullNameRow>
            <UserAvatarWrapper>
              <Avatar url={profile_image_url} alt="User Avatar" size={64} avatarSize={32} />
            </UserAvatarWrapper>
            <NameDurationColumn>
              <UserFullName>{userFullName}</UserFullName>
              <CallDuration>
                <DurationTimer isOngoing={isOngoing} startDateTime={startDateTime} />
              </CallDuration>
            </NameDurationColumn>
          </UserFullNameRow>
          <Controls>
            <VoiceControls
              isReady={isReady}
              isInitiated={isInitiated}
              isOngoing={isOngoing}
              isMuted={isMuted}
              onMuteChange={onMuteChange}
              onCallStart={onCallStart}
              onCallEnd={onCallEnd}
            />
          </Controls>
        </CallRow>
      )}
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  modalData: selectModalData(state),
  userIsLoading: selectUserIsLoading(state),
  userInstance: selectUserInstance(state)
});

const mapDispatchToProps = {
  getUserInstance,
  getTwilioVoiceAccessToken,
  closeModal
};

export default connect(mapStateToProps, mapDispatchToProps)(TwilioVoice);
