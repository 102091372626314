import actionTypes from 'constants/actionTypes';
import { conversationTypes } from 'common/enums/chat';

const defaultState = {
  clearAllLoading: false,
  persistentList: [],
  persistentListLoading: false,
  persistentListTotalCount: 0,
  alerts: {}
};

function reducer(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.GET_NOTIFICATION_LIST_FETCH: {
      const { page } = action.payload;
      return {
        ...state,
        persistentListLoading: true,
        ...(page === 1 ? { persistentListTotalCount: 0 } : {})
      };
    }
    case actionTypes.GET_NOTIFICATION_LIST_SUCCESS: {
      const { page, persistentList, totalCount } = action.payload;
      const updatedPersistentList = page === 1 ? persistentList : [...state.persistentList, ...persistentList];
      return {
        ...state,
        persistentList: updatedPersistentList,
        persistentListTotalCount: totalCount,
        persistentListLoading: false
      };
    }
    case actionTypes.GET_NOTIFICATION_LIST_ERROR: {
      return {
        ...state,
        persistentListLoading: false
      };
    }
    case actionTypes.SHOW_NOTIFICATION_ALERT: {
      return {
        ...state,
        alerts: {
          ...state.alerts,
          [action.payload.notificationId]: action.payload
        }
      };
    }
    case actionTypes.REMOVE_NOTIFICATION_ALERT: {
      const { [action.payload]: _, ...alerts } = state.alerts;
      return {
        ...state,
        alerts
      };
    }
    case actionTypes.ADD_NOTIFICATION_TO_PERSISTENT_LIST: {
      return {
        ...state,
        persistentList: state.persistentList?.length ? [action.payload, ...state.persistentList] : [action.payload]
      };
    }
    case actionTypes.DELETE_PERSISTENT_NOTIFICATION_FETCH: {
      const {
        payload: { notification_id = null, request_id = null, user_id: user = null }
      } = action;
      return {
        ...state,
        clearAllLoading: !(notification_id || user || request_id)
      };
    }

    case actionTypes.DELETE_PERSISTENT_NOTIFICATION_SUCCESS: {
      const {
        payload: { notification_id = null, request_id = null, user_id: user = null, isClearAll }
      } = action;

      if (isClearAll) {
        return {
          ...state,
          clearAllLoading: false,
          persistentList: []
        };
      }

      const persistentList = notification_id
        ? state.persistentList?.filter(({ id }) => Number(id) !== Number(notification_id))
        : user || request_id
        ? state.persistentList?.filter(
            ({ service_request_id = null, user_id = null, conversation_type = null }) =>
              user_id !== user && conversation_type === conversationTypes.SUPPORT && request_id !== service_request_id
          )
        : [];

      return {
        ...state,
        persistentList
      };
    }

    case actionTypes.DELETE_PERSISTENT_NOTIFICATION_ERROR: {
      const { isClearAll } = action.payload;

      if (isClearAll) {
        return {
          ...state,
          clearAllLoading: false
        };
      }

      return state;
    }

    case actionTypes.SET_ENVIRONMENT:
    case actionTypes.SET_DEV_ENVIRONMENT: {
      return defaultState;
    }

    default:
      return state;
  }
}

export const selectClearAllPersistentNotificationsLoading = (state) => state.notification.clearAllLoading;
export const selectNotificationPersistentList = (state) => state.notification.persistentList;
export const selectHasNotificationsBadge = (state) => Boolean(state.notification.persistentList?.length);
export const selectNotificationPersistentListLoading = (state) => state.notification.persistentListLoading;
export const selectNotificationAlerts = (state) => state.notification.alerts;
export const selectPersistentListTotalCount = (state) => state.notification.persistentListTotalCount;

export default reducer;
