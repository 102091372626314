import { call, takeLatest, put } from 'redux-saga/effects';
import api_constants from 'constants/api';
import actionTypes from 'constants/actionTypes';
import modalTypes from 'constants/modalTypes';
import errors from 'constants/errors';

import Api from 'services/ApiService';
import { openModal } from 'store/actions';
import { refreshTaskInfo } from 'store/actions/service';
import { getMediaIds } from 'common/helpers/media';

function* createQuotation({
  payload: {
    data,
    data: { service_request_id: task_id },
    files
  }
}) {
  try {
    const mediaList = files.length ? yield call(getMediaIds, { files, hasMediaType: true }) : [];

    const response = yield call(Api.amplifyPost, api_constants.QUOTATION, {
      ...data,
      media: mediaList
    });

    if (!response?.id) {
      throw new Error(response?.error_message || response?.err?.message || response?.err?.name || errors.DEFAULT);
    }

    yield put({
      type: actionTypes.CREATE_QUOTATION_SUCCESS,
      payload: {
        ...response,
        is_quotation: true
      }
    });

    yield put(
      openModal(modalTypes.RESULT, {
        title: `Quotation Sent`,
        text: 'The homeowner will be notified.'
      })
    );
  } catch (err) {
    yield put({
      type: actionTypes.CREATE_QUOTATION_ERROR
    });
    yield put(
      openModal(modalTypes.ERROR, {
        errorMessage: err?.message || err?.name || errors.DEFAULT
      })
    );
  } finally {
    yield put(refreshTaskInfo({ task_id, silent: true, isPaymentInfo: true }));
  }
}

export default function* watchCreateQuotation() {
  yield takeLatest(actionTypes.CREATE_QUOTATION_FETCH, createQuotation);
}
