import React from 'react';

import { formatters } from 'utils';
import { fieldTypes } from 'common/enums/form';
import messages from 'constants/messages';

import { HookForm, Reward, Tooltip } from 'components';

import { inputWrapperStyle } from '../../commonStyled';
import {
  AttachedRewardRow,
  FieldWrapper,
  FieldName,
  Heading,
  RewardCurency,
  Row,
  Wrapper,
  discountInputWrapperStyle,
  ownInputWrapperStyle
} from './styled';

export const Discounts = ({ clearErrors, getValues, control, error, reward, onOpenDetachRewardConfirmationModal }) => {
  const requiredPurposeValidate = (value) => {
    if (!value) {
      clearErrors('amount_customer_reward');
    }
    return Boolean(getValues('amount_customer_reward') && !value) ? messages.formErrors.REQUIRED : true;
  };

  const requiredAmountValidate = (value) => {
    if (!value) {
      clearErrors('custom_reward_purpose');
    }
    return Boolean(getValues('custom_reward_purpose') && !value) ? messages.formErrors.REQUIRED : true;
  };

  return (
    <Wrapper>
      <Heading>Discounts</Heading>
      {Boolean(reward?.id) ? (
        <FieldWrapper>
          <FieldName>Attached to Job</FieldName>
          <AttachedRewardRow>
            <Tooltip
              title={reward?.description}
              bottom={45}
              maxWidth={300}
              wrappedComponent={
                <Reward
                  promocodeId={reward?.promo_code_id}
                  width={104}
                  isCloseButton={Boolean(reward?.promo_code_id)}
                  onCloseClick={onOpenDetachRewardConfirmationModal}
                />
              }
            />
            <RewardCurency>{formatters.dollarCurrencyDB(reward?.amount || 0)}</RewardCurency>
          </AttachedRewardRow>
        </FieldWrapper>
      ) : null}
      <FieldWrapper>
        <FieldName>Dobby Discount (optional)</FieldName>
        <Row>
          <HookForm.Input
            key="custom_reward_purpose"
            name="custom_reward_purpose"
            control={control}
            placeholder="Short explanation"
            rules={{
              validate: {
                requiredIfAmount: requiredPurposeValidate
              },
              maxLength: 512
            }}
            wrapperStyle={discountInputWrapperStyle}
          />
          <HookForm.Input
            key="amount_customer_reward"
            name="amount_customer_reward"
            control={control}
            rules={{
              validate: {
                requiredIfPurpose: requiredAmountValidate
              }
            }}
            fieldType={fieldTypes.DISCOUNT_CURRENCY}
            wrapperStyle={{
              ...inputWrapperStyle,
              ...ownInputWrapperStyle
            }}
          />
        </Row>
      </FieldWrapper>
    </Wrapper>
  );
};
