import { formatterTypes } from 'common/enums/form';
import { regexExpressions } from 'common/enums/regex';

const removeCurrency = (value) =>
  value.replace(/,/g, '').replace(/\$/g, '').replace(/%/g, '').replace(/-/g, '').replace(/ /g, '') || '';

const getZeroCents = ({ slicedFloatPart, valuePartsLength }) => {
  if (valuePartsLength < 2) {
    return '';
  }
  // restore entered zeros after parseFloat erasing them
  if (['.', '.0', '.00', '.00'].includes(`.${slicedFloatPart}`)) {
    return `.${slicedFloatPart}`;
  }
  // not to erase dot if user entered not a number after dot
  if (isNaN(parseInt(slicedFloatPart))) {
    return '.';
  }
  return '';
};

const normalizeFloatValue = (value) => {
  const valueParts = removeCurrency(value).split('.');
  const slicedFloatPart = valueParts[1]?.length > 2 ? valueParts[1].slice(0, 2) : valueParts[1];
  const parsedAmount = parseFloat(valueParts[0] + '.' + slicedFloatPart);

  if (!parsedAmount || isNaN(parsedAmount)) {
    return '';
  }

  const zeroCentsPart = getZeroCents({
    slicedFloatPart,
    valuePartsLength: valueParts?.length
  });

  return parsedAmount.toString() + zeroCentsPart;
};

const withZeroFloatValue = (value) => {
  const valueParts = removeCurrency(value).split('.');
  const secondPart = (valueParts[1] || '').slice(0, 2);
  const firstPart = parseInt(valueParts[0]);

  if (isNaN(firstPart) || (secondPart !== '' && isNaN(secondPart))) {
    return '';
  }

  const parsedAmount = parseFloat(valueParts[0] + '.' + secondPart);
  const zeroCentsPart = getZeroCents({
    slicedFloatPart: secondPart,
    valuePartsLength: valueParts?.length
  });

  return parsedAmount.toString() + zeroCentsPart;
};

export const normalizers = {
  currency: (value) => value.replace(/,/g, ''),
  date: (value) => value.slice(0, 10),
  default: (value) => value,
  discountCurrency: normalizeFloatValue,
  dollarCurrencyDB: (value) => {
    const amountString = removeCurrency(value);
    return (parseFloat(amountString) * 100).toString();
  },
  dollarCurrencyInput: normalizeFloatValue,
  dollarCurrencyInputZero: withZeroFloatValue,
  currencyInput: normalizeFloatValue,
  dollarNumber: (value) => value.replace(/[^0-9]/g, ''),
  number: (value) => value.replace(/[^0-9]/g, ''),
  percent: (value) => {
    const normalizedValue = normalizeFloatValue(value);
    const floatValue = parseFloat(normalizedValue);
    if (floatValue > 100) {
      return '100';
    }
    if (floatValue < 0 || isNaN(floatValue)) {
      return '0';
    }
    return normalizedValue;
  },
  [formatterTypes.INVOICE_TEXT]: (value) => value.replace(regexExpressions.INVOICE_TEXT, ''),
  [formatterTypes.COLOR]: (value) => {
    const trimmedValue = value?.trim();
    return trimmedValue.startsWith('#') || !trimmedValue ? trimmedValue : `#${trimmedValue}`;
  },
  [formatterTypes.TRIM_TEXT]: (value) => value?.trim() || ''
};
