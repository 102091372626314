import styled from 'styled-components';
import styles from 'constants/styles';

export const ModalTitle = styled.h3`
  align-self: flex-start;
  color: ${({ theme }) => theme.colors.INVOICE_TEXT};
  font-weight: bold;
  font-size: 22px;
  font-style: normal;
  line-height: 31px;
  margin: 0 0 17px;
  white-space: pre-wrap;
  text-align: center;
`;

export const ButtonsWrapper = styled.div`
  align-self: stretch;
  display: flex;
  flex: 1;
  ${({ verticalAlign }) => verticalAlign && 'flex-direction: column; align-items: center;'}
  justify-content: ${({ verticalAlign }) => (verticalAlign ? 'center' : 'space-between')};
  margin-top: ${({ verticalAlign }) => verticalAlign && '28px'};
  min-width: 325px;
`;

export const ContentWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const RelativeWrapper = styled.div`
  position: relative;
  display: flex;
`;

export const Line = styled.hr`
  background-color: ${styles.colors.LIGHT_GRAY};
  height: 1px;
  margin: 0;
  border-width: 0px;
  width: 100%;
`;
