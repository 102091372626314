import { call, takeLatest, put } from 'redux-saga/effects';

import { openModal, closeModal } from 'store/actions/modal';
import { refreshTaskInfo } from 'store/actions/service';
import { getBadges } from 'store/actions';

import api_constants from 'constants/api';
import actionTypes from 'constants/actionTypes';
import Api from 'services/ApiService';
import modalTypes from 'constants/modalTypes';
import errors from 'constants/errors';

function* updateJobField({
  payload: {
    job_id,
    tag,
    body: { resultModalType, resultModal, closedModalType, errorModalEnabled, ...data },
    callback
  }
}) {
  try {
    const response = yield call(Api.amplifyPut, `${api_constants.SERVICE}/${job_id}`, data);
    if (!response || response?.err) {
      throw new Error(errors.DEFAULT);
    }

    yield put({
      type: actionTypes.UPDATE_JOB_FIELD_SUCCESS,
      payload: {
        data: response,
        tag
      }
    });

    if (closedModalType) {
      yield put(closeModal(closedModalType));
    }

    if (resultModalType) {
      yield put(openModal(resultModalType, response || {}));
    } else if (resultModal) {
      const { modalType, ...modalData } = resultModal;
      yield put(openModal(modalType, modalData));
    }

    // Assign Provider only => opening result modal
    if (callback && typeof callback === 'function') {
      callback({
        job_id: response.id,
        service_provider: response.service_provider
      });
    }
  } catch (err) {
    yield put({ type: actionTypes.UPDATE_JOB_FIELD_ERROR, tag });

    if (callback && typeof callback === 'function') {
      callback({
        hasError: true
      });
    }

    if (resultModalType) {
      yield put(
        openModal(resultModalType, {
          errorMessage: errors.DEFAULT
        })
      );
      return;
    }

    if (resultModal || errorModalEnabled) {
      yield put(
        openModal(modalTypes.ERROR, {
          errorMessage: errors.DEFAULT
        })
      );
    }
  } finally {
    // on change a job field we need to:
    // - always reload location
    // - reload progress if data.status || cancel visit)
    // - no need to update instance, because it's updating on put success
    // - payments if cancel job, archive job, or change status in dropdown
    yield put(
      refreshTaskInfo({
        task_id: job_id,
        silent: true,
        isAllInfo: true
      })
    );
    // - reload counters if tag changed
    if (tag) {
      yield put(getBadges());
    }
  }
}

export default function* watchUpdateJobField() {
  yield takeLatest(actionTypes.UPDATE_JOB_FIELD_FETCH, updateJobField);
}
