import styled from 'styled-components';
import styles from 'constants/styles';

export const BoldText = styled.span`
  font-weight: 700;
  color: ${({ theme }) => theme.colors.INVOICE_TEXT};
`;

export const ButtonsWrapper = styled.div`
  margin-bottom: 24px;
`;

export const FlexRow = styled.div`
  display: flex;
  flex: 1;
  padding: 10px 0 25px 0;
  &:first-child {
    padding: 18px 0 5px;
  }
  &:last-child {
    padding: 28px 0 20px;
    justify-content: space-between;
  }
`;

export const Label = styled.label`
  font-weight: 400;
  font-size: 12px;
  line-height: 1.7;
  color: ${({ theme }) => theme.colors.INVOICE_TEXT};
`;

export const ModalText = styled.p`
  font-weight: 700;
  font-size: 12px;
  line-height: 1.17;
  text-align: center;
  color: ${({ theme }) => theme.colors.INVOICE_TEXT};
  white-space: pre-wrap;
  text-align: center;
  padding: 10px 0 30px 0;
  margin-top: 16px;
  margin-bottom: 0;
`;

export const ModalTitle = styled.h3`
  color: ${({ theme }) => theme.colors.INVOICE_TEXT};
  font-weight: 700;
  font-size: 24px;
  line-height: 1;
  text-align: center;
  white-space: pre-wrap;
  word-break: break-word;
  margin: 0;
`;
