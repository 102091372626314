import { call, takeLatest, put } from 'redux-saga/effects';
import api_constants from 'constants/api';
import actionTypes from 'constants/actionTypes';
import Api from 'services/ApiService';

import { smsMessageTransformer } from 'utils';

function* createCommunicationSms(action) {
  const { id, date_created, ...data } = action.payload;

  try {
    const response = yield call(Api.amplifyPost, `${api_constants.COMMUNICATION_SMS}`, data);
    if (!response) {
      throw new Error();
    }

    yield put({
      type: actionTypes.CREATE_COMMUNICATION_SMS_SUCCESS,
      payload: {
        tmpId: id,
        user_id: data.user_id,
        ...smsMessageTransformer(response)
      }
    });
  } catch (err) {
    yield put({
      type: actionTypes.CREATE_COMMUNICATION_SMS_ERROR,
      payload: {
        user_id: data.user_id,
        tmpId: id
      }
    });
    console.log(err);
  }
}

export default function* watchCreateCommunicationSms() {
  yield takeLatest(actionTypes.CREATE_COMMUNICATION_SMS_FETCH, createCommunicationSms);
}
