import { all, fork } from 'redux-saga/effects';
import { watchGetAdminList, watchGetAdminInfo } from './admin';
import {
  watchGetAgentList,
  watchUpdateAgent,
  watchGetAgentInstance,
  watchGetAgentLocationList,
  watchGetAgentCharges
} from './agent';
import { watchGetAnalyticMetric, watchGetAnalyticSnapshot } from './analytics';
import { watchGetBadges } from './app';
import {
  watchCreateCampaign,
  watchGetCampaignsList,
  watchGetCampaignById,
  watchDeleteCampaign,
  watchEditCampaign,
  watchSendTestCampaignEvent
} from './campaigns';
import {
  watchCreateCommunicationSms,
  watchGetConversationList,
  watchGetCommunicationSmsList,
  watchUpdateConversation
} from './communication';
import {
  watchGetCustomerInstance,
  watchGetCustomerLocation,
  watchGetCustomerList,
  watchGetCustomerReviews,
  watchUpdateCustomerLocation,
  watchUpdateCustomerInstance,
  watchUploadCustomerInspectionReport,
  watchGetCustomerInspectionReport,
  watchGetRewards,
  watchGetCustomerReferrals,
  watchManageCustomerReward,
  watchGetCustomerDobbyCash,
  watchGetCustomerDobbyCashTransactions
} from './customer';

import {
  watchCreatePayment,
  watchCreatePayout,
  watchDeletePayment,
  watchGetPaymentList,
  watchGetTaskPaymentList,
  watchUpdatePayment,
  watchClosePayment
} from './payment';
import {
  watchGetPromoCodeList,
  watchCreatePromoCode,
  watchUpdatePromoCode,
  watchCreateReward,
  watchDeleteReward,
  watchExpirePromoCode,
  watchGetReferralList
} from './promoCode';
import {
  watchGetProviderList,
  watchGetProviderInstance,
  watchUpdateProviderInstance,
  watchGetProviderReviews,
  watchDeleteProviderReview,
  watchGetProviderAreaList,
  watchGetSkills,
  watchUpdateSkill,
  watchCreateSkill
} from './provider';
import { watchGetReviewList, watchUpdateReview } from './review';
import {
  watchCreateQuotation,
  watchDeleteQuotation,
  watchGetQuotationList,
  watchGetTaskQuotationList,
  watchUpdateQuotation
} from './quotation';
import {
  watchGetAvatar,
  watchGetAvatarOriginal,
  watchGetMediaUrl,
  watchGetMediaUrlForList,
  watchGetMediaUrls,
  watchGetMediaUrlsForList,
  watchGetCSVFile,
  watchGetLinkPreviews
} from './runtimeCache';
import {
  watchCreateRequest,
  watchChatExtendOrClose,
  watchUpdateRequest,
  watchGetServiceList,
  watchGetOwnerReport,
  watchGetServiceInstance,
  watchSignServiceImageUrls,
  watchUpdateJobField,
  watchGetServiceProgressList,
  watchGetServiceLocation,
  watchGetServiceProAssignBundle,
  watchRefreshTaskInfo,
  watchBundleServices,
  watchDuplicateService,
  watchScheduleVisit,
  watchUnbundleTask,
  watchSnoozeTask
} from './service';
import {
  watchGetSupportMessageList,
  watchSendSupportMessage,
  watchGetSupportMessageById,
  watchDeleteSupportMessage,
  watchUpdateSupportMessage
} from './supportChat';
import {
  watchCreateServiceNote,
  watchGetServiceNoteList,
  watchUpdateServiceNote,
  watchDeleteServiceNote
} from './taskNote';
import { watchGetNotificationList, watchDeletePersistentNotification } from './notification';
import {
  watchGetTaskMessageList,
  watchSendTaskMessage,
  watchGetTaskMessageById,
  watchGetUnreadMessagesByCategories,
  watchUpdateTaskMessage,
  watchDeleteTaskMessage
} from './taskChat';
import { watchGetTwilioAccessToken } from './twilio';
import {
  watchGetUserInstance,
  watchDeleteCognitoUser,
  watchGetUserServiceList,
  watchUpdateUserInstance,
  watchGetUserNoteList,
  watchGetUserQuestions,
  watchCreateUserNote,
  watchUpdateUserNote,
  watchDeleteUserNote
} from './user';

export default function* rootSaga() {
  yield all([
    fork(watchCreateCampaign),
    fork(watchDeleteCampaign),
    fork(watchEditCampaign),
    fork(watchGetCampaignById),
    fork(watchGetCampaignsList),
    fork(watchSendTestCampaignEvent),
    fork(watchGetCustomerDobbyCashTransactions),
    fork(watchClosePayment),
    fork(watchGetCustomerReferrals),
    fork(watchGetCustomerDobbyCash),
    fork(watchManageCustomerReward),
    fork(watchUpdateSkill),
    fork(watchCreateSkill),
    fork(watchGetSkills),
    fork(watchGetReviewList),
    fork(watchExpirePromoCode),
    fork(watchUpdateReview),
    fork(watchGetAdminList),
    fork(watchGetAdminInfo),
    fork(watchGetAnalyticMetric),
    fork(watchGetAnalyticSnapshot),
    fork(watchGetBadges),
    fork(watchCreateCommunicationSms),
    fork(watchGetConversationList),
    fork(watchGetCommunicationSmsList),
    fork(watchUpdateConversation),
    fork(watchGetCustomerList),
    fork(watchGetCustomerInstance),
    fork(watchGetProviderInstance),
    fork(watchGetAgentInstance),
    fork(watchGetProviderList),
    fork(watchGetProviderAreaList),
    fork(watchUpdateProviderInstance),
    fork(watchGetAgentList),
    fork(watchUpdateAgent),
    fork(watchGetAgentCharges),
    fork(watchGetAvatar),
    fork(watchGetAvatarOriginal),
    fork(watchGetMediaUrl),
    fork(watchGetMediaUrlForList),
    fork(watchGetMediaUrls),
    fork(watchGetMediaUrlsForList),
    fork(watchGetCSVFile),
    fork(watchGetLinkPreviews),
    fork(watchCreateRequest),
    fork(watchUpdateRequest),
    fork(watchGetServiceList),
    fork(watchGetServiceInstance),
    fork(watchSignServiceImageUrls),
    fork(watchCreatePayment),
    fork(watchCreatePayout),
    fork(watchDeletePayment),
    fork(watchGetPaymentList),
    fork(watchGetTaskPaymentList),
    fork(watchUpdatePayment),
    fork(watchCreateQuotation),
    fork(watchDeleteQuotation),
    fork(watchGetQuotationList),
    fork(watchGetTaskQuotationList),
    fork(watchUpdateQuotation),
    fork(watchGetServiceLocation),
    fork(watchGetCustomerLocation),
    fork(watchUpdateJobField),
    fork(watchGetServiceProgressList),
    fork(watchGetServiceNoteList),
    fork(watchGetUserQuestions),
    fork(watchCreateServiceNote),
    fork(watchGetTaskMessageList),
    fork(watchGetSupportMessageList),
    fork(watchSendTaskMessage),
    fork(watchSendSupportMessage),
    fork(watchDeleteTaskMessage),
    fork(watchUpdateTaskMessage),
    fork(watchUpdateCustomerLocation),
    fork(watchUpdateCustomerInstance),
    fork(watchUploadCustomerInspectionReport),
    fork(watchGetCustomerInspectionReport),
    fork(watchGetSupportMessageById),
    fork(watchDeleteSupportMessage),
    fork(watchUpdateSupportMessage),
    fork(watchGetTaskMessageById),
    fork(watchUpdateServiceNote),
    fork(watchDeleteUserNote),
    fork(watchDeleteServiceNote),
    fork(watchGetUserServiceList),
    fork(watchUpdateUserInstance),
    fork(watchBundleServices),
    fork(watchDuplicateService),
    fork(watchGetOwnerReport),
    fork(watchUnbundleTask),
    fork(watchSnoozeTask),
    fork(watchGetUnreadMessagesByCategories),
    fork(watchRefreshTaskInfo),
    fork(watchGetNotificationList),
    fork(watchDeletePersistentNotification),
    fork(watchGetTwilioAccessToken),
    fork(watchGetUserInstance),
    fork(watchDeleteCognitoUser),
    fork(watchGetProviderReviews),
    fork(watchGetCustomerReviews),
    fork(watchDeleteProviderReview),
    fork(watchGetUserNoteList),
    fork(watchCreateUserNote),
    fork(watchUpdateUserNote),
    fork(watchGetRewards),
    fork(watchGetPromoCodeList),
    fork(watchCreatePromoCode),
    fork(watchUpdatePromoCode),
    fork(watchCreateReward),
    fork(watchDeleteReward),
    fork(watchGetReferralList),
    fork(watchGetAgentLocationList),
    fork(watchScheduleVisit),
    fork(watchGetServiceProAssignBundle),
    fork(watchChatExtendOrClose)
  ]);
}
