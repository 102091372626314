import React, { useState } from 'react';

import { HookForm, Icon, IconButton } from 'components';
import styles from 'constants/styles';
import { CustomFieldsTooltip } from '../../../../../';
import { NoteFile } from './styled';

export const ChatMessageSetUp = ({ control }) => {
  const [attachedFile, setAttachedFile] = useState();

  const handleAttachedFiles = (e) => {
    e.stopPropagation();
    e.preventDefault();

    setAttachedFile(e.target.files[0]);
  };
  const handleRemoveAttachedFile = () => {
    setAttachedFile();
  };

  return (
    <>
      <div>
        {/* <HookForm.Textarea
                name="Description"
                register={register}
                control={control}
                label="Message to support chat*"
                rules={{ required: true }}
                placeholder="Enter message"
                rows={5}
            />  */}
        <HookForm.RichTextEditor
          control={control}
          hasContentPreview
          name="description"
          title="Message to support chat*"
          renderTooltip={() => <CustomFieldsTooltip />}
        />
      </div>
      <div style={{ margin: '-10px 0 23px 0' }}>
        {attachedFile ? (
          <NoteFile clickable={false}>
            <Icon name="file" size={17} />
            <div>{attachedFile?.name}</div>

            <IconButton
              iconName="close2"
              iconSize={8}
              size={16}
              onClick={handleRemoveAttachedFile}
              margin="0 0 0 5px"
            />
          </NoteFile>
        ) : (
          <label>
            <input type="file" accept="image/*" style={{ display: 'none' }} onChange={handleAttachedFiles} />
            <Icon name="attachment" size={18} color={styles.colors.GREY} />
            <span>Attach Media</span>
          </label>
        )}
      </div>
      <HookForm.Checkbox
        green
        smallLabelFont
        labelFontWeight={700}
        control={control}
        name="support_message.is_send_sms"
        label="Send SMS text message also"
      />
    </>
  );
};
