import { columnNames } from 'common/enums/sortings';

export const bundleTaskTable = {
  ID: {
    TITLE: 'ID',
    FILTER_NAME: columnNames.ID
  },
  HO_GIVEN_NAME: {
    TITLE: 'HO Name',
    FILTER_NAME: columnNames.HO_GIVEN_NAME
  },
  PRO_GIVEN_NAME: {
    TITLE: 'PRO Name',
    FILTER_NAME: columnNames.PRO_GIVEN_NAME
  },
  DATETIME_CREATED: {
    TITLE: 'Request Date',
    FILTER_NAME: columnNames.DATETIME_CREATED
  },
  JOB_CATEGORY_NAME: {
    TITLE: 'Request Cat',
    FILTER_NAME: columnNames.JOB_CATEGORY_NAME
  },
  JOB_TYPE_NAME: {
    TITLE: 'Job Type',
    FILTER_NAME: columnNames.JOB_TYPE_NAME
  },
  CHECKBOX: {
    TITLE: '',
    FILTER_NAME: columnNames.CHECKBOX
  }
};
