import { all, call, takeLatest, put } from 'redux-saga/effects';
import api_constants from 'constants/api';
import actionTypes from 'constants/actionTypes';
import errors from 'constants/errors';
import Api from 'services/ApiService';
import { closeModal, openModal } from 'store/actions/modal';
import modalTypes from 'constants/modalTypes';
import { oneClickActions } from 'common/enums/user';

function* updateCustomerInstance({ payload }) {
  const { data, userId, locationId, skipNestedUpdate, updateAction } = payload;

  try {
    const {
      media,
      given_name,
      family_name,
      email,
      lead_source,
      estimate_value,
      year_built,
      square,
      sold_date,
      ...restData
    } = data;
    // avatar
    const mediaUploadUrl = media
      ? yield call(Api.amplifyPost, api_constants.MEDIA, {
          original_name: media.name,
          sub_folder: 'avatar'
        })
      : null;
    const uploadResponse = mediaUploadUrl
      ? yield call(Api.s3Put, mediaUploadUrl.upload_url, media, { 'Content-Type': media.type })
      : null;
    const profileImageId = uploadResponse ? mediaUploadUrl.id : null;
    const avatarId = profileImageId ? { profile_image_id: profileImageId } : {};
    const [soldDate] = sold_date ? new Date(sold_date).toISOString().split('T') : [null];

    let responseCustomerData = {};
    let responseCustomerLocation = {};
    if (!skipNestedUpdate) {
      [responseCustomerData, responseCustomerLocation] = yield all([
        call(Api.amplifyPut, `${api_constants.USER}/${userId}`, {
          given_name,
          family_name,
          email,
          lead_source,
          ...avatarId
        }),
        call(Api.amplifyPut, `${api_constants.CUSTOMER_LOCATION}/${locationId}`, {
          estimate_value: parseInt(estimate_value),
          year_built: parseInt(year_built),
          sold_date: soldDate,
          square: parseInt(square?.split(',')?.join(''))
        })
      ]);

      if (!responseCustomerData || !responseCustomerLocation) {
        throw new Error(errors.DEFAULT);
      }
    }

    const response = yield call(Api.amplifyPut, `${api_constants.CUSTOMER}/${userId}`, {
      id: userId,
      ...restData
    });

    if (!response) {
      throw new Error(errors.DEFAULT);
    }

    yield put({
      type: actionTypes.UPDATE_CUSTOMER_INSTANCE_SUCCESS,
      payload: {
        user: responseCustomerData,
        location: responseCustomerLocation,
        customer: response,
        skipNestedUpdate,
        updateAction
      }
    });
    yield put(closeModal(modalTypes.EDIT_USER));

    if (updateAction === oneClickActions.IS_TROUBLED) {
      yield put(openModal(modalTypes.RESULT, { title: 'The homeowner was added to the Sara Todd list' }));
    }
    if (updateAction === oneClickActions.REMOVE_IS_TROUBLED) {
      yield put(openModal(modalTypes.RESULT, { title: 'The homeowner was removed from the Sara Todd list' }));
    }
    if (updateAction === oneClickActions.IS_NON_ICP) {
      yield put(openModal(modalTypes.RESULT, { title: 'The homeowner was added to the Non-ICP list' }));
    }
    if (updateAction === oneClickActions.REMOVE_IS_NON_ICP) {
      yield put(openModal(modalTypes.RESULT, { title: 'The homeowner was removed from the Non-ICP list' }));
    }
  } catch (err) {
    console.log(err);
    yield put({
      type: actionTypes.UPDATE_CUSTOMER_INSTANCE_ERROR,
      payload: {
        data,
        updateAction
      }
    });
  }
}

export default function* watchUpdateCustomerInstance() {
  yield takeLatest(actionTypes.UPDATE_CUSTOMER_INSTANCE_FETCH, updateCustomerInstance);
}
