import React from 'react';

import { getFullName } from 'common/helpers/user';
import { useEffect, useDispatch, useMemo, useReloadTable, useSelector } from 'hooks';

import styles from 'constants/styles';
import { staticExtraOptions } from './enums';

import { getAdminList, setFilteredAdminList } from 'store/actions';
import { selectAdminList, selectAdminListLoading } from 'store/reducers/admin';
import { isTestTasksVisible, selectFilteredAdminList } from 'store/reducers/app';
import { selectServiceListLoading } from 'store/reducers/service';
import { DropdownMultiSelect } from 'components';

const TaskOwnerSelect = () => {
  const dispatch = useDispatch();
  const { handleReloadTable } = useReloadTable();
  const admins = useSelector(selectAdminList);
  const adminsLoading = useSelector(selectAdminListLoading);
  const jobsLoading = useSelector(selectServiceListLoading);
  const filteredAdmins = useSelector(selectFilteredAdminList);
  const isTest = useSelector(isTestTasksVisible);

  useEffect(() => {
    const requestFilters = {
      is_active: 1,
      order_by: 'given_name',
      position_id: 7, // 7 - is a position of 'Job Owner' in the list of admin positions
      ...(isTest ? {} : { is_test: 0 })
    };
    dispatch(getAdminList(requestFilters));
  }, [dispatch, isTest]);

  // Iterable options list
  const taskOwnersList = admins?.length ? [...staticExtraOptions, ...admins.map(({ user }) => user)] : [];

  // Selected options list
  const selectedTaskOwnersList = useMemo(() => {
    if (adminsLoading) {
      return filteredAdmins || []; // show selected values while all options are loading
    }

    const taskOwnerOptions = [...staticExtraOptions, ...(admins || [])];
    if (!taskOwnerOptions.length) {
      return [];
    }

    const selectedTaskOwners = filteredAdmins.map(({ id }) => taskOwnerOptions.find((admin) => admin?.id === id)?.user);
    // if test admin was selected and then 'Hide Test Tasks' checked we need to filter empty options
    return selectedTaskOwners.filter(Boolean);
  }, [admins, adminsLoading, filteredAdmins]);

  // MultiSelect component text transforming functions
  const transformSelectedValuesText = (option) => `${option?.given_name}`;
  const transformMenuOptionsText = ({ given_name, family_name }) => getFullName({ given_name, family_name });

  /**
   * Loads tasks list and notifications with selected task owner IDs
   * @param {Array} admins - list of selected admins simplified objects
   */
  const applyAdminFilter = (admins) => {
    const preparedOwners = admins?.map(({ id }) => id).join(',');
    handleReloadTable({
      newRequestFilters: preparedOwners ? { owner_id: preparedOwners } : {},
      hasUpdateNotificationList: true,
      hasUpdateBadges: true
    });
  };

  /**
   * Simplifies objects from param array, stores them in persistent Redux reducer and calls for lists reload
   * @param {Array} selectedAdminList - list of selected admins rich objects
   */
  const handleApplySelection = (selectedAdminList = []) => {
    const adminList = selectedAdminList.length
      ? selectedAdminList.map(({ id, given_name }) => ({ id, given_name }))
      : [];
    dispatch(setFilteredAdminList(adminList));
    applyAdminFilter(adminList);
  };

  return (
    <DropdownMultiSelect
      options={taskOwnersList}
      selectedOptions={selectedTaskOwnersList}
      extraOptions={staticExtraOptions}
      onApplySelection={handleApplySelection}
      isDisabled={adminsLoading}
      isLoading={jobsLoading || adminsLoading}
      label={'Job Owner'}
      selectPlaceholderText={'Select Owner'}
      selectAllOptionText={'All Jobs'}
      selectedItemsStringTruncateLimit={20}
      transformSelectedValuesText={transformSelectedValuesText}
      transformMenuOptionsText={transformMenuOptionsText}
      selectedValuesColor={styles.colors.BLACK}
      selectedValuesBackgroundColor={styles.colors.LIGHT_GRAY_2}
      dropdownOptionsColor={styles.colors.BLACK}
      dropdownSelectedOptionsColor={styles.colors.RED}
      hasExtraOptions
      hasImages
      hasCloseButton
      applyOnItemSelection={false}
      selectedValuesWidth={220}
      dropdownOptionsWidth={260}
      dropdownOptionsHeight={50}
    />
  );
};

export default TaskOwnerSelect;
