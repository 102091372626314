import React, { useCallback, useEffect, useState } from 'react';

import { useDispatch } from 'hooks';
import { Notes } from 'containers';
import { Button } from 'components';

import { getUserNoteList } from 'store/actions';

import { NotesSection, DataRecord, PlaceholderText } from './styled';

const NotesContainer = ({ userId }) => {
  const dispatch = useDispatch();
  const [isNoteForm, setNoteForm] = useState(false);

  const toggleNoteForm = useCallback(() => setNoteForm((value) => !value), []);

  useEffect(() => {
    if (!userId) {
      return;
    }
    dispatch(getUserNoteList({ userId }));
  }, [userId]);

  if (!userId) {
    return (
      <NotesSection>
        <PlaceholderText>Please, select a conversation to see notes</PlaceholderText>
      </NotesSection>
    );
  }

  return (
    <NotesSection>
      <Notes
        type="homeowner"
        showForm={isNoteForm}
        userId={userId}
        openEditNoteForm={toggleNoteForm}
        closeEditNoteForm={toggleNoteForm}
      />
      {!isNoteForm && (
        <DataRecord>
          <Button title="Add Notes" variant="primary" onClick={toggleNoteForm} />
        </DataRecord>
      )}
    </NotesSection>
  );
};

export default NotesContainer;
