import { call, takeLatest, put } from 'redux-saga/effects';
import api_constants from 'constants/api';
import actionTypes from 'constants/actionTypes';
import Api from 'services/ApiService';

function* getCustomerInstance({ payload: id }) {
  try {
    const response = yield call(Api.amplifyGet, `${api_constants.CUSTOMER}/${id}`);
    if (response) {
      yield put({
        type: actionTypes.GET_CUSTOMER_INSTANCE_SUCCESS,
        payload: response
      });
    } else {
      yield put({
        type: actionTypes.GET_CUSTOMER_INSTANCE_ERROR
      });
    }
  } catch (err) {
    console.log(err);
  }
}

export default function* watchGetCustomerInstance() {
  yield takeLatest(actionTypes.GET_CUSTOMER_INSTANCE_FETCH, getCustomerInstance);
}
