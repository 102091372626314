import styled from 'styled-components';
import styles from 'constants/styles';

export const Wrapper = styled.div`
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }

  display: flex;
  overflow: auto;
  position: relative;
  ${({ theme }) => theme.darkMode && `background: ${theme.colors.DARK_3}`}
`;

export const EmptySpace = styled.div`
  ${({ theme }) => !theme.darkMode && `border-bottom: 1px solid ${theme.colors.LIGHT_GRAY};`}
  flex: 1;
`;

export const Button = styled.div`
  background: linear-gradient(90deg, #262e30 0%, rgba(38, 46, 48, 0) 100%);
  padding: 0 22px 0 12px;
  position: absolute;
  top: 0;
  z-index: 999;
  height: 50px;
  display: flex;
  align-items: center;
  cursor: pointer;
  ${({ right }) => (right ? `transform: rotate(180deg); right: 0;` : `left: 0;`)}
`;

export const TabsWrapper = styled.div`
  position: relative;
`;
