import { commissionTypes } from 'common/enums/invoice';

export const summaryTooltipContent = {
  [commissionTypes.SUB_FEE]: {
    DOBBY_PLATFORM_FEE: {
      title: 'Dobby’s Operating Margin',
      description: '(labor + material) * %amount'
    },
    PRO_PAYOUT: {
      title: 'Pro Payout',
      description: '(labor+material) - amount% + tips'
    },
    DOBBY_CASH_REWARD: {
      title: 'DobbyCash Reward',
      description: 'Dobby Discount (excluding DobbyCash personal funds)'
    },
    STRIPE_FEE: {
      title: 'Stripe Fee',
      description: '(labor + material + tips) * 0.03'
    },
    DOBBY_MARKUP: {
      title: `Dobby's Net Margin`,
      description: 'Dobby’s Operating Margin - DobbyCash Reward'
    },
    PERCENT_DOBBY_MARKUP: {
      title: `% Dobby's Net Margin`,
      description: '(Dobby’s Operating Margin - DobbyCash Reward / (Pro Payout - Pro Tips)) * 100'
    },
    GMV: {
      title: 'GMV Before Discounts',
      description: 'Pro Payout + Dobby’s Operating Margin + Stripe Fee'
    },
    HO_PAYMENT: {
      title: 'HO Payment',
      description: 'Labor + Material + Tips + Stripe Fee - DobbyCash Reward'
    },
    ALSO_HO_PAYMENT: {
      prefix: '(also',
      tootipTextProps: {
        marginLeft: 12
      },
      title: 'HO Payment',
      description: 'Pre-paid by DobbyCash + Paid at Completion)'
    },
    PRE_PAID_BY_DOBBY_CASH: {
      title: 'Pre-paid by DobbyCash',
      suffix: ' - ',
      description: 'amount applied from the DobbyCash balance (excluding DobbyCash Reward)'
    },
    PAID_AT_COMPLETION: {
      title: 'Paid at Completion',
      suffix: ' - ',
      description: 'how much HO has spent by credit card/cash'
    }
  },
  [commissionTypes.MARGIN]: {
    DOBBY_PLATFORM_FEE: {
      title: 'Dobby’s Operating Margin',
      description: '(Labor + Material + Flat Rate) / (1-percentage amount/100)'
    },
    PRO_PAYOUT: {
      title: 'Pro Payout',
      description: 'Labor + Material + Flat Rate'
    },
    DOBBY_CASH_REWARD: {
      title: 'DobbyCash Reward',
      description: 'Dobby Discount (excluding DobbyCash personal funds)'
    },
    STRIPE_FEE: {
      title: 'Stripe Fee',
      description: '(Labor + Material + Flat Rate + Dobby’s Operating Margin + tips) * 0.03'
    },
    STRIPE_FEE_FOR_ACH: {
      title: 'Stripe Fee for ACH payment',
      suffix: ':'
    },
    STRIPE_FEE_FOR_ACH_PERSENT: {
      prefix: 'If (Labor + Material + Flat Rate + Tips - DobbyCash Reward) < $625, then Stripe Fee ',
      description: '0.8%',
      tootipTextProps: {
        marginLeft: 12
      }
    },
    STRIPE_FEE_FOR_ACH_FIXED: {
      prefix: 'If (Labor + Material + Flat Rate + Tips - DobbyCash Reward) >= $625, then Stripe Fee ',
      description: '$5',
      tootipTextProps: {
        marginLeft: 12
      }
    },
    DOBBY_MARKUP: {
      title: `Dobby's Net Margin`,
      description: 'Dobby’s Operating Margin - DobbyCash Reward - Stripe Failure Fee'
    },
    PERCENT_DOBBY_MARKUP: {
      title: `% Dobby's Net Margin`,
      description: '(Dobby’s Operating Margin - DobbyCash Reward / (Pro Payout - Pro Tips)) * 100'
    },
    GMV: {
      title: 'GMV Before Discounts',
      description: 'Pro Payout + Dobby’s Operating Margin + Stripe Fee - Stripe Failure Fee'
    },
    HO_PAYMENT: {
      title: 'HO Payment',
      description: 'Labor + Material + Flat Rate + Dobby’s Operating Margin + Tips + Stripe Fee - DobbyCash Reward'
    },
    ALSO_HO_PAYMENT: {
      prefix: '(also',
      tootipTextProps: {
        marginLeft: 12
      },
      title: 'HO Payment',
      description: 'Pre-paid by DobbyCash + Paid at Completion)'
    },
    PRE_PAID_BY_DOBBY_CASH: {
      title: 'Pre-paid by DobbyCash',
      suffix: ' - ',
      description: 'amount applied from the DobbyCash balance (excluding DobbyCash Reward)'
    },
    PAID_AT_COMPLETION: {
      title: 'Paid at Completion',
      suffix: ' - ',
      description: 'how much HO has spent by credit card/cash'
    }
  }
};
