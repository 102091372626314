import React from 'react';

import { closeModal } from 'store/actions';
import modalTypes from 'constants/modalTypes';

import { createSkill } from 'store/actions/provider';

import { Button, Modal, HookForm } from 'components';
import { useDispatch, useSelector } from 'hooks';
import { Heading, Buttons, Content } from './styled';
import { useForm, useWatch } from 'react-hook-form';
import { selectSkillsCreateLoading } from 'store/reducers/provider';

const CreateSkill = ({ modalType = modalTypes.CREATE_SKILL }) => {
  const dispatch = useDispatch();
  const { control } = useForm({
    mode: 'onChange'
  });
  const values = useWatch({ control });
  const loading = useSelector(selectSkillsCreateLoading);
  const hideModal = () => dispatch(closeModal(modalType));

  return (
    <Modal
      modalType={modalType}
      hideModal={hideModal}
      padding="0"
      maxHeight={608}
      heading={<Heading>Create new skill</Heading>}>
      <Content>
        <HookForm.Input name="name" control={control} placeholder="Enter skill name" />
        <Buttons>
          <Button variant="primary" onClick={hideModal}>
            Cancel
          </Button>
          <Button
            loading={loading}
            disabled={!values?.name?.trim() || values?.name?.length > 60}
            onClick={() => dispatch(createSkill(values?.name))}>
            Create
          </Button>
        </Buttons>
      </Content>
    </Modal>
  );
};

export default CreateSkill;
