export const isAreaListDirty = ({ areaList, defaultAreaList }) => {
  // no changes, nothing to check
  if (!areaList && !defaultAreaList) {
    return false;
  }

  // if length isn't equal => doesn't make sense to compare each item
  if (areaList?.length !== defaultAreaList?.length) {
    return true;
  }

  const isAnyItemDifferent = areaList.some((item) =>
    defaultAreaList.every((defaultItem) => defaultItem.id !== item.id)
  );
  return isAnyItemDifferent;
};
