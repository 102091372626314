import styled from 'styled-components';

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  padding: 16px 24px;
  background-color: ${({ theme }) => theme.colors.DARK_2};
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  margin-top: 17px;
`;

export const FlexRow = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
`;

export const MarginSection = styled.div`
  display: flex;
  flex-direction: column;
  ${({ margin }) => margin && `margin: ${margin}`};
`;

export const ModalTitle = styled.div`
  font-weight: 400;
  font-size: 20px;
  line-height: 1.2;
  padding: 12px 0 12px 24px;
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;
  background: ${({ theme }) => theme.colors.DARK_3};
  color: ${({ theme }) => theme.colors.INVOICE_TEXT};
`;

export const SectionTitle = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 1.14;

  color: ${({ theme }) => theme.colors.INVOICE_TEXT};
`;

export const Label = styled.label`
  color: ${({ theme }) => theme.colors.DARK_GREY};
  font-weight: 500;
  font-size: 12px;
  line-height: 1.17;
  margin: 17px 0 0 0;
  margin-bottom: 8px;
`;

export const Value = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 1.14;
  color: ${({ theme }) => theme.colors.INVOICE_TEXT};
  margin: 0;
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;

  word-break: break-word;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;

  margin-bottom: 5px;

  max-width: 100%;
  overflow: wrap;

  ${({ bold }) =>
    bold &&
    `
    font-weight: 700;
  `};
`;

export const Description = styled.div`
  flex: 1;
  flex-basis: 100px;
  text-align: left;
`;

export const Amount = styled.span`
  flex-basis: 100px;
  text-align: right;
`;

export const TotalWrapper = styled.div`
  display: flex;
  margin: 26px 0 32px;
`;

export const labelStyle = {
  fontWeight: 500,
  fontSize: 12,
  lineHeight: 1.17,
  marginTop: 16,
  marginBottom: 5
};

export const valueStyle = {
  fontWeight: 700,
  fontSize: 15,
  lineHeight: 1.33,
  marginRight: 25
};

export const lineStyle = {
  marginTop: 20,
  marginBottom: 20
};
