import { columnNames } from 'common/enums/sortings';

export const referralTable = {
  DOWNLOAD_CSV_BUTTON: {
    TITLE: '',
    FILTER_NAME: columnNames.DOWNLOAD_CSV_BUTTON
  },
  SOURCE_USER_ID: {
    TITLE: 'User ID',
    FILTER_NAME: columnNames.SOURCE_USER_ID
  },
  SOURCE_USER_NAME: {
    TITLE: 'Invited By',
    FILTER_NAME: columnNames.SOURCE_USER_NAME
  },
  SOURCE_USER_GROUP_ID: {
    TITLE: 'Type',
    FILTER_NAME: columnNames.SOURCE_USER_GROUP_ID
  },
  TARGET_USER_ID: {
    TITLE: 'User ID',
    FILTER_NAME: columnNames.TARGET_USER_ID
  },
  TARGET_USER_NAME: {
    TITLE: 'First&Last Name',
    FILTER_NAME: columnNames.TARGET_USER_NAME
  },
  PHONE_NUMBER: {
    TITLE: 'Phone number',
    FILTER_NAME: columnNames.PHONE_NUMBER
  },
  EMAIL: {
    TITLE: 'Email',
    FILTER_NAME: columnNames.EMAIL
  },
  DATETIME_CREATED: {
    TITLE: 'Reg Date',
    FILTER_NAME: columnNames.DATETIME_CREATED
  },
  TARGET_JOB_COUNT_ACTIVE: {
    TITLE: 'Active',
    FILTER_NAME: columnNames.TARGET_JOB_COUNT_ACTIVE
  },
  TARGET_JOB_COUNT_COMPLETED: {
    TITLE: 'Completed',
    FILTER_NAME: columnNames.TARGET_JOB_COUNT_COMPLETED
  }
};

export const groupToString = {
  1: 'HO',
  2: 'Pro',
  4: 'RE'
};
