import React from 'react';
import { connect } from 'react-redux';
import { fieldTypes } from 'common/enums/form';

import { useDispatch, useForm, useState, useSelector, useMemo, useWatch } from 'hooks';
import modalTypes from 'constants/modalTypes';
import { userGroups, providerStages, providerCohorts } from 'common/enums/user';
import styles from 'constants/styles';
import { Avatar, Modal, Button, HookForm, LoadingSpinner } from 'components';
import { validators } from 'utils';
import { generateSpecialtiesListOptions, generateServiceAreaListOptions } from 'common/helpers/user';

import { isAreaListDirty } from './helpers/form';
import {
  LoadingContainer,
  AvatarLabel,
  LogoLabel,
  FlexRow,
  ContentWrapper,
  ContentColumnWrapper,
  ContentRowWrapper,
  InvisibleInput,
  ButtonsWrapper,
  CompanyWrapper,
  externalAuthorAreaStyle,
  Grid,
  Flex,
  Buttons
} from './styled';
import { getSkills } from 'store/actions/provider';
import { selectModalData } from 'store/reducers/modal';
import { selectUpdateCustomerInstanceLoading } from 'store/reducers/customer';
import { selectUpdateAgentInstanceLoading } from 'store/reducers/agent';
import {
  selectProviderAreaList,
  selectProviderAreaListLoading,
  selectUpdateProviderInstanceLoading
} from 'store/reducers/provider';
import { updateProviderInstance, updateAgentInstance, updateCustomerInstance, closeModal } from 'store/actions';
import { mapSkillsToOptions } from 'common/helpers/user';
import { ProTagSelects } from './components';

const UserDetailsEditModal = ({
  updateAgentInstance,
  updateCustomerInstance,
  updateProviderInstance,
  areaList,
  areaListLoading,
  closeModal,
  modalData: {
    initialValues,
    additional_user_id,
    is_active,
    is_api_active,
    is_cognito_active,
    is_pre_filled,
    id,
    location_id,
    onToggleUserIsActive,
    onOpenDeleteUserModal,
    userGroupId
  }
}) => {
  const hasUpdateAgentInstanceLoading = useSelector(selectUpdateAgentInstanceLoading);
  const hasUpdateCustomerInstanceLoading = useSelector(selectUpdateCustomerInstanceLoading);
  const hasUpdateProviderInstanceLoading = useSelector(selectUpdateProviderInstanceLoading);

  const [avatarBase64, setAvatarBase64] = useState();
  const [logoBase64, setLogoBase64] = useState();

  const isAgent = userGroupId === userGroups.AGENT.TYPE;
  const isCustomer = userGroupId === userGroups.CUSTOMER.TYPE;
  const isProvider = userGroupId === userGroups.PROVIDER.TYPE;

  const hasUpdateInstanceLoading = useMemo(() => {
    if (userGroupId === userGroups.AGENT.TYPE) {
      return hasUpdateAgentInstanceLoading;
    }

    if (userGroupId === userGroups.CUSTOMER.TYPE) {
      return hasUpdateCustomerInstanceLoading;
    }

    if (userGroupId === userGroups.PROVIDER.TYPE) {
      return hasUpdateProviderInstanceLoading;
    }

    return false;
  }, [userGroupId, hasUpdateAgentInstanceLoading, hasUpdateCustomerInstanceLoading, hasUpdateProviderInstanceLoading]);

  const onEditModalClose = () => closeModal(modalTypes.EDIT_USER);

  const {
    control,
    resetField,
    register,
    handleSubmit,
    setValue,
    formState: { isValid, dirtyFields }
  } = useForm({
    defaultValues: initialValues,
    mode: 'onChange'
  });
  const data = useWatch({ control });
  const excludeSecondaryCategory = useMemo(() => {
    if (data.primary_category?.id) {
      return [data?.primary_category?.id];
    }
    return [];
  }, [data.primary_category?.id]);

  const onSubmitEditForm = (data) => {
    if (isProvider) {
      const service_area_list = data.service_area_list?.map((item) => ({ service_area_id: item.id })) || [];
      const auto_assign_service_area = data.auto_assign_service_area?.map((item) => item.id) || [];
      const one_click_service_area = data.one_click_service_area?.map((item) => item.id) || [];
      const isServiceAreaListEdited = isAreaListDirty({
        areaList: data.service_area_list,
        defaultAreaList: initialValues.service_area_list
      });

      const providerTagFields =
        Object.values(providerCohorts)?.find(({ TYPE }) => TYPE === data?.pro_cohort)?.FIELDS || {};
      const hasAutoAssign =
        [providerStages.ONE_CLICK_BOOKING.TYPE, providerStages.NEW_HO_PRO.TYPE].includes(data.pro_stage) &&
        auto_assign_service_area?.length;
      const hasOneClickBooking =
        [providerStages.ONE_CLICK_BOOKING.TYPE].includes(data.pro_stage) && one_click_service_area?.length;

      updateProviderInstance({
        data: {
          ...data,
          ...providerTagFields,
          service_area_list,
          category: [],
          primary_service_id: data.primary_category?.id,
          secondary_category: data.secondary_category?.map((item) => ({ id: item?.id })) || [],
          auto_assign: hasAutoAssign
            ? {
                service_id: data.auto_assign_service_id,
                service_area: auto_assign_service_area
              }
            : null,
          one_click: hasOneClickBooking
            ? {
                service_id: data.one_click_service_id,
                service_area: one_click_service_area
              }
            : null
        },
        userId: id,
        isServiceAreaListEdited,
        providerId: additional_user_id
      });
    }

    if (isAgent) {
      updateAgentInstance({
        data,
        userId: id,
        agentId: additional_user_id
      });
    }

    if (isCustomer) {
      updateCustomerInstance({
        data,
        userId: id,
        locationId: location_id
      });
    }
  };

  const handleAvatarUploading = (e) => {
    register('media');
    e.stopPropagation();
    e.preventDefault();
    const { files } = e.target;
    if (files && files[0]) {
      const fileReader = new FileReader();
      setValue('media', files[0], { shouldDirty: true });
      fileReader.onload = function () {
        if (this.result) {
          setAvatarBase64(this.result);
        }
      };
      fileReader.readAsDataURL(files[0]);
    }
  };

  const handleLogoUploading = (e) => {
    register('logo_media');
    e.stopPropagation();
    e.preventDefault();
    const { files } = e.target;
    if (files && files[0]) {
      const fileReader = new FileReader();
      setValue('logo_media', files[0], { shouldDirty: true });
      fileReader.onload = function () {
        if (this.result) {
          setLogoBase64(this.result);
        }
      };
      fileReader.readAsDataURL(files[0]);
    }
  };

  const removeSelectedAvatar = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setAvatarBase64('');
    resetField('media');
  };

  const removeSelectedLogo = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setLogoBase64('');
    resetField('logo_media');
  };
  const dispatch = useDispatch();

  return (
    <Modal
      modalType={modalTypes.EDIT_USER}
      hideModal={onEditModalClose}
      closeButton={{
        onClick: onEditModalClose,
        disabled: false
      }}
      heading="Edit Profile Info"
      backgroundColor={styles.colors.WHITE}
      middlePaddings
      padding={'0px'}
      maxWidth={isProvider ? 1128 : 760}
      maxHeight={0}>
      {areaListLoading && (
        <LoadingContainer>
          <LoadingSpinner text="Please wait" type="logo" />
        </LoadingContainer>
      )}
      {!areaListLoading && (
        <ContentWrapper>
          <ContentRowWrapper>
            <ContentColumnWrapper>
              <FlexRow>
                <Flex gap={'24px'}>
                  <Grid>
                    <AvatarLabel>
                      <Avatar
                        src={avatarBase64}
                        alt="Upload Photo"
                        clickable={true}
                        onCloseButtonClick={avatarBase64 ? removeSelectedAvatar : null}
                        placeholderText="Upload Photo"
                        size={64}
                      />
                      <InvisibleInput
                        type="file"
                        multiple={false}
                        accept="image/png, image/jpeg"
                        onChange={handleAvatarUploading}
                      />
                    </AvatarLabel>
                    {isProvider && (
                      <CompanyWrapper>
                        <LogoLabel>
                          <Avatar
                            src={logoBase64}
                            alt="Upload Logo"
                            clickable={true}
                            onCloseButtonClick={logoBase64 ? removeSelectedLogo : null}
                            placeholderText="Upload Logo"
                            height={40}
                            width={72}
                          />
                          <InvisibleInput
                            type="file"
                            multiple={false}
                            accept="image/png, image/jpeg"
                            onChange={handleLogoUploading}
                          />
                        </LogoLabel>
                      </CompanyWrapper>
                    )}
                  </Grid>
                  <Grid gap="21px">
                    <Flex gap="24px">
                      <HookForm.Input
                        register={register}
                        type="name"
                        name="given_name"
                        label="First name"
                        placeholder="John"
                        width={296}
                      />
                      <HookForm.Input
                        register={register}
                        type="name"
                        name="family_name"
                        label="Last name"
                        placeholder="Smith"
                        width={296}
                      />
                    </Flex>
                    <Flex gap="24px">
                      <HookForm.Input
                        register={register}
                        name="email"
                        label="Email"
                        placeholder="john.smith@gmail.com"
                        rules={{ validate: validators.email }}
                        width={296}
                      />
                      {isProvider && (
                        <HookForm.Input
                          register={register}
                          type="name"
                          name="preferred_name"
                          label="Preferred name"
                          placeholder="Alias"
                          width={296}
                        />
                      )}
                      {isCustomer && (
                        <HookForm.Input
                          name="lead_source"
                          register={register}
                          label="Invited By"
                          placeholder="Spectora scheduling"
                          width={296}
                        />
                      )}
                    </Flex>
                  </Grid>
                </Flex>
              </FlexRow>
              {isProvider && (
                <FlexRow>
                  <HookForm.SelectMultiple
                    name="service_area_list"
                    label="Working Area"
                    control={control}
                    placeholder="Add more"
                    options={generateServiceAreaListOptions(areaList)}
                    defaultValue={initialValues.service_area_list}
                  />
                </FlexRow>
              )}
              {isProvider && (
                <FlexRow>
                  <HookForm.SelectMultiple
                    name="primary_category"
                    label="Primary Category"
                    placeholder={'Select primary category'}
                    isMulti={false}
                    control={control}
                    options={generateSpecialtiesListOptions()}
                    defaultValue={initialValues.primary_category}
                  />
                  <HookForm.SelectMultiple
                    name="secondary_category"
                    label="Secondary Category"
                    placeholder={'Select secondary category'}
                    excludeOptions={excludeSecondaryCategory}
                    control={control}
                    maxCount={4}
                    options={generateSpecialtiesListOptions()}
                    defaultValue={initialValues.secondary_category}
                  />
                </FlexRow>
              )}
              {isAgent && (
                <FlexRow>
                  <HookForm.Input
                    name="company_name"
                    register={register}
                    label="Company"
                    placeholder="Dobby"
                    width={195}
                  />
                  <HookForm.Input
                    name="position"
                    register={register}
                    label="Position"
                    placeholder="Dobby Agent"
                    width={195}
                  />
                </FlexRow>
              )}
              {isProvider && (
                <HookForm.SelectMultiple
                  name="provider_skills"
                  label="Skills"
                  control={control}
                  marginLeft={0}
                  placeholder="Add more"
                  loadOptions={(search, callback) => dispatch(getSkills({ search, callback }))}
                  defaultValue={mapSkillsToOptions({ skills: initialValues.provider_skills || [], ownSkills: true })}
                />
              )}
              {isAgent && (
                <FlexRow>
                  <HookForm.Input
                    name="website_link"
                    register={register}
                    label="Website Link"
                    placeholder="https://agent.portfolio.com"
                    rules={{ validate: validators.link }}
                    width={456}
                    dropdownWidth={356}
                  />
                </FlexRow>
              )}
              {isCustomer && (
                <FlexRow>
                  <HookForm.Input
                    name="estimate_value"
                    control={control}
                    fieldType={fieldTypes.DOLLAR_CURRENCY}
                    label="Sold price, $"
                    customPlaceholder="$1,234,567.89"
                    width={135}
                  />
                  <HookForm.Input
                    name="year_built"
                    register={register}
                    type="number"
                    label="Year Built"
                    placeholder=""
                    width={135}
                  />
                  <HookForm.Input
                    name="square"
                    control={control}
                    fieldType={fieldTypes.CURRENCY}
                    label="SQFT"
                    customPlaceholder="1,234.56"
                    width={135}
                  />
                  <HookForm.Input
                    name="sold_date"
                    register={register}
                    type="number"
                    label="Year Purchased"
                    placeholder=""
                    width={135}
                  />
                </FlexRow>
              )}
              {isCustomer && (
                <FlexRow>
                  <HookForm.Input
                    name="social_profile"
                    register={register}
                    label="Public Profile"
                    placeholder=""
                    width={200}
                  />
                  <HookForm.Input
                    name="home_listing"
                    register={register}
                    label="Home Listing"
                    placeholder=""
                    width={200}
                  />
                  <HookForm.Input
                    name="planning_mode_link"
                    register={register}
                    label="Planning Mode Link"
                    placeholder=""
                    width={200}
                  />
                </FlexRow>
              )}
              {(isAgent || isProvider) && (
                <FlexRow isLast={isAgent}>
                  <HookForm.Textarea
                    name="about"
                    label="Bio"
                    register={register}
                    placeholder="Bio"
                    rows={6}
                    style={externalAuthorAreaStyle}
                  />
                </FlexRow>
              )}
              {isProvider ? (
                <>
                  <FlexRow>
                    <HookForm.Input
                      name="external_author_1"
                      register={register}
                      label="External review 1"
                      placeholder="Satadru"
                      width={240}
                    />
                  </FlexRow>
                  <FlexRow>
                    <HookForm.Textarea
                      name="external_review_1"
                      register={register}
                      label=""
                      placeholder="External review 1"
                      rows={6}
                      style={externalAuthorAreaStyle}
                    />
                  </FlexRow>
                  <FlexRow>
                    <HookForm.Input
                      name="external_author_2"
                      register={register}
                      label="External review 2"
                      placeholder="Emma"
                      width={240}
                    />
                  </FlexRow>
                  <FlexRow>
                    <HookForm.Textarea
                      name="external_review_2"
                      register={register}
                      label=""
                      placeholder="External review 2"
                      rows={6}
                      style={externalAuthorAreaStyle}
                    />
                  </FlexRow>
                  <FlexRow>
                    <HookForm.Input
                      name="external_author_3"
                      register={register}
                      label="External review 3"
                      placeholder="Alex"
                      width={240}
                    />
                  </FlexRow>
                  <FlexRow>
                    <HookForm.Textarea
                      name="external_review_3"
                      register={register}
                      label=""
                      placeholder="External review 3"
                      rows={6}
                      style={externalAuthorAreaStyle}
                    />
                  </FlexRow>
                </>
              ) : null}
            </ContentColumnWrapper>
            {isProvider && (
              <ProTagSelects
                control={control}
                areaList={areaList}
                initialValues={initialValues}
                is_active={is_active}
                is_api_active={is_api_active}
                is_cognito_active={is_cognito_active}
                is_pre_filled={is_pre_filled}
              />
            )}
          </ContentRowWrapper>
          <ButtonsWrapper>
            <div>
              {(isAgent || isProvider) && !is_pre_filled && (
                <Buttons>
                  <Button title="Remove" variant="primary" margin="0 20px 0 0" onClick={onOpenDeleteUserModal} />
                  <Button
                    title={is_active ? 'Block' : 'Activate'}
                    variant="primary"
                    margin="0 0 0 10px"
                    onClick={onToggleUserIsActive}
                  />
                </Buttons>
              )}
            </div>

            <Button
              title="Save"
              onClick={handleSubmit(onSubmitEditForm)}
              loading={hasUpdateInstanceLoading}
              disabled={hasUpdateInstanceLoading || !Object.keys(dirtyFields).length || !isValid}
            />
          </ButtonsWrapper>
        </ContentWrapper>
      )}
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  areaList: selectProviderAreaList(state),
  areaListLoading: selectProviderAreaListLoading(state),
  modalData: selectModalData(state)
});

const mapDispatchToProps = {
  updateProviderInstance,
  updateAgentInstance,
  updateCustomerInstance,
  closeModal
};

export default connect(mapStateToProps, mapDispatchToProps)(UserDetailsEditModal);
