// React/Redux
import { call, takeLatest, put } from 'redux-saga/effects';
// Store
import { openModal, closeModal } from 'store/actions/modal';
// Services
import Api from 'services/ApiService';
// Constants
import api_constants from 'constants/api';
import actionTypes from 'constants/actionTypes';
import modalTypes from 'constants/modalTypes';
import errors from 'constants/errors';

function* getServiceProAssignBundle({ payload }) {
  const { task_id } = payload;

  try {
    const response = yield call(Api.amplifyGet, `${api_constants.PRO_ASSIGN_JOB}/${task_id}`);

    if (!response || response?.err) {
      throw new Error(errors.DEFAULT);
    }
    yield put({
      type: actionTypes.SIGN_SERVICE_IMAGE_URLS_FETCH,
      payload: { serviceId: response.id, media: response.media }
    });
    yield put({
      type: actionTypes.GET_PRO_ASSIGN_BUNDLE_SUCCESS,
      payload: response
    });
  } catch (err) {
    console.log(err);
    yield put(
      openModal(modalTypes.ERROR, {
        errorMessage: errors.DEFAULT
      })
    );
    yield put({
      type: actionTypes.GET_PRO_ASSIGN_BUNDLE_ERROR
    });
  }
}

export default function* watchGetServiceProAssignBundle() {
  yield takeLatest(actionTypes.GET_PRO_ASSIGN_BUNDLE_FETCH, getServiceProAssignBundle);
}
