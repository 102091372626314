import styled from 'styled-components';
import styles from 'constants/styles';
import { Field } from 'redux-form';

export const StyledInput = styled(Field)`
  background: ${styles.colors.LIGHT_GRAY_2};
  border-radius: 4px;
  border: none;
  color: ${styles.colors.DARK_BLUE};
  font-weight: 600;
  font-size: 14px;
  line-height: 1.2;
  padding: 4px 8px;
  display: inline-block;
  outline: 0 none;
  text-align: left;
  margin-bottom: -5px;
  border: ${({ props: { invalid } }) => (invalid ? `1px solid ${styles.colors.RED}` : '')};
  flex: 1;
  width: 120px;
  max-width: ${({ props: { styletype } }) => (styletype === 'CURRENCY' ? '120px' : null)};
  &::placeholder {
    opacity: 1;
    color: ${styles.colors.LIGHT_GRAY};
  }
  &:active,
  &:focus {
    outline: none;
    box-shadow: 0 0 0 0.1em ${styles.colors.GRAY};
    background: ${styles.colors.WHITE};
  }
  &[disabled] {
    color: ${styles.colors.GRAY};
    box-shadow: none;
    background: ${styles.colors.LIGHT_GRAY_2};
  }
`;

export const FieldValue = styled.span`
  padding: ${({ isInputShowing }) => (isInputShowing ? '0' : '4px 0')};
  font-weight: 500;
  font-size: 14px;
  line-height: 1.2;
  ${({ isValueEmpty }) =>
    isValueEmpty &&
    `
      color: ${styles.colors.LIGHT_GRAY};
      font-weight: 600;
      &:hover {
          cursor: ${({ disabled }) => (!disabled ? 'pointer' : 'default')};
          ${({ disabled }) => !disabled && `color: ${styles.colors.DARK_GREEN_3}`};
      }
    `}

  div {
    display: flex;
  }
`;

export const SpinnerContainer = styled.div`
  padding: 7px 0;
  background: ${styles.colors.LIGHT_GRAY_2};
  border-radius: 4px;
  width: 115px;
  display: flex;
  justify-content: ${({ name }) =>
    ['datetime_expected_completed', 'expected_value'].includes(name) ? 'start' : 'center'};
  align-items: center;
`;

export const IconMargin = styled.div`
  margin-left: 7px;
  margin-top: -2px;
  div {
    margin: 0;
  }
`;

export const EditableValue = styled.div`
  color: ${({ theme }) => theme.colors.INVOICE_TEXT};
`;

export const EditableLabelPrefix = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.2;
  margin-right: 8px;
`;
