import React from 'react';
import { connect } from 'react-redux';
import { closeModal } from 'store/actions';
import { ModalConfirmation } from 'components';
import errors from 'constants/errors';
import modalTypes from 'constants/modalTypes';
import { selectModalData } from 'store/reducers/modal';

const ErrorModal = ({ closeModal, modalData }) => (
  <>
    <ModalConfirmation
      maxWidth={null}
      modalType={modalTypes.ERROR}
      smallPaddings={false}
      text={modalData.errorMessage || errors.DEFAULT}
      hideModal={() => closeModal(modalTypes.ERROR)}
      secondaryButton={{
        title: 'Ok',
        onClick: () => {
          closeModal(modalTypes.ERROR);
        }
      }}
    />
  </>
);

const mapStateToProps = (state) => ({
  modalData: selectModalData(state)
});

const mapDispatchToProps = {
  closeModal
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorModal);
