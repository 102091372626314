import actionTypes from 'constants/actionTypes';

/**
 * locationSearch stores array of queryStrings by menu type
 * each item shouldn't start from '?'
 */
const defaultState = {
  activeMenuItem: {},
  locationSearch: {},
  hasGoBackButton: false,
  hasNotificationsPopup: false
};

function reducer(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.CHANGE_HAS_GO_BACK_BUTTON: {
      return {
        ...state,
        hasGoBackButton: action.payload
      };
    }

    case actionTypes.TOGGLE_HAS_NOTIFICATIONS_POPUP: {
      return {
        ...state,
        hasNotificationsPopup: action.payload
      };
    }

    case actionTypes.SET_ACTIVE_MENU_ITEM: {
      return {
        ...state,
        activeMenuItem: action.payload
      };
    }

    case actionTypes.SAVE_LOCATION_SEARCH: {
      const { locationSearch, menuItemType, locationSearchIndex = 0 } = action.payload;

      if (!menuItemType) {
        return state;
      }

      return {
        ...state,
        locationSearch: {
          ...state.locationSearch,
          [menuItemType]: {
            ...state.locationSearch[menuItemType],
            [locationSearchIndex]: locationSearch
          }
        }
      };
    }

    case actionTypes.SET_ENVIRONMENT:
    case actionTypes.SET_DEV_ENVIRONMENT: {
      return defaultState;
    }

    default:
      return state;
  }
}

export const selectHasGoBackButton = (state) => state.navigation.hasGoBackButton;
export const selectHasNotificationsPopup = (state) => state.navigation.hasNotificationsPopup;
export const selectActiveMenuItem = (state) => state.navigation.activeMenuItem;
export const selectActiveLocationSearch = (state) =>
  state.navigation.locationSearch[state.navigation.activeMenuItem?.TYPE];

export default reducer;
