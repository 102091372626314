import React from 'react';
import { connect } from 'react-redux';

import { closeModal } from 'store/actions';
import { selectModalData } from 'store/reducers/modal';

import modalTypes from 'constants/modalTypes';
import { ModalConfirmation } from 'components';

const DetachRewardFromInvoice = ({ modalData: { onConfirm } = {}, closeModal }) => {
  const closeConfirmationModal = () => {
    closeModal(modalTypes.DETACH_REWARD_FROM_INVOICE);
  };

  return (
    <ModalConfirmation
      modalType={modalTypes.DETACH_REWARD_FROM_INVOICE}
      hideModal={closeConfirmationModal}
      title={`Are you sure?`}
      text={`It may occur bad experience at the homeowner`}
      primaryButton={{
        title: 'No',
        onClick: closeConfirmationModal
      }}
      secondaryButton={{
        title: 'Yes',
        onClick: onConfirm
      }}
    />
  );
};

const mapStateToProps = (state) => ({
  modalData: selectModalData(state)
});

const mapDispatchToProps = {
  closeModal
};

export default connect(mapStateToProps, mapDispatchToProps)(DetachRewardFromInvoice);
