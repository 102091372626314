import { cellTypes } from 'common/enums/table';
import { avatarTableCellStyle } from 'constants/styles';
import { getUserIcon } from 'utils';
import { agentTable } from '../enums';

export const getColumns = () => {
  return [
    {
      id: agentTable.AVATAR.FILTER_NAME,
      title: agentTable.AVATAR.TITLE,
      cellType: cellTypes.AVATAR,
      hasSorting: false,
      width: 60,
      accessor: ({ profile_image_url, user }) => ({
        url: profile_image_url,
        icon: getUserIcon(user),
        style: avatarTableCellStyle
      })
    },
    {
      id: agentTable.ID.FILTER_NAME,
      title: agentTable.ID.TITLE,
      cellType: cellTypes.TEXT,
      width: 80,
      accessor: ({ id }) => ({
        hasHighlight: true,
        text: String(id)
      })
    },
    {
      id: agentTable.GIVEN_NAME.FILTER_NAME,
      title: agentTable.GIVEN_NAME.TITLE,
      cellType: cellTypes.TEXT,
      minWidth: 160,
      accessor: ({ given_name }) => ({
        hasHighlight: true,
        text: given_name
      })
    },
    {
      id: agentTable.FAMILY_NAME.FILTER_NAME,
      title: agentTable.FAMILY_NAME.TITLE,
      cellType: cellTypes.TEXT,
      minWidth: 120,
      accessor: ({ family_name }) => ({
        hasHighlight: true,
        text: family_name
      })
    },
    {
      id: agentTable.PHONE_NUMBER.FILTER_NAME,
      title: agentTable.PHONE_NUMBER.TITLE,
      cellType: cellTypes.TEXT,
      width: 120,
      accessor: ({ phone_number }) => ({
        hasHighlight: true,
        highlightType: 'phone_number',
        text: phone_number
      })
    },
    {
      id: agentTable.EMAIL.FILTER_NAME,
      title: agentTable.EMAIL.TITLE,
      cellType: cellTypes.TEXT,
      minWidth: 120,
      accessor: ({ email }) => ({
        text: email
      })
    },
    {
      id: agentTable.COMPANY_NAME.FILTER_NAME,
      title: agentTable.COMPANY_NAME.TITLE,
      cellType: cellTypes.TEXT,
      accessor: ({ company_name }) => ({
        text: company_name
      })
    },
    {
      id: agentTable.POSITION.FILTER_NAME,
      title: agentTable.POSITION.TITLE,
      cellType: cellTypes.TEXT,
      accessor: ({ position }) => ({
        text: position
      })
    }
  ];
};
