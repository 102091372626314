import { cellTypes } from 'common/enums/table';
import { providerTable } from '../enums';

export const getColumns = ({ page }) => [
  {
    id: providerTable.SKILL_NAME.COLUMN_NAME,
    title: providerTable.SKILL_NAME.TITLE,
    cellType: cellTypes.SKILLS,
    width: 60,
    accessor: (skill) => ({
      skill
    })
  }
];
