import moment from 'moment';
import dates from 'constants/dates';
import { MINUTES_IN_DAY, DEFAULT_VISIT_WINDOW, TIME_PICKER_MINUTES_STEP } from 'constants/visit';

export const generateTimesArray = () => {
  let data = [];
  const zeroTime = moment('00:00', dates.TIME_24);
  for (let i = 0; i < MINUTES_IN_DAY / TIME_PICKER_MINUTES_STEP; i++) {
    data.push({
      name: zeroTime.format(dates.TIME_PICKER_LABEL),
      id: i,
      value: zeroTime.format(dates.TIME_24)
    });
    zeroTime.add(TIME_PICKER_MINUTES_STEP, 'minutes');
  }
  return data;
};

export const getMinTime = () => {
  const time = moment().second(0).millisecond(0);
  if (time.minute() > 30) {
    time.add(1, 'hours').minute(0);
  } else {
    time.minute(30);
  }
  return time;
};

export const getStartTimeInitialId = ({ times, date }) => {
  const selectedTime = date ? moment(date).format(dates.TIME_24) : getMinTime().format(dates.TIME_24);
  return times.find(({ value }) => value === selectedTime)?.id;
};
