import { jobTypesByTab } from 'common/enums/job';
import {
  selectCustomerActiveJobs,
  selectCustomerActiveJobsCount,
  selectCustomerActiveJobsPage,
  selectCustomerCancelledJobs,
  selectCustomerCancelledJobsCount,
  selectCustomerCancelledJobsPage,
  selectCustomerCompletedJobs,
  selectCustomerCompletedJobsCount,
  selectCustomerCompletedJobsPage
} from 'store/reducers/customer';
import {
  selectProviderActiveJobs,
  selectProviderActiveJobsCount,
  selectProviderActiveJobsPage,
  selectProviderCancelledJobs,
  selectProviderCancelledJobsCount,
  selectProviderCancelledJobsPage,
  selectProviderCompletedJobs,
  selectProviderCompletedJobsCount,
  selectProviderCompletedJobsPage
} from 'store/reducers/provider';

export const getCustomerJobsSelectors = (type) => {
  switch (type) {
    case jobTypesByTab.ACTIVE:
      return {
        selectList: selectCustomerActiveJobs,
        selectTotalCount: selectCustomerActiveJobsCount,
        selectPage: selectCustomerActiveJobsPage
      };
    case jobTypesByTab.PAID:
      return {
        selectList: selectCustomerCompletedJobs,
        selectTotalCount: selectCustomerCompletedJobsCount,
        selectPage: selectCustomerCompletedJobsPage
      };
    case jobTypesByTab.CANCELLED:
      return {
        selectList: selectCustomerCancelledJobs,
        selectTotalCount: selectCustomerCancelledJobsCount,
        selectPage: selectCustomerCancelledJobsPage
      };
    default:
      return {
        selectList: selectCustomerActiveJobs,
        selectTotalCount: selectCustomerActiveJobsCount,
        selectPage: selectCustomerActiveJobsPage
      };
  }
};

export const getProviderJobsSelectors = (type) => {
  switch (type) {
    case jobTypesByTab.ACTIVE:
      return {
        selectList: selectProviderActiveJobs,
        selectTotalCount: selectProviderActiveJobsCount,
        selectPage: selectProviderActiveJobsPage
      };
    case jobTypesByTab.PAID:
      return {
        selectList: selectProviderCompletedJobs,
        selectTotalCount: selectProviderCompletedJobsCount,
        selectPage: selectProviderCompletedJobsPage
      };
    case jobTypesByTab.CANCELLED:
      return {
        selectList: selectProviderCancelledJobs,
        selectTotalCount: selectProviderCancelledJobsCount,
        selectPage: selectProviderCancelledJobsPage
      };
    default:
      return {
        selectList: selectProviderActiveJobs,
        selectTotalCount: selectProviderActiveJobsCount,
        selectPage: selectProviderActiveJobsPage
      };
  }
};
