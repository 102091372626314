import styled from 'styled-components';
import { INVOICE_FORM_MARGIN_RIGHT } from '../../constants/invoice';

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ marginBottom }) => marginBottom || 20}px;
  width: 100%;
`;

export const InvoiceAutofillWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;

  ${({ width }) => width && `width: ${width}px;`}
  ${({ isHidden }) => isHidden && 'display: none;'}
`;

export const InvoiceFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 20px 24px 40px 24px;
  background: ${({ theme }) => theme.colors.INVOICE_BG};
`;

export const MainHeading = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 1.14;
  color: ${({ theme }) => theme.colors.INVOICE_TEXT};
  margin: 0 0 14px 0;
`;

export const FieldName = styled.label`
  font-weight: 500;
  font-size: 12px;
  line-height: 1.17;
  color: ${({ theme }) => theme.colors.GRAY};
`;

export const SummaryWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 27px;
  padding-right: ${INVOICE_FORM_MARGIN_RIGHT}px;

  ${FieldName} {
    margin-bottom: 3px;
  }

  label {
    margin-right: 4px;
  }
`;

export const TipsRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 14px;
  align-items: center;
  padding-right: ${INVOICE_FORM_MARGIN_RIGHT}px;
`;

export const Heading = styled.div`
  color: ${({ theme }) => theme.colors.INVOICE_TEXT};
  font-weight: 700;
  font-size: 14px;
  line-height: 1.14;
  margin: 0 0 9px 0;
`;

export const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InvoiceTypeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 26px 0;

  ${({ isHidden }) => isHidden && 'display: none;'}
`;

export const MediaBeforeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 26px 0;
`;

export const dividerStyle = {
  marginTop: 8,
  marginBottom: 20
};

export const commissionDividerStyle = {
  marginBottom: 24
};
