import { all, takeLatest, put } from 'redux-saga/effects';
import actionTypes from 'constants/actionTypes';
import { getImageFromS3 } from 'utils';
import errors from 'constants/errors';

function* signServiceImageUrls(action) {
  const {
    payload: { media, serviceId }
  } = action;
  try {
    const mediaObjects = media.filter(({ media }) => media?.path).map(({ media: { path } }) => getImageFromS3(path));
    const mediaFiles = (yield all(mediaObjects)).filter((file) => !(file instanceof Error));
    const mediaBlobs = yield all(mediaFiles.map((media) => media.blob()));
    const urls = mediaBlobs.map(URL.createObjectURL);

    if (!urls) {
      throw new Error(errors.GET_EMPTY_MEDIA);
    }

    yield put({
      type: actionTypes.SIGN_SERVICE_IMAGE_URLS_SUCCESS,
      payload: {
        imageUrls: urls,
        serviceId
      }
    });
  } catch (err) {
    yield put({
      type: actionTypes.SIGN_SERVICE_IMAGE_URLS_ERROR
    });
  }
}

export default function* watchSignServiceImageUrls() {
  yield takeLatest(actionTypes.SIGN_SERVICE_IMAGE_URLS_FETCH, signServiceImageUrls);
}
