import { call, takeLatest, put } from 'redux-saga/effects';
import api_constants from 'constants/api';
import actionTypes from 'constants/actionTypes';
import errors from 'constants/errors';
import Api from 'services/ApiService';
import { mediaObjectTypes } from 'common/enums/app';

function* getTaskPaymentList({ payload: { serviceId } }) {
  try {
    const response = yield call(Api.amplifyGet, `${api_constants.PAYMENT}?desc_order&service_request_id=${serviceId}`);

    if (!response || !response?.results) {
      throw new Error(errors.DEFAULT);
    }

    yield put({
      type: actionTypes.GET_TASK_PAYMENT_LIST_SUCCESS,
      payload: response.results || []
    });

    yield put({
      type: actionTypes.GET_MEDIA_URLS_FOR_LIST_FETCH,
      payload: {
        instances: response.results,
        type: mediaObjectTypes.PAYMENT_IMAGES
      }
    });

    yield put({
      type: actionTypes.GET_MEDIA_URLS_FOR_LIST_FETCH,
      payload: {
        instances: response.results
          .map(({ note_media }) =>
            note_media.map(({ media, media_id, ...other }) => ({
              id: media_id,
              media: [{ ...other, media_id, media }]
            }))
          )
          .flat(),
        type: mediaObjectTypes.PAYMENT_NOTES
      }
    });
    yield put({
      type: actionTypes.GET_MEDIA_URL_FOR_LIST_FETCH,
      payload: {
        instances: response.results,
        type: mediaObjectTypes.PAYMENT_PDF,
        media_field: 'pdf_invoice_media'
      }
    });
  } catch (err) {
    yield put({
      type: actionTypes.GET_TASK_PAYMENT_LIST_ERROR
    });
  }
}

export default function* watchGetTaskPaymentList() {
  yield takeLatest(actionTypes.GET_TASK_PAYMENT_LIST_FETCH, getTaskPaymentList);
}
