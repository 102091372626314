import { call, takeLatest, put } from 'redux-saga/effects';

import Api from 'services/ApiService';
import api_constants from 'constants/api';
import actionTypes from 'constants/actionTypes';

function* updateReview({ payload }) {
  const { id } = payload;
  try {
    const response = yield call(Api.amplifyPut, `${api_constants.REVIEW}/${id}`, payload);

    if (response) {
      yield put({
        type: actionTypes.UPDATE_REVIEW_SUCCESS,
        payload: response
      });
    } else {
      throw new Error(response?.error_message);
    }
  } catch (err) {
    yield put({
      type: actionTypes.UPDATE_REVIEW_ERROR
    });
  }
}

export default function* watchUpdateReview() {
  yield takeLatest(actionTypes.UPDATE_REVIEW_FETCH, updateReview);
}
