import styled, { css } from 'styled-components';
import styles from 'constants/styles';

export const FlexColumn = css`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: calc(100vh - ${styles.layout.headerHeight}px - ${styles.layout.paginationContainerHeight}px);
`;

export const ConversationListContainer = styled.div`
  ${FlexColumn}
  border-right: 1px solid ${styles.colors.WHITE};
`;

export const SearchSection = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const SearchWrapper = styled.div`
  width: 100%;
  padding-right: 6px;
`;

export const TooltipText = styled.div`
  white-space: pre;
`;

export const ScrollContainer = styled.div`
  overflow: overlay;
  display: flex;
  flex-direction: column;
`;

export const ConversationWrapper = styled.div`
  ${FlexColumn}
  flex: 2;
  padding: 0;
`;

export const NoResultsHeader = styled.span`
  flex: 1;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.2;
  margin: 0 0 25px 0;
  color: ${({ theme }) => theme.colors.TEXT_DISABLED};
`;

export const LoadingContainer = styled.div`
  display: flex;
  flex: 1;
  padding: 50px;
  justify-content: center;
  text-align: center;
  position: relative;
`;
