import { invoiceItemTypes } from 'common/enums/invoice';

export const toValues = (invoice) => {
  const amount_flat_rate =
    invoice?.items
      .filter((item) => item.item_section === invoiceItemTypes.FLAT.TYPE)
      .reduce((total, item) => total + item.price, 0) / 100;
  return {
    id: invoice.id,
    commission_value: invoice.percent_commission ? invoice.percent_commission / 100 : 0,
    amount_material: invoice.amount_material ? invoice.amount_material / 100 : 0,
    amount_labor_cost: invoice.amount_labor_cost ? invoice.amount_labor_cost / 100 - amount_flat_rate : 0,
    amount_flat_rate: amount_flat_rate,
    amount_customer_reward: invoice.amount_customer_reward ? invoice.amount_customer_reward / 100 : 0,
    amount_customer_paid: invoice.amount_total_charge
      ? (invoice.amount_total_charge - invoice.amount_stripe_fee) / 100
      : '',
    datetime_paid: '',
    is_paid: true,
    amount_service_fee: (invoice.amount_service_fee || 0) / 100,
    percent_stripe_fee: (invoice.percent_stripe_fee || 0) / 100,
    is_transfered: false,
    is_apply_dobby_cash: false,
    payment_note: ''
  };
};
