import React from 'react';

import { useDispatch, useSelector, useState, useRef, useEffect, useCallback } from 'hooks';
import modalTypes from 'constants/modalTypes';
import { closeModal, getProviderList, getServiceProAssignBundle } from 'store/actions';
import { selectServiceProAssignBundleLoading, selectServiceProAssignBundle } from 'store/reducers/service';
import { selectProviderDashboard, selectProviderDashboardLoading } from 'store/reducers/provider';
import { debounceWithPromise } from 'utils';

import { defaultProvidersRouteParams } from './enums';
import { Map, ProviderList, Header, CustomerPopup } from '../../components';
import { selectFilteredGeoLocationList } from 'store/reducers/app';

import { ProviderListContainer, ProviderListWrapper, FlexContainer, JobDetailsContainer } from './styled';

export const AssignProviderContainer = ({ onSetConfirmationModal, jobId }) => {
  const dispatch = useDispatch();
  const scrollRef = useRef();

  const jobLoading = useSelector(selectServiceProAssignBundleLoading);
  const job = useSelector(selectServiceProAssignBundle);
  const providers = useSelector(selectProviderDashboard);
  const providersLoading = useSelector(selectProviderDashboardLoading);

  // State
  const [hoveredProId, setHoveredProId] = useState(null);
  const [hoveredVisit, setHoveredVisit] = useState(null);
  const [selectedProvider, setSelectedProvider] = useState({});
  const [selectedVisitId, setSelectedVisitId] = useState(null);
  const [listHeaderCategory, setListHeaderCategory] = useState(job.job_category_name);

  // Effects
  useEffect(() => {
    dispatch(
      getProviderList({
        ...defaultProvidersRouteParams,
        service_job: jobId
      })
    );
    dispatch(getServiceProAssignBundle({ task_id: jobId }));
  }, [jobId, dispatch]);

  useEffect(() => {
    setListHeaderCategory(job.job_category_name);
  }, [job.job_category_name]);

  const handleProviderSelect = useCallback(
    (id) => {
      const provider = providers.find((pro) => pro.id === id);
      if (!provider?.upcoming_events?.length) {
        return;
      }

      let visitId = null;
      if (provider?.upcoming_events?.length === 1) {
        visitId = provider?.upcoming_events[0].visit_id;
      }

      setSelectedVisitId(visitId);
      setSelectedProvider(provider);
    },
    [providers]
  );

  const handleProviderDeselect = () => {
    setSelectedProvider({});
    setSelectedVisitId(null);

    scrollRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    });
  };

  const handleDeselectVisit = () => {
    setSelectedVisitId(null);
  };
  const [searchText, setSearchText] = useState('');
  const handleSearch = debounceWithPromise(setSearchText, 500);
  const geoLocation = useSelector(selectFilteredGeoLocationList);

  useEffect(() => {
    if (searchText) {
      setListHeaderCategory('');
      handleProviderDeselect();
    } else {
      setListHeaderCategory(job.job_category_name);
    }

    dispatch(
      getProviderList({
        ...defaultProvidersRouteParams,
        search: searchText,
        service_job: jobId,
        ...(searchText ? { skip_category_filtering: true } : {})
      })
    );
  }, [searchText, geoLocation, jobId, job.job_category_name, dispatch]);

  const handleCloseAssignModal = useCallback(() => {
    dispatch(closeModal(modalTypes.ASSIGN_PROVIDER_MAP));
  }, [dispatch]);

  const handleChangeHoveredProId = useCallback(
    (providerId) => {
      setHoveredProId(providerId);
    },
    [setHoveredProId]
  );

  const handleChangeHoveredVisit = useCallback(
    ({ visit, isReset }) => {
      setHoveredVisit((currentVisit) => {
        if (!isReset) {
          return visit;
        }
        if (isReset && currentVisit?.visit_id === visit?.visit_id) {
          return null;
        }
      });
    },
    [setHoveredVisit]
  );

  return (
    <FlexContainer>
      <JobDetailsContainer>
        <CustomerPopup job={job} jobLoading={jobLoading} media={job?.media} closeModal={handleCloseAssignModal} />
        <Map
          job={jobLoading ? null : job}
          selectedProvider={selectedProvider}
          selectedVisitId={selectedVisitId}
          setSelectedVisitId={setSelectedVisitId}
          providersLoading={providersLoading}
          providers={providers}
          onCloseAssignModal={handleCloseAssignModal}
          hoveredProId={hoveredProId}
          onProviderSelect={handleProviderSelect}
          onChangeHoveredVisit={handleChangeHoveredVisit}
        />
      </JobDetailsContainer>
      <ProviderListContainer>
        <Header
          selectedProvider={selectedProvider}
          selectedVisitId={selectedVisitId}
          onRemoveProvider={handleProviderDeselect}
          hasResetVisit={Boolean(selectedVisitId) && selectedProvider?.upcoming_events?.length > 1}
          onRemoveVisit={handleDeselectVisit}
          onSearch={handleSearch}
        />
        <ProviderListWrapper>
          <div ref={scrollRef}>
            <ProviderList
              headerCategory={listHeaderCategory}
              providers={providers}
              providersLoading={providersLoading}
              selectedProvider={selectedProvider}
              selectedVisitId={selectedVisitId}
              onProviderSelect={handleProviderSelect}
              hoveredVisit={hoveredVisit}
              onSetConfirmationModal={onSetConfirmationModal}
              onChangeHoveredProId={handleChangeHoveredProId}
            />
          </div>
        </ProviderListWrapper>
      </ProviderListContainer>
    </FlexContainer>
  );
};
