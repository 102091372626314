import React from 'react';

import { useCallback } from 'hooks';
import { LoadingSpinner } from 'components';

import { ProviderItem } from './components';
import { Header, Label, LoadingContainer, ProviderListSubheader } from './styled';

const ProviderList = ({
  headerCategory,
  hoveredVisit,
  selectedProvider,
  selectedVisitId,
  providers,
  providersLoading,
  onProviderSelect,
  onSetConfirmationModal,
  onChangeHoveredProId
}) => {
  const handleAssignClick = useCallback(
    (provider) => {
      onSetConfirmationModal({ visible: true, modalData: { provider } });
    },
    [onSetConfirmationModal]
  );

  const handleToggleHover = ({ isHovered, providerId }) => {
    onChangeHoveredProId(isHovered ? providerId : null);
  };

  if (providersLoading) {
    return (
      <LoadingContainer>
        <LoadingSpinner color={'dark'} />
      </LoadingContainer>
    );
  }

  if (!providers.length) {
    return (
      <LoadingContainer>
        <Header fontSize={18} fontWeight={600}>
          No providers found.
        </Header>
      </LoadingContainer>
    );
  }

  const { topProviders, otherProviders } = providers.reduce(
    (acc, cur) => {
      cur?.is_top_provider ? acc.topProviders.push(cur) : acc.otherProviders.push(cur);
      return acc;
    },
    { topProviders: [], otherProviders: [] }
  );

  const hasTwoSections = Boolean(topProviders.length && otherProviders.length && headerCategory);

  return (
    <>
      {hasTwoSections && (
        <ProviderListSubheader>
          <Label>Top {headerCategory ? `${headerCategory} ` : ''}Pros</Label>
        </ProviderListSubheader>
      )}
      {topProviders.map((pro) => (
        <ProviderItem
          isSelected={pro.id === selectedProvider?.id}
          isVisitHovered={pro.id === hoveredVisit?.service_provider_id}
          key={`pro_card_${pro.id}`}
          {...pro}
          onAssignClick={handleAssignClick}
          onCardClick={onProviderSelect}
          onToggleHover={handleToggleHover}
        />
      ))}
      {hasTwoSections && (
        <ProviderListSubheader>
          <Label>Other {headerCategory ? `${headerCategory} ` : ''}Pros</Label>
        </ProviderListSubheader>
      )}
      {otherProviders.map((pro) => (
        <ProviderItem
          isSelected={pro.id === selectedProvider?.id}
          isSomeVisitSelected={Boolean(selectedVisitId)}
          isVisitHovered={pro.id === hoveredVisit?.service_provider_id}
          key={`pro_card_${pro.id}`}
          {...pro}
          onAssignClick={handleAssignClick}
          onCardClick={onProviderSelect}
          onToggleHover={handleToggleHover}
        />
      ))}
    </>
  );
};

export default ProviderList;
