import styled from 'styled-components';
import styles from 'constants/styles';

export const LoadingContainer = styled.div`
  display: flex;
  flex: 1;
  padding: 50px;
  justify-content: center;
  text-align: center;
  position: relative;
  height: 100%;
`;

export const AvatarLabel = styled.label`
  cursor: pointer;

  border-radius: 50%;
`;
export const Flex = styled.div`
  display: flex;
  ${({ gap }) => gap && `gap: ${gap}`};
`;

export const Grid = styled.div`
  display: grid;
  ${({ gap }) => gap && `gap: ${gap}`};
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.DARK_3};
  padding: 24px;
  width: 100%;
`;

export const CompanyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
`;

export const ContentRowWrapper = styled.div`
  display: flex;
  flex: 1;
`;

export const ContentColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 24px 24px;
`;

export const FieldTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 1.7;
  color: ${styles.colors.GRAY};
  ${({ height }) => height && `height: ${height}px`};
  ${({ width }) => width && `width: ${width}%`};
  ${({ alignItems }) =>
    alignItems &&
    `
    display: flex;
    align-items: center;
  `};

  ${({ withoutMargin }) => !withoutMargin && 'margin-bottom: 5px'};

  padding: 0 18px 0 0;
  white-space: break-spaces;
`;

export const FlexRow = styled.div`
  display: flex;
  flex: 1;
  padding: 13px 0 5px;
  gap: 20px;
  &:first-child {
    padding: 18px 0 5px;
  }
`;

export const InvisibleInput = styled.input`
  display: none;
`;
export const Buttons = styled.div`
  display: flex;
  align-items: center;
`;
export const LogoLabel = styled.label`
  cursor: pointer;
  margin-top: 3px;
`;

export const externalAuthorAreaStyle = {
  borderRadius: 8
};

export const lineStyle = {
  marginTop: 32,
  marginBottom: 32
};

export const logoBackgroundStyle = {
  backgroundColor: styles.colors.LIGHT_GRAY_2
};
