import styled, { createGlobalStyle } from 'styled-components';
import styles from 'constants/styles';
import '@aws-amplify/ui-react/styles.css';

import RobotoLight from 'static/fonts/Roboto-Light.ttf';
import RobotoLightItalic from 'static/fonts/Roboto-LightItalic.ttf';
import RobotoRegular from 'static/fonts/Roboto-Regular.ttf';
import RobotokRegularItalic from 'static/fonts/Roboto-Italic.ttf';
import RobotoBold from 'static/fonts/Roboto-Bold.ttf';

import RobotokBoldItalic from 'static/fonts/Roboto-BoldItalic.ttf';
import OpenSansSemiBold from 'static/fonts/OpenSans-SemiBold.ttf';
import OpenSansSemiBoldItalic from 'static/fonts/OpenSans-SemiBoldItalic.ttf';
import OpenSansExtraBold from 'static/fonts/OpenSans-ExtraBold.ttf';
import OpenSansExtraBoldItalic from 'static/fonts/OpenSans-ExtraBoldItalic.ttf';

export const GlobalStyle = createGlobalStyle`
    @font-face {
        font-family: Roboto;
        src: url(${RobotoLight}) format('truetype');
        font-weight: 100 300;
        font-style: normal;
    }
    @font-face {
        font-family: Roboto;
        src: url(${RobotoLightItalic}) format('truetype');
        font-weight: 100 300;
        font-style: italic;
    }
    @font-face {
        font-family: Roboto;
        src: url(${RobotoRegular}) format('truetype');
        font-weight: 400 500;
        font-style: normal;
    }
    @font-face {
        font-family: Roboto;
        src: url(${RobotokRegularItalic}) format('truetype');
        font-weight: 400 500;
        font-style: italic;
    }

    @font-face {
        font-family: Roboto;
        src: url(${RobotoBold}) format('truetype');
        font-weight: 700;
        font-style: normal;
    }
    @font-face {
        font-family: Roboto;
        src: url(${RobotokBoldItalic}) format('truetype');
        font-weight: 700;
        font-style: italic;
    }
    @font-face {
        font-family: Roboto;
        src: url(${OpenSansSemiBold}) format('truetype');
        font-weight: 600;
        font-style: normal;
    }
    @font-face {
        font-family: Roboto;
        src: url(${OpenSansSemiBoldItalic}) format('truetype');
        font-weight: 600;
        font-style: italic;
    }
    @font-face {
        font-family: Roboto;
        src: url(${OpenSansExtraBold}) format('truetype');
        font-weight: 800;
        font-style: normal;
    }
    @font-face {
        font-family: Roboto;
        src: url(${OpenSansExtraBoldItalic}) format('truetype');
        font-weight: 800;
        font-style: italic;
    }

    * {
      box-sizing: border-box;
    }

    img {
        max-width: 100%;
        border: 0;
        vertical-align: middle;
    }
    body {
      font-family: 'Roboto', sans-serif;
      margin: 0;
      color: ${styles.colors.DARK_BLUE};
      line-height: 1.2;
      font-size: 14px;
      background-color: ${({ theme }) => theme.colors.BODY};

    }
    p {
        font-family: Roboto, sans-serif;
        margin-top: 0;
        font-size: 18px;
        line-height: 150%;
        font-weight: normal;
        color: ${styles.colors.BLACK};
    }
    input, span, select, textarea {
      font-family: 'Roboto', sans-serif;
    }
    pre {
      font-family: 'Roboto', sans-serif;
      margin: 0;
      text-align: start;
    }

    h1, h2, h3, h4, h5 {
      font-family: 'Roboto', sans-serif;
      margin-bottom: 10px;
    }

    h2 {
      font-size: 30px;
      line-height: 120%;
      font-weight: 800;
      color: ${styles.colors.DARK_BLUE};
      margin-top: 0;

      @media screen and (min-width: 768px) {
          font-size: 32px;
      }
      @media screen and (min-width: 1024px) {
          font-size: 35px;
      }
      @media screen and (min-width: 1280px) {
          font-size: 40px;
      }
    }

    h3 {
      font-size: 24px;
      line-height: 32px;
      font-weight: bold;

      @media screen and (min-width: 768px) {
        font-size: 28px;
        line-height: 48px;
      }
      @media screen and (min-width: 1024px) {
        font-size: 30px;
        line-height: 54px;
      }
      @media screen and (min-width: 1280px) {
        font-size: 32px;
        line-height: 60px;
      }
    }
    a {
      font-family: 'Roboto', sans-serif;
      text-decoration: none;
      &[disabled] {
        cursor: default;
      }
    }
    strong {
      font-family: 'Roboto', sans-serif;
    }
    textarea {
      cursor: auto;
    }
    button {
      cursor: pointer;
      background-color: unset;
      font-family: 'Roboto', sans-serif;
      transition: all 0.3s ease;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      border: unset;
      box-shadow: none;
      outline: 0;
      &:focus {
        outline: 0;
      }
      &:disabled {
        cursor: default;
      }
    }

    // hide close button & triangle at InfoWindow on the map
    button.gm-ui-hover-effect, .gm-style-iw-tc {
      visibility: hidden;
    }

    div.gm-style-iw-c, div.gm-style-iw-d {
      max-height: calc(50vh - 100px) !important;
      overflow-y: overlay !important;
      max-width: 534px !important;
      padding: 0 !important;
      overflow-x: hidden !important;
    }
  
    .gm-style iframe + div { border:none!important; }

    ::-webkit-scrollbar-corner { 
      opacity: 0;
    }
    ::-webkit-scrollbar {
      width: 0px;
      height: 0px;            
    }
   ::-webkit-scrollbar {
      width: 10px;
      height: 10px;            
    }
    
    ::-webkit-scrollbar-track {
      background: ${({ theme }) => theme.colors.DARK_1};        
    }
    
    ::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) => theme.colors.DARK_GREY_2};
      &:hover {
        background-color: ${({ theme }) => theme.colors.DARK_GREY}; 
      }
      border-radius: 20px;       
      border: 3px solid ${({ theme }) => theme.colors.DARK_1};  
    }
`;

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;

  & [data-amplify-theme] {
    width: 100%;
    height: 100vh;
  }
  & [data-amplify-authenticator][data-variation='modal'] {
    padding-bottom: 20vh;
  }
`;
