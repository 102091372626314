import styled, { css } from 'styled-components';

export const AbsoluteWrapper = styled.div`
  position: absolute;
  right: -28px;
  top: 0;
  align-self: flex-start;
`;

export const Info = styled.div`
  display: flex;
  align-items: center;
`;

export const bullet = css`
  border-radius: 50%;
  background: #20292d;
  width: 3px;
  height: 3px;
`;

export const Bullet = styled.div`
  ${bullet}
  margin 0 6px;
`;

export const SubBullet = styled.div`
  ${bullet}
  margin 0 6px 0 20px;
`;
