// React
import React from 'react';

import { IconTooltip } from '../../../../components';

import { TitleRow, TitleWrapper, Title } from './styled';

const Header = ({ totalName, tooltip }) => {
  return (
    <TitleRow>
      <TitleWrapper key={`header-item-${totalName}`}>
        <Title withTooltip={Boolean(tooltip)}>{totalName}</Title>
        {Boolean(tooltip) && <IconTooltip title={tooltip} />}
      </TitleWrapper>
    </TitleRow>
  );
};

export { Header };
