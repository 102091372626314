import React from 'react';
import { connect } from 'react-redux';

import { selectModalType } from 'store/reducers/modal';
import modalTypes from 'constants/modalTypes';

import { Confirmation, Error, InnerHtml, Result, VideoPlayer } from './common';
import { OwnerReport } from './analytics';
import { UploadReport, QuestionDetails } from './customer';
import {
  ApproveQuotation,
  CancelInvoiceFormConfirmation,
  DeleteInvoiceConfirmation,
  DetachRewardFromInvoice,
  InvoiceConfirmation,
  PayoutConfirmation,
  CloseInvoice,
  CloseConfirmation,
  PaymentDate,
  ValidateInvoice
} from './invoice';

import { ExecutionDate, TestEvent, DiscardChanges, CampaignCreationConfirmation } from './campaigns';

import { ClearAllNotificationsConfirmation, ClearAllNotificationsResult } from './notification';

import { PromoCode, RewardCreate, ExpirePromoCode, ManageReward } from './reward';
import Timezone from './system/Timezone/Timezone';
import { TwilioVoice } from './twilio';
import { UserDetailsEditModal } from './user';

import {
  AssignTaskOwner,
  AssignProvider,
  BundleJobs,
  BundleResult,
  CancelTask,
  CloseSilently,
  CloseSilentlyResult,
  CloseSilentlyWithPayout,
  CloseSilentlyFinal,
  DeleteNote,
  DuplicateTask,
  ReopenTask,
  Task,
  UnbundleTask,
  SnoozeTask,
  ToggleJobChat,
  UnassignProviderConfirmation,
  UnassignProviderResult
} from './task';

import { CancelVisit, ScheduleVisit } from './visit';
import { DeleteMessage } from './chat';
import { CreateSkill, UpdateSkill } from './provider';
// TODO: (refactoring) add modalData as props here
const modals = {
  // analytics
  [modalTypes.OWNER_REPORT]: <OwnerReport />,

  // chat
  [modalTypes.DELETE_CHAT_MESSAGE]: <DeleteMessage />,

  // common
  [modalTypes.CONFIRMATION]: <Confirmation />,
  [modalTypes.ERROR]: <Error />,
  [modalTypes.INNER_HTML]: <InnerHtml />,
  [modalTypes.RESULT]: <Result />,
  [modalTypes.VIDEO_PLAYER]: <VideoPlayer />,

  // campaigns
  [modalTypes.CAMPAIGN_CREATION_CONFIRMATION]: <CampaignCreationConfirmation />,
  [modalTypes.DISCARD_CHANGES]: <DiscardChanges />,
  [modalTypes.EXECUTION_DATE]: <ExecutionDate />,
  [modalTypes.TEST_EVENT]: <TestEvent />,

  // invoice
  [modalTypes.APPROVE_QUOTATION]: <ApproveQuotation />,
  [modalTypes.CANCEL_INVOICE_FORM_CONFIRMATION]: <CancelInvoiceFormConfirmation />,
  [modalTypes.DELETE_INVOICE_CONFIRMATION]: <DeleteInvoiceConfirmation />,
  [modalTypes.DETACH_REWARD_FROM_INVOICE]: <DetachRewardFromInvoice />,
  [modalTypes.CREATE_INVOICE_CONFIRMATION]: <InvoiceConfirmation />,
  [modalTypes.PAYOUT_CONFIRMATION]: <PayoutConfirmation />,
  [modalTypes.UPLOAD_INSPECTION_REPORT]: <UploadReport />,
  [modalTypes.CLOSE_INVOICE_CONFIRMATION]: <CloseConfirmation />,
  [modalTypes.CLOSE_INVOICE]: <CloseInvoice />,
  [modalTypes.PAYMENT_DATE]: <PaymentDate />,
  [modalTypes.VALIDATE_INVOICE]: <ValidateInvoice />,

  // notification
  [modalTypes.CLEAR_ALL_PERSISTENT_NOTIFICATIONS]: <ClearAllNotificationsConfirmation />,
  [modalTypes.CLEAR_ALL_PERSISTENT_NOTIFICATIONS_RESULT]: <ClearAllNotificationsResult />,

  // reward
  [modalTypes.PROMO_CODE]: <PromoCode />,
  [modalTypes.REWARD]: <RewardCreate />,
  [modalTypes.EXPIRE_PROMO_CODE]: <ExpirePromoCode />,
  [modalTypes.MANAGE_REWARD]: <ManageReward />,

  // system
  [modalTypes.TIMEZONE]: <Timezone />,

  // task
  [modalTypes.ASSIGN_TASK_OWNER]: <AssignTaskOwner />,
  [modalTypes.ASSIGN_PROVIDER_MAP]: <AssignProvider />,
  [modalTypes.BUNDLE_SERVICES]: <BundleJobs />,
  [modalTypes.BUNDLE_SERVICES_RESULT]: <BundleResult />,
  [modalTypes.CANCEL_TASK_REASON]: <CancelTask />,
  [modalTypes.CLOSE_TASK_SILENTLY]: <CloseSilently />,
  [modalTypes.CLOSE_TASK_SILENTLY_WITH_PAYOUT]: <CloseSilentlyWithPayout />,
  [modalTypes.CLOSE_TASK_SILENTLY_FINAL]: <CloseSilentlyFinal />,
  [modalTypes.CLOSE_TASK_SILENTLY_RESULT]: <CloseSilentlyResult />,
  [modalTypes.DELETE_NOTE]: <DeleteNote />,
  [modalTypes.DUPLICATE_TASK]: <DuplicateTask />,
  [modalTypes.REOPEN_TASK]: <ReopenTask />,
  [modalTypes.TASK]: <Task />,
  [modalTypes.UNBUNDLE_TASK]: <UnbundleTask />,
  [modalTypes.SNOOZE_TASK]: <SnoozeTask />,
  [modalTypes.TOGGLE_JOB_CHAT]: <ToggleJobChat />,
  [modalTypes.UNASSIGN_PROVIDER]: <UnassignProviderConfirmation />,
  [modalTypes.UNASSIGN_PROVIDER_RESULT]: <UnassignProviderResult />,

  // twilio
  [modalTypes.TWILIO_VOICE]: <TwilioVoice />,

  // visit
  [modalTypes.CANCEL_VISIT]: <CancelVisit />,
  [modalTypes.CANCEL_VISIT_RESULT]: <CancelVisit />,
  [modalTypes.SCHEDULE_VISIT]: <ScheduleVisit />,

  // provider
  [modalTypes.UPDATE_SKILL]: <UpdateSkill />,
  [modalTypes.CREATE_SKILL]: <CreateSkill />,

  //customer
  [modalTypes.QUESTION_DETAILS]: <QuestionDetails />,

  // user
  [modalTypes.EDIT_USER]: <UserDetailsEditModal />
};

const Modals = ({ modalType }) => {
  if (!modals[modalType]) {
    return null;
  }

  return modals[modalType];
};

const mapStateToProps = (state) => ({
  modalType: selectModalType(state)
});

export default connect(mapStateToProps)(Modals);
