import { call, takeLatest, put } from 'redux-saga/effects';
import api_constants from 'constants/api';
import actionTypes from 'constants/actionTypes';
import Api from 'services/ApiService';
import { openModal, getUserNoteList, getCustomerDobbyCash, getCustomerDobbyCashTransactions } from 'store/actions';
import modalTypes from 'constants/modalTypes';
import { formatters } from 'utils';
import errors from 'constants/errors';

function* manageCustomerReward({ payload: { hasNotification, hasRewardReduce, ...payload } }) {
  try {
    const response = yield call(
      Api.amplifyPost,
      `${hasRewardReduce ? api_constants.DOBBY_CASH_REDUCE : api_constants.DOBBY_CASH_TOPUP}`,
      payload
    );
    yield put({
      type: actionTypes.MANAGE_CUSTOMER_REWARD_SUCCESS,
      payload: response
    });
    yield put(
      openModal(modalTypes.RESULT, {
        title: `$${formatters.commaSeparated(payload.amount_charge / 100)} Dobby Reward has been ${
          hasRewardReduce ? 'removed' : 'added'
        }`,
        text: hasNotification ? 'The homeowner will be notified.' : ''
      })
    );
  } catch (err) {
    yield put(
      openModal(modalTypes.ERROR, {
        errorMessage: err?.message || err?.name || errors.DEFAULT
      })
    );
    console.log(err);
  } finally {
    yield put(getUserNoteList({ userId: payload.user_id }));
    yield put(getCustomerDobbyCash(payload.user_id));
    yield put(getCustomerDobbyCashTransactions(payload.user_id));
  }
}

export default function* watchManageCustomerReward() {
  yield takeLatest(actionTypes.MANAGE_CUSTOMER_REWARD_FETCH, manageCustomerReward);
}
