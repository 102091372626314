import styled from 'styled-components';
import styles from 'constants/styles';
import { INVOICE_FORM_MARGIN_RIGHT } from '../../../../constants/invoice';

export const ButtonRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 30px 0 0 0;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${({ marginTop }) => marginTop ?? 10}px;
  margin-right: ${INVOICE_FORM_MARGIN_RIGHT}px;

  color: ${({ secondary, theme }) => (secondary ? theme.colors.DARK_GREY : theme.colors.INVOICE_TEXT)};
`;

export const Title = styled.label`
  font-weight: 500;
  font-size: 12px;
  line-height: 1.17;
`;

export const ValueWrapper = styled.div`
  display: flex;
  justify-content: end;
  font-weight: 700;
  ${({ accent }) => accent && `color: ${styles.colors.GREEN}`};
`;

export const Value = styled(Title)`
  padding: 0 0 0 15px;
`;

export const Separator = styled.div`
  width: 14px;
`;

export const dividerStyle = {
  marginTop: 12,
  marginBottom: 12
};
