import React from 'react';

import routes from 'constants/routes';

import { getTimeWindow } from 'common/helpers/job';

import { IconButton, Icon } from 'components';
import { useTheme } from 'styled-components';

import { PopupContainer, VisitDate, Address, JobLink, iconStyles, SpaceBetweenRow, Row } from './styled';

const VisitPopup = ({ job_id, address, datetime_visit_start, datetime_visit_end, onCloseVisitPopup }) => {
  const scheduledWindow = getTimeWindow({
    datetime_visit: datetime_visit_start,
    datetime_window_end: datetime_visit_end
  });

  const onLinkClick = (e) => {
    if (!job_id) {
      e.preventDefault();
    }
  };
  const theme = useTheme();
  return (
    <PopupContainer>
      <SpaceBetweenRow>
        <Address>{address}</Address>
        <IconButton iconName="close2" iconSize={9.5} onClick={onCloseVisitPopup} />
      </SpaceBetweenRow>
      <Row>
        <VisitDate>{scheduledWindow}</VisitDate>
        <JobLink
          rel="noopener noreferrer"
          target="_blank"
          to={`${routes.SERVICE}/${job_id}`}
          disabled={!job_id}
          onClick={onLinkClick}>
          <span>View Job</span>

          <Icon style={iconStyles} name="arrow" backgroundSize={14} rotate={270} size={10} color={theme.colors.WHITE} />
        </JobLink>
      </Row>
    </PopupContainer>
  );
};

export default VisitPopup;
