import React from 'react';

import { useEffect, useDispatch, useMemo, useRouter, useSelector } from 'hooks';
import { CampaignDetails, NotExist, LoadingSpinner } from 'components';
import { selectCampaignById, selectHasCampaignLoading } from 'store/reducers/campaigns';

import { getCampaignById } from 'store/actions';
import { MoreActions } from './containers';
import { LoadingContainer, StaticContainer } from './styled';

export const CampaignPage = () => {
  const {
    params: { campaign_id }
  } = useRouter();
  const campaignId = useMemo(() => Number(campaign_id), [campaign_id]);
  const campaign = useSelector((state) => selectCampaignById(state, campaignId));
  const loading = useSelector(selectHasCampaignLoading);

  const dispatch = useDispatch();

  useEffect(() => {
    if (campaign_id) {
      dispatch(getCampaignById({ campaignId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignId]);

  if (campaign.id !== campaignId && loading) {
    return (
      <LoadingContainer>
        <LoadingSpinner text="Please wait" type="logo" />
      </LoadingContainer>
    );
  }

  if (!campaign.id) {
    return <NotExist name="Campaign" id={campaignId} />;
  }

  return (
    <StaticContainer>
      {Boolean(campaign?.id) && (
        <CampaignDetails
          values={campaign}
          article={campaign.article}
          articleServiceAreaList={campaign.article?.service_areas?.map(({ name }) => name)}
          campaignPush={campaign.campaign_push}
          cohortType={campaign.cohort_type}
          execution={campaign.campaign_execute}
          executionServiceAreaList={campaign.campaign_execute?.service_areas.map(({ name }) => name)}
          hasExecuted={campaign.campaign_execute?.is_executed}
          renderActions={() => <MoreActions campaignId={campaignId} />}
        />
      )}
    </StaticContainer>
  );
};
