import styled, { css } from 'styled-components';
import styles from 'constants/styles';
import { NavLink } from 'react-router-dom';

export const ProviderCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  padding: 5px 24px 0 24px;

  ${({ disabled }) => !disabled && 'cursor: pointer;'}
  justify-content: flex-start;
  ${({ isHighlighted, theme }) =>
    isHighlighted &&
    `
    background-color: ${theme.colors.WHITE}33;
  `};

  &:hover {
    background-color: ${({ theme }) => theme.colors.WHITE}33;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 24px 0 8px;
`;

export const HeaderSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 3px 0 0;
`;

export const BadgeSection = styled.div`
  color: ${({ theme }) => theme.colors.TEXT};
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  margin: 3px 0 0 0;
`;

export const PillsSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
`;

export const StatusNameBlock = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

export const DotWrapper = styled.div`
  margin: 5px;
  justify-content: center;
`;

export const Dot = styled.span`
  height: ${({ size }) => size || 12}px;
  width: ${({ size }) => size || 12}px;
  background-color: ${({ backgroundColor, isVisible }) =>
    isVisible ? backgroundColor || styles.colors.GRAY : 'rgba(0,0,0,0.0)'};
  border-radius: 50%;
  display: inline-block;
`;

export const LabelStyle = css`
  font-weight: 400;
  font-size: 20px;
  line-height: 1.2;
  color: ${({ color, theme }) => color || theme.colors.TEXT};
`;

export const Label = styled.label`
  margin: 0 24px;
  ${LabelStyle}
`;

export const PositiveReviews = styled.label`
  ${LabelStyle}
  margin-right: 10px;
`;

export const ProviderNavLink = styled(NavLink)`
  ${LabelStyle}
  margin-right: 10px;
  color: ${({ color, theme }) => color || theme.colors.TEXT}};
  &:hover {
    text-decoration: underline;
  }
`;

export const Divider = styled.div`
  background-color: ${({ theme }) => theme.colors.DARK_GREY_3};
  height: 1px;
  width: 100%;
`;

export const Pill = styled.div`
  padding: 4px 16px;
  background: ${({ theme }) => theme.colors.DARK_2};
  border-radius: 20px;
  font-size: 12px;
  color: ${({ theme, color }) => color || theme.colors.WHITE};
  margin: 0 8px 3px 0;
`;

export const Footer = styled.div`
  margin: 16px 0 16px 0;
  color: ${({ theme }) => theme.colors.WHITE};
`;

export const FooterInfo = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.WHITE};
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const TooltipText = styled(FooterInfo)`
  &:hover {
    color: ${({ theme }) => theme.colors.DARK_GREEN};
  }
  cursor: pointer;
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
  margin-right: 10px;
`;

export const FooterMain = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ProLabel = styled.div`
  font-weight: 700;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.DARK_1};
  background: ${({ theme, isCore }) => (isCore ? theme.colors.DARK_YELLOW : theme.colors.WHITE)};
  padding: 4px 8px;
  border-radius: 4px;
  margin-right: 10px;
`;

export const DropDownWrapper = styled.div`
  margin-bottom: 5px;
`;
