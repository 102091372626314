import { useCallback, useDispatch, useReloadTable, useRouter } from 'hooks';
import { defaultParams } from 'common/enums/queryParams';
import { getExtendedDefaultQueryParams } from 'common/helpers/queryParams';
import { setTestTasksVisibility } from 'store/actions';

const useTestFilter = () => {
  const { queryParams, pathname, navigate, location } = useRouter();
  const filters = queryParams.getAll();
  const dispatch = useDispatch();
  const { handleReloadTable } = useReloadTable();

  const handleToggleTestFilter = useCallback(
    (event) => {
      dispatch(setTestTasksVisibility(!event.target.checked));

      const queryParams = {
        ...filters,
        [defaultParams.PAGE.NAME]: defaultParams.PAGE.DEFAULT_VALUE
      };

      const locationSearch = filters.page === '1' ? location.search : getExtendedDefaultQueryParams(queryParams);
      if (filters.page !== '1') {
        navigate(`${pathname}?${locationSearch}`, {
          replace: true
        });
      }

      handleReloadTable({ newRequestFilters: queryParams, hasUpdateNotificationList: true, locationSearch });
    },
    [dispatch, filters, handleReloadTable, navigate, pathname, location.search]
  );

  return handleToggleTestFilter;
};

export default useTestFilter;
