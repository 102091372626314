import { call, takeEvery, put } from 'redux-saga/effects';
import { refreshTaskInfo } from 'store/actions/service';
import { openModal } from 'store/actions/modal';
import api_constants from 'constants/api';
import actionTypes from 'constants/actionTypes';
import errors from 'constants/errors';
import modalTypes from 'constants/modalTypes';
import Api from 'services/ApiService';

function* unbundleTask({ payload: id }) {
  try {
    const response = yield call(Api.amplifyPost, `${api_constants.SERVICE}/${id}${api_constants.UNBUNDLE}`);

    if (!response || response?.err) {
      throw new Error(errors.DEFAULT);
    }

    yield put({
      type: actionTypes.UNBUNDLE_TASK_SUCCESS,
      payload: response
    });

    yield put(
      openModal(modalTypes.RESULT, {
        title: `Job is unbundled!`
      })
    );

    yield put(refreshTaskInfo({ task_id: id, silent: true, isAllInfo: true }));
  } catch (err) {
    yield put({
      type: actionTypes.UNBUNDLE_TASK_ERROR
    });
    yield put(
      openModal(modalTypes.ERROR, {
        errorMessage: errors.DEFAULT
      })
    );
    console.log(err);
  }
}

export default function* watchUnbundleTask() {
  yield takeEvery(actionTypes.UNBUNDLE_TASK_FETCH, unbundleTask);
}
