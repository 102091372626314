import styled, { css } from 'styled-components';

import styles from 'constants/styles';
import { DataRow } from '../styled';

export const AssignPlaceholder = styled.div`
  color: ${styles.colors.LIGHT_GRAY};
  font-weight: 600;
  font-size: 20px;
  line-height: 1.4;
  margin-right: 10px;
  ${({ disabled }) =>
    !disabled &&
    `
    cursor: pointer;
  `};
`;

export const HighlitedRow = styled(DataRow)`
  background-color: ${({ theme }) => theme.colors.HIGHLIGHTED_ROW};
`;

const subtitle = css`
  font-weight: 400;
  font-size: 14px;
  margin-top: 4px;
  line-height: 1.2;
  color: ${styles.colors.GRAY};
  white-space: pre-wrap;
`;

export const InvitedBy = styled.span`
  ${subtitle}
  font-weight: 600;
  ${({ padding }) => padding && `padding: ${padding}`};
  a {
    color: ${styles.colors.GRAY};
    text-decoration: none;
  }
  a:hover {
    color: ${styles.colors.DARK_GREEN};
  }
`;

export const NameWithOptions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: ${({ alignItems }) => (alignItems ? alignItems : 'flex-start')};
  justify-content: ${({ justifyContent }) => (justifyContent ? justifyContent : 'flex-start')};
`;

export const ProviderButtonsHolder = styled.div`
  display: flex;
  span {
    &:hover {
      color: ${styles.colors.DARK_GREEN};
    }
  }
`;

export const ProviderRecordWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const userNameTextStyle = css`
  text-transform: capitalize;
  line-height: 1.2;
  font-size: 20px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.TEXT};
`;

export const callButtonStyle = {
  marginLeft: 16
};

export const SwitchBtnWrapper = styled.div`
  margin: 0 8px;
`;

export const UserNameRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  span {
    &:hover {
      color: ${styles.colors.DARK_GREEN};
    }
  }
`;

export const UserPhoneNumber = styled.span`
  ${subtitle}
`;

export const InfoRow = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.WHITE};
  gap: 5px;
  margin-top: 4px;
`;
export const FlexWrapInfoRow = styled(InfoRow)`
  flex-wrap: wrap;
`;

export const TooltipText = styled.div`
  &:hover {
    color: ${({ theme }) => theme.colors.DARK_GREEN};
  }
  cursor: default;
`;
