import styled from 'styled-components';
import styles from 'constants/styles';

export const CreateInvoiceButton = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 1.14;
  width: 100%;
  cursor: pointer;
  padding: 16px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.DARK_GREY};
  border-bottom: 1px solid ${({ theme }) => theme.colors.DARK_GREY_3};
  &:hover {
    background-color: ${({ theme }) => theme.colors.DARK_4};
    color: ${({ theme }) => theme.colors.WHITE};
  }

  &:focus {
    outline: 0;
  }
`;

export const PaymentSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 500px;
  max-height: calc(100vh - ${styles.layout.headerHeight}px - 50px);
  overflow-y: overlay;
  background-color: ${({ theme }) => theme.colors.DARK_1};
`;

export const LoadingContainer = styled.div`
  display: flex;
  flex: 1;
  padding: 50px;
  justify-content: center;
  position: relative;
  height: calc(100vh - ${styles.layout.headerHeight}px);
`;

export const ChargeButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  ${({ withMargin }) => withMargin && 'margin: 4px 16px 10px;'}
`;
export const newInvoiceBtnStyles = { margin: '0 6px 0 0' };
