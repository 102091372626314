import React from 'react';
import { connect } from 'react-redux';
import { closeModal, deleteServiceNote, deleteUserNote } from 'store/actions';
import { ModalConfirmation } from 'components';
import modalTypes from 'constants/modalTypes';
import { selectModalData } from 'store/reducers/modal';
import { selectUserDeleteNoteLoading } from 'store/reducers/user';
import { selectDeleteServiceNoteLoading } from 'store/reducers/service';

const DeleteNote = ({
  closeModal,
  deleteUserNoteLoading,
  deleteServiceNoteLoading,
  deleteServiceNote,
  deleteUserNote,
  modalData
}) => {
  const deleteNote = () => {
    if (modalData?.serviceId) {
      deleteServiceNote(modalData.deletedNote);
      return;
    }
    deleteUserNote(modalData.deletedNote);
  };

  const deleteNoteLoading = modalData?.serviceId ? deleteServiceNoteLoading : deleteUserNoteLoading;

  return (
    <ModalConfirmation
      modalType={modalTypes.DELETE_NOTE}
      title="Remove note?"
      hideModal={() => closeModal(modalTypes.DELETE_NOTE)}
      primaryButton={{
        title: 'Cancel',
        disabled: deleteNoteLoading,
        onClick: () => closeModal(modalTypes.DELETE_NOTE)
      }}
      secondaryButton={{
        title: 'Confirm',
        disabled: deleteNoteLoading,
        loading: deleteNoteLoading,
        onClick: deleteNote
      }}
    />
  );
};

const mapStateToProps = (state) => ({
  modalData: selectModalData(state),
  deleteUserNoteLoading: selectUserDeleteNoteLoading(state),
  deleteServiceNoteLoading: selectDeleteServiceNoteLoading(state)
});

const mapDispatchToProps = {
  closeModal,
  deleteServiceNote,
  deleteUserNote
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteNote);
