import React from 'react';
import { useForm } from 'react-hook-form';

import { customJobFilters } from 'common/enums/filters';
import { adminStatuses } from 'common/enums/job';

import { HookForm, RightSidebar, Button } from 'components';
import { oneFieldRequiredResolver } from './helpers/form';
import { FieldsWrapper, StyledForm, FieldRow, FieldLabel, FormFooter } from './styled';

const TaskFiltersForm = ({ defaultResetValues, initialValues, onSubmitForm, onToggleForm }) => {
  const {
    control,
    reset,
    handleSubmit,
    setValue,
    getValues,
    formState: { isValid }
  } = useForm({
    defaultValues: initialValues,
    mode: 'onChange',
    resolver: oneFieldRequiredResolver
  });

  const handleFormReset = () => {
    reset(defaultResetValues, { keepIsValid: false });
  };
  const handleFormSubmit = (data) => {
    onSubmitForm(data);
  };

  const handleFormSubmitError = (errors, e) => console.log(errors, e);

  const adminStatusOptions = Object.values(adminStatuses).map((stage) => ({
    id: String(stage.TYPE),
    name: stage.NAME
  }));

  return (
    <RightSidebar visible onSidebarClose={onToggleForm} title="Filter List By">
      <StyledForm onSubmit={handleSubmit(handleFormSubmit, handleFormSubmitError)} onReset={handleFormReset}>
        <FieldsWrapper>
          <FieldLabel>{customJobFilters.ADMIN_STATUS.NAME}</FieldLabel>
          <FieldRow marginBottom={24}>
            <HookForm.Select
              label=""
              name={customJobFilters.ADMIN_STATUS.TYPE}
              type="square-small"
              control={control}
              placeholder={`Select ${customJobFilters.ADMIN_STATUS.NAME}`}
              width={256}
              options={adminStatusOptions}
            />
          </FieldRow>
          <FieldLabel>{customJobFilters.DATETIME_CREATED_FROM.NAME}</FieldLabel>
          <FieldRow marginBottom={24}>
            <HookForm.DateRangePicker
              startDateName={customJobFilters.DATETIME_CREATED_FROM.TYPE}
              endDateName={customJobFilters.DATETIME_CREATED_TO.TYPE}
              getValues={getValues}
              setValue={setValue}
            />
          </FieldRow>
          <FieldLabel>{customJobFilters.DATETIME_COMPLETED_FROM.NAME}</FieldLabel>
          <FieldRow marginBottom={24}>
            <HookForm.DateRangePicker
              startDateName={customJobFilters.DATETIME_COMPLETED_FROM.TYPE}
              endDateName={customJobFilters.DATETIME_COMPLETED_TO.TYPE}
              getValues={getValues}
              setValue={setValue}
            />
          </FieldRow>
          <FieldLabel>{customJobFilters.DATETIME_CANCELED_FROM.NAME}</FieldLabel>
          <FieldRow marginBottom={24}>
            <HookForm.DateRangePicker
              startDateName={customJobFilters.DATETIME_CANCELED_FROM.TYPE}
              endDateName={customJobFilters.DATETIME_CANCELED_TO.TYPE}
              getValues={getValues}
              setValue={setValue}
            />
          </FieldRow>
          <FieldRow marginBottom={8}>
            <HookForm.Checkbox
              control={control}
              name={customJobFilters.HAS_EARLY_WARNING.TYPE}
              label={customJobFilters.HAS_EARLY_WARNING.NAME}
            />
          </FieldRow>
          <FieldRow>
            <HookForm.Checkbox
              control={control}
              name={customJobFilters.HAS_UNREAD_MESSAGES.TYPE}
              label={customJobFilters.HAS_UNREAD_MESSAGES.NAME}
            />
          </FieldRow>
        </FieldsWrapper>
        <FormFooter>
          <Button inputType="reset" title="Clear" isPlain disabled={!isValid} onClick={handleFormReset} />
          <Button inputType="submit" title="Filter Out" disabled={!isValid} />
        </FormFooter>
      </StyledForm>
    </RightSidebar>
  );
};

export default TaskFiltersForm;
