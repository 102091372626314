import styled from 'styled-components';
import styles from 'constants/styles';

export const Wrapper = styled.div`
  width: 100%;
  overflow-y: overlay;
  overflow-x: hidden;
  background-color: ${({ theme }) => theme.colors.DARK_1};
  height: calc(100vh - ${styles.layout.headerHeight + styles.layout.tabsHeaderHeight}px) !important;
`;

export const Question = styled.div`
  padding: 21px 16px;
`;
export const Divider = styled.div`
  margin: 0 16px;
  width: 100%;
  height: 1px;
  background: ${({ theme }) => theme.colors.DARK_2};
`;
export const Title = styled.div`
  font-size: 20px;
  line-height: 1.2;
  color: ${({ theme }) => theme.colors.WHITE};
  margin-right: 10px;
  max-width: 368px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
`;

export const AnswerDate = styled.div`
  width: 100%;
  font-weight: 500;
  font-size: 10px;
  line-height: 1.2;
  color: ${({ theme }) => theme.colors.GRAY};
  margin: 10px 0;
  text-align: left;
  text-transform: uppercase;
`;

export const Answer = styled.div`
  font-size: 14px;
  line-height: 1.14;
  margin-bottom: 25px;
  color: ${({ theme }) => theme.colors.GRAY};
`;

export const ViewMore = styled.div`
  display: inline-block;
  font-weight: 400;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.WHITE};
  cursor: pointer;
  margin-top: 10px;
  &:hover {
    color: ${({ theme }) => theme.colors.DARK_GREEN};
  }
`;
export const Submitted = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 12px 8px 8px;
  gap: 8px;
  border: 1px solid ${({ theme }) => theme.colors.DARK_4};
  border-radius: 30px;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.WHITE};
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.colors.DARK_GREEN};
  }
`;
export const ViewDetails = styled.div`
  font-weight: 500;
  font-size: 12px;
  cursor: pointer;
`;
