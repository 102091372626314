import routes from 'constants/routes';
import { useAdminPermissions, useDispatch, useEffect, useMemo, useRouter, useSelector } from 'hooks';
import { useActiveMenuItem } from 'navigation/hooks';
import { navigationTabs, sidebarMenuItems } from 'common/enums/navigation';
import { getNotificationList } from 'store/actions/notification';
import { selectHasNotificationsBadge } from 'store/reducers/notification';

const useTopNavigation = ({ init } = {}) => {
  const { pathname, queryParams } = useRouter();
  const filters = queryParams.getAll();

  const { activeMenuItem } = useActiveMenuItem({ init: true });
  const { hasAdminRole } = useAdminPermissions({ init: true });
  const dispatch = useDispatch();
  const hasNotificationsBadge = useSelector(selectHasNotificationsBadge);

  useEffect(() => {
    if (!hasNotificationsBadge && init) {
      dispatch(getNotificationList({ page: 1 }));
    }
  }, [dispatch, hasNotificationsBadge, init]);
  const hasCreateCampaignButton = pathname === routes.CAMPAIGNS;
  const hasNavigationTabs = useMemo(() => {
    const isTableView = !pathname.split('/')[2];
    return Boolean(activeMenuItem?.HAS_TABS && isTableView);
  }, [activeMenuItem, pathname]);

  const hasTaskOwnerSelect = useMemo(() => {
    if (!hasAdminRole) {
      return false;
    }

    const routesWithOwnerFilter = [routes.SERVICE, routes.PAYMENTS, routes.INVOICE_PENDING].includes(pathname);
    const isGringottsPage = sidebarMenuItems.GRINGOTTS.TYPE === filters.menu_item;
    const isTroubledPage = sidebarMenuItems.TROUBLED.TYPE === filters.menu_item;
    return routesWithOwnerFilter || isGringottsPage || isTroubledPage;
  }, [hasAdminRole, pathname, filters.menu_item]);

  const hasGeoLocationSelect = useMemo(() => {
    if (!hasAdminRole) {
      return false;
    }

    const routesWithGeoFilter = [
      routes.CUSTOMER,
      routes.PAYMENTS,
      routes.INVOICE_PENDING,
      routes.PROVIDER,
      routes.PRIORITY_TASKS,
      routes.SERVICE,
      routes.COMMUNICATION
    ];
    const hasSearchTab = navigationTabs.REFERRAL.TYPE !== filters.tab_id;

    return routesWithGeoFilter.includes(pathname) && hasSearchTab;
  }, [filters.tab_id, hasAdminRole, pathname]);

  return {
    hasTaskOwnerSelect,
    hasGeoLocationSelect,
    hasNavigationTabs,
    hasNotificationsBadge,
    hasCreateCampaignButton
  };
};

export default useTopNavigation;
