import { call, takeLatest, put, select } from 'redux-saga/effects';

import { generateQueryParams } from 'common/helpers/httpRequest';
import { getCsvFileProps } from 'common/helpers/media';

import errors from 'constants/errors';
import actionTypes from 'constants/actionTypes';
import { downloadCSV } from 'utils';
import Api from 'services/ApiService';
import { isTestTasksVisible, selectFilteredAdminList, selectFilteredGeoLocationList } from 'store/reducers/app';

function* getCSVFile({ payload: { csvFileType, filters } = {} }) {
  try {
    const isTestTasks = yield select(isTestTasksVisible);
    const filteredAdminList = yield select(selectFilteredAdminList);
    const filteredGeoLocationList = yield select(selectFilteredGeoLocationList);

    const opAreasIDString = filteredGeoLocationList.length ? filteredGeoLocationList.join(',') : '';
    const ownersIDString = filteredAdminList.length ? filteredAdminList.map(({ id }) => id).join(',') : null;

    const params = {
      ...(isTestTasks ? {} : { is_test: 0 }),
      operational_area_id: opAreasIDString,
      owner_id: ownersIDString,
      ...filters
    };

    const { endpoint, filenameGenerator } = getCsvFileProps({ csvFileType });

    const response = yield call(Api.amplifyGet, `${endpoint}${generateQueryParams(params)}`, {
      headers: { Accept: 'text/csv' },
      response: true,
      responseType: 'arraybuffer'
    });

    if (!response?.data || response.err) {
      throw new Error(errors.DEFAULT);
    }

    downloadCSV({
      data: response.data,
      fileName: filenameGenerator(params)
    });

    yield put({
      type: actionTypes.GET_CSV_FILE_SUCCESS
    });
  } catch (err) {
    yield put({
      type: actionTypes.GET_CSV_FILE_ERROR
    });
  }
}

export default function* watchGetCSVFile() {
  yield takeLatest(actionTypes.GET_CSV_FILE_FETCH, getCSVFile);
}
