import React from 'react';
import { useTheme } from 'styled-components';

import { Icon } from 'components';

import { Pill, BadgeText } from './styled';

const WorkTogetherBadge = ({ is_worked_together, is_worked_together_review }) => {
  const theme = useTheme();
  const pillColor =
    is_worked_together_review === true
      ? theme.colors.DARK_GREEN
      : is_worked_together_review === false
      ? theme.colors.RED
      : theme.colors.WHIE;
  const iconName = is_worked_together_review === true ? 'thumbUp' : 'thumbDown';

  if (!is_worked_together) {
    return null;
  }

  return (
    <Pill color={pillColor}>
      <BadgeText>
        {is_worked_together_review !== null && <Icon name={iconName} size={16} color={pillColor} />}
        <span>Worked together</span>
      </BadgeText>
    </Pill>
  );
};

export default WorkTogetherBadge;
