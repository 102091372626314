import { cellTypes } from 'common/enums/table';
import dates from 'constants/dates';
import { getFullName } from 'common/helpers/user';
import { formatters } from 'utils';
import { bundleTaskTable } from '../enums';

export const getColumns = ({ currentTaskID, isTaskSelected }) => {
  return [
    {
      id: bundleTaskTable.ID.FILTER_NAME,
      title: bundleTaskTable.ID.TITLE,
      cellType: cellTypes.TEXT,
      hasSorting: false,
      accessor: ({ id }) => ({
        text: String(id)
      })
    },
    {
      id: bundleTaskTable.HO_GIVEN_NAME.FILTER_NAME,
      title: bundleTaskTable.HO_GIVEN_NAME.TITLE,
      cellType: cellTypes.USER_NAME,
      hasSorting: false,
      accessor: ({ customer, customer_family_name, customer_given_name }) => ({
        family_name: customer_family_name,
        given_name: customer_given_name,
        stage: customer.stage
      })
    },
    {
      id: bundleTaskTable.PRO_GIVEN_NAME.FILTER_NAME,
      title: bundleTaskTable.PRO_GIVEN_NAME.TITLE,
      cellType: cellTypes.USER_NAME,
      hasSorting: false,
      accessor: ({ provider_family_name, provider_given_name, pro_stage }) => ({
        family_name: provider_family_name,
        given_name: provider_given_name,
        pro_stage
      })
    },
    {
      id: bundleTaskTable.DATETIME_CREATED.FILTER_NAME,
      title: bundleTaskTable.DATETIME_CREATED.TITLE,
      cellType: cellTypes.TEXT,
      hasSorting: false,
      accessor: ({ date_created }) => ({
        text: formatters.dateWithTimezone(date_created, dates.DATE)
      })
    },
    {
      id: bundleTaskTable.JOB_CATEGORY_NAME.FILTER_NAME,
      title: bundleTaskTable.JOB_CATEGORY_NAME.TITLE,
      cellType: cellTypes.TEXT,
      hasSorting: false,
      accessor: ({ job_category_name }) => ({
        text: job_category_name
      })
    },
    {
      id: bundleTaskTable.JOB_TYPE_NAME.FILTER_NAME,
      title: bundleTaskTable.JOB_TYPE_NAME.TITLE,
      cellType: cellTypes.TEXT,
      hasSorting: false,
      accessor: ({ job_type_name }) => ({
        text: job_type_name
      })
    },
    {
      id: bundleTaskTable.CHECKBOX.FILTER_NAME,
      title: bundleTaskTable.CHECKBOX.TITLE,
      cellType: cellTypes.CHECKBOX,
      hasSorting: false,
      accessor: ({ id }) => ({
        isChecked: isTaskSelected(id),
        isTarget: currentTaskID === id
      })
    }
  ];
};
