import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import Select, { components } from 'react-select';

import { uploadCustomerInspectionReport } from 'store/actions/customer';
import { closeModal } from 'store/actions/modal';

import { selectUploadPropertyReportLoading } from 'store/reducers/customer';
import { selectModalData } from 'store/reducers/modal';
import { selectServiceUploadPropertyReportLoading } from 'store/reducers/service';

import { Icon, Button, Modal } from 'components';
import {
  FormHolder,
  ReportHeader,
  FieldTitle,
  InputWrapper,
  UploadedFile,
  UploadFileButton,
  UploadFileInput,
  UploadedFileName,
  UploadedFileClose,
  reportTypeSelectStyles,
  DropdownArrow
} from './styled';

import styles from 'constants/styles';
import modalTypes from 'constants/modalTypes';
import { reportTypes } from 'constants/content';

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <DropdownArrow />
    </components.DropdownIndicator>
  );
};

const UploadReport = ({
  uploadCustomerInspectionReport,
  closeModal,
  modalData: { customerId, task_id },
  uploadPropertyReportLoading,
  uploadServicePropertyReportLoading
}) => {
  const uploadInputRef = useRef(null);
  const [uploadedReportFile, setUploadedReportFile] = useState(null);
  const [reportType, setReportType] = useState(reportTypes[0]);

  const onReportTypeChange = (value) => setReportType(value);

  const onUploadClick = () => uploadInputRef.current && uploadInputRef.current.click();

  const onUploadReportChange = (event) => {
    event.stopPropagation();
    event.preventDefault();
    const files = event.target.files;

    if (files && files.length > 0) {
      const uploadedFile = files[0];
      setUploadedReportFile(uploadedFile);
    }
  };

  const onUploadFileClose = () => setUploadedReportFile(null);

  const onUploadSubmit = () =>
    uploadCustomerInspectionReport({
      user_id: customerId,
      file: uploadedReportFile,
      reportType: reportType.value,
      task_id
    });

  const onModalClose = () => {
    onUploadFileClose();
    setReportType(reportTypes[0]);
    closeModal();
  };

  return (
    <Modal
      modalType={modalTypes.UPLOAD_INSPECTION_REPORT}
      hideModal={onModalClose}
      smallPaddings
      overflowMode="none"
      minWidth={296}
      closeButton>
      <FormHolder>
        <ReportHeader>Upload Report</ReportHeader>
        <InputWrapper marginBottom={25}>
          <FieldTitle>Select Type</FieldTitle>
          <Select
            defaultValue={reportTypes[0]}
            value={reportType}
            components={{ DropdownIndicator }}
            styles={reportTypeSelectStyles}
            options={reportTypes}
            onChange={onReportTypeChange}
            isDisabled={uploadPropertyReportLoading || uploadServicePropertyReportLoading}
            isSearchable={false}
          />
        </InputWrapper>
        <InputWrapper marginBottom={31}>
          <FieldTitle>Choose a file</FieldTitle>
          <UploadFileInput
            type="file"
            accept="application/pdf"
            ref={uploadInputRef}
            onChange={onUploadReportChange}
            label="Upload report"
          />
          <UploadFileButton
            onClick={onUploadClick}
            disabled={uploadPropertyReportLoading || uploadServicePropertyReportLoading}>
            Upload Report
          </UploadFileButton>
          {uploadedReportFile && (
            <UploadedFile>
              <UploadedFileName>{uploadedReportFile.name}</UploadedFileName>
              <UploadedFileClose
                onClick={onUploadFileClose}
                disabled={uploadPropertyReportLoading || uploadServicePropertyReportLoading}>
                <Icon name="close" size={10} color={styles.colors.BLACK} />
              </UploadedFileClose>
            </UploadedFile>
          )}
        </InputWrapper>
        <Button
          disabled={!uploadedReportFile || uploadPropertyReportLoading || uploadServicePropertyReportLoading}
          loading={uploadPropertyReportLoading || uploadServicePropertyReportLoading}
          title="SEND TO HO"
          onClick={onUploadSubmit}
        />
      </FormHolder>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  uploadPropertyReportLoading: selectUploadPropertyReportLoading(state),
  uploadServicePropertyReportLoading: selectServiceUploadPropertyReportLoading(state),
  modalData: selectModalData(state)
});

const mapDispatchToProps = {
  uploadCustomerInspectionReport,
  closeModal
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadReport);
