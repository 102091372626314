export const formatTime = (num) => {
  let strNum = String(num);
  if (num < 10) {
    strNum = '0' + strNum;
  }

  return strNum;
};

export const formatDuration = (dateTimeNow, startDateTime) => {
  const duration = dateTimeNow.diff(startDateTime, 'second');
  const minutes = Math.trunc(duration / 60);
  const seconds = duration % 60;
  return `${formatTime(minutes)} : ${formatTime(seconds)}`;
};
