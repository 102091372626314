import styled from 'styled-components';
import styles from 'constants/styles';

export const DataRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: ${({ justifyContent }) => (justifyContent ? justifyContent : 'flex-start')};
  padding: 0 24px;
`;

export const DataRecord = styled.div`
  align-items: ${({ alignItems }) => alignItems || 'flex-start'};
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 1)};
  flex-direction: ${({ flexDirection }) => flexDirection || 'column'};
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
  margin: ${({ margin }) => (margin ? margin : '12px 20px 12px 0')};
`;

export const FieldTitle = styled.span`
  color: ${styles.colors.GRAY};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25;
  ${({ withoutMargin }) => !withoutMargin && 'margin-bottom: 5px'};
`;

export const FieldValue = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 1.14;

  color: ${({ theme }) => theme.colors.INVOICE_TEXT};
  a {
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.INVOICE_TEXT};
    &:hover {
      color: ${({ theme }) => theme.colors.DARK_GREEN};
    }
  }
`;

export const Tag = styled.div`
  padding: 4px 16px;
  border: 1px solid ${({ theme }) => theme.colors.DARK_4};
  border-radius: 20px;
  font-weight: 400;
  font-size: 14px;
  margin: 2px;
  color: ${({ theme }) => theme.colors.WHITE};
  ${({ capitalize }) => capitalize && `text-transform: capitalize;`}
`;

export const TagsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -2px;
`;
