import styled from 'styled-components';
import styles from 'constants/styles';

export const StyledForm = styled.form`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const FieldsWrapper = styled.div`
  width: 256px;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 18px 24px;
`;

export const FieldRow = styled.div`
  display: flex;
  margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : 19)}px;
`;

export const FieldLabel = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 1.2;
  letter-spacing: 0.1px;
  color: ${styles.colors.GRAY};
  margin-bottom: 4px;
`;

export const FormFooter = styled.div`
  border-top: 1px solid ${styles.colors.LIGHT_GRAY_2};
  width: 100%;
  padding: 14px 24px 21px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
