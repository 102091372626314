import { call, takeLatest, put } from 'redux-saga/effects';
import api_constants from 'constants/api';
import actionTypes from 'constants/actionTypes';
import Api from 'services/ApiService';
import modalTypes from 'constants/modalTypes';
import { openModal } from 'store/actions';

function* updateSkill({ payload }) {
  const { name, id } = payload;
  try {
    const response = yield call(Api.amplifyPut, `${api_constants.SKILL}/${id}`, { name });
    yield put({
      type: actionTypes.UPDATE_SKILL_SUCCESS,
      payload: response
    });
    yield put(
      openModal(modalTypes.RESULT, {
        title: `The “${response.name || ''}” skill has been updated`
      })
    );
  } catch (err) {
    yield put({
      type: actionTypes.UPDATE_SKILL_ERROR
    });
  }
}

export default function* watchUpdateSkill() {
  yield takeLatest(actionTypes.UPDATE_SKILL_FETCH, updateSkill);
}
