import styled from 'styled-components';

export const BundleHeader = styled.h3`
  font-weight: 600;
  font-size: 20px;
  margin: 0;
  width: 100%;
  text-align: left;
  color: ${({ theme }) => theme.colors.TEXT};
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 47%;
  button {
    margin: 0;
  }
`;

export const TableContainer = styled.div`
  margin: 0;
  padding: 0;
  width: 100%;
  display: block;
  height: 305px;
  overflow-y: overlay;
`;

export const TableFooter = styled.div`
  padding: 15px 0 5px 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SelectedTaskCount = styled.span`
  font-weight: 600;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.TEXT};
`;

export const LoadingWrapper = styled.div`
  position: relative;
  flex: 1;
  width: 500px;
  height: 400px;
  min-height: 300px;
`;
