import styled from 'styled-components';

export const HeaderArea = styled.div`
  flex: 1;
  font-weight: 600;
  font-size: 24px;
  line-height: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.DARK_2};
  padding: 18px 12px 14px 24px;
  color: ${({ theme }) => theme.colors.INVOICE_TEXT};
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FiltersRow = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 20px;
`;
export const SearchWrapper = styled.div`
  flex: 1;
  margin: 0 8px 0 0;
  position: relative;
  input {
    padding: 8px 26px 8px 16px;
  }
`;
export const InputDescription = styled.div`
  margin-top: ${({ marginTop }) => marginTop ?? 10}px;
  color: ${({ theme }) => theme.colors.DARK_GREY};
  font-weight: 400;
  font-size: 12px;
  line-height: 1.33;
`;

export const Label = styled.span`
  margin-right: 16px;
`;

export const PillWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-self: flex-start;
  align-items: center;
  justify-content: center;
  padding: 4px 4px 4px 12px;

  border: 1px solid ${({ theme }) => theme.colors.DARK_4};
  border-radius: 16px;

  color: ${({ theme }) => theme.colors.WHITE};
  border-radius: 16px;

  &:hover {
    cursor: pointer;
  }
`;

export const ResetFilter = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 1.17;
  text-decoration-line: underline;
  color: ${({ theme }) => theme.colors.WHITE};
  margin-left: 16px;
  cursor: pointer;
`;
