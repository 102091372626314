import React from 'react';
import { connect } from 'react-redux';
import { closeModal, unbundleTask } from 'store/actions';
import { selectModalData } from 'store/reducers/modal';
import { selectUpdateTaskStatusLoading } from 'store/reducers/service';

import modalTypes from 'constants/modalTypes';

import { ModalConfirmation } from 'components';
import { ModalText, BoldTextWrapper } from './styled';

const UnbundleTask = ({ closeModal, updateTaskLoading, modalData: { task_id } = {}, unbundleTask }) => {
  const onDuplicateTask = () => {
    if (!task_id) {
      return;
    }
    unbundleTask(task_id);
  };

  const onModalClose = () => {
    closeModal(modalTypes.UNBUNDLE_TASK);
  };

  return (
    <ModalConfirmation
      modalType={modalTypes.UNBUNDLE_TASK}
      padding="40px 30px"
      title={`Please confirm unbundling of the job`}
      text={
        <ModalText>
          <BoldTextWrapper>The unbundling will reflect the target job</BoldTextWrapper>
        </ModalText>
      }
      hideModal={onModalClose}
      primaryButton={{
        disabled: updateTaskLoading,
        onClick: onModalClose
      }}
      secondaryButton={{
        title: 'Confirm',
        loading: updateTaskLoading,
        disabled: updateTaskLoading,
        onClick: onDuplicateTask
      }}
    />
  );
};

const mapStateToProps = (state) => ({
  modalData: selectModalData(state),
  updateTaskLoading: selectUpdateTaskStatusLoading(state)
});

const mapDispatchToProps = {
  closeModal,
  unbundleTask
};

export default connect(mapStateToProps, mapDispatchToProps)(UnbundleTask);
