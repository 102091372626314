import { call, takeLatest, put } from 'redux-saga/effects';
import api_constants from 'constants/api';
import actionTypes from 'constants/actionTypes';
import Api from 'services/ApiService';
import { getFormattedReview } from 'utils';

function* getProviderReviews({ payload: { page, reset, providerId } }) {
  try {
    const response = yield call(
      Api.amplifyGet,
      `${api_constants.REVIEW}?page=${page}&service_provider_id=${providerId}`
    );

    const formattedReviews = response.results.map(getFormattedReview);
    if (response && response.results) {
      yield put({
        type: actionTypes.GET_PROVIDER_REVIEWS_SUCCESS,
        payload: {
          reviews: formattedReviews,
          count: response.count,
          page,
          reset
        }
      });
    } else {
      yield put({
        type: actionTypes.GET_PROVIDER_REVIEWS_ERROR
      });
    }
  } catch (err) {
    console.log(err);
  }
}

export default function* watchGetProviderReviews() {
  yield takeLatest(actionTypes.GET_PROVIDER_REVIEWS_FETCH, getProviderReviews);
}
