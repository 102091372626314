import React from 'react';

import { useCallback, useDispatch, useSiblingsDetailsPagination, useRouter, useSelector, useMemo } from 'hooks';
import { siblingPaginationTypes } from 'common/enums/navigation';
import { apiPageSizes } from 'common/enums/queryParams';
import { getJobRequestParams } from 'common/helpers/job';
import styles from 'constants/styles';

import { getCustomerReviews, getProviderReviews } from 'store/actions';
import { getUserServiceList } from 'store/actions/user';
import { selectHasGoBackButton } from 'store/reducers/navigation';
import { IconButton } from 'components';

import { hasPaginationAvailable } from './helpers/pagination';
import { getPageSelectors } from './helpers/selectors';
import { PaginationContainer, PaginationText } from './styles';

const SiblingsDetailsPagination = () => {
  const {
    location: { pathname, state: locationState }
  } = useRouter();
  const dispatch = useDispatch();
  const {
    name = 'Job',
    route,
    pageSize = apiPageSizes.DEFAULT,
    selectInstanceId,
    selectList,
    selectTotalCount,
    selectPage,
    isPayment
  } = useMemo(
    () =>
      getPageSelectors({
        paginationType: locationState?.siblingPaginationType,
        jobType: locationState?.jobType
      }),
    [locationState]
  );

  const hasGoBackButton = useSelector(selectHasGoBackButton);
  const page = useSelector(selectPage);
  const totalCount = useSelector(selectTotalCount);

  const handleLoadMore = useCallback(() => {
    if ([siblingPaginationTypes.CUSTOMER_JOB].includes(locationState?.siblingPaginationType)) {
      dispatch(
        getUserServiceList({
          customer_id: locationState?.rootId,
          page: page + 1,
          limit: pageSize,
          ...getJobRequestParams(locationState?.jobType)
        })
      );
      return;
    }

    if ([siblingPaginationTypes.PROVIDER_JOB].includes(locationState?.siblingPaginationType)) {
      dispatch(
        getUserServiceList({
          provider_id: locationState?.rootId,
          page: page + 1,
          limit: pageSize,
          ...getJobRequestParams(locationState?.jobType)
        })
      );
      return;
    }

    if ([siblingPaginationTypes.CUSTOMER_JOB_REVIEW].includes(locationState?.siblingPaginationType)) {
      dispatch(getCustomerReviews({ page: page + 1, customerId: locationState?.rootId }));
      return;
    }

    if ([siblingPaginationTypes.PROVIDER_JOB_REVIEW].includes(locationState?.siblingPaginationType)) {
      dispatch(getProviderReviews({ page: page + 1, providerId: locationState?.rootId }));
      return;
    }
  }, [dispatch, page, pageSize, locationState]);

  const { prevId, nextId, onNavigateSibling, currentIndex } = useSiblingsDetailsPagination({
    route,
    pageSize,
    selectInstanceId,
    selectList,
    handleLoadMore,
    hasLoadMoreButton: locationState?.hasLoadMoreButton,
    siblingIdName: locationState?.siblingIdName || 'id',
    page,
    totalCount,
    isPayment
  });

  const isPaginationAvailable = useMemo(
    () => hasPaginationAvailable({ pathname, hasGoBackButton }) && Boolean(totalCount),
    [hasGoBackButton, pathname, totalCount]
  );
  const handleNavigateNext = useCallback(() => onNavigateSibling(nextId, true), [nextId, onNavigateSibling]);
  const handleNavigatePrev = useCallback(() => onNavigateSibling(prevId), [onNavigateSibling, prevId]);

  if (!isPaginationAvailable || !locationState?.siblingPaginationType) {
    return null;
  }

  return (
    <PaginationContainer>
      <IconButton
        iconName="arrowLeft"
        iconColor={prevId ? styles.colors.BLACK : styles.colors.GRAY}
        size={32}
        iconWidth={8}
        iconHeight={16}
        disabled={!prevId}
        onClick={handleNavigatePrev}
      />

      <PaginationText>{`${name} ${currentIndex} of ${totalCount}`}</PaginationText>

      <IconButton
        iconColor={nextId ? styles.colors.BLACK : styles.colors.GRAY}
        size={32}
        iconWidth={8}
        iconHeight={16}
        disabled={!nextId}
        onClick={handleNavigateNext}
        iconName="arrowRight"
      />
    </PaginationContainer>
  );
};

export default SiblingsDetailsPagination;
