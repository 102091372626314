import { checkProviderAccess, mapFieldsToProviderCohort } from 'common/helpers/user';

export const getRows = ({ list }) => {
  return list.map((provider) => {
    const { hasAutoAreaCategoryDisabled } = checkProviderAccess({
      is_pre_filled: provider.user.is_pre_filled,
      is_api_active: provider.user.is_api_active,
      is_active: provider.user.is_active,
      is_cognito_active: provider.user.is_cognito_active,
      pro_cohort: mapFieldsToProviderCohort({ is_core: provider.is_core, is_parked: provider.is_parked }),
      pro_stage: provider.pro_stage
    });

    return {
      amount_average_earned: provider.amount_average_earned,
      amount_total_earned: provider.amount_total_earned,
      auto_assign: provider.auto_assign,
      count_accepted: provider.count_accepted,
      count_jobs_assigned: provider.count_jobs_assigned,
      count_jobs_completed: provider.count_jobs_completed,
      count_jobs_ongoing: provider.count_jobs_ongoing,
      count_jobs_scheduled: provider.count_jobs_scheduled,
      count_jobs_unfulfilled: provider.count_jobs_unfulfilled,
      date_created: provider.date_created,
      date_login: provider.user.date_login,
      days_since_sign_up: provider.user.days_since_sign_up,
      device_notification_status: provider.platform.device_notification_status,
      given_name: provider.user?.given_name,
      family_name: provider.user?.family_name,
      id: provider.id,
      is_parked: provider.is_parked,
      one_click: provider.one_click,
      phone_number: provider.user?.phone_number,
      platform: provider.platform,
      profile_image_url: provider.user?.profile_image_url,
      pro_stage: provider.pro_stage,
      service_area_list: provider.service_area_list,
      primary_category: provider.primary_category,
      secondary_category: provider.secondary_category,
      user: provider.user,
      hasAutoAreaCategoryDisabled
    };
  });
};
