import React from 'react';
import { closeModal, chatExtendOrClose } from 'store/actions';
import { useDispatch, useSelector } from 'hooks';
import { ModalConfirmation } from 'components';
import modalTypes from 'constants/modalTypes';
import { selectModalData } from 'store/reducers/modal';
import { selectChatExtendOrCloseLoading } from 'store/reducers/loading';

export const ToggleJobChat = () => {
  const dispatch = useDispatch();
  const modalData = useSelector(selectModalData);
  const hasLoading = useSelector(selectChatExtendOrCloseLoading);

  const handleToggleJobChat = () => {
    dispatch(
      chatExtendOrClose({
        service_request_id: modalData?.job_id,
        action: modalData?.is_service_chat_closed ? 'extend' : 'close'
      })
    );
  };

  // close chat
  if (!modalData?.is_service_chat_closed) {
    return (
      <ModalConfirmation
        modalType={modalTypes.TOGGLE_JOB_CHAT}
        title="Close ability to chat in this job?"
        text={`The HO and Pro will no longer have access to chat, and the job will be moved from the “Recently Completed“ tab to the “Jobs History“`}
        hideModal={() => dispatch(closeModal(modalTypes.TOGGLE_JOB_CHAT))}
        primaryButton={{
          title: 'Cancel',
          onClick: () => dispatch(closeModal(modalTypes.TOGGLE_JOB_CHAT)),
          disabled: hasLoading
        }}
        secondaryButton={{
          title: 'Close the chat',
          onClick: handleToggleJobChat,
          loading: hasLoading
        }}
      />
    );
  }

  // open chat
  return (
    <ModalConfirmation
      modalType={modalTypes.TOGGLE_JOB_CHAT}
      title="Open ability to chat in this job?"
      text={`The HO and Pro will get access to chat in the job. The job will be moved to the “Recently Completed“ tab from the “Jobs History“`}
      hideModal={() => dispatch(closeModal(modalTypes.TOGGLE_JOB_CHAT))}
      primaryButton={{
        title: 'Cancel',
        onClick: () => dispatch(closeModal(modalTypes.TOGGLE_JOB_CHAT)),
        disabled: hasLoading
      }}
      secondaryButton={{
        title: 'Open the chat',
        onClick: handleToggleJobChat,
        loading: hasLoading
      }}
    />
  );
};
