import styled from 'styled-components';

export const ModalText = styled.p`
  color: ${({ theme }) => theme.colors.BLACK};
  font-size: 14px;
  white-space: pre-wrap;
  text-align: center;
  padding: 0px 38px;
  margin-top: 16px;
  margin-bottom: 0;
`;

export const BoldTextWrapper = styled.span`
  font-weight: 600;
`;
