import { call, takeLatest, put, select } from 'redux-saga/effects';

import { userGroups } from 'common/enums/user';
import api_constants from 'constants/api';
import actionTypes from 'constants/actionTypes';
import { saveLocationSearch } from 'store/actions/navigation';
import { isTestTasksVisible } from 'store/reducers/app';
import Api from 'services/ApiService';
import { formatSearchTerm } from 'common/helpers/queryParams';

function* getAgentList({ payload }) {
  const {
    page,
    order_by,
    search = '',
    is_full_active = null,
    is_cognito_active = null,
    is_pre_filled = null,
    hasSavingQueryParams,
    menuItemType,
    locationSearch
  } = payload;
  const testTasksVisible = yield select(isTestTasksVisible);

  try {
    const response = yield call(
      Api.amplifyGet,
      `${api_constants.REAL_ESTATE_AGENT}?page=${page}&group_id=${userGroups.AGENT.TYPE}${
        order_by ? `&order_by=${order_by}` : ''
      }${search ? `&search=${formatSearchTerm(search)}` : ''}${
        is_full_active !== null ? `&is_full_active=${Number(is_full_active)}` : ''
      }${is_cognito_active !== null ? `&is_cognito_active=${Number(is_cognito_active)}` : ''}${
        is_pre_filled !== null ? `&is_pre_filled=${Number(is_pre_filled)}` : ''
      }${testTasksVisible ? '' : '&is_test=0'}`
    );

    if (!response?.results) {
      throw new Error();
    }
    yield put({
      type: actionTypes.GET_AGENT_LIST_SUCCESS,
      payload: {
        page,
        totalCount: response.count,
        users: response.results
      }
    });

    if (hasSavingQueryParams) {
      yield put(saveLocationSearch({ menuItemType, locationSearch }));
    }
  } catch (err) {
    yield put({
      type: actionTypes.GET_AGENT_LIST_ERROR
    });
    console.log(err);
  }
}

export default function* watchGetAgentList() {
  yield takeLatest(actionTypes.GET_AGENT_LIST_FETCH, getAgentList);
}
