import styled from 'styled-components';

export const WarningLabel = styled.div`
  color: ${({ color }) => color};
  background: ${({ theme }) => theme.colors.DARK_2};
  border-radius: 20px;
  padding: 5px 24px;
`;

export const WarningTooltipText = styled.div`
  color: ${({ theme }) => theme.colors.DARK_1};
  font-weight: 500;
  font-size: 12px;
  line-height: 1.17;
  text-align: left;
`;

export const DateVisitLabel = styled.div`
  color: ${({ isPastScheduled, theme }) => (isPastScheduled ? theme.colors.GRAY : theme.colors.DARK_GREEN)};
  border-radius: 20px;
  background: ${({ theme }) => theme.colors.DARK_2};
  border-radius: 20px;
  padding: 5px 24px;
`;
