import { call, put, takeLatest } from 'redux-saga/effects';
import api_constants from 'constants/api';
import actionTypes from 'constants/actionTypes';
import errors from 'constants/errors';
import Api from 'services/ApiService';

function* getUnreadMessagesByCategories() {
  try {
    const response = yield call(Api.amplifyGet, api_constants.GET_UNREAD_MESSAGED_BY_CATEGORIES);
    if (!response || response?.err) {
      throw new Error(errors.DEFAULT);
    }
    yield put({
      type: actionTypes.GET_UNREAD_MESSAGED_BY_CATEGORIES_SUCCESS,
      payload: response
    });
  } catch (err) {
    yield put({
      type: actionTypes.GET_UNREAD_MESSAGED_BY_CATEGORIES_ERROR
    });
    console.log(err);
  }
}

export default function* watchGetUnreadMessagesByCategories() {
  yield takeLatest(actionTypes.GET_UNREAD_MESSAGED_BY_CATEGORIES_FETCH, getUnreadMessagesByCategories);
}
