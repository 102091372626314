import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { useCallback, useFilters, useSelector, useMemo } from 'hooks';
import { siblingPaginationTypes } from 'common/enums/navigation';
import routes from 'constants/routes';
import { getServiceList } from 'store/actions';
import { selectServiceList, selectServiceListLoading, selectServiceTotalCount } from 'store/reducers/service';
import { Table, PaginationFullScreen } from 'components';
import { selectActiveMenuItem } from 'store/reducers/navigation';

import { getColumns, getRows } from './helpers';

const PriorityTasks = ({ count, list, loading, getServiceList }) => {
  const activeMenuItem = useSelector(selectActiveMenuItem);
  const handleFetchTasksList = useCallback(
    (updatedFilters = {}) => {
      getServiceList(updatedFilters);
    },
    [getServiceList]
  );

  const { filters, handleChangePage, handleChangeSorting, handleRowClick, handleRowContextClick } = useFilters({
    onFetchList: handleFetchTasksList,
    instanceRoute: routes.SERVICE,
    listRoute: routes.PRIORITY_TASKS,
    siblingPaginationType: siblingPaginationTypes.JOB
  });

  const columns = useMemo(() => getColumns({ tab_id: filters.tab_id }), [filters.tab_id]);
  const data = useMemo(() => getRows({ list }), [list]);

  return (
    <PaginationFullScreen
      count={count}
      currentPage={Number(filters.page)}
      hasNavigationTabs={activeMenuItem.HAS_TABS}
      onPageChange={handleChangePage}
      length={list?.length}
      loading={loading}
      searchTerm={filters.search}>
      <Table
        onSort={handleChangeSorting}
        onRowClick={handleRowClick}
        onRowContextClick={handleRowContextClick}
        searchTerm={filters.search}
        columns={columns}
        data={data}
        sortingDirection={filters.sorting_direction}
        sortingColumnName={filters.sorting_column}
      />
    </PaginationFullScreen>
  );
};

PriorityTasks.propTypes = {
  list: PropTypes.array.isRequired,
  count: PropTypes.number.isRequired,
  getServiceList: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => ({
  list: selectServiceList(state),
  count: selectServiceTotalCount(state),
  loading: selectServiceListLoading(state)
});

const mapDispatchToProps = {
  getServiceList
};

export default connect(mapStateToProps, mapDispatchToProps)(PriorityTasks);
