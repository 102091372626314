import React from 'react';
import moment from 'moment-timezone';

import { useDispatch, useSelector, useState } from 'hooks';
import { getAdminList, openModal } from 'store/actions';
import { updateJobField } from 'store/actions/service';
import { selectAdminList } from 'store/reducers/admin';
import { isTestTasksVisible } from 'store/reducers/app';
import { selectServiceInstance, selectUpdateJobFieldLoading } from 'store/reducers/service';
import {
  convertAmountValueToString,
  convertStringToAmountValue,
  formatDateStamp,
  formatters,
  numberWithCommas
} from 'utils';
import { AutocompleteSearch } from 'components';
import modalTypes from 'constants/modalTypes';
import { getFullName } from 'common/helpers/user';

import { getRequestSourceByType } from './helpers/campaigns';
import { EditableLabel } from '../';
import { DataRecord, DataRow, FieldTitle, FieldValue } from '../styled';
import routes from 'constants/routes';
import { Link } from 'react-router-dom';

const JobSystemRow = () => {
  const dispatch = useDispatch();

  const adminList = useSelector(selectAdminList);
  const updateJobFieldLoading = useSelector(selectUpdateJobFieldLoading);
  const testTasksVisible = useSelector(isTestTasksVisible);
  const serviceInstance = useSelector(selectServiceInstance);

  const [selectedEditingField, setSelectedEditingField] = useState(null);

  const onQuoteAmountSubmit = ({ inputField: quoteAmount }) => {
    const parsedQuoteAmount = convertStringToAmountValue(quoteAmount);
    setSelectedEditingField('expected_value');

    if (parsedQuoteAmount !== serviceInstance.expected_value) {
      dispatch(
        updateJobField({
          job_id: serviceInstance.id,
          body: {
            expected_value: parsedQuoteAmount || null
          }
        })
      );
    }
  };

  const handleTaskOwnerSubmit = (taskOwner) => {
    setSelectedEditingField('task_owner');

    if (taskOwner.id !== serviceInstance.owner_id) {
      dispatch(
        openModal(modalTypes.ASSIGN_TASK_OWNER, {
          id: serviceInstance.id,
          taskOwner
        })
      );
    }
  };

  const handleTaskOwnerChange = (searchValue) => {
    const requestFilters = {
      search: searchValue,
      is_active: 1,
      order_by: 'given_name',
      ...(testTasksVisible ? {} : { is_test: 0 })
    };
    dispatch(getAdminList(requestFilters));
  };

  const onDatetimeQuoteSubmit = ({ inputField: datetimeQuote }) => {
    const [month, day, year] = datetimeQuote.split('/');
    setSelectedEditingField('datetime_expected_completed');
    if (datetimeQuote !== formatters.dateUTC(serviceInstance.datetime_expected_completed)) {
      dispatch(
        updateJobField({
          job_id: serviceInstance.id,
          body: {
            datetime_expected_completed: datetimeQuote ? moment([year, month - 1, day]).format('YYYY-MM-DD') : null
          }
        })
      );
    }
  };
  const quotedAmount =
    serviceInstance.expected_value !== null
      ? numberWithCommas(convertAmountValueToString(serviceInstance.expected_value))
      : '';

  const jobSourceString =
    serviceInstance.request_source &&
    getRequestSourceByType({
      requestSourceName: serviceInstance.request_source_name,
      requestSourceId: serviceInstance.request_source_id,
      requestSource: serviceInstance.request_source,
      requestSourceType: serviceInstance.request_source_type,
      providerName: `${serviceInstance?.prefilled_provider?.user?.given_name || ''} ${
        serviceInstance?.prefilled_provider?.user?.family_name || ''
      }`
    });

  const [jobSourceText, jobSourceTaskId] =
    serviceInstance.request_source && jobSourceString.toLowerCase().split('chat ');

  return (
    <DataRow>
      <DataRecord flex={3}>
        <FieldTitle>Request Date</FieldTitle>
        <FieldValue>{formatDateStamp(serviceInstance.date_created)}</FieldValue>
      </DataRecord>

      <DataRecord flex={3}>
        <FieldTitle># days to quote</FieldTitle>
        <FieldValue>
          {serviceInstance.days_to_quote >= 1 ? serviceInstance.days_to_quote?.toString()[0] : '-'}
        </FieldValue>
      </DataRecord>
      <DataRecord flex={3}>
        <FieldTitle>Quote Amount</FieldTitle>
        <FieldValue>{quotedAmount ? `$${quotedAmount}` : '-'}</FieldValue>
      </DataRecord>

      <DataRecord flex={3}>
        <FieldTitle>Completed Date</FieldTitle>
        <FieldValue>
          {serviceInstance.datetime_completed ? formatDateStamp(serviceInstance.datetime_completed) : '-'}
        </FieldValue>
      </DataRecord>
      <DataRecord flex={2}>
        <FieldTitle>Quote Date</FieldTitle>
        <EditableLabel
          value={
            serviceInstance.datetime_expected_completed !== null
              ? formatters.dateUTC(serviceInstance.datetime_expected_completed)
              : ''
          }
          type="DATE"
          onBlur={onDatetimeQuoteSubmit}
          selectedEditingField={selectedEditingField}
          name="datetime_expected_completed"
          disabled={!serviceInstance.is_open}
        />
      </DataRecord>
      <DataRecord flex={2}>
        <FieldTitle>Est Amount</FieldTitle>
        <EditableLabel
          value={quotedAmount}
          onBlur={onQuoteAmountSubmit}
          type="CURRENCY"
          name="expected_value"
          disabled={!serviceInstance.is_open}
          selectedEditingField={selectedEditingField}
        />
      </DataRecord>
      <DataRecord flex={2} margin="12px 10px 12px 0">
        <FieldTitle>Job Owner</FieldTitle>
        <AutocompleteSearch
          searchList={adminList}
          name="task_owner"
          width={132}
          format={getFullName}
          defaultSearchText={serviceInstance.owner_id !== null ? getFullName(serviceInstance.owner) : ''}
          onSubmit={handleTaskOwnerSubmit}
          onSearch={handleTaskOwnerChange}
          loading={updateJobFieldLoading}
          disabled={!serviceInstance.is_open}
          selectedEditingField={selectedEditingField}
        />
      </DataRecord>
      <DataRecord flex={4}>
        <FieldTitle>Job Source</FieldTitle>
        <FieldValue>
          {serviceInstance.request_source ? (
            <>
              {jobSourceText}
              {jobSourceTaskId && (
                <Link to={`${routes.SERVICE}/${jobSourceTaskId}`} rel="noopener noreferrer" target="_blank">
                  chat {jobSourceTaskId}
                </Link>
              )}
            </>
          ) : (
            '-'
          )}
        </FieldValue>
      </DataRecord>
    </DataRow>
  );
};

export default JobSystemRow;
