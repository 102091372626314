import actionTypes from 'constants/actionTypes';

const defaultState = {
  metrics: {},
  snapshot: null,
  snapshotLoading: false
};

function reducer(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.GET_ANALYTIC_METRIC_FETCH: {
      const { metricType } = action.payload;

      return {
        ...state,
        metrics: {
          ...state.metrics,
          [metricType]: {
            data: [],
            loading: true
          }
        }
      };
    }
    case actionTypes.GET_ANALYTIC_METRIC_SUCCESS: {
      const { metricType, data } = action.payload;

      return {
        ...state,
        metrics: {
          ...state.metrics,
          [metricType]: {
            loading: false,
            data
          }
        }
      };
    }
    case actionTypes.GET_ANALYTIC_METRIC_ERROR: {
      const { metricType } = action.payload;

      return {
        ...state,
        metrics: {
          ...state.metrics,
          [metricType]: {
            data: [],
            loading: false
          }
        }
      };
    }

    case actionTypes.GET_ANALYTIC_SNAPSHOT_FETCH: {
      return {
        ...state,
        snapshotLoading: true
      };
    }
    case actionTypes.GET_ANALYTIC_SNAPSHOT_SUCCESS: {
      return {
        ...state,
        snapshot: action.payload,
        snapshotLoading: false
      };
    }

    case actionTypes.GET_ANALYTIC_SNAPSHOT_ERROR: {
      return {
        ...state,
        snapshotLoading: false
      };
    }

    default:
      return state;
  }
}

// selectors
export const selectAnalyticSnapshot = (state) => state.analytics.snapshot;
export const selectAnalyticSnapshotLoading = (state) => state.analytics.snapshotLoading;
export const selectAnalyticMetric = (state, metricType) => state.analytics.metrics[metricType];

export default reducer;
