import styled, { css } from 'styled-components';
import { NavLink as ReactRouterNavLink } from 'react-router-dom';

export const breadcrumbLinkStyle = css`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.BREADCRUMB};
  font-weight: 600;
  font-size: 20px;
  line-height: 1.4;
  vertical-align: middle;

  ${({ active, theme }) =>
    active &&
    `
    color: ${theme.colors.BREADCRUMB_DISABLED};
    cursor: default;
  `}
`;
export const NavLink = styled(ReactRouterNavLink)`
  ${breadcrumbLinkStyle}
`;
