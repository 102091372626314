import React from 'react';

import { useSelector } from 'hooks';
import routes from 'constants/routes';

import { selectServiceInstance, selectServiceLocation } from 'store/reducers/service';

import { DataRow, DataRecord, FieldTitle } from '../styled';
import { BundledTaskDescription, FieldValueWithMargin, GrayBoldLink, GrayBoldSpan } from './styled';

export const TaskInfo = () => {
  const serviceLocation = useSelector(selectServiceLocation);
  const serviceInstance = useSelector(selectServiceInstance);

  const trimDescription = (bundledDescription) => {
    const { bundled } = serviceLocation;
    return bundled.reduce((description, task) => description.replace(task.description, ''), bundledDescription).trim();
  };

  return (
    <DataRow>
      <DataRecord>
        <FieldTitle>Description</FieldTitle>
        {serviceLocation.bundled ? (
          <FieldValueWithMargin>
            <BundledTaskDescription>
              <GrayBoldSpan>ID {serviceInstance.id}:</GrayBoldSpan> {trimDescription(serviceInstance.description)}
            </BundledTaskDescription>
            {serviceLocation.bundled.map((bundledTask) => (
              <BundledTaskDescription key={`description-${bundledTask.id}`}>
                <GrayBoldLink to={`${routes.SERVICE}/${bundledTask.id}`} rel="noopener noreferrer" target="_blank">
                  ID {bundledTask.id}:
                </GrayBoldLink>{' '}
                {bundledTask.description}
              </BundledTaskDescription>
            ))}
          </FieldValueWithMargin>
        ) : (
          <FieldValueWithMargin>{serviceInstance.description}</FieldValueWithMargin>
        )}
      </DataRecord>
    </DataRow>
  );
};

export default TaskInfo;
