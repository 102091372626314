import React from 'react';

import { useSelector } from 'hooks';
import { selectCustomerInstance, selectCustomerInstanceLoading } from 'store/reducers/customer';
import { selectServiceInstance, selectServiceInstanceLoading } from 'store/reducers/service';
import { getHomeownerTags } from 'common/helpers/user';

import { Breadcrumb } from '../../components';

const CustomerBreadcrumb = ({ match, location }) => {
  const { customer_id } = match.params;
  const customer = useSelector(selectCustomerInstance);
  const job = useSelector(selectServiceInstance);
  const customerLoading = useSelector(selectCustomerInstanceLoading);
  const jobLoading = useSelector(selectServiceInstanceLoading);

  const hasLoading = jobLoading || customerLoading;
  let breadcrumbName = ` (${customer_id})`;

  if (!hasLoading) {
    const currentSplittedPathname = location.pathname.split('/');
    const isNestedPath = Boolean(job?.id && currentSplittedPathname[4] === job?.id?.toString());
    const customerName = isNestedPath ? job.user.full_name : customer?.user?.full_name;
    const emoji = getHomeownerTags({ stage: customer?.stage })?.EMOJI;

    breadcrumbName = `${emoji || ''} ${customerName || ''} (${customer_id})`;
  }

  return (
    <Breadcrumb
      breadcrumbName={breadcrumbName}
      hasLoading={hasLoading}
      isActive={match.pathname === location.pathname}
      pathname={match.pathname}
    />
  );
};

export default CustomerBreadcrumb;
