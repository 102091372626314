import React from 'react';
import { IconButton } from 'components';
import { invoiceStatuses } from 'common/enums/invoice';

export const InvoiceValidation = ({ invoiceStatus, onOpenValidationConfirmation }) => {
  if (invoiceStatus === invoiceStatuses.PENDING_ADMIN.TYPE) {
    return (
      <IconButton square height={32} width={140} onClick={onOpenValidationConfirmation}>
        <span>Send to HO</span>
      </IconButton>
    );
  }

  return null;
};
