import { createSearchParams as createQueryParams } from 'react-router-dom';

import { useRouter } from 'hooks';
import { defaultQueryParams } from 'common/enums/queryParams';
import { getExtendedDefaultQueryParams } from 'common/helpers/queryParams';

/***
 * reloads table informatiion on switch test tasks checkbox
 */
const useReplaceHistory = () => {
  const { queryParams, navigate } = useRouter();

  const handleReplaceHistory = ({ filters, navigationRoute }) => {
    const { search = '', ...otherFilters } = filters;

    const newQueryParams = {
      ...defaultQueryParams,
      ...otherFilters,
      ...(search ? { search } : {})
    };

    if (!navigationRoute) {
      queryParams.setAll(newQueryParams);
      return createQueryParams(newQueryParams).toString();
    }

    const searchParam = getExtendedDefaultQueryParams(newQueryParams);
    navigate(`${navigationRoute}?${searchParam}`, {
      replace: false
    });

    return searchParam;
  };

  return {
    handleReplaceHistory
  };
};

export default useReplaceHistory;
