import React from 'react';
import moment from 'moment-timezone';

import { useState, useDispatch, useSelector, useTheme } from 'hooks';
import { closeModal } from 'store/actions';
import modalTypes from 'constants/modalTypes';
import dates from 'constants/dates';

import { selectModalData } from 'store/reducers/modal';
import { fieldTypes } from 'common/enums/form';
import { invoiceItemTypes } from 'common/enums/invoice';

import { Button, Modal, HookForm, Line, Icon, IconButton, StyledTooltip } from 'components';
import { selectServiceInstance } from 'store/reducers/service';
import {
  Heading,
  Buttons,
  Content,
  Inputs,
  InputsItem,
  percentInputStyle,
  currencyInputStyle,
  dateInputStyle,
  textareaStyle,
  hiddenInput,
  Row,
  Title,
  ValueWrapper,
  Value,
  Summary,
  dividerStyle,
  Checkbox,
  AttachmentLabel,
  NoteFileName,
  NoteFile,
  Label,
  AttachFile,
  DobbyCashBalance,
  DobbyCash,
  TooltipText
} from './styled';
import { useForm, useWatch } from 'react-hook-form';
import { formatters } from 'utils';
import { paymentMethods } from 'common/enums/invoice';
import { openModal } from 'store/actions';
import { toValues } from './helpers';

const CloseInvoice = ({ modalType = modalTypes.CLOSE_INVOICE }) => {
  const dispatch = useDispatch();
  const { invoice = {}, closeInvoiceData } = useSelector(selectModalData);
  const invoiceValues = toValues(invoice);

  const {
    handleSubmit,
    control,
    formState: { isValid }
  } = useForm({
    mode: 'onChange',
    defaultValues: closeInvoiceData || invoiceValues
  });
  const data = useWatch({ control });
  const amount_material = Number(data.amount_material) * 100;
  const amount_labor_cost = Number(data.amount_labor_cost) * 100;
  const amount_flat_rate = Number(data.amount_flat_rate) * 100;
  const commission_value = Number(data.commission_value);
  const percent_stripe_fee = Number(data.percent_stripe_fee);
  const amount_tip = Number(invoice.amount_tip) || 0;

  const markupValue = (100 + commission_value) / 100;
  const amount_labor_display = Math.ceil(amount_labor_cost * markupValue);
  const amount_material_display = Math.ceil(amount_material * markupValue);
  const amount_flat_display = Math.ceil(amount_flat_rate * markupValue);

  const initial_amount_customer_paid =
    amount_labor_display + amount_material_display + amount_flat_display + amount_tip;
  const amount_customer_reward = data.amount_customer_reward
    ? Number(data.amount_customer_reward) * 100
    : data.amount_customer_reward;
  const amount_provider_payout = amount_labor_cost + amount_material + amount_flat_rate + amount_tip;
  const amount_platform_fee = initial_amount_customer_paid - amount_provider_payout;
  const amountStripeFee = parseInt((initial_amount_customer_paid * percent_stripe_fee) / 100);

  const amount_customer_paid = initial_amount_customer_paid - amount_customer_reward + amountStripeFee;
  const [attachedFiles, setAttachedFiles] = useState(closeInvoiceData?.attachedFiles || []);

  const handleCloseInvoice = () => {
    dispatch(
      openModal(modalTypes.CLOSE_INVOICE_CONFIRMATION, {
        invoiceData: {
          ...data,
          datetime_paid: moment(data.datetime_paid, dates.DATE_SHORT).format(dates.DATETIME_ISO),
          amount_customer_paid: Math.round(amount_customer_paid),
          type_of_payment: Number(data.type_of_payment),
          amount_provider_payout: Math.round(amount_provider_payout),
          amount_platform_fee: Math.round(amount_platform_fee),
          commission_value: Math.round(commission_value * 100),
          amount_labor_cost: Math.round(amount_labor_cost),
          amount_material: Math.round(amount_material),
          amount_flat_rate: Math.round(amount_flat_rate),
          items: [
            ...(amount_labor_cost
              ? [{ description: 'Labor', price: amount_labor_cost, item_section: invoiceItemTypes.LABOR.TYPE }]
              : []),
            ...(amount_material
              ? [{ description: 'Materials', price: amount_material, item_section: invoiceItemTypes.MATERIAL.TYPE }]
              : []),
            ...(amount_flat_rate
              ? [{ description: 'Flat Rate', price: amount_flat_rate, item_section: invoiceItemTypes.FLAT.TYPE }]
              : [])
          ],
          amount_display_labor_cost: Math.round(amount_labor_display),
          amount_display_material: Math.round(amount_material_display),
          percent_stripe_fee: Math.round(percent_stripe_fee * 100),
          amount_service_fee: Math.round(amountStripeFee),
          attachedFiles
        },
        closeInvoiceData: { ...data, attachedFiles }
      })
    );
  };

  const handleSelectDate = () => {
    dispatch(openModal(modalTypes.PAYMENT_DATE, { invoice, data: { ...data, attachedFiles } }));
  };

  const hideModal = () => dispatch(closeModal(modalType));
  const theme = useTheme();
  const handleAttachedFiles = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const files = Array.from(e.target.files);

    setAttachedFiles([...attachedFiles, ...files]);
  };
  const removeAttachedFile = (index) => {
    setAttachedFiles([...attachedFiles.slice(0, index), ...attachedFiles.slice(index + 1)]);
  };
  const serviceInstance = useSelector(selectServiceInstance);
  const customerUser = serviceInstance.customer;
  return (
    <Modal
      modalType={modalType}
      hideModal={hideModal}
      padding="0"
      maxWidth={424}
      minWidth={424}
      maxHeight={900}
      heading={<Heading>Close the Invoice</Heading>}>
      <Content>
        <HookForm.Radio
          label="Select Payment Type*"
          name="type_of_payment"
          control={control}
          rules={{ required: true }}
          options={[
            { name: 'Paid in cash', value: paymentMethods.CASH },
            { name: 'Wire transfer', value: paymentMethods.TRANSFER }
          ]}
        />

        <Inputs>
          <InputsItem>
            <HookForm.Input
              name="amount_labor_cost"
              control={control}
              fieldType={fieldTypes.INVOICE_CURRENCY}
              rules={{ required: true }}
              style={currencyInputStyle}
              label="Confirm Total Labor*"
              placeholder="Confirm"
            />
            <HookForm.Input
              name="amount_flat_rate"
              control={control}
              fieldType={fieldTypes.INVOICE_CURRENCY}
              rules={{ required: true }}
              style={currencyInputStyle}
              label="Confirm Flat Rate*"
              placeholder="Confirm"
            />
            <HookForm.Input
              name="commission_value"
              control={control}
              fieldType={fieldTypes.PERCENT}
              rules={{ required: true }}
              style={percentInputStyle}
              label="Confirm Dobby's Operating Margin*"
            />
            <div onClick={handleSelectDate}>
              <HookForm.Input
                name="datetime_paid"
                control={control}
                rules={{ required: true }}
                style={dateInputStyle}
                label="Payment Date*"
                placeholder="02/24/23"
              />
            </div>
          </InputsItem>
          <InputsItem>
            <HookForm.Input
              name="amount_material"
              control={control}
              fieldType={fieldTypes.INVOICE_CURRENCY}
              rules={{ required: true }}
              style={currencyInputStyle}
              label="Confirm Total Materials*"
            />
            <HookForm.Input
              name="percent_stripe_fee"
              control={control}
              fieldType={fieldTypes.PERCENT}
              rules={{ required: true }}
              style={currencyInputStyle}
              label="Confirm Service Fee*"
            />
          </InputsItem>
        </Inputs>
        <DobbyCashBalance>DobbyCash Balance</DobbyCashBalance>
        <DobbyCash>
          <StyledTooltip placement="top" arrow title="Total DobbyCash balance">
            <TooltipText>{formatters.dollarCurrencyDB(customerUser?.dobby_cash?.amount_current_balance)}</TooltipText>
          </StyledTooltip>
          <div>&#9679;</div>
          <StyledTooltip placement="top" arrow title="Personal funds">
            <TooltipText>{formatters.dollarCurrencyDB(customerUser?.dobby_cash?.amount_paid_balance)}</TooltipText>
          </StyledTooltip>
          <div>&#9679;</div>
          <StyledTooltip placement="top" arrow title="Dobby’s reward">
            <TooltipText>{formatters.dollarCurrencyDB(customerUser?.dobby_cash?.amount_bonus_balance)}</TooltipText>
          </StyledTooltip>
        </DobbyCash>

        <Checkbox>
          <HookForm.Checkbox
            control={control}
            name="is_apply_dobby_cash"
            label="Deducted amount from the DobbyCash balance"
            green
            checkboxBackground={theme.colors.CHECKBOX_BG}
          />
          <HookForm.Checkbox
            control={control}
            name="is_transfered"
            label="Already paid out to Pro"
            green
            checkboxBackground={theme.colors.CHECKBOX_BG}
          />
        </Checkbox>
        <Line lineStyle={dividerStyle} />
        <Summary>Summary</Summary>

        <Row secondary>
          <Title>Pro Payout</Title>
          <ValueWrapper>
            <Value>{formatters.dollarCurrencyDB(Math.round(amount_provider_payout)?.toString()) || '0.00'}</Value>
          </ValueWrapper>
        </Row>

        <Row secondary>
          <Title>Dobby’s Operating Margin</Title>
          <ValueWrapper>
            <Value>{formatters.dollarCurrencyDB(Math.round(amount_platform_fee)?.toString()) || '0.00'}</Value>
          </ValueWrapper>
        </Row>

        <Row secondary>
          <Title>Stripe Fee</Title>
          <ValueWrapper>
            <Value>{formatters.dollarCurrencyDB(Math.round(amountStripeFee)?.toString()) || '0.00'}</Value>
          </ValueWrapper>
        </Row>

        <Row>
          <Title>HO Payment</Title>
          <ValueWrapper>
            <Value>{formatters.dollarCurrencyDB(Math.round(amount_customer_paid)?.toString()) || '0.00'}</Value>
          </ValueWrapper>
        </Row>
        <Line lineStyle={dividerStyle} />

        <Label>
          <div>Add Notes</div>
          <StyledTooltip arrow placement="top" title={'For internal usage, visible only in the Gryffindor'}>
            <div>
              <Icon name="infoOutlined" color={theme.colors.DARK_4} size={12} margin="2px 0 0 " />
            </div>
          </StyledTooltip>
        </Label>
        <HookForm.ControlledTextarea
          name="payment_note"
          control={control}
          placeholder="Share the couple of words to keep historical data of this invoice"
          rules={{
            maxLength: 500,
            maxHeight: 88
          }}
          style={textareaStyle}
        />
        <AttachFile>
          <div>
            {attachedFiles.map((file, index) => (
              <NoteFile key={index}>
                <Icon name="file" size={17} />
                <NoteFileName>{file.name}</NoteFileName>
                <IconButton iconName="close2" iconSize={8} size={16} onClick={() => removeAttachedFile(index)} />
              </NoteFile>
            ))}
            <AttachmentLabel>
              <input type="file" multiple style={hiddenInput} onChange={handleAttachedFiles} />
              <Icon name="attachment" size={18} color={theme.colors.GREY} />
              <span>Attach Media</span>
            </AttachmentLabel>
          </div>
        </AttachFile>
        <Buttons>
          <Button variant="primary" onClick={hideModal}>
            Cancel
          </Button>
          <Button disabled={!isValid} onClick={handleSubmit(handleCloseInvoice)}>
            Close the invoice
          </Button>
        </Buttons>
      </Content>
    </Modal>
  );
};

export default CloseInvoice;
