import { call, takeLatest, put } from 'redux-saga/effects';
import api_constants from 'constants/api';
import actionTypes from 'constants/actionTypes';
import Api from 'services/ApiService';

function* getAdminInfo() {
  try {
    const response = yield call(Api.amplifyGet, `${api_constants.ADMIN}/me`);
    if (!response) {
      throw new Error();
    }
    yield put({
      type: actionTypes.GET_ADMIN_INFO_SUCCESS,
      payload: response
    });
  } catch (error) {
    console.log(error);
    yield put({
      type: actionTypes.GET_ADMIN_INFO_ERROR
    });
  }
}

export default function* watchGetAdminInfo() {
  yield takeLatest(actionTypes.GET_ADMIN_INFO_FETCH, getAdminInfo);
}
