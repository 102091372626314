import { call, takeLatest, put, select } from 'redux-saga/effects';
import api_constants from 'constants/api';
import actionTypes from 'constants/actionTypes';
import Api from 'services/ApiService';

import { searchPrefixes } from 'common/enums/app';
import { prepareSearchForBackendRequest } from 'common/helpers/transformers';
import { generateQueryParams } from 'common/helpers/httpRequest';
import { selectGlobalFilters } from 'store/reducers/app';

function* getConversationList({ payload: { page = 1, search } = {} } = {}) {
  const { searchPrefixType, preparedSearchValue } = prepareSearchForBackendRequest({ search });
  const hasSearchByMessages = search && (searchPrefixType?.TYPE === searchPrefixes.MESSAGE.TYPE || !searchPrefixType);

  try {
    const { owner_id, ...glodalFilters } = yield select(selectGlobalFilters);
    const filterParams = {
      page,
      search: preparedSearchValue,
      ...glodalFilters
    };

    const response = yield call(
      Api.amplifyGet,
      `${api_constants.COMMUNICATION_SMS}${hasSearchByMessages ? '' : '/conversation'}${generateQueryParams(
        filterParams
      )}`
    );
    if (!response?.results) {
      throw new Error();
    }
    yield put({
      type: actionTypes.GET_CONVERSATION_LIST_SUCCESS,
      payload: {
        page,
        count: response.count,
        list: hasSearchByMessages
          ? response.results?.map(({ conversation, date_created, id, message }) => ({
              ...conversation,
              datetime_last_message: date_created,
              message_id: id,
              last_message: message
            }))
          : response.results
      }
    });
  } catch (err) {
    yield put({
      type: actionTypes.GET_CONVERSATION_LIST_ERROR
    });
    console.log(err);
  }
}

export default function* watchGetConversationList() {
  yield takeLatest(actionTypes.GET_CONVERSATION_LIST_FETCH, getConversationList);
}
