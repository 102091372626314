import styled from 'styled-components';

export const FlexContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.DARK_1};
`;

export const JobDetailsContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 400px;
  width: 100%;
`;

export const ProviderListContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 624px;
  width: 624px;
`;

export const ProviderListWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  margin: 7px 0px 5px 1px;
  overflow-y: overlay;
  overflow-x: hidden;
`;
