import styled from 'styled-components';
import styles from 'constants/styles';

export const FiltersContainer = styled.div`
  width: 100%;
  overflow: overlay;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px 12px 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  height: ${styles.layout.filtersHeaderHeight}px;
`;

export const FiltersWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const FilterWrapper = styled.div`
  background: ${styles.colors.LIGHT_GRAY_2};
  border-radius: 61px;
  padding: 4px 3px 3px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: ${({ minWidth }) => minWidth || 95}px;
  ${({ marginRight }) => marginRight && `margin-right: ${marginRight}px`};
`;

export const FilterHeader = styled.h4`
  margin: 0;
  padding: 0;
  ${({ marginRight }) => marginRight && `margin-right: ${marginRight}px`};
  font-weight: 600;
  font-size: 14px;
  line-height: 1.2;
  text-transform: ${({ capitalize }) => (capitalize ? 'capitalize' : 'uppercase')};
  color: ${styles.colors.GRAY};
  white-space: nowrap;
`;

export const FilterValue = styled.span`
  font-weight: 500;
  font-size: 13px;
  line-height: 1.31;
  color: ${styles.colors.BLACK};
  margin-right: 9px;
  white-space: nowrap;
  ${({ hasCapitalizeDisabled }) => !hasCapitalizeDisabled && `text-transform: capitalize;`}
  text-overflow: ellipsis;
`;

export const RemoveAllButton = styled.button`
  border: none;
  box-shadow: none;
  min-width: 95px;
  outline: 0;
  justify-content: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${styles.colors.LIGHT_GRAY_2};
  color: ${styles.colors.BLACK};
  border-radius: 61px;
  padding: 4px 3px 5px;

  &:focus {
    outline: 0;
  }
`;
