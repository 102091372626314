import React from 'react';
import { NavLink } from 'react-router-dom';

import { useMemo, useNavigationBadges } from 'hooks';
import { useActiveMenuItem, useAwsCredentials } from 'navigation/hooks';
import { sidebarMenuItems } from 'common/enums/navigation';
import routes from 'constants/routes';
import { getExtendedDefaultQueryParams, getDefaultNavigationParams } from 'common/helpers/queryParams';
import { Icon } from 'components';
import { MenuItem } from './components';
import { LogoText, NavBar, Navigation, NavigationHolder, NavigationLinkHolder, LogoWrapper } from './styled';

const Sidebar = ({ items }) => {
  useAwsCredentials();
  const { activeMenuItem, handleMenuItemClick } = useActiveMenuItem();
  const { menuBadges } = useNavigationBadges({ init: true });

  const sidebarItems = useMemo(() => Object.values(items), [items]);

  const logoNavLink = {
    pathname: routes.PRIORITY_TASKS,
    search: getExtendedDefaultQueryParams(getDefaultNavigationParams({ menuItem: sidebarMenuItems.MY_JOBS }))
  };

  return (
    <Navigation>
      <NavigationLinkHolder>
        <NavLink
          to={logoNavLink}
          onClick={handleMenuItemClick({ menuItem: sidebarMenuItems.MY_JOBS, search: logoNavLink.search })}>
          <LogoWrapper>
            <Icon name="logo" width={123} height={32} />
            <LogoText>Gryffindor OS</LogoText>
          </LogoWrapper>
        </NavLink>
      </NavigationLinkHolder>
      <NavBar>
        <NavigationHolder>
          {sidebarItems.map((menuItem, index) => (
            <MenuItem
              key={`menu-item-${index}`}
              menuItem={menuItem}
              onMenuItemChange={handleMenuItemClick}
              menuBadge={menuItem.COUNTER_NAME ? menuBadges?.[menuItem.COUNTER_NAME] : null}
              active={menuItem.TYPE === activeMenuItem.TYPE ? 'true' : undefined}
            />
          ))}
        </NavigationHolder>
      </NavBar>
    </Navigation>
  );
};

export default Sidebar;
