import styled from 'styled-components';
import styles from 'constants/styles';
import { NavLink } from 'react-router-dom';

export const PopupContainer = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 3;
  background-color: ${({ theme }) => theme.colors.DARK_2};
  padding: 16px;
  max-width: 360px;
  min-width: 360px;
`;

export const SpaceBetweenRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

export const Address = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 1.14;

  color: ${({ theme }) => theme.colors.WHITE};
`;

export const VisitDate = styled.div`
  background: ${({ theme }) => theme.colors.DARK_GREEN_2};

  color: ${styles.colors.WHITE};

  text-transform: capitalize;
  font-weight: 500;
  font-size: 12px;
  line-height: 1.17;
  text-align: center;
  border-radius: 20px;
  padding: 6px 24px;
  white-space: nowrap;
  position: relative;

  ${({ isEllipsis }) =>
    isEllipsis &&
    `
    overflow: hidden;
    text-overflow: ellipsis;
  `}
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}px`};
`;

export const JobLink = styled(NavLink)`
  font-weight: 500;
  font-size: 12px;
  line-height: 1.17;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.WHITE};
  outline: none;
  margin-left: 16px;
  &:hover:not([disabled]) {
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const iconStyles = {
  marginLeft: '2px'
};
