import React from 'react';

import { HookForm, Icon } from 'components';
import { fieldTypes } from 'common/enums/form';
import {
  // campaignNavigationTypes,
  cohortTypes
} from 'common/enums/campaigns';
import { DateInput } from './styled';
import {
  // ChatMessageSetUp,
  CohortDetails
} from './components';
// import { CreateJobNavigation } from '../../../';

export const Event = ({ control, onSetExecutionDate, values, onSetIDs, onSetUploadedCSVFile, onScrollToEnd }) => {
  const handleSetExecutionDate = () => {
    onSetExecutionDate(values.execution?.cohort_type == cohortTypes.ALL_USERS);
  };
  return (
    <>
      <div style={{ margin: '24px 0 16px 0' }}>
        <div
          style={{
            fontSize: '20px',
            color: '#FFFFFF'
          }}>
          Event set up
        </div>
      </div>
      <div onClick={handleSetExecutionDate}>
        <DateInput>
          <HookForm.Input
            name="execution.datetime_execute"
            control={control}
            label="Execution date*"
            placeholder="Select Date & Time"
            readOnly
            renderCustomIcon={() => <Icon name="arrowFillBottom" backgroundSize={17} />}
            style={{
              cursor: 'pointer'
            }}
            fieldType={
              values.execution?.cohort_type == cohortTypes.ALL_USERS
                ? fieldTypes.DATE_EXECUTION
                : fieldTypes.DATETIME_EXECUTION_LOCAL
            }
          />
        </DateInput>
      </div>
      <div style={{ margin: '24px 0 16px 0' }}>
        <HookForm.Radio
          label="Cohort*"
          name="execution.cohort_type"
          control={control}
          row
          options={[
            { name: 'Working Area', value: cohortTypes.WORKING_AREA },
            { name: 'Enter ID', value: cohortTypes.RECIPIENTS_LIST },
            { name: 'Upload File', value: cohortTypes.CSV_FILE },
            { name: 'All Install Base', value: cohortTypes.ALL_USERS }
          ]}
        />
      </div>
      <CohortDetails
        cohort_type={values.execution?.cohort_type}
        control={control}
        recipients_list={values.execution?.recipients_list || []}
        onSetIDs={onSetIDs}
        csv_cohort_name={values.execution?.csv_cohort_name}
        onSetUploadedCSVFile={onSetUploadedCSVFile}
        onScrollToEnd={onScrollToEnd}
      />
      {/* <div style={{ margin: "30px 0" }}>
        <HookForm.Radio
          label="Notification tap navigation*"
          name="path_type"
          control={control}
          gap="12px"
          row
          rules={{ required: true }}
          options={[
            { name: campaignNavigationTypes.ARTICLE.TYPE, value: campaignNavigationTypes.ARTICLE.TYPE },
            { name: campaignNavigationTypes.HOME_PAGE.TYPE, value: campaignNavigationTypes.HOME_PAGE.TYPE },
            { name: campaignNavigationTypes.JOB_CREATION.TITLE, value: campaignNavigationTypes.JOB_CREATION.TYPE }
          ]}
        />
      </div>
      {values?.path_type === campaignNavigationTypes.JOB_CREATION.TYPE && <CreateJobNavigation control={control} register={register} name_prefix='campaign_push.path_type' />}
      {values?.path_type === campaignNavigationTypes.SUPPORT_CHAT.TYPE && (
        <ChatMessageSetUp control={control} />
      )} */}
    </>
  );
};
