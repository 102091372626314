import React from 'react';

import { useDispatch, useSelector } from 'hooks';
import modalTypes from 'constants/modalTypes';

import { closeModal } from 'store/actions/modal';
import { toggleNotificationsPopup } from 'store/actions/navigation';
import { selectModalData } from 'store/reducers/modal';

import { ModalConfirmation } from 'components';

const ClearAllNotificationsResult = () => {
  const dispatch = useDispatch();
  const modalData = useSelector(selectModalData);

  const handleResultModalClose = () => {
    dispatch(closeModal(modalTypes.CLEAR_ALL_PERSISTENT_NOTIFICATIONS_RESULT));
    dispatch(toggleNotificationsPopup());
  };

  if (!modalData?.text) {
    return null;
  }

  return (
    <ModalConfirmation
      maxWidth={null}
      modalType={modalTypes.CLEAR_ALL_PERSISTENT_NOTIFICATIONS_RESULT}
      smallPaddings={false}
      title={modalData.title}
      text={modalData.text}
      hideModal={handleResultModalClose}
      secondaryButton={{
        title: 'OK',
        onClick: handleResultModalClose
      }}
    />
  );
};

export default ClearAllNotificationsResult;
