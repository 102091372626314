import styled from 'styled-components';

export const DataRecord = styled.div`
  display: flex;
  flex-direction: column;
  margin: 12px 24px;
  flex: 1;
`;

export const PlaceholderText = styled.span`
  padding: 0 10px 10px;
  color: ${({ theme }) => theme.colors.WHITE};
`;

export const NotesSection = styled.div`
  background-color: ${({ theme }) => theme.colors.DARK_3};
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: overlay;
  padding: 10px 0;
  position: relative;
`;
