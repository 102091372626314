import React, { useState } from 'react';
import { Icon, StyledTooltip } from 'components';
import { Notes } from './Notes';

import { IconWrapper, NoteTitle, TooltipBody, tooltipStyle } from './styled';
import { useTheme } from 'styled-components';
import { VideoPlayer } from 'modals/common';
import PDFViewer from 'components/PDFViewer';
import Lightbox from 'react-image-lightbox';

export const ProviderNotes = ({ userId, notesCount }) => {
  const theme = useTheme();
  const [mediaUrl, setMediaUrl] = useState(null);
  const [isLightBoxShown, setIsLightBoxShown] = useState(false);
  const [isPDFViewerShown, setIsPDFViewerShown] = useState(false);
  const [isVideoPlayerShown, setIsVideoPlayerShown] = useState(false);

  const closePDFViewer = () => {
    setIsPDFViewerShown(false);
    setMediaUrl(null);
  };

  const closeImageLightBox = () => {
    setIsLightBoxShown(false);
    setMediaUrl(null);
  };

  const closeVideoPlayer = () => {
    setIsVideoPlayerShown(false);
    setMediaUrl(null);
  };

  return (
    <IconWrapper
      onClick={(e) => {
        e.stopPropagation();
      }}>
      <StyledTooltip
        dark
        title={
          <Notes
            setIsLightBoxShown={setIsLightBoxShown}
            setIsPDFViewerShown={setIsPDFViewerShown}
            setIsVideoPlayerShown={setIsVideoPlayerShown}
            setMediaUrl={setMediaUrl}
            userId={userId}
          />
        }
        tooltipStyle={tooltipStyle}
        arrow
        placement="top">
        <TooltipBody>
          <Icon clickable name="comment" color={theme.colors.DARK_4} size={17} margin="0 5px 0 0" />
          <NoteTitle>{notesCount}</NoteTitle>
        </TooltipBody>
      </StyledTooltip>
      {isLightBoxShown && <Lightbox mainSrc={mediaUrl} onCloseRequest={closeImageLightBox} />}
      {isPDFViewerShown && <PDFViewer onViewerClose={closePDFViewer} mediaUrl={mediaUrl} />}
      {isVideoPlayerShown && <VideoPlayer signedUrl={mediaUrl} onModalClose={closeVideoPlayer} />}
    </IconWrapper>
  );
};
