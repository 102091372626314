import { useCallback, useMemo, useReloadTable, useRouter } from 'hooks';
import routes from 'constants/routes';
import { defaultParams, defaultQueryParams } from 'common/enums/queryParams';
import { sidebarMenuItems } from 'common/enums/navigation';
import { findNavigationTab } from 'common/helpers/navigation';

import { useActiveMenuItem, useReplaceHistory } from 'navigation/hooks';
import { navigationTabs } from 'common/enums/navigation';

/***
 * handle search input in top navigation
 */
const useSearchFilter = () => {
  const { queryParams, pathname } = useRouter();
  const filters = queryParams.getAll();
  const { handleReloadTable } = useReloadTable();
  const { activeMenuItem } = useActiveMenuItem();
  const { handleReplaceHistory } = useReplaceHistory();

  const searchPlaceholder = useMemo(() => {
    if (filters.tab_id === navigationTabs.SKILLS.TYPE) {
      return 'Search skills';
    }
    if ([routes.CUSTOMER].includes(pathname)) {
      return 'Find by ID/Name';
    }
    const userRoutes = [routes.AGENT, routes.PROVIDER];
    if (userRoutes.includes(pathname)) {
      return 'Find by ID/Name/phone number';
    }

    if (pathname === routes.CAMPAIGNS) {
      return 'Find Campaigns';
    }
    
    return 'Find by job ID/user ID/name';
  }, [pathname, filters.tab_id]);

  const searchType = useMemo(
    () => (!pathname.split('/')[2] && activeMenuItem.HAS_SEARCH ? activeMenuItem.TYPE : null),
    [pathname, activeMenuItem.HAS_SEARCH, activeMenuItem.TYPE]
  );

  const hasNavigationChange = useCallback(() => {
    const hasRouteChange = pathname === routes.PRIORITY_TASKS && filters.menu_item !== sidebarMenuItems.MY_JOBS.TYPE;
    const hasMenuItemChange =
      hasRouteChange || (pathname === routes.SERVICE && filters.menu_item !== sidebarMenuItems.ALL_JOBS.TYPE);
    const newSortingFilters = hasMenuItemChange ? defaultQueryParams : {};

    return {
      hasMenuItemChange,
      hasRouteChange,
      newSortingFilters
    };
  }, [filters.menu_item, pathname]);

  const getNavigationMenuItem = useCallback(
    ({ hasMenuItemChange }) => {
      const menuItem = hasMenuItemChange ? sidebarMenuItems.ALL_JOBS : activeMenuItem;
      const tabItem = findNavigationTab({ menuItemType: menuItem.TYPE, tabId: filters.tab_id });

      return {
        menuItem,
        tabItem
      };
    },
    [activeMenuItem, filters.tab_id]
  );

  const handleSearch = (searchText) => {
    const { tab_id, ...otherFilters } = filters;
    const { hasMenuItemChange, hasRouteChange, newSortingFilters } = hasNavigationChange();
    const { menuItem, tabItem } = getNavigationMenuItem({ hasMenuItemChange });

    const navigationFilters = {
      ...otherFilters,
      ...newSortingFilters,
      menu_item: menuItem.TYPE,
      ...(tabItem?.TYPE ? { tab_id: tabItem.TYPE } : {}),
      [defaultParams.PAGE.NAME]: defaultParams.PAGE.DEFAULT_VALUE,
      search: searchText
    };

    const locationSearch = handleReplaceHistory({
      filters: navigationFilters,
      ...(hasRouteChange ? { navigationRoute: menuItem?.ROUTE } : {})
    });

    // menu item changes on replace history in useActiveMenuItem hook
    // handleChangeActiveMenuItem cares about reloading information
    if (!hasMenuItemChange) {
      handleReloadTable({
        newNavigationFilters: navigationFilters,
        newRequestFilters: {
          [defaultParams.PAGE.NAME]: defaultParams.PAGE.DEFAULT_VALUE,
          search: searchText
        },
        locationSearch
      });
    }
  };

  return {
    searchType,
    handleSearch,
    searchPlaceholder,
    searchValue: filters.search || ''
  };
};

export default useSearchFilter;
