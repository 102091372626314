import React from 'react';
import { Tooltip, Icon } from 'components';
import { useTheme } from 'styled-components';

export const IconTooltip = ({ title, inline }) => {
  const theme = useTheme();
  return (
    <Tooltip
      title={title}
      inline={inline}
      left={0}
      bottom={28}
      minWidth={220}
      wrappedComponent={
        <Icon
          backgroundColor={theme.colors.INFO_BG}
          color={theme.colors.INFO_ICON}
          backgroundSize={12}
          size={2}
          name="info"
          clickable
        />
      }
    />
  );
};
