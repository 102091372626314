import React from 'react';
import ReactPlayer from 'react-player/lazy';

import modalTypes from 'constants/modalTypes';
import { useDispatch, useSelector } from 'hooks';
import { Modal } from 'components';
import { selectModalData } from 'store/reducers/modal';
import { closeModal } from 'store/actions';

export const VideoPlayer = ({ onModalClose, signedUrl } = {}) => {
  const dispatch = useDispatch();
  const modalData = useSelector(selectModalData);

  const handleCloseModal = () => {
    if (onModalClose) {
      onModalClose();
      return;
    }
    dispatch(closeModal(modalTypes.VIDEO_PLAYER));
  };

  return (
    <Modal
      maxWidth={null}
      maxHeight={null}
      modalType={signedUrl ? null : modalTypes.VIDEO_PLAYER}
      visible={Boolean(signedUrl)}
      smallPaddings={false}
      text={modalData.errorMessage}
      hideModal={handleCloseModal}
      closeButton>
      <ReactPlayer
        url={signedUrl || modalData?.signedUrl}
        controls
        style={{ marginTop: 14 }}
        config={{ file: { attributes: { disablePictureInPicture: true } } }}
        width={'70vw'}
        height={'70vh'}
        playing
      />
    </Modal>
  );
};
