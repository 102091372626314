import styled from 'styled-components';
import styles from 'constants/styles';

export const Label = styled.label`
  font-weight: 600;
  font-size: 12px;
  line-height: 1.7;
  color: ${styles.colors.GRAY};
`;

export const Header = styled.span`
  flex: 1;
  font-weight: ${({ fontWeight }) => fontWeight || 700};
  font-size: ${({ fontSize }) => fontSize || 24}px;
  line-height: 1.2;
  ${({ margin }) => (margin ? `margin: ${margin}` : 'margin: 0 0 25px 0')};
`;

export const LoadingContainer = styled.div`
  display: flex;
  padding: 50px;
  justify-content: center;
  text-align: center;
  position: relative;
`;

export const ProviderListSubheader = styled.div`
  display: flex;
  flex-direction: column;
  margin: 15px 0 15px 20px;
`;
