import styled from 'styled-components';

export const Heading = styled.div`
  font-weight: 400;
  font-size: 20px;
  line-height: 1.2;
  color: ${({ theme }) => theme.colors.WHITE};
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
`;

export const Content = styled.div`
  padding: 32px 24px;
  width: 100%;
`;

export const Inputs = styled.div`
  display: flex;
  gap: 33px;
  margin: 25px 0 0 0;
`;

export const InputsItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;

  color: ${({ secondary, theme }) => (secondary ? theme.colors.DARK_GREY : theme.colors.INVOICE_TEXT)};
`;

export const Title = styled.label`
  font-weight: 500;
  font-size: 12px;
  line-height: 1.17;
`;

export const ValueWrapper = styled.div`
  display: flex;
  justify-content: end;
  font-weight: 700;
  ${({ accent, theme }) => accent && `color: ${theme.colors.GREEN}`};
`;

export const Value = styled(Title)`
  padding: 0 0 0 15px;
`;
export const Summary = styled.div`
  margin-bottom: 8px;
  color: ${({ theme }) => theme.colors.INVOICE_TEXT};
  font-weight: 700;
  font-size: 14px;
  line-height: 1.14;
`;

export const Checkbox = styled.div`
  margin: 24px 0 0;
  display: flex;
  flex-direction: column;
  gap: 18px;
`;

export const AttachmentLabel = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-right: 20px;
  color: ${({ theme }) => theme.colors.DARK_GREY};
  gap: 4px;
  font-weight: 500;
  font-size: 12px;
  line-height: 1.17;

  cursor: pointer;
`;

export const NoteFileName = styled.span`
  color: ${({ theme }) => theme.colors.INVOICE_TEXT};
  font-size: 12px;
  font-weight: 600;
  line-height: 1.7;
  margin: 0 5px;
`;

export const NoteFile = styled.div`
  display: flex;
  align-items: center;
`;

export const Label = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  gap: 7px;
  color: ${({ theme }) => theme.colors.DARK_GREY};
`;

export const AttachFile = styled.div`
  margin: -19px 0 4px;
  position: relative;
  z-index: 10;
`;

export const DobbyCash = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.WHITE};
  display: flex;
  gap: 5px;
  align-items: center;
`;

export const DobbyCashBalance = styled.div`
  font-weight: 500;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.DARK_GREY};
  margin: 24px 0 9px;
`;
export const TooltipText = styled.div`
  &:hover {
    color: ${({ theme }) => theme.colors.DARK_GREEN};
  }
  cursor: default;
`;

export const percentInputStyle = {
  width: 60,
  minWidth: 60
};
export const currencyInputStyle = {
  width: 110,
  minWidth: 110
};
export const dateInputStyle = {
  ...currencyInputStyle,
  pointerEvents: 'none'
};
export const dividerStyle = {
  marginTop: 20,
  marginBottom: 20
};

export const textareaStyle = {
  minHeight: '70px'
};

export const hiddenInput = {
  display: 'none'
};
