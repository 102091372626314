import React, { lazy, useEffect } from 'react';

import { useDispatch, useRouter, useMemo, useSelector, useState } from 'hooks';
import { conversationTypes } from 'common/enums/chat';
import { siblingPaginationTypes } from 'common/enums/navigation';
import { userGroups } from 'common/enums/user';
import routes from 'constants/routes';
import modalTypes from 'constants/modalTypes';

import { resetUserChatUnreadMessagesDivider, resetUserChatUnreadMessagesMark } from 'store/actions/supportChat';
import { getCustomerInstance, getCustomerLocation, getCustomerInspectionReport } from 'store/actions/customer';
import { deletePersistentNotification, openModal, getUserNoteList } from 'store/actions';

import { selectCustomerInstance, selectCustomerInstanceLoading, selectCustomerLocation } from 'store/reducers/customer';
import { LoadingSpinner, OUTDATED_TABS, Button, NotExist } from 'components';
import { Chat, UserDetails, UserServiceLists, Notes, Reviews, Questions } from 'containers';
import styles from 'constants/styles';
import {
  LoadingContainer,
  Sections,
  ActionContainer,
  ColumnContainer,
  NotesWrapper,
  DataRow,
  DataRecord
} from './styled';
import { useTheme } from 'styled-components';
import { CustomerReferrals } from '../../containers';
const PDFViewer = lazy(() => import('../../components/PDFViewer'));

const CustomerPage = () => {
  const dispatch = useDispatch();
  const {
    params,
    location: { state: locationState }
  } = useRouter();
  const { customerLocation, instance, instanceLoading } = useSelector((state) => ({
    customerLocation: selectCustomerLocation(state),
    instance: selectCustomerInstance(state),
    instanceLoading: selectCustomerInstanceLoading(state)
  }));
  const customerId = useMemo(() => Number(params.customer_id), [params.customer_id]);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [isNoteForm, setNoteForm] = useState(false);
  const [mediaUrl, setMediaUrl] = useState(null);
  const [isPdfViewer, setPdfViewer] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    dispatch(getCustomerInstance(customerId));
    dispatch(getCustomerLocation({ customerId }));
    dispatch(getUserNoteList({ userId: customerId }));
    if (customerId && locationState?.openByNotif) {
      setActiveTabIndex(1);
    }
    return () => {
      dispatch(resetUserChatUnreadMessagesDivider(customerId));
      dispatch(resetUserChatUnreadMessagesMark(customerId));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId]);

  const deleteSupportChatPersistentNotifications = () => {
    dispatch(deletePersistentNotification({ user_id: customerId, conversation_type: conversationTypes.SUPPORT }));
  };

  const handleTabChange = (index) => {
    setActiveTabIndex(index);
    if (index === 1) {
      deleteSupportChatPersistentNotifications();
    }
  };

  const handleOpenReport = () => {
    dispatch(
      getCustomerInspectionReport({
        user_id: customerId,
        onSuccess: (url) => {
          setPdfViewer(true);
          setMediaUrl(url);
        }
      })
    );
  };

  const closeReportViewer = () => {
    setPdfViewer(false);
    setMediaUrl(null);
  };

  const handleOpenUploadModal = () => {
    dispatch(openModal(modalTypes.UPLOAD_INSPECTION_REPORT, { customerId }));
  };

  const toggleNoteForm = () => setNoteForm((value) => !value);

  if (instance.id !== customerId && instanceLoading) {
    return (
      <LoadingContainer>
        <LoadingSpinner text="Please wait" type="logo" />
      </LoadingContainer>
    );
  }

  if (!instance.id) {
    return <NotExist name="Homeowner" id={customerId} />;
  }

  const userDetails = {
    user: {
      ...instance.user,
      inspection_original_name: instance.inspection_original_name,
      additional_user_id: instance.id,
      subscription: instance.subscription,
      lifetime_margin: instance.lifetime_margin,
      lifetime_margin_percent: instance.lifetime_margin_percent,
      social_profile: instance.social_profile,
      planning_mode_link: instance.planning_mode_link,
      home_listing: instance.home_listing,
      count_referral_jobs: instance.count_referral_jobs,
      stage: instance.stage,
      days_since_last_completed: instance.days_since_last_completed
    },
    isCreateJobEnabled: instance.is_create_job_enabled,
    isRemindPaymentEnabled: instance.is_remind_payment,
    location: customerLocation,
    deviceData: instance.platform,
    onOpenReport: handleOpenReport,
    onUploadReport: handleOpenUploadModal,
    userGroupId: userGroups.CUSTOMER.TYPE
  };

  return (
    <Sections>
      <ColumnContainer>
        <UserDetails {...userDetails} />
        <DataRow paddingTop="15">
          <NotesWrapper>
            <Notes
              type="homeowner"
              showForm={isNoteForm}
              userId={customerId}
              openEditNoteForm={toggleNoteForm}
              closeEditNoteForm={toggleNoteForm}
              displayNotesLoading
            />
          </NotesWrapper>
        </DataRow>
        <DataRow>
          <DataRecord>
            {!isNoteForm && <Button title="Add Notes" variant="primary" onClick={toggleNoteForm} />}
          </DataRecord>
        </DataRow>
      </ColumnContainer>
      <ActionContainer>
        <OUTDATED_TABS
          headers={['Jobs', 'Direct Chat', 'Check the Price', 'Reviews', 'Referrals']}
          border
          headerWrapperStyle={{ paddingLeft: '8px' }}
          onTabChange={handleTabChange}
          defaultTabIndex={activeTabIndex}
          bg={theme.colors.DARK_1}
          minHeight={`calc(100vh - ${styles.layout.headerHeight}px)`}
          boldFont>
          <UserServiceLists
            customerId={customerId}
            userGroupId={userGroups.CUSTOMER.TYPE}
            rootId={customerId}
            siblingPaginationType={siblingPaginationTypes.CUSTOMER_JOB}
            routePrefix={`${routes.CUSTOMER}/${customerId}`}
          />
          <Chat userId={customerId} hasCustomerChat userInstance={instance.user} />
          <Questions />
          <Reviews isCustomersReviews />
          <CustomerReferrals userId={customerId} />
        </OUTDATED_TABS>
      </ActionContainer>
      {isPdfViewer && <PDFViewer mediaUrl={mediaUrl} onViewerClose={closeReportViewer} />}
    </Sections>
  );
};

export default CustomerPage;
