import styled from 'styled-components';

export const ColumnFlex = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: overlay;
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
`;

export const NavigationPlaceholder = styled.div`
  display: flex;
  min-width: ${({ theme }) => theme.layout.navigationWidth}px;
  width: ${({ theme }) => theme.layout.navigationWidth}px;
  background-color: ${({ theme }) => theme.colors.BRAND_DARK};
`;

export const RowFlex = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  max-width: 100%;
  background-color: ${({ theme }) => theme.colors.TABLE_BG};
`;
