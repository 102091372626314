import { formatters, convertAmountValueToString } from 'utils';
import { getInvoiceType } from 'common/helpers/invoice';
import dates from 'constants/dates';
import { systemStatuses } from 'common/enums/job';
import { INVOICE_TYPE_DEFAULT } from 'common/constants/invoice';
import { invoiceTypes, invoiceItemTypes } from 'common/enums/invoice';
import { paymentStatuses, planStages, quoteStatuses } from '../constants';

export const generateInitialValues = ({
  invoice: {
    id,
    amount_customer_reward, // it's a manual entered discount or amount from reward
    custom_reward_purpose,
    amount_labor_cost,
    amount_material,
    amount_tip,
    labor = [],
    material = [],
    items = [],
    amount_charge,
    percent_commission,
    purpose_note,
    percent_stripe_fee,
    datetime_expire,
    expire_duration,
    estimate_duration,
    percent_deposit,
    is_quotation,
    is_final_payment,
    is_autopopulated
  } = {},
  defaultCommission,
  hasApprovedQuote
}) => {
  let defaultValues = {
    amount_labor_cost: '',
    amount_material: '',
    amount_tip: '',
    purpose_note: '',
    amount_charge: '',
    percent_commission: convertAmountValueToString(defaultCommission),
    percent_stripe_fee: '3',
    amount_customer_reward: '',
    custom_reward_purpose: '',
    labor: [],
    material: [],
    flat_rate: [],
    items: [],
    invoice_type: hasApprovedQuote ? invoiceTypes.PARTIAL.value : INVOICE_TYPE_DEFAULT,
    is_autopopulated: false
  };
  if (id) {
    defaultValues = {
      ...defaultValues,
      amount_labor_cost: labor?.length
        ? defaultValues.amount_labor_cost
        : convertAmountValueToString(amount_labor_cost),
      amount_material: material?.length ? defaultValues.amount_material : convertAmountValueToString(amount_material),
      amount_tip: is_final_payment ? convertAmountValueToString(amount_tip) : defaultValues.amount_tip,
      purpose_note,
      amount_charge: convertAmountValueToString(amount_charge),
      percent_commission: convertAmountValueToString(percent_commission),
      percent_stripe_fee: convertAmountValueToString(percent_stripe_fee),
      amount_customer_reward: custom_reward_purpose ? convertAmountValueToString(amount_customer_reward) : '',
      custom_reward_purpose,
      labor: labor?.length
        ? labor?.map(({ price, description, details, id }) => ({
            id,
            description,
            details,
            price: convertAmountValueToString(price)
          }))
        : defaultValues.labor,
      material: material?.length
        ? material?.map(({ price, description, details, id }) => ({
            id,
            description,
            details,
            price: convertAmountValueToString(price)
          }))
        : defaultValues.material,
      flat_rate: items?.length
        ? items
            .filter(({ item_section }) => item_section === invoiceItemTypes.FLAT.TYPE)
            .map(({ price, description, details, id }) => ({
              id,
              description,
              details,
              price: convertAmountValueToString(price)
            }))
        : defaultValues.flat_rate,

      invoice_type: getInvoiceType({
        is_final_payment,
        is_quotation
      }).value,
      datetime_expire,
      expire_duration: expire_duration || 7,
      is_do_not_expire: !datetime_expire && !expire_duration,
      estimate_duration: estimate_duration,
      estimate_duration_unit: 1,
      percent_deposit,

      is_autopopulated
    };
  }
  // Duration unit always in hours
  if (estimate_duration && estimate_duration >= 24) {
    defaultValues.estimate_duration = parseInt((estimate_duration / 24).toFixed(0));
    defaultValues.estimate_duration_unit = 24;
  }
  return defaultValues;
};

/**
 * Returns corresponding object from invoice type enum based on incoming invoice params
 * @param {Object} invoice object
 * @returns {Object} { VALUE, LABEL, COLOR } from invoice type enum with VALUE, LABEL and COLOR fields
 */
export const getInvoiceStatus = ({ status, is_quotation }) => {
  const invoiceStatuses = is_quotation ? quoteStatuses : paymentStatuses;
  return Object.values(invoiceStatuses).find(({ TYPE }) => TYPE === status) || quoteStatuses.PENDING_ADMIN;
};

export const generatePDFname = ({ date, isDeposit, is_final_payment, is_quotation, full_name }) => {
  const type = getInvoiceType({ isDeposit, is_final_payment, is_quotation }).value;
  const dateFormatted = formatters.dateWithTimezone(date, dates.DATE_SHORT);
  return `${type}_${dateFormatted}${
    full_name?.trim() ? `_${full_name?.trim().replace(/ /g, '_')}` : ''
  }.pdf`.toLowerCase();
};

export const generateAutofillOptionName = ({ amount_total_charge, date_created, is_quotation, status }) => {
  const { LABEL } = getInvoiceStatus({ status, is_quotation });
  const dateFormatted = formatters.dateWithTimezone(date_created, dates.DATE_SHORT);
  return `${dateFormatted}${
    !isNaN(amount_total_charge) ? ` - ${formatters.dollarCurrencyDB(amount_total_charge)}` : ''
  } - ${LABEL}`;
};

export const getFilesFromMedia = ({ media }) =>
  media.map(({ media_id, media }) => ({
    media_id,
    file: { id: media_id, name: media.original_name },
    previewUrl: media.thumbnail_path,
    isUploading: false
  }));

export const sumProviderPayout = (invoices) =>
  invoices
    .filter(
      ({ deposit, amount_deposit, amount_total_payout }) => (deposit?.length || amount_deposit) && amount_total_payout
    )
    .reduce((accumulator, { amount_total_payout }) => accumulator + amount_total_payout, 0);

export const getPlanSliceIndex = ({ statusCode, datetimeVisit, isOpen, finalPayment, quotation, isAnyPayment }) => {
  if (!isOpen) {
    return planStages.FINAL_PAID;
  }

  if (finalPayment) {
    return finalPayment.is_paid ? planStages.FINAL_PAID : planStages.FINAL_SENT;
  }

  if (quotation && !quotation.is_accepted && !datetimeVisit) {
    return planStages.QUOTE_SENT;
  }

  if (
    [systemStatuses.NEW, systemStatuses.PROPOSED, systemStatuses.CANCELED, systemStatuses.REJECTED].includes(statusCode)
  ) {
    return planStages.NOT_CONFIRMED;
  }

  const isAccepted = [systemStatuses.IN_PROGRESS, systemStatuses.ACCEPTED].includes(statusCode);
  if (isAccepted && quotation && !datetimeVisit) {
    return quotation.is_accepted ? planStages.QUOTE_APPROVED : planStages.QUOTE_SENT;
  }

  if (isAccepted && !isAnyPayment) {
    return datetimeVisit ? planStages.SCHEDULED : planStages.CONFIRMED;
  }

  if (isAccepted && isAnyPayment) {
    return datetimeVisit ? planStages.SCHEDULED : planStages.QUOTE_APPROVED;
  }

  if (statusCode === systemStatuses.COMPLETED && !finalPayment) {
    return planStages.COMPLETED;
  }

  if (statusCode === systemStatuses.COMPLETED) {
    return finalPayment?.is_paid ? planStages.FINAL_PAID : planStages.FINAL_SENT;
  }

  if (statusCode === systemStatuses.FINAL_PAID) {
    return planStages.FINAL_PAID;
  }

  return planStages.NOT_CONFIRMED;
};

export const getDisplayValue = (value = 0, commissionValue = 0) => {
  const floatValue = value * (100 + parseFloat(commissionValue));
  return Math.ceil(floatValue);
};

export const setItemDisplayValue = (item, isMarginCommission, commissionValue = 0, itemSection = 0) => {
  const updatedItem = { ...item };
  if (isMarginCommission) {
    updatedItem.amount_display = getDisplayValue(item.price || 0, commissionValue);
  } else {
    updatedItem.amount_display = Math.ceil((item.price || 0) * 100);
  }
  updatedItem.item_section = itemSection;
  return updatedItem;
};

export const makeDisplayValues = ({
  isMarginCommission = true,
  percent_commission = 0,
  flat_rate = [],
  labor = [],
  material = []
}) => {
  // Display values for margin commission type
  flat_rate = flat_rate.map((item) =>
    setItemDisplayValue(item, isMarginCommission, percent_commission, invoiceItemTypes.FLAT.TYPE)
  );
  labor = labor.map((item) => setItemDisplayValue(item, isMarginCommission, percent_commission));
  material = material.map((item) =>
    setItemDisplayValue(item, isMarginCommission, percent_commission, invoiceItemTypes.MATERIAL.TYPE)
  );
  const items = [...labor, ...material, ...flat_rate];

  return {
    flat_rate,
    labor,
    material,
    items
  };
};
